import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useGetJob } from '../../../../app/ApiGen';
import { DataErrorHandler } from '../../../../components/ErrorHandler';
import { CustomerDetailsSection } from '../../../../components/JobDetail/CustomerDetailsSection';
import { HeaderSection } from '../../../../components/JobDetail/HeaderSection';
import { LocationDetailsSection } from '../../../../components/JobDetail/LocationDetailsSection';
import LoadingPlaceholder from '../../../../components/LoadingPlaceholder';
import { Section, Workspace } from '../../../../design-system';
import { customerDetailsFields } from '../../../../utils/jobs/customer';
import { installationDetailsFields } from '../../../../utils/jobs/FieldDefinitions';
import { jobLocationFields } from '../../../../utils/jobs/location';
import { normalise } from '../../../../utils/request';
import { InstallationDetailsSection } from './InstallationDetailsSection';

export const JobView: React.FC = () => {
  const {
    params: { jobId },
  } = useRouteMatch<{ jobId: string }>();

  const { loading, data, error, refetch } = useGetJob({ jobId });

  if (loading) {
    return (
      <LoadingPlaceholder
        sections={[
          { items: Object.values(customerDetailsFields()), title: true },
          { items: Object.values(jobLocationFields()) },
          { items: Object.values(installationDetailsFields()) },
        ]}
      />
    );
  }

  if (error || !data) {
    return <DataErrorHandler error={error} refetch={refetch} description="Unable to load job details" />;
  }

  const jobData = normalise(data);

  return (
    <Workspace>
      <HeaderSection job={jobData} />
      <CustomerDetailsSection job={jobData} refetchJob={refetch} />
      <LocationDetailsSection job={jobData} refetchJob={refetch} />
      <InstallationDetailsSection job={jobData} refetchJob={refetch} />
      <Section minHeight="2rem" />
    </Workspace>
  );
};
