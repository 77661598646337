import * as React from 'react';
import { useNavigation } from '../../Provider';
import { StyledMenuSection } from './styled';

export interface MenuSectionProps {
  canOverflow?: boolean;
  children: (props: { isCollapsed: boolean }) => React.ReactNode;
}

const MenuSection: React.FC<MenuSectionProps> = ({ canOverflow = false, children }) => {
  const { isCollapsed } = useNavigation();

  return <StyledMenuSection canOverflow={canOverflow}>{children({ isCollapsed })}</StyledMenuSection>;
};

MenuSection.displayName = 'MenuSection';

export default MenuSection;
