import * as React from 'react';
import * as Yup from 'yup';
import type { Tier, UserOrganisation } from '../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import { labelsFor, OrganisationTier } from '../../../../../app/enums';
import { Card, Form, Loading, ValueComponentProps, Workspace } from '../../../../../design-system';

type OrgDetails = Pick<UserOrganisation, 'name' | 'tier' | 'createdDate'>;

export const Details: React.FC = () => {
  const { loading, organisation } = useCurrentOrganisation();

  if (loading || !organisation) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  const { name, tier, createdDate } = organisation;

  const initialValues: OrgDetails = {
    name,
    tier,
    createdDate,
  };

  return (
    <Card title="Organisation details">
      <Form<OrgDetails>
        fields={[
          {
            id: 'name',
            label: 'Name',
          },
          {
            id: 'tier',
            label: 'Tier',
            valueComponent: ({ value }: ValueComponentProps<Tier>) => <>{labelsFor(OrganisationTier)[value]}</>,
          },
        ]}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({})}
        onSubmit={() => {}}
      />
    </Card>
  );
};
