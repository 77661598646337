import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  clearCurrentOrganisationUserAction,
  getCurrentOrganisationUserAction,
} from '../../../../../store/organisationUser/actions';
import { fullStateSelector } from '../../../../../store/root';
import type { StoreContextProps } from '../../../../../store/types';

export const useOrganisationUser = (): StoreContextProps<'organisationUser'> => {
  const {
    organisationUser: { loading, error, current },
    organisation: { current: organisation },
  } = useSelector(fullStateSelector);

  const dispatch = useDispatch();

  const {
    params: { slug: orgSlug, id: userId },
  } = useRouteMatch<{ slug: string; id: string }>();

  const refetch = React.useCallback(() => {
    if (current) {
      dispatch(clearCurrentOrganisationUserAction());
    }
    dispatch(getCurrentOrganisationUserAction(organisation?.slug ?? orgSlug, current?.id ?? userId));
  }, [current, dispatch, organisation?.slug, orgSlug, userId]);

  React.useEffect(() => {
    if (!orgSlug || !userId) {
      // Route params are not available in some contexts, such as nav views.
      return;
    }

    // Initial fetch action, if the store is still in its initial state
    if (!loading && !current) {
      dispatch(getCurrentOrganisationUserAction(organisation?.slug ?? orgSlug, userId));
    }
    if (current && current.id !== userId) {
      dispatch(clearCurrentOrganisationUserAction());
      dispatch(getCurrentOrganisationUserAction(organisation?.slug ?? orgSlug, userId));
    }
  }, [current, dispatch, loading, organisation?.slug, orgSlug, userId]);

  return { error, loading, organisationUser: current, refetch };
};

const context = React.createContext<StoreContextProps<'organisationUser'>>({ loading: false, refetch: () => {} });
export const OrganisationUserProvider: React.FC = ({ children }) => {
  const organisationUserState = useOrganisationUser();
  return <context.Provider value={organisationUserState}>{children}</context.Provider>;
};
