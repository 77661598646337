import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Grid } from '@material-ui/core';

export type DetailListItemProps = {
  attributeName?: string;
};

export const DetailListItem: React.FC<DetailListItemProps> = ({ attributeName, children }) => {
  if (!attributeName) {
    return (
      <Grid container style={{ paddingBottom: '8px', paddingTop: '8px' }}>
        {children}
      </Grid>
    );
  }

  return (
    <Grid container style={{ paddingBottom: '8px', paddingTop: '8px' }}>
      <Grid item xs={5}>
        <Typography variant="body2" color="textSecondary">
          {attributeName}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {children}
      </Grid>
    </Grid>
  );
};
