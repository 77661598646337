import { sortByDate } from '../../sortBy';
import { TableColumn } from '../../dataTable/columns';
import { NetworkStatus } from '../../../app/ApiGen';

type TableChargePoint = {
  connectivityStatus: string;
  id: string;
  name: string;
  networkStatus: string;
  networkStatusUpdatedDate: string;
};

export function connectivityStatusColumn<T extends TableChargePoint>(): TableColumn<T> {
  const neverConnectedStatus: NetworkStatus = 'NEVER_CONNECTED';
  return {
    title: 'Connectivity status',
    field: 'connectivityStatus',
    customSort: (cpA: T, cpB: T): number => {
      if (cpA.networkStatus === neverConnectedStatus) {
        return -1;
      }
      if (cpB.networkStatus === neverConnectedStatus) {
        return 1;
      }
      return sortByDate('networkStatusUpdatedDate')(cpA, cpB);
    },
  };
}

export function referenceIdColumn<T extends TableChargePoint>(): TableColumn<T> {
  return { title: 'Reference ID', field: 'referenceId', hidden: true };
}

export function ocppChargePointIdColumn<T extends TableChargePoint>(): TableColumn<T> {
  return { title: 'OCPP Charge Point ID', field: 'ocppChargePointId' };
}

export function chargePointIdColumn<T extends TableChargePoint>(): TableColumn<T> {
  return { title: 'Charge point ID', field: 'id', hidden: true };
}

export function serialColumn<T extends TableChargePoint>(): TableColumn<T> {
  return { title: 'Serial', field: 'serial' };
}

export function modelColumn<T extends TableChargePoint>(): TableColumn<T> {
  return { title: 'Model', field: 'model' };
}

export function vendorColumn<T extends TableChargePoint>(): TableColumn<T> {
  return { title: 'Vendor', field: 'vendor' };
}

export function firmwareVersionColumn<T extends TableChargePoint>(): TableColumn<T> {
  return { title: 'Version', field: 'firmware' };
}
