import type { Reducer } from 'redux';
import * as Actions from './actions';
import { CardAction, CardState } from './types';

type Action = ReturnType<typeof Actions[keyof typeof Actions]>;

const initialState: CardState = {
  loading: false,
  error: undefined,
  current: undefined,
  selectedItemId: undefined,
};

export const cardReducers: Reducer<CardState, Action> = (state, action): CardState => {
  switch (action.type) {
    case CardAction.GET_CURRENT_CARD:
      return {
        ...(state ?? initialState),
        selectedItemId: action.payload.selectedItemId,
        loading: true,
        error: undefined,
      };
    case CardAction.SET_CURRENT_CARD:
      return {
        ...(state ?? initialState),
        selectedItemId: action.payload.card.id,
        loading: false,
        error: undefined,
        current: action.payload.card,
      };
    case CardAction.SET_CARD_ERROR:
      return {
        ...(state ?? initialState),
        selectedItemId: action.payload.selectedItemId,
        loading: false,
        error: action.payload.error,
      };
    case CardAction.CLEAR_CURRENT_CARD:
      return {
        ...(state ?? initialState),
        selectedItemId: undefined,
        loading: false,
        error: undefined,
        current: undefined,
      };
    default:
      return state ?? initialState;
  }
};
