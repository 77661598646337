import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import LoadBalancingGroupView from './LoadBalancingGroup';
import LoadBalancingGroupsListView from './LoadBalancingGroupsList';

const LoadBalancingView: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <LoadBalancingGroupView />
      </Route>
      <Route>
        <LoadBalancingGroupsListView />
      </Route>
    </Switch>
  );
};

export default LoadBalancingView;
