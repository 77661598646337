import type { Address } from './api';

export interface GooglePlace {
  addressComponents: {
    streetNumber?: string;
    streetName?: string;
    city?: string;
    district?: string;
    stateShort?: string;
    stateLong?: string;
    countryShort?: string;
    countryLong?: string;
    postalCode?: string;
  };
  utcOffsetMinutes?: number;
  coordinates?: { latitude: number; longitude: number };
}

function parseAddressComponents(
  addressComponents: google.maps.GeocoderAddressComponent[] | undefined,
): GooglePlace['addressComponents'] {
  if (!addressComponents) {
    return {};
  }

  const getComponentByType = (type: string, short = false): string | undefined => {
    const match = addressComponents.find((component) => component.types.includes(type));
    return short ? match?.short_name : match?.long_name;
  };

  return {
    streetNumber: getComponentByType('street_number'),
    streetName: getComponentByType('route'),
    city: getComponentByType('locality'),
    district: getComponentByType('sublocality_level_1'),
    stateShort: getComponentByType('administrative_area_level_1', true),
    stateLong: getComponentByType('administrative_area_level_1'),
    countryShort: getComponentByType('country', true),
    countryLong: getComponentByType('country'),
    postalCode: getComponentByType('postal_code'),
  };
}

function parseCoordinates(geometry: google.maps.places.PlaceGeometry | undefined): GooglePlace['coordinates'] {
  if (!geometry?.location) {
    return undefined;
  }
  const {
    location: { lat, lng },
  } = geometry;
  return { latitude: lat(), longitude: lng() };
}

export function parsePlace(place: google.maps.places.PlaceResult): GooglePlace {
  const { address_components: addressComponents, geometry, utc_offset_minutes: utcOffsetMinutes } = place;

  return {
    utcOffsetMinutes,
    addressComponents: parseAddressComponents(addressComponents),
    coordinates: parseCoordinates(geometry),
  };
}

export const mapPlaceToAddress: (place: GooglePlace) => Required<Address> = ({
  addressComponents: { streetNumber, streetName, stateLong, countryShort, postalCode, district, city },
}) => ({
  address1: [streetNumber, streetName].join(' ').trim(),
  address2: district ?? '',
  address3: '',
  city: city ?? '',
  postCode: postalCode ?? '',
  state: stateLong ?? '',
  country: countryShort ?? '',
});
