import { action, Action, PayloadAction } from 'typesafe-actions';
import { UserAction, UserState } from './types';

export function getCurrentUserAction(id: string): PayloadAction<UserAction.GET_CURRENT_USER, { id: string }> {
  return action(UserAction.GET_CURRENT_USER, { id });
}

export function setCurrentUserAction(
  user: UserState['current'],
): PayloadAction<UserAction.SET_CURRENT_USER, { user: UserState['current'] }> {
  return action(UserAction.SET_CURRENT_USER, { user });
}

export function setUserErrorAction(error: Error): PayloadAction<UserAction.SET_USER_ERROR, { error: Error }> {
  return action(UserAction.SET_USER_ERROR, { error });
}

export function clearCurrentUserAction(): Action<UserAction.CLEAR_CURRENT_USER> {
  return action(UserAction.CLEAR_CURRENT_USER);
}
