import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import styled from 'styled-components';
import { useGetOrganisationLocations } from '../../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../../app/CurrentOrganisationProvider';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { Loading } from '../../../../../../design-system';
import { normalise } from '../../../../../../utils/request';
import { DetailPanel } from './components/DetailPanel';
import { LocationMap } from './components/LocationMap';
import { LocationMapProvider } from './Context';

export const MapRoot = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WithOrgContext: React.FC<{ orgSlug: string }> = ({ orgSlug }) => {
  const { loading, error, data, refetch } = useGetOrganisationLocations({ orgSlug });

  if (loading) {
    return (
      <MapRoot>
        <Loading />
      </MapRoot>
    );
  }

  if (error || !data) {
    return (
      <>
        <MapRoot>
          <Box>
            <DataErrorHandler
              description="Unable to load locations for organisation"
              error={error}
              refetch={refetch}
              type="embedded"
            />
          </Box>
        </MapRoot>
        <Divider />
      </>
    );
  }

  const locations = normalise(data);
  return (
    <MapRoot>
      <LocationMapProvider>
        <LocationMap locations={locations} />
        <DetailPanel orgSlug={orgSlug} />
      </LocationMapProvider>
    </MapRoot>
  );
};

export const LocationsMap: React.FC = () => {
  const { organisation } = useCurrentOrganisation();

  return organisation ? (
    <WithOrgContext orgSlug={organisation.slug} />
  ) : (
    <MapRoot>
      <Loading />
    </MapRoot>
  );
};
