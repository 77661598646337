import { rgba } from 'polished';
import styled from 'styled-components';
import { StyledFooterSection } from '../FooterSection/styled';
import { StyledHeaderSection } from '../HeaderSection/styled';
import { StyledListItemIcon, StyledLink } from '../Item/styled';
import { StyledMenuSection } from '../MenuSection/styled';
import { Separator } from '../Separator';
import { StyledItemLoading } from '../ItemLoading/styled';

export const StyledContainerNavigation = styled('div')`
  min-width: 220px;
  max-width: 220px;
  height: 100vh;
  display: flex;
  flex-flow: nowrap column;
  background-color: ${(p) => p.theme.palette.common.white};
  box-shadow: ${(p) => p.theme.shadows['4']};
  overflow: hidden;
  transition:
    min-width ${(p) => p.theme.transitions.easing.easeInOut} ${(p) => p.theme.transitions.duration.standard}ms,
    max-width ${(p) => p.theme.transitions.easing.easeInOut} ${(p) => p.theme.transitions.duration.standard}ms;
  z-index: ${(p) => p.theme.zIndex.appBar - 1};

  ${StyledHeaderSection} {
  }

  ${Separator} {
    border-color: ${(p) => rgba(p.theme.palette.common.black, 0.13)}
  }

  ${StyledMenuSection} {
  }

  ${StyledFooterSection} {
  }

  ${StyledLink},
  ${StyledItemLoading} {
    margin: ${(p) => p.theme.spacing(1)}px;
    margin-top: 0;
    padding: ${(p) => p.theme.spacing(0, 1)};
    border-radius: 4px;
    color ${(p) => p.theme.palette.common.black};
  }

  ${StyledLink} {
    :hover,
    :focus,
    &.active {
      background-color: ${(p) => rgba(p.theme.palette.common.black, 0.13)};
    }
    
    ${StyledListItemIcon} {
      color ${(p) => p.theme.palette.text.primary};
    }
  }
`;
