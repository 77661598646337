import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Tier, useCreateOrganisation } from '../../app/ApiGen';
import { useAuthenticatedUser } from '../../app/AuthenticatedUserProvider';
import { labelsFor, OrganisationTier } from '../../app/enums';
import { RadioGroupInput, RadioGroupValue } from '../../components/CustomFields/RadioGroup';
import { errorSnackbar } from '../../components/ErrorSnackbar';
import {
  Card,
  Form,
  InputComponentProps,
  OnSubmit,
  Section,
  ValueComponentProps,
  Workspace,
} from '../../design-system';
import { getAuthenticatedUserAction } from '../../store/authenticatedUser/actions';
import { changeCurrentOrganisationAction } from '../../store/organisation/actions';
import { standardiseError } from '../../utils/error';
import { createSchema, FieldDefinitions } from '../../utils/formUtils';

type OrganisationFormValues = {
  emspId?: string;
  name: string;
  slug?: string;
  tier?: TierValueType;
};
type TierValueType = `${Tier}`;

const fields: FieldDefinitions<OrganisationFormValues> = [
  {
    id: 'name',
    label: 'Organisation name',
    inputProps: { helperText: 'Display name for the organisation' },
  },
  {
    id: 'slug',
    label: 'Organisation slug',
    inputProps: {
      helperText: "A unique identifier for the organisation's URL",
    },
  },
  {
    id: 'tier',
    label: 'Organisation tier',
    inputComponent: ({ disabled, error, name, onBlur, onChange, value }: InputComponentProps<TierValueType>) => (
      <RadioGroupInput<TierValueType>
        disabled={disabled}
        error={error}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        options={Object.entries(OrganisationTier).map(([k, v]) => ({ value: `${v}`, label: k }))}
        value={value}
      />
    ),
    valueComponent: ({ value }: ValueComponentProps<TierValueType>) => (
      <RadioGroupValue<TierValueType> labels={labelsFor(OrganisationTier)} value={value} />
    ),
  },
  {
    id: 'emspId',
    label: 'eMSP ID',
    inputProps: { helperText: 'Identifier of this organisation in an OCPI context' },
  },
];

const initialValues: OrganisationFormValues = {
  name: '',
  slug: '',
  tier: `${OrganisationTier.Business}`,
  emspId: '',
};

const validationSchema = createSchema<OrganisationFormValues>({
  emspId: Yup.string()
    .optional()
    .matches(
      /^[A-Z]{2}-[A-Z\d]{3}$/,
      'eMSP ID is an alpha-2 country code and 3-character party ID separated by a hyphen',
    ),
  name: Yup.string()
    .required('Organisation name is required')
    // eslint-disable-next-line no-template-curly-in-string --- Yup messages can be customised using template curlies in strings.
    .min(3, 'Organisation name must be at least ${min} characters')
    // eslint-disable-next-line no-template-curly-in-string --- Yup messages can be customised using template curlies in strings.
    .max(80, 'Organisation name must be at most ${max} characters'),
  slug: Yup.string()
    .optional()
    .matches(/^[a-z0-9-_]*$/, 'Organisation slug may only include lower-case letters, numbers, "-" and "_" characters')
    // eslint-disable-next-line no-template-curly-in-string --- Yup messages can be customised using template curlies in strings.
    .min(3, 'Organisation slug must be at least ${min} characters')
    // eslint-disable-next-line no-template-curly-in-string --- Yup messages can be customised using template curlies in strings.
    .max(36, 'Organisation slug must be at most ${max} characters'),
  tier: Yup.mixed<TierValueType>()
    .optional()
    .oneOf(
      Object.values(OrganisationTier).map((v) => v.toString() as TierValueType),
      'Invalid tier value',
    ),
});

const CreateOrganisationView: React.FC = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { loading } = useAuthenticatedUser();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { mutate: createOrganisation } = useCreateOrganisation({});

  const [redirectTo, setRedirectTo] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (!loading && redirectTo) {
      dispatch(changeCurrentOrganisationAction(redirectTo));
      push(`/organisations/${redirectTo}`);
    }
  }, [dispatch, loading, push, redirectTo]);

  const onSubmit: OnSubmit<OrganisationFormValues> = ({ name, slug, tier }, { setFieldError, setSubmitting }) => {
    setFieldError('slug', undefined);
    createOrganisation({
      data: {
        type: 'organisations',
        attributes: {
          name,
          slug: slug === '' ? undefined : slug,
          tier: typeof tier === 'string' ? (parseInt(tier, 10) as Tier) : undefined,
        },
      },
    })
      .then(({ data }) => {
        setSubmitting(false);
        enqueueSnackbar('Organisation created', { variant: 'success' });
        setRedirectTo(data.attributes.slug);
        // Reload the current user's organisation memberships.
        dispatch(getAuthenticatedUserAction());
      })
      .catch((e) => {
        setSubmitting(false);
        const error = standardiseError(e);
        if (error.title === 'AlreadyExistsError') {
          setFieldError('slug', 'Organisation with this slug already exists');
        } else {
          errorSnackbar('Unable to create organisation', closeSnackbar, enqueueSnackbar, e);
        }
      });
  };

  return (
    <Workspace>
      <Section
        title="Create new organisation"
        description={
          <>
            An organisation is a set of customers who will use CP-Link to manage their charge points (Admins), and users
            who will charge their vehicles at these charge points (Drivers).
          </>
        }
      />
      <Section description="When the organisation is created, you will be its sole member and have the Admin role.">
        <Card>
          <Form<OrganisationFormValues>
            fields={fields}
            initialValues={initialValues}
            isEditing
            labels={{ save: 'Create organisation' }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          />
        </Card>
      </Section>
    </Workspace>
  );
};

export default CreateOrganisationView;
