import { useSnackbar } from 'notistack';
import * as React from 'react';
import * as Yup from 'yup';
import { useCurrentOrganisation } from '../../../../../../../../app/CurrentOrganisationProvider';
import { Dialog } from '../../../../../../../../components/Dialog';
import { errorSnackbar } from '../../../../../../../../components/ErrorSnackbar';
import { Form, OnSubmit } from '../../../../../../../../design-system';
import { usePostChargePointCommandUpdateFirmware } from '../../../../../../../../utils/api';
import { useChargePoint } from '../../../Provider';

export interface UpdateFirmwareDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const initialValues = {
  location: '',
};

const validationSchema = Yup.object().shape({
  location: Yup.string().required('URL is required'),
});

export const UpdateFirmwareDialog: React.FC<UpdateFirmwareDialogProps> = ({
  isOpen,
  onClose,
}: UpdateFirmwareDialogProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { slug: organisationSlug } = useCurrentOrganisation().organisation ?? { slug: '' };
  const { chargePoint, refetch } = useChargePoint();

  const { error, mutate } = usePostChargePointCommandUpdateFirmware({
    organisationSlug,
    chargePointId: chargePoint?.id ?? '',
  });

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to update firmware', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  const onSubmit = React.useCallback<OnSubmit<typeof initialValues>>(
    async ({ location }, { setSubmitting }) => {
      try {
        const { data } = await mutate({ location });
        enqueueSnackbar(`Update firmware command ${data.status.toLowerCase()}`);
        onClose();
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [mutate, enqueueSnackbar, onClose, refetch],
  );

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Update firmware">
      <Form<typeof initialValues>
        fields={[{ id: 'location', label: 'URL' }]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        labels={{ save: 'Send command' }}
        isEditing
      />
    </Dialog>
  );
};
