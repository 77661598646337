import type { Connector, NetworkStatus } from '../app/ApiGen';
import { AddressProps } from '../components/Address';
import { getAddressAsString } from './locations/address';
import { getStatusIndicator } from '../components/Connectors';
import { getNestedKey } from './getNestedKey';

/**
 * Places NaN values together in the sort order.
 */
function numericSort(a: number, b: number): number {
  if (Number.isNaN(a) && Number.isNaN(b)) {
    return 0;
  }
  if (Number.isNaN(a)) {
    return 1;
  }
  if (Number.isNaN(b)) {
    return -1;
  }
  return a - b;
}

type NestedKeyedObject<Path extends string, Value> = Path extends `${infer K}.${infer Rest}`
  ? Partial<Record<K, NestedKeyedObject<Rest, Value>>>
  : Partial<Record<Path, Value>>;

/**
 * Sorts objects in locale-aware alphabetical order by a property that is an Address.
 */
export function sortByAddress<K extends string>(
  key: K,
): (
  propsA: NestedKeyedObject<K, AddressProps['address']>,
  propsB: NestedKeyedObject<K, AddressProps['address']>,
) => number {
  return (propsA, propsB) => {
    const valueA = getNestedKey(key, propsA) as AddressProps['address'] | undefined;
    const valueB = getNestedKey(key, propsB) as AddressProps['address'] | undefined;

    if (!valueA && !valueB) {
      return 0;
    }
    if (!valueA) {
      return 1;
    }
    if (!valueB) {
      return -1;
    }

    const addressA = getAddressAsString(valueA).toUpperCase();
    const addressB = getAddressAsString(valueB).toUpperCase();

    return addressA.localeCompare(addressB);
  };
}

/**
 * Sorts objects in chronological order by a property that is a Date.
 */
export function sortByDate<K extends string>(
  key: K,
): (propsA: NestedKeyedObject<K, Date | string>, propsB: NestedKeyedObject<K, Date | string>) => number {
  return (propsA, propsB) => {
    const valueA = getNestedKey(key, propsA) as Date | string | undefined;
    const valueB = getNestedKey(key, propsB) as Date | string | undefined;

    const dateA = valueA ? new Date(valueA).valueOf() : NaN;
    const dateB = valueB ? new Date(valueB).valueOf() : NaN;
    return numericSort(dateA, dateB);
  };
}

/**
 * Sort objects that have a start and end timestamp property in duration order.
 */
export function sortByDuration<K1 extends string, K2 extends string>(
  startKey: K1,
  endKey: K2,
): (
  propsA: NestedKeyedObject<K1, Date | string> & NestedKeyedObject<K2, Date | string>,
  propsB: NestedKeyedObject<K1, Date | string> & NestedKeyedObject<K2, Date | string>,
) => number {
  return (propsA, propsB) => {
    const valueAStart = getNestedKey(startKey, propsA) as Date | string | undefined;
    const valueAEnd = getNestedKey(endKey, propsA) as Date | string | undefined;

    const valueBStart = getNestedKey(startKey, propsB) as Date | string | undefined;
    const valueBEnd = getNestedKey(endKey, propsB) as Date | string | undefined;

    const durationA = valueAStart && valueAEnd ? new Date(valueAEnd).valueOf() - new Date(valueAStart).valueOf() : NaN;
    const durationB = valueBStart && valueBEnd ? new Date(valueBEnd).valueOf() - new Date(valueBStart).valueOf() : NaN;
    return numericSort(durationA, durationB);
  };
}

/**
 * Sort objects in locale-aware alphabetical order by a property that is a String.
 */
export function sortByLocale<K extends string>(
  key: K,
): (propsA: NestedKeyedObject<K, string>, propsB: NestedKeyedObject<K, string>) => number {
  return (propsA, propsB) => {
    const valueA = getNestedKey(key, propsA) as string | undefined;
    const valueB = getNestedKey(key, propsB) as string | undefined;

    if (!valueA && !valueB) {
      return 0;
    }

    if (!valueA) {
      return 1;
    }

    if (!valueB) {
      return -1;
    }

    return valueA.trim().toLocaleUpperCase().localeCompare(valueB.trim().toLocaleUpperCase());
  };
}

/**
 * Sort objects in numerical order by a property that will be coerced to a Number.
 */
export function sortByValue<K extends string>(
  key: K,
): (propsA: NestedKeyedObject<K, unknown>, propsB: NestedKeyedObject<K, unknown>) => number {
  return (propsA, propsB) => {
    const valueA = getNestedKey(key, propsA);
    const valueB = getNestedKey(key, propsB);

    return numericSort(Number(valueA), Number(valueB));
  };
}

/**
 * Sort objects by a property that is an email address, grouped by the portion after the `@`, then by the portion before
 * the `@`. Case-insensitive.
 */
export function sortByEmail<K extends string>(
  key: K,
): (propsA: NestedKeyedObject<K, string>, propsB: NestedKeyedObject<K, string>) => number {
  return (propsA, propsB) => {
    const emailA = getNestedKey(key, propsA) as string | undefined;
    const emailB = getNestedKey(key, propsB) as string | undefined;

    const aParts = emailA?.split('@') ?? [];
    const bParts = emailB?.split('@') ?? [];

    const valueA = aParts.slice(1).concat(aParts.slice(0)).join('');
    const valueB = bParts.slice(1).concat(bParts.slice(0)).join('');

    return valueA.trim().toLocaleUpperCase().localeCompare(valueB.trim().toLocaleUpperCase());
  };
}

export type SortableByConnectors = {
  connectors: Pick<Connector, 'connectorId' | 'ocppStatus'>[];
  networkStatus: NetworkStatus;
};
/**
 * Sort charge points by their network and connector statuses, and then from fewest to most connectors
 */
export function sortByConnectors(chargePointA: SortableByConnectors, chargePointB: SortableByConnectors): number {
  const { connectors: connectorsA, networkStatus: networkStatusA } = chargePointA;
  const { connectors: connectorsB, networkStatus: networkStatusB } = chargePointB;
  function compareStatus(
    connectorA: SortableByConnectors['connectors'][number],
    connectorB: SortableByConnectors['connectors'][number],
  ): number {
    const { label: labelA } = getStatusIndicator(networkStatusA, connectorA);
    const { label: labelB } = getStatusIndicator(networkStatusB, connectorB);
    return labelA.trim().toLocaleUpperCase().localeCompare(labelB.trim().toLocaleUpperCase());
  }

  const [connectorA] = connectorsA.sort(compareStatus);
  const [connectorB] = connectorsB.sort(compareStatus);
  const comparedStatus = compareStatus(connectorA, connectorB);

  return comparedStatus === 0 ? connectorsA.length - connectorsB.length : comparedStatus;
}
