import type { ScatterDataPoint } from 'chart.js';
import { LLMClient, PhaseKey } from '../../../../../../../utils/chargePoints/diagnostics';
import { ClientMeasurement, ClientMeasurementAttributes } from './ClientMeasurement';
import { colourOptions, getDataset, LineDataset } from './presentation';

export type ClientName = string;

export type ClientsByName = Record<ClientName, LLMClientModel>;

interface LLMClientAttributes {
  label: ClientName;
  measurements: ClientMeasurementAttributes[];
}

export class LLMClientModel implements LLMClientAttributes {
  readonly label: ClientName;

  readonly measurements: ClientMeasurement[];

  private constructor(params: LLMClientAttributes) {
    const { label, measurements } = params;
    this.label = label;
    this.measurements = measurements.map((c) => new ClientMeasurement(c));
  }

  static createFromData(params: LLMClientAttributes) {
    return new LLMClientModel(params);
  }

  static addClientModel(args: {
    currentClient: LLMClientModel | undefined;
    llmClient: LLMClient;
    serverEventDate: string;
    allClients: ClientsByName;
  }): ClientsByName {
    const { allClients, llmClient, serverEventDate } = args;
    const { Name, MeasuredCurrent: current, ChargingOnPhase: phase } = llmClient;
    const measurement = new ClientMeasurement({ current, phase, serverEventDate });

    const currentClient = args.currentClient ?? allClients[Name];
    const clientModel = currentClient
      ? currentClient.addMeasurement(measurement)
      : LLMClientModel.createFromData({ label: Name, measurements: [measurement] });

    return { ...allClients, [Name]: clientModel };
  }

  static getDatasets(args: { clientsByName: ClientsByName; phase: PhaseKey }): LineDataset[] {
    const { clientsByName, phase } = args;
    return Object.keys(clientsByName)
      .sort((a, b) => a.localeCompare(b))
      .reduce<LineDataset[]>((datasets, label, index) => {
        const client = clientsByName[label];
        return [...datasets, client.getDataset({ colour: colourOptions[index], label, phase })];
      }, []);
  }

  private getDataset(args: { colour: string; label: string; phase: PhaseKey }): LineDataset {
    const { colour, label, phase } = args;
    const dataset = getDataset({
      key: 'client',
      data: this.getSortedData({ phase }),
    });
    return { ...dataset, label, borderColor: colour, backgroundColor: colour };
  }

  addMeasurement(value: ClientMeasurement): LLMClientModel {
    return new LLMClientModel({ ...this, measurements: [...this.measurements, value] });
  }

  getSortedData(args: { phase: PhaseKey }): ScatterDataPoint[] {
    const { phase } = args;
    return this.measurements.map((model) => model.getDatum({ phase })).sort((a, b) => (a && b ? a.x - b.x : 0));
  }
}
