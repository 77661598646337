import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthenticatedUserAction, getAuthenticatedUserAction } from '../store/authenticatedUser/actions';
import { fullStateSelector } from '../store/root';
import type { StoreContextProps } from '../store/types';

export const useAuthenticatedUser: () => StoreContextProps<'authenticatedUser'> = () => {
  const {
    authenticatedUser: { loading, error, current, mfaRequired },
  } = useSelector(fullStateSelector);
  const dispatch = useDispatch();

  const refetch = React.useCallback(() => {
    if (current) {
      dispatch(clearAuthenticatedUserAction());
    }
    dispatch(getAuthenticatedUserAction());
  }, [current, dispatch]);

  React.useEffect(() => {
    // Initial fetch action, if the store is still in its initial state
    if (!loading && !current) {
      dispatch(getAuthenticatedUserAction());
    }
  }, [current, dispatch, loading]);

  return { authenticatedUser: current, error, loading, refetch, mfaRequired };
};

const context = React.createContext<StoreContextProps<'authenticatedUser'>>({
  loading: false,
  mfaRequired: false,
  refetch: () => {},
});

export const AuthenticatedUserProvider: React.FC = ({ children }) => {
  const authenticatedUserState = useAuthenticatedUser();
  return <context.Provider value={authenticatedUserState}>{children}</context.Provider>;
};
