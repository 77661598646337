import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { Section, Workspace } from '../../../../../../design-system';
import { ChargePointCommands } from './components/ChargePointCommands';

const SettingsView: React.FC = () => (
  <Workspace>
    <Section title="Firmware" description="Firmware operations for this charge point.">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ChargePointCommands />
        </Grid>
      </Grid>
    </Section>
  </Workspace>
);

export default SettingsView;
