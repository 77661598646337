import { ChartData } from 'chart.js';
import moment from 'moment-timezone';
import { InsightsSummary } from '../../../../../../app/ApiGen';
import { getCost } from '../../../../../../utils/electricityCost';

const updateTotalCarbonUsage = (params: { currentTotalCarbonUsage?: number; newCarbonUsage?: number }) => {
  const { currentTotalCarbonUsage, newCarbonUsage } = params;
  if (currentTotalCarbonUsage) {
    if (newCarbonUsage) {
      return currentTotalCarbonUsage + newCarbonUsage;
    }
    return currentTotalCarbonUsage;
  }
  return newCarbonUsage;
};

export const getInsightTotals = (data: InsightsSummary[]) => {
  let powerUsage = 0;
  let costs = 0;
  let duration = 0;
  let sessions = 0;
  let carbonOffset = 0;
  let totalCarbonUsage: number | undefined;

  data.forEach((node) => {
    powerUsage += node.powerUsage;
    costs += getCost(node.costs) ?? 0;
    duration += node.duration;
    sessions += node.sessions;
    carbonOffset += node.carbonOffset;
    totalCarbonUsage = updateTotalCarbonUsage({
      currentTotalCarbonUsage: totalCarbonUsage,
      newCarbonUsage: node.totalCarbonUsage,
    });
  });

  return {
    powerUsage: Number((powerUsage / 1000).toFixed(0)).toLocaleString(),
    costs: Number(costs.toFixed(0)).toLocaleString(),
    duration: duration.toLocaleString(),
    sessions: sessions.toLocaleString(),
    carbonOffset: Number((carbonOffset / 1000).toFixed(0)).toLocaleString(),
    totalCarbonUsage,
  };
};

const chartType = ['powerUsage', 'costs', 'sessions', 'carbonOffset', 'totalCarbonUsage'];

const getChartLabels = (carbonUsageUnit: string) => ({
  powerUsage: 'Electricity usage (kWh)',
  costs: 'Electricity cost ($)',
  sessions: 'Sessions',
  carbonOffset: 'Carbon offset (kg)',
  totalCarbonUsage: `Carbon use (${carbonUsageUnit})`,
});

const carbonUsageForGraph = (params: { useKilogramForCarbonUsage: boolean; totalCarbonUsage?: number }) => {
  const { useKilogramForCarbonUsage, totalCarbonUsage } = params;
  if (totalCarbonUsage) {
    if (useKilogramForCarbonUsage) {
      return totalCarbonUsage / 1000;
    }
    return totalCarbonUsage;
  }
  return 0;
};

export const getChartData = (tabId: number, insights: InsightsSummary[], backgroundColor: string): ChartData<'bar'> => {
  const type = chartType[tabId];

  const labels =
    insights.length > 14
      ? insights.map(({ startDate }) => moment(startDate).format('D MMM'))
      : insights.map(({ startDate }) => moment(startDate).format('ddd'));

  const totalCarbonUsageForAllSessions = getInsightTotals(insights).totalCarbonUsage;

  const useKilogramForCarbonUsage =
    totalCarbonUsageForAllSessions !== undefined && totalCarbonUsageForAllSessions >= 1000;

  const carbonUseUnit = useKilogramForCarbonUsage ? `kg` : 'g';

  const data = insights.map((node) => {
    switch (type) {
      case 'powerUsage':
        return node[type] / 1000;
      case 'costs':
        return getCost(node.costs) ?? 0;
      case 'carbonOffset':
        return node[type] / 1000;
      case 'totalCarbonUsage':
        return carbonUsageForGraph({ useKilogramForCarbonUsage, totalCarbonUsage: node[type] });
      default:
        return node[type as keyof InsightsSummary] as number;
    }
  });

  const chartLabel = getChartLabels(carbonUseUnit);
  const label = chartLabel[type as keyof typeof chartLabel];

  return {
    labels,
    datasets: [
      {
        label,
        data,
        backgroundColor,
        maxBarThickness: 30,
      },
    ],
  };
};
