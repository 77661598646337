import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useGetChargePoint } from '../../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../../app/CurrentOrganisationProvider';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { Loading, Workspace } from '../../../../../../design-system';
import { locationHasAttributes } from '../../../../../../utils/format';
import { normalise } from '../../../../../../utils/request';
import { useChargePoint as useLegacyChargePoint } from '../Provider';
import { ConnectorList } from './components/ConnectorList';
import { Header } from './components/Header';
import { RecentSessions } from './components/RecentSessions';
import { ReloadButton } from './components/ReloadButton';

export const OverviewView: React.FC = () => {
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  const { refetch: refetchSideNavChargePoint } = useLegacyChargePoint();
  const { organisation, loading: organisationLoading } = useCurrentOrganisation();
  const { loading: chargePointLoading, data, error, refetch } = useGetChargePoint({ id });

  if (chargePointLoading || organisationLoading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (error || !data || !organisation) {
    return (
      <DataErrorHandler
        description="Unable to load charge point"
        error={error}
        refetch={async () => {
          await refetch();
          refetchSideNavChargePoint();
        }}
      />
    );
  }

  const chargePoint = normalise(data);
  const location = locationHasAttributes(chargePoint.location) ? chargePoint.location : undefined;

  return (
    <Workspace maxWidth={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header chargePoint={chargePoint} organisation={organisation} location={location} />
        </Grid>
        <Grid item xs={12}>
          <RecentSessions chargePoint={chargePoint} organisation={organisation} />
        </Grid>
        <Grid item xs={12}>
          <ConnectorList chargePoint={chargePoint} refetch={refetch} />
        </Grid>
        <ReloadButton loading={chargePointLoading} refetch={refetch} />
      </Grid>
    </Workspace>
  );
};
