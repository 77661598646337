import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { GetDataError } from 'restful-react';
import styled from 'styled-components';
import { Location, LocationSummary, ResponseApiErrors } from '../../app/ApiGen';
import { Normalised } from '../../utils/request';
import { LocationDetails } from '../../views/Organisations/Organisation/Create/CreateChargePoint/types';
import { getAddressAsString } from '../../utils/locations/address';
import { AutocompleteContents, AutocompleteWrapper, renderAutocompleteInput } from './SimpleAutocomplete';
import { InputComponentProps, ValueComponentProps } from '../../design-system';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

interface LocationValueProps extends ValueComponentProps<LocationDetails | Normalised<LocationSummary> | undefined> {
  isLink?: boolean;
  organisationSlug?: string;
}

function getLocationAttributes(location: LocationDetails | Normalised<LocationSummary>) {
  return { id: location.id, name: location.name, address: location.address };
}

export const LocationValue: React.FC<LocationValueProps> = ({
  isLink = true,
  organisationSlug,
  value,
}: LocationValueProps) => {
  if (!value) {
    return null;
  }

  const { id, name, address } = getLocationAttributes(value);

  if (isLink && organisationSlug) {
    return (
      <StyledLink to={`/organisations/${organisationSlug}/locations/${id}`}>
        <Typography color="primary" variant="body2">
          {name}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {getAddressAsString(address)}
        </Typography>
      </StyledLink>
    );
  }

  return (
    <div>
      <Typography color="textPrimary" variant="body2">
        {name}
      </Typography>
      <Typography color="textSecondary" variant="body2">
        {getAddressAsString(address)}
      </Typography>
    </div>
  );
};

export interface LocationInputProps extends InputComponentProps<string | undefined> {
  dataLoading: boolean;
  dataError: GetDataError<ResponseApiErrors> | null;
  options?: Normalised<Location>[];
}

export const LocationInput: React.FC<LocationInputProps> = ({
  dataLoading,
  options,
  value,
  name,
  onBlur,
  onChange,
  disabled,
  dataError,
  helperText,
}: LocationInputProps) => {
  if (dataLoading) {
    return <CircularProgress size="32px" />;
  }

  if (dataError || !options) {
    return <Typography color="error">Unable to load locations</Typography>;
  }

  const locationOptions = options.map(({ id: val, name: label, address }) => ({
    value: val,
    label,
    address: getAddressAsString(address),
  }));

  return (
    <AutocompleteWrapper>
      <Autocomplete
        disableClearable
        options={locationOptions}
        getOptionLabel={(option) => option.label}
        value={locationOptions.find(({ value: key }) => key === value)}
        style={{ width: '100%' }}
        renderOption={({ label, address }) => (
          <div>
            <Typography variant="body2">{label}</Typography>
            <Typography variant="body2" color="textSecondary">
              {address}
            </Typography>
          </div>
        )}
        disabled={disabled}
        renderInput={renderAutocompleteInput(disabled, !!dataError, helperText)}
        onBlur={onBlur}
        onChange={(_, location: AutocompleteContents) => {
          // Note: this also blocks the user from manually clearing the field
          if (location?.value) {
            onChange(name)(location.value);
          }
        }}
      />
    </AutocompleteWrapper>
  );
};
