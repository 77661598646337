import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { FormReadonly, Section, useWizard } from '../../../../../design-system';
import { jobDetailsFields, JobFormDetails } from '../../../../../utils/jobs/CreateJob';
import { JobLocationDetails, jobLocationFields } from '../../../../../utils/jobs/location';
import { UserCreateDetails, userCreateFields } from '../../../../../utils/users/FieldDefinitions';
import type { JobWizardState } from '../types';

export const ConfirmationStep: React.FC = () => {
  const { state: untypedState, prevStep, nextStep, isLoading } = useWizard();
  const state = untypedState as JobWizardState;

  return (
    <>
      <Section title="Customer details">
        <FormReadonly<UserCreateDetails>
          fields={Object.values(userCreateFields)}
          initialValues={state.driverFieldContents}
        />
      </Section>
      <Section title="New location details">
        <FormReadonly<JobLocationDetails>
          fields={Object.values(jobLocationFields())}
          initialValues={state.locationFieldContents}
        />
      </Section>
      <Section title="Job details">
        <FormReadonly<JobFormDetails> fields={Object.values(jobDetailsFields)} initialValues={state} />
      </Section>
      <Box pt={2} display="flex">
        <Button variant="contained" onClick={prevStep} disabled={isLoading}>
          Back
        </Button>
        <Box mr="auto" />
        <Button color="primary" variant="contained" onClick={nextStep} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Create job'}
        </Button>
      </Box>
    </>
  );
};
