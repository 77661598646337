import TextField from '@material-ui/core/TextField';
import { Field as FormikField, FieldProps } from 'formik';
import * as React from 'react';
import { uid } from 'react-uid';
import { Field } from '../../../Field';
import { List, ListItem } from '../../../List';
import { FormField } from '../../types';
import { getNestedValue } from '../../utils';

const hasError = (id: string, touched: Record<string, unknown>, errors: Record<string, unknown>) =>
  touched && errors && !!getNestedValue(id, touched) && !!getNestedValue(id, errors);

const getHelperText = (
  id: string,
  touched: Record<string, unknown>,
  errors: Record<string, unknown>,
  helperText: React.ReactNode,
): string | undefined =>
  (hasError(id, touched, errors) ? getNestedValue(id, errors) : helperText) as string | undefined;

export interface FormEditableProps {
  fields: FormField[];
  errors: Record<string, unknown>;
  touched: Record<string, unknown>;
  disabled: boolean;
  inset?: boolean;
}

export const FormEditable: React.FC<FormEditableProps> = ({ fields, errors, touched, disabled, inset }) => (
  <List inset={inset}>
    {fields.map((field, idx) => {
      const { id, label, inputComponent: Input, inputProps = {} } = field;
      const { helperText, inputProps: textFieldProps, style, ...rest } = inputProps;

      const props = {
        disabled,
        error: hasError(id, touched, errors),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- TODO
        helperText: getHelperText(id, touched, errors, helperText),
      };

      return (
        <ListItem key={uid(field, idx)}>
          <Field label={label}>
            <FormikField name={id}>
              {({ field: formikField }: FieldProps) =>
                Input ? (
                  <Input {...props} {...formikField} {...rest} />
                ) : (
                  <TextField
                    {...props}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO
                    inputProps={{ ...textFieldProps }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO
                    style={{ margin: 0, ...style }}
                    {...formikField}
                    {...rest}
                  />
                )
              }
            </FormikField>
          </Field>
        </ListItem>
      );
    })}
  </List>
);
