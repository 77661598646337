import MuiCssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, StylesProvider as MuiStylesProvider } from '@material-ui/core/styles';
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { light } from './system';

export type ThemeProps = Record<string, unknown>;

const Theme: React.FC<ThemeProps> = ({ children }) => (
  <MuiStylesProvider injectFirst>
    <MuiThemeProvider theme={light.mui}>
      <StyledThemeProvider theme={light.styled}>
        <>
          <MuiCssBaseline />
          {children}
        </>
      </StyledThemeProvider>
    </MuiThemeProvider>
  </MuiStylesProvider>
);

Theme.displayName = 'Theme';

export { Theme };
