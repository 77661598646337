import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import * as Yup from 'yup';
import { useCurrentOrganisation } from '../../../../../../../../app/CurrentOrganisationProvider';
import { Dialog, DialogProps } from '../../../../../../../../components/Dialog';
import { errorSnackbar } from '../../../../../../../../components/ErrorSnackbar';
import { Form, OnSubmit } from '../../../../../../../../design-system';
import { CommandReset, usePostChargePointCommandReset } from '../../../../../../../../utils/api';
import { createSchema } from '../../../../../../../../utils/formUtils';
import { useChargePoint } from '../../../Provider';

const initialValues = {
  resetType: '',
};

type ResetDialogFields = {
  resetType: string;
};

const validationSchema = createSchema<ResetDialogFields>({
  resetType: Yup.string().required('Type is required'),
});

export const ResetDialog: React.FC<DialogProps> = ({ isOpen, onClose }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { slug: organisationSlug } = useCurrentOrganisation().organisation ?? { slug: '' };
  const { chargePoint, refetch } = useChargePoint();

  const { error, mutate } = usePostChargePointCommandReset({
    organisationSlug,
    chargePointId: chargePoint?.id ?? '',
  });

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to reset charge point', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  const onSubmit = React.useCallback<OnSubmit<ResetDialogFields>>(
    async ({ resetType }, { setSubmitting }) => {
      try {
        const { data } = await mutate({ resetType: resetType as CommandReset['resetType'] });
        enqueueSnackbar(`Reset command ${data.status.toLowerCase()}`);
        onClose();
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [mutate, enqueueSnackbar, onClose, refetch],
  );

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Reset">
      <Form<ResetDialogFields>
        fields={[
          {
            id: 'resetType',
            label: 'Reset type',
            inputComponent: (props) => (
              <TextField
                select
                variant="outlined"
                margin="dense"
                fullWidth
                style={{ margin: 0 }}
                disabled={props.disabled}
                error={props.error}
                name={props.name}
                onBlur={props.onBlur}
                onChange={props.onChange}
                defaultValue=""
              >
                {['Soft', 'Hard'].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            ),
          },
        ]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        labels={{ save: 'Send command' }}
        isEditing
      />
    </Dialog>
  );
};
