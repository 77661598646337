import type { Job } from '../../app/ApiGen';
import {
  InstallationPoint,
  InstallationPointInput,
  InstallationPointValue,
} from '../../components/CustomFields/InstallationPoint';
import { locationHasAttributes } from '../format';
import type { FormFields } from '../formUtils';
import { addressField, LocationFields, locationFields } from '../locations/FieldDefinitions';
import { InstallationPointField } from '../locations/installationConnectionPoint';
import type { Normalised } from '../request';

export type JobLocationDetails = LocationFields & InstallationPointField;

function getJobInstallationPoint(job: Normalised<Job>): InstallationPoint | null {
  const { details, location } = job;
  if (locationHasAttributes(location) && typeof location.icpNumber === 'string') {
    return { id: location.icpNumber };
  }
  if (typeof details.icpNumber === 'string') {
    return { id: details.icpNumber };
  }
  return null;
}

export function jobLocationFields(
  options: { timeZoneEditable: boolean } = { timeZoneEditable: true },
): FormFields<JobLocationDetails> {
  const { timeZoneEditable } = options;
  return {
    name: locationFields.name,
    address: addressField({
      coordinatesFieldId: 'coordinates',
      icpFieldId: 'installationPoint',
      timeZoneFieldId: timeZoneEditable ? 'timeZone' : undefined,
    }),
    coordinates: locationFields.coordinates,
    timeZone: timeZoneEditable
      ? locationFields.timeZone
      : {
          id: 'timeZone',
          label: 'Time zone',
          inputProps: {
            disabled: true,
            helperText: 'Please contact Evnex support if the time zone of this location is not correct',
          },
        },
    installationPoint: {
      id: 'installationPoint',
      label: 'ICP (NZ)/NMI (AU) Number',
      inputComponent: InstallationPointInput,
      valueComponent: InstallationPointValue,
    },
  };
}

export function toJobLocationFields(job: Normalised<Job>): Required<JobLocationDetails> {
  const location = locationHasAttributes(job.location)
    ? job.location
    : {
        address: {
          address1: '',
          city: '',
          country: '',
        },
        coordinates: {
          latitude: '',
          longitude: '',
        },
        name: '',
      };

  return { ...location, timeZone: job.details.timeZone ?? '', installationPoint: getJobInstallationPoint(job) };
}
