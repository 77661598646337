import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import React from 'react';
import { useCurrentOrganisation } from '../../../../../../../app/CurrentOrganisationProvider';
import { DataErrorHandler, ErrorHandler } from '../../../../../../../components/ErrorHandler';
import { Card, Loading, Section, Workspace } from '../../../../../../../design-system';
import { useLocation } from '../../Provider';
import { SelectUserStep } from './SelectUserStep';

const AddUserView: React.FC = () => {
  const { organisation, loading: organisationLoading } = useCurrentOrganisation();
  const { error, loading: locationLoading, location, refetch } = useLocation();
  if (organisationLoading || locationLoading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }
  if (error || !location) {
    return <DataErrorHandler description="Unable to load location" error={error} refetch={refetch} />;
  }
  if (!organisation) {
    return (
      <Workspace>
        <ErrorHandler title="Unable to load organisation" />
      </Workspace>
    );
  }
  return (
    <Workspace>
      <Section title="Add user" description="Add user to a location">
        <Card>
          <Box py={4}>
            <Container maxWidth="sm">
              <SelectUserStep organisation={organisation} location={location} />
            </Container>
          </Box>
        </Card>
      </Section>
    </Workspace>
  );
};

export default AddUserView;
