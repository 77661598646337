import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  min-width: 64px;
  min-height: 56px;
  font-weight: normal;
  text-transform: none;
`;

const Inner = styled('div')`
  display: flex;
  flex-flow: nowrap column;
`;

const Icon = styled('div').withConfig({ shouldForwardProp: (prop) => !['color', 'isDisabled'].includes(prop) })<{
  color: string;
  isDisabled: boolean;
}>`
  line-height: 0;
  ${(p) => (p.color ? `color: ${p.isDisabled ? 'inherit' : p.color};` : '')}
`;

const Label = styled('div').withConfig({ shouldForwardProp: (prop) => !['isDisabled'].includes(prop) })<{
  isDisabled: boolean;
}>`
  margin-top: ${(p) => p.theme.spacing(0.5)}px;
  color: ${(p) => (p.isDisabled ? 'inherit' : p.theme.palette.text.secondary)};
  line-height: 16px;
  font-size: 12px;
`;

export interface CommandActionProps {
  color: string;
  icon: React.ReactNode;
  label: string;
  isDisabled: boolean;
  isLoading: boolean;
  onClick: () => void;
}

export const CommandAction: React.FC<CommandActionProps> = ({ color, icon, label, isDisabled, isLoading, onClick }) => (
  <StyledButton size="small" onClick={onClick} disabled={isDisabled || isLoading}>
    <Inner>
      <Icon isDisabled={isDisabled || isLoading} color={color}>
        {isLoading ? <CircularProgress color="inherit" size={24} /> : icon}
      </Icon>
      <Label isDisabled={isDisabled || isLoading}>{label}</Label>
    </Inner>
  </StyledButton>
);
