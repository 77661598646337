import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import { GoogleMap, Marker } from '@react-google-maps/api';
import * as React from 'react';
import locationImg from '../../assets/map/location.png';
import { InputComponentProps, ValueComponentProps } from '../../design-system';
import type { Coordinates } from '../../utils/api';
import { getNestedHelperText, toChangeEvent } from '../../utils/formUtils';
import { GoogleMapLoader, googleMapStyles } from '../GoogleMap';

const mapContainerStyle: React.CSSProperties = {
  height: '160px',
  width: '100%',
};

export const PositionValue: React.FC<ValueComponentProps<Coordinates>> = ({ value: { latitude, longitude } }) => {
  const pos = { lat: Number(latitude), lng: Number(longitude) };

  return (
    <GoogleMapLoader>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={pos}
        zoom={15}
        options={{
          styles: googleMapStyles,
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: false,
        }}
      >
        <Marker position={pos} icon={locationImg} />
      </GoogleMap>
    </GoogleMapLoader>
  );
};

interface PositionInputProps extends InputComponentProps<Coordinates> {
  zoom?: number;
}

export const PositionInput: React.FC<PositionInputProps> = ({
  value: { latitude, longitude },
  zoom = 15,
  onChange,
  disabled,
  error,
  helperText,
}) => {
  const pos = { lat: Number(latitude), lng: Number(longitude) };

  return (
    <Box width="100%">
      <GoogleMapLoader>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={pos}
          zoom={zoom}
          options={{
            styles: googleMapStyles,
            streetViewControl: false,
          }}
        >
          <Marker
            draggable={!disabled}
            icon={locationImg}
            onDragEnd={(e: google.maps.MouseEvent) => {
              if (!e.latLng) {
                return;
              }
              const value = {
                latitude: e.latLng.lat().toFixed(7),
                longitude: e.latLng.lng().toFixed(7),
              };
              onChange('coordinates')(toChangeEvent<Coordinates>(e, value));
            }}
            position={pos}
          >
            <input
              hidden
              onChange={(e) => {
                const { value } = e.target;
                const latLngRegExp = /^(-?\d+(?:\.\d+)?),(-?\d+(?:\.\d+)?)$/;
                const [lat, lng] = value.split(latLngRegExp).slice(1, 3);
                onChange('coordinates')(
                  toChangeEvent<Coordinates>(e, { latitude: lat, longitude: lng }),
                );
              }}
              value={`${latitude},${longitude}`}
            />
          </Marker>
        </GoogleMap>
      </GoogleMapLoader>
      <FormHelperText error={error}>
        {getNestedHelperText(helperText) ?? 'Drag the marker to pinpoint your location.'}
      </FormHelperText>
    </Box>
  );
};
