import { Location } from '../../app/ApiGen';
import { APIErrorAndError } from '../../utils/error';
import { Normalised } from '../../utils/request';

export enum LocationAction {
  GET_CURRENT_LOCATION = '@@location/GET_CURRENT_LOCATION',
  SET_CURRENT_LOCATION = '@@location/SET_CURRENT_LOCATION',
  SET_LOCATION_ERROR = '@@location/SET_LOCATION_ERROR',
  CLEAR_CURRENT_LOCATION = '@@location/CLEAR_CURRENT_LOCATION',
}

export interface LocationState {
  loading: boolean;
  error?: APIErrorAndError;
  current?: Normalised<Location>;
  selectedItemId?: Normalised<Location>['id'];
}
