import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EvStationIcon from '@material-ui/icons/EvStation';
import React from 'react';
import { uid } from 'react-uid';
import styled from 'styled-components';
import { ChargePointAvatar } from '../../../../../../../components/Avatar/ChargePoint';
import { RouterLink } from '../../../../../../../components/Link';
import { Card, List, ListItem } from '../../../../../../../design-system';
import type { LoadBalancingGroup } from '../../../../../../../store/loadBalancingGroup/types';
import { totalChargingCurrent } from '../../../../../../../utils/chargePoints/calculateCurrent';
import { getChargePointImage } from '../../../../../../../utils/chargePoints/getChargePointImage';

const Item = styled('div')`
  padding: ${(p) => p.theme.spacing(1, 0)};
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
`;

const Icon = styled('div')`
  flex: 0 0 auto;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled('div')`
  padding-left: ${(p) => p.theme.spacing(3)}px;
  flex: 1 1 60%;
`;

const Action = styled('div')`
  padding-left: ${(p) => p.theme.spacing(6)}px;
  flex: 0 0 auto;
  line-height: 0;
  svg {
    color: ${(p) => p.theme.palette.text.secondary};
  }
`;

const ChargePointItem: React.FC<{ name: string; vendor?: string; model?: string; chargingCurrent: number }> = ({
  name,
  vendor,
  model,
  chargingCurrent,
}) => (
  <Item>
    <Icon>
      <ChargePointAvatar src={vendor && model ? getChargePointImage(vendor, model) : undefined}>
        <EvStationIcon />
      </ChargePointAvatar>
    </Icon>
    <Title>
      <Typography variant="body2">{name}</Typography>
      <Typography variant="body2" color="textSecondary">
        {chargingCurrent && `Charging at: ${chargingCurrent}A`}
      </Typography>
    </Title>
    <Action>
      <ChevronRightIcon />
    </Action>
  </Item>
);

export const ChargePoints: React.FC<{ loadBalancingGroup: LoadBalancingGroup; orgSlug: string }> = ({
  loadBalancingGroup,
  orgSlug,
}) => (
  <Card title="Charge points">
    <List>
      {loadBalancingGroup.chargePoints.map((chargePoint, idx) => {
        const { name, vendor, model } = chargePoint;
        const chargingCurrent = totalChargingCurrent([chargePoint]);
        return (
          <ListItem key={uid(chargePoint, idx)}>
            <RouterLink to={`/organisations/${orgSlug}/charge-points/${chargePoint.id}`}>
              <ChargePointItem name={name} vendor={vendor} model={model} chargingCurrent={chargingCurrent} />
            </RouterLink>
          </ListItem>
        );
      })}
    </List>
  </Card>
);
