import { Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useSnackbar } from 'notistack';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useCommandDataTransfer } from '../../../../../../../../app/ApiGen';
import { Dialog, DialogProps } from '../../../../../../../../components/Dialog';
import { Form, FormField, OnSubmit } from '../../../../../../../../design-system';
import { createSchema } from '../../../../../../../../utils/formUtils';
import { useChargePoint } from '../../../Provider';
import { useHandleError } from './useHandleError';

type DataTransferDialogFields = {
  vendorId: string;
  messageId: string;
  data: string;
};

const initialValues = {
  vendorId: 'Evnex',
  messageId: '',
  data: '',
};

const fields: FormField[] = [
  {
    id: 'vendorId',
    label: 'Vendor ID',
  },
  {
    id: 'messageId',
    label: 'Message ID',
  },
  {
    id: 'data',
    label: 'Data',
  },
];

const StyledAlert = styled(Alert)`
  .MuiAlert-message {
    width: calc(100% - 16px - 22px);
  }
`;

const validationSchema = createSchema<DataTransferDialogFields>({
  vendorId: Yup.string().required('Vendor ID is required'),
  messageId: Yup.string().notRequired(),
  data: Yup.string().notRequired(),
});

const getFormattedResponse = (responseMessage: string | undefined): string => {
  if (responseMessage) {
    return JSON.stringify(JSON.parse(responseMessage), null, 2);
  }
  return '';
};

export const DataTransferDialog: React.FC<DialogProps> = ({ isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { chargePoint } = useChargePoint();

  const { error, mutate } = useCommandDataTransfer({ id: chargePoint?.id ?? '' });

  const [response, setResponse] = React.useState('');

  const clearResponse = () => {
    setResponse('');
    onClose();
  };

  useHandleError({ error, defaultErrorMessage: 'Unable to transfer data' });

  const onSubmit = React.useCallback<OnSubmit<DataTransferDialogFields>>(
    async ({ vendorId, messageId, data }, { setSubmitting }) => {
      try {
        const responseDataTransfer = await mutate({ vendorId, messageId, data });
        const responseMessage = getFormattedResponse(responseDataTransfer.data.message);
        enqueueSnackbar(`Data transfer command ${responseDataTransfer.data.status.toLocaleLowerCase()}`);
        setResponse(responseMessage);
      } finally {
        setSubmitting(false);
      }
    },
    [mutate, enqueueSnackbar, setResponse],
  );

  return (
    <Dialog isOpen={isOpen} onClose={clearResponse} title="Data transfer">
      {response.length > 0 && (
        <Box mx={2} mb={2}>
          <StyledAlert severity="info">
            <AlertTitle>Command response</AlertTitle>
            <pre style={{ margin: 0, overflow: 'auto' }}>{response}</pre>
          </StyledAlert>
        </Box>
      )}
      <Form<DataTransferDialogFields>
        isEditing
        fields={fields}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        labels={{ save: 'Send command' }}
      />
    </Dialog>
  );
};
