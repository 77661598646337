import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { RouterLink } from '../../../../components/Link';
import { MailboxGraphic } from '../../../../design-system';

export interface CodeSentProps {
  sentToEmail?: string;
}

export const CodeSent: React.FC<CodeSentProps> = ({ sentToEmail }: CodeSentProps) => (
  <Fade in>
    <div>
      <MailboxGraphic textAlign="center" svgProps={{ style: { maxWidth: '60%', height: '100%' } }} />
      <Box mt={3} textAlign="center">
        <Typography>
          Check your <strong>{sentToEmail}</strong> inbox for instructions from us on how to verify your account.
        </Typography>
      </Box>
      <Box mt={2} textAlign="center">
        <RouterLink to="/auth/signin">Go to sign in screen</RouterLink>
      </Box>
    </div>
  </Fade>
);
