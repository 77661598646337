import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import QRCodeCanvas from 'qrcode.react';
import * as React from 'react';
import { useAuth } from '../../../../app/AuthProvider';
import { Card, List, ListItem } from '../../../../design-system';
import { SubmitCodeForm, SubmitCodeFormProps } from './SubmitCodeForm';

export const MfaSetup: React.FC = () => {
  const [user, setUser] = React.useState<CognitoUser | undefined>(undefined);
  const [token, setToken] = React.useState<string | undefined>(undefined);
  const [mfaEnabled, setMfaEnabled] = React.useState<boolean | undefined>(undefined);

  const { signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((cognitoUser: CognitoUser) => {
        setUser(cognitoUser);
      })
      .catch((e) => {
        console.error('Error getting current user', e);
      });
  }, []);

  React.useEffect(() => {
    if (!user) {
      setMfaEnabled(undefined);
      return;
    }

    user.getUserData((err, userData) => {
      if (err || !userData) {
        console.error('CognitoUser.getUserData() error:', err);
        return;
      }

      // NB: The UserData interface definition from Amplify is incorrect: UserMFASettingList is not present if the user
      // does not have MFA enabled.
      const userMfaSettings = userData.UserMFASettingList ?? [];
      setMfaEnabled(userMfaSettings.length > 0);
    });
  }, [user]);

  const generateToken = React.useCallback(() => {
    Auth.setupTOTP(user)
      .then((secretCode) => {
        const username = user?.getUsername();
        const issuer = 'Evnex';
        if (username) {
          setToken(`otpauth://totp/${username}?secret=${secretCode}&issuer=${issuer}`);
        }
      })
      .catch((e) => console.log(e));
  }, [user, setToken]);

  const setupMFA = React.useCallback<SubmitCodeFormProps['onSubmit']>(
    ({ code }, { setFieldError, setSubmitting }) => {
      Auth.verifyTotpToken(user, code)
        .then(() => {
          Auth.setPreferredMFA(user, 'TOTP')
            .then(() => {
              enqueueSnackbar('MFA enabled', { variant: 'success' });
              signOut().catch(() => {});
            })
            .catch((e) => {
              const error = e as Error;
              setFieldError('code', error.message);
            });
        })
        .catch((e) => {
          const error = e as Error;
          setFieldError('code', error.message);
        })
        .finally(() => setSubmitting(false));
    },
    [enqueueSnackbar, signOut, user],
  );

  return (
    <Card title="Configure MFA">
      <Box p={2} pt={0}>
        {mfaEnabled ? (
          <Alert severity="success">MFA enabled</Alert>
        ) : (
          <>
            <List>
              <ListItem>
                <Button variant="outlined" size="small" onClick={generateToken}>
                  Generate QR Code
                </Button>
              </ListItem>
              <ListItem>{token && <QRCodeCanvas value={token} />}</ListItem>
            </List>
            <SubmitCodeForm onSubmit={setupMFA} />
          </>
        )}
      </Box>
    </Card>
  );
};
