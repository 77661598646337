import TextField from '@material-ui/core/TextField';
import { TimePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import * as React from 'react';
import { InputComponentProps, ValueComponentProps } from '../../design-system';
import { TimeFormat } from '../../utils/dateFormat';
import { getNestedHelperText } from '../../utils/formUtils';

export const TimeValue: React.FC<ValueComponentProps<string>> = ({ value }) => {
  if (!value) {
    return null;
  }

  return <>{moment(value).format(TimeFormat.WithSeconds)}</>;
};

export const TimeInput: React.FC<InputComponentProps<string>> = ({
  name,
  disabled,
  error,
  helperText,
  value,
  onBlur,
  onChange,
}) => (
  <TimePicker
    fullWidth
    TextFieldComponent={(props) => (
      <TextField
        id={name}
        margin="dense"
        fullWidth
        style={{ margin: 0 }}
        disabled={disabled}
        error={error}
        helperText={getNestedHelperText(helperText)}
        {...props}
        variant="outlined"
      />
    )}
    value={value}
    onBlur={onBlur}
    onChange={(date) => onChange(name)(moment(date).toISOString())}
  />
);
