import * as React from 'react';
import { StyledListItem } from './styled';

export interface ListItemProps {
  onClick?: () => void;
}

export const ListItem: React.FC<ListItemProps> = ({ children, onClick }) => {
  const onKeyPress = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => (onClick && event.key === 'Enter' ? onClick() : undefined),
    [onClick],
  );

  return (
    <StyledListItem
      onClick={onClick}
      onKeyPress={onKeyPress}
      role={onClick ? 'button' : undefined}
      tabIndex={onClick ? 0 : undefined}
    >
      {children}
    </StyledListItem>
  );
};
