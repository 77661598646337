import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { Loading, Section, Workspace } from '../../../../../../design-system';
import { useChargePoint } from '../Provider';
import { DayScheduleForm } from './components/DayScheduleForm';

const ChargeSchedulingView: React.FC = () => {
  const { chargePoint, error, loading: chargePointLoading, refetch } = useChargePoint();

  if (chargePointLoading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (error || !chargePoint) {
    return <DataErrorHandler description="Unable to load charge point" error={error} refetch={refetch} />;
  }

  return (
    <Workspace maxWidth="lg">
      <Section title="Charge scheduling" description="Set times that a charge point will charge between.">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DayScheduleForm chargePoint={chargePoint} refetch={refetch} />
          </Grid>
        </Grid>
      </Section>
    </Workspace>
  );
};

export default ChargeSchedulingView;
