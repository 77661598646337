import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { json2csvAsync } from 'json-2-csv';
import React from 'react';
import { Organisation, ResponseGetOrganisationChargePoints } from '../../../app/ApiGen';
import { DangerButton } from '../../../components/DangerButton';
import { DataErrorHandler } from '../../../components/ErrorHandler';
import { Card, Section } from '../../../design-system';
import { Normalised, request } from '../../../utils/request';

type ReportData = {
  organisation: string;
  chargePoints: {
    ocppId?: string;
    lastHeard?: string;
    model?: string;
  }[];
  chargePoints3rdPartyCount: number;
  chargePointsEvnexCount: number;
}[];

const getOrganisationChargePointTotals = async (
  organisations: Normalised<Organisation>[],
  firstDayOfLastMonth: string,
): Promise<ReportData> => {
  const values = [];
  /* eslint no-await-in-loop: "off", "no-restricted-syntax": "off"
       -- Fire off the charge point requests in sequence to avoid parallel requests to the server */
  for (const org of organisations) {
    const orgSlug: string = org.slug;
    const {
      data: { items },
    } = await request<ResponseGetOrganisationChargePoints>(`/v2/apps/organisations/${orgSlug}/charge-points`, {
      method: 'GET',
    });

    const { chargePointsEvnexCount, chargePoints3rdPartyCount } = items.reduce(
      (acc, chargePoint) => {
        const isActiveChargePoint =
          chargePoint.networkStatus !== 'NEVER_CONNECTED' && chargePoint.networkStatusUpdatedDate > firstDayOfLastMonth;
        const isEvnexChargePoint = chargePoint.details.vendor === 'Evnex' || chargePoint.details.vendor === 'evnex';

        const isActiveEvnexChargePoint = isActiveChargePoint && isEvnexChargePoint;
        const isActiveNonEvnexChargePoint = isActiveChargePoint && !isEvnexChargePoint;

        return {
          chargePointsEvnexCount: isActiveEvnexChargePoint
            ? acc.chargePointsEvnexCount + 1
            : acc.chargePointsEvnexCount,
          chargePoints3rdPartyCount: isActiveNonEvnexChargePoint
            ? acc.chargePoints3rdPartyCount + 1
            : acc.chargePoints3rdPartyCount,
        };
      },
      {
        chargePointsEvnexCount: 0,
        chargePoints3rdPartyCount: 0,
      },
    );

    const chargePoints = items.map((cpt) => ({
      ocppId: cpt.ocppChargePointId,
      lastHeard: cpt.lastHeard,
      model: cpt.details.vendor,
      serial: cpt.serial,
    }));

    values.push({
      organisation: org.name,
      chargePoints,
      chargePoints3rdPartyCount,
      chargePointsEvnexCount,
    });
  }
  return values;
};

const downloadCSV = async (reportData: ReportData) => {
  const dateToday = new Date().toDateString();
  const csv = await json2csvAsync(reportData, {
    expandArrayObjects: true,
  });
  const url = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv;charset=utf-8;' }));
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', `organisation-summary-${dateToday}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const OrganisationReportButton: React.FC<{ organisations: Normalised<Organisation>[] }> = ({ organisations }) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState<Error | undefined>(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const date = new Date();
  const firstDayOfLastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString();

  const downloadReport = React.useCallback(() => {
    setLoading(true);
    setError(undefined);
    getOrganisationChargePointTotals(organisations, firstDayOfLastMonth)
      .then(async (reportData) => {
        setLoading(false);
        await downloadCSV(reportData);
        handleClose();
      })
      .catch((e: Error) => {
        setLoading(false);
        setError(e);
      });
  }, [firstDayOfLastMonth, organisations]);

  return (
    <Card>
      <Box p={2}>
        <Section title="Organisations Report">
          <DangerButton loading={loading} disabled={loading} onClick={handleClickOpen} label="Download" />
        </Section>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Generate Organisations Monthly Report</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This is expensive to calculate and it will take a while for the download to be ready. The report will be
            generated for data from the first date of the previous month to today&apos;s date. Please use sparingly.
          </DialogContentText>
          {error && (
            <DataErrorHandler
              error={error}
              description="Unable to download report"
              refetch={downloadReport}
              type="embedded"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" variant="contained" disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <DangerButton loading={loading} label="Generate report" onClick={downloadReport} />
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default OrganisationReportButton;
