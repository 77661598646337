/* eslint import/no-extraneous-dependencies: "off" -- Use the developmentOnly version of redux-devtools-extension */
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { createRootReducer, rootSaga } from './root';
import { ApplicationState } from './types';

export const configureStore = (): Store<ApplicationState> => {
  const composeEnhancers = composeWithDevTools({});

  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];

  const store = createStore(createRootReducer(), composeEnhancers(applyMiddleware(...middlewares)));

  sagaMiddleware.run(rootSaga);

  return store;
};
