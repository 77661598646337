import { Chart as Chartjs, ChartConfiguration, ChartData } from 'chart.js';
import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled('div')``;

export interface ChartProps {
  config: Omit<ChartConfiguration, 'data'>;
  data: ChartData;
}

export const Chart: React.FC<ChartProps> = ({ config, data }) => {
  const chartRef = React.useRef<HTMLCanvasElement | null>(null);

  const [chartInstance, setChartInstance] = React.useState<Chartjs | null>(null);

  // Create chart
  React.useEffect(() => {
    if (chartRef.current && !chartInstance) {
      const chart = new Chartjs(chartRef.current, { ...config, data });
      setChartInstance(chart);
    }
  }, [config, data, chartRef, chartInstance]);

  // Update chart on data change
  React.useEffect(() => {
    if (chartInstance?.data) {
      chartInstance.data = data;
      chartInstance.update();
    }
  }, [chartInstance, data]);

  // Destroy chart on unmount
  React.useEffect(
    () => () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    },
    [chartInstance],
  );

  return (
    <Wrapper>
      <canvas ref={chartRef} />
    </Wrapper>
  );
};
