import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card } from '../../../../design-system';

const PasswordCard: React.FC = () => (
  <Card title="Password">
    <Box p={2} pt={0}>
      <Typography paragraph>Use the button below to change your password</Typography>
      <Button variant="outlined" size="small" component={RouterLink} to="/user/change-password">
        Change password
      </Button>
    </Box>
  </Card>
);

export default PasswordCard;
