import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import { ConnectorsValue } from '../../../../../../components/CustomFields/Connectors/Value';
import { WizardOcppChargePointId } from '../../../../../../components/CustomFields/PrefixText';
import { SwitchValue } from '../../../../../../components/CustomFields/Switch';
import { FormReadonly, Section, useWizard, ValueComponentProps } from '../../../../../../design-system';
import { FormFields } from '../../../../../../utils/formUtils';
import { locationField } from '../fields';
import { CreateThirdPartyChargePointWizardState, DetailsOther, LocationWizardStep } from '../types';

const detailsFields: FormFields<
  Pick<DetailsOther, 'name' | 'ocppChargePointId' | 'referenceId'> & LocationWizardStep
> = {
  name: { id: 'name', label: 'Name' },
  location: locationField,
  ocppChargePointId: {
    id: 'ocppChargePointId',
    label: 'OCPP Charge Point ID',
    valueComponent: ({ value: { prefix, suffix } }: ValueComponentProps<WizardOcppChargePointId>) => (
      <>{`${prefix}-${suffix}`}</>
    ),
  },
  referenceId: { id: 'referenceId', label: 'Reference ID' },
};

export const ConfirmationStepOther: React.FC = () => {
  const { state: untypedState, prevStep, nextStep, isLoading } = useWizard();
  const state = untypedState as CreateThirdPartyChargePointWizardState;

  return (
    <>
      <Section title="Charge point details">
        <FormReadonly<DetailsOther> fields={Object.values(detailsFields)} initialValues={state} />
      </Section>
      <Section title="Connectors">
        <FormReadonly<DetailsOther>
          fields={[{ id: 'connectors', label: 'Connectors', valueComponent: ConnectorsValue }]}
          initialValues={state}
        />
      </Section>
      <Section pt={3} title="Configuration">
        <FormReadonly
          fields={[{ id: 'authorizationRequired', label: 'Authorization required', valueComponent: SwitchValue }]}
          initialValues={state}
        />
      </Section>
      <Box pt={2} display="flex">
        <Button variant="contained" onClick={prevStep} disabled={isLoading}>
          Back
        </Button>
        <Box mr="auto" />
        <Button color="primary" variant="contained" onClick={nextStep} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Create charge point'}
        </Button>
      </Box>
    </>
  );
};
