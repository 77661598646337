import { useSnackbar } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { EnterpriseApiPermissions, useGetOrganisation, useUpdateOrganisation } from '../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import { OrganisationTier } from '../../../../../app/enums';
import { SwitchInput, SwitchValue } from '../../../../../components/CustomFields/Switch';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { errorSnackbar } from '../../../../../components/ErrorSnackbar';
import { Card, Form, Loading, OnSubmit, ValueComponentProps } from '../../../../../design-system';
import { fullStateSelector } from '../../../../../store/root';
import { SubState } from '../../../../../store/types';
import { createSchema } from '../../../../../utils/formUtils';
import { normalise } from '../../../../../utils/request';

type FormApiPermissions = Required<EnterpriseApiPermissions>;

const defaultApiPermissions = {
  allChargePointsList: false,
  chargePointRead: false,
  chargePointSessionsList: false,
  allSessionsList: false,
  chargePointMeterValuesList: false,
  chargePointLoadManagementUpdate: false,
};

const validationSchema = createSchema<FormApiPermissions>({
  allChargePointsList: Yup.boolean().required(),
  chargePointRead: Yup.boolean().required(),
  chargePointLoadManagementUpdate: Yup.boolean().required(),
  chargePointSessionsList: Yup.boolean().required(),
  chargePointMeterValuesList: Yup.boolean().required(),
  allSessionsList: Yup.boolean().required(),
});

const Labels: Record<keyof FormApiPermissions, string> = {
  allChargePointsList: 'List all charge points',
  allSessionsList: 'List all sessions',
  chargePointLoadManagementUpdate: 'Update charge point load management',
  chargePointMeterValuesList: 'List  meter values',
  chargePointRead: 'Read charge point',
  chargePointSessionsList: 'List charge point sessions',
};

const FetchContext: React.FC<{ organisation: SubState<'organisation'> }> = ({ organisation }) => {
  const { mutate: updateOrg } = useUpdateOrganisation({ orgId: organisation.id });

  const { data, error, loading, refetch } = useGetOrganisation({
    orgSlug: organisation.slug,
  });
  const organisationData = data ? normalise(data) : undefined;
  const apiPermissions = organisationData?.apiPermissions ?? {};

  const { isEvnexAdmin } = useSelector(fullStateSelector);

  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const initialValues = Object.keys(apiPermissions).reduce<FormApiPermissions>((acc, k) => {
    const key = k as keyof EnterpriseApiPermissions;
    return { ...acc, [key]: apiPermissions[key] ?? false };
  }, defaultApiPermissions);

  const formFields = Object.keys(apiPermissions).reduce((acc, k) => {
    const key = k as keyof EnterpriseApiPermissions;
    return {
      ...acc,
      [key]: {
        id: key,
        label: Labels[key],
        inputComponent: SwitchInput,
        valueComponent: ({ value }: ValueComponentProps<boolean>) => (
          <SwitchValue value={value} labels={{ true: 'Enabled', false: 'Disabled' }} />
        ),
      },
    };
  }, {});

  const onSave = React.useCallback<OnSubmit<FormApiPermissions>>(
    async (formPermissions) => {
      try {
        await updateOrg({
          data: {
            attributes: {
              apiPermissions: formPermissions,
            },
            type: 'organisations',
            id: organisation.id,
          },
        });
        enqueueSnackbar('API Permissions updated successfully', { variant: 'success' });
        setIsEditing(false);
        await refetch();
      } catch (e) {
        errorSnackbar('Error: save failed, please try again later', closeSnackbar, enqueueSnackbar, e);
      }
    },
    [organisation.id, updateOrg, enqueueSnackbar, closeSnackbar, refetch],
  );

  if (error) {
    return (
      <DataErrorHandler
        description={error.status === 403 ? 'Not authorized to view this content' : 'Unable to load API permissions'}
        error={error}
        refetch={refetch}
        type="embedded"
      />
    );
  }

  return (
    <Card title="API Permissions">
      <Form<FormApiPermissions>
        fields={Object.values(formFields)}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSave}
        isLoading={loading}
        isEditing={isEvnexAdmin ? isEditing : false}
        setIsEditing={isEvnexAdmin ? setIsEditing : undefined}
      />
    </Card>
  );
};

export const APIPermissions: React.FC = () => {
  const { organisation, loading } = useCurrentOrganisation();
  if (organisation?.tier !== OrganisationTier.Enterprise) {
    return <></>;
  }

  if (loading || !organisation) {
    return <Loading />;
  }

  return <FetchContext organisation={organisation} />;
};
