import * as React from 'react';
import { useNavigation } from '../../Provider';
import { StyledFooterSection } from './styled';

export interface FooterSectionProps {
  children: (props: { isCollapsed: boolean }) => React.ReactNode;
}

const FooterSection: React.FC<FooterSectionProps> = ({ children }) => {
  const { isCollapsed } = useNavigation();

  return <StyledFooterSection isCollapsed={isCollapsed}>{children({ isCollapsed })}</StyledFooterSection>;
};

FooterSection.displayName = 'FooterSection';

export default FooterSection;
