import { LegacyUser } from '../../../app/ApiGen';
import { labelsFor, UserRole } from '../../../app/enums';

export const getRoleInOrganisation = (user: LegacyUser | undefined, organisationId: string) => {
  if (typeof user === 'undefined') {
    return '';
  }
  const userOrganisation = user.organisations.find((o) => o.id === organisationId);

  if (typeof userOrganisation === 'undefined' || typeof userOrganisation.role === 'undefined') {
    return '';
  }
  return labelsFor(UserRole)[userOrganisation.role];
};
