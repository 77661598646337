import { useSnackbar } from 'notistack';
import React from 'react';
import { useSelector, useStore } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { action } from 'typesafe-actions';
import { useChallengeResponse } from '../app/ApiGen';
import { AppLoader } from '../app/AppLoader';
import { AuthenticatedUserProvider, useAuthenticatedUser } from '../app/AuthenticatedUserProvider';
import { useAuth } from '../app/AuthProvider';
import { DataErrorHandler } from '../components/ErrorHandler';
import { errorSnackbar } from '../components/ErrorSnackbar';
import { Loading, Workspace } from '../design-system';
import { AuthenticatedUserAction } from '../store/authenticatedUser/types';
import { selectDerivedState } from '../store/root';
import { AdminView } from './Admin';
import AuthView from './Auth';
import { Layout } from './Auth/components/Layout';
import { MfaChallengeForm, MfaChallengeFormProps } from './Auth/components/MfaChallengeForm';
import AuthenticatedUserView from './AuthenticatedUser';
import { InstallerView } from './Installer';
import OrganisationsView from './Organisations';

const Authenticated: React.FC = ({ children }) => {
  const store = useStore();
  const { authenticatedUser: user, error: loadingUserError, loading, mfaRequired, refetch } = useAuthenticatedUser();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { error: mfaError, mutate: submitMfaCode } = useChallengeResponse({});
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  const DefaultRoute: React.FC = () => <Redirect to={{ pathname: '/', state: { referrer: pathname } }} />;

  React.useEffect(() => {
    if (mfaError) {
      errorSnackbar('MFA code invalid', closeSnackbar, enqueueSnackbar, mfaError);
    }
  }, [closeSnackbar, enqueueSnackbar, mfaError]);

  const onMfaChallenge = React.useCallback<MfaChallengeFormProps['onSubmit']>(
    async ({ code }, { setSubmitting }) => {
      try {
        await submitMfaCode({ code });
        store.dispatch(action(AuthenticatedUserAction.CLEAR_AUTHENTICATED_USER_NEEDS_MFA));
        enqueueSnackbar('MFA complete', { variant: 'success' });
      } finally {
        setSubmitting(false);
      }
    },
    [submitMfaCode, store, enqueueSnackbar],
  );

  if (!isAuthenticated) {
    return <DefaultRoute />;
  }

  if (loading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (loadingUserError || !user) {
    return <DataErrorHandler description="Unable to load user" refetch={refetch} error={loadingUserError} />;
  }

  if (mfaRequired) {
    return (
      <Layout title="Multi-Factor Authentication">
        <MfaChallengeForm onSubmit={onMfaChallenge} />
      </Layout>
    );
  }

  return <AuthenticatedUserProvider>{children}</AuthenticatedUserProvider>;
};

const Views: React.FC = () => {
  const { isEvnexAdmin, isInstaller } = useSelector(selectDerivedState);

  return (
    <Switch>
      <Route path="/auth">
        <AuthView />
      </Route>
      {isEvnexAdmin && (
        <Route path="/admin">
          <Authenticated>
            <AdminView />
          </Authenticated>
        </Route>
      )}
      {isInstaller && !isEvnexAdmin && (
        <Route path="/installer">
          <Authenticated>
            <InstallerView />
          </Authenticated>
        </Route>
      )}
      <Route path="/organisations">
        <Authenticated>
          <OrganisationsView />
        </Authenticated>
      </Route>
      <Route path="/user">
        <Authenticated>
          <AuthenticatedUserView />
        </Authenticated>
      </Route>
      <Route>
        <AppLoader />
      </Route>
    </Switch>
  );
};

export default Views;
