import { Membership, User } from '../../app/ApiGen';
import { Normalised } from '../../utils/request';

export type NormalisedMembership = Omit<Normalised<Membership>, 'user'>;

type NormalisedUser = Omit<Normalised<User>, 'memberships'> & {
  memberships: (Partial<NormalisedMembership> & { id: string })[];
};

export enum UserAction {
  GET_CURRENT_USER = '@@user/GET_CURRENT_USER',
  SET_CURRENT_USER = '@@user/SET_CURRENT_USER',
  SET_USER_ERROR = '@@user/SET_USER_ERROR',
  CLEAR_CURRENT_USER = '@@user/CLEAR_CURRENT_USER',
}

export interface UserState {
  loading: boolean;
  error?: Error;
  current?: NormalisedUser;
}
