import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import { Card, DataTable } from '../../../../../../../design-system';
import { fullStateSelector } from '../../../../../../../store/root';
import { SubState } from '../../../../../../../store/types';
import { Cost } from '../../../../../../../utils/api';
import { TimeFormat } from '../../../../../../../utils/dateFormat';
import { getDailyCostSchedule } from '../utils';

type TimeOfUseScheduleProps = {
  chargePoint: SubState<'chargePoint'>;
};

const noDataMessage = 'No electricity costs have been set';

export const TimeOfUsePricingSchedule: React.VFC<TimeOfUseScheduleProps> = ({ chargePoint }) => {
  const { isEvnexAdmin } = useSelector(fullStateSelector);

  const { electricityCost: costSchedule } = chargePoint;

  if (!costSchedule) {
    return <Typography variant="body2">{noDataMessage}</Typography>;
  }

  return (
    <Card title="Time of Use pricing">
      <Box p={2} pt={0}>
        {isEvnexAdmin ? (
          <Box component="pre">{JSON.stringify(costSchedule, null, 2)}</Box>
        ) : (
          <DataTable<Cost>
            localization={{ body: { emptyDataSourceMessage: noDataMessage } }}
            columns={[
              {
                field: 'start',
                title: 'Start time',
                defaultSort: 'asc',
                render: ({ start }: Cost) => (
                  <>{moment().startOf('week').add(start, 'seconds').format(TimeFormat.WithSeconds)}</>
                ),
              },
              {
                field: 'cost',
                title: `Electricity cost (${costSchedule.currency})`,
                render: ({ cost }: Cost) => `$${cost}`,
              },
            ]}
            data={_.cloneDeep(getDailyCostSchedule(costSchedule.costs))}
          />
        )}
      </Box>
    </Card>
  );
};
