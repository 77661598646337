import { differenceBy } from 'lodash';
import { InstallerDetails } from '../../../../utils/jobs/installer';

export function getUpdatedInstallers({
  originalInstallers,
  selectedInstallers,
}: {
  originalInstallers: InstallerDetails[];
  selectedInstallers: InstallerDetails[];
}): { installersToAssign: InstallerDetails[]; installersToUnassign: InstallerDetails[] } {
  const installersToAssign = differenceBy(selectedInstallers, originalInstallers, 'id');
  const installersToUnassign = differenceBy(originalInstallers, selectedInstallers, 'id');
  return { installersToAssign, installersToUnassign };
}
