import * as React from 'react';
import { useNavigation } from '../../Provider';
import { ProductId } from '../../types';
import { StyledProductNavigation } from './styled';

export interface ProductNavigationProps {
  productId?: ProductId;
}

const ProductNavigation: React.FC<ProductNavigationProps> = ({ children, productId }) => {
  const { isCollapsed } = useNavigation();

  return (
    <StyledProductNavigation isCollapsed={isCollapsed} productId={productId}>
      {children}
    </StyledProductNavigation>
  );
};

ProductNavigation.displayName = 'ProductNavigation';

export default ProductNavigation;
