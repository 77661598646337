import React from 'react';
import { Button } from '@material-ui/core';
import { Chart } from 'chart.js';

type ResetChartZoomProps<T extends string> = {
  chartIds: Record<T, string>;
  minXValue?: string;
  maxXValue?: string;
};

function resetZoom<T extends string>({ chartIds, minXValue, maxXValue }: ResetChartZoomProps<T>): void {
  Object.keys(chartIds).forEach((id) => {
    const chartId = chartIds[id as T];
    const chart = Chart.getChart(chartId);
    if (chart?.config.options?.scales?.x) {
      chart.config.options.scales.x.min = minXValue;
      chart.config.options.scales.x.max = maxXValue;
      chart.update();
    }
  });
}

export function ResetZoomButton<T extends string>({
  chartIds,
  minXValue,
  maxXValue,
}: ResetChartZoomProps<T>): React.ReactElement {
  return (
    <Button variant="outlined" color="inherit" onClick={() => resetZoom<T>({ chartIds, minXValue, maxXValue })}>
      Reset zoom
    </Button>
  );
}
