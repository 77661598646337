import { action, Action, PayloadAction } from 'typesafe-actions';
import { CardAction } from './types';
import type { Token } from '../../app/ApiGen';
import { APIErrorAndError } from '../../utils/error';

export function getCurrentCardAction(
  selectedItemId: string,
  organisationSlug: string,
): PayloadAction<CardAction.GET_CURRENT_CARD, { selectedItemId: string; organisationSlug: string }> {
  return action(CardAction.GET_CURRENT_CARD, { selectedItemId, organisationSlug });
}

export function setCurrentCardAction(card: Token): PayloadAction<CardAction.SET_CURRENT_CARD, { card: Token }> {
  return action(CardAction.SET_CURRENT_CARD, { card });
}

export function setCardErrorAction(
  error: APIErrorAndError,
  selectedItemId: string,
): PayloadAction<CardAction.SET_CARD_ERROR, { error: APIErrorAndError; selectedItemId: string }> {
  return action(CardAction.SET_CARD_ERROR, { error, selectedItemId });
}

export function clearCurrentCardAction(): Action<CardAction.CLEAR_CURRENT_CARD> {
  return action(CardAction.CLEAR_CURRENT_CARD);
}
