import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { InputComponentProps, ValueComponentProps } from '../../design-system';
import { currencyOptions } from '../../utils/electricityCost';
import { AutocompleteContents, AutocompleteWrapper, renderAutocompleteInput } from './SimpleAutocomplete';

export const CurrencyValue: React.FC<ValueComponentProps<string>> = ({ value }: AutocompleteContents) => {
  const option = currencyOptions.find(({ value: key }) => key === value);
  return <>{option ? option.label : value}</>;
};

export const CurrencyInput: React.FC<InputComponentProps<string>> = ({
  value,
  name,
  onBlur,
  onChange,
  disabled,
  error,
  helperText,
}: InputComponentProps) => (
  <AutocompleteWrapper>
    <Autocomplete
      disableClearable
      options={currencyOptions}
      getOptionLabel={(option) => option.label}
      value={currencyOptions.find(({ value: key }) => key === value)}
      renderInput={renderAutocompleteInput(disabled, error, helperText)}
      disabled={disabled}
      onBlur={onBlur}
      onChange={(_, currency: AutocompleteContents) => {
        // Note: this also blocks the user from manually clearing the field
        if (currency?.value) {
          onChange(name)(currency.value);
        }
      }}
      style={{ width: '100%' }}
    />
  </AutocompleteWrapper>
);
