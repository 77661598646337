import type { LegacyUser } from '../../app/ApiGen';

export enum AuthenticatedUserAction {
  GET_AUTHENTICATED_USER = '@@user/GET_AUTHENTICATED_USER',
  SET_AUTHENTICATED_USER = '@@user/SET_AUTHENTICATED_USER',
  SET_AUTHENTICATED_USER_ERROR = '@@user/SET_AUTHENTICATED_USER_ERROR',
  CLEAR_AUTHENTICATED_USER = '@@user/CLEAR_AUTHENTICATED_USER',
  CLEAR_AUTHENTICATED_USER_DONE = '@@user/CLEAR_AUTHENTICATED_USER_DONE',
  SET_AUTHENTICATED_USER_NEEDS_MFA = '@@user/SET_AUTHENTICATED_USER_NEEDS_MFA',
  CLEAR_AUTHENTICATED_USER_NEEDS_MFA = '@@user/CLEAR_AUTHENTICATED_USER_NEEDS_MFA',
}

export interface AuthenticatedUserState {
  loading: boolean;
  mfaRequired: boolean;
  error?: Error;
  current?: LegacyUser;
}
