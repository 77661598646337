import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { Form as FormikForm, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { UserOrganisation } from '../../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../../app/CurrentOrganisationProvider';
import { ConnectorsInput } from '../../../../../../components/CustomFields/Connectors';
import {
  OCPP_CHARGE_POINT_ID_MAX_LENGTH,
  PrefixText,
  PREFIX_NAME_MAX_LENGTH,
  WizardOcppChargePointId,
} from '../../../../../../components/CustomFields/PrefixText';
import { SwitchInput } from '../../../../../../components/CustomFields/Switch';
import { Dialog } from '../../../../../../components/Dialog';
import {
  ChargePointSerialGraphic,
  FormEditable,
  InputComponentProps,
  OnSubmit,
  Section,
  useWizard,
} from '../../../../../../design-system';
import { ConnectorCreate } from '../../../../../../utils/api';
import { createSchema, FieldDefinitions } from '../../../../../../utils/formUtils';
import type { DetailsOther } from '../types';

const DialogContext = React.createContext({ toggle: () => {} });

const detailFields: FieldDefinitions<DetailsOther> = [
  {
    id: 'name',
    label: 'Name',
    inputProps: {
      helperText: 'A recognisable name for this charge point, e.g. "Garage charger" or "Parking spot #1"',
    },
  },
  {
    id: 'ocppChargePointId',
    label: 'OCPP Charge Point ID',
    inputComponent: ({
      name,
      disabled,
      error,
      helperText,
      value,
      onBlur,
      onChange,
    }: InputComponentProps<WizardOcppChargePointId>) => (
      <PrefixText
        name={name}
        disabled={disabled}
        error={error}
        helperText={helperText}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
    ),
    inputProps: {
      helperText: 'OCPP Charge Point ID',
    },
  },
  {
    id: 'referenceId',
    label: 'Reference ID',
    inputProps: {
      helperText: '(Optional) A custom identifier for this charge point',
    },
  },
];

const configurationFields: FieldDefinitions<DetailsOther> = [
  {
    id: 'authorizationRequired',
    label: 'Authorization required',
    inputComponent: SwitchInput,
    inputProps: {
      helperText: 'Whether a valid RFID card must be swiped before a charging session can begin',
    },
  },
];

const connectorFields: FieldDefinitions<DetailsOther> = [
  {
    id: 'connectors',
    label: 'Connectors',
    inputComponent: ConnectorsInput,
  },
];

/* eslint no-template-curly-in-string: "off" --- Yup messages can be customised using template curlies in strings. */
const validationSchema = createSchema<DetailsOther>({
  name: Yup.string()
    .trim()
    .required('Name is required')
    .min(3, 'Name must be at least ${min} characters')
    .max(80, 'Name must be at most ${max} characters'),
  ocppChargePointId: Yup.object()
    .shape<WizardOcppChargePointId>({
      prefix: Yup.string().trim().required('OCPP Charge Point ID prefix is required'),
      suffix: Yup.string()
        .min(3, 'OCPP Charge Point ID must be at least ${min} characters')
        .max(
          OCPP_CHARGE_POINT_ID_MAX_LENGTH - PREFIX_NAME_MAX_LENGTH,
          `OCPP Charge ID must be at most ${OCPP_CHARGE_POINT_ID_MAX_LENGTH} characters`,
        )
        .matches(
          /^[A-Z0-9-]*$/,
          'OCPP Charge Point Id may only include upper case letters, numbers, and "-" characters',
        )
        .required('OCPP Charge Point ID is required'),
    })
    .required(),
  referenceId: Yup.string()
    .trim()
    .min(1, 'Reference ID must be at least 1 character')
    .max(80, 'Reference ID must be at most 80 characters')
    .matches(/^[a-zA-Z0-9-_. ]*$/, 'Reference ID may only include numbers, letters, "-", "_", "." and " " characters'),
  authorizationRequired: Yup.boolean().required('Please indicate whether authorisation is required to start charging'),
  connectors: Yup.array<ConnectorCreate>()
    .min(1, 'At least one connector is required')
    .required('Connector is required'),
});

const getPrefix = (organisation?: UserOrganisation): string => {
  if (organisation) {
    const { namespacePrefix } = organisation;
    if (namespacePrefix !== '') {
      return namespacePrefix;
    }
  }
  return 'EVNEX';
};

export const DetailsOtherStep: React.FC = () => {
  const { state, setState, prevStep, nextStep } = useWizard();
  const { organisation } = useCurrentOrganisation();

  const onSubmit = React.useCallback<OnSubmit<DetailsOther>>(
    ({ connectors, name, ocppChargePointId, authorizationRequired, referenceId }) => {
      setState({
        connectors,
        name,
        ocppChargePointId,
        authorizationRequired,
        referenceId,
      });
      nextStep();
    },
    [nextStep, setState],
  );

  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = React.useCallback(() => setIsOpen((prev) => !prev), []);

  const initialValues: DetailsOther = {
    name: '',
    referenceId: '',
    authorizationRequired: false,
    ocppChargePointId: {
      prefix: getPrefix(organisation),
      suffix: '',
    },
    connectors: [],
  };

  return (
    <DialogContext.Provider value={{ toggle }}>
      <Formik initialValues={{ ...initialValues, ...state }} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ errors, touched, isSubmitting }) => (
          <FormikForm>
            <Section title="Other Charge point details">
              <FormEditable fields={detailFields} errors={errors} touched={touched} disabled={isSubmitting} />
            </Section>
            <Section pt={3} title="Connectors" description="Settings and configuration for your charge point.">
              <FormEditable fields={connectorFields} errors={errors} touched={touched} disabled={isSubmitting} />
            </Section>

            <Section pt={3} title="Configuration" description="Settings and configuration for your charge point.">
              <FormEditable fields={configurationFields} errors={errors} touched={touched} disabled={isSubmitting} />
            </Section>
            <Box pt={2} display="flex">
              <Button variant="contained" onClick={prevStep}>
                Back
              </Button>
              <Box mr="auto" />
              <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Next'}
              </Button>
            </Box>
          </FormikForm>
        )}
      </Formik>

      <Dialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Where do I find my charge point serial?"
        maxWidth="xs"
      >
        <Box px={2}>
          <ChargePointSerialGraphic
            mb={4}
            textAlign="center"
            svgProps={{ style: { maxWidth: '60%', height: '100%' } }}
          />
          <Typography variant="body2" paragraph>
            A charge point serial is a unique alphanumeric code that identifies your charge point.
          </Typography>
          <Typography variant="body2" paragraph>
            It will usually be printed on a label as &quot;Serial&quot; or &quot;S/N&quot; along with other manufacturer
            information such as the model number.
          </Typography>
        </Box>
      </Dialog>
    </DialogContext.Provider>
  );
};
