import * as React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth } from './AuthProvider';
import { fullStateSelector } from '../store/root';
import { Splash } from '../design-system';

export const AppLoader: React.FC = () => {
  const { isLoading, isAuthenticated } = useAuth();
  const { isEvnexAdmin, isInstaller } = useSelector(fullStateSelector);

  const location = useLocation<{ referrer?: string }>();

  if (isLoading) {
    return <Splash />;
  }

  if (isAuthenticated) {
    if (location.state?.referrer) {
      return <Redirect to={location.state.referrer} />;
    }

    return isInstaller && !isEvnexAdmin ? <Redirect to="/installer" /> : <Redirect to="/organisations" />;
  }

  return <Redirect to="/auth" />;
};
