import MUILink from '@material-ui/core/Link';
import type { TypographyTypeMap } from '@material-ui/core';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

type StandaloneLinkProps = React.PropsWithChildren<Pick<LinkProps, 'to'>> & {
  fontWeight?: React.CSSProperties['fontWeight'];
  color?: TypographyTypeMap['props']['color'];
};
export const RouterLink: React.FC<StandaloneLinkProps> = ({ children, color, fontWeight, to }: StandaloneLinkProps) => (
  <MUILink to={to} component={Link} color={color ?? 'primary'} style={{ fontWeight }}>
    {children}
  </MUILink>
);

export const TableLink: React.FC<StandaloneLinkProps> = ({ children, color, fontWeight, to }) => (
  <RouterLink to={to} fontWeight={fontWeight ?? 'bold'} color={color ?? 'textPrimary'}>
    {children}
  </RouterLink>
);
