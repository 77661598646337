import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import * as Yup from 'yup';
import { useCurrentOrganisation } from '../../../../../../../../app/CurrentOrganisationProvider';
import { Dialog, DialogProps } from '../../../../../../../../components/Dialog';
import { errorSnackbar } from '../../../../../../../../components/ErrorSnackbar';
import { Form, OnSubmit } from '../../../../../../../../design-system';
import {
  CommandChangeAvailability,
  usePostChargePointCommandChangeAvailability,
} from '../../../../../../../../utils/api';
import { useChargePoint } from '../../../Provider';

const initialValues = {
  availability: '',
};

const validationSchema = Yup.object().shape({
  availability: Yup.string().required('Availability is required'),
});

export const ChangeAvailabilityDialog: React.FC<DialogProps> = ({ isOpen, onClose }: DialogProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { slug } = useCurrentOrganisation().organisation ?? { slug: '' };
  const { chargePoint, refetch } = useChargePoint();
  const { id: chargePointId } = chargePoint ?? { id: '' };

  const { error, mutate } = usePostChargePointCommandChangeAvailability({
    organisationSlug: slug,
    chargePointId,
  });

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to change charge point availability', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  const onSubmit = React.useCallback<OnSubmit<typeof initialValues>>(
    async ({ availability }, { setSubmitting }) => {
      try {
        const { data } = await mutate({
          connectorId: 0,
          changeAvailabilityType: availability as CommandChangeAvailability['changeAvailabilityType'],
        });
        enqueueSnackbar(`Change availability command ${data.status.toLowerCase()}`);
        onClose();
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [mutate, enqueueSnackbar, onClose, refetch],
  );

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Change availability">
      <Form<typeof initialValues>
        fields={[
          {
            id: 'availability',
            label: 'Availability',
            inputComponent: (props) => (
              <TextField select variant="outlined" margin="dense" fullWidth style={{ margin: 0 }} {...props}>
                {[
                  { value: 'Inoperative', label: 'Disabled' },
                  { value: 'Operative', label: 'Enabled' },
                ].map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            ),
          },
        ]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        labels={{ save: 'Send command' }}
        isEditing
      />
    </Dialog>
  );
};
