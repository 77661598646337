import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Connector, MeterValues, NetworkStatus, OcppStatus } from '../../app/ApiGen';
import { FormField, ValueComponentProps } from '../../design-system';

const ConnectorOcppStatus: Record<OcppStatus, string> = {
  AVAILABLE: 'Available',
  CHARGING: 'Charging',
  FAULTED: 'Faulted',
  FINISHING: 'Finished charging - unplug charge point',
  PREPARING: 'Preparing to charge',
  RESERVED: 'Reserved',
  SUSPENDED_EV: 'The vehicle is not currently requesting energy',
  SUSPENDED_EVSE: 'Charging has been paused by the charge point',
  UNAVAILABLE: 'Disabled',
};

export const getConnectorSecondaryText: (connector: Connector) => string | undefined = ({
  ocppCode,
  ocppStatus,
  meter,
}) => {
  if (ocppStatus === 'CHARGING') {
    return meter && meter.power ? `${(meter.power / 1000).toFixed(2)} kW` : undefined;
  }

  if (ocppStatus === 'FAULTED') {
    return ocppCode ?? 'Unknown code';
  }

  return ConnectorOcppStatus[ocppStatus];
};

interface ValueProps {
  before?: string;
  value?: React.ReactNode;
  after?: string;
}

const ValueOnly: React.FC<ValueProps> = ({ before, value, after }: ValueProps) => {
  if (value !== undefined) {
    return (
      <>
        <br />
        {before}
        {value}
        {after}
      </>
    );
  }

  return <></>;
};

const Value: React.FC<ValueProps> = ({ before, value, after }: ValueProps) => {
  if (value !== undefined) {
    return (
      <>
        {before}
        {value}
        {after}
      </>
    );
  }

  return <Typography color="textSecondary">Unavailable</Typography>;
};

const sharedFields: FormField<Connector>[] = [
  {
    id: 'evseId',
    label: 'EVSE ID',
    valueComponent: ({ value }: ValueComponentProps<string>) => <Value value={value} />,
  },
  {
    id: 'powerType',
    label: 'Power type',
    valueComponent: ({ value }: ValueComponentProps<string>) => <Value value={value} />,
  },
  {
    id: 'connectorType',
    label: 'Connector type',
    valueComponent: ({ value }: ValueComponentProps<string>) => <Value value={value} />,
  },
  {
    id: 'connectorFormat',
    label: 'Connector format',
    valueComponent: ({ value }: ValueComponentProps<string>) => <Value value={value} />,
  },
  {
    id: 'maxAmperage',
    label: 'Maximum current',
    valueComponent: ({ value }: ValueComponentProps<string>) => <Value value={value} after="A" />,
  },
  {
    id: 'maxVoltage',
    label: 'Maximum voltage',
    valueComponent: ({ value }: ValueComponentProps<string>) => <Value value={value} after="V" />,
  },
];

const commonFields: FormField[] = [
  {
    id: 'meter.power',
    label: 'Power',
    valueComponent: ({ value }: ValueComponentProps<number | undefined>) => (
      <Value value={value !== undefined ? (value / 1000).toFixed(2) : undefined} after=" kW" />
    ),
  },

  {
    id: 'meter.voltage',
    label: 'Voltage',
    valueComponent: ({ value }: ValueComponentProps<string>) => <Value value={value} after=" V" />,
  },
  {
    id: 'meter.current',
    label: 'Current',
    valueComponent: ({ value }: ValueComponentProps<string>) => <Value value={value} after=" A" />,
  },
  {
    id: 'meter.frequency',
    label: 'Frequency',
    valueComponent: ({ value }: ValueComponentProps<string>) => <Value value={value} after=" Hz" />,
  },
];

export const getConnectorFields = (networkStatus: NetworkStatus, { ocppStatus, powerType }: Connector): FormField[] => {
  const showMeterValues = ocppStatus === 'CHARGING' && networkStatus !== 'OFFLINE';
  switch (powerType) {
    case 'AC_1_PHASE': {
      if (!showMeterValues) {
        return [...sharedFields];
      }
      return [...sharedFields, ...commonFields];
    }
    case 'AC_3_PHASE': {
      if (!showMeterValues) {
        return [...sharedFields];
      }
      return [
        ...sharedFields,
        {
          id: 'meter.power',
          label: 'Power',
          valueComponent: ({ value }: ValueComponentProps<number | undefined>) => (
            <Value value={value !== undefined ? (value / 1000).toFixed(2) : undefined} after=" kW" />
          ),
        },
        {
          id: 'meter',
          label: 'Voltage',
          valueComponent: ({ value }: ValueComponentProps<MeterValues>) => (
            <div>
              <Value before="Φ1 = " value={value.voltageL1N} after=" V" />
              <ValueOnly before="Φ2 = " value={value.voltageL2N} after=" V" />
              <ValueOnly before="Φ3 = " value={value.voltageL3N} after=" V" />
            </div>
          ),
        },
        {
          id: 'meter',
          label: 'Current',
          valueComponent: ({ value }: ValueComponentProps<MeterValues>) => (
            <div>
              <Value before="Φ1 = " value={value.currentL1} after=" A" />
              <ValueOnly before="Φ2 = " value={value.currentL2} after=" A" />
              <ValueOnly before="Φ3 = " value={value.currentL3} after=" A" />
            </div>
          ),
        },
        {
          id: 'meter.frequency',
          label: 'Frequency',
          valueComponent: ({ value }: ValueComponentProps<string>) => <Value value={value} after=" Hz" />,
        },
      ];
    }
    case 'DC': {
      if (!showMeterValues) {
        return [...sharedFields];
      }
      return [
        ...sharedFields,
        ...commonFields,
        {
          id: 'meter.stateOfCharge',
          label: 'Charge progress',
          valueComponent: ({ value }: ValueComponentProps<string>) => <Value value={value} after="%" />,
        },
        {
          id: 'meter.temperature',
          label: 'Device temperature',
          valueComponent: ({ value }: ValueComponentProps<string>) => <Value value={value} after=" °C" />,
        },
      ];
    }
    default:
      return [...sharedFields];
  }
};
