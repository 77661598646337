import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  RequestCreateLoadBalancingGroup,
  useCreateOrganisationLoadBalancingGroup,
  useLegacyGetOrganisationChargePoints,
} from '../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import ChargePointMultiSelect, {
  SelectableChargePoint,
} from '../../../../../components/CustomFields/ChargePointMultiSelect';
import { DataErrorHandler, ErrorHandler } from '../../../../../components/ErrorHandler';
import { errorSnackbar } from '../../../../../components/ErrorSnackbar';
import { Card, Field, FormActions, List, ListItem, Loading, Section, Workspace } from '../../../../../design-system';
import { SubState } from '../../../../../store/types';
import { numericSchemaParam } from '../../../../../utils/formUtils';
import { CreateLoadBalancingGroup, validationSchema } from '../../../../../utils/loadBalancingGroups/FieldDefinitions';

const initialValues: CreateLoadBalancingGroup = {
  name: '',
  maximumCurrent: 120,
  enabled: true,
  chargePoints: [],
};

interface OrganisationContext {
  organisation: SubState<'organisation'>;
}

interface CreateLoadBalancingGroupProps extends OrganisationContext {
  organisationChargePoints: SelectableChargePoint[];
}

const WithOrgChargePoints: React.VFC<CreateLoadBalancingGroupProps> = ({
  organisation,
  organisationChargePoints,
}: CreateLoadBalancingGroupProps) => {
  const { push } = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { mutate: createLoadBalancingGroup } = useCreateOrganisationLoadBalancingGroup({ orgId: organisation.id });

  return (
    <Workspace maxWidth="sm">
      <Section
        title="Add load balancing group"
        description="Create a new load balancing group for charge points your organisation owns. Charge points in a load balancing group will automatically adjust how much current each is allowed to draw, up to a specified amperage limit for the group."
      >
        <Card>
          <Formik<CreateLoadBalancingGroup>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (formValues, { setSubmitting }) => {
              setSubmitting(true);

              const args: RequestCreateLoadBalancingGroup = {
                data: {
                  relationships: {
                    chargePoints: {
                      data: (formValues.chargePoints ?? []).map((cp) => ({ id: cp.id, type: 'chargePoints' })),
                    },
                    organisation: { data: { type: 'organisations', id: organisation.id } },
                  },
                  attributes: {
                    phases: [{ maximumCurrent: formValues.maximumCurrent }],
                    enabled: formValues.enabled,
                    name: formValues.name,
                  },
                  type: 'loadBalancingGroups',
                },
              };

              try {
                await createLoadBalancingGroup(args);
                enqueueSnackbar('Load balancing group created', { variant: 'success' });
                push(`/organisations/${organisation.slug}/load-balancing-groups`);
              } catch (submitError) {
                errorSnackbar('Unable to create load balancing group', closeSnackbar, enqueueSnackbar, submitError);
              } finally {
                setSubmitting(false);
              }
            }}
            validateOnBlur
            validateOnChange
          >
            {({ isSubmitting, values, touched, handleChange, setFieldValue, errors }) => (
              <Form>
                <List>
                  <ListItem>
                    <Field label="Name">
                      <TextField
                        id="name"
                        error={Boolean(touched.name && errors.name)}
                        helperText={
                          touched.name && errors.name
                            ? errors.name
                            : 'A recognisable name for this load balancing group'
                        }
                        value={values.name}
                        onChange={handleChange}
                      />
                    </Field>
                  </ListItem>
                  <ListItem>
                    <Field label="Maximum Current">
                      <TextField
                        name="maximumCurrent"
                        error={Boolean(touched.maximumCurrent && errors.maximumCurrent)}
                        helperText={
                          errors.maximumCurrent
                            ? errors.maximumCurrent
                            : 'The maximum amount of current to be distributed among the charge points in this group, in amperes'
                        }
                        type="number"
                        InputProps={{
                          style: { width: '4em' },
                          endAdornment: <InputAdornment position="end">A</InputAdornment>,
                          inputProps: {
                            min:
                              numericSchemaParam<CreateLoadBalancingGroup>(validationSchema, 'maximumCurrent', 'min') +
                              1,
                            max:
                              numericSchemaParam<CreateLoadBalancingGroup>(validationSchema, 'maximumCurrent', 'max') -
                              1,
                          },
                        }}
                        value={values.maximumCurrent}
                        onChange={handleChange}
                      />
                    </Field>
                  </ListItem>
                  <ListItem>
                    <Field label="Enabled">
                      <Switch id="enabled" color="primary" checked={values.enabled} onChange={handleChange} />
                    </Field>
                  </ListItem>
                  <ListItem>
                    <Field label="Charge Points">
                      <ChargePointMultiSelect
                        chargePoints={organisationChargePoints}
                        preSelectedChargePoints={[]}
                        onChangeSelection={(selected) => setFieldValue('chargePoints', selected)}
                        fieldInputProps={{ name: 'chargePoints' }}
                      />
                    </Field>
                    {errors.chargePoints ? <FormHelperText>{errors.chargePoints}</FormHelperText> : null}
                  </ListItem>
                </List>
                <FormActions>
                  <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Create load balancing group'}
                  </Button>
                </FormActions>
              </Form>
            )}
          </Formik>
        </Card>
      </Section>
    </Workspace>
  );
};

const WithOrg: React.VFC<OrganisationContext> = ({ organisation }: OrganisationContext) => {
  const { loading, error, data, refetch } = useLegacyGetOrganisationChargePoints({ orgSlug: organisation.slug });

  if (loading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }
  if (error || !data) {
    return (
      <DataErrorHandler error={error} description="Unable to fetch organisation charge points" refetch={refetch} />
    );
  }
  return <WithOrgChargePoints organisationChargePoints={data.data.items} organisation={organisation} />;
};

const CreateLoadBalancingGroupView: React.VFC = () => {
  const { organisation, loading } = useCurrentOrganisation();

  if (loading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }
  if (!organisation) {
    return <ErrorHandler description="Unable to load organisation" />;
  }
  return <WithOrg organisation={organisation} />;
};

export default CreateLoadBalancingGroupView;
