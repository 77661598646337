import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import type { PowerType } from '../../../../app/ApiGen';

const PowerTypeLabel: Record<PowerType, string> = {
  AC_1_PHASE: 'AC, single phase',
  AC_3_PHASE: 'AC, 3-phase',
  DC: 'DC',
};
const connectorPowerTypes = Object.entries(PowerTypeLabel);

export const PowerTypeInput: React.FC = ({ ...other }) => (
  <TextField
    select
    variant="outlined"
    margin="dense"
    fullWidth
    style={{ margin: 0 }}
    aria-label="Power type"
    {...other}
  >
    {connectorPowerTypes.map(([value, label]) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ))}
  </TextField>
);
