import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';

const initialValues = {
  password: '',
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required').min(8, 'Passwords have a minimum length of 8'),
});

export interface NewPasswordFormProps {
  onSubmit: (values: typeof initialValues, helpers: FormikHelpers<typeof initialValues>) => void;
}

export const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ onSubmit }) => (
  <Fade in>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <Box textAlign="center">
            <Typography>Please enter a new password to activate your CP-Link account.</Typography>
          </Box>
          <Box mt={2}>
            <Field name="password">
              {({ field }: FieldProps) => (
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  autoFocus
                  error={!!errors.password}
                  helperText={errors.password}
                  disabled={isSubmitting}
                  {...field}
                />
              )}
            </Field>
          </Box>
          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary" size="large" fullWidth disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress size={26} color="inherit" /> : 'Change password'}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  </Fade>
);
