import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Item, MenuSection, NavigationView } from '../../design-system';
import { ApplicationState } from '../../store/types';

const Items: React.FC = () => {
  const organisationSlug = useSelector((state: ApplicationState) => state.organisation.current?.slug);

  return (
    <MenuSection canOverflow>
      {({ isCollapsed }) => (
        <Item
          icon={ArrowBackIcon}
          text="Back to account"
          to={organisationSlug ? `/organisations/${organisationSlug}` : '/organisations'}
          isCollapsed={isCollapsed}
        />
      )}
    </MenuSection>
  );
};

export const authenticatedUserNav: NavigationView = {
  id: 'userNav/product',
  type: 'product',
  Items,
};
