import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { fade, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import TuneIcon from '@material-ui/icons/Tune';
import React from 'react';
import { Card, Workspace } from '../../../../design-system';

const UpgradeView: React.FC = () => {
  const useStyles = makeStyles((theme) => ({
    features: {
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(10),
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
        borderColor: theme.palette.background.default,
      },
    },
    iconWrapper: {
      backgroundColor: fade(theme.palette.background.default, 0.6),
    },
  }));

  const classes = useStyles();

  const content = {
    badge: 'Upgrade',
    'header-p1': 'Unlock More Features',
    description:
      'Need more functionality? Upgrade your plan to enable more charge points and unlock additional features. Talk to our team to learn more.',
    'primary-action': 'Contact Us',
    'col1-header': 'Load balancing',
    'col1-desc':
      'Software based load balancing allows you to distribute available capacity between a group of charge points.',
    'col2-header': 'Advanced OCPP configuration',
    'col2-desc': 'Remotely access and configure advanced settings on your charge points.',
    'col3-header': 'Remote firmware update',
    'col3-desc': 'Update charge point firmware over the air.',
  };

  return (
    <>
      <Workspace maxWidth="lg">
        <Card>
          <Container maxWidth="lg">
            <Box pt={10} pb={12}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={7}>
                  <Typography variant="overline" color="textPrimary">
                    {content.badge}
                  </Typography>
                  <Typography variant="h3" component="h2" gutterBottom>
                    <Typography color="primary" variant="h3" component="span">
                      {content['header-p1']}{' '}
                    </Typography>
                  </Typography>
                  <Typography variant="subtitle1" component="p" color="textPrimary">
                    {content.description}
                  </Typography>
                  <Box mt={3}>
                    <Link href="https://evnex.com/contact" target="_blank" color="textPrimary">
                      <Button variant="contained" color="primary">
                        {content['primary-action']}
                      </Button>
                    </Link>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div className={classes.features}>
                    <Box display="flex" mb={5}>
                      <Box pr={5}>
                        <Avatar className={classes.iconWrapper} variant="rounded">
                          <ShareOutlinedIcon color="primary" />
                        </Avatar>
                      </Box>
                      <div>
                        <Typography variant="h6" component="h3" gutterBottom>
                          {content['col1-header']}
                        </Typography>
                        <Typography variant="body2" component="p" color="textSecondary">
                          {content['col1-desc']}
                        </Typography>
                      </div>
                    </Box>
                    <Box display="flex" mb={5}>
                      <Box pr={5}>
                        <Avatar className={classes.iconWrapper} variant="rounded">
                          <TuneIcon color="primary" />
                        </Avatar>
                      </Box>
                      <div>
                        <Typography variant="h6" component="h3" gutterBottom>
                          {content['col2-header']}
                        </Typography>
                        <Typography variant="body2" component="p" color="textSecondary">
                          {content['col2-desc']}
                        </Typography>
                      </div>
                    </Box>
                    <Box display="flex">
                      <Box pr={5}>
                        <Avatar className={classes.iconWrapper} variant="rounded">
                          <CloudDownloadIcon color="primary" />
                        </Avatar>
                      </Box>
                      <div>
                        <Typography variant="h6" component="h3" gutterBottom>
                          {content['col3-header']}
                        </Typography>
                        <Typography variant="body2" component="p" color="textSecondary">
                          {content['col3-desc']}
                        </Typography>
                      </div>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Card>
      </Workspace>
    </>
  );
};

export default UpgradeView;
