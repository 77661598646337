import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import { uid } from 'react-uid';
import { getLocationAvatar } from '../../../../../../../../components/Avatar/Location';
import { Card, List, ListItem } from '../../../../../../../../design-system';
import { getAddressAsString } from '../../../../../../../../utils/locations/address';
import { useLocationMap } from '../../Context';
import type { LocationInfoPos } from '../../types';
import { AvatarWrapper, Location, Primary, Secondary, Text } from './styled';

export interface LocationClusterProps {
  locations: LocationInfoPos[];
}

export const LocationCluster: React.FC<LocationClusterProps> = ({ locations }) => {
  const { setSingleLocation } = useLocationMap();

  const onLocationClicked = React.useCallback((location: LocationInfoPos) => setSingleLocation(location), [
    setSingleLocation,
  ]);

  return (
    <Card title={`Viewing ${locations.length} locations`} style={{ height: '100%', borderRadius: 0 }}>
      <Divider />
      <Box height="calc(100% - 57px)" overflow="auto">
        <List>
          {locations.map((location, idx) => {
            const { name } = location;
            const address = getAddressAsString(location.address);

            return (
              <ListItem key={uid(location, idx)} onClick={() => onLocationClicked(location)}>
                <Location>
                  <AvatarWrapper>{getLocationAvatar()}</AvatarWrapper>
                  <Text>
                    <Primary title={name}>{name}</Primary>
                    <Secondary title={address}>{address}</Secondary>
                  </Text>
                </Location>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Card>
  );
};
