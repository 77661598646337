import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import styled from 'styled-components';
import cover from '../../../../assets/cover.jpg';
import { Logo } from '../../../../design-system';
import { Info } from './Info';

const StyledMobile = styled('div')`
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: nowrap column;
  position: relative;
`;

const Image = styled('div')`
  padding: ${(p) => p.theme.spacing(3)}px;
  width: 100%;
  height: 40vh;
  background: url(${cover});
  background-size: cover;
  background-position: center;
`;

const Panel = styled('div')`
  padding: ${(p) => p.theme.spacing(4, 3, 2, 4)};
  height: 70vh;
  width: 100%;
  position: absolute;
  top: 30vh;
  display: flex;
  flex-flow: nowrap column;
  align-items: center;
  background-color: ${(p) => p.theme.palette.background.default};
  border-top-left-radius: ${(p) => p.theme.shape.borderRadius * 6}px;
  border-top-right-radius: ${(p) => p.theme.shape.borderRadius * 6}px;
  overflow: auto;
`;

const Title = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const FormWrapper = styled('div')`
  width: 100%;
  max-width: 320px;
`;

export interface MobileProps {
  title?: React.ReactNode;
}

export const Mobile: React.FC<MobileProps> = ({ children, title }) => (
  <StyledMobile>
    <Image>
      <Logo variant="dark" height={20} width={120} />
    </Image>
    <Panel>
      {title && <Title variant="h4">{title}</Title>}
      <FormWrapper>{children}</FormWrapper>
      <Box mt={3}>
        <Info />
      </Box>
    </Panel>
  </StyledMobile>
);
