import moment from 'moment-timezone';

const invalidTimeZone = [
  'Etc',
  'HST',
  'PST',
  'MST',
  'CST',
  'EST',
  'EST',
  'GB',
  'GMT',
  'UCT',
  'UTC',
  'WET',
  'Zulu',
  'Universal',
  'CET',
  'MET',
  'EET',
  'W-SU',
  'PRC',
  'ROC',
  'ROK',
  'NZ',
];

const allTimeZones = moment.tz.names().filter((tzName) => {
  for (let i = 0; i < invalidTimeZone.length; i += 1) {
    if (tzName.includes(invalidTimeZone[i])) {
      return false;
    }
  }

  return true;
});

export const sortedTimeZones = allTimeZones
  .map((tzString: string) => {
    const tz = moment.tz(tzString);

    return {
      label: `(GMT${moment.tz(tzString).format('Z')}) ${tzString.replace(/_/g, ' ')} ${
        !parseInt(tz.zoneAbbr(), 10) ? `(${tz.zoneAbbr()})` : ''
      }`,
      value: `${tzString}`,
      time: `${tz.format('Z')}`,
    };
  })
  .sort((a, b) => {
    if (parseInt(a.time, 10) < parseInt(b.time, 10)) {
      return -1;
    }

    if (parseInt(a.time, 10) > parseInt(b.time, 10)) {
      return 1;
    }

    return 0;
  });
