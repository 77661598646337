import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useAuthenticatedUser } from '../../../app/AuthenticatedUserProvider';
import { UserAvatar } from '../../../components/Avatar/User';
import { Card, Section, useNavigationView, Workspace } from '../../../design-system';
import { authenticatedUserNav } from '../navView';
import EmailCard from './components/EmailCard';
import { MembershipCard } from './components/MembershipCard';
import { MfaSetup } from './components/MfaSetup';
import PasswordCard from './components/PasswordCard';
import { ProfileForm } from './components/ProfileForm';

const SettingsView: React.FC = () => {
  useNavigationView(authenticatedUserNav);

  const { authenticatedUser: user } = useAuthenticatedUser();

  if (!user) {
    return null;
  }

  const { email } = user;

  return (
    <Workspace>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <MembershipCard user={user} />
                </Grid>
                <Grid item xs={3}>
                  <UserAvatar user={user} />
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Section title="Profile" description="Your user information.">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ProfileForm />
              </Grid>
              <Grid item xs={12} lg={4}>
                <EmailCard email={email} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <PasswordCard />
              </Grid>
              <Grid item xs={12} lg={4}>
                <MfaSetup />
              </Grid>
            </Grid>
          </Section>
        </Grid>
      </Grid>
    </Workspace>
  );
};

export default SettingsView;
