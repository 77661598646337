import * as Yup from 'yup';
import type { SelectableChargePoint } from '../../components/CustomFields/ChargePointMultiSelect';
import { createSchema } from '../formUtils';

export type CreateLoadBalancingGroup = {
  name: string;
  chargePoints: SelectableChargePoint[] | null;
  maximumCurrent: number;
  enabled: boolean;
};

export const validationSchema = createSchema<CreateLoadBalancingGroup>({
  name: Yup.string().required('Group name is required'),
  chargePoints: Yup.array<SelectableChargePoint>().max(10, 'A group can contain no more than 10 charge points'),
  maximumCurrent: Yup.number()
    .lessThan(1000, 'Enter a valid current')
    .moreThan(5, 'Group must have at least 6A to distribute')
    .required('Maximum current is required'),
  enabled: Yup.boolean(),
});
