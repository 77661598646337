import { startOfDay, addSeconds, addDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function getStartOfDay(): Date {
  const midnight = startOfDay(new Date());
  const dayAfter = addDays(midnight, 1).getTimezoneOffset();

  const midnightOffset = Math.abs(
    utcToZonedTime(midnight, Intl.DateTimeFormat().resolvedOptions().timeZone).getTimezoneOffset(),
  );

  const offset = dayAfter !== midnightOffset ? (Math.abs(midnightOffset) - Math.abs(dayAfter)) * 60 : 0;
  return addSeconds(midnight, offset);
}
