import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useNavigationView } from '../../../../../design-system';
import UserLocationsView from '../../../../Admin/Users/User/UserLocationsView';
import { organisationUserNav } from './navView';
import OverviewView from './Overview';
import { OrganisationUserProvider } from './Provider';
import SettingsView from './Settings';

const OrganisationUserView: React.FC = () => {
  useNavigationView(organisationUserNav);

  const {
    path,
    url,
    params: { id },
  } = useRouteMatch<{ id: string }>();

  return (
    <OrganisationUserProvider>
      <Switch>
        <Route path={`${path}/overview`}>
          <OverviewView />
        </Route>
        <Route path={`${path}/locations`}>
          <UserLocationsView userId={id} />
        </Route>
        <Route path={`${path}/settings`}>
          <SettingsView />
        </Route>
        <Route>
          <Redirect to={`${url}/overview`} />
        </Route>
      </Switch>
    </OrganisationUserProvider>
  );
};

export default OrganisationUserView;
