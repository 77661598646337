import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Alert from '@material-ui/lab/Alert';
import { SnackbarKey, SnackbarMessage, OptionsObject } from 'notistack';
import { standardiseError } from '../../utils/error';

export function stringifyErrorMessage(errorMessage: SnackbarMessage, detail?: string) {
  if (errorMessage && detail && errorMessage.toString() !== detail) {
    return `${errorMessage.toString()}: ${detail}`;
  }
  return errorMessage;
}

export const errorSnackbar = (
  errorMessage: SnackbarMessage,
  closeSnackbar: (key?: SnackbarKey) => void,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
  error: unknown,
): void => {
  const { id, meta, title: errName, detail } = standardiseError(error);
  const errorId = id ?? meta?.correlationId;
  const date = new Date().toLocaleString();
  enqueueSnackbar(stringifyErrorMessage(errorMessage, detail), {
    persist: true,
    content: (key: SnackbarKey, message: SnackbarMessage) => (
      <Card>
        <Alert onClose={() => closeSnackbar(key)} variant="filled" severity="error">
          {message}
        </Alert>
        <Paper style={{ padding: 16 }}>
          <Typography gutterBottom>
            Please send the details below to support@evnex.com and we will help troubleshoot the error.
          </Typography>
          {errorId && (
            <Typography gutterBottom>
              <strong>Error ID:</strong> {errorId}
            </Typography>
          )}
          <Typography gutterBottom>
            <strong>Error Name:</strong> {errName}
          </Typography>
          <Typography gutterBottom>
            <strong>Date:</strong> {date}
          </Typography>
        </Paper>
      </Card>
    ),
  });
};
