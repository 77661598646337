import TextField from '@material-ui/core/TextField';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { InputComponentProps, ValueComponentProps } from '../../design-system';
import { AustralianState, isAustralia } from '../../utils/locations/address';
import { AutocompleteInput, AutocompleteValue } from './SimpleAutocomplete';

const australiaStateOptions = Object.keys(AustralianState).map((key) => {
  const fullStateName = AustralianState[key as keyof typeof AustralianState];
  return { label: fullStateName, value: fullStateName };
});

export const StateValue: React.FC<ValueComponentProps<string>> = ({ value }) => (
  <AutocompleteValue label={value} value={value} />
);

export function StateInput<FormValues extends { country: string }>({
  disabled,
  error,
  helperText,
  name,
  onBlur,
  onChange,
  value,
}: InputComponentProps<string>) {
  const { values } = useFormikContext<FormValues>();

  if (isAustralia(values.country)) {
    return (
      <AutocompleteInput<string>
        disabled={disabled}
        error={error}
        helperText={helperText}
        name={name}
        options={australiaStateOptions}
        onBlur={onBlur}
        onChange={onChange}
        useNullAsDefaultValue
        value={value}
      />
    );
  }

  return (
    <TextField
      disabled={disabled}
      error={error}
      fullWidth
      helperText={helperText}
      margin="dense"
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      variant="outlined"
    />
  );
}
