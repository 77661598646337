import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';
import { useTheme } from 'styled-components';

interface CollapsedTooltipProps {
  isCollapsed: boolean;
  text?: React.ReactNode;
  subText?: React.ReactNode;
}

export const CollapsedTooltip: React.FC<CollapsedTooltipProps> = ({ children, isCollapsed, text, subText }) => {
  const { typography } = useTheme();

  if (isCollapsed) {
    return (
      <Tooltip
        placement="right"
        title={
          <div>
            {text && <div style={{ fontSize: '11px', lineHeight: '16px' }}>{text}</div>}
            {subText && <div style={{ fontWeight: typography.fontWeightRegular }}>{subText}</div>}
          </div>
        }
      >
        <div>{children}</div>
      </Tooltip>
    );
  }

  return <>{children}</>;
};
