import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { TableColumn } from './columns';
import { DateFormatDateFns } from '../dateFormat';
import { getAddressAsString, isAddress } from '../locations/address';

const { timeZone: defaultTimeZone } = Intl.DateTimeFormat().resolvedOptions();

type FieldValue = unknown;

export type FieldValueGetter<RowData> = (rowData: TableRow<RowData>, column: TableColumn<RowData>) => FieldValue;

export type TableRow<RowData> = RowData & { tableData: { id: number } };

function getCsvFormattedDate(args: { date: string | Date; dateFormat?: string; timeZone?: string }): string | Date {
  const { date, dateFormat, timeZone } = args;
  try {
    return formatInTimeZone(new Date(date), timeZone ?? defaultTimeZone, dateFormat ?? DateFormatDateFns.Short);
  } catch (e) {
    if (e instanceof RangeError) {
      console.warn('Error formatting date: ', e);
      return date;
    }
    throw e;
  }
}

export function getCsvCellValue<RowData>(args: {
  columnType?: TableColumn<RowData>['type'];
  columnField?: TableColumn<RowData>['field'];
  dateFormat?: string;
  value: FieldValue;
  timeZone?: string;
}) {
  const { columnType, columnField, dateFormat, timeZone, value } = args;
  if (!value) {
    return value;
  }
  if (columnType === 'date' && (typeof value === 'string' || value instanceof Date)) {
    return getCsvFormattedDate({ date: value, dateFormat, timeZone });
  }
  if (columnField === 'address' && isAddress(value)) {
    return getAddressAsString(value);
  }
  return value;
}

export function getCsvColumns<RowData>(columns: TableColumn<RowData>[]): TableColumn<RowData>[] {
  return columns.filter((col) => !col.hidden && col.field && col.export !== false);
}
