import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import { Currency, useUpdateChargePoint } from '../../../../../../../app/ApiGen';
import { CostInput, CostValue } from '../../../../../../../components/CustomFields/Cost';
import { CurrencyInput, CurrencyValue } from '../../../../../../../components/CustomFields/Currency';
import { errorSnackbar } from '../../../../../../../components/ErrorSnackbar';
import { Card, Form, OnSubmit } from '../../../../../../../design-system';
import { SubState } from '../../../../../../../store/types';
import { getCost } from '../../../../../../../utils/electricityCost';
import { createSchema, FieldDefinitions } from '../../../../../../../utils/formUtils';

type ElectricityCostSettingsProps = {
  chargePoint: SubState<'chargePoint'>;
  refetch: () => void;
};

type ElectricityCostFields = {
  cost: string;
  currency: Currency;
};

const formFields: FieldDefinitions<ElectricityCostFields> = [
  {
    id: 'cost',
    label: 'Electricity cost',
    valueComponent: CostValue,
    inputComponent: CostInput,
  },
  {
    id: 'currency',
    label: 'Electricity cost currency',
    valueComponent: CurrencyValue,
    inputComponent: CurrencyInput,
  },
];

const validationSchema = createSchema<ElectricityCostFields>({
  cost: Yup.string().required('Cost is required').min(0),
  currency: Yup.mixed<Currency>().required('Currency is required'),
});

export const ElectricityCostSettings: React.VFC<ElectricityCostSettingsProps> = ({
  chargePoint,
  refetch,
}: ElectricityCostSettingsProps) => {
  const { electricityCost, id: chargePointId } = chargePoint;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { error, mutate } = useUpdateChargePoint({ id: chargePointId });

  const [isEditing, setIsEditing] = React.useState(false);

  const initialValues: ElectricityCostFields = {
    cost: getCost(electricityCost?.costs)?.toString() ?? '',
    currency: electricityCost?.currency ?? '',
  };

  const onSubmit = React.useCallback<OnSubmit<ElectricityCostFields>>(
    async ({ currency, cost }, { setSubmitting }) => {
      try {
        await mutate({
          data: {
            id: chargePointId,
            type: 'chargePoints',
            attributes: {
              electricityCost: {
                currency,
                tariffs: [{ start: 0, rate: cost, type: 'Flat' }],
                tariffType: 'Flat',
              },
            },
          },
        });
        enqueueSnackbar('Charge point cost updated', { variant: 'success' });
        setIsEditing(false);
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [chargePointId, enqueueSnackbar, mutate, refetch],
  );

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to edit charge point cost', closeSnackbar, enqueueSnackbar, error);
    }
  }, [error, closeSnackbar, enqueueSnackbar]);

  return (
    <Card title="Settings">
      <Form<ElectricityCostFields>
        fields={formFields}
        initialValues={initialValues}
        isEditing={isEditing}
        onSubmit={onSubmit}
        setIsEditing={setIsEditing}
        validationSchema={validationSchema}
      />
    </Card>
  );
};
