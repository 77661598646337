export const chartColours = [
  '#abcf38',
  '#E9C46A',
  '#889696',
  '#E76F51',
  '#278577',
  '#3E2F5B',
  '#236477',
  '#D7907B',
  '#011627',
  '#4cc9f0',
  '#432818',
  '#f20089',
];
