import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import AddChargePointImg from '../../../assets/charge-points/evnex/add-charge-points.png';
import { NoContentPlaceholderProps } from '../types';

export const NoChargePoints: React.FC<NoContentPlaceholderProps> = ({
  orgSlug,
  isDriver,
}: NoContentPlaceholderProps) => {
  if (isDriver) {
    return (
      <>
        <Typography variant="h6" gutterBottom component="h3">
          No Charge Points
        </Typography>
        <Typography variant="body2" gutterBottom component="p">
          This is where your charge point will appear once it gets installed.
        </Typography>
        <Typography variant="body2" gutterBottom component="p" style={{ paddingBottom: 20 }}>
          If your charge point has already been installed but still isn’t showing up here, please contact your
          installer.
        </Typography>
        <img src={AddChargePointImg} alt="add-charge-point" style={{ height: 230, width: 250, marginBottom: 20 }} />
      </>
    );
  }

  return (
    <>
      <Typography variant="h6" gutterBottom component="h3">
        Add Charge Point
      </Typography>
      <Typography variant="body2" gutterBottom component="p">
        Your account has no charge points.
      </Typography>
      <Typography variant="body2" gutterBottom component="p" style={{ paddingBottom: 20 }}>
        Click below to add one now.
      </Typography>
      <img src={AddChargePointImg} alt="add-charge-point" style={{ height: 230, width: 250, marginBottom: 20 }} />
      <Button
        component={Link}
        to={`/organisations/${orgSlug}/create/charge-point`}
        color="primary"
        variant="contained"
        style={{ marginBottom: 40 }}
      >
        Add Charge Point
      </Button>
    </>
  );
};
