import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EvStationIcon from '@material-ui/icons/EvStation';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { uid } from 'react-uid';
import styled from 'styled-components';
import { ChargePointSummary } from '../../../../../../../app/ApiGen';
import { ChargePointAvatar } from '../../../../../../../components/Avatar/ChargePoint';
import { Connectors } from '../../../../../../../components/Connectors';
import { RouterLink } from '../../../../../../../components/Link';
import { Card, List, ListItem } from '../../../../../../../design-system';
import {
  getOfflineChargePointsCount,
  totalChargingCurrent,
} from '../../../../../../../utils/chargePoints/calculateCurrent';
import { getChargePointConnectivityStatus } from '../../../../../../../utils/chargePoints/getChargePointConnectivityStatus';
import { getChargePointImage } from '../../../../../../../utils/chargePoints/getChargePointImage';
import { pluralise } from '../../../../../../../utils/format';
import { getChargePoints } from '../../../../../../../utils/locations/chargePoints';
import { Normalised } from '../../../../../../../utils/request';
import { useLocation } from '../../Provider';

const CardSubtitle = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Item = styled('div')`
  padding: ${(p) => p.theme.spacing(1, 0)};
  justify-content: space-between;
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
`;

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  flex: 0 0 55%;
`;

const Icon = styled('div')`
  flex: 0 0 auto;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextContent = styled('div')`
  padding-left: ${(p) => p.theme.spacing(3)}px;
`;

const Action = styled('div')`
  line-height: 0;

  svg {
    color: ${(p) => p.theme.palette.text.secondary};
  }
`;

interface ChargePointItemProps {
  chargePoint: Normalised<ChargePointSummary>;
  canViewCurrent: boolean; // TODO (CPLINK-2868): remove this and always show current
}

const ChargePointItem: React.FC<ChargePointItemProps> = ({ chargePoint, canViewCurrent }) => {
  const { name, vendor, model, networkStatus, networkStatusUpdatedDate, connectors } = chargePoint;
  const chargePointCurrent = totalChargingCurrent([chargePoint]);
  return (
    <Item>
      <TitleContainer>
        <Icon>
          <ChargePointAvatar src={vendor && model ? getChargePointImage(vendor, model) : undefined}>
            <EvStationIcon />
          </ChargePointAvatar>
        </Icon>
        <TextContent>
          <Typography variant="body2">{name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {getChargePointConnectivityStatus(networkStatus, networkStatusUpdatedDate)}
          </Typography>
        </TextContent>
      </TitleContainer>
      <TextContent>
        <Typography variant="body2" color="textSecondary">
          Connector statuses
        </Typography>
        <Box my={0.5}>
          <Connectors networkStatus={networkStatus} connectors={connectors} />
        </Box>
      </TextContent>
      {canViewCurrent && (
        <TextContent>
          <Typography variant="body2" color="textSecondary">
            Current
          </Typography>
          <Box my={0.5}>
            <Typography variant="body2">{chargePointCurrent}A</Typography>
          </Box>
        </TextContent>
      )}
      <Action>
        <ChevronRightIcon />
      </Action>
    </Item>
  );
};

const ChargePointsSubtitle: React.FC<{ chargePoints: Normalised<ChargePointSummary>[] }> = ({ chargePoints }) => {
  const offlineChargePointsCount = getOfflineChargePointsCount(chargePoints);
  const someChargePointsOffline = offlineChargePointsCount > 0;
  const allChargePointsOffline = offlineChargePointsCount === chargePoints.length;

  const getTotalCurrentText = (): string => {
    if (allChargePointsOffline) {
      return 'N/A*';
    }
    const totalCurrent = totalChargingCurrent(chargePoints);
    if (typeof totalCurrent !== 'undefined') {
      const totalCurrentText = `${totalCurrent}A`;
      return someChargePointsOffline ? `${totalCurrentText}*` : totalCurrentText;
    }
    return 'N/A';
  };

  return (
    <CardSubtitle>
      <Box p={2}>
        <Typography variant="body2">Total current:&nbsp;</Typography>
        <Typography variant="body2" color="textSecondary">
          {getTotalCurrentText()}
        </Typography>
      </Box>
      {someChargePointsOffline && (
        <Box p={2}>
          <Typography variant="body2" color="textSecondary">
            {allChargePointsOffline
              ? `*All charge points offline`
              : `*${pluralise(offlineChargePointsCount, 'charge point')} offline`}
          </Typography>
        </Box>
      )}
    </CardSubtitle>
  );
};

export const ChargePoints: React.FC = () => {
  const { location } = useLocation();
  const chargePoints = getChargePoints(location);

  const { push } = useHistory();

  const { params } = useRouteMatch<{ slug: string }>();

  // TODO (CPLINK-2868): remove this and always show current
  const canViewCurrent = false;

  return (
    <Card title="Charge points" style={{ minWidth: 0 }}>
      {canViewCurrent && <ChargePointsSubtitle chargePoints={chargePoints} />}
      <List>
        {chargePoints.map((chargePoint, idx) => (
          <ListItem
            key={uid(chargePoint, idx)}
            onClick={() => push(`/organisations/${params.slug}/charge-points/${chargePoint.id}`)}
          >
            <RouterLink to={`/organisations/${params.slug}/charge-points/${chargePoint.id}/overview`}>
              <ChargePointItem chargePoint={chargePoint} canViewCurrent={canViewCurrent} />
            </RouterLink>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};
