import { useSnackbar } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';
import { Location, useUpdateLocation } from '../../../../../../../app/ApiGen';
import { SwitchInput, SwitchValue, SwitchValueProps } from '../../../../../../../components/CustomFields/Switch';
import { errorSnackbar } from '../../../../../../../components/ErrorSnackbar';
import { Card, Form, FormField, FormReadonly, OnSubmit } from '../../../../../../../design-system';
import { fullStateSelector } from '../../../../../../../store/root';
import { locationFields, locationValidationSchema } from '../../../../../../../utils/locations/FieldDefinitions';
import { getIcpNumber, InstallationPointField } from '../../../../../../../utils/locations/installationConnectionPoint';
import { Normalised } from '../../../../../../../utils/request';
import { useLocation } from '../../Provider';

type LocationDetails = Pick<Normalised<Location>, 'address' | 'coordinates' | 'isPublic' | 'name' | 'timeZone'> &
  InstallationPointField;

const getFormFields = (emspId?: string): FormField[] => {
  const formFields = Object.values(locationFields);
  return emspId
    ? [
        ...formFields,
        {
          id: 'isPublic',
          label: 'OCPI Enabled',
          valueComponent: (props: SwitchValueProps) => {
            const { value }: SwitchValueProps = props;
            return <SwitchValue value={value} labels={{ true: 'Enabled', false: 'Disabled' }} />;
          },
          inputComponent: SwitchInput,
        },
      ]
    : formFields;
};

export const DetailsForm: React.FC = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const {
    isDriver,
    isFleetOperator,
    organisation: { current: organisation },
  } = useSelector(fullStateSelector);

  const { loading, location, refetch } = useLocation();
  const locationId = location?.id ?? '';

  const { mutate, error } = useUpdateLocation({ locationId });

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to update location details', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  const [isEditing, setIsEditing] = React.useState(false);

  const formDetails = getFormFields(organisation?.emspId);

  const onSubmit = React.useCallback<OnSubmit<LocationDetails>>(
    async (fields: LocationDetails, { setSubmitting }) => {
      try {
        await mutate({
          data: {
            id: locationId,
            type: 'locations',
            attributes: {
              address: fields.address,
              coordinates: fields.coordinates,
              icpNumber: getIcpNumber(fields.installationPoint),
              isPublic: fields.isPublic,
              name: fields.name,
              timeZone: fields.timeZone,
            },
          },
        });
        enqueueSnackbar('Location details updated', { variant: 'success' });
        setIsEditing(false);
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [enqueueSnackbar, locationId, mutate, refetch],
  );

  if (!location) {
    return null;
  }

  const { name, address, coordinates, timeZone, isPublic, icpNumber } = location;
  const initialValues: LocationDetails = {
    name,
    address,
    coordinates,
    timeZone,
    isPublic,
    installationPoint: { id: icpNumber },
  };

  return (
    <Card title="Details">
      {isDriver || isFleetOperator ? (
        <FormReadonly fields={formDetails} initialValues={initialValues} />
      ) : (
        <Form<LocationDetails>
          fields={formDetails}
          initialValues={initialValues}
          validationSchema={locationValidationSchema}
          onSubmit={onSubmit}
          isLoading={loading}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      )}
    </Card>
  );
};
