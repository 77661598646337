import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateJobOperation } from '../../../../app/ApiGen';
import { errorSnackbar } from '../../../../components/ErrorSnackbar';
import { Card, Section, Wizard, Workspace } from '../../../../design-system';
import { mapJobWizardStateToRequestAtomicOperations } from '../../../../utils/jobs/mappers';
import { OnWizardComplete } from '../../../../utils/wizard';
import { ConfirmationStep } from './components/ConfirmationStep';
import { JobDetailsStep } from './components/JobDetailsStep';
import { LocationStep } from './components/LocationStep';
import { UserDetailsStep } from './components/UserDetailsStep';
import type { JobWizardState } from './types';

export const CreateJobView: React.FC = () => {
  const { push } = useHistory();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const createJobOperation = useCreateJobOperation({});

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const redirectLocation = '/installer/jobs';

  const onComplete = React.useCallback<OnWizardComplete<JobWizardState>>(
    async (wizardState: JobWizardState) => {
      setIsSubmitting(true);
      try {
        await createJobOperation.mutate(mapJobWizardStateToRequestAtomicOperations(wizardState));
        setIsSubmitting(false);
        enqueueSnackbar(`Installation job created`, { variant: 'success' });
        push(redirectLocation);
      } catch (e) {
        setIsSubmitting(false);
        errorSnackbar('Unable to create pending job', closeSnackbar, enqueueSnackbar, e);
      }
    },
    [closeSnackbar, createJobOperation, enqueueSnackbar, push],
  );

  return (
    <Workspace maxWidth="md">
      <Section
        title="Create a job"
        description="Enter the information for a installation job and create a new customer account and location"
      >
        <Card>
          <Wizard<JobWizardState>
            steps={[
              { id: 'userDetails', label: 'User Details', view: UserDetailsStep },
              { id: 'location', label: 'Location Details', view: LocationStep },
              { id: 'jobDetails', label: 'Job Details', view: JobDetailsStep },
              { id: 'confirm', label: 'Confirm', view: ConfirmationStep },
            ]}
            onComplete={onComplete}
            onExit={() => push(redirectLocation)}
            isLoading={isSubmitting}
            hasStepper
          />
        </Card>
      </Section>
    </Workspace>
  );
};
