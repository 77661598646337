import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { InputComponentProps } from '../../design-system';
import { getNestedHelperText, toChangeEvent } from '../../utils/formUtils';

export type WizardOcppChargePointId = {
  prefix: string;
  suffix: string;
};

export const OCPP_CHARGE_POINT_ID_MAX_LENGTH = 48;
export const PREFIX_NAME_MAX_LENGTH = 6;

export const PrefixText: React.FC<InputComponentProps<WizardOcppChargePointId>> = ({
  name,
  disabled,
  error,
  helperText,
  value: { prefix, suffix },
  onBlur,
  onChange,
}) => (
  <Grid container direction="column">
    <Grid item>
      <Grid container>
        <Grid item xs={3}>
          <Tooltip
            placement="top"
            arrow
            title="This is a prefix for your OCPP Charge Point ID. It is automatically generated for your organisation."
          >
            <TextField
              InputProps={{ readOnly: true }}
              id="prefix"
              margin="dense"
              helperText="Prefix"
              style={{ marginTop: 0, marginRight: '0.5em' }}
              disabled
              variant="outlined"
              defaultValue={`${prefix}-`}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={9}>
          <TextField
            id="suffix"
            margin="dense"
            fullWidth
            style={{ margin: 0 }}
            disabled={disabled}
            error={error}
            value={suffix}
            helperText={getNestedHelperText(helperText)}
            inputProps={{
              'aria-label': `ocpp-id-input`,
              maxLength: OCPP_CHARGE_POINT_ID_MAX_LENGTH - PREFIX_NAME_MAX_LENGTH,
            }}
            variant="outlined"
            onBlur={onBlur}
            onChange={({ target }) => {
              const newId = { prefix, suffix: target.value.toUpperCase() };
              onChange(name)(toChangeEvent<WizardOcppChargePointId>(new Event('change'), newId));
            }}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Typography
        style={{
          fontSize: '0.75rem',
          paddingLeft: '14px',
          paddingTop: '8px',
          color: 'rgba(0, 0, 0, 0.54)',
        }}
      >
        Your OCPP charge point ID is: {`${prefix}-${suffix}`}
      </Typography>
    </Grid>
  </Grid>
);
