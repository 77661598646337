import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React from 'react';
import styled from 'styled-components';
import { environment } from '../config';
import { Workspace } from '../design-system';
import { standardiseError } from '../utils/error';

const StyledAlert = styled(Alert)`
  .MuiAlert-message {
    width: 100%;
  }
`;

const DebugWrapper = styled('div')`
  margin-top: ${(p) => p.theme.spacing(2)}px;
  padding: ${(p) => p.theme.spacing(2)}px;
  background-color: ${(p) => p.theme.palette.blue.light};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  color: ${(p) => p.theme.palette.common.white};
`;

const Description = styled('div')``;

export interface ErrorHandlerProps extends Pick<AlertProps, 'action' | 'elevation' | 'severity' | 'variant'> {
  title?: React.ReactNode;
  description?: React.ReactNode;
  error?: unknown;
}

export const ErrorHandler: React.VFC<ErrorHandlerProps> = ({
  action,
  description,
  elevation,
  error,
  severity = 'error',
  title,
  variant = 'filled',
}: ErrorHandlerProps) => {
  const [showError, setShowError] = React.useState(false);
  const standardisedError = standardiseError(error);
  const { id, meta, title: errName } = standardisedError;
  const errorId = id ?? meta?.correlationId;

  return (
    <>
      <StyledAlert
        severity={severity}
        variant={variant}
        elevation={elevation}
        action={
          <>
            {action}
            {error && (
              <Tooltip title="Show error details">
                <IconButton size="small" color="inherit" onClick={() => setShowError((prev) => !prev)}>
                  {showError ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Tooltip>
            )}
          </>
        }
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {description && <Description>{description}</Description>}
      </StyledAlert>
      {error && (
        <Grow in={showError} unmountOnExit>
          <DebugWrapper>
            <Typography variant="h6">Sorry, something went wrong.</Typography>
            <Typography variant="body2">
              Please send the details below to support@evnex.com and we will help troubleshoot the error.
            </Typography>
            <br />
            {errorId && (
              <Typography variant="body2">
                <strong>Error ID:</strong> {errorId}
              </Typography>
            )}
            {(environment === 'development' || environment === 'jest') && (
              <>
                <Typography variant="body2">
                  <strong>Error Object:</strong>
                </Typography>
                <Box component="pre">{JSON.stringify(error, undefined, 2)}</Box>
                <Typography variant="body2">
                  <strong>Standardised Error:</strong>
                </Typography>
                <Box component="pre">{JSON.stringify(standardisedError, undefined, 2)}</Box>
              </>
            )}
            <Typography variant="body2">
              <strong>Error Name:</strong> {errName}
            </Typography>
            <Typography variant="body2">
              <strong>Date:</strong> {new Date().toLocaleString()}
            </Typography>
          </DebugWrapper>
        </Grow>
      )}
    </>
  );
};

interface DataErrorHandlerProps {
  error: unknown;
  description: string;
  refetch: (() => void) | (() => Promise<void>);
  type?: 'embedded' | 'view';
  severity?: AlertProps['severity'];
}

export const DataErrorHandler: React.VFC<DataErrorHandlerProps> = ({
  description,
  error,
  refetch,
  severity,
  type,
}: DataErrorHandlerProps) => {
  const [err, setErr] = React.useState(error);

  const Error: React.FC = () => (
    <ErrorHandler
      description={description}
      severity={severity}
      error={err}
      action={
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          onClick={() => {
            try {
              const result = refetch();
              if (typeof result !== 'undefined') {
                result.catch((e) => {
                  setErr(e);
                });
              }
            } catch (e) {
              setErr(e);
            }
          }}
        >
          Retry
        </Button>
      }
    />
  );
  return type === 'embedded' ? (
    <Error />
  ) : (
    <Workspace>
      <Error />
    </Workspace>
  );
};

DataErrorHandler.defaultProps = { type: 'view' };
