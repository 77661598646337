import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateThirdPartyChargePoint } from '../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import { errorSnackbar } from '../../../../../components/ErrorSnackbar';
import { Card, Section, Wizard, Workspace } from '../../../../../design-system';
import { usePostChargePointEvnex } from '../../../../../utils/api';
import {
  mapWizardStateToRequestCreateEvnexChargePoint,
  mapWizardStateToRequestCreateThirdPartyChargePoint,
} from '../../../../../utils/chargePoints/mappers';
import { ConfirmationStep } from './components/ConfirmationStep';
import { DetailsStep } from './components/DetailsStep';
import { ElectricityPriceStep } from './components/ElectricityPriceStep';
import { LocationStep } from './components/LocationStep';
import { VendorStep } from './components/VendorStep';
import { CreateChargePointWizardState, CreateThirdPartyChargePointWizardState } from './types';

type OnComplete = (state: CreateChargePointWizardState & CreateThirdPartyChargePointWizardState) => void;

const CreateChargePointView: React.FC = () => {
  const { push } = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { organisation } = useCurrentOrganisation();
  const { slug, id: organisationId } = organisation ?? { slug: '', id: '' };

  const { error: errorEvnex, mutate: mutateEvnex } = usePostChargePointEvnex({});
  const { error: errorOther, mutate: mutateOther } = useCreateThirdPartyChargePoint({ orgId: organisationId });

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (errorOther) {
      errorSnackbar('Unable to add charge point', closeSnackbar, enqueueSnackbar, errorOther);
    }
  }, [errorOther, enqueueSnackbar, closeSnackbar]);

  React.useEffect(() => {
    if (errorEvnex) {
      errorSnackbar('Unable to add charge point', closeSnackbar, enqueueSnackbar, errorEvnex);
    }
  }, [errorEvnex, enqueueSnackbar, closeSnackbar]);

  const onExit = React.useCallback(() => push(`/organisations/${slug}/charge-points`), [push, slug]);

  const onComplete = React.useCallback<OnComplete>(
    async (state) => {
      setIsSubmitting(true);

      try {
        switch (state.vendor) {
          case 'evnex':
            await mutateEvnex(mapWizardStateToRequestCreateEvnexChargePoint({ organisationId, state }));
            break;
          default:
            await mutateOther(mapWizardStateToRequestCreateThirdPartyChargePoint(state));
        }

        enqueueSnackbar('Charge point created', { variant: 'success' });
        push(`/organisations/${slug}/charge-points`);
      } finally {
        setIsSubmitting(false);
      }
    },
    [enqueueSnackbar, mutateEvnex, mutateOther, organisationId, push, slug],
  );

  return (
    <Workspace>
      <Section
        title="Create charge point"
        description="Create a new charge point to your organisation. Once completed you will be able to control and view insights for it."
      >
        <Card>
          <Wizard<CreateChargePointWizardState & CreateThirdPartyChargePointWizardState>
            steps={[
              { id: 'vendor', label: 'Vendor', view: VendorStep },
              { id: 'location', label: 'Location', view: LocationStep },
              { id: 'details', label: 'Details', view: DetailsStep },
              { id: 'electricity-price', label: 'Electricity price', view: ElectricityPriceStep },
              { id: 'confirmation', label: 'Confirmation', view: ConfirmationStep },
            ]}
            onComplete={onComplete}
            onExit={onExit}
            isLoading={isSubmitting}
            hasStepper
          />
        </Card>
      </Section>
    </Workspace>
  );
};

export default CreateChargePointView;
