import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { RouterLink } from '../../../../components/Link';

const initialValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
});

export interface RequestNewCodeFormProps {
  onSubmit: (values: typeof initialValues, helpers: FormikHelpers<typeof initialValues>) => void;
}

export const RequestNewCodeForm: React.FC<RequestNewCodeFormProps> = ({ onSubmit }) => (
  <Fade in>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => onSubmit({ ...values, email: values.email.toLowerCase() }, helpers)}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <Box textAlign="center">
            <Typography>Enter your email to request a new verification code.</Typography>
          </Box>
          <Box mt={3}>
            <Field name="email">
              {({ field }: FieldProps) => (
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  autoFocus
                  error={!!errors.email}
                  helperText={errors.email}
                  disabled={isSubmitting}
                  {...field}
                />
              )}
            </Field>
          </Box>
          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary" size="large" fullWidth disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress size={26} color="inherit" /> : 'Request code'}
            </Button>
          </Box>
          <Box mt={2} textAlign="center">
            <RouterLink to="/auth/signin">Go to sign in screen</RouterLink>
          </Box>
        </Form>
      )}
    </Formik>
  </Fade>
);
