import React from 'react';
import * as Yup from 'yup';
import { SwitchValue } from '../../../../../../../components/CustomFields/Switch';
import { Card, Form, ValueComponentProps } from '../../../../../../../design-system';
import type { SubState } from '../../../../../../../store/types';

export const Details: React.FC<{ card: SubState<'card'> }> = ({ card }) => {
  const { isValid, tokenType, uid } = card;

  const initialValues = {
    isValid,
    tokenType,
    uid,
  };

  return (
    <Card title="Card details">
      <Form<typeof initialValues>
        fields={[
          {
            id: 'isValid',
            label: 'Status',
            valueComponent: ({ value }: ValueComponentProps<boolean>) => (
              <SwitchValue value={value} labels={{ true: 'Enabled', false: 'Disabled' }} />
            ),
          },
          {
            id: 'tokenType',
            label: 'Type',
          },
          {
            id: 'uid',
            label: 'UID',
          },
        ]}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({})}
        onSubmit={() => {}}
      />
    </Card>
  );
};
