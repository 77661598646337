import { FormikValues } from 'formik';
import * as React from 'react';
import { uid } from 'react-uid';
import { Field } from '../../../Field';
import { List, ListItem } from '../../../List';
import { FormField, InitialValues } from '../../types';
import { getNestedValue } from '../../utils';

export interface FormReadonlyProps<V = InitialValues> {
  fields: FormField[];
  initialValues: V;
  inset?: boolean;
}

export function FormReadonly<Values extends FormikValues>({
  fields,
  initialValues,
  inset,
}: FormReadonlyProps<Values>): React.ReactElement {
  return (
    <List inset={inset}>
      {fields.map((field, idx) => {
        const { valueComponent: Value } = field;
        const value = getNestedValue(field.id, initialValues) as React.ReactNode; // TODO

        return (
          <ListItem key={uid(field, idx)}>
            <Field label={field.label}>{Value ? <Value value={value} /> : value}</Field>
          </ListItem>
        );
      })}
    </List>
  );
}
