import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { rgba } from 'polished';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuthenticatedUser } from '../../app/AuthenticatedUserProvider';
import { labelsFor, OrganisationTier } from '../../app/enums';
import { getOrganisationAvatar, OrganisationAvatar } from '../../components/Avatar/Organisation';
import { Card, Section, useNavigationView, Workspace } from '../../design-system';
import { mainNav } from '../navView';

const OrganisationHeader = styled(Link)`
  padding: ${(p) => p.theme.spacing(2)}px;
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  text-decoration: none;
  transition: background-color
    ${({ theme: { transitions } }) => `${transitions.easing.easeInOut} ${transitions.duration.shortest}`}ms;

  &:hover {
    background-color: ${(p) => rgba(p.theme.palette.secondary.main, 0.03)};
  }

  ${OrganisationAvatar} {
    height: 48px;
    width: 48px;
    font-size: 24px;
  }
`;

const Primary = styled(Typography)`
  max-width: calc(100% - 48px - 16px);
  font-weight: ${(p) => p.theme.typography.fontWeightMedium};
`;

const Secondary = styled(Typography)`
  max-width: calc(100% - 48px - 16px);
`;

const OrganisationPicker: React.FC = () => {
  useNavigationView(mainNav);
  const { authenticatedUser: user } = useAuthenticatedUser();

  const organisations = React.useMemo(() => {
    if (!user?.organisations) {
      return [];
    }

    const orgs = [...user.organisations];
    orgs.sort((a, b) => a.name.localeCompare(b.name));
    return orgs;
  }, [user]);

  return (
    <Workspace maxWidth="lg">
      <Section title="Your organisations" description="Select the organisation you want to view.">
        <Grid container spacing={3}>
          {organisations.map(({ id, name, slug, tier, color, avatar }) => (
            <Grid key={id} item xs={12} sm={6} md={4}>
              <Card>
                <OrganisationHeader to={`/organisations/${slug}`} tabIndex={0}>
                  <Box mr={2}>{getOrganisationAvatar({ name, color, avatar })}</Box>
                  <Box width="100%">
                    <Primary variant="body2" color="textPrimary" noWrap>
                      {name}
                    </Primary>
                    <Secondary variant="body2" color="textSecondary" noWrap>
                      {labelsFor(OrganisationTier)[tier]}
                    </Secondary>
                  </Box>
                </OrganisationHeader>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Section>
    </Workspace>
  );
};

export default OrganisationPicker;
