import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { ListLabel } from './Label';
import { Empty } from './Empty';
import { includesSearch } from '../utils/search';
import { EmptyGraphic, List, ListItem, Section } from '../design-system';

export interface SelectableItem {
  id: string;
  title: string;
  subtitle: string;
  color?: string;
  image?: string;
}

export interface SelectionListProps {
  description?: string;
  error?: string;
  getImage: (item: SelectableItem) => JSX.Element;
  items: SelectableItem[];
  label: string;
  lowerActions?: JSX.Element;
  noItemsDescription?: string;
  onSelectItem: (item: SelectableItem) => void;
  upperActions?: JSX.Element;
}

const searchItems = (items: SelectableItem[], search: string) =>
  items.filter(({ title, subtitle }) => includesSearch(title, search) || includesSearch(subtitle, search));

export const SelectionList: React.FC<SelectionListProps> = ({
  description,
  error,
  getImage,
  items,
  label,
  lowerActions,
  noItemsDescription,
  onSelectItem,
  upperActions,
}: SelectionListProps) => {
  const [search, setSearch] = React.useState<string>('');
  const onSearchChange = React.useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => setSearch(target.value),
    [setSearch],
  );

  const visibleItems: SelectableItem[] = searchItems(items, search);

  return (
    <>
      <Section title={`Select ${label}`} description={description}>
        <Box display="flex" alignItems="center">
          <TextField
            autoFocus
            placeholder={`Search ${label}s`}
            value={search}
            onChange={onSearchChange}
            margin="dense"
            variant="outlined"
            style={{ margin: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="inherit" fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={!search} onClick={() => setSearch('')} size="small">
                    <ClearIcon color="inherit" fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box mr="auto" />
          {upperActions}
        </Box>
        {error && (
          <Box pt={2}>
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          </Box>
        )}
        <Box pt={2}>
          {visibleItems.length > 0 ? (
            <List>
              {visibleItems.map(({ id, title, subtitle }) => (
                <ListItem key={id} onClick={() => onSelectItem({ id, title, subtitle })}>
                  <Box display="flex" flexDirection="row">
                    {getImage({ id, title, subtitle })}
                    <ListLabel>
                      <Typography variant="body2">{title}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {subtitle}
                      </Typography>
                    </ListLabel>
                  </Box>
                </ListItem>
              ))}
            </List>
          ) : (
            <Box mx="auto" my={2} width="50%" textAlign="center">
              <Empty graphic={EmptyGraphic} title={`No ${label}s found`} description={noItemsDescription ?? ''} />
            </Box>
          )}
        </Box>
      </Section>
      <Box pt={2}>{lowerActions}</Box>
    </>
  );
};
