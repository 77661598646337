import type {
  RequestCommandChangeConfiguration,
  RequestCommandDataTransfer,
  RequestCommandReset,
  RequestCommandUpdateFirmware,
} from '../../../app/ApiGen';
import { Normalised } from '../../../utils/request';
import { ChargePoint } from '../../../app/ApiGen';

export enum CommandType {
  NO_COMMAND = '',
  UPDATE_FIRMWARE = 'update-firmware',
  RESET = 'reset',
  CHANGE_CONFIGURATION = 'change-configuration',
  DATA_TRANSFER = 'data-transfer',
}

export type CommandState = {
  command: { type: CommandType } & Partial<
    UpdateFirmwareStepFields & ResetStepFields & ChangeConfigurationStepFields & DataTransferStepFields
  >;
};

export type CommandsWizardState = {
  chargePoints: ChargePointState[];
} & CommandState;

export type ChargePointState = Pick<Normalised<ChargePoint>, 'id' | 'name' | 'ocppChargePointId'>;

export type UpdateFirmwareStepFields = { firmwareUrl: RequestCommandUpdateFirmware['location'] };

export type ResetStepFields = { resetType: RequestCommandReset['resetType'] };

export type DataTransferStepFields = { messageId: RequestCommandDataTransfer['messageId'] };

export type ChangeConfigurationStepFields = {
  configurationKey: RequestCommandChangeConfiguration['key'];
  configurationValue: RequestCommandChangeConfiguration['value'];
};
