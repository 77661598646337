import React from 'react';
import { CommandsWizardState, CommandType } from '../types';
import { UpdateFirmwareStep } from './UpdateFirmwareStep';
import { ResetStep } from './ResetStep';
import { ChangeConfigurationStep } from './ChangeConfigurationStep';
import { useWizard } from '../../../../design-system';
import { DataTransferStep } from './DataTransferStep';

export const MetadataStep: React.FC = () => {
  const { state: untypedState } = useWizard();
  const state = untypedState as Partial<CommandsWizardState>;

  switch (state.command?.type) {
    case CommandType.UPDATE_FIRMWARE:
      return <UpdateFirmwareStep />;
    case CommandType.RESET:
      return <ResetStep />;
    case CommandType.CHANGE_CONFIGURATION:
      return <ChangeConfigurationStep />;
    case CommandType.DATA_TRANSFER:
      return <DataTransferStep />;
    default:
      return <></>;
  }
};
