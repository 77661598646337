import React from 'react';
import { useSnackbar } from 'notistack';
import type { Job } from '../../app/ApiGen';
import { useUpdateJob, useUpdateJobCustomer } from '../../app/ApiGen';
import {
  CustomerDetails,
  customerDetailsFields,
  customerDetailsValidationSchema,
  toCustomerDetailsFields,
} from '../../utils/jobs/customer';
import type { Normalised } from '../../utils/request';
import { errorSnackbar } from '../ErrorSnackbar';
import { Card, Form, OnSubmit, Section } from '../../design-system';

interface CustomerDetailsSectionProps {
  job: Normalised<Job>;
  refetchJob: () => Promise<void>;
}

export const CustomerDetailsSection: React.VFC<CustomerDetailsSectionProps> = ({
  job,
  refetchJob,
}: CustomerDetailsSectionProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isEditing, setIsEditing] = React.useState<boolean>();

  const { id: jobId, customer } = job;
  const customerId = customer?.id ?? '';

  const { mutate: updateCustomer } = useUpdateJobCustomer({ jobId });
  const { mutate: updateJob } = useUpdateJob({ jobId });

  const onSubmit = React.useCallback<OnSubmit<CustomerDetails>>(
    async ({ contactPhoneNumber, customerName }, { setSubmitting }) => {
      try {
        await updateCustomer({ data: { id: customerId, type: 'users', attributes: { name: customerName } } });
        await updateJob({ data: { id: job.id, type: 'jobs', attributes: { contactPhoneNumber } } });
        enqueueSnackbar('Customer updated', { variant: 'success' });
        setSubmitting(false);
        setIsEditing(false);
        await refetchJob();
      } catch (err) {
        setSubmitting(false);
        errorSnackbar('Unable to edit customer', closeSnackbar, enqueueSnackbar, err);
      }
    },
    [closeSnackbar, customerId, enqueueSnackbar, job.id, refetchJob, updateCustomer, updateJob],
  );

  return (
    <Section>
      <Card title="Customer">
        <Form<CustomerDetails>
          fields={Object.values(
            customerDetailsFields({
              emailDisabled: true,
              emailHelperText:
                'Please contact Evnex support on 0800 395 007 (NZ) or 1800 959 377 (Aus) if you need to edit the customer email address',
            }),
          )}
          initialValues={toCustomerDetailsFields(job)}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          onSubmit={onSubmit}
          validationSchema={customerDetailsValidationSchema}
        />
      </Card>
    </Section>
  );
};
