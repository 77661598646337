import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ConfirmedGraphic } from '../../../../design-system';

export const ChangePasswordComplete: React.FC = () => (
  <>
    <ConfirmedGraphic my={2} textAlign="center" svgProps={{ style: { maxWidth: '40%', height: '100%' } }} />
    <Box textAlign="center">
      <Typography>Your password has been successfully changed.</Typography>
    </Box>
    <Box mt={2} textAlign="center">
      <Button color="primary" variant="outlined" component={RouterLink} to="/user">
        Back to settings
      </Button>
    </Box>
  </>
);
