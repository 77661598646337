import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import { useAuthenticatedUser } from '../../../../app/AuthenticatedUserProvider';
import { ColorInput, ColorValue } from '../../../../components/CustomFields/Color';
import { OrganisationInput, OrganisationValue } from '../../../../components/CustomFields/Organisation';
import { errorSnackbar } from '../../../../components/ErrorSnackbar';
import { Card, Form, OnSubmit } from '../../../../design-system';
import { usePutUser } from '../../../../utils/api';
import { createSchema } from '../../../../utils/formUtils';

type UserDetails = {
  name: string;
  color: string;
  defaultOrganisationId: string;
};

const validationSchema = createSchema<UserDetails>({
  // eslint-disable-next-line no-template-curly-in-string --- Yup messages can be customised using template curlies in strings.
  name: Yup.string().required('Name is required').min(3, 'Name must be at least ${min} characters'),
  color: Yup.string()
    .matches(/^#[0-9a-f]{6}$/i, 'Color must be a valid 6 character hex color beginning with the # character')
    .required('Color is required'),
  defaultOrganisationId: Yup.string().required('Default organisation is required'),
});

export const ProfileForm: React.FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { authenticatedUser: user, refetch } = useAuthenticatedUser();

  const { error, mutate } = usePutUser({});

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to edit user details', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  const [isEditing, setIsEditing] = React.useState(false);

  const initialValues: UserDetails = {
    name: user?.name ?? '',
    color: user?.color ? `#${user.color}` : '#bdbdbd',
    defaultOrganisationId: user?.organisations.find(({ isDefault }) => isDefault)?.id ?? '',
  };

  const onSubmit = React.useCallback<OnSubmit<UserDetails>>(
    async (fields, { setSubmitting }) => {
      try {
        await mutate({ ...fields, color: `${fields.color}`.substr(1, 7) });
        enqueueSnackbar('User details updated', { variant: 'success' });
        setIsEditing(false);
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [mutate, enqueueSnackbar, refetch],
  );

  if (!user) {
    return null;
  }

  return (
    <Card title="Your details">
      <Form
        fields={[
          {
            id: 'name',
            label: 'Name',
          },
          {
            id: 'color',
            label: 'Color',
            valueComponent: ColorValue,
            inputComponent: ColorInput,
          },
          {
            id: 'defaultOrganisationId',
            label: 'Default organisation',
            valueComponent: ({ value }) => (
              <OrganisationValue value={user.organisations.find(({ id }) => id === value)} />
            ),
            inputComponent: (props) => <OrganisationInput organisations={user.organisations} {...props} />,
          },
        ]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </Card>
  );
};
