import AddBoxIcon from '@material-ui/icons/AddBox';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SearchIcon from '@material-ui/icons/Search';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import MaterialTable, { Icons, MaterialTableProps } from 'material-table';
import React from 'react';
import { Toolbar, ToolbarProps } from './components/Toolbar';
import { StyledTable } from './styled';

const tableIcons: Icons = {
  Add: React.forwardRef((props, ref) => <AddBoxIcon {...props} ref={ref} />),
  Check: React.forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
  Clear: React.forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Delete: React.forwardRef((props, ref) => <DeleteOutlineIcon {...props} ref={ref} />),
  DetailPanel: React.forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
  Edit: React.forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
  Export: React.forwardRef((props, ref) => <SaveAltIcon {...props} ref={ref} />),
  Filter: React.forwardRef((props, ref) => <FilterListIcon {...props} ref={ref} />),
  FirstPage: React.forwardRef((props, ref) => <FirstPageIcon {...props} ref={ref} />),
  LastPage: React.forwardRef((props, ref) => <LastPageIcon {...props} ref={ref} />),
  NextPage: React.forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref} />),
  PreviousPage: React.forwardRef((props, ref) => <ChevronLeftIcon {...props} ref={ref} />),
  ResetSearch: React.forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
  Search: React.forwardRef((props, ref) => <SearchIcon {...props} ref={ref} />),
  SortArrow: React.forwardRef((props, ref) => <ArrowUpwardIcon {...props} ref={ref} />),
  ThirdStateCheck: React.forwardRef((props, ref) => <RemoveIcon {...props} ref={ref} />),
  ViewColumn: React.forwardRef((props, ref) => <ToggleOffIcon {...props} ref={ref} />),
};
const obj = {};

export type DataTableProps<Data extends typeof obj> = {
  toolbarProps?: Omit<ToolbarProps, 'tableProps'>;
} & MaterialTableProps<Data>;

function DataTable<Data extends typeof obj>({
  toolbarProps,
  components,
  icons,
  options,
  ...rest
}: DataTableProps<Data>): React.ReactElement {
  return (
    <StyledTable>
      <MaterialTable
        components={{
          Container: (props: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />,
          Toolbar: (props: MaterialTableProps<Record<string, unknown>>) => (
            <Toolbar {...toolbarProps} tableProps={props} />
          ),
          ...components,
        }}
        icons={{ ...tableIcons, ...icons }}
        options={{
          ...options,
          showTitle: false,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],
          searchFieldAlignment: 'left',
        }}
        {...rest}
      />
    </StyledTable>
  );
}

DataTable.displayName = 'DataTable';

export { DataTable };
