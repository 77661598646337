/* Generated by restful-react */

import React from 'react';
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from 'restful-react';
export const SPEC_VERSION = '0.0.44';
/**
 * An API error response object. The errors array will contain object(s) with more information about exactly what went wrong with the request
 */
export interface APIError {
  /**
   * An application-specific error code
   */
  code: number;
  /**
   * A human-readable explanation specific to this occurrence of the problem
   */
  message?: string;
  correlationId?: string;
  /**
   * A short, human-readable summary of the problem
   */
  title: string;
}

/**
 * Address for a location
 */
export interface Address {
  /**
   * Address line 1
   */
  address1: string;
  /**
   * Address line 2
   */
  address2?: string;
  /**
   * Address line 3
   */
  address3?: string;
  /**
   * City or town
   */
  city: string;
  /**
   * Country
   */
  country: string;
  /**
   * Post code
   */
  postCode?: string;
  /**
   * State or province
   */
  state?: string;
}

/**
 * A complete charge point object
 */
export type ChargePoint = ChargePointInfoWithLocation & {
  /**
   * Configuration settings
   */
  configuration?: {
    /**
     * Maximum current of the charge point
     */
    maxCurrent?: number;
    /**
     * If the charge point has plug and charge enabled
     */
    plugAndCharge?: boolean;
  };
  electricityCost?: ElectricityCost;
  loadManagement?: LoadManagement;
  loadSchedule?: LoadSchedule;
  rates?: Rate[];
  /**
   * Whether solar is turned on, on the charge point
   */
  isSolarEnabled?: boolean;
};

/**
 * Add a third party charge point to the system
 */
export interface ChargePointAdd {
  /**
   * Configuration settings
   */
  configuration?: {
    /**
     * If the charge point has plug and charge enabled
     */
    plugAndCharge?: boolean;
  };
  /**
   * The charge point's connectors
   */
  connectors: ConnectorCreate[];
  electricityCost?: ElectricityCostCreate;
  /**
   * Location id to assign this charge point to
   */
  locationId: string;
  metadata?: ChargePointMetadata;
  /**
   * Display name for this charge point
   */
  name: string;
  /**
   * Maximum charging current the charge point can draw
   */
  maxCurrent: number;
  /**
   * If a token is required to start charging
   */
  tokenRequired: boolean;
  /**
   * Default token to be set on charge point sessions
   */
  defaultToken?: string;
  /**
   * Unique OCCP id for this charge point
   */
  ocppChargePointId: string;
  /**
   * Organisation id to assign this charge point to
   */
  organisationId: string;
}

/**
 * Claim a Evnex charge point
 */
export interface ChargePointClaim {
  /**
   * Configuration settings
   */
  configuration?: {
    /**
     * If the charge point has plug and charge enabled
     */
    plugAndCharge?: boolean;
  };
  electricityCost?: ElectricityCostCreate;
  /**
   * Location id to assign this charge point to
   */
  locationId: string;
  metadata?: ChargePointMetadata;
  /**
   * Display name for this charge point
   */
  name: string;
  /**
   * Organisation id to assign this charge point to
   */
  organisationId: string;
  /**
   * Serial number of the Evnex charge point being claimed
   */
  serial: string;
  /**
   * Default token to be set on charge point sessions
   */
  defaultToken?: string;
  /**
   * If a token is required to start charging
   */
  tokenRequired?: boolean;
  /**
   * Maximum charging current the charge point can draw
   */
  maxCurrent?: number;
}

/**
 * A charge point summary object (without location)
 */
export interface ChargePointInfo {
  /**
   * Connectors on the charge point
   */
  connectors: Connector[];
  /**
   * Date this charge point was created
   */
  createdDate: string;
  /**
   * Maximum charging current the charge point can draw
   */
  maxCurrent: number;
  /**
   * If a token is required to start charging
   */
  tokenRequired: boolean;
  /**
   * Default token to be set on charge point sessions
   */
  defaultToken?: string;
  /**
   * Whether the charge point needs registration information
   */
  needsRegistrationInformation: boolean;
  /**
   * Details about the charge point
   */
  details: {
    /**
     * Charge point's firmware version
     */
    firmware?: string;
    /**
     * Charge point's iccid
     */
    iccid?: string;
    /**
     * Charge point's model
     */
    model?: string;
    /**
     * Charge point's vendor
     */
    vendor?: string;
  };
  /**
   * Charge point's id
   */
  id: string;
  /**
   * Charge point's OCCP ID
   */
  ocppChargePointId?: string;
  /**
   * Time that the charge point last contacted the server
   */
  lastHeard?: string;
  metadata?: ChargePointMetadata;
  /**
   * Charge point's display name
   */
  name: string;
  networkStatus: NetworkStatus;
  /**
   * Serial of the charge point
   */
  serial?: string;
  /**
   * Date the charge point network status was last updated
   */
  networkStatusUpdatedDate: string;
  /**
   * Date this charge point was last updated
   */
  updatedDate: string;
}

/**
 * A charge point summary object (with location)
 */
export type ChargePointInfoWithLocation = ChargePointInfo & {
  location: LocationInfo;
};

/**
 * A charge point log event
 */
export interface ChargePointLog {
  /**
   * The date that the charge point created the log event
   */
  chargePointEventDate?: string;
  /**
   * The event's data
   */
  data?: { [key: string]: any };
  event: ChargePointLogEventType;
  /**
   * The date that the server recieved the log event
   */
  serverEventDate: string;
  /**
   * The OCPP message ID of the event, if applicable
   */
  messageId?: string;
}

/**
 * Charge point log event types
 */
export type ChargePointLogEventType =
  | 'Authorize'
  | 'BootNotification'
  | 'DataTransfer'
  | 'DiagnosticsStatusNotification'
  | 'FirmwareStatusNotification'
  | 'Heartbeat'
  | 'MeterValues'
  | 'StartTransaction'
  | 'StatusNotification'
  | 'StopTransaction'
  | 'CancelReservation'
  | 'ChangeAvailability'
  | 'ChangeConfiguration'
  | 'ClearCache'
  | 'ClearChargingProfile'
  | 'GetCompositeSchedule'
  | 'GetConfiguration'
  | 'GetDiagnostics'
  | 'GetLocalListVersion'
  | 'RemoteStartTransaction'
  | 'RemoteStopTransaction'
  | 'ReserveNow'
  | 'Reset'
  | 'SendLocalList'
  | 'SetChargingProfile'
  | 'TriggerMessage'
  | 'UnlockConnector'
  | 'UpdateFirmware'
  | 'AuthorizeResp'
  | 'BootNotificationResp'
  | 'DataTransferResp'
  | 'DiagnosticsStatusNotificationResp'
  | 'FirmwareStatusNotificationResp'
  | 'HeartbeatResp'
  | 'MeterValuesResp'
  | 'StartTransactionResp'
  | 'StatusNotificationResp'
  | 'StopTransactionResp'
  | 'CancelReservationResp'
  | 'ChangeAvailabilityResp'
  | 'ChangeConfigurationResp'
  | 'ClearCacheResp'
  | 'ClearChargingProfileResp'
  | 'GetCompositeScheduleResp'
  | 'GetConfigurationResp'
  | 'GetDiagnosticsResp'
  | 'GetLocalListVersionResp'
  | 'RemoteStartTransactionResp'
  | 'RemoteStopTransactionResp'
  | 'ReserveNowResp'
  | 'ResetResp'
  | 'SendLocalListResp'
  | 'SetChargingProfileResp'
  | 'TriggerMessageResp'
  | 'UnlockConnectorResp'
  | 'UpdateFirmwareResp'
  | 'WebSocketConnection'
  | 'WebSocketClose';

/**
 * Charge point metadata
 */
export interface ChargePointMetadata {
  /**
   * reference id of charge point
   */
  referenceId?: string;
}

/**
 * Charge point update object
 */
export interface ChargePointUpdate {
  /**
   * Configuration settings
   */
  configuration?: {
    /**
     * If the charge point has plug and charge enabled
     */
    plugAndCharge?: boolean;
  };
  electricityCost?: ElectricityCostCreate;
  /**
   * Location id to assign this charge point to
   */
  locationId?: string;
  metadata?: ChargePointMetadata;
  /**
   * Display name for the charge point
   */
  name?: string;
  /**
   * Maximum charging current the charge point can draw
   */
  maxCurrent?: number;
  /**
   * If a token is required to start charging
   */
  tokenRequired?: boolean;
  /**
   * Default token to be set on charge point sessions
   */
  defaultToken?: string;
}

/**
 * Charging period profile used by load management and scheduling
 */
export interface ChargingProfilePeriod {
  /**
   * Charging limit of the profile
   */
  limit: number;
  /**
   * Time in seconds that this profile begins
   */
  start: number;
}

/**
 * A color string
 */
export type Color = string;

/**
 * Object used send command to change availability
 */
export interface CommandChangeAvailability {
  /**
   * Type of availability change that the Charge Point should perform.
   */
  changeAvailabilityType?: 'Inoperative' | 'Operative';
  connectorId: number;
}

/**
 * Object used to send the command to change configuration
 */
export interface CommandChangeConfiguration {
  /**
   * The name of the configuration setting to change
   */
  key: string;
  /**
   * The new value as string for the setting.
   */
  value: string;
}

/**
 * Object used send command remote start transaction
 */
export interface CommandRemoteStartTransaction {
  chargingProfile?: { [key: string]: any };
  connectorId?: number;
  tokenUid: string;
}

/**
 * Object used send command remote stop transaction
 */
export interface CommandRemoteStopTransaction {
  connectorId: number;
}

/**
 * Object used send command reset
 */
export interface CommandReset {
  /**
   * Type of reset that the charge point should perform
   */
  resetType: 'Hard' | 'Soft';
}

/**
 * Command status response
 */
export type CommandResponseStatus = 'Accepted' | 'Rejected';

/**
 * Object used send a unlock connector command
 */
export interface CommandUnlockConnector {
  /**
   * Id of the connector to unlock
   */
  connectorId: number;
}

/**
 * Object used send an update firmware command
 */
export interface CommandUpdateFirmware {
  /**
   * URI point to a location from which to retrieve the firmware
   */
  location: string;
  /**
   * How many times for the charge point to retry the download
   */
  retries?: number;
  /**
   * Date and time after which the charge point is allowed to retrieve the new firmware
   */
  retrieveDate?: string;
  /**
   * Seconds after which a retry may be attempted
   */
  retryInterval?: number;
}

/**
 * Charge point connector object
 */
export interface Connector {
  /**
   * Connector amperage value
   */
  amperage: number;
  connectorFormat: ConnectorFormat;
  /**
   * Id of the connector
   */
  connectorId: string;
  connectorType: ConnectorType;
  /**
   * Unique EVSE id of the connector
   */
  evseId: string;
  /**
   * The current meter values for the connector
   */
  meter?: MeterValuesAC1Phase | MeterValuesAC3Phase | MeterValuesDC;
  /**
   * OCPP code
   */
  ocppCode?: string;
  ocppStatus: OcppStatus;
  powerType: PowerType;
  status: ConnectorStatus;
  /**
   * Date this connector was last updated
   */
  updatedDate: string;
  /**
   * Connector voltage value
   */
  voltage: number;
}

/**
 * Object used to create a new charge point connector
 */
export interface ConnectorCreate {
  /**
   * Connector amperage value
   */
  amperage: number;
  connectorFormat: ConnectorFormat;
  connectorType: ConnectorType;
  powerType: PowerType;
  /**
   * Connector voltage value
   */
  voltage: number;
}

/**
 * Format of a connector
 */
export type ConnectorFormat = 'SOCKET' | 'CABLE';

/**
 * Status of a connector
 */
export type ConnectorStatus = 'CHARGING' | 'OCCUPIED' | 'RESERVED' | 'AVAILABLE' | 'DISABLED' | 'FAULTED' | 'OFFLINE';

/**
 * Standard of a connector. (`ConnectorType` in OCPI)
 */
export type ConnectorType =
  | 'CHADEMO'
  | 'IEC_62196_T1'
  | 'IEC_62196_T1_COMBO'
  | 'IEC_62196_T2'
  | 'IEC_62196_T2_COMBO'
  | 'IEC_62196_T3A'
  | 'IEC_62196_T3C';

/**
 * GPS coordinates
 */
export interface Coordinates {
  latitude: string;
  longitude: string;
}

/**
 * Cost object used by electricity pricing
 */
export interface Cost {
  /**
   * Price of electricity
   */
  cost: number;
  /**
   * Time in seconds that this cost begins
   */
  start: number;
}

/**
 * An ISO 4217 currency code
 */
export type Currency = string;

/**
 * A duration in seconds. Either `86400` seconds (daily) or `604800` seconds (weekly)
 */
export type Duration = 86400 | 604800;

/**
 * Object representing electricity costs over a period of time
 */
export interface ElectricityCost {
  /**
   * An array of cost objects that represent the changes in price over the duration
   */
  costs: Cost[];
  currency: Currency;
  duration: Duration;
}

/**
 * Object used to create a new electricity cost
 */
export interface ElectricityCostCreate {
  /**
   * An array of cost objects that represent the changes in price over the duration
   */
  costs: Cost[];
  currency: Currency;
  duration: Duration;
}

/**
 * Electricity unit type
 */
export type ElectricityUnit = 'A' | 'W';

/**
 * Object used to send command to get a configured value
 */
export interface GetConfigurationKeyValue {
  /**
   * The name of the configuration setting
   */
  key: string;
  /**
   * Whether the value can be configured.
   */
  readonly: boolean;
  /**
   * The value of the configuration setting
   */
  value?: string;
}

/**
 * Load balancing profile for a location
 */
export interface LoadBalancing {
  /**
   * Date the profile was created
   */
  createdDate: string;
  /**
   * If the load balancing profile is enabled
   */
  enabled: boolean;
  /**
   * Maximum load amount
   */
  limit: number;
  units: ElectricityUnit;
  /**
   * Date the profile was last updated
   */
  updatedDate: string;
}

/**
 * Object used to update a load balancing profile
 */
export interface LoadBalancingUpdate {
  /**
   * If the load balancing profile is enabled
   */
  enabled: boolean;
  /**
   * Maximum load amount
   */
  limit: number;
  units: ElectricityUnit;
}

/**
 * Load management profile for a charge point
 */
export interface LoadManagement {
  /**
   * An array of charging profile periods
   */
  chargingProfilePeriods?: ChargingProfilePeriod[];
  duration: Duration;
  /**
   * If the load management profile is enabled
   */
  enabled: boolean;
  /**
   * Time zone of the location where profile is applied
   */
  timezone?: string;
  units: ElectricityUnit;
}

/**
 * Create or update Load management profile for a charge point
 */
export interface LoadManagementUpdate {
  /**
   * An array of charging profile periods
   */
  chargingProfilePeriods: ChargingProfilePeriod[];
  duration: Duration;
  /**
   * If the load management profile is enabled
   */
  enabled: boolean;
  units: ElectricityUnit;
}

/**
 * Load schedule profile for a location
 */
export interface LoadSchedule {
  /**
   * An array of charging profile periods
   */
  chargingProfilePeriods?: ChargingProfilePeriod[];
  duration: Duration;
  /**
   * If the load management profile is enabled
   */
  enabled: boolean;
  /**
   * Time zone of the location where profile is applied
   */
  timezone?: string;
  units: ElectricityUnit;
}

/**
 * Create or update Load Schedule profile for a charge point
 */
export interface LoadScheduleUpdate {
  /**
   * An array of charging profile periods
   */
  chargingProfilePeriods: ChargingProfilePeriod[];
  duration: Duration;
  /**
   * If the load management profile is enabled
   */
  enabled: boolean;
  units: ElectricityUnit;
}

/**
 * A complete location object
 */
export type Location = LocationInfo & {
  /**
   * A list of charge points at this location
   */
  chargePoints: ChargePointInfo[];
  rates?: Rate[];
  timeZone: Timezone;
  /**
   * Whether a location has been enabled for public use
   */
  isPublic?: boolean;
};

/**
 * Location summary object
 */
export interface LocationInfo {
  address: Address;
  /**
   * Number of charge points at this location
   */
  chargePointCount: number;
  coordinates: Coordinates;
  /**
   * Date the location was created
   */
  createdDate: string;
  /**
   * Location's id
   */
  id: string;
  /**
   * Location's display name
   */
  name: string;
  settings?: LocationSettings;
  /**
   * Date the location was last updated
   */
  updatedDate: string;
}

/**
 * Settings for a location
 */
export interface LocationSettings {
  loadBalancing?: LoadBalancing;
}

/**
 * Object used to update settings for a location
 */
export interface LocationSettingsUpdate {
  loadBalancing?: LoadBalancingUpdate;
}

/**
 * Connector meter values
 */
export interface MeterValues {
  /**
   * Current frequency in hertz (Hz)
   */
  frequency?: number;
  /**
   * Current power in kilowatts (kW)
   */
  power?: number;
  powerType: PowerType;
  /**
   * The meter's power register in watt hours (Wh)
   */
  register?: number;
  /**
   * Date the connector was last updated
   */
  updatedDate: string;
}

/**
 * Meter values for a AC 1 phase connector
 */
export type MeterValuesAC1Phase = MeterValues & {
  /**
   * Current in amps (A)
   */
  current?: number;
  /**
   * Current voltage in volts (V)
   */
  voltage?: number;
};

/**
 * Meter values for a AC 3 phase connector
 */
export type MeterValuesAC3Phase = MeterValues & {
  /**
   * Current in amps (A) for each phase
   */
  current?: {
    '1'?: number;
    '2'?: number;
    '3'?: number;
  };
  /**
   * Current voltage in volts (V) for each phase
   */
  voltage?: {
    '1'?: number;
    '2'?: number;
    '3'?: number;
  };
};

/**
 * Meter values for a DC connector
 */
export type MeterValuesDC = MeterValues & {
  /**
   * Current in amps (A)
   */
  current?: number;
  /**
   * Percentage of battery charged
   */
  stateOfCharge?: number;
  /**
   * Current temperature in degrees celsius (°C)
   */
  temperature?: number;
  /**
   * Current voltage in volts (V)
   */
  voltage?: number;
};

/**
 * Charge point network connectivity
 */
export type NetworkStatus = 'ONLINE' | 'OFFLINE' | 'NEVER_CONNECTED';

/**
 * Charge point OCPP status
 */
export type OcppStatus =
  | 'AVAILABLE'
  | 'PREPARING'
  | 'CHARGING'
  | 'SUSPENDED_EV'
  | 'SUSPENDED_EVSE'
  | 'FINISHING'
  | 'RESERVED'
  | 'UNAVAILABLE'
  | 'FAULTED';

/**
 * Organisation summary object
 */
export interface OrganisationInfo {
  /**
   * Organisation's avatar url
   */
  avatar?: string;
  color?: Color;
  /**
   * Organisation's id
   */
  id: string;
  /**
   * Organisation's display name
   */
  name: string;
  slug: OrganisationSlug;
  tier: Tier;
}

/**
 * A member of an organisation
 */
export type OrganisationMember = UserInfo & {
  role: Role;
};

/**
 * Unique URL for an organisation
 */
export type OrganisationSlug = string;

/**
 * Charge point connector power type
 */
export type PowerType = 'AC_1_PHASE' | 'AC_3_PHASE' | 'DC';

export interface Rate {
  [key: string]: any;
}

export interface ResponseApiErrors {
  errors: APIError[];
}

/**
 * Charge point get configuration response
 */
export interface ResponseCommandGetConfiguration {
  data: {
    items?: GetConfigurationKeyValue[];
  };
}

export interface ResponseGetChargePoint {
  data: ChargePoint;
}

export interface ResponseGetChargePointLogs {
  data: {
    items: ChargePointLog[];
  };
}

export interface ResponseGetOrganisationMembers {
  data: {
    items: OrganisationMember[];
  };
}

export interface ResponseGetOrganisationSummaryChargePoints {
  data: {
    items: SummaryChargePoint[];
  };
}

/**
 * Charge point command response
 */
export interface ResponsePostCommand {
  data: {
    message?: string;
    status: CommandResponseStatus;
  };
}

export interface ResponsePostEvnexChargePoint {
  data: {
    chargePointId: string;
  };
}

export interface ResponsePostOtherChargePoint {
  data: {
    chargePointId: string;
  };
}

export interface ResponsePostToken {
  data: Token;
}

export interface ResponsePostUser {
  data: User;
}

export interface ResponsePutChargePoint {
  data: {
    chargePointId: string;
  };
}

export interface ResponsePutChargeSchedule {
  data: LoadSchedule;
}

export interface ResponsePutLoadManagement {
  data: LoadManagement;
}

export interface ResponsePutToken {
  data: Token;
}

export interface ResponsePutUser {
  data: User;
}

/**
 * A role for a user in an organisation
 */
export type Role = 0 | 1 | 2;

export interface SummaryChargePoint {
  /**
   * Current connector statuses
   */
  connectorStatuses: {
    /**
     * Connector ID
     */
    id: string;
    status: ConnectorStatus;
  }[];
  /**
   * Total costs
   */
  cost: SummaryCost[];
  /**
   * Total duration charged for
   */
  duration: number;
  /**
   * Charge point ID
   */
  id: string;
  /**
   * Time that the charge point last contacted the server
   */
  lastHeard?: string;
  /**
   * Location name
   */
  locationName: string;
  /**
   * Charge point name
   */
  name: string;
  /**
   * Total number of sessions
   */
  sessions: number;
}

/**
 * Summary total cost for one currency
 */
export interface SummaryCost {
  cost: number;
  currency: Currency;
}

/**
 * The tier of an organisation
 */
export type Tier = 1 | 2 | 3 | 4;

/**
 * One of IANA tzdata's TZ-values representing the time zone of the location (see: http://www.iana.org/time-zones)
 */
export type Timezone = string;

/**
 * A complete token object
 */
export type Token = TokenInfo & {
  /**
   * Date the token was created
   */
  createdDate: string;
  /**
   * If the token is enabled or disabled
   */
  isValid: boolean;
  /**
   * Date the token was last updated
   */
  updatedDate: string;
};

/**
 * Object for creating a new token
 */
export interface TokenCreate {
  isValid: boolean;
  name?: string;
  organisationId: string;
  /**
   * Unique ID by which this Token can be identified. This is the field used by the RFID reader on the Charge Point.
   */
  uid: string;
}

/**
 * A token summary object
 */
export interface TokenInfo {
  /**
   * Token ID
   */
  id: string;
  /**
   * User friendly token name
   */
  name?: string;
  tokenType: TokenType;
  /**
   * Unique ID by which this Token can be identified. This is the field used by the RFID reader on the Charge Point.
   */
  uid: string;
}

/**
 * Type of token
 */
export type TokenType = 'RFID' | 'APP_USER' | 'AD_HOC_USER' | 'OTHER';

/**
 * Token update object
 */
export interface TokenUpdate {
  id: string;
  isValid?: boolean;
  name?: string;
  organisationId: string;
}

/**
 * A complete user object
 */
export type User = UserInfo & {
  /**
   * Date the user was created
   */
  createdDate: string;
  /**
   * List of organisations that the user belongs to
   */
  organisations: UserOrganisation[];
  /**
   * Date the user was last updated
   */
  updatedDate: string;
};

/**
 * Object for creating a new user
 */
export interface UserCreate {
  email: string;
  password: string;
}

/**
 * User summary object
 */
export interface UserInfo {
  /**
   * User's avatar url
   */
  avatar?: string;
  color?: Color;
  /**
   * User's email address
   */
  email: string;
  /**
   * User's unique id
   */
  id: string;
  /**
   * User's display name
   */
  name?: string;
}

/**
 * A user's organisation. Contains the user's role within the organisation and if it is their default selected organisation
 */
export type UserOrganisation = OrganisationInfo & {
  isDefault: boolean;
  role: Role;
};

/**
 * User update object
 */
export interface UserUpdate {
  avatar?: string;
  color?: Color;
  defaultOrganisationId?: string;
  name?: string;
}

export type PostChargePointEvnexProps = Omit<
  MutateProps<ResponsePostEvnexChargePoint, ResponseApiErrors, void, ChargePointClaim, void>,
  'path' | 'verb'
>;

/**
 * Claim Evnex charge point
 *
 * Claim a Evnex charge point.
 */
export const PostChargePointEvnex = (props: PostChargePointEvnexProps) => (
  <Mutate<ResponsePostEvnexChargePoint, ResponseApiErrors, void, ChargePointClaim, void>
    verb="POST"
    path={`/v2/apps/charge-points/evnex`}
    {...props}
  />
);

export type UsePostChargePointEvnexProps = Omit<
  UseMutateProps<ResponsePostEvnexChargePoint, ResponseApiErrors, void, ChargePointClaim, void>,
  'path' | 'verb'
>;

/**
 * Claim Evnex charge point
 *
 * Claim a Evnex charge point.
 */
export const usePostChargePointEvnex = (props: UsePostChargePointEvnexProps) =>
  useMutate<ResponsePostEvnexChargePoint, ResponseApiErrors, void, ChargePointClaim, void>(
    'POST',
    `/v2/apps/charge-points/evnex`,
    props,
  );

export type PostChargePointOtherProps = Omit<
  MutateProps<ResponsePostOtherChargePoint, ResponseApiErrors, void, ChargePointAdd, void>,
  'path' | 'verb'
>;

/**
 * Add third party charge point
 *
 * Add a third party charge point.
 */
export const PostChargePointOther = (props: PostChargePointOtherProps) => (
  <Mutate<ResponsePostOtherChargePoint, ResponseApiErrors, void, ChargePointAdd, void>
    verb="POST"
    path={`/v2/apps/charge-points/other`}
    {...props}
  />
);

export type UsePostChargePointOtherProps = Omit<
  UseMutateProps<ResponsePostOtherChargePoint, ResponseApiErrors, void, ChargePointAdd, void>,
  'path' | 'verb'
>;

/**
 * Add third party charge point
 *
 * Add a third party charge point.
 */
export const usePostChargePointOther = (props: UsePostChargePointOtherProps) =>
  useMutate<ResponsePostOtherChargePoint, ResponseApiErrors, void, ChargePointAdd, void>(
    'POST',
    `/v2/apps/charge-points/other`,
    props,
  );

export type DeleteChargePointProps = Omit<MutateProps<void, ResponseApiErrors, void, string, void>, 'path' | 'verb'>;

/**
 * Delete charge point
 *
 * Delete a charge point.
 */
export const DeleteChargePoint = (props: DeleteChargePointProps) => (
  <Mutate<void, ResponseApiErrors, void, string, void> verb="DELETE" path={`/v2/apps/charge-points`} {...props} />
);

export type UseDeleteChargePointProps = Omit<
  UseMutateProps<void, ResponseApiErrors, void, string, void>,
  'path' | 'verb'
>;

/**
 * Delete charge point
 *
 * Delete a charge point.
 */
export const useDeleteChargePoint = (props: UseDeleteChargePointProps) =>
  useMutate<void, ResponseApiErrors, void, string, void>('DELETE', `/v2/apps/charge-points`, { ...props });

export interface GetChargePointPathParams {
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type GetChargePointProps = Omit<
  GetProps<ResponseGetChargePoint, ResponseApiErrors, void, GetChargePointPathParams>,
  'path'
> &
  GetChargePointPathParams;

/**
 * Get charge point
 *
 * Return the requested charge point.
 */
export const GetChargePoint = ({ chargePointId, ...props }: GetChargePointProps) => (
  <Get<ResponseGetChargePoint, ResponseApiErrors, void, GetChargePointPathParams>
    path={`/v2/apps/charge-points/${chargePointId}`}
    {...props}
  />
);

export type UseGetChargePointProps = Omit<
  UseGetProps<ResponseGetChargePoint, ResponseApiErrors, void, GetChargePointPathParams>,
  'path'
> &
  GetChargePointPathParams;

/**
 * Get charge point
 *
 * Return the requested charge point.
 */
export const useGetChargePoint = ({ chargePointId, ...props }: UseGetChargePointProps) =>
  useGet<ResponseGetChargePoint, ResponseApiErrors, void, GetChargePointPathParams>(
    (paramsInPath: GetChargePointPathParams) => `/v2/apps/charge-points/${paramsInPath.chargePointId}`,
    { pathParams: { chargePointId }, ...props },
  );

export interface PutChargePointPathParams {
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type PutChargePointProps = Omit<
  MutateProps<ResponsePutChargePoint, ResponseApiErrors, void, ChargePointUpdate, PutChargePointPathParams>,
  'path' | 'verb'
> &
  PutChargePointPathParams;

/**
 * Update charge point
 *
 * Update a charge point.
 */
export const PutChargePoint = ({ chargePointId, ...props }: PutChargePointProps) => (
  <Mutate<ResponsePutChargePoint, ResponseApiErrors, void, ChargePointUpdate, PutChargePointPathParams>
    verb="PUT"
    path={`/v2/apps/charge-points/${chargePointId}`}
    {...props}
  />
);

export type UsePutChargePointProps = Omit<
  UseMutateProps<ResponsePutChargePoint, ResponseApiErrors, void, ChargePointUpdate, PutChargePointPathParams>,
  'path' | 'verb'
> &
  PutChargePointPathParams;

/**
 * Update charge point
 *
 * Update a charge point.
 */
export const usePutChargePoint = ({ chargePointId, ...props }: UsePutChargePointProps) =>
  useMutate<ResponsePutChargePoint, ResponseApiErrors, void, ChargePointUpdate, PutChargePointPathParams>(
    'PUT',
    (paramsInPath: PutChargePointPathParams) => `/v2/apps/charge-points/${paramsInPath.chargePointId}`,
    { pathParams: { chargePointId }, ...props },
  );

export interface PutChargePointChargeSchedulePathParams {
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type PutChargePointChargeScheduleProps = Omit<
  MutateProps<
    ResponsePutChargeSchedule,
    ResponseApiErrors,
    void,
    LoadScheduleUpdate,
    PutChargePointChargeSchedulePathParams
  >,
  'path' | 'verb'
> &
  PutChargePointChargeSchedulePathParams;

/**
 * Create or update a charge point's load managment profile.
 */
export const PutChargePointChargeSchedule = ({ chargePointId, ...props }: PutChargePointChargeScheduleProps) => (
  <Mutate<
    ResponsePutChargeSchedule,
    ResponseApiErrors,
    void,
    LoadScheduleUpdate,
    PutChargePointChargeSchedulePathParams
  >
    verb="PUT"
    path={`/v2/apps/charge-points/${chargePointId}/charge-schedule`}
    {...props}
  />
);

export type UsePutChargePointChargeScheduleProps = Omit<
  UseMutateProps<
    ResponsePutChargeSchedule,
    ResponseApiErrors,
    void,
    LoadScheduleUpdate,
    PutChargePointChargeSchedulePathParams
  >,
  'path' | 'verb'
> &
  PutChargePointChargeSchedulePathParams;

/**
 * Create or update a charge point's load managment profile.
 */
export const usePutChargePointChargeSchedule = ({ chargePointId, ...props }: UsePutChargePointChargeScheduleProps) =>
  useMutate<
    ResponsePutChargeSchedule,
    ResponseApiErrors,
    void,
    LoadScheduleUpdate,
    PutChargePointChargeSchedulePathParams
  >(
    'PUT',
    (paramsInPath: PutChargePointChargeSchedulePathParams) =>
      `/v2/apps/charge-points/${paramsInPath.chargePointId}/charge-schedule`,
    { pathParams: { chargePointId }, ...props },
  );

export interface PutChargePointLoadManagementPathParams {
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type PutChargePointLoadManagementProps = Omit<
  MutateProps<
    ResponsePutLoadManagement,
    ResponseApiErrors,
    void,
    LoadManagementUpdate,
    PutChargePointLoadManagementPathParams
  >,
  'path' | 'verb'
> &
  PutChargePointLoadManagementPathParams;

/**
 * Create or update a charge point's load managment profile.
 */
export const PutChargePointLoadManagement = ({ chargePointId, ...props }: PutChargePointLoadManagementProps) => (
  <Mutate<
    ResponsePutLoadManagement,
    ResponseApiErrors,
    void,
    LoadManagementUpdate,
    PutChargePointLoadManagementPathParams
  >
    verb="PUT"
    path={`/v2/apps/charge-points/${chargePointId}/load-management`}
    {...props}
  />
);

export type UsePutChargePointLoadManagementProps = Omit<
  UseMutateProps<
    ResponsePutLoadManagement,
    ResponseApiErrors,
    void,
    LoadManagementUpdate,
    PutChargePointLoadManagementPathParams
  >,
  'path' | 'verb'
> &
  PutChargePointLoadManagementPathParams;

/**
 * Create or update a charge point's load managment profile.
 */
export const usePutChargePointLoadManagement = ({ chargePointId, ...props }: UsePutChargePointLoadManagementProps) =>
  useMutate<
    ResponsePutLoadManagement,
    ResponseApiErrors,
    void,
    LoadManagementUpdate,
    PutChargePointLoadManagementPathParams
  >(
    'PUT',
    (paramsInPath: PutChargePointLoadManagementPathParams) =>
      `/v2/apps/charge-points/${paramsInPath.chargePointId}/load-management`,
    { pathParams: { chargePointId }, ...props },
  );

export interface GetChargePointLogsQueryParams {
  /**
   * ISO formatted timestamp
   */
  from?: string;
  /**
   * ISO formatted timestamp
   */
  to?: string;
}

export interface GetChargePointLogsPathParams {
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type GetChargePointLogsProps = Omit<
  GetProps<ResponseGetChargePointLogs, ResponseApiErrors, GetChargePointLogsQueryParams, GetChargePointLogsPathParams>,
  'path'
> &
  GetChargePointLogsPathParams;

/**
 * Get Charge Point Logs
 *
 * Return the requested charge point's last 100 log events.
 */
export const GetChargePointLogs = ({ chargePointId, ...props }: GetChargePointLogsProps) => (
  <Get<ResponseGetChargePointLogs, ResponseApiErrors, GetChargePointLogsQueryParams, GetChargePointLogsPathParams>
    path={`/v2/apps/charge-points/${chargePointId}/logs`}
    {...props}
  />
);

export type UseGetChargePointLogsProps = Omit<
  UseGetProps<
    ResponseGetChargePointLogs,
    ResponseApiErrors,
    GetChargePointLogsQueryParams,
    GetChargePointLogsPathParams
  >,
  'path'
> &
  GetChargePointLogsPathParams;

/**
 * Get Charge Point Logs
 *
 * Return the requested charge point's last 100 log events.
 */
export const useGetChargePointLogs = ({ chargePointId, ...props }: UseGetChargePointLogsProps) =>
  useGet<ResponseGetChargePointLogs, ResponseApiErrors, GetChargePointLogsQueryParams, GetChargePointLogsPathParams>(
    (paramsInPath: GetChargePointLogsPathParams) => `/v2/apps/charge-points/${paramsInPath.chargePointId}/logs`,
    { pathParams: { chargePointId }, ...props },
  );

export type DeleteLocationProps = Omit<MutateProps<void, ResponseApiErrors, void, string, void>, 'path' | 'verb'>;

/**
 * Delete location
 *
 * Delete a location.
 */
export const DeleteLocation = (props: DeleteLocationProps) => (
  <Mutate<void, ResponseApiErrors, void, string, void> verb="DELETE" path={`/v2/apps/locations`} {...props} />
);

export type UseDeleteLocationProps = Omit<UseMutateProps<void, ResponseApiErrors, void, string, void>, 'path' | 'verb'>;

/**
 * Delete location
 *
 * Delete a location.
 */
export const useDeleteLocation = (props: UseDeleteLocationProps) =>
  useMutate<void, ResponseApiErrors, void, string, void>('DELETE', `/v2/apps/locations`, { ...props });

export interface PostChargePointCommandChangeAvailabilityPathParams {
  /**
   * Slug of a organisation
   */
  organisationSlug: OrganisationSlug;
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type PostChargePointCommandChangeAvailabilityProps = Omit<
  MutateProps<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandChangeAvailability,
    PostChargePointCommandChangeAvailabilityPathParams
  >,
  'path' | 'verb'
> &
  PostChargePointCommandChangeAvailabilityPathParams;

/**
 * Send a change availability command to the charge point
 *
 * Send a command to change the availability of a charge point.
 */
export const PostChargePointCommandChangeAvailability = ({
  organisationSlug,
  chargePointId,
  ...props
}: PostChargePointCommandChangeAvailabilityProps) => (
  <Mutate<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandChangeAvailability,
    PostChargePointCommandChangeAvailabilityPathParams
  >
    verb="POST"
    path={`/v2/apps/organisations/${organisationSlug}/charge-points/${chargePointId}/commands/change-availability`}
    {...props}
  />
);

export type UsePostChargePointCommandChangeAvailabilityProps = Omit<
  UseMutateProps<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandChangeAvailability,
    PostChargePointCommandChangeAvailabilityPathParams
  >,
  'path' | 'verb'
> &
  PostChargePointCommandChangeAvailabilityPathParams;

/**
 * Send a change availability command to the charge point
 *
 * Send a command to change the availability of a charge point.
 */
export const usePostChargePointCommandChangeAvailability = ({
  organisationSlug,
  chargePointId,
  ...props
}: UsePostChargePointCommandChangeAvailabilityProps) =>
  useMutate<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandChangeAvailability,
    PostChargePointCommandChangeAvailabilityPathParams
  >(
    'POST',
    (paramsInPath: PostChargePointCommandChangeAvailabilityPathParams) =>
      `/v2/apps/organisations/${paramsInPath.organisationSlug}/charge-points/${paramsInPath.chargePointId}/commands/change-availability`,
    { pathParams: { organisationSlug, chargePointId }, ...props },
  );

export interface PostChargePointCommandStartTransactionPathParams {
  /**
   * Slug of a organisation
   */
  organisationSlug: OrganisationSlug;
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type PostChargePointCommandStartTransactionProps = Omit<
  MutateProps<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandRemoteStartTransaction,
    PostChargePointCommandStartTransactionPathParams
  >,
  'path' | 'verb'
> &
  PostChargePointCommandStartTransactionPathParams;

/**
 * Send a remote start transaction command to the charge point
 *
 * Send a command to start a transaction on a connector of a charge point.
 */
export const PostChargePointCommandStartTransaction = ({
  organisationSlug,
  chargePointId,
  ...props
}: PostChargePointCommandStartTransactionProps) => (
  <Mutate<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandRemoteStartTransaction,
    PostChargePointCommandStartTransactionPathParams
  >
    verb="POST"
    path={`/v2/apps/organisations/${organisationSlug}/charge-points/${chargePointId}/commands/remote-start-transaction`}
    {...props}
  />
);

export type UsePostChargePointCommandStartTransactionProps = Omit<
  UseMutateProps<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandRemoteStartTransaction,
    PostChargePointCommandStartTransactionPathParams
  >,
  'path' | 'verb'
> &
  PostChargePointCommandStartTransactionPathParams;

/**
 * Send a remote start transaction command to the charge point
 *
 * Send a command to start a transaction on a connector of a charge point.
 */
export const usePostChargePointCommandStartTransaction = ({
  organisationSlug,
  chargePointId,
  ...props
}: UsePostChargePointCommandStartTransactionProps) =>
  useMutate<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandRemoteStartTransaction,
    PostChargePointCommandStartTransactionPathParams
  >(
    'POST',
    (paramsInPath: PostChargePointCommandStartTransactionPathParams) =>
      `/v2/apps/organisations/${paramsInPath.organisationSlug}/charge-points/${paramsInPath.chargePointId}/commands/remote-start-transaction`,
    { pathParams: { organisationSlug, chargePointId }, ...props },
  );

export interface PostChargePointCommandStopTransactionPathParams {
  /**
   * Slug of a organisation
   */
  organisationSlug: OrganisationSlug;
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type PostChargePointCommandStopTransactionProps = Omit<
  MutateProps<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandRemoteStopTransaction,
    PostChargePointCommandStopTransactionPathParams
  >,
  'path' | 'verb'
> &
  PostChargePointCommandStopTransactionPathParams;

/**
 * Send a remote stop transaction command to the charge point
 *
 * Send a command to stop a transaction on a connector of a charge point.
 */
export const PostChargePointCommandStopTransaction = ({
  organisationSlug,
  chargePointId,
  ...props
}: PostChargePointCommandStopTransactionProps) => (
  <Mutate<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandRemoteStopTransaction,
    PostChargePointCommandStopTransactionPathParams
  >
    verb="POST"
    path={`/v2/apps/organisations/${organisationSlug}/charge-points/${chargePointId}/commands/remote-stop-transaction`}
    {...props}
  />
);

export type UsePostChargePointCommandStopTransactionProps = Omit<
  UseMutateProps<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandRemoteStopTransaction,
    PostChargePointCommandStopTransactionPathParams
  >,
  'path' | 'verb'
> &
  PostChargePointCommandStopTransactionPathParams;

/**
 * Send a remote stop transaction command to the charge point
 *
 * Send a command to stop a transaction on a connector of a charge point.
 */
export const usePostChargePointCommandStopTransaction = ({
  organisationSlug,
  chargePointId,
  ...props
}: UsePostChargePointCommandStopTransactionProps) =>
  useMutate<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandRemoteStopTransaction,
    PostChargePointCommandStopTransactionPathParams
  >(
    'POST',
    (paramsInPath: PostChargePointCommandStopTransactionPathParams) =>
      `/v2/apps/organisations/${paramsInPath.organisationSlug}/charge-points/${paramsInPath.chargePointId}/commands/remote-stop-transaction`,
    { pathParams: { organisationSlug, chargePointId }, ...props },
  );

export interface PostChargePointCommandResetPathParams {
  /**
   * Slug of a organisation
   */
  organisationSlug: OrganisationSlug;
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type PostChargePointCommandResetProps = Omit<
  MutateProps<ResponsePostCommand, ResponseApiErrors, void, CommandReset, PostChargePointCommandResetPathParams>,
  'path' | 'verb'
> &
  PostChargePointCommandResetPathParams;

/**
 * Send a reset command to the charge point
 *
 * Send a command to reset a charge point.
 */
export const PostChargePointCommandReset = ({
  organisationSlug,
  chargePointId,
  ...props
}: PostChargePointCommandResetProps) => (
  <Mutate<ResponsePostCommand, ResponseApiErrors, void, CommandReset, PostChargePointCommandResetPathParams>
    verb="POST"
    path={`/v2/apps/organisations/${organisationSlug}/charge-points/${chargePointId}/commands/reset`}
    {...props}
  />
);

export type UsePostChargePointCommandResetProps = Omit<
  UseMutateProps<ResponsePostCommand, ResponseApiErrors, void, CommandReset, PostChargePointCommandResetPathParams>,
  'path' | 'verb'
> &
  PostChargePointCommandResetPathParams;

/**
 * Send a reset command to the charge point
 *
 * Send a command to reset a charge point.
 */
export const usePostChargePointCommandReset = ({
  organisationSlug,
  chargePointId,
  ...props
}: UsePostChargePointCommandResetProps) =>
  useMutate<ResponsePostCommand, ResponseApiErrors, void, CommandReset, PostChargePointCommandResetPathParams>(
    'POST',
    (paramsInPath: PostChargePointCommandResetPathParams) =>
      `/v2/apps/organisations/${paramsInPath.organisationSlug}/charge-points/${paramsInPath.chargePointId}/commands/reset`,
    { pathParams: { organisationSlug, chargePointId }, ...props },
  );

export interface PostChargePointCommandUnlockConnectorPathParams {
  /**
   * Slug of a organisation
   */
  organisationSlug: OrganisationSlug;
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type PostChargePointCommandUnlockConnectorProps = Omit<
  MutateProps<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandUnlockConnector,
    PostChargePointCommandUnlockConnectorPathParams
  >,
  'path' | 'verb'
> &
  PostChargePointCommandUnlockConnectorPathParams;

/**
 * Send a unlock connector command to the charge point
 *
 * Send a command to unlock a connector on a charge point.
 */
export const PostChargePointCommandUnlockConnector = ({
  organisationSlug,
  chargePointId,
  ...props
}: PostChargePointCommandUnlockConnectorProps) => (
  <Mutate<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandUnlockConnector,
    PostChargePointCommandUnlockConnectorPathParams
  >
    verb="POST"
    path={`/v2/apps/organisations/${organisationSlug}/charge-points/${chargePointId}/commands/unlock-connector`}
    {...props}
  />
);

export type UsePostChargePointCommandUnlockConnectorProps = Omit<
  UseMutateProps<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandUnlockConnector,
    PostChargePointCommandUnlockConnectorPathParams
  >,
  'path' | 'verb'
> &
  PostChargePointCommandUnlockConnectorPathParams;

/**
 * Send a unlock connector command to the charge point
 *
 * Send a command to unlock a connector on a charge point.
 */
export const usePostChargePointCommandUnlockConnector = ({
  organisationSlug,
  chargePointId,
  ...props
}: UsePostChargePointCommandUnlockConnectorProps) =>
  useMutate<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandUnlockConnector,
    PostChargePointCommandUnlockConnectorPathParams
  >(
    'POST',
    (paramsInPath: PostChargePointCommandUnlockConnectorPathParams) =>
      `/v2/apps/organisations/${paramsInPath.organisationSlug}/charge-points/${paramsInPath.chargePointId}/commands/unlock-connector`,
    { pathParams: { organisationSlug, chargePointId }, ...props },
  );

export interface PostChargePointCommandUpdateFirmwarePathParams {
  /**
   * Slug of a organisation
   */
  organisationSlug: OrganisationSlug;
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type PostChargePointCommandUpdateFirmwareProps = Omit<
  MutateProps<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandUpdateFirmware,
    PostChargePointCommandUpdateFirmwarePathParams
  >,
  'path' | 'verb'
> &
  PostChargePointCommandUpdateFirmwarePathParams;

/**
 * Send a update firmware command to the charge point
 *
 * Send a command to update the firmware of a charge point.
 */
export const PostChargePointCommandUpdateFirmware = ({
  organisationSlug,
  chargePointId,
  ...props
}: PostChargePointCommandUpdateFirmwareProps) => (
  <Mutate<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandUpdateFirmware,
    PostChargePointCommandUpdateFirmwarePathParams
  >
    verb="POST"
    path={`/v2/apps/organisations/${organisationSlug}/charge-points/${chargePointId}/commands/update-firmware`}
    {...props}
  />
);

export type UsePostChargePointCommandUpdateFirmwareProps = Omit<
  UseMutateProps<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandUpdateFirmware,
    PostChargePointCommandUpdateFirmwarePathParams
  >,
  'path' | 'verb'
> &
  PostChargePointCommandUpdateFirmwarePathParams;

/**
 * Send a update firmware command to the charge point
 *
 * Send a command to update the firmware of a charge point.
 */
export const usePostChargePointCommandUpdateFirmware = ({
  organisationSlug,
  chargePointId,
  ...props
}: UsePostChargePointCommandUpdateFirmwareProps) =>
  useMutate<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandUpdateFirmware,
    PostChargePointCommandUpdateFirmwarePathParams
  >(
    'POST',
    (paramsInPath: PostChargePointCommandUpdateFirmwarePathParams) =>
      `/v2/apps/organisations/${paramsInPath.organisationSlug}/charge-points/${paramsInPath.chargePointId}/commands/update-firmware`,
    { pathParams: { organisationSlug, chargePointId }, ...props },
  );

export interface GetChargePointCommandGetConfigurationPathParams {
  /**
   * Slug of a organisation
   */
  organisationSlug: OrganisationSlug;
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type GetChargePointCommandGetConfigurationProps = Omit<
  GetProps<ResponseCommandGetConfiguration, ResponseApiErrors, void, GetChargePointCommandGetConfigurationPathParams>,
  'path'
> &
  GetChargePointCommandGetConfigurationPathParams;

/**
 * Send a get configuration command to the charge point
 *
 * Send a command to get configuration of a charge point.
 */
export const GetChargePointCommandGetConfiguration = ({
  organisationSlug,
  chargePointId,
  ...props
}: GetChargePointCommandGetConfigurationProps) => (
  <Get<ResponseCommandGetConfiguration, ResponseApiErrors, void, GetChargePointCommandGetConfigurationPathParams>
    path={`/v2/apps/organisations/${organisationSlug}/charge-points/${chargePointId}/commands/get-configuration`}
    {...props}
  />
);

export type UseGetChargePointCommandGetConfigurationProps = Omit<
  UseGetProps<
    ResponseCommandGetConfiguration,
    ResponseApiErrors,
    void,
    GetChargePointCommandGetConfigurationPathParams
  >,
  'path'
> &
  GetChargePointCommandGetConfigurationPathParams;

/**
 * Send a get configuration command to the charge point
 *
 * Send a command to get configuration of a charge point.
 */
export const useGetChargePointCommandGetConfiguration = ({
  organisationSlug,
  chargePointId,
  ...props
}: UseGetChargePointCommandGetConfigurationProps) =>
  useGet<ResponseCommandGetConfiguration, ResponseApiErrors, void, GetChargePointCommandGetConfigurationPathParams>(
    (paramsInPath: GetChargePointCommandGetConfigurationPathParams) =>
      `/v2/apps/organisations/${paramsInPath.organisationSlug}/charge-points/${paramsInPath.chargePointId}/commands/get-configuration`,
    { pathParams: { organisationSlug, chargePointId }, ...props },
  );

export interface PostChargePointCommandChangeConfigurationPathParams {
  /**
   * Slug of a organisation
   */
  organisationSlug: OrganisationSlug;
  /**
   * Id of a charge point
   */
  chargePointId: string;
}

export type PostChargePointCommandChangeConfigurationProps = Omit<
  MutateProps<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandChangeConfiguration,
    PostChargePointCommandChangeConfigurationPathParams
  >,
  'path' | 'verb'
> &
  PostChargePointCommandChangeConfigurationPathParams;

/**
 * Send a change configuration command to the charge point
 *
 * Send a change configuration to a charge point.
 */
export const PostChargePointCommandChangeConfiguration = ({
  organisationSlug,
  chargePointId,
  ...props
}: PostChargePointCommandChangeConfigurationProps) => (
  <Mutate<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandChangeConfiguration,
    PostChargePointCommandChangeConfigurationPathParams
  >
    verb="POST"
    path={`/v2/apps/organisations/${organisationSlug}/charge-points/${chargePointId}/commands/change-configuration`}
    {...props}
  />
);

export type UsePostChargePointCommandChangeConfigurationProps = Omit<
  UseMutateProps<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandChangeConfiguration,
    PostChargePointCommandChangeConfigurationPathParams
  >,
  'path' | 'verb'
> &
  PostChargePointCommandChangeConfigurationPathParams;

/**
 * Send a change configuration command to the charge point
 *
 * Send a change configuration to a charge point.
 */
export const usePostChargePointCommandChangeConfiguration = ({
  organisationSlug,
  chargePointId,
  ...props
}: UsePostChargePointCommandChangeConfigurationProps) =>
  useMutate<
    ResponsePostCommand,
    ResponseApiErrors,
    void,
    CommandChangeConfiguration,
    PostChargePointCommandChangeConfigurationPathParams
  >(
    'POST',
    (paramsInPath: PostChargePointCommandChangeConfigurationPathParams) =>
      `/v2/apps/organisations/${paramsInPath.organisationSlug}/charge-points/${paramsInPath.chargePointId}/commands/change-configuration`,
    { pathParams: { organisationSlug, chargePointId }, ...props },
  );

export interface GetOrganisationMembersPathParams {
  /**
   * Slug of a organisation
   */
  organisationSlug: OrganisationSlug;
}

export type GetOrganisationMembersProps = Omit<
  GetProps<ResponseGetOrganisationMembers, ResponseApiErrors, void, GetOrganisationMembersPathParams>,
  'path'
> &
  GetOrganisationMembersPathParams;

/**
 * Get organisation members (Unimplemented)
 *
 * Return the users in an organisation.
 */
export const GetOrganisationMembers = ({ organisationSlug, ...props }: GetOrganisationMembersProps) => (
  <Get<ResponseGetOrganisationMembers, ResponseApiErrors, void, GetOrganisationMembersPathParams>
    path={`/v2/apps/organisations/${organisationSlug}/members`}
    {...props}
  />
);

export type UseGetOrganisationMembersProps = Omit<
  UseGetProps<ResponseGetOrganisationMembers, ResponseApiErrors, void, GetOrganisationMembersPathParams>,
  'path'
> &
  GetOrganisationMembersPathParams;

/**
 * Get organisation members (Unimplemented)
 *
 * Return the users in an organisation.
 */
export const useGetOrganisationMembers = ({ organisationSlug, ...props }: UseGetOrganisationMembersProps) =>
  useGet<ResponseGetOrganisationMembers, ResponseApiErrors, void, GetOrganisationMembersPathParams>(
    (paramsInPath: GetOrganisationMembersPathParams) =>
      `/v2/apps/organisations/${paramsInPath.organisationSlug}/members`,
    { pathParams: { organisationSlug }, ...props },
  );

export interface GetOrganisationSummaryChargePointsQueryParams {
  /**
   * Number of days from now (7, 14 or 30)
   */
  days: '7' | '14' | '30';
  /**
   * Timezone offset
   */
  tzOffset?: string;
}

export interface GetOrganisationSummaryChargePointsPathParams {
  organisationSlug: string;
}

export type GetOrganisationSummaryChargePointsProps = Omit<
  GetProps<
    ResponseGetOrganisationSummaryChargePoints,
    ResponseApiErrors,
    GetOrganisationSummaryChargePointsQueryParams,
    GetOrganisationSummaryChargePointsPathParams
  >,
  'path'
> &
  GetOrganisationSummaryChargePointsPathParams;

/**
 * Get organisation summary of charge points
 *
 * Return summary information about all charge points in an organisation.
 */
export const GetOrganisationSummaryChargePoints = ({
  organisationSlug,
  ...props
}: GetOrganisationSummaryChargePointsProps) => (
  <Get<
    ResponseGetOrganisationSummaryChargePoints,
    ResponseApiErrors,
    GetOrganisationSummaryChargePointsQueryParams,
    GetOrganisationSummaryChargePointsPathParams
  >
    path={`/v2/apps/organisations/${organisationSlug}/summary/charge-points`}
    {...props}
  />
);

export type UseGetOrganisationSummaryChargePointsProps = Omit<
  UseGetProps<
    ResponseGetOrganisationSummaryChargePoints,
    ResponseApiErrors,
    GetOrganisationSummaryChargePointsQueryParams,
    GetOrganisationSummaryChargePointsPathParams
  >,
  'path'
> &
  GetOrganisationSummaryChargePointsPathParams;

/**
 * Get organisation summary of charge points
 *
 * Return summary information about all charge points in an organisation.
 */
export const useGetOrganisationSummaryChargePoints = ({
  organisationSlug,
  ...props
}: UseGetOrganisationSummaryChargePointsProps) =>
  useGet<
    ResponseGetOrganisationSummaryChargePoints,
    ResponseApiErrors,
    GetOrganisationSummaryChargePointsQueryParams,
    GetOrganisationSummaryChargePointsPathParams
  >(
    (paramsInPath: GetOrganisationSummaryChargePointsPathParams) =>
      `/v2/apps/organisations/${paramsInPath.organisationSlug}/summary/charge-points`,
    { pathParams: { organisationSlug }, ...props },
  );

export type PostOrganisationTokenProps = Omit<
  MutateProps<ResponsePostToken, ResponseApiErrors, void, TokenCreate, void>,
  'path' | 'verb'
>;

/**
 * Create Token
 *
 * Create a new token.
 */
export const PostOrganisationToken = (props: PostOrganisationTokenProps) => (
  <Mutate<ResponsePostToken, ResponseApiErrors, void, TokenCreate, void>
    verb="POST"
    path={`/v2/apps/tokens`}
    {...props}
  />
);

export type UsePostOrganisationTokenProps = Omit<
  UseMutateProps<ResponsePostToken, ResponseApiErrors, void, TokenCreate, void>,
  'path' | 'verb'
>;

/**
 * Create Token
 *
 * Create a new token.
 */
export const usePostOrganisationToken = (props: UsePostOrganisationTokenProps) =>
  useMutate<ResponsePostToken, ResponseApiErrors, void, TokenCreate, void>('POST', `/v2/apps/tokens`, props);

export interface PutOrganisationTokenPathParams {
  /**
   * Id of a token
   */
  tokenId: string;
}

export type PutOrganisationTokenProps = Omit<
  MutateProps<ResponsePutToken, ResponseApiErrors, void, TokenUpdate, PutOrganisationTokenPathParams>,
  'path' | 'verb'
> &
  PutOrganisationTokenPathParams;

/**
 * Edit a token for an organisation
 *
 * Editing a token for an organisation.
 */
export const PutOrganisationToken = ({ tokenId, ...props }: PutOrganisationTokenProps) => (
  <Mutate<ResponsePutToken, ResponseApiErrors, void, TokenUpdate, PutOrganisationTokenPathParams>
    verb="PUT"
    path={`/v2/apps/tokens/${tokenId}`}
    {...props}
  />
);

export type UsePutOrganisationTokenProps = Omit<
  UseMutateProps<ResponsePutToken, ResponseApiErrors, void, TokenUpdate, PutOrganisationTokenPathParams>,
  'path' | 'verb'
> &
  PutOrganisationTokenPathParams;

/**
 * Edit a token for an organisation
 *
 * Editing a token for an organisation.
 */
export const usePutOrganisationToken = ({ tokenId, ...props }: UsePutOrganisationTokenProps) =>
  useMutate<ResponsePutToken, ResponseApiErrors, void, TokenUpdate, PutOrganisationTokenPathParams>(
    'PUT',
    (paramsInPath: PutOrganisationTokenPathParams) => `/v2/apps/tokens/${paramsInPath.tokenId}`,
    { pathParams: { tokenId }, ...props },
  );

export type PostUserProps = Omit<
  MutateProps<ResponsePostUser, ResponseApiErrors, void, UserCreate, void>,
  'path' | 'verb'
>;

/**
 * Create user (Unimplemented)
 *
 * Create a new user.
 */
export const PostUser = (props: PostUserProps) => (
  <Mutate<ResponsePostUser, ResponseApiErrors, void, UserCreate, void> verb="POST" path={`/v2/apps/user`} {...props} />
);

export type UsePostUserProps = Omit<
  UseMutateProps<ResponsePostUser, ResponseApiErrors, void, UserCreate, void>,
  'path' | 'verb'
>;

/**
 * Create user (Unimplemented)
 *
 * Create a new user.
 */
export const usePostUser = (props: UsePostUserProps) =>
  useMutate<ResponsePostUser, ResponseApiErrors, void, UserCreate, void>('POST', `/v2/apps/user`, props);

export type PutUserProps = Omit<
  MutateProps<ResponsePutUser, ResponseApiErrors, void, UserUpdate, void>,
  'path' | 'verb'
>;

/**
 * Update user
 *
 * Update the current user's details. The current user is determined by the `sub` in the JWT.
 */
export const PutUser = (props: PutUserProps) => (
  <Mutate<ResponsePutUser, ResponseApiErrors, void, UserUpdate, void> verb="PUT" path={`/v2/apps/user`} {...props} />
);

export type UsePutUserProps = Omit<
  UseMutateProps<ResponsePutUser, ResponseApiErrors, void, UserUpdate, void>,
  'path' | 'verb'
>;

/**
 * Update user
 *
 * Update the current user's details. The current user is determined by the `sub` in the JWT.
 */
export const usePutUser = (props: UsePutUserProps) =>
  useMutate<ResponsePutUser, ResponseApiErrors, void, UserUpdate, void>('PUT', `/v2/apps/user`, props);
