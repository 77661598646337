import Box from '@material-ui/core/Box';
import React from 'react';
import { Card, Section } from '../../design-system';
import { ExpandButton } from '../ExpandButton';

interface RawDataSectionProps {
  json: Record<string, unknown>;
}

export const RawDataSection: React.VFC<RawDataSectionProps> = ({ json }: RawDataSectionProps) => {
  const [displayRawData, toggleDisplayRawData] = React.useState(false);
  return (
    <Section>
      <Card
        title={
          <>
            Raw job data
            <ExpandButton isExpanded={displayRawData} setIsExpanded={toggleDisplayRawData} />
          </>
        }
      >
        <Box p={2} pt={0}>
          {displayRawData && (
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }} title="JSON">
              {JSON.stringify(json, null, 2)}
            </pre>
          )}
        </Box>
      </Card>
    </Section>
  );
};
