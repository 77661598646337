import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uid } from 'react-uid';
import { UserAvatar } from '../../../../components/Avatar/User';
import { ReloadContainerNavItem } from '../../../../components/ReloadContainerNavItem';
import {
  ContainerHeader,
  ContainerHeaderLoading,
  HeaderSection,
  Item,
  ItemLoading,
  MenuSection,
  NavigationView,
  Separator,
} from '../../../../design-system';
import { fullStateSelector } from '../../../../store/root';
import { getCurrentUserAction } from '../../../../store/user/actions';

const Items = (): JSX.Element => {
  const {
    user: { loading, current: user, error },
  } = useSelector(fullStateSelector);

  const dispatch = useDispatch();

  const refetch = React.useCallback(() => {
    if (user) {
      dispatch(getCurrentUserAction(user.id));
    }
  }, [dispatch, user]);

  const ReloadButton = () => <ReloadContainerNavItem loading={loading} descriptor="user" onClick={refetch} />;

  if (loading) {
    return (
      <>
        <div data-testid="skeleton">
          <HeaderSection>{() => <ContainerHeaderLoading avatar subText />}</HeaderSection>
          <Separator mb={1} />
          <MenuSection canOverflow>
            {() =>
              Array(3)
                .fill(undefined)
                .map((_, idx) => <ItemLoading key={uid(idx)} />)
            }
          </MenuSection>
        </div>
        <ReloadButton />
      </>
    );
  }

  if (error || !user) {
    return (
      <>
        <HeaderSection>
          {() => (
            <ContainerHeader
              avatar={<UserAvatar embedded user={user ?? {}} />}
              text={user?.name ?? 'Unknown'}
              subText="User"
            />
          )}
        </HeaderSection>
        <Separator mb={1} />
        <ReloadButton />
      </>
    );
  }

  const { id, name } = user;

  return (
    <>
      <HeaderSection>
        {() => <ContainerHeader avatar={<UserAvatar embedded user={user} />} text={name} subText="User" />}
      </HeaderSection>
      <Separator mb={1} />
      <MenuSection canOverflow>
        {() => (
          <>
            <Item text="Overview" to={`/admin/users/${id}/overview`} />
            <Item text="Organisations" to={`/admin/users/${id}/memberships`} />
            <Item text="Locations" to={`/admin/users/${id}/locations`} />
          </>
        )}
      </MenuSection>
      <ReloadButton />
    </>
  );
};

export const userNav: NavigationView = {
  id: 'user/container',
  type: 'container',
  Items,
};
