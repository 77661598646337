import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link as RouterLink } from 'react-router-dom';
import { uid } from 'react-uid';
import { useTheme } from 'styled-components';
import { useGetOrganisationStatusSummary } from '../../../../../../app/ApiGen';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { Card, Indicator } from '../../../../../../design-system';
import { ChartWrapper, Count, Counts, Icon, Label, SkeletonChart, Value } from './styled';
import { getChartData, legacyStatusesByKey, statuses } from './utils';

export const ConnectorStatuses: React.FC<{ orgSlug: string }> = ({ orgSlug }) => {
  const { palette } = useTheme();
  const { loading, error, data, refetch } = useGetOrganisationStatusSummary({ orgSlug });

  if (loading) {
    return (
      <Card style={{ height: '100%' }} title="All connector statuses">
        <SkeletonChart>
          <Skeleton variant="circle" height="128px" width="128px" />
        </SkeletonChart>
        <Counts>
          {Array(statuses.length)
            .fill(undefined)
            .map((_: undefined, idx) => (
              <Count key={uid({ _ }, idx)}>
                <Skeleton width="100%" />
              </Count>
            ))}
        </Counts>
      </Card>
    );
  }

  if (!data || error) {
    return (
      <DataErrorHandler
        description="Unable to retrieve all connector statuses"
        refetch={refetch}
        error={error}
        type="embedded"
      />
    );
  }

  return (
    <Card
      style={{ height: '100%' }}
      title="All connector statuses"
      footer={
        <>
          <Box ml="auto" />
          <Button
            component={RouterLink}
            to={`/organisations/${orgSlug}/charge-points`}
            color="primary"
            variant="text"
            size="small"
            endIcon={<ChevronRightIcon />}
          >
            Charge points
          </Button>
        </>
      }
    >
      <ChartWrapper>
        <Doughnut
          options={{
            aspectRatio: 2.75,
            plugins: {
              legend: { display: false },
            },
          }}
          data={getChartData(data.data, palette)}
        />
      </ChartWrapper>
      <Box mt={1} />
      <Counts>
        {statuses.map(([statusKey], idx) => {
          const { color, label } = legacyStatusesByKey[statusKey];
          return (
            <Count key={uid(statusKey, idx)} tabIndex={0}>
              <Icon>
                <Indicator color={color} variant="dot" />
              </Icon>
              <Label>{label}</Label>
              <Box ml="auto" />
              <Value>{data.data[statusKey]}</Value>
            </Count>
          );
        })}
      </Counts>
    </Card>
  );
};
