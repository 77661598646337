import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React from 'react';
import * as Yup from 'yup';
import { Form, OnSubmit } from '../../../../design-system';
import { combineSchemas, createSchema } from '../../../../utils/formUtils';
import { newPasswordSchema } from '../../../Auth/ForgotPassword/components/EnterNewPasswordForm';

type ChangePassword = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

const initialValues: ChangePassword = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const validationSchema = combineSchemas(
  newPasswordSchema,
  createSchema<Pick<ChangePassword, 'currentPassword'>>({
    currentPassword: Yup.string().required('Current password is required'),
  }),
);

export interface ChangePasswordFormProps {
  onSubmit: OnSubmit<ChangePassword>;
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ onSubmit }: ChangePasswordFormProps) => {
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);

  return (
    <Form<ChangePassword>
      fields={[
        {
          id: 'currentPassword',
          label: 'Current password',
          inputProps: {
            type: showCurrentPassword ? 'text' : 'password',
            autoFocus: true,
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle current password visibility"
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    size="small"
                  >
                    {showCurrentPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          },
        },
        {
          id: 'newPassword',
          label: 'New password',
          inputProps: {
            type: showNewPassword ? 'text' : 'password',
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle new password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    size="small"
                  >
                    {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          },
        },
        {
          id: 'confirmNewPassword',
          label: 'Re-enter your new password',
          inputProps: {
            type: showNewPassword ? 'text' : 'password',
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle new password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    size="small"
                  >
                    {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          },
        },
      ]}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      isEditing
      labels={{ save: 'Change password' }}
    />
  );
};
