import { Box, Button, InputAdornment, TextField } from '@material-ui/core';
import { FieldArray as FormikFieldArray, Form as FormikForm, Formik } from 'formik';
import * as React from 'react';
import { uid } from 'react-uid';
import * as Yup from 'yup';
import { InputComponentProps } from '../../../design-system';
import type { ConnectorCreate, ConnectorFormat, ConnectorType, PowerType } from '../../../utils/api';
import { pluralise } from '../../../utils/format';
import { createSchema, FieldDefinitions, toChangeEvent } from '../../../utils/formUtils';
import type { DetailsOther } from '../../../views/Organisations/Organisation/Create/CreateChargePoint/types';
import { Actions } from '../../ConnectorItem/styled';
import { Dialog } from '../../Dialog';
import { ConnectorSection } from './ConnectorSection';
import { FormatInput } from './Format';
import { PowerTypeInput } from './PowerType';
import { StandardInput } from './Standard';

type ConnectorsForm = Pick<DetailsOther, 'connectors'>;

const defaultConnector = {
  connectorType: '' as ConnectorType,
  connectorFormat: '' as ConnectorFormat,
  powerType: '' as PowerType,
  voltage: 230,
  amperage: 32,
} as ConnectorCreate;

const connectorFields: FieldDefinitions<ConnectorCreate> = [
  {
    id: 'connectorType',
    label: 'Standard',
    inputComponent: StandardInput,
  },
  {
    id: 'connectorFormat',
    label: 'Format',
    inputComponent: FormatInput,
  },
  {
    id: 'powerType',
    label: 'Power type',
    inputComponent: PowerTypeInput,
  },
  {
    id: 'voltage',
    label: 'Max Voltage',
    inputProps: {
      type: 'number',
      InputProps: { endAdornment: <InputAdornment position="end">V</InputAdornment> },
    },
  },
  {
    id: 'amperage',
    label: 'Max Current',
    inputProps: {
      type: 'number',
      InputProps: { endAdornment: <InputAdornment position="end">A</InputAdornment> },
    },
  },
];

/* eslint no-template-curly-in-string: "off" --- Yup messages can be customised using template curlies in strings. */
const connectorValidationSchema = createSchema<ConnectorCreate & Record<string, unknown>>({
  connectorType: Yup.string<ConnectorType>().required('Connector standard is required'),
  connectorFormat: Yup.string<ConnectorFormat>().required('Connector format is required'),
  powerType: Yup.string<PowerType>().required('Connector power type is required'),
  voltage: Yup.number()
    .typeError('Connector voltage must be a number')
    .min(0, 'Connector voltage must be between ${min} and 920 volts')
    .max(920, 'Connector voltage must be between 0 and ${max} volts')
    .required('Connector voltage is required'),
  amperage: Yup.number()
    .typeError('Connector current must be a number')
    .min(6, 'Connector current must be between ${min} and 500 amps')
    .max(500, 'Connector current must be between 6 and ${max} amps')
    .required('Connector current is required'),
});
const validationSchema = Yup.object().shape({
  connectors: Yup.array().of(connectorValidationSchema).required('Charge point must have at least one connector'),
});

export const ConnectorsInput: React.FC<InputComponentProps<ConnectorCreate[]>> = ({
  disabled,
  error,
  helperText,
  name,
  onBlur,
  onChange,
  value,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onSubmit = ({ connectors }: ConnectorsForm) => {
    onChange(name)(toChangeEvent<ConnectorCreate[]>(new Event('change'), connectors));
    setIsOpen(false);
  };

  return (
    <>
      <TextField
        InputProps={{ readOnly: true, style: { cursor: 'pointer' }, inputProps: { style: { cursor: 'pointer' } } }}
        disabled={disabled}
        error={error}
        fullWidth
        helperText={helperText}
        margin="dense"
        onBlur={onBlur}
        onChange={onChange}
        onClick={() => setIsOpen(true)}
        style={{ margin: 0 }}
        value={pluralise(value.length, 'connector')}
        variant="outlined"
      />
      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)} title="Configure connectors" hasCloseButton={false}>
        <Formik<ConnectorsForm>
          initialValues={{ connectors: value ?? [] }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched }) => (
            <FormikForm autoComplete="off" style={{ padding: '24px' }}>
              <FormikFieldArray name={name}>
                {(arrayHelpers) => (
                  <>
                    {values.connectors.map((connector: ConnectorCreate, idx: number) => (
                      <ConnectorSection
                        arrayHelpers={arrayHelpers}
                        errors={errors}
                        fields={connectorFields}
                        key={uid(name, idx)}
                        name={name}
                        sectionId={idx}
                        touched={touched}
                      />
                    ))}
                    <Box mt={values.connectors.length > 0 ? 4 : 0} mb={3}>
                      <Button variant="outlined" fullWidth onClick={() => arrayHelpers.push(defaultConnector)}>
                        {values.connectors.length > 0 ? 'Add another connector' : 'Add connector'}
                      </Button>
                    </Box>
                  </>
                )}
              </FormikFieldArray>
              <Actions style={{ display: 'flex' }}>
                <Button variant="contained" onClick={() => setIsOpen(false)}>
                  Discard
                </Button>
                <Box ml="auto" />
                <Button variant="contained" color="primary" type="submit">
                  Done
                </Button>
              </Actions>
            </FormikForm>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
