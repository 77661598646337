import Button from '@material-ui/core/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigationView, Workspace } from '../../../design-system';
import { mainNav } from '../../navView';

const InstallersView: React.FC = () => {
  useNavigationView(mainNav);

  return (
    <Workspace maxWidth={false}>
      <Button component={Link} to="/admin/create/installer" color="primary" variant="contained">
        Create installer account
      </Button>
    </Workspace>
  );
};

export default InstallersView;
