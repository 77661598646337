import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useListChargePoints } from '../../../app/ApiGen';
import { Connectors } from '../../../components/Connectors';
import { ColumnFiltersToggle, RefreshButton } from '../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../components/ErrorHandler';
import { ExportCsv, ExportProps } from '../../../components/ExportCsv';
import { TableLink } from '../../../components/Link';
import {
  ColumnChanger,
  ColumnChangerProps,
  DataTable,
  SearchField,
  SearchFieldProps,
  Workspace,
} from '../../../design-system';
import {
  chargePointIdColumn,
  connectivityStatusColumn,
  firmwareVersionColumn,
  modelColumn,
  ocppChargePointIdColumn,
  referenceIdColumn,
  serialColumn,
  vendorColumn,
} from '../../../utils/chargePoints/table/columns';
import type { TableColumn } from '../../../utils/dataTable/columns';
import { getCondensedDate } from '../../../utils/dateFormat';
import { pluralise } from '../../../utils/format';
import { normalise } from '../../../utils/request';
import { connectorsIncludeSearch } from '../../../utils/search';
import { sortByConnectors, sortByLocale } from '../../../utils/sortBy';
import type { ChargePointState } from '../Commands/types';
import { constructTableChargePoints, TableChargePoint } from './utils';

const selectedChargePoints = (tableChargePoints: TableChargePoint[]): TableChargePoint[] =>
  tableChargePoints.filter((cp) => cp.tableData.checked);

const ChargePointsCommandAction: React.FC<{ chargePoints: TableChargePoint[]; count: number }> = ({
  chargePoints,
  count,
}) => {
  const { push } = useHistory();

  const goToBulkCommandPage = React.useCallback(
    (tableCps: TableChargePoint[]) => {
      const chargePointsState: ChargePointState[] = selectedChargePoints(tableCps).map((cp) => ({
        id: cp.id,
        name: cp.name,
        ocppChargePointId: cp.ocppChargePointId,
      }));
      push(`/admin/charge-points-command`, { chargePoints: chargePointsState });
    },
    [push],
  );

  return (
    <Button
      color="primary"
      variant="contained"
      disabled={count === 0}
      onClick={() => goToBulkCommandPage(chargePoints)}
    >
      {count > 0 ? `Send command to selection (${count})` : 'Send command to selection'}
    </Button>
  );
};

const SelectedChargePointsCount: React.FC<{ count: number }> = ({ count }) => (
  <Box p={2}>
    <Typography style={{ display: 'flex', flexDirection: 'row-reverse', fontWeight: 'bold' }}>
      {pluralise(count, 'charge point')} selected
    </Typography>
  </Box>
);

export const ChargePointsList: React.FC = () => {
  const { loading, error, data: chargePointsData, refetch } = useListChargePoints({});

  const [filtering, setFiltering] = React.useState<boolean>(false);
  const [tableChargePoints, setTableChargePoints] = React.useState<TableChargePoint[]>([]);
  const [columns] = React.useState<TableColumn<TableChargePoint>[]>([
    {
      title: 'Name',
      field: 'name',
      defaultSort: 'asc',
      customSort: sortByLocale('name'),
      render: ({ id, name, organisationSlug }) =>
        organisationSlug ? (
          <TableLink to={`/organisations/${organisationSlug}/charge-points/${id}`}>{name}</TableLink>
        ) : (
          name
        ),
    },
    {
      title: 'Organisation',
      field: 'organisationName',
      customSort: sortByLocale('organisationName'),
      render: ({ organisationName, organisationSlug }) =>
        organisationSlug ? (
          <TableLink to={`/organisations/${organisationSlug}`}>{organisationName}</TableLink>
        ) : (
          organisationName
        ),
    },
    connectivityStatusColumn<TableChargePoint>(),
    referenceIdColumn<TableChargePoint>(),
    ocppChargePointIdColumn<TableChargePoint>(),
    chargePointIdColumn<TableChargePoint>(),
    serialColumn<TableChargePoint>(),
    modelColumn<TableChargePoint>(),
    vendorColumn<TableChargePoint>(),
    firmwareVersionColumn<TableChargePoint>(),
    {
      title: 'Connectors',
      field: 'connectorStatuses',
      render: ({ connectors, networkStatus }) => <Connectors connectors={connectors} networkStatus={networkStatus} />,
      customFilterAndSearch: connectorsIncludeSearch,
      customSort: sortByConnectors,
    },
    {
      title: 'Active this month',
      field: 'activeThisMonth',
      hidden: true,
    },
    {
      title: 'ICCID',
      field: 'iccid',
      hidden: true,
    },
    {
      title: 'Created',
      render: ({ createdDate }) => getCondensedDate(createdDate),
      field: 'createdDate',
      hidden: true,
    },
    {
      title: 'Updated',
      field: 'updatedDate',
      render: ({ updatedDate }) => getCondensedDate(updatedDate),
      hidden: true,
    },
  ]);
  const [selectedChargePointsCount, setSelectedChargePointsCount] = React.useState<number>(0);

  React.useEffect(() => {
    if (chargePointsData) {
      const chargePoints = normalise(chargePointsData);
      setTableChargePoints(constructTableChargePoints(chargePoints));
    }
  }, [chargePointsData]);

  if (error) {
    return <DataErrorHandler description="Unable to load charge points" error={error} refetch={refetch} />;
  }

  return (
    <Workspace maxWidth="xl">
      <Card>
        <DataTable<TableChargePoint>
          isLoading={loading}
          options={{ columnsButton: true, filtering, selection: true }}
          toolbarProps={{
            search: ({ search, searchText, onSearchChanged }: SearchFieldProps) => (
              <SearchField
                search={search}
                searchText={searchText}
                onSearchChanged={onSearchChanged}
                placeholder="Search charge points..."
              />
            ),
            actions: (props: ColumnChangerProps & ExportProps<TableChargePoint>) => (
              <>
                <ColumnChanger
                  columnsButton={props.columnsButton}
                  columns={props.columns}
                  icons={props.icons}
                  onColumnsChanged={props.onColumnsChanged}
                />
                <ColumnFiltersToggle filtering={filtering} setFiltering={setFiltering} />
                <RefreshButton refetch={refetch} />
                <ExportCsv
                  columns={props.columns}
                  data={props.data}
                  exportFileName="charge-points"
                  getFieldValue={props.getFieldValue}
                  icons={props.icons}
                />
                <ChargePointsCommandAction chargePoints={tableChargePoints} count={selectedChargePointsCount} />
              </>
            ),
          }}
          onSelectionChange={(selected) => setSelectedChargePointsCount(selected.length)}
          columns={columns}
          data={tableChargePoints}
        />
        <SelectedChargePointsCount count={selectedChargePointsCount} />
      </Card>
    </Workspace>
  );
};
