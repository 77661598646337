import { Chart, ChartOptions } from 'chart.js';
import React from 'react';
import { Line as LineChart } from 'react-chartjs-2';
import { ResetZoomButton } from '../../../../../../../components/ResetZoomButton';
import { Section } from '../../../../../../../design-system';
import { verticalSelectorLinePlugin } from '../../../../../../../utils/chart';
import { DataTransferLog } from '../utils/configuration';
import { LLMChartModel } from '../utils/LLMChartModel';
import { chartIds, getChartOptions, Phase } from '../utils/presentation';

type LoadGraphProps = {
  logs: DataTransferLog[];
  onClickHandler: (chart: Chart) => void;
};

const chartContainerStyles = { paddingTop: 16, paddingBottom: 16 };
const chartStyles = { height: '50vh' };

const ChartContainer: React.FC<{ title: string }> = ({ title, children }) => (
  <Section style={chartContainerStyles} title={title}>
    <ResetZoomButton<Phase> chartIds={chartIds} />
    <Section style={chartStyles}>{children}</Section>
  </Section>
);

export const LoadChart: React.VFC<LoadGraphProps> = ({ logs, onClickHandler }: LoadGraphProps) => {
  const chartData = LLMChartModel.fromLogs(logs).constructChartData();

  const [options] = React.useState<ChartOptions<'line'>>(getChartOptions(onClickHandler));

  return (
    <Section>
      <ChartContainer title="Phase L1 Load (A)">
        <LineChart id={chartIds.phaseL1} options={options} data={chartData.L1} plugins={[verticalSelectorLinePlugin]} />
      </ChartContainer>
      <ChartContainer title="Phase L2 Load (A)">
        <LineChart id={chartIds.phaseL2} options={options} data={chartData.L2} plugins={[verticalSelectorLinePlugin]} />
      </ChartContainer>
      <ChartContainer title="Phase L3 Load (A)">
        <LineChart id={chartIds.phaseL3} options={options} data={chartData.L3} plugins={[verticalSelectorLinePlugin]} />
      </ChartContainer>
    </Section>
  );
};
