import React from 'react';
import { WizardStep } from '../../../../../components/WizardStep';
import { OnSubmit, useWizard } from '../../../../../design-system';
import { jobDetailsFields, jobDetailsValidationSchema, JobFormDetails } from '../../../../../utils/jobs/CreateJob';
import type { JobWizardState } from '../types';

export const JobDetailsStep: React.FC = () => {
  const { state: untypedState, setState: untypedSetState, prevStep, nextStep, step } = useWizard();
  const state = untypedState as JobWizardState;
  const setState = untypedSetState as (state: Partial<JobWizardState>) => void;
  const initialValues: Required<JobFormDetails> = {
    referenceId: '',
    contactPhoneNumber: '',
    powerSensorCount: 1,
  };

  const onCancel = React.useCallback<OnSubmit<JobFormDetails>>(
    (values) => {
      setState(values);
      prevStep();
    },
    [setState, prevStep],
  );

  const onSubmit = React.useCallback<OnSubmit<JobFormDetails>>(
    (job: JobFormDetails) => {
      setState(job);
      nextStep();
    },
    [setState, nextStep],
  );

  return (
    <WizardStep<JobFormDetails>
      fields={Object.values(jobDetailsFields)}
      initialValues={{ ...initialValues, ...state }}
      name="Job details"
      onCancel={onCancel}
      onSubmit={onSubmit}
      step={step}
      subtitle="Details about the installation job"
      validationSchema={jobDetailsValidationSchema}
    />
  );
};
