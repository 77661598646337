import { rgba } from 'polished';
import styled from 'styled-components';

export const StyledList = styled('div')<{
  alternate?: 'even' | 'odd';
  variant?: 'alternate' | 'bordered';
  inset?: boolean;
}>`
  margin: ${(p) => (p.inset ? p.theme.spacing(0, 2) : 0)};
  display: flex;
  flex-flow: nowrap column;

  ${(p) =>
    p.variant === 'bordered' &&
    `
  > * {
    border-bottom: 1px solid ${rgba(p.theme.palette.evnex.main, 0.13)};

    &:last-child {
      border-bottom: none;
    }
  }
  `}
`;
