import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import AddLocationImg from '../../../assets/charge-points/evnex/add-location.png';
import { NoContentPlaceholderProps } from '../types';
import AddChargePointImg from '../../../assets/charge-points/evnex/add-charge-points.png';

export const NoLocations: React.FC<NoContentPlaceholderProps> = ({ orgSlug, isDriver }: NoContentPlaceholderProps) => {
  if (isDriver) {
    return (
      <>
        <Typography variant="h6" gutterBottom component="h3">
          No Locations
        </Typography>
        <Typography variant="body2" gutterBottom component="p">
          This is where your charge point location will appear once the charge point is installed.
        </Typography>
        <Typography variant="body2" gutterBottom component="p" style={{ paddingBottom: 20 }}>
          If your charge point has already been installed but the location isn’t showing up here, please contact your
          installer.
        </Typography>
        <img src={AddChargePointImg} alt="add-charge-point" style={{ height: 230, width: 250, marginBottom: 20 }} />
      </>
    );
  }

  return (
    <>
      <Typography variant="h6" gutterBottom component="h3">
        Add Locations
      </Typography>
      <Typography variant="body2" gutterBottom component="p">
        Your account has no locations.
      </Typography>
      <Typography variant="body2" gutterBottom component="p" style={{ paddingBottom: 20 }}>
        Click below to add one now.
      </Typography>
      <img src={AddLocationImg} alt="add-location" style={{ height: 230, width: 250, marginBottom: 20 }} />
      <Button
        component={Link}
        to={`/organisations/${orgSlug}/create/location`}
        color="primary"
        variant="contained"
        style={{ marginBottom: 40 }}
      >
        Add Location
      </Button>
    </>
  );
};
