import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as React from 'react';
import { useTheme } from 'styled-components';
import { Logo } from '../Logo';
import { Brand, SplashWrapper } from './styled';

const Splash: React.FC = () => {
  const { palette } = useTheme();

  return (
    <Fade in>
      <Backdrop open style={{ backgroundColor: palette.background.default }}>
        <SplashWrapper>
          <Brand>
            <Logo variant="dark" />
            <Box width="50%">
              <LinearProgress color="primary" />
            </Box>
          </Brand>
        </SplashWrapper>
      </Backdrop>
    </Fade>
  );
};

Splash.displayName = 'Splash';

export { Splash };
