import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import moment from 'moment-timezone';
import prettyMs from 'pretty-ms';
import React from 'react';
import { Link } from 'react-router-dom';
import { uid } from 'react-uid';
import { ChargePointSummary, Session, useListChargePointSessions } from '../../../../../../../app/ApiGen';
import { useDeveloperMode } from '../../../../../../../app/DeveloperModeProvider';
import { DataErrorHandler } from '../../../../../../../components/ErrorHandler';
import { Card, List, ListHeader, ListItem, Loading } from '../../../../../../../design-system';
import type { SubState } from '../../../../../../../store/types';
import { getCondensedDate } from '../../../../../../../utils/dateFormat';
import { normalise, Normalised } from '../../../../../../../utils/request';

type SessionItemProps = Pick<
  Normalised<Session>,
  'startDate' | 'endDate' | 'totalCost' | 'totalPowerUsage' | 'cost' | 'chargingConfiguration'
>;

const SessionItem: React.FC<SessionItemProps> = ({
  startDate,
  endDate,
  totalCost,
  totalPowerUsage,
  cost,
  chargingConfiguration,
}) => (
  <ListItem>
    <Grid container>
      <Grid item xs={2}>
        {getCondensedDate(startDate)}
      </Grid>
      <Grid item xs={2}>
        {startDate && endDate && <>{prettyMs(moment(endDate).diff(moment(startDate)), { secondsDecimalDigits: 0 })}</>}
      </Grid>
      <Grid item xs={2}>
        {totalPowerUsage !== undefined && <>{Number((totalPowerUsage / 1000).toFixed(2)).toLocaleString()} kWh</>}
      </Grid>
      <Grid item xs={2}>
        {totalCost ? (
          <>
            ${Number(totalCost.amount).toFixed(2)} ({totalCost.currency})
          </>
        ) : (
          <>$0.00</>
        )}
      </Grid>
      <Grid item xs={2}>
        {cost && (
          <>
            ${Number(cost.total).toFixed(2)} {chargingConfiguration?.currency ?? 'NZD'}
          </>
        )}
      </Grid>
    </Grid>
  </ListItem>
);

export const RecentSessions: React.FC<{
  chargePoint: Normalised<ChargePointSummary>;
  organisation: SubState<'organisation'>;
}> = ({ chargePoint, organisation }) => {
  const { loading, error, data, refetch } = useListChargePointSessions({ id: chargePoint.id });
  const { isDeveloperMode } = useDeveloperMode();

  if (loading) {
    return (
      <Card style={{ height: '100%' }} title="Recent sessions">
        <Loading p={2} size={32} />
      </Card>
    );
  }

  if (error || !data) {
    return (
      <DataErrorHandler description="Unable to load recent sessions" error={error} refetch={refetch} type="embedded" />
    );
  }

  const sessions = data ? normalise(data).slice(0, 5) : [];

  return (
    <Card
      style={{ height: '100%' }}
      title="Recent sessions"
      footer={
        <>
          <Box ml="auto" />
          <Button
            component={Link}
            to={`/organisations/${organisation.slug}/charge-points/${chargePoint.id}/sessions`}
            color="primary"
            variant="text"
            size="small"
            endIcon={<ChevronRightIcon />}
          >
            View sessions
          </Button>
        </>
      }
    >
      <Box flex="1 1 auto">
        <List alternate="odd">
          <ListHeader>
            <Grid container>
              <Grid item xs={2}>
                Start time
              </Grid>
              <Grid item xs={2}>
                Duration
              </Grid>
              <Grid item xs={2}>
                Energy delivered
              </Grid>
              <Grid item xs={2}>
                Total cost
              </Grid>
              {isDeveloperMode && (
                <Grid item xs={2}>
                  Total cost (new)
                </Grid>
              )}
            </Grid>
          </ListHeader>

          {sessions &&
            sessions.map((session, idx) => (
              <SessionItem
                key={uid(session, idx)}
                startDate={session.startDate}
                endDate={session.endDate}
                totalCost={session.totalCost}
                totalPowerUsage={session.totalPowerUsage}
                cost={isDeveloperMode ? session.cost : undefined}
              />
            ))}

          {sessions.length === 0 && (
            <Box p={2} display="flex" justifyContent="center">
              <Typography variant="body2">No sessions to display</Typography>
            </Box>
          )}
        </List>
      </Box>
    </Card>
  );
};
