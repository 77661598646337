import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useNavigationView } from '../../design-system';
import { mainNav } from '../navView';
import CreateOrganisationView from '../Organisations/CreateOrganisation';
import CreateInstallerView from './Installers/CreateInstaller';
import CreateJobView from './Jobs/CreateJob';

const CreateView: React.FC = () => {
  const { path } = useRouteMatch();
  useNavigationView(mainNav);

  return (
    <Switch>
      <Route path={`${path}/installer`}>
        <CreateInstallerView />
      </Route>
      <Route path={`${path}/job`}>
        <CreateJobView />
      </Route>
      <Route path={`${path}/organisation`}>
        <CreateOrganisationView />
      </Route>
    </Switch>
  );
};

export default CreateView;
