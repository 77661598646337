import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useCommandGetConfiguration } from '../../../../../../../app/ApiGen';
import { ErrorHandler } from '../../../../../../../components/ErrorHandler';
import { Card, DataTable, Section } from '../../../../../../../design-system';
import { ChargePoint } from '../../../../../../../utils/api';
import { ConfigurationKey } from '../../../../../../../utils/chargePoints/commands';
import {
  getLimitCalculationData,
  LimitCalculationData,
  LLMServerStatusLog,
} from '../../../../../../../utils/chargePoints/diagnostics';
import { standardiseError } from '../../../../../../../utils/error';

type LimitCalculationTableProps = {
  chargePoint: ChargePoint;
  log: LLMServerStatusLog | undefined;
};

const supplyCurrentLimitConfigurationKey: ConfigurationKey = 'SupplyCurrentLimit';

export const LimitCalculationTable: React.FC<LimitCalculationTableProps> = ({ chargePoint, log }) => {
  const [supplyCurrentLimit, setSupplyCurrentLimit] = React.useState<number>();
  const [error, setError] = React.useState<string>();

  const { loading, mutate } = useCommandGetConfiguration({ id: chargePoint.id });

  const chargePointOnline = chargePoint.networkStatus === 'ONLINE';

  React.useEffect(() => {
    if (chargePointOnline) {
      mutate({ keys: [supplyCurrentLimitConfigurationKey] })
        .then(({ data: { items } }) => {
          const configuration = items[0];
          if (configuration?.key === supplyCurrentLimitConfigurationKey && configuration?.value) {
            setSupplyCurrentLimit(parseFloat(configuration.value));
          }
        })
        .catch((e) => {
          setError(standardiseError(e).message);
        });
    }
  }, [chargePointOnline, mutate]);

  const tableData = log ? getLimitCalculationData({ log, supplyCurrentLimit }) : [];

  if (error) {
    return <ErrorHandler description="Unable to load limit calculation table" error={error} />;
  }

  return (
    <Section>
      <Card>
        <Typography style={{ padding: 16 }} variant="body2">
          Supply current limit: {supplyCurrentLimit ?? 'unknown'} {!chargePointOnline && '(charge point offline)'}
        </Typography>
        <DataTable<LimitCalculationData>
          options={{ filtering: true, pageSize: 50 }}
          isLoading={loading}
          localization={{
            body: { emptyDataSourceMessage: 'Click on a point in the graph to see its limit calculation data.' },
          }}
          columns={[
            { title: 'Phase', field: 'phase' },
            { title: 'Group Limit', field: 'groupLimit' },
            { title: 'Non Charging Currents', field: 'nonChargingCurrents' },
            {
              title: 'Total',
              field: 'total',
              render: ({ total, totalAboveLimit }) =>
                totalAboveLimit ? (
                  <Typography color="error" style={{ fontWeight: 'bold' }} variant="body2">
                    {total}
                  </Typography>
                ) : (
                  total
                ),
            },
          ]}
          data={tableData}
        />
      </Card>
    </Section>
  );
};
