import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateAccount } from '../../../app/ApiGen';
import { errorSnackbar } from '../../../components/ErrorSnackbar';
import { Card, Form, OnSubmit, Section, Workspace } from '../../../design-system';
import { standardiseError } from '../../../utils/error';
import { UserCreateDetails, userCreateFields, userCreateValidation } from '../../../utils/users/FieldDefinitions';

const initialValues: UserCreateDetails = { email: '', name: '' };

const CreateInstallerView: React.FC = () => {
  const { push } = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { mutate: createUser } = useCreateAccount({});

  const onSubmit: OnSubmit<UserCreateDetails> = ({ email, name }, { setFieldError, setSubmitting }) => {
    setFieldError('email', undefined);
    createUser({
      data: { type: 'users', attributes: { email: email.trim(), name, userType: 'Installer' } },
    })
      .then(({ data }) => {
        setSubmitting(false);
        enqueueSnackbar(`Invitation email sent to ${data.attributes.email}`, { variant: 'success' });
        push('/admin/installers');
      })
      .catch((e) => {
        setSubmitting(false);
        const error = standardiseError(e);
        if (error.title === 'AlreadyExistsError') {
          setFieldError('email', 'User with this email address already exists');
        } else {
          errorSnackbar('Unable to create installer account', closeSnackbar, enqueueSnackbar, e);
        }
      });
  };

  return (
    <Workspace>
      <Section
        title="Create installer account"
        description={
          <>
            Create a new account to add someone to Evnex&apos;s list of installers. They will receive an invitation
            email with instructions to verify their account. Once their account is confirmed they will be able to login
            to the Evnex Installer App.
          </>
        }
      >
        <Card>
          <Form<UserCreateDetails>
            fields={Object.values(userCreateFields)}
            initialValues={initialValues}
            isEditing
            labels={{ save: 'Invite installer' }}
            onSubmit={onSubmit}
            validationSchema={userCreateValidation}
          />
        </Card>
      </Section>
    </Workspace>
  );
};

export default CreateInstallerView;
