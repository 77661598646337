import React from 'react';
import type {
  Job,
  JobStatus as NumericJobStatus,
  JobType as JobTypeValue,
  LocationSummary,
  UserIdentifier,
  UserSummary,
} from '../../app/ApiGen';
import { JobStatus, JobType } from '../../app/enums';
import { Address } from '../../components/Address';
import { HumanReadableJobStatus, JobStatusValue } from '../../components/CustomFields/JobStatus';
import type { ColumnDefinitions } from '../dataTable/columns';
import { getDisplayUser, locationHasAttributes, userHasAttributes } from '../format';
import type { Normalised } from '../request';
import { addressIncludesSearch, userIncludesSearch } from '../search';
import { sortByAddress } from '../sortBy';

export type JobListData = {
  address?: Normalised<LocationSummary>['address'];
  created: Date;
  customer: UserIdentifier | Normalised<UserSummary> | null;
  customerName: string;
  email?: string;
  icpNumber?: string;
  id: string;
  installer: UserIdentifier | Normalised<UserSummary> | null;
  installerLabel: string;
  organisationName: string;
  status: NumericJobStatus;
  type: JobTypeValue;
  updated: Date;
  referenceId?: string;
};

type JobColumn = Exclude<keyof JobListData, 'created' | 'customer' | 'id' | 'installer' | 'updated'>;

export const jobListOptions = {
  columnsButton: true,
  filtering: true,
  exportButton: true,
  exportFileName: 'jobs',
  pageSize: 50,
  search: true,
};

export const HumanReadableJobType: Record<JobTypeValue, string> = {
  [JobType.ResidentialInstall]: 'Residential Install',
};

export const jobColumns: ColumnDefinitions<JobListData, JobColumn> = {
  address: {
    field: 'address',
    title: 'Customer Address',
    hidden: true,
    render: (job) => (job.address ? <Address address={job.address} /> : ''),
    customSort: sortByAddress('address'),
    customFilterAndSearch: (searchValue, { address }) =>
      address ? addressIncludesSearch(address, searchValue) : false,
  },
  customerName: {
    field: 'customerName',
    title: 'Customer Name',
    customFilterAndSearch: (search, { customer, customerName }) =>
      userIncludesSearch({ user: customer, label: customerName, search }),
  },
  email: { field: 'email', title: 'Customer Email', hidden: true },
  icpNumber: { field: 'icpNumber', title: 'ICP/NMI number', hidden: true },
  installerLabel: {
    field: 'installerLabel',
    title: 'Installed By',
    hidden: true,
    customFilterAndSearch: (search, { installer, installerLabel }) =>
      userIncludesSearch({ user: installer, label: installerLabel, search }),
  },
  organisationName: { field: 'organisationName', title: 'Organisation' },
  referenceId: { field: 'referenceId', title: 'Reference ID', filterCellStyle: { maxWidth: '6rem' } },
  status: {
    field: 'status',
    title: 'Status',
    type: 'numeric',
    defaultFilter: [JobStatus.Pending.toString(10), JobStatus.Commissioning.toString(10), JobStatus.Error.toString(10)],
    filterCellStyle: { maxWidth: '6rem' },
    lookup: HumanReadableJobStatus,
    render: (job) => <JobStatusValue value={job.status} maxWidth="5em" />,
  },
  type: { field: 'type', title: 'Job Type', lookup: HumanReadableJobType },
};

export function toJobListData(job: Normalised<Job>): JobListData {
  const location = locationHasAttributes(job.location) ? job.location : undefined;
  return {
    id: job.id,
    address: location?.address,
    created: new Date(job.created),
    email: userHasAttributes(job.customer) ? job.customer.email : undefined,
    icpNumber: location?.icpNumber ?? job.details.icpNumber,
    installer: job.installer,
    installerLabel: getDisplayUser({ user: job.installer, noUserLabel: 'n/a' }),
    customer: job.customer,
    customerName: getDisplayUser({ user: job.customer, noUserLabel: '(unknown)' }),
    organisationName: job.organisationName,
    status: job.status,
    type: job.jobType,
    updated: new Date(job.updated),
    referenceId: job.referenceId,
  };
}
