import type { OverridableComponent, OverridableTypeMap } from '@material-ui/core/OverridableComponent';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BusinessIcon from '@material-ui/icons/Business';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EvStationIcon from '@material-ui/icons/EvStation';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import PeopleIcon from '@material-ui/icons/People';
import PlaceIcon from '@material-ui/icons/Place';
import React from 'react';
import { useSelector } from 'react-redux';
import { uid } from 'react-uid';
import { Item, ItemLoading, MenuSection, NavigationView } from '../design-system';
import { fullStateSelector } from '../store/root';

type NavItemProps = { icon: OverridableComponent<OverridableTypeMap>; text: string; to: string };
function getItems(state: {
  hasOrganisationMemberships: boolean | undefined;
  isCollapsed: boolean;
  isEvnexAdmin: boolean;
  isInstaller: boolean;
}): NavItemProps[] {
  const { hasOrganisationMemberships, isEvnexAdmin, isInstaller } = state;
  const navItems: NavItemProps[] = [];

  if (hasOrganisationMemberships && (isEvnexAdmin || isInstaller)) {
    navItems.push({ icon: DashboardIcon, text: 'Select organisation', to: '/organisations' });
  }
  if (isEvnexAdmin) {
    navItems.push(
      { icon: BusinessIcon, text: 'Organisations', to: '/admin/organisations' },
      { icon: EvStationIcon, text: 'Charge points', to: '/admin/charge-points' },
      { icon: PlaceIcon, text: 'Locations', to: '/admin/locations' },
      { icon: AssignmentTurnedInIcon, text: 'Jobs', to: '/admin/jobs' },
      { icon: HowToRegIcon, text: 'Installers', to: '/admin/installers' },
      { icon: PeopleIcon, text: 'Users', to: '/admin/users' },
    );
  } else if (isInstaller) {
    navItems.push({ icon: AssignmentTurnedInIcon, text: 'My Jobs', to: '/installer/jobs' });
  }

  return navItems;
}

const Items: React.FC = () => {
  const { authenticatedUser, isEvnexAdmin, isInstaller } = useSelector(fullStateSelector);

  return (
    <MenuSection canOverflow>
      {({ isCollapsed }) => {
        const navItems = getItems({
          hasOrganisationMemberships: authenticatedUser.current && authenticatedUser.current.organisations.length > 0,
          isCollapsed,
          isEvnexAdmin,
          isInstaller,
        });

        if (authenticatedUser.loading || !authenticatedUser.current || authenticatedUser.error) {
          // NB: Can only use test ID to find an ItemLoading element in the document, since it has no ARIA role.
          return (
            <div data-testid="skeleton">
              {navItems.map((_, idx) => (
                <ItemLoading key={uid(idx)} />
              ))}
            </div>
          );
        }

        return (
          <>
            {navItems.map(({ icon, text, to }, idx) => (
              <Item key={uid(text, idx)} icon={icon} text={text} to={to} />
            ))}
          </>
        );
      }}
    </MenuSection>
  );
};

export const mainNav: NavigationView = {
  id: 'main/product',
  type: 'product',
  Items,
};
