import React from 'react';
import { WizardStep } from '../../../../../components/WizardStep';
import { OnSubmit, useWizard } from '../../../../../design-system';
import { UserCreateDetails, userCreateFields, userCreateValidation } from '../../../../../utils/users/FieldDefinitions';
import type { JobWizardState } from '../types';

export const UserDetailsStep: React.FC = () => {
  const { state: untypedState, setState: untypedSetState, prevStep, nextStep, step } = useWizard();
  const state = untypedState as Partial<JobWizardState>;
  const setState = untypedSetState as (state: Partial<JobWizardState>) => void;

  const initialValues: UserCreateDetails = { email: '', name: '' };

  const onCancel = React.useCallback<OnSubmit<UserCreateDetails>>(
    (values) => {
      setState({ driverFieldContents: values });
      prevStep();
    },
    [setState, prevStep],
  );

  const onSubmit = React.useCallback<OnSubmit<UserCreateDetails>>(
    (values, { setFieldError }) => {
      setFieldError('email', undefined);
      setState({ driverFieldContents: { ...values, name: values.name.trim() } });
      nextStep();
    },
    [nextStep, setState],
  );

  return (
    <WizardStep<UserCreateDetails>
      fields={Object.values(userCreateFields)}
      initialValues={state.driverFieldContents ?? initialValues}
      name="User details"
      onCancel={onCancel}
      onSubmit={onSubmit}
      step={step}
      subtitle="Enter details about the user account to create for the customer."
      validationSchema={userCreateValidation}
    />
  );
};
