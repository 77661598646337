import React from 'react';
import * as Yup from 'yup';
import { WizardStep } from '../../../../components/WizardStep';
import { OnSubmit, useWizard } from '../../../../design-system';
import { createSchema } from '../../../../utils/formUtils';
import { CommandsWizardState, CommandType, UpdateFirmwareStepFields } from '../types';

const validationSchema = createSchema<UpdateFirmwareStepFields>({
  firmwareUrl: Yup.string().required('Firmware URL is required'),
});

export const UpdateFirmwareStep: React.FC = () => {
  const { state: untypedState, setState: untypedSetState, prevStep, nextStep, step } = useWizard();
  const state = untypedState as Partial<CommandsWizardState>;
  const setState = untypedSetState as (state: Partial<CommandsWizardState>) => void;

  const onCancel = React.useCallback<OnSubmit<UpdateFirmwareStepFields>>(
    ({ firmwareUrl }) => {
      setState({ command: { type: CommandType.UPDATE_FIRMWARE, firmwareUrl } });
      prevStep();
    },
    [setState, prevStep],
  );

  const onSubmit = React.useCallback<OnSubmit<UpdateFirmwareStepFields>>(
    ({ firmwareUrl }, { setFieldError }) => {
      setFieldError('firmwareUrl', undefined);
      setState({ command: { type: CommandType.UPDATE_FIRMWARE, firmwareUrl } });
      nextStep();
    },
    [setState, nextStep],
  );

  return (
    <WizardStep<UpdateFirmwareStepFields>
      fields={[{ id: 'firmwareUrl', label: 'Firmware URL' }]}
      initialValues={{ firmwareUrl: state.command?.firmwareUrl ?? '' }}
      name="Enter firmware URL"
      onCancel={onCancel}
      onSubmit={onSubmit}
      step={step}
      subtitle="Enter the URL containing the firmware you wish to update to. Clicking 'Next' will start the upgrade process."
      validationSchema={validationSchema}
    />
  );
};
