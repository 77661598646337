import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo';
import React from 'react';
import { uid } from 'react-uid';
import type { Connector, NetworkStatus, OcppStatus } from '../../app/ApiGen';
import { Indicator, IndicatorColor } from '../../design-system';
import { Statuses } from './styled';

interface ConnectorsProps {
  connectors: Pick<Connector, 'connectorId' | 'ocppStatus'>[];
  networkStatus: NetworkStatus;
}

interface StatusIndicator {
  label: string;
  color: IndicatorColor;
}

const StatusIndicators: Record<OcppStatus | Exclude<NetworkStatus, 'ONLINE'>, StatusIndicator> = {
  AVAILABLE: { label: 'Available', color: 'cyan' },
  CHARGING: { label: 'Charging', color: 'enabled' },
  FAULTED: { label: 'Faulted', color: 'red' },
  FINISHING: { label: 'Finishing', color: 'blue' },
  NEVER_CONNECTED: { label: 'Never connected', color: 'disabled' },
  OFFLINE: { label: 'Offline', color: 'steel' },
  PREPARING: { label: 'Preparing', color: 'blue' },
  RESERVED: { label: 'Reserved', color: 'deepOrange' },
  SUSPENDED_EV: { label: 'Suspended EV', color: 'amber' },
  SUSPENDED_EVSE: { label: 'Suspended EVSE', color: 'purple' },
  UNAVAILABLE: { label: 'Unavailable', color: 'disabled' },
};

export const getStatusIndicator = (
  networkStatus: NetworkStatus,
  connector: ConnectorsProps['connectors'][number],
): StatusIndicator => {
  const status = networkStatus !== 'ONLINE' ? networkStatus : connector.ocppStatus;
  return StatusIndicators[status];
};

export const Connectors: React.FC<ConnectorsProps> = ({ connectors, networkStatus }) => (
  <Statuses aria-label="connectors">
    {connectors.map((connector, idx) => {
      const { color, label } = getStatusIndicator(networkStatus, connector);
      return (
        <Indicator
          key={uid(connector, idx)}
          variant="dot"
          color={color}
          icon={<SettingsInputSvideoIcon />}
          label={`#${connector.connectorId} ${label}`}
          toolTipText={`#${connector.connectorId} ${label}`}
        />
      );
    })}
  </Statuses>
);
