import * as React from 'react';
import type { LocationInfoPos } from './types';

interface LocationMapContextProps {
  mapRef?: google.maps.Map;
  setMapRef: (ref: google.maps.Map) => void;
  isPanelOpen: boolean;
  clusterLocations?: LocationInfoPos[];
  setClusterLocations: (locations: LocationInfoPos[]) => void;
  singleLocation?: LocationInfoPos;
  setSingleLocation: (location: LocationInfoPos) => void;
}

const LocationMapContext = React.createContext<LocationMapContextProps>({
  mapRef: undefined,
  setMapRef: () => {},
  isPanelOpen: false,
  clusterLocations: undefined,
  setClusterLocations: () => {},
  singleLocation: undefined,
  setSingleLocation: () => {},
});

export const LocationMapProvider: React.FC = ({ children }) => {
  const mapRef = React.useRef<google.maps.Map | undefined>(undefined);

  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [clusterLocations, setClusterLocations] = React.useState<undefined | LocationInfoPos[]>(undefined);
  const [singleLocation, setSingleLocation] = React.useState<undefined | LocationInfoPos>(undefined);

  const updateClusterLocations = React.useCallback(
    (locations: LocationInfoPos[]) => {
      setClusterLocations(locations);
      setSingleLocation(undefined);

      if (!isPanelOpen) {
        setIsPanelOpen(true);
      }
    },
    [isPanelOpen],
  );

  const updateSingleLocation = React.useCallback(
    (location: LocationInfoPos) => {
      setClusterLocations(undefined);
      setSingleLocation(location);

      if (!isPanelOpen) {
        setIsPanelOpen(true);
      }
    },
    [isPanelOpen],
  );

  const updateMapRef = React.useCallback((ref: google.maps.Map) => {
    mapRef.current = ref;
  }, []);

  return (
    <LocationMapContext.Provider
      value={{
        mapRef: mapRef.current,
        setMapRef: updateMapRef,
        isPanelOpen,
        clusterLocations,
        setClusterLocations: updateClusterLocations,
        singleLocation,
        setSingleLocation: updateSingleLocation,
      }}
    >
      {children}
    </LocationMapContext.Provider>
  );
};

export const useLocationMap = (): LocationMapContextProps => React.useContext(LocationMapContext);
