import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';
import { InputComponentProps, ValueComponentProps } from '../../design-system';

export const Swatch = styled('div')`
  display: block;
  height: 20px;
  width: 20px;
  background-color: ${(p) => p.color};
  border-radius: 2px;
`;

export const Label = styled('span')`
  margin-left: ${(p) => p.theme.spacing(1)}px;
`;

export const ColorValue: React.FC<ValueComponentProps<string>> = ({ value }) => (
  <Box display="flex" alignItems="center">
    <Swatch color={value} />
    <Label>{value}</Label>
  </Box>
);

export const ColorInput: React.FC<InputComponentProps<string>> = ({ value, onBlur, onChange, ...other }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <>
      <TextField
        variant="outlined"
        margin="dense"
        fullWidth
        style={{ margin: 0 }}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Swatch color={value} />
            </InputAdornment>
          ),
        }}
        value={value}
        {...other}
        onClick={(event: React.MouseEvent<HTMLElement>) => setAnchorEl(anchorEl ? null : event.currentTarget)}
      />
      <Popper
        id="user-settings-color-picker"
        style={{ marginTop: '4px', marginBottom: '4px', zIndex: 9999 }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <div>
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <ChromePicker disableAlpha color={value} onChangeComplete={({ hex }) => onChange('color')(hex)} />
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
};
