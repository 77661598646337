import { useHistory } from 'react-router-dom';

type OnRowClick<D> = (event: unknown, rowData: D | undefined) => void;

/**
 * Helper function for using table rows as links, ensuring that the location isn't pushed to the navigation history
 * multiple times.
 */
export function navigateOnRowClick<D>(
  to: (rowData: D) => string,
  history: Pick<ReturnType<typeof useHistory>, 'push' | 'location'>,
): OnRowClick<D> {
  return (_, clickedRow) => {
    if (!clickedRow) {
      return;
    }
    const navigateTo = to(clickedRow);
    if (navigateTo && navigateTo !== history.location.pathname) {
      history.push(navigateTo);
    }
  };
}
