import moment from 'moment-timezone';
import { NetworkStatus } from '../../app/ApiGen';

export const getChargePointConnectivityStatus = (
  networkStatus: NetworkStatus | string,
  networkStatusUpdatedDate: string,
): string => {
  switch (networkStatus) {
    case 'ONLINE': {
      return 'Connected';
    }
    case 'OFFLINE': {
      return `Last connected ${moment(networkStatusUpdatedDate).fromNow()}`;
    }
    case 'NEVER_CONNECTED': {
      return 'Never seen';
    }
    default:
      return '';
  }
};
