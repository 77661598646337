import * as React from 'react';
import { Card, List } from '../../../../../../../design-system';
import { useChargePoint } from '../../Provider';
import { CommandItem } from '../../Settings/components/CommandItem';
import { DialogContents } from '../../Settings/types/commands';
import { UpdateFirmwareDialog } from './commandDialogs/UpdateFirmwareDialog';

enum Dialog {
  ChangeAvailability,
  UpdateFirmware,
  Reset,
}

const commands: DialogContents<Dialog>[] = [
  {
    id: Dialog.UpdateFirmware,
    label: 'Update firmware',
    description: "Update the charge point's firmware",
    DialogComponent: UpdateFirmwareDialog,
  },
];

export const ChargePointCommands: React.FC = () => {
  const [opened, setOpened] = React.useState<Dialog | undefined>(undefined);

  const { loading } = useChargePoint();

  return (
    <Card title="Commands">
      <List>
        {commands.map(({ id, label, description, DialogComponent }) => (
          <CommandItem
            key={id}
            label={label}
            description={description}
            isDisabled={loading}
            onClick={() => setOpened(id)}
            dialog={<DialogComponent isOpen={opened === id} onClose={() => setOpened(undefined)} />}
          />
        ))}
      </List>
    </Card>
  );
};
