import * as React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ForgotPasswordView from './ForgotPassword';
import SignInView from './SignIn';
import VerifyView from './Verify';

const AuthView: React.FC = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/signin`}>
        <SignInView />
      </Route>
      <Route path={`${path}/verify`}>
        <VerifyView />
      </Route>
      <Route path={`${path}/forgot-password`}>
        <ForgotPasswordView />
      </Route>
      <Route>
        <Redirect to={`${url}/signin`} />
      </Route>
    </Switch>
  );
};

export default AuthView;
