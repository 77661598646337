import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { InputComponentProps, ValueComponentProps } from '../../../design-system';
import type { Address as AddressType } from '../../../utils/api';
import { Address } from '../../Address';
import { SearchWrapper } from '../../SearchWrapper';
import { AddressDialogInput } from './AddressDialogInput';
import { AddressEditorSearchInput } from './AddressEditorSearchInput';

export interface AddressInputProps extends InputComponentProps<AddressType> {
  coordinatesFieldId: string;
  timeZoneFieldId?: string;
  icpFieldId?: string;
}

export const AddressInput: React.VFC<AddressInputProps> = ({
  coordinatesFieldId,
  disabled,
  error,
  helperText,
  icpFieldId,
  name,
  onBlur,
  onChange,
  timeZoneFieldId,
  value,
}: AddressInputProps) => (
  <SearchWrapper>
    <AddressEditorSearchInput
      addressFieldId={name}
      coordinatesFieldId={coordinatesFieldId}
      disabled={disabled}
      icpFieldId={icpFieldId}
      onChange={onChange}
      timeZoneFieldId={timeZoneFieldId}
    />
    <Box mb={1} />
    <AddressDialogInput
      disabled={disabled}
      error={error}
      value={value}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      helperText={helperText}
    />
  </SearchWrapper>
);

export const AddressValue: React.FC<ValueComponentProps<AddressType>> = ({
  value,
}: ValueComponentProps<AddressType>) => (
  <Typography component="div" style={{ fontSize: 'inherit' }}>
    <Address multiline address={value} />
  </Typography>
);
