import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Form as FormikForm, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import { SwitchInput } from '../../../../../../components/CustomFields/Switch';
import { Dialog } from '../../../../../../components/Dialog';
import {
  ChargePointSerialGraphic,
  FormEditable,
  FormField,
  OnSubmit,
  Section,
  useWizard,
} from '../../../../../../design-system';
import { createSchema } from '../../../../../../utils/formUtils';

const DialogContext = React.createContext({ toggle: () => {} });

const detailFields: FormField[] = [
  {
    id: 'name',
    label: 'Name',
    inputProps: {
      helperText: 'A recognisable name for this charge point, e.g. "Garage charger" or "Parking spot #1"',
    },
  },
  {
    id: 'serial',
    label: 'Serial',
    inputProps: {
      helperText: (
        <DialogContext.Consumer>
          {({ toggle }) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid -- Component has been set to "button".
            <Link component="button" variant="body2" onClick={toggle}>
              Where do I find my charge point serial?
            </Link>
          )}
        </DialogContext.Consumer>
      ),
    },
  },
  {
    id: 'referenceId',
    label: 'Reference ID',
    inputProps: {
      helperText: '(Optional) A custom identifier for this charge point',
    },
  },
];

const configurationFields: FormField[] = [
  {
    id: 'authorizationRequired',
    label: 'Authorization required',
    inputComponent: SwitchInput,
    inputProps: {
      helperText: 'Whether a valid RFID card must be swiped before a charging session can begin',
    },
  },
];

const initialValues = {
  name: '',
  serial: '',
  referenceId: '',
  authorizationRequired: false,
};

type DetailsEvnex = {
  name: string;
  serial: string;
  referenceId?: string;
  authorizationRequired: boolean;
};

const validationSchema = createSchema<DetailsEvnex>({
  name: Yup.string()
    .required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(80, 'Name must be at most 80 characters'),
  serial: Yup.string()
    .required('Serial is required')
    // eslint-disable-next-line no-template-curly-in-string --- Yup messages can be customised using template curlies in strings.
    .max(80, 'Serial must be at most ${max} characters')
    .matches(/^[a-zA-Z0-9-_]*$/, 'Serial may only include numbers, letters, "-" and "_" characters'),
  referenceId: Yup.string()
    .min(1, 'Reference ID must be at least 1 character')
    .max(80, 'Reference ID must be at most 80 characters')
    .matches(/^[a-zA-Z0-9-_. ]*$/, 'Reference ID may only include numbers, letters, "-", "_", "." and " " characters'),
  authorizationRequired: Yup.boolean().required('Please indicate whether authorisation is required to start charging'),
});

export const DetailsEvnexStep: React.FC = () => {
  const { state, setState, prevStep, nextStep } = useWizard();

  const onSubmit = React.useCallback<OnSubmit<DetailsEvnex>>(
    ({ name, serial, referenceId, authorizationRequired }) => {
      setState({ name, serial, referenceId, authorizationRequired });
      nextStep();
    },
    [setState, nextStep],
  );

  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = React.useCallback(() => setIsOpen((prev) => !prev), []);

  return (
    <DialogContext.Provider value={{ toggle }}>
      <Formik<DetailsEvnex>
        initialValues={{ ...initialValues, ...state }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <FormikForm>
            <Section title="Charge point details">
              <FormEditable fields={detailFields} errors={errors} touched={touched} disabled={isSubmitting} />
            </Section>
            <Section pt={3} title="Configuration" description="Settings and configuration for your charge point.">
              <FormEditable fields={configurationFields} errors={errors} touched={touched} disabled={isSubmitting} />
            </Section>
            <Box pt={2} display="flex">
              <Button variant="contained" onClick={prevStep}>
                Back
              </Button>
              <Box mr="auto" />
              <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Next'}
              </Button>
            </Box>
          </FormikForm>
        )}
      </Formik>

      {/* Where do I find my charge point serial dialog */}
      <Dialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Where do I find my charge point serial?"
        maxWidth="xs"
      >
        <Box px={2}>
          <ChargePointSerialGraphic
            mb={4}
            textAlign="center"
            svgProps={{ style: { maxWidth: '60%', height: '100%' } }}
          />
          <Typography variant="body2" paragraph>
            A charge point serial is a unique alphanumeric code that identifies your charge point.
          </Typography>
          <Typography variant="body2" paragraph>
            You can find it on your Evnex charge point&apos;s label, in the bottom left corner.
          </Typography>
        </Box>
      </Dialog>
    </DialogContext.Provider>
  );
};
