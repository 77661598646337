import { action, Action, PayloadAction } from 'typesafe-actions';
import type { LegacyUser as User } from '../../app/ApiGen';
import { AuthenticatedUserAction } from './types';

export function getAuthenticatedUserAction(): Action<AuthenticatedUserAction.GET_AUTHENTICATED_USER> {
  return action(AuthenticatedUserAction.GET_AUTHENTICATED_USER);
}

export function setAuthenticatedUserAction(
  user: User,
): PayloadAction<AuthenticatedUserAction.SET_AUTHENTICATED_USER, { user: User }> {
  return action(AuthenticatedUserAction.SET_AUTHENTICATED_USER, { user });
}

export function setAuthenticatedUserErrorAction(
  error: Error,
): PayloadAction<AuthenticatedUserAction.SET_AUTHENTICATED_USER_ERROR, { error: Error }> {
  return action(AuthenticatedUserAction.SET_AUTHENTICATED_USER_ERROR, { error });
}

export function clearAuthenticatedUserAction(): Action<AuthenticatedUserAction.CLEAR_AUTHENTICATED_USER> {
  return action(AuthenticatedUserAction.CLEAR_AUTHENTICATED_USER);
}

export function clearAuthenticatedUserDoneAction(): Action<AuthenticatedUserAction.CLEAR_AUTHENTICATED_USER_DONE> {
  return action(AuthenticatedUserAction.CLEAR_AUTHENTICATED_USER_DONE);
}

export function setNeedsMfaAction(): Action<AuthenticatedUserAction.SET_AUTHENTICATED_USER_NEEDS_MFA> {
  return action(AuthenticatedUserAction.SET_AUTHENTICATED_USER_NEEDS_MFA);
}

export function clearNeedsMfaAction(): Action<AuthenticatedUserAction.CLEAR_AUTHENTICATED_USER_NEEDS_MFA> {
  return action(AuthenticatedUserAction.CLEAR_AUTHENTICATED_USER_NEEDS_MFA);
}
