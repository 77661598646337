import { Chart } from 'chart.js';
import React from 'react';
import { DataErrorHandler } from '../../../../../../../components/ErrorHandler';
import { Loading, Section, Workspace } from '../../../../../../../design-system';
import { ChargePoint, useGetChargePointLogs } from '../../../../../../../utils/api';
import { LLMServerStatusLog } from '../../../../../../../utils/chargePoints/diagnostics';
import { useEndDateState, useSetDateRange, useStartDateState } from '../../../../../../../utils/dateRange';
import { ChargePointLogsTable } from '../../Logs/components/ChargePointLogsTable';
import { DataTransferLog } from '../utils/configuration';
import { LLMChartModel } from '../utils/LLMChartModel';
import { timestampOfActiveElement } from '../utils/presentation';
import { GroupTable } from './GroupTable';
import { LimitCalculationTable } from './LimitCalculationTable';
import { LoadChart } from './LoadChart';

type LogsProps = {
  chargePoint: ChargePoint;
};

function getLogAtTime(logs: DataTransferLog[], timestamp: number | undefined): LLMServerStatusLog | undefined {
  if (typeof timestamp === 'undefined') {
    return undefined;
  }
  return LLMChartModel.fromLogs(logs).logs.find((log) => new Date(log.serverEventDate).getTime() === timestamp);
}

export const Logs: React.VFC<LogsProps> = ({ chargePoint }: LogsProps) => {
  const [fromDate, setFromDate] = useStartDateState();
  const [toDate, setToDate] = useEndDateState();
  const [selectedTimestamp, setSelectedTimestamp] = React.useState<number | undefined>(undefined);
  const [selectedLog, setSelectedLog] = React.useState<LLMServerStatusLog | undefined>(undefined);
  const [dataTransferLogs, setDataTransferLogs] = React.useState<DataTransferLog[]>([]);

  const setDateRange = useSetDateRange(setFromDate, setToDate);

  const { loading, error, data, refetch } = useGetChargePointLogs({
    chargePointId: chargePoint.id,
    queryParams: { from: fromDate, to: toDate },
  });

  React.useEffect(() => {
    const logs = data ? (data.data.items.filter((l) => l.event === 'DataTransfer') as DataTransferLog[]) : [];
    setDataTransferLogs(logs);
  }, [data]);

  const onClickHandler = React.useCallback(
    (chart: Chart) => {
      const timestamp = timestampOfActiveElement(chart.getActiveElements(), chart.data.datasets);
      setSelectedTimestamp(timestamp);
    },
    [setSelectedTimestamp],
  );

  React.useEffect(() => {
    if (typeof selectedTimestamp === 'undefined') {
      setSelectedLog(undefined);
    } else {
      const log = getLogAtTime(dataTransferLogs, selectedTimestamp);
      setSelectedLog(log);
    }
  }, [selectedTimestamp, setSelectedLog, dataTransferLogs]);

  if (error) {
    return <DataErrorHandler description="Unable to load charge point logs" refetch={refetch} error={error} />;
  }

  return (
    <Section>
      <ChargePointLogsTable
        tableProps={{
          loading,
          refetch,
          chargePointLogs: dataTransferLogs,
          title: 'Charge point DataTransfer logs',
          description:
            'View DataTransfer messages sent and received by the system from the charge point. ' +
            'Note that if you select a very wide range, the request is more likely to time out.',
        }}
        dateProps={{ dateRange: { fromDate, toDate }, setDateRange }}
      />
      {loading && (
        <Workspace>
          <Loading />
        </Workspace>
      )}
      {!loading && (
        <>
          <LoadChart logs={dataTransferLogs} onClickHandler={onClickHandler} />
          <GroupTable log={selectedLog} />
          <LimitCalculationTable chargePoint={chargePoint} log={selectedLog} />
        </>
      )}
    </Section>
  );
};
