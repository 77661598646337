import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import styled from 'styled-components';
import cover from '../../../../assets/cover.jpg';
import { Logo } from '../../../../design-system';
import { Info } from './Info';

const StyledDesktop = styled('div')`
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: nowrap row;
`;

const Image = styled('div')`
  height: 100vh;
  width: 50vw;
  background: url(${cover});
  background-size: cover;
  background-position: center;
`;

const Panel = styled('div')`
  height: 100vh;
  width: 50vw;
  position: relative;
  display: flex;
  flex-flow: nowrap column;
  align-items: center;
  justify-content: center;
`;

const Inner = styled('div')`
  width: 320px;
  display: flex;
  flex-flow: nowrap column;
  align-items: center;
  justify-content: center;
`;

const Branding = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(6)}px;
`;

const Title = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
  text-align: center;
`;

const FormWrapper = styled('div')`
  width: 100%;
`;

export interface DesktopProps {
  title?: React.ReactNode;
}

export const Desktop: React.FC<DesktopProps> = ({ children, title }) => (
  <StyledDesktop>
    <Panel>
      <Inner>
        <Branding>
          <Logo variant="dark" height={40} width={240} />
        </Branding>
        {title && <Title variant="h4">{title}</Title>}
        <FormWrapper>{children}</FormWrapper>
      </Inner>
      <Info style={{ position: 'absolute', bottom: 0 }} />
    </Panel>
    <Image />
  </StyledDesktop>
);
