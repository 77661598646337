import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { JobView } from './Job';
import { mainNav } from '../../navView';
import { JobsList } from './JobsList';
import { useNavigationView } from '../../../design-system';

export const JobsView: React.FC = () => {
  const { path } = useRouteMatch();
  useNavigationView(mainNav);

  return (
    <Switch>
      <Route path={`${path}/:jobId`}>
        <JobView />
      </Route>

      <Route>
        <JobsList />
      </Route>
    </Switch>
  );
};
