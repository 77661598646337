import React from 'react';
import * as Yup from 'yup';
import { WizardStep } from '../../../../components/WizardStep';
import { OnSubmit, useWizard } from '../../../../design-system';
import { createSchema } from '../../../../utils/formUtils';
import { CommandsWizardState, CommandType, DataTransferStepFields } from '../types';

const validationSchema = createSchema<DataTransferStepFields>({
  messageId: Yup.string().required('Message is required'),
});

export const DataTransferStep: React.FC = () => {
  const { state: untypedState, setState: untypedSetState, prevStep, nextStep, step } = useWizard();
  const state = untypedState as Partial<CommandsWizardState>;
  const setState = untypedSetState as (state: Partial<CommandsWizardState>) => void;

  const onCancel = React.useCallback<OnSubmit<DataTransferStepFields>>(
    ({ messageId }) => {
      setState({ command: { type: CommandType.DATA_TRANSFER, messageId } });
      prevStep();
    },
    [setState, prevStep],
  );

  const onSubmit = React.useCallback<OnSubmit<DataTransferStepFields>>(
    ({ messageId }, { setFieldError }) => {
      setFieldError('messageId', undefined);
      setState({ command: { type: CommandType.DATA_TRANSFER, messageId } });
      nextStep();
    },
    [setState, nextStep],
  );

  return (
    <WizardStep<DataTransferStepFields>
      fields={[{ id: 'messageId', label: 'Message ID' }]}
      initialValues={{ messageId: state.command?.messageId ?? '' }}
      name="Enter data"
      onCancel={onCancel}
      onSubmit={onSubmit}
      step={step}
      subtitle="Enter the data you wish to send to an Evnex charge point. Clicking 'Next' will start the data transfer process."
      validationSchema={validationSchema}
    />
  );
};
