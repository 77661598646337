import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';

export const StyledDetail = styled.div`
  display: flex;
  flex-flow: nowrap row;
  min-height: ${(p) => p.theme.spacing(5)}px;

  ${(p) => p.theme.breakpoints.down('xs')} {
    flex-flow: nowrap column;
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading -- There are too many props to reasonably avoid spreading here.
const DivTypography: React.FC<TypographyProps<'div'>> = (props) => <Typography component="div" {...props} />;

export const Label = styled(DivTypography)`
  flex: 1 1 30%;
  margin-right: ${(p) => p.theme.spacing(2)}px;
  display: flex;
  align-items: center;
  height: ${(p) => p.theme.spacing(5)}px;

  ${(p) => p.theme.breakpoints.down('xs')} {
    margin-bottom: ${(p) => p.theme.spacing(1)}px;
  }
`;

export const Value = styled(DivTypography)`
  flex: 1 1 70%;
  display: flex;
  align-items: center;
`;
