import { rgba } from 'polished';
import styled from 'styled-components';

export const StyledTable = styled('div')`
  thead {
    th {
      padding: ${(p) => p.theme.spacing(1, 2)};
      color: ${(p) => p.theme.palette.text.secondary};
      font-weight: ${(p) => p.theme.typography.fontWeightRegular};
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: ${(p) => rgba(p.theme.palette.evnex.main, 0.03)};
    }

    tr {
      &:empty {
        display: none;
      }

      &.MuiTableRow-hover {
        :hover,
        :focus {
          background-color: ${(p) => rgba(p.theme.palette.evnex.main, 0.06)};
        }
      }

      td {
        padding: ${(p) => p.theme.spacing(1, 2)};
        border-bottom: none;
      }
    }
  }

  .MuiTablePagination-root {
    border-bottom: none;
  }
`;
