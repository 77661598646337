import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import LocationView from './Location';
import LocationsListView from './LocationsList';

const LocationsView: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <LocationView />
      </Route>

      <Route>
        <LocationsListView />
      </Route>
    </Switch>
  );
};

export default LocationsView;
