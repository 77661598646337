import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form as FormikForm, Formik } from 'formik';
import React from 'react';
import Yup from 'yup';
import { FormEditable, FormField, FormReadonly, OnSubmit, Section } from '../design-system';

interface WizardStepContextProps<S extends Record<string, unknown>> {
  fields: FormField[];
  initialValues: S;
  locked?: boolean;
  name: string;
  onCancel: OnSubmit<S>;
  onSubmit: OnSubmit<S>;
  step: number;
  subtitle: string;
  validationSchema: Yup.ObjectSchema;
}

export function WizardStep<S extends Record<string, unknown>>(props: WizardStepContextProps<S>): React.ReactElement {
  const { fields, initialValues, locked, name, onCancel, onSubmit, step, subtitle, validationSchema } = props;

  return (
    <Section title={name} description={subtitle}>
      <Formik<S> initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} validateOnChange>
        {(formikProps) => {
          const { errors, isSubmitting, touched, values } = formikProps;
          return (
            <FormikForm>
              {locked ? (
                <FormReadonly fields={fields} initialValues={initialValues} />
              ) : (
                <FormEditable fields={fields} errors={errors} touched={touched} disabled={isSubmitting} />
              )}
              <Box pt={2} display="flex" flexDirection="row-reverse">
                <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Next'}
                </Button>
                <Box mr="auto" />
                <Button variant="contained" onClick={() => onCancel(values, formikProps)}>
                  {step === 0 ? 'Cancel' : 'Back'}
                </Button>
              </Box>
            </FormikForm>
          );
        }}
      </Formik>
    </Section>
  );
}
