import React from 'react';
import * as Yup from 'yup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CommandsWizardState, CommandType, ResetStepFields } from '../types';
import { WizardStep } from '../../../../components/WizardStep';
import type { ResetType } from '../../../../app/ApiGen';
import { createSchema, FieldDefinitions } from '../../../../utils/formUtils';
import { AutocompleteContents, renderAutocompleteInput } from '../../../../components/CustomFields/SimpleAutocomplete';
import { InputComponentProps, OnSubmit, useWizard } from '../../../../design-system';

const resetTypeOptions: ResetType[] = ['Soft', 'Hard'];

const fields: FieldDefinitions<ResetStepFields> = [
  {
    id: 'resetType',
    label: 'Reset type',
    inputComponent: ({
      disabled,
      error,
      helperText,
      name,
      onChange,
      value: selectedResetType,
    }: InputComponentProps<ResetType>) => (
      <Autocomplete<ResetType, false, true>
        disableClearable
        fullWidth
        getOptionLabel={(option) => option ?? ''}
        handleHomeEndKeys
        onChange={(event, command) => {
          onChange(name)({ ...event, target: { ...event.target, value: command } });
        }}
        options={resetTypeOptions}
        renderInput={renderAutocompleteInput(disabled, error, helperText)}
        value={selectedResetType}
      />
    ),
    valueComponent: ({ value }: AutocompleteContents<ResetType>) => <>{value}</>,
  },
];

const validationSchema = createSchema<ResetStepFields>({
  resetType: Yup.mixed<ResetType>().required('Reset type is required'),
});

export const ResetStep: React.FC = () => {
  const { state: untypedState, setState: untypedSetState, prevStep, nextStep, step } = useWizard();
  const state = untypedState as Partial<CommandsWizardState>;
  const setState = untypedSetState as (state: Partial<CommandsWizardState>) => void;

  const onCancel = React.useCallback<OnSubmit<ResetStepFields>>(
    (values) => {
      setState({ command: { type: CommandType.RESET, resetType: values.resetType } });
      prevStep();
    },
    [setState, prevStep],
  );

  const onSubmit = React.useCallback<OnSubmit<ResetStepFields>>(
    (values, { setFieldError }) => {
      setFieldError('resetType', undefined);
      setState({ command: { type: CommandType.RESET, resetType: values.resetType } });
      nextStep();
    },
    [setState, nextStep],
  );

  return (
    <WizardStep<ResetStepFields>
      fields={fields}
      initialValues={{ resetType: state.command?.resetType ?? 'Soft' }}
      name="Enter reset type"
      onCancel={onCancel}
      onSubmit={onSubmit}
      step={step}
      subtitle="Clicking 'Next' will start resetting the charge points."
      validationSchema={validationSchema}
    />
  );
};
