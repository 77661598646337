import { ScatterDataPoint } from 'chart.js';
import { LLMServer, PhaseKey } from '../../../../../../../utils/chargePoints/diagnostics';
import { ClientsByName } from './LLMClientModel';
import { DatasetKey } from './presentation';

export type LLMServerModelKey = Exclude<DatasetKey, 'client'>;

export type Models = {
  client: ClientsByName;
  groupLimit: LLMServerModel[];
  maxSafeLimit: LLMServerModel[];
  sharedLimit: LLMServerModel[];
  supplyCurrent: LLMServerModel[];
};

export const ServerModelKeys: LLMServerModelKey[] = ['groupLimit', 'maxSafeLimit', 'sharedLimit', 'supplyCurrent'];

interface LLMServerModelAttributes {
  server: LLMServer;
  serverEventDate: string;
}

export abstract class LLMServerModel {
  readonly server: LLMServer;

  readonly serverEventDate: string;

  constructor(params: LLMServerModelAttributes) {
    const { server, serverEventDate } = params;
    this.server = server;
    this.serverEventDate = serverEventDate;
  }

  abstract getDatum(args: { phase: PhaseKey }): ScatterDataPoint;

  static getSortedData(args: { models: LLMServerModel[]; phase: PhaseKey }): ScatterDataPoint[] {
    const { models, phase } = args;
    return models.map((model) => model.getDatum({ phase })).sort((a, b) => (a && b ? a.x - b.x : 0));
  }
}
