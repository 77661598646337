import styled from 'styled-components';

export const NavigationLayout = styled('div')`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 0px);
`;

export const NavigationWrapper = styled('div')`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-flow: nowrap row;
  z-index: 200;
`;

const getPaddingLeft = ({
  isCollapsed,
  hasProductNavigation,
  hasContainerNavigation,
}: {
  isCollapsed: boolean;
  hasProductNavigation: boolean;
  hasContainerNavigation: boolean;
}) => {
  let p = 0;

  if (hasProductNavigation) {
    if (isCollapsed) {
      p += 72;
    } else {
      p += 220;
    }
  }

  if (hasContainerNavigation) {
    p += 220;
  }

  return p;
};

export const ContentWrapper = styled('div')<{
  isCollapsed: boolean;
  hasProductNavigation: boolean;
  hasContainerNavigation: boolean;
}>`
  margin-top: 0;
  padding-left: ${getPaddingLeft}px;
  width: 0px;
  flex: 1 1 auto;
  transition: padding-left ${(p) => p.theme.transitions.easing.easeInOut}
    ${(p) => p.theme.transitions.duration.standard}ms;
  will-change: padding-left;
`;
