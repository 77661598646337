import EvStationIcon from '@material-ui/icons/EvStation';
import React from 'react';
import { useSelector } from 'react-redux';
import { UserOrganisation } from '../../../../../../app/ApiGen';
import { ChargePointAvatar } from '../../../../../../components/Avatar/ChargePoint';
import { ContainerHeader, HeaderSection, Item, MenuSection, Separator } from '../../../../../../design-system';
import { fullStateSelector } from '../../../../../../store/root';
import { ChargePoint } from '../../../../../../utils/api';
import { getChargePointImage } from '../../../../../../utils/chargePoints/getChargePointImage';
import { menuItemsFactory } from './menuItems';

type MenuProps = {
  chargePoint: ChargePoint;
  organisation: UserOrganisation;
  menuItemsFactory: typeof menuItemsFactory;
};

export const Menu: React.FC<MenuProps> = ({
  chargePoint,
  organisation,
  menuItemsFactory: menuItems = menuItemsFactory,
}) => {
  const { isAdmin, isEvnexAdmin } = useSelector(fullStateSelector);
  const { vendor, model } = chargePoint.details;

  return (
    <>
      <HeaderSection>
        {() => (
          <ContainerHeader
            avatar={
              <ChargePointAvatar src={vendor && model ? getChargePointImage(vendor, model) : undefined}>
                <EvStationIcon />
              </ChargePointAvatar>
            }
            text={chargePoint.name}
            subText="Charge point"
          />
        )}
      </HeaderSection>
      <Separator mb={1} />
      <MenuSection canOverflow>
        {() => (
          <>
            {menuItems({
              organisation,
              chargePoint,
              isEvnexAdmin,
              isAdmin,
            }).map((menuItem) => (menuItem.shouldRender ? <Item text={menuItem.text} to={menuItem.path} /> : <></>))}
          </>
        )}
      </MenuSection>
    </>
  );
};
