/**
 * Designed to work with enum-like types below, where values as well as keys are unique.
 *
 * Modified from a StackOverflow suggestion: specifically only works on number-to-string records, avoiding `any`
 * @see https://stackoverflow.com/a/60768453
 */
type ReverseMap<R extends Record<number, string>> = R[keyof R] extends string
  ? {
      [L in R[keyof R]]: {
        [N in keyof R]: R[N] extends L ? N : never;
      }[keyof R];
    }
  : never;

function asEnum<R extends Record<number, string>>(labels: R): ReverseMap<R> {
  const reversed = Object.entries(labels).map(([value, label]) => [label, parseInt(value, 10)]);
  return Object.fromEntries(reversed) as ReverseMap<R>;
}

export function labelsFor<N extends number, L extends string>(values: Record<L, N>): Record<N, string> {
  const reversed = Object.entries(values).map(([k, v]) => [v, k]);
  return Object.fromEntries(reversed) as Record<N, string>;
}

/**
 * Needed because RestfulReact loses the labels for numeric enums (JobStatus).
 */
const jobStatusLabels = {
  0: 'Pending',
  1: 'Complete',
  2: 'Cancelled',
  3: 'Commissioning',
  4: 'Error',
  5: 'ManuallyCommissioned',
} as const;
export const JobStatus = asEnum(jobStatusLabels);

/**
 * Needed because RestfulReact loses the labels for numeric enums (JobType).
 */
const jobTypeLabels = {
  0: 'ResidentialInstall',
} as const;
export const JobType = asEnum(jobTypeLabels);

/**
 * Needed because RestfulReact loses the labels for numeric enums (Tier).
 */
const tierLabels = {
  1: 'Free',
  2: 'Business',
  4: 'Enterprise',
  5: 'Flexibility',
} as const;
export const OrganisationTier = asEnum(tierLabels);

/**
 * Needed because RestfulReact loses the labels for numeric enums (Role).
 */
const roleLabels = {
  0: 'Admin',
  1: 'FleetOperator',
  2: 'Driver',
} as const;
export const UserRole = asEnum(roleLabels);

/**
 * Needed because RestfulReact loses the labels for numeric enums (CommissionAttemptResult).
 */
const attemptResultStatus = {
  0: 'Success',
  1: 'ChargePointUnresponsive',
  2: 'AccountNotActive',
  3: 'ChargePointAlreadyClaimed',
  4: 'FailedForUnknownReason',
  5: 'JobHasIncorrectParameters',
} as const;
export const CommissionAttemptResult = asEnum(attemptResultStatus);
