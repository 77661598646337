import Button from '@material-ui/core/Button';
import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Token, useGetOrganisationTokens } from '../../../../../app/ApiGen';
import { SwitchValue } from '../../../../../components/CustomFields/Switch';
import { getToolbarSearch, RefreshButton } from '../../../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { Card, ColumnChanger, ColumnChangerProps, DataTable, Loading, Workspace } from '../../../../../design-system';
import { navigateOnRowClick } from '../../../../../utils/dataTable/rowHelpers';
import { includesSearch } from '../../../../../utils/search';
import { sortByLocale } from '../../../../../utils/sortBy';

const ENABLED_TEXT = 'Enabled';
const DISABLED_TEXT = 'Disabled';

const CardsListView: React.FC = () => {
  const { url, params } = useRouteMatch<{ slug: string }>();
  const history = useHistory();

  const { loading, error, data, refetch } = useGetOrganisationTokens({ orgSlug: params.slug });

  if (loading && !data) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (error || !data) {
    return <DataErrorHandler description="Unable to load cards" error={error} refetch={refetch} />;
  }

  return (
    <Workspace maxWidth="xl">
      <Card>
        <DataTable<Token>
          isLoading={loading}
          options={{ columnsButton: true }}
          toolbarProps={{
            ...getToolbarSearch('cards'),
            actions: (props: ColumnChangerProps) => (
              <>
                <ColumnChanger
                  columnsButton={props.columnsButton}
                  columns={props.columns}
                  icons={props.icons}
                  onColumnsChanged={props.onColumnsChanged}
                />
                <RefreshButton refetch={refetch} />
                <Button
                  component={Link}
                  to={`/organisations/${params.slug}/create/card`}
                  color="primary"
                  variant="contained"
                >
                  Add card
                </Button>
              </>
            ),
          }}
          columns={[
            {
              title: 'Name',
              field: 'name',
              defaultSort: 'asc',
              customSort: sortByLocale('name'),
            },
            {
              title: 'Status',
              field: 'isValid',
              render: ({ isValid }: Token) => (
                <SwitchValue value={isValid} labels={{ true: ENABLED_TEXT, false: DISABLED_TEXT }} />
              ),
              customFilterAndSearch: (searchValue: string, { isValid }: Token) => {
                const keyword = isValid ? ENABLED_TEXT : DISABLED_TEXT;
                return includesSearch(keyword, searchValue);
              },
            },
            {
              title: 'Type',
              field: 'tokenType',
            },
            {
              title: 'UID',
              field: 'uid',
            },
          ]}
          data={data.data.items}
          onRowClick={navigateOnRowClick((row) => `${url}/${row.id}`, history)}
        />
      </Card>
    </Workspace>
  );
};

export default CardsListView;
