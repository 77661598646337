import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import { Cluster } from '@react-google-maps/marker-clusterer';
import { rgba } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import emptyImg from '../../../../../../../assets/map/empty.png';
import locationImg from '../../../../../../../assets/map/location.png';
import locationSelectedImg from '../../../../../../../assets/map/locationSelected.png';
import { getAddressAsString } from '../../../../../../../utils/locations/address';
import { GoogleMapLoader, googleMapStyles } from '../../../../../../../components/GoogleMap';
import { useLocationMap } from '../Context';
import type { LocationInfoPos } from '../types';
import { device } from '../../../../../../../utils/screenUtils';
import { Normalised } from '../../../../../../../utils/request';
import { Location } from '../../../../../../../app/ApiGen';

export const MapWrapper = styled('div')`
  width: 100%;
  position: relative;

  .evnex-map-cluster {
    box-sizing: content-box;
    background-color: ${(p) => p.theme.palette.primary.main};
    border: 2px solid ${(p) => rgba(p.theme.palette.evnex.main, 0.13)};
    border-radius: 50%;

    &:empty {
      border: none;
    }

    > * {
      color: ${(p) => p.theme.palette.common.white} !important;
      font-family: inherit !important;
      font-size: 12px !important;
      font-weight: 500 !important;
    }
  }

  height: ${(p) => p.theme.spacing(30)}px;

  @media ${device.laptop} {
    height: ${(p) => p.theme.spacing(35)}px;
  }

  @media ${device.desktop} {
    height: ${(p) => p.theme.spacing(50)}px;
  }
`;

interface CenterState {
  lat: number;
  lng: number;
}

export interface LocationMapProps {
  locations: Normalised<Location>[];
}

export const LocationMap: React.FC<LocationMapProps> = ({ locations }) => {
  const { mapRef, setMapRef, setClusterLocations, setSingleLocation, singleLocation } = useLocationMap();

  const [center, setCenter] = React.useState<CenterState | undefined>({ lat: 0, lng: 0 });

  const locationItems = React.useMemo<LocationInfoPos[]>(
    () =>
      locations.map(({ coordinates, ...rest }) => ({
        coordinates,
        pos: { lat: Number(coordinates.latitude), lng: Number(coordinates.longitude) },
        ...rest,
      })),
    [locations],
  );

  const onClusterClicked = React.useCallback(
    (cluster: Cluster) => {
      const clusterLocations = cluster.getMarkers().map((marker) => marker.get('evnexLocationData') as LocationInfoPos);

      setClusterLocations(clusterLocations);
    },
    [setClusterLocations],
  );

  const onMarkerClicked = React.useCallback(
    (event: google.maps.MouseEvent, location: LocationInfoPos) => {
      setSingleLocation(location);

      if (mapRef) {
        mapRef.panTo(location.pos);
      }
    },
    [mapRef, setSingleLocation],
  );

  React.useEffect(() => {
    const geo = navigator.geolocation;

    if (!geo) {
      console.error('Geolocation is not supported!');
      return;
    }

    navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      setCenter({ lat: Number(latitude), lng: Number(longitude) });
    });
  }, []);

  return (
    <MapWrapper>
      <GoogleMapLoader>
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          center={center}
          zoom={4}
          onLoad={setMapRef}
          options={{
            streetViewControl: false,
            styles: googleMapStyles,
          }}
        >
          <MarkerClusterer
            averageCenter
            clusterClass="evnex-map-cluster"
            onClick={onClusterClicked}
            styles={[{ url: emptyImg, height: 20, width: 20 }]}
          >
            {(clusterer) =>
              locationItems.map((location, idx) => (
                <Marker
                  // react-uid creates a new key every re-render which makes the icons flash when clicked.
                  // eslint-disable-next-line react/no-array-index-key -- Can't use react-uid here.
                  key={`marker___${idx}`}
                  icon={singleLocation?.id === location.id ? locationSelectedImg : locationImg}
                  position={location.pos}
                  clusterer={clusterer}
                  onClick={(e) => onMarkerClicked(e, location)}
                  onLoad={(m) => {
                    m.set('evnexLocationData', location);
                  }}
                  title={`${location.name}\n${getAddressAsString(location.address)}`}
                />
              ))
            }
          </MarkerClusterer>
        </GoogleMap>
      </GoogleMapLoader>
    </MapWrapper>
  );
};
