import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MuiDialog, { DialogProps as MuiDialogProps } from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import styled from 'styled-components';
import { Card } from '../../design-system';

const TitleWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const DialogContent = styled(Box)`
  padding: 0 1.5rem;
`;

const DialogActions = styled(Box)`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export type DialogProps = React.PropsWithChildren<{
  hasCancelButton?: boolean;
  hasCloseButton?: boolean;
  isOpen: boolean;
  maxWidth?: MuiDialogProps['maxWidth'];
  onClose: () => void;
  submitButton?: React.ReactNode;
  title?: string;
}>;

export const Dialog: React.FC<DialogProps> = ({
  children,
  hasCancelButton,
  hasCloseButton = true,
  isOpen,
  maxWidth,
  onClose,
  submitButton,
  title,
}: DialogProps) => (
  <MuiDialog onClose={onClose} open={isOpen} fullWidth maxWidth={maxWidth}>
    <Card
      title={
        hasCloseButton ? (
          <TitleWrapper>
            {title}
            <Box ml="auto" />
            <IconButton onClick={onClose} size="small" aria-label="dialog close button">
              <CloseIcon />
            </IconButton>
          </TitleWrapper>
        ) : (
          title
        )
      }
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {submitButton}
        {hasCancelButton && (
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
        )}
      </DialogActions>
    </Card>
  </MuiDialog>
);
