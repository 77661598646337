import { useSnackbar } from 'notistack';
import { parse } from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../app/AuthProvider';
import { errorSnackbar } from '../../../components/ErrorSnackbar';
import { Loading } from '../../../design-system';
import { Layout } from '../components/Layout';
import { CodeSent } from '../Verify/components/CodeSent';
import { EnterNewPasswordForm, EnterNewPasswordFormProps } from './components/EnterNewPasswordForm';
import { ForgotPasswordForm, ForgotPasswordFormProps } from './components/ForgotPasswordForm';
import { PasswordChanged } from './components/PasswordChanged';

export enum ForgotPasswordState {
  LOADING,
  REQUEST_NEW_CODE,
  CODE_SENT,
  ENTER_NEW_PASSWORD,
  PASSWORD_CHANGED,
}

const ForgotPasswordView: React.FC = () => {
  const [forgotPasswordState, setForgotPasswordState] = React.useState(ForgotPasswordState.LOADING);
  const [sentToEmail, setSentToEmail] = React.useState<string | undefined>(undefined);

  const { error, forgotPassword, forgotPasswordSubmit } = useAuth();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const fromSearch = parse(search);

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to send reset password request', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  React.useEffect(() => {
    if (
      fromSearch.email &&
      typeof fromSearch.email === 'string' &&
      fromSearch.code &&
      typeof fromSearch.code === 'string'
    ) {
      setForgotPasswordState(ForgotPasswordState.ENTER_NEW_PASSWORD);
      return;
    }

    setForgotPasswordState(ForgotPasswordState.REQUEST_NEW_CODE);
  }, [fromSearch.email, fromSearch.code]);

  const onForgotPassword = React.useCallback<ForgotPasswordFormProps['onSubmit']>(
    async ({ email }, { setSubmitting }) => {
      try {
        const sentTo = await forgotPassword(email);
        setSentToEmail(sentTo);
        setSubmitting(false);
        setForgotPasswordState(ForgotPasswordState.CODE_SENT);
      } catch (e) {
        setSubmitting(false);
      }
    },
    [forgotPassword],
  );

  const onForgotPasswordSubmit = React.useCallback<EnterNewPasswordFormProps['onSubmit']>(
    async ({ newPassword }, { setSubmitting }) => {
      try {
        await forgotPasswordSubmit(fromSearch.email as string, fromSearch.code as string, newPassword);
        setSubmitting(false);
        setForgotPasswordState(ForgotPasswordState.PASSWORD_CHANGED);
      } catch (e) {
        setSubmitting(false);
      }
    },
    [forgotPasswordSubmit, fromSearch.email, fromSearch.code],
  );

  switch (forgotPasswordState) {
    case ForgotPasswordState.LOADING:
      return (
        <Layout>
          <Loading />
        </Layout>
      );
    case ForgotPasswordState.CODE_SENT:
      return (
        <Layout>
          <CodeSent sentToEmail={sentToEmail} />
        </Layout>
      );
    case ForgotPasswordState.ENTER_NEW_PASSWORD:
      return (
        <Layout>
          <EnterNewPasswordForm onSubmit={onForgotPasswordSubmit} />
        </Layout>
      );
    case ForgotPasswordState.PASSWORD_CHANGED:
      return (
        <Layout>
          <PasswordChanged />
        </Layout>
      );
    default:
      return (
        <Layout>
          <ForgotPasswordForm onSubmit={onForgotPassword} />
        </Layout>
      );
  }
};

export default ForgotPasswordView;
