import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useNavigationView } from '../../../design-system';
import { mainNav } from '../../navView';
import { LocationsList } from './LocationsList';

export const LocationsView: React.FC = () => {
  useNavigationView(mainNav);

  return (
    <Switch>
      <Route>
        <LocationsList />
      </Route>
    </Switch>
  );
};
