import * as React from 'react';
import useMedia from 'use-media';
import { useTheme } from 'styled-components';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';

export interface LayoutProps {
  title?: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ ...rest }) => {
  const { breakpoints } = useTheme();
  const isMobile = useMedia({ maxWidth: breakpoints.values.md });

  return isMobile ? <Mobile {...rest} /> : <Desktop {...rest} />;
};
