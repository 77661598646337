import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig } from 'axios';
import type {
  ChargePoint,
  InstallationControlPoint,
  Job,
  LoadBalancingGroup,
  Location,
  Membership,
  Organisation,
  User,
  Session,
} from '../app/ApiGen';
import { apiEndpoint } from '../config';
import { standardiseError } from './error';

export async function request<T>(
  url: string,
  options: Pick<AxiosRequestConfig, 'method' | 'data'> & {
    headers?: Record<string, unknown>;
  },
): Promise<T> {
  try {
    const session = await Auth.currentSession();
    const { method, headers, ...other } = options;

    const { data } = await axios.request<T>({
      method,
      url: `${apiEndpoint}${url}`,
      headers: {
        Authorization: session.getAccessToken().getJwtToken(),
        'Content-Type': 'application/json',
        ...headers,
      },
      ...other,
    });
    return data;
  } catch (e) {
    throw standardiseError(e);
  }
}

type DataType =
  | 'attachments'
  | 'chargePoints'
  | 'installationControlPoints'
  | 'jobs'
  | 'loadBalancingGroups'
  | 'locations'
  | 'memberships'
  | 'organisations'
  | 'sessions'
  | 'tokens'
  | 'users';
type ResourceIdentifier<D extends DataType> = { id: string; type: D };
type Resource<D extends DataType> = D extends 'chargePoints'
  ? ChargePoint
  : D extends 'installationControlPoints'
  ? InstallationControlPoint
  : D extends 'jobs'
  ? Job
  : D extends 'loadBalancingGroups'
  ? LoadBalancingGroup
  : D extends 'locations'
  ? Location
  : D extends 'memberships'
  ? Membership
  : D extends 'organisations'
  ? Organisation
  : D extends 'users'
  ? User
  : D extends 'sessions'
  ? Session
  : {
      type: D;
      id: string;
      relationships: Record<string, { data: ResourceIdentifier<DataType> | ResourceIdentifier<DataType>[] | null }>;
      attributes: Record<string, unknown>;
    };
type ResourceSummary<D extends DataType> = Omit<Resource<D>, 'relationships'>;

type NormalisedRelated<R extends Resource<DataType>> = {
  [K in keyof R['relationships']]: R['relationships'][K] extends { data: ResourceIdentifier<infer I> | null }
    ? NormalisedAttributes<Resource<I>> | ResourceIdentifier<I> | null
    : R['relationships'][K] extends { data: ResourceIdentifier<infer I>[] }
    ? NormalisedAttributes<Resource<I>>[] | ResourceIdentifier<I>[]
    : never;
};

type NormalisedAttributes<R extends ResourceSummary<DataType>> = R['attributes'] & Pick<R, 'id'>;
type NormalisedWithRelationships<R extends Resource<DataType>> = NormalisedAttributes<R> & NormalisedRelated<R>;

export type Normalised<R extends ResourceSummary<DataType>> = R extends Resource<DataType>
  ? NormalisedWithRelationships<R>
  : NormalisedAttributes<R>;

export function findIncludedResource<D extends DataType>(
  included: ResourceSummary<DataType>[],
  resource: ResourceIdentifier<D>,
): Resource<D> | ResourceSummary<D> | undefined {
  const found = included.find((o) => o.type === resource.type && o.id === resource.id);
  if (found) {
    return found as Resource<D> | ResourceSummary<D>;
  }
  return undefined;
}

function normaliseRelationships<R extends Resource<DataType>>(
  relationships: NonNullable<R['relationships']>,
  included: ResourceSummary<DataType>[],
): NormalisedRelated<R> {
  const normalised = Object.entries(relationships).map(([relationshipName, relationship]) => {
    const extractAttributes = (e: Resource<DataType> | ResourceSummary<DataType>) => ({ id: e.id, ...e.attributes });

    if (Array.isArray(relationship.data)) {
      return [
        relationshipName,
        relationship.data.map((identifier) => {
          const nested = findIncludedResource(included, identifier);
          return nested ? extractAttributes(nested) : identifier;
        }),
      ];
    }
    if (relationship.data) {
      const nested = findIncludedResource(included, relationship.data);
      return [relationshipName, nested ? extractAttributes(nested) : relationship.data];
    }
    return [relationshipName, relationship.data];
  });
  return Object.fromEntries(normalised) as NormalisedRelated<R>;
}

export function normalise<R extends Resource<DataType> | ResourceSummary<DataType>>(response: {
  data: R;
  included?: ResourceSummary<DataType>[];
}): Normalised<R>;
export function normalise<R extends Resource<DataType> | ResourceSummary<DataType>>(response: {
  data: R[];
  included?: ResourceSummary<DataType>[];
}): Normalised<R>[];
export function normalise<R extends Resource<DataType> | ResourceSummary<DataType>>(response: {
  data: R | R[];
  included?: ResourceSummary<DataType>[];
}): R extends Resource<DataType>
  ? NormalisedWithRelationships<R> | NormalisedWithRelationships<R>[]
  : NormalisedAttributes<R> | NormalisedAttributes<R>[] {
  const { data, included } = response;
  const normaliseEntity = (e: R) => {
    if ('relationships' in e) {
      return {
        id: e.id,
        ...e.attributes,
        ...normaliseRelationships(e.relationships ?? {}, included ?? []),
      };
    }
    return { id: e.id, ...e.attributes };
  };
  type NormalisedType = R extends Resource<DataType>
    ? NormalisedWithRelationships<R> | NormalisedWithRelationships<R>[]
    : NormalisedAttributes<R> | NormalisedAttributes<R>[];
  if (Array.isArray(data)) {
    return data.map(normaliseEntity) as NormalisedType;
  }
  return normaliseEntity(data) as NormalisedType;
}
