import React from 'react';
import { useSelector } from 'react-redux';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { Loading, Section, Workspace } from '../../../../../../design-system';
import { fullStateSelector } from '../../../../../../store/root';
import { ChargePointLog, useGetChargePointLogs } from '../../../../../../utils/api';
import { useEndDateState, useSetDateRange, useStartDateState } from '../../../../../../utils/dateRange';
import { ChargePointLogsTable } from '../Logs/components/ChargePointLogsTable';
import { useChargePoint } from '../Provider';
import { ConnectionChart } from './components/ConnectionChart';
import { EventChart } from './components/EventChart';
import { MeteringCharts } from './components/MeteringCharts';

const ChargePointMetrics: React.VFC<{ chargePointId: string }> = ({ chargePointId }: { chargePointId: string }) => {
  const [fromDate, setFromDate] = useStartDateState();
  const [toDate, setToDate] = useEndDateState();
  const setDateRange = useSetDateRange(setFromDate, setToDate);

  const { isEvnexAdmin } = useSelector(fullStateSelector);
  const queryParams = isEvnexAdmin ? { queryParams: { from: fromDate, to: toDate } } : {};

  const { loading, error, data, refetch } = useGetChargePointLogs({
    chargePointId,
    ...queryParams,
  });

  if (loading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (error || !data) {
    return <DataErrorHandler description="Unable to load charge point logs" error={error} refetch={refetch} />;
  }

  const chargePointLogs: ChargePointLog[] = data.data.items.sort(
    (a, b) => new Date(a.serverEventDate).getTime() - new Date(b.serverEventDate).getTime(),
  );

  const minXValue = chargePointLogs[0]?.serverEventDate ?? '';
  const maxXValue = chargePointLogs[chargePointLogs.length - 1]?.serverEventDate ?? '';

  return (
    <Workspace maxWidth={false}>
      <Section>
        <ChargePointLogsTable
          tableProps={{ loading, refetch, chargePointLogs }}
          dateProps={{ dateRange: { fromDate, toDate }, setDateRange }}
        />
      </Section>
      {chargePointLogs.length > 0 && (
        <Section>
          <Section style={{ paddingTop: 32 }} title="Connection Chart">
            <ConnectionChart data={chargePointLogs} startingDate={minXValue} endDate={maxXValue} />
          </Section>
          <Section style={{ paddingTop: 32 }} title="Electrical Characteristic Charts">
            <MeteringCharts data={chargePointLogs} startingDate={minXValue} endDate={maxXValue} />
          </Section>
          <Section style={{ paddingTop: 32 }} title="Events Chart">
            <EventChart data={chargePointLogs} startingDate={minXValue} endDate={maxXValue} />
          </Section>
        </Section>
      )}
    </Workspace>
  );
};

const MetricsView: React.VFC = () => {
  const { chargePoint, loading, error, refetch } = useChargePoint();
  if (loading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (error || !chargePoint) {
    return <DataErrorHandler description="Unable to load charge point" error={error} refetch={refetch} />;
  }

  return <ChargePointMetrics chargePointId={chargePoint.id} />;
};

export default MetricsView;
