import * as React from 'react';
import { useNavigation } from '../../Provider';
import { StyledHeaderSection } from './styled';

export interface HeaderSectionProps {
  children: (props: { isCollapsed: boolean }) => React.ReactNode;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({ children }) => {
  const { isCollapsed } = useNavigation();

  return <StyledHeaderSection>{children({ isCollapsed })}</StyledHeaderSection>;
};

HeaderSection.displayName = 'HeaderSection';

export default HeaderSection;
