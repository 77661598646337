import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteChargePoint } from '../../../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../../../app/CurrentOrganisationProvider';
import { DangerButton } from '../../../../../../../components/DangerButton';
import { errorSnackbar } from '../../../../../../../components/ErrorSnackbar';
import { Card } from '../../../../../../../design-system';
import { SubState } from '../../../../../../../store/types';
import { useChargePoint } from '../../Provider';

const DeleteChargePointDialog: React.FC<{
  chargePoint: SubState<'chargePoint'>;
  organisation: SubState<'organisation'>;
}> = ({ chargePoint, organisation }) => {
  const [open, setOpen] = React.useState(false);

  const { push } = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { loading, mutate } = useDeleteChargePoint({});

  const onDelete = React.useCallback(() => {
    mutate(chargePoint.id)
      .then(() => {
        handleClose();
        push(`/organisations/${organisation.slug}/charge-points`);
      })
      .catch((e) => {
        errorSnackbar('Unable to delete charge point', closeSnackbar, enqueueSnackbar, e);
      });
  }, [chargePoint, closeSnackbar, enqueueSnackbar, mutate, organisation, push]);

  return (
    <Card title="Delete charge point">
      <Box p={2} pt={0}>
        <Typography paragraph>This will delete the charge point.</Typography>
        <Button color="inherit" variant="contained" onClick={handleClickOpen}>
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Delete charge point'}
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete charge point {chargePoint.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone. Are you sure you want to permanently delete the charge point?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" variant="contained" disabled={loading} onClick={handleClose}>
            Cancel
          </Button>
          <DangerButton loading={loading} label="Delete charge point" onClick={onDelete} />
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export const DeleteChargePoint: React.FC = () => {
  const { organisation } = useCurrentOrganisation();
  const { chargePoint } = useChargePoint();

  if (!chargePoint || !organisation) {
    return <Card title="Delete charge point" />;
  }
  return <DeleteChargePointDialog chargePoint={chargePoint} organisation={organisation} />;
};
