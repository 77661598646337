import styled from 'styled-components';

export const StyledField = styled('label')`
  min-height: ${(p) => p.theme.spacing(4)}px;
  display: flex;
  flex-flow: nowrap row;

  ${(p) => p.theme.breakpoints.down('xs')} {
    flex-flow: nowrap column;
  }
`;

export const StyledLabel = styled('div')`
  margin-right: ${(p) => p.theme.spacing(2)}px;
  flex: 1 1 30%;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.palette.text.secondary};
  font-size: 14px;
`;

export const StyledChildren = styled('div')`
  flex: 1 1 70%;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.palette.text.primary};
  font-size: 14px;
`;
