import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useListUsers, UserSummary } from '../../../app/ApiGen';
import { getToolbarSearch } from '../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../components/ErrorHandler';
import { TableLink } from '../../../components/Link';
import { Card, ColumnChanger, ColumnChangerProps, DataTable, Workspace } from '../../../design-system';
import { navigateOnRowClick } from '../../../utils/dataTable/rowHelpers';
import { getCondensedDate } from '../../../utils/dateFormat';

type UsersData = {
  id: string;
  name?: string;
  email: string;
  updated: Date;
  created: Date;
};

function toRowData(users: UserSummary[]): UsersData[] {
  return users.map((user) => ({
    id: user.id,
    email: user.attributes.email,
    name: user.attributes.name,
    created: new Date(user.attributes.created),
    updated: new Date(user.attributes.updated),
  }));
}

const UsersListView: React.FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch<{ slug: string }>();
  const { data, error, loading, refetch } = useListUsers({});

  if (error) {
    return <DataErrorHandler description="Unable to load users" error={error} refetch={refetch} />;
  }

  const tableData: UsersData[] = data?.data ? toRowData(data.data) : [];

  return (
    <Workspace maxWidth={false}>
      <Card>
        <DataTable<UsersData>
          isLoading={loading}
          options={{ columnsButton: true }}
          toolbarProps={{
            ...getToolbarSearch('users'),
            actions: (props: React.PropsWithChildren<ColumnChangerProps>) => (
              <ColumnChanger
                columnsButton={props.columnsButton}
                columns={props.columns}
                icons={props.icons}
                onColumnsChanged={props.onColumnsChanged}
              />
            ),
          }}
          columns={[
            {
              field: 'email',
              title: 'Email',
              defaultSort: 'asc',
              render: (rowData) => <TableLink to={`${url}/${rowData.id}`}>{rowData.email}</TableLink>,
            },
            {
              field: 'name',
              title: 'Name',
            },
            {
              field: 'created',
              title: 'Member Since',
              render: ({ updated }) => getCondensedDate(updated),
            },
            {
              field: 'updated',
              title: 'Membership Updated',
              hidden: true,
              render: ({ updated }) => getCondensedDate(updated),
            },
          ]}
          data={tableData}
          onRowClick={navigateOnRowClick((rowData) => `${url}/${rowData.id}`, history)}
        />
      </Card>
    </Workspace>
  );
};
export default UsersListView;
