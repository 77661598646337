import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ChargePointInfoWithLocation, useLegacyGetOrganisationChargePoints } from '../../../../../../../app/ApiGen';
import { DataErrorHandler } from '../../../../../../../components/ErrorHandler';
import { TableLink } from '../../../../../../../components/Link';
import { Card, DataTable, Section } from '../../../../../../../design-system';
import { LLMServerStatusLog } from '../../../../../../../utils/chargePoints/diagnostics';
import { roundTo2DecimalPlaces } from '../../../../../../../utils/number';

type GroupTableProps = {
  log: LLMServerStatusLog | undefined;
};

type LocalLoadManagementGroupMemberData = {
  id: string | undefined;
  name: string;
  readyToCharge: string;
  chargeNow: string;
  maximumCurrent: number;
  wantedCurrent: number;
  allowedCurrent: number;
  measuredCurrent: number;
  phase: string;
};

function toRowData(
  log: LLMServerStatusLog,
  chargePoints: ChargePointInfoWithLocation[],
): LocalLoadManagementGroupMemberData[] {
  return log.data.ClientInfos.Clients.map((c) => {
    const chargePoint = chargePoints.find((cp) => cp.ocppChargePointId === c.Name);
    return {
      id: chargePoint?.id,
      name: c.Name,
      readyToCharge: c.CanCharge ? 'Yes' : 'No',
      chargeNow: c.ChargeNow ? 'Yes' : 'No',
      maximumCurrent: roundTo2DecimalPlaces(c.MaxCurrent),
      wantedCurrent: roundTo2DecimalPlaces(c.WantedCurrent),
      allowedCurrent: roundTo2DecimalPlaces(c.AllowedCurrent),
      measuredCurrent: roundTo2DecimalPlaces(c.MeasuredCurrent),
      phase: c.ChargingOnPhase,
    };
  });
}

export const GroupTable: React.FC<GroupTableProps> = ({ log }) => {
  const { params } = useRouteMatch<{ slug: string }>();
  const { slug: organisationSlug } = params;

  const { loading, error, data: chargePoints, refetch } = useLegacyGetOrganisationChargePoints({
    orgSlug: organisationSlug,
  });

  const tableData: LocalLoadManagementGroupMemberData[] = log ? toRowData(log, chargePoints?.data.items ?? []) : [];

  if (error) {
    return <DataErrorHandler description="Unable to load charge points" error={error} refetch={refetch} />;
  }

  return (
    <Section>
      <Card>
        <DataTable<LocalLoadManagementGroupMemberData>
          isLoading={loading}
          options={{ filtering: true, pageSize: 50 }}
          localization={{ body: { emptyDataSourceMessage: 'Click on a point in the graph to see its data.' } }}
          columns={[
            {
              title: 'OCPP ID',
              field: 'name',
              render: ({ id, name }: Pick<LocalLoadManagementGroupMemberData, 'id' | 'name'>) =>
                id ? <TableLink to={`/organisations/${organisationSlug}/charge-points/${id}`}>{name}</TableLink> : name,
            },
            { title: 'Ready', field: 'readyToCharge' },
            { title: 'Charge Now', field: 'chargeNow' },
            { title: 'Max', field: 'maximumCurrent' },
            { title: 'Wanted', field: 'wantedCurrent' },
            { title: 'Allowed', field: 'allowedCurrent' },
            { title: 'Measured', field: 'measuredCurrent' },
            { title: 'Phase', field: 'phase' },
          ]}
          data={tableData}
        />
      </Card>
    </Section>
  );
};
