import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { rgba } from 'polished';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

interface WrappedLinkProps extends LinkProps {
  isActive: boolean;
  toNewTab?: boolean;
}

const WrappedLink: React.FC<WrappedLinkProps> = React.forwardRef(({ toNewTab, isActive, ...rest }, ref) => {
  if (toNewTab) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content -- TODO
      <a
        {...rest}
        target="_blank"
        rel="noreferrer"
        href={rest.to.toString()}
        ref={ref as React.MutableRefObject<HTMLAnchorElement>}
      />
    );
  }
  return <Link {...rest} ref={ref as React.MutableRefObject<HTMLAnchorElement>} />;
});

const WrappedLinkButton: React.FC<WrappedLinkProps> = React.forwardRef(
  ({ toNewTab, isActive, children, ...rest }, ref) => {
    if (toNewTab) {
      return (
        <a
          {...rest}
          target="_blank"
          rel="noreferrer"
          href={rest.to.toString()}
          ref={ref as React.MutableRefObject<HTMLAnchorElement>}
        >
          <Button>{children}</Button>
        </a>
      );
    }

    return (
      <Link {...rest} ref={ref as React.MutableRefObject<HTMLAnchorElement>}>
        <Button>{children}</Button>
      </Link>
    );
  },
);

export const StyledLink = styled(WrappedLink)`
  margin: 0 8px 8px;
  text-decoration: none;
  color: inherit;

  & .MuiListItemIcon-root,
  & .MuiIconButton-root {
    color: inherit;
  }
  & .MuiListItem-root.Mui-selected,
  & .MuiListItem-button:hover {
    background-color: transparent;
  }
`;

export const StyledCollapsedItem = styled(WrappedLinkButton)`
  color: inherit;
  & .MuiButton-root {
    color: inherit;
    margin-left: auto;
    margin-bottom: 8px;
    padding: 0.2em 0.5em;
    height: 40px;
    min-width: unset;
    border-radius: 4px;
    background-color: ${(p) => rgba(p.theme.palette.common.white, p.isActive ? 0.17 : 0)};
    :hover {
      background-color: ${(p) => rgba(p.theme.palette.common.white, 0.13)};
    }
    svg {
      font-size: 24px;
    }
  }
`;

export const StyledListItem = styled(ListItem)`
  margin: 0;
  padding: 0.2em 0.5em;
  border-radius: 4px;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: unset;
  margin-right: 0.7em;
  svg {
    font-size: 24px;
  }
`;

export const StyledListItemText = styled(ListItemText)`
  & .MuiListItemText-primary {
    font-size: 14px;
  }
`;

export const StyledTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {children}
  </Tooltip>
))`
  & .MuiTooltip-tooltip {
    font-size: 14px;
  }
`;
