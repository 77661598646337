import Button from '@material-ui/core/Button';
import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Job, useListJobs, UserIdentifier, UserSummary } from '../../../app/ApiGen';
import { DateRangePicker } from '../../../components/DateRange';
import { DataErrorHandler } from '../../../components/ErrorHandler';
import { ExportCsv, ExportProps } from '../../../components/ExportCsv';
import { TableLink } from '../../../components/Link';
import {
  Card,
  ColumnChanger,
  ColumnChangerProps,
  DataTable,
  SearchField,
  SearchFieldProps,
  Workspace,
} from '../../../design-system';
import { ColumnDefinitions } from '../../../utils/dataTable/columns';
import { navigateOnRowClick } from '../../../utils/dataTable/rowHelpers';
import { getCondensedDate } from '../../../utils/dateFormat';
import { DateRangeProps, useEndDateState, useSetDateRange, useStartDateState } from '../../../utils/dateRange';
import { renderUserList } from '../../../utils/format';
import { jobColumns, JobListData, jobListOptions, toJobListData } from '../../../utils/jobs/table';
import { normalise, Normalised } from '../../../utils/request';
import { userListIncludesSearch } from '../../../utils/search';

type IsCertainType = 'Yes' | 'No';

type AdminJobData = JobListData & {
  assignedInstallers: (UserIdentifier | Normalised<UserSummary>)[];
  assignedInstallersLabel: string;
  data: string;
  isThirdParty: IsCertainType;
  isHardwareOnly: IsCertainType;
};

const DefaultDaysAgo = 60;

function toAdminJobData(jobs: Normalised<Job>[]): AdminJobData[] {
  return jobs.map((job) => ({
    ...toJobListData(job),
    assignedInstallers: job.assignedInstallers,
    assignedInstallersLabel: renderUserList(job.assignedInstallers, 'installer'),
    isThirdParty: job.isThirdParty ? 'Yes' : 'No',
    isHardwareOnly: job.isHardwareOnly ? 'Yes' : 'No',
    data: JSON.stringify(job, null, 2),
  }));
}

type JobListSearchFieldProps = SearchFieldProps & DateRangeProps;

const JobsList: React.FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const [fromDate, setFromDate] = useStartDateState(DefaultDaysAgo);
  const [toDate, setToDate] = useEndDateState();
  const setDateRange = useSetDateRange(setFromDate, setToDate);

  const { data, error, loading, refetch } = useListJobs({
    queryParams: { from: fromDate, to: toDate },
  });

  if (error) {
    return <DataErrorHandler description="Unable to load jobs" error={error} refetch={refetch} />;
  }

  const tableData: AdminJobData[] = data ? toAdminJobData(normalise(data)) : [];
  const columns = jobColumns as ColumnDefinitions<AdminJobData>;

  return (
    <Workspace maxWidth={false}>
      <Card>
        <DataTable<AdminJobData>
          isLoading={loading}
          options={jobListOptions}
          toolbarProps={{
            search: (props: JobListSearchFieldProps) => (
              <span style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ paddingBottom: 8 }}>
                  <DateRangePicker fromDate={fromDate} toDate={toDate} onApply={setDateRange} />
                </span>

                <SearchField
                  search={props.search}
                  searchText={props.searchText}
                  onSearchChanged={props.onSearchChanged}
                  placeholder="Search jobs..."
                />
              </span>
            ),
            actions: (props: ColumnChangerProps & ExportProps<AdminJobData>) => (
              <>
                <ColumnChanger
                  columnsButton={props.columnsButton}
                  columns={props.columns}
                  icons={props.icons}
                  onColumnsChanged={props.onColumnsChanged}
                />
                <ExportCsv<AdminJobData>
                  columns={props.columns}
                  data={props.data}
                  exportFileName={props.exportFileName}
                  getFieldValue={props.getFieldValue}
                  icons={props.icons}
                />
                <Button component={Link} to="/admin/create/job" color="primary" variant="contained">
                  Create job
                </Button>
              </>
            ),
          }}
          columns={[
            {
              field: 'updated',
              title: 'Last Updated',
              type: 'date',
              defaultSort: 'desc',
              render: ({ id, updated }) => <TableLink to={`${url}/${id}`}>{getCondensedDate(updated)}</TableLink>,
            },
            {
              field: 'created',
              title: 'Created',
              type: 'date',
              hidden: true,
              render: ({ created }) => getCondensedDate(created),
            },
            columns.referenceId,
            columns.type,
            columns.status,
            columns.organisationName,
            columns.customerName,
            columns.email,
            columns.address,
            columns.icpNumber,
            {
              field: 'assignedInstallersLabel',
              title: 'Assigned Installer(s)',
              customFilterAndSearch: (search: string, { assignedInstallers, assignedInstallersLabel }) =>
                userListIncludesSearch({ users: assignedInstallers, label: assignedInstallersLabel, search }),
            },
            columns.installerLabel,
            {
              field: 'isThirdParty',
              title: 'Third-party',
              lookup: { Yes: 'Yes', No: 'No' },
            },
            {
              field: 'isHardwareOnly',
              title: 'Hardware-only',
              lookup: { Yes: 'Yes', No: 'No' },
            },
            {
              field: 'data',
              title: 'Raw Job Data (JSON)',
              hidden: true,
              render: ({ data: rawJSON }) => <pre>{rawJSON}</pre>,
            },
          ]}
          data={tableData}
          onRowClick={navigateOnRowClick((rowData) => `${url}/${rowData.id}`, history)}
        />
      </Card>
    </Workspace>
  );
};

export default JobsList;
