import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { RouterLink } from '../../../../components/Link';
import { createSchema } from '../../../../utils/formUtils';

type ForgotPasswordSubmit = {
  newPassword: string;
  confirmNewPassword: string;
};

const initialValues: ForgotPasswordSubmit = {
  newPassword: '',
  confirmNewPassword: '',
};

export const newPasswordSchema = createSchema<ForgotPasswordSubmit>({
  newPassword: Yup.string()
    .required('New password is required')
    // eslint-disable-next-line no-template-curly-in-string --- Yup messages can be customised using template curlies in strings.
    .min(12, 'Password must contain at least ${min} characters')
    .matches(/^[\S]+.*[\S]+$/, 'Password cannot start or end with a space'),
  confirmNewPassword: Yup.string()
    .required('Both values for new password must match')
    .oneOf([Yup.ref('newPassword')], 'Both values for new password must match'),
});

export interface EnterNewPasswordFormProps {
  onSubmit: (values: typeof initialValues, helpers: FormikHelpers<typeof initialValues>) => void;
}

export const EnterNewPasswordForm: React.FC<EnterNewPasswordFormProps> = ({ onSubmit }) => (
  <Fade in>
    <Formik
      initialValues={initialValues}
      validationSchema={newPasswordSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {({ errors, isSubmitting, touched }) => (
        <Form>
          <Box textAlign="center">
            <Typography>Enter a new password for your account.</Typography>
          </Box>
          <Box mt={3}>
            <Field name="newPassword">
              {({ field }: FieldProps) => (
                <TextField
                  label="New password"
                  id="password-field"
                  variant="outlined"
                  fullWidth
                  type="password"
                  autoFocus
                  error={!!touched.newPassword && !!errors.newPassword}
                  helperText={!!touched.newPassword && errors.newPassword}
                  disabled={isSubmitting}
                  {...field}
                />
              )}
            </Field>
          </Box>
          <Box mt={1}>
            <Field name="confirmNewPassword">
              {({ field }: FieldProps) => (
                <TextField
                  label="Re-enter your new password"
                  id="confirm-password-field"
                  variant="outlined"
                  fullWidth
                  type="password"
                  error={!!touched.confirmNewPassword && !!errors.confirmNewPassword}
                  helperText={!!touched.confirmNewPassword && errors.confirmNewPassword}
                  disabled={isSubmitting}
                  {...field}
                />
              )}
            </Field>
          </Box>
          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary" size="large" fullWidth disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress size={26} color="inherit" /> : 'Change password'}
            </Button>
          </Box>
          <Box mt={2} textAlign="center">
            <RouterLink to="/auth/signin">Back to sign in screen</RouterLink>
          </Box>
        </Form>
      )}
    </Formik>
  </Fade>
);
