import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentOrganisation } from '../../../../../../../app/CurrentOrganisationProvider';
import { DangerButton } from '../../../../../../../components/DangerButton';
import { errorSnackbar } from '../../../../../../../components/ErrorSnackbar';
import { Card } from '../../../../../../../design-system';
import { useDeleteLocation } from '../../../../../../../utils/api';
import { useLocation } from '../../Provider';

export const DeleteLocation: React.FC = () => {
  const { push } = useHistory();

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const { organisation } = useCurrentOrganisation();
  const { slug: orgSlug } = organisation ?? { slug: '' };

  const { location } = useLocation();
  const { id: locationId } = location ?? { id: '' };

  const { loading, mutate } = useDeleteLocation({});

  const canDelete = location?.chargePoints.length === 0;

  const onDelete = React.useCallback(() => {
    mutate(locationId)
      .then(() => {
        enqueueSnackbar('Location deleted', { variant: 'success' });
        push(`/organisations/${orgSlug}/locations`);
      })
      .catch((error) => {
        errorSnackbar('Unable to delete location', closeSnackbar, enqueueSnackbar, error);
      });
  }, [closeSnackbar, enqueueSnackbar, locationId, mutate, orgSlug, push]);

  return (
    <Card title="Delete location">
      <Box p={2} pt={0}>
        <Typography paragraph>This will permanently delete the location and its associated data.</Typography>
        {!canDelete && (
          <>
            <Typography paragraph>
              <strong>You cannot delete a location that contains charge points.</strong>
            </Typography>
          </>
        )}
        <DangerButton disabled={!canDelete} onClick={onDelete} label="Delete location" loading={loading} />
      </Box>
    </Card>
  );
};
