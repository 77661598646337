import Box from '@material-ui/core/Box';
import { rgba } from 'polished';
import styled from 'styled-components';

const Separator = styled(Box)`
  border-bottom: 2px solid ${(p) => rgba(p.theme.palette.common.white, 0.13)};
`;

Separator.displayName = 'Separator';

export default Separator;
