import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import {
  Membership,
  MembershipIdentifier,
  useDeleteOrganisationMemberships,
  useListOrganisationMembers,
  UserSummary,
} from '../../../../app/ApiGen';
import { getToolbarSearch } from '../../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../../components/ErrorHandler';
import { ExportCsv, ExportProps } from '../../../../components/ExportCsv';
import { TableLink } from '../../../../components/Link';
import { Card, ColumnChanger, ColumnChangerProps, DataTable, Workspace } from '../../../../design-system';
import { navigateOnRowClick } from '../../../../utils/dataTable/rowHelpers';
import { normalise, Normalised } from '../../../../utils/request';
import { userRoleLabels } from '../../../../utils/users/FieldDefinitions';
import { getDeleteUserAction } from '../../../../utils/users/form';

type OrgUser = Normalised<UserSummary> & { role: string };
function toRowData(rows: OrgUser[], membership: Normalised<Membership>): OrgUser[] {
  const { user, role } = membership;
  if (!user || !('email' in user)) {
    return rows;
  }
  return rows.concat({ role: userRoleLabels[role], ...user });
}

const UsersListView: React.FC = () => {
  const history = useHistory();
  const {
    url,
    params: { slug: orgSlug },
  } = useRouteMatch<{ slug: string }>();
  const { data, error, loading, refetch } = useListOrganisationMembers({ orgSlug });
  const removeUsers = useDeleteOrganisationMemberships({ orgSlug });
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const memberships: Membership[] = data?.data ?? [];
  const tableData: OrgUser[] = data ? normalise(data).reduce(toRowData, []) : [];

  if (error) {
    return <DataErrorHandler description="Unable to load users" error={error} refetch={refetch} />;
  }

  return (
    <Workspace maxWidth="xl">
      <Card>
        <DataTable<OrgUser>
          isLoading={loading}
          options={{ columnsButton: true, actionsColumnIndex: -1 }}
          toolbarProps={{
            ...getToolbarSearch('users'),
            actions: (props: React.PropsWithChildren<ColumnChangerProps> & ExportProps<OrgUser>) => (
              <>
                <ColumnChanger
                  columnsButton={props.columnsButton}
                  columns={props.columns}
                  icons={props.icons}
                  onColumnsChanged={props.onColumnsChanged}
                />
                <ExportCsv<OrgUser>
                  columns={props.columns}
                  data={props.data}
                  exportFileName="users"
                  getFieldValue={props.getFieldValue}
                  icons={props.icons}
                />
                <Button component={Link} to={`/organisations/${orgSlug}/add/user`} color="primary" variant="contained">
                  Add user
                </Button>
              </>
            ),
          }}
          columns={[
            {
              field: 'name',
              title: 'Name',
              render: (rowData) => (
                <TableLink to={`/organisations/${orgSlug}/users/${rowData.id}/overview`}>{rowData.name}</TableLink>
              ),
            },
            { field: 'email', title: 'Email' },
            { field: 'role', title: 'Role' },
          ]}
          data={tableData}
          onRowClick={navigateOnRowClick((rowData) => `${url}/${rowData.id}`, history)}
          actions={[
            getDeleteUserAction({
              closeSnackbar,
              enqueueSnackbar,
              refetch,
              removeUsers: async (users: Normalised<UserSummary>[]) => {
                const membershipsToRemove: MembershipIdentifier[] = memberships
                  .filter((membership) => users.some((user) => user.id === membership.relationships.user.data.id))
                  .map((membership) => ({
                    id: membership.id,
                    type: 'memberships',
                  }));
                await removeUsers.mutate({ data: membershipsToRemove.filter(Boolean) });
              },
              source: 'the organisation',
            }),
          ]}
        />
      </Card>
    </Workspace>
  );
};

export default UsersListView;
