import Button from '@material-ui/core/Button';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Card, Section, useNavigationView, Wizard, Workspace } from '../../../design-system';
import { mainNav } from '../../navView';
import { CommandStep } from './components/CommandStep';
import { MetadataStep } from './components/MetadataStep';
import { ResultsStep } from './components/ResultsStep';
import { CommandsWizardState } from './types';

export const CommandsView: React.FC = () => {
  useNavigationView(mainNav);

  const { push } = useHistory();
  const { state } = useLocation<Pick<CommandsWizardState, 'chargePoints'>>();

  const onComplete = React.useCallback(() => {}, []);

  if (!state?.chargePoints || state?.chargePoints.length === 0) {
    return (
      <Workspace maxWidth="xl">
        <Section title="No charge points are selected">
          <Button component={Link} to="/admin/charge-points" color="primary" variant="contained">
            Select charge points
          </Button>
        </Section>
      </Workspace>
    );
  }

  return (
    <Workspace maxWidth="xl">
      <Section title="Send command" description="Send command to charge points">
        <Card>
          <Wizard<CommandsWizardState>
            steps={[
              { id: 'command', label: 'Select a command', view: CommandStep },
              { id: 'metadata', label: 'Add command metadata', view: MetadataStep },
              { id: 'results', label: 'Send command', view: ResultsStep },
            ]}
            onComplete={onComplete}
            onExit={() => push('/admin/charge-points')}
            hasStepper
          />
        </Card>
      </Section>
    </Workspace>
  );
};
