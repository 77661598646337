import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useNavigationView } from '../../../../../design-system';
import { cardNav } from './navView';
import OverviewView from './Overview';
import { CardProvider } from './Provider';
import SettingsView from './Settings';

const CardView: React.FC = () => {
  useNavigationView(cardNav);

  const { path, url } = useRouteMatch<{ id: string }>();

  return (
    <CardProvider>
      <Switch>
        <Route path={`${path}/overview`}>
          <OverviewView />
        </Route>
        <Route path={`${path}/settings`}>
          <SettingsView />
        </Route>
        <Route>
          <Redirect to={`${url}/overview`} />
        </Route>
      </Switch>
    </CardProvider>
  );
};

export default CardView;
