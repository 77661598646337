import type { Connector, NetworkStatus, UserIdentifier, UserSummary } from '../app/ApiGen';
import { getStatusIndicator } from '../components/Connectors';
import type { Address } from './api';
import { getAddressAsString } from './locations/address';
import { userHasAttributes } from './format';
import type { Normalised } from './request';

const beginsWith = (text: string, prefix: string): boolean => text.toLowerCase().startsWith(prefix.toLowerCase());

export const includesSearch = (item: string | undefined, searchValue: string): boolean =>
  !!item && item.toLowerCase().includes(searchValue.toLowerCase());

export const addressIncludesSearch = (address: Address, searchValue: unknown): boolean =>
  typeof searchValue === 'string' && includesSearch(getAddressAsString(address), searchValue);

export const numberIncludesSearch = (numericalItem: number | undefined, searchValue: string): boolean => {
  if (typeof numericalItem === 'undefined') {
    return false;
  }
  const [symbols, value] = searchValue.split(/([-\\., \d]+)/);
  const numericSearchValue = parseFloat(value);
  switch (symbols) {
    case '>':
      return numericalItem > numericSearchValue;
    case '>=':
      return numericalItem >= numericSearchValue;
    case '<':
      return numericalItem < numericSearchValue;
    case '<=':
      return numericalItem <= numericSearchValue;
    default:
      return (
        beginsWith(numericalItem.toString(10), searchValue) || beginsWith(numericalItem.toLocaleString(), searchValue)
      );
  }
};

export const userIncludesSearch = (args: {
  user: Normalised<UserSummary> | UserIdentifier | null;
  label: string;
  search: unknown;
}): boolean => {
  const { user, label, search } = args;
  if (typeof search !== 'string') {
    return false;
  }
  return includesSearch(label, search) || (userHasAttributes(user) && includesSearch(user.email, search));
};

export const userListIncludesSearch = (args: {
  users: (Normalised<UserSummary> | UserIdentifier)[];
  label: string;
  search: string;
}): boolean => {
  const { label, search, users } = args;
  return users.length > 0
    ? users.some((user) => userIncludesSearch({ user, label, search }))
    : includesSearch(label, search);
};

export const connectorsIncludeSearch = (
  searchValue: unknown,
  item: { connectors: Pick<Connector, 'connectorId' | 'ocppStatus'>[]; networkStatus: NetworkStatus },
): boolean =>
  typeof searchValue === 'string' &&
  item.connectors.some((connector) => {
    const { label } = getStatusIndicator(item.networkStatus, connector);
    return beginsWith(label, searchValue) || beginsWith(connector.connectorId, searchValue);
  });
