import * as Yup from 'yup';
import type { ResponseListUsers } from '../../app/ApiGen';
import { createSchema } from '../formUtils';

export interface InstallerDetails {
  email: string;
  id: string;
  name: string;
}

export const getInstallerLabel = (installer: InstallerDetails): string =>
  `${installer.email}${installer.email !== installer.name ? ` (${installer.name})` : ''}`;

export const getInstallersDetails = (installers: ResponseListUsers): InstallerDetails[] =>
  installers.data.map((installer) => ({
    email: installer.attributes.email,
    id: installer.id,
    name: installer.attributes.name ?? '',
  }));

export type AssignedInstallers = {
  assignedInstallers: InstallerDetails[];
};

export const assignedInstallersValidationSchema = createSchema<AssignedInstallers>({
  assignedInstallers: Yup.array<InstallerDetails>().min(1, 'At least one installer is required').required(),
});
