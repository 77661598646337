import { createTheme } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import deepmerge from 'deepmerge';
import { base, DefaultPalette } from './base';

export type MuiColor = 'background' | 'primary' | 'secondary';
export type MuiPalette = Record<MuiColor, DefaultPalette>;

export const muiPalette: MuiPalette = {
  background: {
    default: common.white,
    main: common.white,
    contrastText: base.palette.bone.contrastText,
  },
  primary: {
    main: base.palette.tussock.main,
    contrastText: base.palette.tussock.contrastText,
  },
  secondary: {
    main: base.palette.sand.main,
    contrastText: base.palette.sand.contrastText,
  },
};

const mui = createTheme({
  palette: {
    type: 'light',
    ...muiPalette,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
  typography: {
    fontFamily: ['Lausanne', 'Arial'].join(', '),
  },
});

export const light = {
  mui,
  styled: deepmerge(mui, base),
};
