import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useNavigationView } from '../../../../../design-system';
import { fullStateSelector } from '../../../../../store/root';
import { locationNav } from './navView';
import OverviewView from './Overview';
import { LocationProvider } from './Provider';
import SettingsView from './Settings';
import UsersView from './Users';

const LocationView: React.FC = () => {
  useNavigationView(locationNav);

  const { isEvnexAdmin } = useSelector(fullStateSelector);
  const { path, url } = useRouteMatch<{ id: string }>();

  return (
    <LocationProvider>
      <Switch>
        <Route path={`${path}/overview`}>
          <OverviewView />
        </Route>
        {isEvnexAdmin && (
          <Route path={`${path}/users`}>
            <UsersView />
          </Route>
        )}
        <Route path={`${path}/settings`}>
          <SettingsView />
        </Route>
        <Route>
          <Redirect to={`${url}/overview`} />
        </Route>
      </Switch>
    </LocationProvider>
  );
};

export default LocationView;
