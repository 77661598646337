import Button, { ButtonProps } from '@material-ui/core/Button';
import { rgba } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

interface StyledToggleButtonProps extends ButtonProps {
  isCollapsed: boolean;
}

const ToggleButton: React.FC<StyledToggleButtonProps> = React.forwardRef(({ isCollapsed, ...rest }, ref) => (
  <Button {...rest} ref={ref} />
));

export const StyledToggleButton = styled(ToggleButton)`
  margin-left: auto;
  height: 40px;
  min-width: unset;

  :hover {
    background-color: ${(p) => rgba(p.theme.palette.common.white, 0.13)};
  }

  svg {
    font-size: 24px;
    transform: ${(p) => (p.isCollapsed ? 'rotate(180deg)' : '')};
  }
`;
