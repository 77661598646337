import { Box, Card, List } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { uid } from 'react-uid';
import { useCurrentOrganisation } from '../../../../../../app/CurrentOrganisationProvider';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { Loading, Section, Workspace } from '../../../../../../design-system';
import {
  useGetChargePointCommandGetConfiguration,
  usePostChargePointCommandChangeConfiguration,
} from '../../../../../../utils/api';
import { useChargePoint } from '../Provider';
import { ConfigurationItem } from './components/ConfigurationItem';

interface CommandReturn {
  configurationKey: string;
  configurationValue: unknown;
}

const CommandList: React.FC<{ chargePointId: string; organisationSlug: string }> = ({
  chargePointId,
  organisationSlug,
}) => {
  const [commandKey, setCommandKey] = React.useState<string | undefined>(undefined);
  const [commandError, setCommandError] = React.useState<CommandReturn | undefined>(undefined);
  const [commandResponse, setCommandResponse] = React.useState<CommandReturn | undefined>(undefined);

  const { data, loading, refetch, error } = useGetChargePointCommandGetConfiguration({
    organisationSlug,
    chargePointId,
  });

  const { loading: mutateLoading, mutate } = usePostChargePointCommandChangeConfiguration({
    organisationSlug,
    chargePointId,
  });

  const update = React.useCallback(
    (key: string, value: string) => {
      setCommandKey(key);
      setCommandError(undefined);
      setCommandResponse(undefined);
      mutate({ key, value })
        .then((res) => {
          setCommandResponse({ configurationKey: key, configurationValue: res });
        })
        .catch(async (err) => {
          setCommandError({ configurationKey: key, configurationValue: err });
          return refetch();
        })
        .finally(() => {
          setCommandKey(undefined);
        });
    },
    [mutate, refetch],
  );

  if (loading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (error || !data) {
    return <DataErrorHandler description="Unable to load charge point configuration" error={error} refetch={refetch} />;
  }

  const configuration = data.data.items ?? [];

  return (
    <Workspace>
      <Section title="Advanced configuration" description="Change advanced configuration settings on the charge point.">
        <Card>
          <Alert severity="warning">
            <strong>Exercise caution when changing these variables on customer charge points!</strong>
            <br />
            <br />
            <span>
              Incorrectly changing these variables can render a charge point inoperative and unable to charge vehicles
              or permanently disable its ability to connect to our network.
            </span>
          </Alert>
        </Card>
        <Box mb={2} />
        <Card>
          <List>
            {configuration.map((item, idx) => (
              <ConfigurationItem
                key={uid(item, idx)}
                id={item.key}
                value={item.value}
                isReadonly={item.readonly}
                isDisabled={mutateLoading}
                loading={commandKey === item.key && mutateLoading}
                error={item.key === commandError?.configurationKey ? commandError.configurationValue : undefined}
                response={
                  item.key === commandResponse?.configurationKey ? commandResponse.configurationValue : undefined
                }
                update={update}
              />
            ))}
          </List>
        </Card>
      </Section>
    </Workspace>
  );
};

const AdvancedView: React.FC = () => {
  const { loading: orgLoading, organisation } = useCurrentOrganisation();
  const { chargePoint, error, loading, refetch } = useChargePoint();

  if (loading || orgLoading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }
  if (error || !chargePoint || !organisation) {
    return <DataErrorHandler description="Unable to load charge point" error={error} refetch={refetch} />;
  }
  return <CommandList chargePointId={chargePoint.id} organisationSlug={organisation.slug} />;
};

export default AdvancedView;
