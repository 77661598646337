import { all, fork, put, select, takeLatest, Effect } from 'redux-saga/effects';
import type { ApplicationState } from '../types';
import { changeCurrentOrganisationAction, setCurrentOrganisationAction } from './actions';
import { OrganisationAction } from './types';

export function* handleChangeCurrentOrganisation(
  action: ReturnType<typeof changeCurrentOrganisationAction>,
): Generator<Effect> {
  const user = (yield select(
    (state: ApplicationState) => state.authenticatedUser.current,
  )) as ApplicationState['authenticatedUser']['current'];

  const nextOrganisation = user?.organisations.find(({ slug }) => slug === action.payload.slug);

  if (!nextOrganisation) {
    yield put(setCurrentOrganisationAction(undefined));
    return;
  }

  yield put(setCurrentOrganisationAction(nextOrganisation));
}

function* watchChangeCurrentOrganisation() {
  yield takeLatest(OrganisationAction.CHANGE_CURRENT_ORGANISATION, handleChangeCurrentOrganisation);
}

export function* organisationSagas(): Generator<Effect> {
  yield all([fork(watchChangeCurrentOrganisation)]);
}
