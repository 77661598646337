import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useSelector } from 'react-redux';
import { useCurrentOrganisation } from '../../../../../../app/CurrentOrganisationProvider';
import { DataErrorHandler, ErrorHandler } from '../../../../../../components/ErrorHandler';
import { Loading, Section, Workspace } from '../../../../../../design-system';
import { fullStateSelector } from '../../../../../../store/root';
import { useChargePoint } from '../Provider';
import { ChargePointCommands } from './components/ChargePointCommands';
import { ConnectorCommands } from './components/ConnectorCommands';
import { DeleteChargePoint } from './components/DeleteChargePoint';
import { DetailsForm } from './components/DetailsForm';

export const SettingsView: React.FC = () => {
  const { chargePoint, loading: chargePointLoading, error, refetch: refetchChargePoint } = useChargePoint();
  const { organisation, loading: orgLoading } = useCurrentOrganisation();
  const { isAdmin, isEvnexAdmin } = useSelector(fullStateSelector);

  if (chargePointLoading || orgLoading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (!organisation) {
    return (
      <Workspace>
        <ErrorHandler description="Unable to load organisation" />
      </Workspace>
    );
  }

  if (error || !chargePoint) {
    return <DataErrorHandler description="Unable to fetch charge point" error={error} refetch={refetchChargePoint} />;
  }

  return (
    <Workspace>
      <Section title="Charge point settings" description="Details and settings for this charge point.">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DetailsForm chargePointId={chargePoint.id} orgSlug={organisation.slug} />
          </Grid>
          <Grid item xs={12}>
            <ChargePointCommands />
          </Grid>
          {chargePoint.connectors.map(({ connectorId }) => (
            <Grid item xs={12} key={connectorId}>
              <ConnectorCommands chargePointId={chargePoint.id} connectorId={connectorId} />
            </Grid>
          ))}
          {(isEvnexAdmin || isAdmin) && (
            <Grid item xs={12}>
              <DeleteChargePoint />
            </Grid>
          )}
        </Grid>
      </Section>
    </Workspace>
  );
};
