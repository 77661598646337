import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateLocation } from '../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import { InstallationPoint } from '../../../../../components/CustomFields/InstallationPoint';
import { errorSnackbar } from '../../../../../components/ErrorSnackbar';
import { Card, Section, Wizard, Workspace } from '../../../../../design-system';
import { Address, Coordinates } from '../../../../../utils/api';
import { getIcpNumber } from '../../../../../utils/locations/installationConnectionPoint';
import { ConfirmationStep } from './components/ConfirmationStep';
import { DetailsStep } from './components/DetailsStep';

type CreateLocationWizardState = {
  name: string;
  address: Address;
  coordinates: Coordinates;
  timeZone: string;
  cost: number;
  currency: string;
  installationPoint: InstallationPoint;
};

type OnComplete = (state: CreateLocationWizardState) => void;

const CreateLocationView: React.FC = () => {
  const { push } = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { organisation } = useCurrentOrganisation();
  const { slug } = organisation ?? { id: '', slug: '' };

  const { error, mutate } = useCreateLocation({ orgSlug: slug });

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to create location', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  const onExit = React.useCallback(() => push(`/organisations/${slug}/locations`), [push, slug]);

  const onComplete = React.useCallback<OnComplete>(
    async ({ name, address, coordinates, timeZone, installationPoint }) => {
      setIsSubmitting(true);

      try {
        await mutate({
          data: {
            attributes: {
              name,
              address,
              coordinates,
              timeZone,
              isPublic: false,
              icpNumber: getIcpNumber(installationPoint),
            },
            type: 'locations',
          },
        });
        enqueueSnackbar('Location created', { variant: 'success' });
        push(`/organisations/${slug}/locations`);
      } finally {
        setIsSubmitting(false);
      }
    },
    [enqueueSnackbar, mutate, push, slug],
  );

  return (
    <Workspace>
      <Section
        title="Create location"
        description="Create a new location for your organisation. A location is a collection of one or more charge points."
      >
        <Card>
          <Wizard<CreateLocationWizardState>
            steps={[
              { id: 'details', label: 'Details', view: DetailsStep },
              { id: 'confirmation', label: 'Confirmation', view: ConfirmationStep },
            ]}
            onComplete={onComplete}
            onExit={onExit}
            isLoading={isSubmitting}
            hasStepper
          />
        </Card>
      </Section>
    </Workspace>
  );
};

export default CreateLocationView;
