import * as Yup from 'yup';
import { combineSchemas, createSchema, FormFields } from '../formUtils';
import { customerDetailsFields, phoneNumberValidationSchema } from './customer';

export type JobFormDetails = {
  contactPhoneNumber: string;
  referenceId: string | null;
  powerSensorCount: number;
};

export const referenceIdValidation = createSchema<JobFormDetails>({
  referenceId: Yup.string().uppercase('Job reference ID must be upper case').strict(true),
});

export const powerSensorCountValidation = createSchema<JobFormDetails>({
  powerSensorCount: Yup.number()
    .integer('Number of power sensors must be an integer')
    .min(0, 'Minimum is 0')
    .max(
      1,
      'Only jobs with up to 1 power sensor are supported at this time. If you need to represent more, please create additional jobs.',
    )
    .required()
    .strict(true),
});

export const jobDetailsValidationSchema = combineSchemas(
  combineSchemas(phoneNumberValidationSchema, referenceIdValidation),
  powerSensorCountValidation,
);

export const jobDetailsFields: FormFields<JobFormDetails> = {
  contactPhoneNumber: customerDetailsFields().contactPhoneNumber,
  referenceId: { id: 'referenceId', label: 'Job reference ID' },
  powerSensorCount: {
    id: 'powerSensorCount',
    label: 'How many power sensors installed',
    inputProps: { type: 'number' },
  },
};
