import React from 'react';
import { WizardStep } from '../../../../../components/WizardStep';
import { OnSubmit, useWizard } from '../../../../../design-system';
import { JobLocationDetails, jobLocationFields } from '../../../../../utils/jobs/location';
import { locationValidationSchema } from '../../../../../utils/locations/FieldDefinitions';
import type { JobWizardState } from '../types';

export const LocationStep: React.FC = () => {
  const { state: untypedState, setState: untypedSetState, prevStep, nextStep, step } = useWizard();
  const state = untypedState as Partial<JobWizardState>;
  const setState = untypedSetState as (state: Partial<JobWizardState>) => void;

  const locationName = state.driverFieldContents?.name ? `${state.driverFieldContents.name}'s Home` : '';
  const initialValues: JobLocationDetails = state.locationFieldContents
    ? { ...state.locationFieldContents, name: locationName }
    : {
        name: locationName,
        address: { address1: '', address2: '', address3: '', city: '', postCode: '', state: '', country: '' },
        coordinates: { latitude: '', longitude: '' },
        timeZone: '',
        installationPoint: null,
      };

  const onCancel = React.useCallback<OnSubmit<JobLocationDetails>>(
    (values) => {
      setState({ locationFieldContents: values });
      prevStep();
    },
    [setState, prevStep],
  );

  const onSubmit = React.useCallback<OnSubmit<JobLocationDetails>>(
    (values) => {
      setState({ locationFieldContents: values });
      nextStep();
    },
    [nextStep, setState],
  );

  return (
    <WizardStep<JobLocationDetails>
      fields={Object.values(jobLocationFields())}
      initialValues={initialValues}
      name="Location details"
      onCancel={onCancel}
      onSubmit={onSubmit}
      step={step}
      subtitle="Create the location where the driver's new charge point will be installed."
      validationSchema={locationValidationSchema}
    />
  );
};
