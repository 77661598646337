import RSeriesSocket from '../../assets/charge-points/evnex/r-series-socket.png';

// Master list of all Evnex product codes: https://evnexsystems.atlassian.net/wiki/x/CoCIIw
const chargePointImages: Record<string, Record<string, string | undefined>> = {
  Evnex: {
    'R7-T2S-3G': RSeriesSocket,
    'R22-T2S-3G': RSeriesSocket,
    'R7-T2S-LTE': RSeriesSocket,
    'R22-T2S-LTE': RSeriesSocket,

    'R7-T1T-3G': undefined,
    'R7-T2T-3G': undefined,
    'R22-T2T-3G': undefined,
    'R7-T1T-LTE': undefined,
    'R7-T2T-LTE': undefined,
    'R22-T2T-LTE': undefined,

    'CS-M-32A-1P-T2S-3G': undefined,
    'CS-M-32A-1P-T2T-3G': undefined,
    'CS-M-32A-1P-T1T-3G': undefined,
    'CS-M-32A-3P-T2S-3G': undefined,
    'CS-M-32A-3P-T2T-3G': undefined,
  },
};

export const getChargePointImage = (vendor: string | undefined, model: string | undefined): string | undefined => {
  if (!vendor || !model) {
    return undefined;
  }

  return chargePointImages[vendor] && chargePointImages[vendor][model] ? chargePointImages[vendor][model] : undefined;
};
