import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import { useUpdateToken } from '../../../../../../../app/ApiGen';
import { SwitchInput, SwitchValue } from '../../../../../../../components/CustomFields/Switch';
import { errorSnackbar } from '../../../../../../../components/ErrorSnackbar';
import { Card, Form, OnSubmit, ValueComponentProps } from '../../../../../../../design-system';
import { createSchema } from '../../../../../../../utils/formUtils';
import { useCard } from '../../Provider';

const validationSchema = createSchema<CardSettings>({
  // eslint-disable-next-line no-template-curly-in-string --- Yup messages can be customised using template curlies in strings.
  name: Yup.string().required('Name is required').max(40, 'Card name must be at most ${max} characters'),
  isValid: Yup.boolean().required('Status is required'),
});

type CardSettings = {
  name: string;
  isValid: boolean;
};

export const DetailsForm: React.FC<{ tokenId: string }> = ({ tokenId }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { loading, card, refetch } = useCard();
  const { error, mutate } = useUpdateToken({ tokenId });

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to edit card details', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);
  const [isEditing, setIsEditing] = React.useState(false);

  const initialValues: CardSettings = {
    name: card?.name ?? '',
    isValid: card?.isValid ?? false,
  };

  const onSubmit = React.useCallback<OnSubmit<CardSettings>>(
    async ({ name, isValid }, { setSubmitting }) => {
      try {
        await mutate({ name, isValid });
        enqueueSnackbar('Card updated', { variant: 'success' });
        setIsEditing(false);
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [mutate, enqueueSnackbar, refetch],
  );

  return (
    <Card title="Details">
      <Form
        fields={[
          {
            id: 'name',
            label: 'Name',
          },
          {
            id: 'isValid',
            label: 'Status',
            valueComponent: ({ value }: ValueComponentProps<boolean>) => (
              <SwitchValue value={value} labels={{ true: 'Enabled', false: 'Disabled' }} />
            ),
            inputComponent: SwitchInput,
          },
        ]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        isLoading={loading}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </Card>
  );
};
