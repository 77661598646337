import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';
import styled from 'styled-components';
import ContainerHeader from '../ContainerHeader/ContainerHeader';

const RoundedSkeleton = styled(Skeleton)`
  border-radius: 4px;
`;

export interface ContainerHeaderLoadingProps {
  avatar?: boolean;
  subText?: boolean;
}

const ContainerHeaderLoading: React.FC<ContainerHeaderLoadingProps> = ({ avatar, subText }) => (
  <ContainerHeader
    avatar={avatar && <RoundedSkeleton variant="rect" width="40px" height="40px" />}
    text={<Skeleton width="100%" />}
    subText={subText && <Skeleton width="100%" />}
  />
);

ContainerHeaderLoading.displayName = 'ContainerHeaderLoading';

export default ContainerHeaderLoading;
