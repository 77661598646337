import { CircularProgress, List, ListItem, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { MutateMethod } from 'restful-react';
import {
  CommandChangeConfigurationPathParams,
  CommandResetPathParams,
  CommandUpdateFirmwarePathParams,
  RequestCommandChangeConfiguration,
  RequestCommandReset,
  RequestCommandUpdateFirmware,
  useCommandDataTransfer,
  ResponseCommand,
  ResponseCommandChangeConfiguration,
  useCommandChangeConfiguration,
  useCommandReset,
  useCommandUpdateFirmware,
  RequestCommandDataTransfer,
  CommandDataTransferPathParams,
  ResponseCommandDataTransfer,
} from '../../../../app/ApiGen';
import { Section, useWizard, Workspace } from '../../../../design-system';
import { ChargePointState, CommandState, CommandsWizardState, CommandType } from '../types';

type ChargePointCommand = {
  chargePoint: ChargePointState;
} & CommandState;

enum CommandStatus {
  READY = 'Ready',
  LOADING = 'Loading',
  ERROR = 'Error',
  UNKNOWN = 'Unknown',
}

type RunCommandProps<Request, Response, PathParams> = {
  chargePoint: ChargePointState;
  loading: boolean;
  mutate: MutateMethod<Response, Request, void, PathParams>;
  mutateBody: Request;
};

const getCommandTitle = (commandType: CommandType) => {
  switch (commandType) {
    case CommandType.UPDATE_FIRMWARE:
      return 'Update firmware';
    case CommandType.RESET:
      return 'Reset';
    case CommandType.CHANGE_CONFIGURATION:
      return 'Change configuration';
    case CommandType.DATA_TRANSFER:
      return 'Data transfer';
    default:
      return '';
  }
};

const ChargePointStatus: React.FC<{ status: string }> = ({ status }) => {
  if (status === CommandStatus.LOADING) {
    return <CircularProgress size={24} color="inherit" />;
  }

  return (
    <Typography aria-label={`Status: ${status}`} color={status === CommandStatus.ERROR ? 'error' : 'inherit'}>
      {status}
    </Typography>
  );
};

function CommandResult<
  Request,
  Response extends ResponseCommandChangeConfiguration | ResponseCommand | void,
  PathParams
>({ chargePoint, loading, mutate, mutateBody }: RunCommandProps<Request, Response, PathParams>) {
  const [status, setStatus] = React.useState<string>(CommandStatus.READY);

  React.useEffect(() => {
    if (loading) {
      setStatus(CommandStatus.LOADING);
    }
  }, [loading]);

  React.useEffect(() => {
    if (status === CommandStatus.READY && !loading) {
      mutate(mutateBody)
        .then((response) => {
          const responseStatus: string =
            response && response.data?.status ? response.data.status : CommandStatus.UNKNOWN;
          setStatus(responseStatus);
        })
        .catch((e) => {
          console.error('Error: ', e);
          setStatus(CommandStatus.ERROR);
        });
    }
  }, [loading, mutate, mutateBody, setStatus, status]);

  return (
    <ListItem key={chargePoint.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Typography variant="body1">{chargePoint.name ?? chargePoint.ocppChargePointId}</Typography>
      <ChargePointStatus status={status} />
    </ListItem>
  );
}

const ChargePointListItem: React.FC<ChargePointCommand> = ({ chargePoint, command }) => {
  const { id } = chargePoint;

  const useFirmwareUpdate = useCommandUpdateFirmware({ id });
  const useReset = useCommandReset({ id });
  const useChangeConfiguration = useCommandChangeConfiguration({ id });
  const useDataTransfer = useCommandDataTransfer({ id });

  switch (command.type) {
    case CommandType.UPDATE_FIRMWARE:
      return (
        <CommandResult<RequestCommandUpdateFirmware, void, CommandUpdateFirmwarePathParams>
          chargePoint={chargePoint}
          loading={useFirmwareUpdate.loading}
          mutate={useFirmwareUpdate.mutate}
          mutateBody={{ location: command.firmwareUrl ?? '' }}
        />
      );
    case CommandType.RESET:
      return (
        <CommandResult<RequestCommandReset, ResponseCommand, CommandResetPathParams>
          chargePoint={chargePoint}
          loading={useReset.loading}
          mutate={useReset.mutate}
          mutateBody={{ resetType: command.resetType ?? 'Soft' }}
        />
      );
    case CommandType.CHANGE_CONFIGURATION:
      return (
        <CommandResult<
          RequestCommandChangeConfiguration,
          ResponseCommandChangeConfiguration,
          CommandChangeConfigurationPathParams
        >
          chargePoint={chargePoint}
          loading={useChangeConfiguration.loading}
          mutate={useChangeConfiguration.mutate}
          mutateBody={{ key: command.configurationKey ?? '', value: command.configurationValue ?? '' }}
        />
      );
    case CommandType.DATA_TRANSFER:
      return (
        <CommandResult<RequestCommandDataTransfer, ResponseCommandDataTransfer, CommandDataTransferPathParams>
          chargePoint={chargePoint}
          loading={useDataTransfer.loading}
          mutate={useDataTransfer.mutate}
          mutateBody={{ vendorId: 'Evnex', messageId: command.messageId, data: '' }}
        />
      );
    default:
      return <></>;
  }
};

export const ResultsStep: React.FC = () => {
  const { state: untypedState, prevStep } = useWizard();
  const { command } = untypedState as CommandsWizardState;

  const {
    state: { chargePoints },
  } = useLocation<Pick<CommandsWizardState, 'chargePoints'>>();

  return (
    <Workspace maxWidth="lg">
      <Section title={`${getCommandTitle(command.type)} results`}>
        <List>
          {chargePoints.map((cp) => (
            <ChargePointListItem chargePoint={cp} command={command} key={cp.id} />
          ))}
        </List>
        <Box pt={2}>
          <Button onClick={prevStep} variant="contained">
            Back
          </Button>
        </Box>
      </Section>
    </Workspace>
  );
};
