import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { UserOrganisation } from '../../app/ApiGen';
import { InputComponentProps, ValueComponentProps } from '../../design-system';

export const OrganisationValue: React.FC<ValueComponentProps<UserOrganisation | undefined>> = ({ value }) => {
  if (!value) {
    return null;
  }

  const { name } = value;

  return <>{name}</>;
};

export interface OrganisationInputProps extends InputComponentProps<UserOrganisation> {
  organisations: UserOrganisation[];
}

export const OrganisationInput: React.FC<OrganisationInputProps> = ({ organisations, ...other }) => (
  <TextField select variant="outlined" margin="dense" fullWidth style={{ margin: 0 }} {...other}>
    {organisations.map(({ id, name }) => (
      <MenuItem key={id} value={id}>
        {name}
      </MenuItem>
    ))}
  </TextField>
);
