import { MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import { ChargingProfilePurposeType, useCommandClearChargingProfile } from '../../../../../../../../app/ApiGen';
import { Dialog, DialogProps } from '../../../../../../../../components/Dialog';
import { Form, FormField, OnSubmit } from '../../../../../../../../design-system';
import { createSchema } from '../../../../../../../../utils/formUtils';
import { useChargePoint } from '../../../Provider';
import { useHandleError } from './useHandleError';

const fields: FormField[] = [
  {
    id: 'id',
    label: 'Profile ID',
  },
  {
    id: 'connectorId',
    label: 'Connector ID',
  },
  {
    id: 'chargingProfilePurpose',
    label: 'Profile Purpose',
    inputComponent: (props) => (
      <TextField
        select
        variant="outlined"
        margin="dense"
        fullWidth
        style={{ margin: 0 }}
        disabled={props.disabled}
        error={props.error}
        name={props.name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        defaultValue=""
      >
        {['ChargePointMaxProfile', 'TxDefaultProfile', 'TxProfile'].map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </TextField>
    ),
  },
  {
    id: 'stackLevel',
    label: 'Stack Level',
  },
];

const initialValues = {
  id: undefined,
  connectorId: undefined,
  chargingProfilePurpose: undefined,
  stackLevel: undefined,
};

type ClearChargingProfileDialogFields = {
  id?: number;
  connectorId?: number;
  chargingProfilePurpose?: ChargingProfilePurposeType;
  stackLevel?: number;
};

const validationSchema = createSchema<ClearChargingProfileDialogFields>({
  id: Yup.number().optional(),
  connectorId: Yup.number().optional(),
  chargingProfilePurpose: Yup.string<ChargingProfilePurposeType>().optional(),
  stackLevel: Yup.number().optional(),
});

export const ClearChargingProfileDialog: React.FC<DialogProps> = ({ isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { chargePoint, refetch } = useChargePoint();

  const { error, mutate } = useCommandClearChargingProfile({ id: chargePoint?.id ?? '' });

  useHandleError({ error, defaultErrorMessage: 'Unable to clear charging profile' });

  const onSubmit = React.useCallback<OnSubmit<ClearChargingProfileDialogFields>>(
    async ({ id, connectorId, chargingProfilePurpose, stackLevel }, { setSubmitting }) => {
      try {
        const { data } = await mutate({ id, connectorId, chargingProfilePurpose, stackLevel });
        enqueueSnackbar(`Clear charging profile command ${data.status.toLocaleLowerCase()}`);
        onClose();
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [onClose, mutate, refetch, enqueueSnackbar],
  );

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Clear charging profile">
      <Form<ClearChargingProfileDialogFields>
        fields={fields}
        initialValues={initialValues}
        onSubmit={onSubmit}
        labels={{ save: 'Send command' }}
        isEditing
        validationSchema={validationSchema}
      />
    </Dialog>
  );
};
