import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import * as React from 'react';
import styled from 'styled-components';
import { ErrorHandler } from '../../../../../../../components/ErrorHandler';
import { Field, ListItem } from '../../../../../../../design-system';

const StyledAlert = styled(Alert)`
  .MuiAlert-message {
    width: calc(100% - 16px - 22px);
  }
`;

export interface ConfigurationItemProps {
  id: string;
  value?: string;
  isReadonly?: boolean;
  isDisabled: boolean;
  loading: boolean;
  error: unknown;
  response: unknown;
  update: (key: string, value: string) => void;
}

export const ConfigurationItem: React.FC<ConfigurationItemProps> = ({
  id,
  value = '',
  isReadonly,
  isDisabled,
  loading,
  error,
  response,
  update,
}: ConfigurationItemProps) => {
  const [configValue, setConfigValue] = React.useState(value);

  return (
    <ListItem>
      {error && (
        <Box mb={2}>
          <ErrorHandler description="Command Error" error={error} />
        </Box>
      )}
      {response && (
        <Box mb={2}>
          <StyledAlert severity="info">
            <AlertTitle>Command response</AlertTitle>
            <pre style={{ margin: 0, overflow: 'auto' }}>{JSON.stringify(response, null, 2)}</pre>
          </StyledAlert>
        </Box>
      )}
      <Field label={id}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={10}>
            <TextField
              value={configValue}
              onChange={({ target }) => setConfigValue(target.value)}
              disabled={loading || isDisabled || isReadonly}
              fullWidth
              margin="dense"
              variant="outlined"
              style={{ margin: 0 }}
            />
          </Grid>
          <Grid item xs={12} md={2} style={{ display: 'flex', alignItems: 'center' }}>
            {isReadonly ? null : (
              <Button
                variant="outlined"
                disabled={loading || isDisabled}
                fullWidth
                onClick={() => update(id, configValue)}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Update'}
              </Button>
            )}
          </Grid>
        </Grid>
      </Field>
    </ListItem>
  );
};
