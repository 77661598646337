import { action, Action, PayloadAction } from 'typesafe-actions';
import type { UserOrganisation } from '../../app/ApiGen';
import { OrganisationAction } from './types';

export function setCurrentOrganisationAction(
  current?: UserOrganisation,
): PayloadAction<OrganisationAction.SET_CURRENT_ORGANISATION, { current?: UserOrganisation }> {
  return action(OrganisationAction.SET_CURRENT_ORGANISATION, { current });
}

export function clearCurrentOrganisationAction(): Action<OrganisationAction.CLEAR_CURRENT_ORGANISATION> {
  return action(OrganisationAction.CLEAR_CURRENT_ORGANISATION);
}

export function changeCurrentOrganisationAction(
  slug: string,
): PayloadAction<OrganisationAction.CHANGE_CURRENT_ORGANISATION, { slug: string }> {
  return action(OrganisationAction.CHANGE_CURRENT_ORGANISATION, { slug });
}
