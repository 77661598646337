import moment from 'moment-timezone';
import * as React from 'react';

export type IsoDateRange = {
  fromDate: string;
  toDate: string;
};

export type SetDateRangeCallback = (startDate: string, endDate: string) => void;

export type SetDateRangeAction = React.Dispatch<React.SetStateAction<string>>;

export type DateRangeProps = {
  dateRange: IsoDateRange;
  setDateRange: SetDateRangeCallback;
};

export const DEFAULT_DAYS_AGO = 7;

export const useStartDateState = (daysAgo = 0): [startDate: string, setStartDate: SetDateRangeAction] =>
  React.useState(moment().subtract(daysAgo, 'days').startOf('day').toISOString());

export const useEndDateState = (): [endDate: string, setEndDate: SetDateRangeAction] =>
  React.useState(moment().endOf('day').toISOString());

export const useSetDateRange: (
  setStartDate: SetDateRangeAction,
  setEndDate: SetDateRangeAction,
) => (startDate: string, endDate: string) => void = (
  setStartDate: SetDateRangeAction,
  setEndDate: SetDateRangeAction,
) =>
  React.useCallback(
    (startDate: string, endDate: string) => {
      setStartDate(startDate);
      setEndDate(endDate);
    },
    [setEndDate, setStartDate],
  );
