/**
 * Reverse the payload, convert each character to it's ASCII character code, and double every other number.
 *
 * @param payload the NMI number, with the checksum character removed
 */
function reverseAndDouble(payload: string) {
  return payload
    .split('')
    .reverse()
    .map((c) => c.charCodeAt(0))
    .map((v, i) => (i % 2 === 0 ? v * 2 : v));
}

/**
 * Return the sum of the digits in the given string
 * @param value a string containing only digits (e.g., '1236354')
 */
function sumDigitsInString(value: string) {
  return value
    .split('')
    .map((c) => parseInt(c, 10))
    .reduce((previous, current) => previous + current, 0);
}

/**
 * Return the sum of all digits in the given array
 *
 * @param numbers an array of n-length numbers, (e.g., [1, 2, 363, 54])
 */
function sumDigitsOfArray(numbers: number[]): number {
  return numbers
    .map((n) => n.toString(10))
    .map(sumDigitsInString)
    .reduce((previous, current) => previous + current, 0);
}

/**
 * Calculate the check sum for the given string, following the procedure documented in the National Metering Identifier
 * Procedure (ME_GN059GN059, V5.1)
 */
function calculateCheckSum(value: string): number {
  return (10 - (sumDigitsOfArray(reverseAndDouble(value)) % 10)) % 10;
}

/**
 * Return whether the given NMI is valid
 */
export function isValidNationalMeteringIdentifier(nmi: string | null | undefined) {
  // Don't mark empty values as invalid. Required fields are handled separately by Yup.
  if (typeof nmi !== 'string') {
    return true;
  }

  const payload = nmi.slice(0, nmi.length - 1);
  const checkSum = calculateCheckSum(payload);
  return payload + checkSum.toString(10) === nmi;
}
