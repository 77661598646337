import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import * as React from 'react';
import { InputComponentProps } from '../../../../../../../../design-system';

export const TokenRequiredSwitchInput: React.FC<InputComponentProps<boolean>> = ({
  name,
  value,
  disabled,
  onBlur,
  onChange,
  helperText,
  error,
}) => (
  <FormControl>
    <Switch
      id={name}
      color="primary"
      checked={value}
      disabled={disabled}
      onBlur={onBlur}
      onChange={({ target: { checked } }) => {
        onChange(name)({ target: { id: name, value: checked, type: '' } } as React.ChangeEvent<any>);
        if (checked) {
          onChange('defaultToken')({
            target: { id: 'defaultToken', value: '' },
            type: '',
          } as React.ChangeEvent<any>);
        }
      }}
    />
    {helperText && (
      <FormHelperText variant="outlined" error={error}>
        {helperText}
      </FormHelperText>
    )}
  </FormControl>
);
