import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useCurrentOrganisation } from '../../../../../../app/CurrentOrganisationProvider';
import { DataErrorHandler, ErrorHandler } from '../../../../../../components/ErrorHandler';
import { Loading, Workspace } from '../../../../../../design-system';
import { useLoadBalancingGroup } from '../Provider';
import { ChargePoints } from './components/ChargePoints';
import { Header } from './components/Header';

const OverviewView: React.FC = () => {
  const { error, loadBalancingGroup, loading, refetch } = useLoadBalancingGroup();
  const { organisation, loading: organisationLoading } = useCurrentOrganisation();
  if (loading || organisationLoading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }
  if (!organisation) {
    return (
      <Workspace>
        <ErrorHandler description="Unable to fetch organisation" />
      </Workspace>
    );
  }
  if (error || !loadBalancingGroup) {
    return (
      <DataErrorHandler description="Unable to fetch load balancing group details" error={error} refetch={refetch} />
    );
  }

  return (
    <Workspace>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header loadBalancingGroup={loadBalancingGroup} />
        </Grid>
        <Grid item xs={12}>
          <ChargePoints loadBalancingGroup={loadBalancingGroup} orgSlug={organisation.slug} />
        </Grid>
      </Grid>
    </Workspace>
  );
};

export default OverviewView;
