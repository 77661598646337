import Typography from '@material-ui/core/Typography';
import React from 'react';
import type { JobStatus as NumericJobStatus } from '../../app/ApiGen';
import { JobStatus, labelsFor } from '../../app/enums';
import { Indicator, IndicatorColor, ValueComponentProps } from '../../design-system';

export const HumanReadableJobStatus: Record<NumericJobStatus, string> = {
  ...labelsFor(JobStatus),
  [JobStatus.ManuallyCommissioned]: 'Manually Commissioned',
};

const JobStatusDetail: Record<NumericJobStatus, string | undefined> = {
  [JobStatus.ManuallyCommissioned]: 'Job was manually confirmed as complete by an Evnex administrator.',
  [JobStatus.Pending]: 'Job will be visible to assigned installers in the Installation app.',
  [JobStatus.Error]: 'Something went wrong while trying to complete this job; may require manual intervention.',
  [JobStatus.Commissioning]:
    'Commissioning is currently in-progress. If status does not automatically update in the next 60 seconds, job may require manual intervention.',
  [JobStatus.Cancelled]: undefined,
  [JobStatus.Complete]: undefined,
};

const JobStatusColor: Record<NumericJobStatus, IndicatorColor> = {
  [JobStatus.Pending]: 'steel',
  [JobStatus.Error]: 'red',
  [JobStatus.Complete]: 'green',
  [JobStatus.Commissioning]: 'amber',
  [JobStatus.ManuallyCommissioned]: 'purple',
  [JobStatus.Cancelled]: 'cyan',
};

type JobStatusValueProps = ValueComponentProps<NumericJobStatus> & {
  maxWidth?: React.CSSProperties['maxWidth'];
};

export const JobStatusValue: React.VFC<JobStatusValueProps> = ({ maxWidth, value }: JobStatusValueProps) => {
  const label = (
    // TODO: 'Title' attribute would be unnecessary if design-system Indicator's "toolTipText" attribute worked correctly.
    <Typography style={{ maxWidth }} noWrap display="block" variant="caption" title={JobStatusDetail[value]}>
      {HumanReadableJobStatus[value]}
    </Typography>
  );
  const color = JobStatusColor[value];
  return <Indicator variant="badge" label={label} color={color} />;
};
