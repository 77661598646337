import * as React from 'react';

const themes = {
  light: '#fff',
  dark: '#141414',
};

export type LogoProps = {
  variant?: 'light' | 'dark';
  height?: number;
  width?: number;
};

const Logo: React.FC<LogoProps> = ({ variant = 'light', height = 64, width = 176 }) => {
  const color = themes[variant];

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1392 267"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
    >
      <g transform="matrix(4.16667,0,0,4.16667,0,0)">
        <path
          d="M10.96,27.01C13.154,17.323 21.797,10.469 31.73,10.54C42.34,10.54 49.86,16.82 51.58,27.01L10.96,27.01ZM31.73,0.77C13.64,0.77 0,14.46 0,32.61C0,51.09 13.18,64 32.06,64C44.41,64 53.63,58.97 60.25,48.61L60.76,47.83L51.52,42.41L51.07,43.21C46.95,50.55 40.73,54.12 32.06,54.12C20.82,54.12 12.35,47.16 10.67,36.68L62.7,36.68L62.7,31.73C62.7,13.79 49.67,0.77 31.73,0.77Z"
          fill={color}
          style={{ fillRule: 'nonzero' }}
        />
        <path
          d="M97.461,48.514L79.037,1.924L67.593,1.924L92.039,62.758L102.093,62.758L127.665,1.926L116.204,1.924L97.461,48.514Z"
          fill={color}
          style={{ fillRule: 'nonzero' }}
        />
        <path
          d="M169.879,2.004C154.608,-2.6 138.495,6.048 133.891,21.32C133.076,24.023 132.662,26.83 132.662,29.653L132.662,62.799L143.288,62.799L143.288,28.77C143.288,18.691 151.459,10.519 161.539,10.519C171.619,10.519 179.79,18.691 179.79,28.77L179.79,62.799L190.416,62.799L190.416,29.653C190.418,16.916 182.074,5.682 169.879,2.004Z"
          fill={color}
          style={{ fillRule: 'nonzero' }}
        />
        <path
          d="M211.893,27.01C214.082,17.319 222.728,10.463 232.663,10.54C243.273,10.54 250.793,16.82 252.503,27.01L211.893,27.01ZM232.663,0.77C214.573,0.77 200.923,14.46 200.923,32.61C200.923,51.09 214.103,64 232.983,64C245.333,64 254.553,58.97 261.183,48.61L261.683,47.83L252.433,42.41L251.993,43.21C247.883,50.56 241.663,54.13 232.983,54.13C221.743,54.13 213.273,47.16 211.593,36.68L263.623,36.68L263.623,31.73C263.623,13.79 250.603,0.77 232.663,0.77Z"
          fill={color}
          style={{ fillRule: 'nonzero' }}
        />
        <path
          d="M333.032,9.538L325.519,2.024L302.714,24.828L279.911,2.023L272.396,9.537L295.2,32.341L272.396,55.144L279.909,62.658L302.714,39.855L325.517,62.659L333.031,55.147L310.228,32.342L333.032,9.538Z"
          fill={color}
          style={{ fillRule: 'nonzero' }}
        />
      </g>
    </svg>
  );
};

Logo.displayName = 'Logo';

export { Logo };
