import styled from 'styled-components';

export const Header = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
  padding-bottom: ${(p) => p.theme.spacing(1)}px;
`;

export const Title = styled('h2')`
  margin: 0;
  color: ${(p) => p.theme.palette.text.primary};
  font-size: 20px;
  font-weight: ${(p) => p.theme.typography.fontWeightRegular};
  line-height: 32px;
`;

export const Description = styled('p')`
  margin: 0;
  color: ${(p) => p.theme.palette.text.secondary};
  font-size: 14px;
  font-weight: ${(p) => p.theme.typography.fontWeightRegular};
  line-height: 20px;
`;
