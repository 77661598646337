import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as React from 'react';
import { Item, MenuSection, NavigationView } from '../../../design-system';

const Items = () => (
  <MenuSection canOverflow>
    {({ ...rest }) => <Item icon={ArrowBackIcon} text="Back to user settings" to="/user" hideActive {...rest} />}
  </MenuSection>
);

export const changePasswordNav: NavigationView = {
  id: 'changePasswordNav/product',
  type: 'product',
  Items,
};
