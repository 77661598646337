import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { InputComponentProps, ValueComponentProps } from '../../design-system';
import { SearchWrapper } from '../SearchWrapper';
import { AutocompleteContents, renderAutocompleteInput } from './SimpleAutocomplete';

const vendorOptions = [
  { label: 'Evnex', value: 'evnex' },
  { label: 'Other', value: 'other' },
];

export const VendorValue: React.FC<ValueComponentProps<string>> = ({ value }: AutocompleteContents) => {
  const option = vendorOptions.find(({ value: key }) => key === value);
  return <>{option ? option.label : value}</>;
};

export const VendorInput: React.FC<InputComponentProps<string>> = ({
  value,
  name,
  onBlur,
  onChange,
  disabled,
  error,
  helperText,
}: InputComponentProps) => (
  <SearchWrapper>
    <Autocomplete
      disableClearable
      options={vendorOptions}
      getOptionLabel={(option) => option.label}
      value={vendorOptions.find(({ value: key }) => key === value)}
      renderInput={renderAutocompleteInput(disabled, error, helperText)}
      disabled={disabled}
      onBlur={onBlur}
      onChange={(_, vendor: AutocompleteContents) => {
        // Note: this also blocks the user from manually clearing the field
        if (vendor?.value) {
          onChange(name)(vendor.value);
        }
      }}
      style={{ width: '100%' }}
    />
  </SearchWrapper>
);
