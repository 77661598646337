import { ChargingProfilePeriod } from '../../../../../../../utils/api';

export type Seconds = number;

export const solarExportLimit = -999;

export function generateSchedule(start: Seconds, end: Seconds, isSolarEnabled = false): ChargingProfilePeriod[] {
  const startSeconds = start;
  const endSeconds = end;
  const maxLimit = 32;
  const minLimit = isSolarEnabled ? solarExportLimit : 0;
  const schedule = [];
  if (startSeconds > endSeconds && endSeconds === 0) {
    // starts before midnight and ends at midnight e.g 5pm - 12am
    schedule.push({ start: 0, limit: minLimit });
    schedule.push({ start: startSeconds, limit: maxLimit });
  } else if (startSeconds > endSeconds && endSeconds !== 0) {
    // starts before midnight and ends after midnight 8pm - 6am
    schedule.push({ start: 0, limit: maxLimit });
    schedule.push({ start: endSeconds, limit: minLimit });
    schedule.push({ start: startSeconds, limit: maxLimit });
  } else if (startSeconds < endSeconds && startSeconds !== 0) {
    // starts after midnight and ends before midnight
    schedule.push({ start: 0, limit: minLimit });
    schedule.push({ start: startSeconds, limit: maxLimit });
    schedule.push({ start: endSeconds, limit: minLimit });
  } else if (startSeconds < endSeconds && startSeconds === 0) {
    // starts at midnight and ends after midnight
    schedule.push({ start: 0, limit: maxLimit });
    schedule.push({ start: endSeconds, limit: minLimit });
  } else if (startSeconds === endSeconds) {
    // same time
    schedule.push({ start: 0, limit: maxLimit });
  }
  return schedule;
}

export type SimplifiedSchedule = {
  startTime: Seconds;
  endTime: Seconds;
};

function simplifyScheduleWithLimit(args: { limit: number; periods: ChargingProfilePeriod[] }): SimplifiedSchedule {
  const { limit, periods } = args;
  if (periods.length === 2) {
    if (periods[0].limit !== limit && periods[1].limit === limit) {
      return { startTime: periods[0].start, endTime: periods[1].start };
    }
    if (periods[0].limit === limit && periods[1].limit !== limit) {
      return { startTime: periods[1].start, endTime: periods[0].start };
    }
  }

  if (periods.length === 3) {
    if (periods[0].limit !== limit && periods[1].limit === limit && periods[2].limit !== limit) {
      return { startTime: periods[2].start, endTime: periods[1].start };
    }
    if (periods[0].limit === limit && periods[1].limit !== limit && periods[2].limit === limit) {
      return { startTime: periods[1].start, endTime: periods[2].start };
    }
  }
  return { startTime: 0, endTime: 0 };
}

export function getScheduleStartAndEndTimes(args: {
  isSolarEnabled?: boolean;
  periods: ChargingProfilePeriod[];
}): SimplifiedSchedule {
  const { isSolarEnabled, periods } = args;
  if (periods.length === 1) {
    return { startTime: periods[0].start, endTime: periods[0].start };
  }

  const limit = isSolarEnabled ? solarExportLimit : 0;
  return simplifyScheduleWithLimit({ periods, limit });
}

export function scheduleFromChargingProfile(args: {
  chargingProfilePeriods?: ChargingProfilePeriod[];
  isSolarEnabled?: boolean;
}): SimplifiedSchedule {
  const { chargingProfilePeriods, isSolarEnabled } = args;
  if (typeof chargingProfilePeriods === 'undefined') {
    return { startTime: 0, endTime: 0 };
  }

  return getScheduleStartAndEndTimes({ isSolarEnabled, periods: chargingProfilePeriods });
}
