import { Fab } from '@material-ui/core';
import React, { createContext, useState, useContext, ReactChild } from 'react';
import ExploreIcon from '@material-ui/icons/Explore';
import ExploreOffIcon from '@material-ui/icons/ExploreOff';
import { useSelector } from 'react-redux';
import { fullStateSelector } from '../store/root';

const DeveloperModeContext = createContext({
  isDeveloperMode: false,
  toggleDeveloperMode: () => {},
});

export const useDeveloperMode = () => {
  const context = useContext(DeveloperModeContext);
  if (!context) {
    throw new Error('Use developer mode error');
  }
  return context;
};

export const DeveloperModeProvider = ({ children }: { children: ReactChild }) => {
  const { isEvnexAdmin } = useSelector(fullStateSelector);
  const [isDeveloperMode, setIsDeveloperMode] = useState(isEvnexAdmin);
  const toggleDeveloperMode = () => {
    if (!isEvnexAdmin) {
      setIsDeveloperMode(false);
    }
    setIsDeveloperMode(!isDeveloperMode);
  };

  return (
    <DeveloperModeContext.Provider value={{ isDeveloperMode, toggleDeveloperMode }}>
      {children}
      {isEvnexAdmin && (
        <Fab
          color={isDeveloperMode ? 'primary' : 'secondary'}
          onClick={toggleDeveloperMode}
          style={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: 1300,
          }}
        >
          {isDeveloperMode ? (
            <ExploreIcon data-testid="mode-on-icon" />
          ) : (
            <ExploreOffIcon data-testid="mode-off-icon" />
          )}
        </Fab>
      )}
    </DeveloperModeContext.Provider>
  );
};
