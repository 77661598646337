import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useGetOrganisation } from '../../../app/ApiGen';
import { useAuthenticatedUser } from '../../../app/AuthenticatedUserProvider';
import { DetailListItem } from '../../../components/DetailListItem';
import { DataErrorHandler } from '../../../components/ErrorHandler';
import { RouterLink } from '../../../components/Link';
import { Card, Loading, Workspace } from '../../../design-system';
import { fullStateSelector } from '../../../store/root';
import { normalise } from '../../../utils/request';
import ChargePointTable from './ChargePointTable';
import { UpdateMembership } from './UpdateMembership';
import { getRoleInOrganisation } from './util';

export const Overview: React.FC = () => {
  const {
    params: { slug },
  } = useRouteMatch<{ slug: string }>();

  const { loading, data, error, refetch } = useGetOrganisation({ orgSlug: slug });
  const { authenticatedUser } = useAuthenticatedUser();
  const { isSuperuser } = useSelector(fullStateSelector);

  if (error) {
    return <DataErrorHandler error={error} refetch={refetch} description="Unable to load organisation" />;
  }

  if (loading || !data) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  const organisationData = normalise(data);

  return (
    <>
      <Workspace maxWidth="lg">
        <Card>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box p={2} style={{ paddingBottom: '8px' }}>
                <Typography variant="h6">{organisationData.name}</Typography>
                <RouterLink to={`/organisations/${organisationData.slug}`}>View in context</RouterLink>
                <Divider style={{ margin: '8px 0px' }} />
                <DetailListItem attributeName="Role">
                  {getRoleInOrganisation(authenticatedUser, organisationData.id) ?? 'N/A'}
                </DetailListItem>
              </Box>
            </Grid>
            <Grid container item xs={12} md={6} alignItems="flex-end" justify="flex-end" style={{ padding: 20 }}>
              {isSuperuser && <UpdateMembership organisation={organisationData} user={authenticatedUser} />}
            </Grid>
          </Grid>
        </Card>
      </Workspace>

      <Workspace maxWidth="lg">
        <ChargePointTable organisation={organisationData} />
      </Workspace>
    </>
  );
};
