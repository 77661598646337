type NestedValue<Path extends string, Value> = Path extends keyof Value
  ? Value[Path]
  : Path extends `${infer K}.${infer Rest}`
  ? K extends keyof Value
    ? NestedValue<Rest, Value[K]>
    : never
  : Value;

/**
 * Fetches a nested key from an object.
 *
 * Examples:
 * - `getNestedKey('apples.color', { apples: { color: 'red' } })` => returns `'red'`
 * - `getNestedKey('apples', { apples: { color: 'red' } })` => returns `{ color: 'red' }`
 */
export function getNestedKey<Key extends string, Value>(keys: Key, object: Value): NestedValue<Key, Value> {
  const key = keys.split('.');

  return key.reduce<unknown>((value, propName) => {
    if (typeof value === 'object') {
      return (value as Record<string, unknown>)[propName];
    }
    return value;
  }, object) as NestedValue<Key, Value>;
}
