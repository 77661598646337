import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { ConfirmedGraphic } from '../../../../design-system';

export const CodeVerified = () => (
  <Fade in>
    <>
      <ConfirmedGraphic textAlign="center" svgProps={{ style: { maxWidth: '60%', height: '100%' } }} />
      <div>
        <Box mt={3} textAlign="center">
          <Typography>Thanks for verifying your email address.</Typography>
        </Box>
        <Box mt={2} textAlign="center">
          <Typography>Go to the Evnex app to sign in</Typography>
        </Box>
      </div>
    </>
  </Fade>
);
