import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import * as Yup from 'yup';
import { OrganisationSummary, useListOrganisations } from '../../../../../app/ApiGen';
import {
  AutocompleteContents,
  renderAutocompleteInput,
} from '../../../../../components/CustomFields/SimpleAutocomplete';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { WizardStep } from '../../../../../components/WizardStep';
import type { JobOrganisation, JobWizardState } from '../types';
import { createSchema, FormFields } from '../../../../../utils/formUtils';
import { sortByLocale } from '../../../../../utils/sortBy';
import { InputComponentProps, Loading, OnSubmit, useWizard } from '../../../../../design-system';

export type OrganisationDetails = Pick<JobWizardState, 'organisation'>;

const DEFAULT_ORG_PREFIX = 'Evnex';

const validationSchema = createSchema<OrganisationDetails>({
  organisation: Yup.object()
    .shape<JobOrganisation>({
      id: Yup.string().required(),
      name: Yup.string().required(),
      slug: Yup.string().required(),
    })
    .required(),
});

export const getOrganisationFields = (organisationOptions: JobOrganisation[]): FormFields<OrganisationDetails> => ({
  organisation: {
    id: 'organisation',
    label: 'Name',
    inputComponent: ({
      disabled,
      error: nameInputError,
      helperText,
      value,
      name,
      onChange,
    }: InputComponentProps<JobOrganisation>) => {
      const validationText = helperText ? 'Organisation is required' : undefined;
      return (
        <Autocomplete<JobOrganisation, false, true>
          disableClearable
          filterOptions={(options) => options.filter((option) => !!option.id)}
          fullWidth
          getOptionLabel={(org) => org.name}
          getOptionSelected={(option, org) => option.id === org.id}
          handleHomeEndKeys
          onChange={(event, selectedOrganisation) => {
            onChange(name)({ ...event, target: { ...event.target, value: selectedOrganisation } });
          }}
          options={organisationOptions}
          renderOption={(option) => <>{option.name}</>}
          renderInput={renderAutocompleteInput(disabled, nameInputError, validationText)}
          selectOnFocus
          value={value}
        />
      );
    },
    valueComponent: ({ value: organisation }: AutocompleteContents<JobOrganisation>) => <>{organisation.name}</>,
  },
});

const getSortedJobOrgs = (organisations: OrganisationSummary[]): JobOrganisation[] => {
  const jobOrganisations: JobOrganisation[] = organisations.map((org) => ({
    id: org.id,
    name: org.attributes.name,
    slug: org.attributes.slug,
  }));
  return jobOrganisations.sort(sortByLocale('name'));
};

const getOrgsWithDefaultPrefixPrioritised = (organisations: JobOrganisation[]): JobOrganisation[] => {
  const defaultOrgs = organisations.filter((org) => org.name.includes(DEFAULT_ORG_PREFIX));
  const otherOrgs = organisations.filter((org) => !org.name.includes(DEFAULT_ORG_PREFIX));
  return [...defaultOrgs, ...otherOrgs];
};

export const OrganisationStep: React.FC = () => {
  const { state: untypedState, setState: untypedSetState, prevStep, nextStep, step } = useWizard();
  const state = untypedState as Partial<JobWizardState>;
  const setState = untypedSetState as (state: Partial<JobWizardState>) => void;

  const onCancel = React.useCallback<OnSubmit<OrganisationDetails>>(
    (values) => {
      setState({ organisation: values.organisation });
      prevStep();
    },
    [setState, prevStep],
  );

  const onSubmit = React.useCallback<OnSubmit<OrganisationDetails>>(
    (values, { setFieldError }) => {
      setFieldError('name', undefined);
      setState({ organisation: values.organisation });
      nextStep();
    },
    [setState, nextStep],
  );

  const { data, error, loading, refetch } = useListOrganisations({});

  if (loading && !data) {
    return <Loading />;
  }

  if (error || !data) {
    return (
      <DataErrorHandler description="Unable to load organisations" error={error} refetch={refetch} type="embedded" />
    );
  }

  const sortedOrganisations = getOrgsWithDefaultPrefixPrioritised(getSortedJobOrgs(data.data));

  const initialOrganisation = state.organisation ?? sortedOrganisations[0];

  const initialValues: OrganisationDetails = {
    organisation: {
      id: initialOrganisation.id,
      name: initialOrganisation.name,
      slug: initialOrganisation.slug,
    },
  };

  return (
    <WizardStep<OrganisationDetails>
      fields={Object.values(getOrganisationFields(sortedOrganisations))}
      initialValues={initialValues}
      name="Organisation"
      onCancel={onCancel}
      onSubmit={onSubmit}
      step={step}
      subtitle="Select an organisation for the job to be created in."
      validationSchema={validationSchema}
    />
  );
};
