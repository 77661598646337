import format from 'date-fns/format';

/**
 * Locale-aware date formatting options for moment.js
 * @see https://momentjs.com/docs/#/displaying/format/
 */
export const DateFormat = {
  // 1986-09-24
  Filename: 'YYYY-MM-DD',

  // 24/09/1986
  Short: 'DD/MM/YYYY',

  // 24/9/1986
  ShortCondensed: 'D/M/YYYY',

  // September 24, 1986
  Long: 'MMMM D, YYYY',

  // Sep 24, 1986
  LongCondensed: 'MMM D, YYYY',
} as const;

export const TimeFormat = {
  // 8:30:25 PM
  WithSeconds: 'LTS',

  // 8:30 PM
  NoSeconds: 'LT',

  // 20:30:00
  TwentyFourHoursWithSeconds: 'HH:mm:ss',
} as const;

/**
 * Locale-aware date formatting options for date-fns
 * @see https://date-fns.org/v2.29.2/docs/format
 */
export const DateFormatDateFns: Record<keyof typeof DateFormat, string> = {
  // 1986-09-05
  Filename: 'yyyy-LL-dd',

  // September 5, 1986
  Long: 'MMMM d, yyyy',

  // Sep 5, 1986
  LongCondensed: 'MMM d, yyyy',

  // 05/09/1986
  Short: 'dd/LL/yyyy',

  // 5/9/1986
  ShortCondensed: 'd/L/yyyy',
};

export const TimeFormatDateFns: Record<keyof typeof TimeFormat, string> = {
  // 8:30:25 PM
  WithSeconds: 'h:mm:ss aa',

  // 8:30 PM
  NoSeconds: 'h:mm aa',

  // 20:30:00
  TwentyFourHoursWithSeconds: TimeFormat.TwentyFourHoursWithSeconds,
};

export const getCondensedDate = (date: string | Date): string =>
  format(new Date(date), `${DateFormatDateFns.LongCondensed} ${TimeFormatDateFns.NoSeconds}`);
