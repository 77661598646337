import styled from 'styled-components';

export const Statuses = styled('div')`
  height: ${(p) => p.theme.spacing(2.5)}px;
  display: flex;
  flex-flow: nowrap row;
  align-items: center;

  > * {
    margin-right: ${(p) => p.theme.spacing(1)}px;
  }
`;
