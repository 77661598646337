import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import * as React from 'react';
import { LegacyUser } from '../../../../app/ApiGen';
import { DateFormat, TimeFormat } from '../../../../utils/dateFormat';
import { pluralise } from '../../../../utils/format';

type MembershipCardProps = {
  user: LegacyUser;
};

export const MembershipCard: React.FC<MembershipCardProps> = ({ user }) => {
  const { name, email, createdDate, organisations } = user;

  return (
    <Box>
      <Typography variant="h6" style={{ wordBreak: 'break-word' }}>
        {name ?? email}
      </Typography>
      <Typography variant="body2" color="textSecondary" style={{ wordBreak: 'break-word' }} paragraph>
        {email}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        User created&nbsp;
        <span title={moment(createdDate).format(`${DateFormat.Long} ${TimeFormat.NoSeconds}`)}>
          {moment(createdDate).fromNow()}
        </span>
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Member of {pluralise(organisations.length, 'organisation')}
      </Typography>
    </Box>
  );
};
