import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import { Card, Loading, Workspace } from '../../../../../design-system';

export const Header: React.FC = () => {
  const { loading, organisation } = useCurrentOrganisation();

  if (loading || !organisation) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  const { name } = organisation;

  return (
    <Card>
      <Box p={2}>
        <Grid container spacing={2}>
          <Box>
            <Typography variant="h6" style={{ wordBreak: 'break-word' }}>
              {name}
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Card>
  );
};
