import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Section, Wizard, Workspace } from '../../../../design-system';
import type { OnWizardComplete } from '../../../../utils/wizard';
import { ConfirmationStep } from './components/ConfirmationStep';
import { JobDetailsStep } from './components/JobDetailsStep';
import { LocationStep } from './components/LocationStep';
import { OrganisationStep } from './components/OrganisationStep';
import { UserDetailsStep } from './components/UserDetailsStep';
import type { JobWizardState } from './types';

const CreateJobView: React.FC = () => {
  const { push } = useHistory();

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const redirectLocation = '/admin/jobs';

  const onComplete = React.useCallback<OnWizardComplete<JobWizardState>>(() => {
    setIsSubmitting(false);
  }, []);

  return (
    <Workspace maxWidth="md">
      <Section
        title="Create a job"
        description="Enter the information for a home installation job and create a new customer account and location"
      >
        <Card>
          <Wizard<JobWizardState>
            steps={[
              { id: 'organisation', label: 'Organisation Details', view: OrganisationStep },
              { id: 'userDetails', label: 'User Details', view: UserDetailsStep },
              { id: 'location', label: 'Location Details', view: LocationStep },
              { id: 'jobDetails', label: 'Job Details', view: JobDetailsStep },
              { id: 'confirm', label: 'Confirm', view: ConfirmationStep },
            ]}
            onComplete={onComplete}
            onExit={() => push(redirectLocation)}
            isLoading={isSubmitting}
            hasStepper
          />
        </Card>
      </Section>
    </Workspace>
  );
};

export default CreateJobView;
