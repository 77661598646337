import Box, { BoxProps } from '@material-ui/core/Box';
import * as React from 'react';
import styled from 'styled-components';

const StyledAction = styled(Box)`
  display: flex;
  flex-flow: nowrap row;
`;

export type FormActionsProps = BoxProps;

export const FormActions: React.FC<FormActionsProps> = ({ children, ...rest }) => (
  <StyledAction p={2} {...rest}>
    {children}
  </StyledAction>
);
