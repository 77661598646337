import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import * as React from 'react';
import { InputComponentProps, ValueComponentProps } from '../../design-system';
import { AutocompleteInput, AutocompleteValue } from './SimpleAutocomplete';

countries.registerLocale(en);

export const CountryValue: React.FC<ValueComponentProps<string>> = ({ value }) => (
  <AutocompleteValue label={countries.getName(value, 'en')} value={value} />
);

const countryNames = countries.getNames('en');
const countryOptions = Object.keys(countryNames).map((key) => ({ label: countryNames[key], value: key }));

export const CountryInput: React.FC<InputComponentProps<string>> = ({
  disabled,
  error,
  helperText,
  name,
  onBlur,
  onChange,
  value,
}: InputComponentProps<string>) => (
  <AutocompleteInput<string>
    disabled={disabled}
    error={error}
    helperText={helperText}
    name={name}
    options={countryOptions}
    onBlur={onBlur}
    onChange={onChange}
    value={value}
  />
);
