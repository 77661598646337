import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import * as React from 'react';
import { uid } from 'react-uid';
import styled from 'styled-components';

const SelectWrapper = styled('div')`
  margin: auto 0;
  max-height: 24px;
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  color: ${(p) => p.theme.palette.text.secondary};
  border-bottom: 2px solid transparent;
  cursor: pointer;
  outline: none;
  user-select: none;

  &:focus {
    color: ${(p) => p.theme.palette.text.primary};
    border-bottom: 2px solid ${(p) => p.theme.palette.primary.main};
  }
`;

const IconWrapper = styled('span')`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
`;

export interface DateLookbackSelectProps {
  options: { id: number; label: string }[];
  selected: number;
  onSelect: (id: number) => void;
}

export const DateLookbackSelect: React.FC<DateLookbackSelectProps> = ({ options, selected, onSelect }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(undefined);

  const openOptions = React.useCallback((e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget), []);
  const closeOptions = React.useCallback(() => setAnchorEl(undefined), []);

  const selectOption = React.useCallback(
    (id: number) => {
      onSelect(id);
      closeOptions();
    },
    [onSelect, closeOptions],
  );

  const selectedItem = options.find(({ id }) => id === selected);

  return (
    <>
      <SelectWrapper onClick={openOptions} tabIndex={0}>
        <Typography component="span" variant="body2">
          {selectedItem?.label}
        </Typography>
        <IconWrapper>
          <ArrowDropDownIcon />
        </IconWrapper>
      </SelectWrapper>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={closeOptions}>
        {options.map((opt, idx) => (
          <MenuItem key={uid(opt, idx)} selected={opt.id === selected} onClick={() => selectOption(opt.id)} dense>
            {opt.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
