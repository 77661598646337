import { useSnackbar } from 'notistack';
import React from 'react';
import { GetDataError } from 'restful-react';
import * as Yup from 'yup';
import { Location, ResponseApiErrors, useGetOrganisationLocations } from '../../../../../../../app/ApiGen';
import { LocationInput, LocationValue } from '../../../../../../../components/CustomFields/Location';
import { SwitchValue } from '../../../../../../../components/CustomFields/Switch';
import { errorSnackbar } from '../../../../../../../components/ErrorSnackbar';
import { Card, Form, OnSubmit } from '../../../../../../../design-system';
import { ChargePointUpdate, LocationInfo, usePutChargePoint } from '../../../../../../../utils/api';
import { FormFields } from '../../../../../../../utils/formUtils';
import { normalise, Normalised } from '../../../../../../../utils/request';
import { useChargePoint } from '../../Provider';
import { TokenRequiredSwitchInput } from './customFormFields/TokenRequiredSwitchInput';

interface ChargePointDetails {
  name: string;
  locationId: string;
  tokenRequired: boolean;
  referenceId: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required').min(3).max(80),
  locationId: Yup.string().required('Location is required'),
  referenceId: Yup.string()
    .min(1)
    .max(80)
    .matches(
      /^[a-zA-Z0-9-_. ]{0,80}$/,
      'Reference ID must only include numbers, letters, ".", "-", "_", or " " characters.',
    ),
});

const getFormFields = (args: {
  organisationSlug: string;
  location: LocationInfo | undefined;
  locations: Normalised<Location>[] | undefined;
  locationsLoading: boolean;
  locationsError: GetDataError<ResponseApiErrors> | null;
}): FormFields<ChargePointDetails> => {
  const { location, locations, locationsLoading, locationsError, organisationSlug } = args;
  return {
    name: { id: 'name', label: 'Name' },
    locationId: {
      id: 'locationId',
      label: 'Location',
      valueComponent: () => <LocationValue value={location} organisationSlug={organisationSlug} />,
      inputComponent: ({
        disabled,
        error: inputError,
        helperText,
        value,
        name: inputName,
        multiple,
        checked,
        onChange,
        onBlur,
      }) => (
        <LocationInput
          disabled={disabled}
          error={inputError}
          helperText={helperText}
          value={value}
          name={inputName}
          multiple={multiple}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          dataLoading={locationsLoading}
          dataError={locationsError}
          options={locations}
        />
      ),
    },
    tokenRequired: {
      id: 'tokenRequired',
      label: 'Authorisation required',
      valueComponent: SwitchValue,
      inputComponent: TokenRequiredSwitchInput,
      inputProps: { helperText: 'Whether a valid RFID card must be swiped before a charging session can begin' },
    },
    referenceId: { id: 'referenceId', label: 'Reference ID' },
  };
};

export const DetailsForm: React.FC<{ orgSlug: string; chargePointId: string }> = ({ chargePointId, orgSlug }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { chargePoint, loading, refetch } = useChargePoint();
  const { loading: locationsLoading, error: locationsError, data } = useGetOrganisationLocations({
    orgSlug,
  });
  const locations = data ? normalise(data) : undefined;
  const updateChargePoint = usePutChargePoint({ chargePointId });

  const [isEditing, setIsEditing] = React.useState(false);

  const formFields = getFormFields({
    organisationSlug: orgSlug,
    location: chargePoint?.location,
    locations,
    locationsLoading,
    locationsError,
  });

  const initialValues: ChargePointDetails = {
    name: chargePoint?.name ?? '',
    locationId: chargePoint?.location?.id ?? '',
    tokenRequired: chargePoint?.tokenRequired ?? !chargePoint?.configuration?.plugAndCharge, // TODO: CPLINK-1544 Remove config post migration
    referenceId: chargePoint?.metadata?.referenceId ?? '',
  };

  React.useEffect(() => {
    if (updateChargePoint.error) {
      errorSnackbar('Unable to edit charge point details', closeSnackbar, enqueueSnackbar, updateChargePoint.error);
    }
  }, [closeSnackbar, enqueueSnackbar, updateChargePoint.error]);

  const onSubmit = React.useCallback<OnSubmit<ChargePointDetails>>(
    async (update, { setSubmitting }) => {
      try {
        const unchangedElectricityCost: ChargePointUpdate['electricityCost'] = chargePoint?.electricityCost;
        await updateChargePoint.mutate({
          name: update.name,
          locationId: update.locationId,
          // TODO: CPLINK-1544 Remove post migration
          configuration: { plugAndCharge: !update.tokenRequired },
          maxCurrent: chargePoint?.maxCurrent,
          tokenRequired: update.tokenRequired,
          metadata: { referenceId: update.referenceId },
          electricityCost: unchangedElectricityCost,
        });
        enqueueSnackbar('Charge point updated', { variant: 'success' });
        setIsEditing(false);
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [chargePoint?.electricityCost, chargePoint?.maxCurrent, enqueueSnackbar, refetch, updateChargePoint],
  );

  return (
    <Card title="Details">
      <Form<ChargePointDetails>
        fields={Object.values(formFields)}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        isLoading={loading}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </Card>
  );
};
