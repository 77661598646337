import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { RouterLink } from '../../../../components/Link';

export interface UnsupportedChallengeProps {
  challenge: string;
}

export const UnsupportedChallenge: React.FC<UnsupportedChallengeProps> = ({ challenge }) => (
  <Fade in>
    <div>
      <Box textAlign="center">
        <Typography>
          An unknown or unsupported challenge ({challenge}) was initiated after login. Please contact support to resolve
          this issue.
        </Typography>
      </Box>
      <Box mt={2} textAlign="center">
        <RouterLink to="/auth/signin">Go to sign in screen</RouterLink>
      </Box>
    </div>
  </Fade>
);
