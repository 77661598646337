import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCreateOrganisationUser } from '../../../../app/ApiGen';
import { errorSnackbar } from '../../../../components/ErrorSnackbar';
import { Card, Form, OnSubmit, Section, Workspace } from '../../../../design-system';
import { standardiseError } from '../../../../utils/error';
import { UserCreateDetails, userCreateFields, userCreateValidation } from '../../../../utils/users/FieldDefinitions';

const CreateUserView: React.FC = () => {
  const { slug: orgSlug } = useParams<{ slug: string }>();
  const { push } = useHistory();
  const { mutate: inviteUser } = useCreateOrganisationUser({ orgSlug });
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const initialValues: UserCreateDetails = { email: '', name: '' };

  const onSubmit = React.useCallback<OnSubmit<UserCreateDetails>>(
    async (values, { setSubmitting, setFieldError }) => {
      try {
        setFieldError('email', undefined);
        setSubmitting(true);
        const { data } = await inviteUser({ data: { type: 'users', attributes: values } });
        setSubmitting(false);
        enqueueSnackbar('New user account created', { variant: 'success' });
        enqueueSnackbar(`Invitation email sent to ${data.attributes.email}`, { variant: 'success' });
        push(`/organisations/${orgSlug}/users`);
      } catch (e) {
        const error = standardiseError(e);
        if (error.title === 'AlreadyExistsError') {
          setFieldError('email', 'User with this email address already exists');
        } else {
          errorSnackbar('Unable to create user account', closeSnackbar, enqueueSnackbar, e);
        }
        setSubmitting(false);
      }
    },
    [closeSnackbar, enqueueSnackbar, inviteUser, orgSlug, push],
  );

  return (
    <Workspace>
      <Section
        title="Invite new user to organisation"
        description="Send an invitation email for a customer to create a new Evnex account. The new user will be added
        to this organisation as a Driver."
      >
        <Card>
          <Form<UserCreateDetails>
            fields={Object.values(userCreateFields)}
            initialValues={initialValues}
            isEditing
            labels={{ save: 'Invite user' }}
            onSubmit={onSubmit}
            validationSchema={userCreateValidation}
          />
        </Card>
      </Section>
    </Workspace>
  );
};

export default CreateUserView;
