import type { ScatterDataPoint } from 'chart.js';
import { LLMServerModel } from './LLMServerModel';

export class LLMSharedLimitModel extends LLMServerModel {
  getDatum(): ScatterDataPoint {
    return {
      x: new Date(this.serverEventDate).getTime(),
      y: this.server.SharedLimit !== undefined ? this.server.SharedLimit : 0,
    };
  }
}
