import { all, call, Effect, fork, put, takeLatest } from 'redux-saga/effects';
import { standardiseError } from '../../utils/error';
import { findIncludedResource, normalise } from '../../utils/request';
import { getCurrentUserAction, setCurrentUserAction, setUserErrorAction } from './actions';
import { getCurrentUser, Response } from './api';
import { UserAction, UserState } from './types';

function normaliseUser(response: Response): NonNullable<UserState['current']> {
  const user = normalise(response);

  const memberships = user.memberships.map((membership) => {
    const organisation = findIncludedResource(response.included, {
      id: membership.id.split('_')[0],
      type: 'organisations',
    });
    return { ...membership, organisation: organisation ? normalise({ data: organisation }) : undefined };
  });

  return { ...user, memberships };
}

export function* handleGetCurrentUser(action: ReturnType<typeof getCurrentUserAction>): Generator<Effect> {
  try {
    const response = (yield call(getCurrentUser, action.payload.id)) as Response;
    yield put(setCurrentUserAction(normaliseUser(response)));
  } catch (e) {
    const error = standardiseError(e);
    yield put(setUserErrorAction(error));
  }
}

function* watchGetCurrentUser() {
  yield takeLatest(UserAction.GET_CURRENT_USER, handleGetCurrentUser);
}

export function* userSagas(): Generator<Effect> {
  yield all([fork(watchGetCurrentUser)]);
}
