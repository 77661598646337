import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useSelector } from 'react-redux';
import { Section, Workspace } from '../../../../../../design-system';
import { fullStateSelector } from '../../../../../../store/root';
import { DeleteLocation } from './components/DeleteLocation';
import { DetailsForm } from './components/DetailsForm';

const SettingsView: React.FC = () => {
  const { isAdmin, isEvnexAdmin } = useSelector(fullStateSelector);
  return (
    <Workspace>
      <Section title="Location settings" description="Details and settings for this location.">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DetailsForm />
          </Grid>
        </Grid>
      </Section>
      <Box pt={3} />
      {(isAdmin || isEvnexAdmin) && (
        <Section title="Warning" description="This process cannot be undone.">
          <DeleteLocation />
        </Section>
      )}
    </Workspace>
  );
};

export default SettingsView;
