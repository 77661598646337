import {
  CreateChargePointWizardState,
  CreateThirdPartyChargePointWizardState,
} from '../../views/Organisations/Organisation/Create/CreateChargePoint/types';
import { ChargePointClaim } from '../api';
import { RequestCreateThirdPartyChargePoint } from '../../app/ApiGen';

export function mapWizardStateToRequestCreateEvnexChargePoint(args: {
  organisationId: string;
  state: CreateChargePointWizardState;
}): ChargePointClaim {
  const { organisationId, state } = args;
  const { authorizationRequired, cost, currency, location, name, referenceId, serial } = state;
  return {
    configuration: { plugAndCharge: !authorizationRequired },
    electricityCost: { costs: [{ start: 0, cost }], currency, duration: 86400 },
    locationId: location.id,
    metadata: { referenceId },
    name,
    organisationId,
    serial,
    tokenRequired: authorizationRequired,
  };
}

export function mapWizardStateToRequestCreateThirdPartyChargePoint(
  state: CreateThirdPartyChargePointWizardState,
): RequestCreateThirdPartyChargePoint {
  const { authorizationRequired, connectors, cost, currency, location, name, ocppChargePointId, referenceId } = state;
  return {
    data: {
      attributes: {
        connectors: connectors.map(({ amperage, connectorFormat, connectorType, powerType, voltage }) => ({
          connectorFormat,
          connectorType,
          maxAmperage: amperage,
          maxVoltage: voltage,
          powerType,
        })),
        electricityCost: {
          cost,
          currency,
          tariffType: 'Flat',
          tariffs: [{ rate: cost.toString(), start: 0, type: 'Flat' }],
        },
        name,
        ocppChargePointId: `${ocppChargePointId.prefix}-${ocppChargePointId.suffix}`,
        referenceId,
        tokenRequired: authorizationRequired,
      },
      relationships: { location: { data: { id: location.id, type: 'locations' } } },
      type: 'chargePoints',
    },
  };
}
