import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Organisation, useListOrganisations } from '../../../app/ApiGen';
import { useAuthenticatedUser } from '../../../app/AuthenticatedUserProvider';
import { labelsFor, OrganisationTier } from '../../../app/enums';
import { ColumnFiltersToggle, getToolbarSearch, RefreshButton } from '../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../components/ErrorHandler';
import { TableLink } from '../../../components/Link';
import { navigateOnRowClick } from '../../../utils/dataTable/rowHelpers';
import { normalise, Normalised } from '../../../utils/request';
import { sortByLocale } from '../../../utils/sortBy';
import { getRoleInOrganisation } from './util';
import { ExportCsv, ExportProps } from '../../../components/ExportCsv';
import OrganisationReportButton from '../../Organisations/Organisation/OrganisationReportButton';
import { ColumnChanger, ColumnChangerProps, DataTable, Workspace } from '../../../design-system';

type TableOrganisation = Omit<Normalised<Organisation>, 'role' | 'tier'> & { role: string; tier: string };

const getOrganisationUrl = (organisation: TableOrganisation) => `/admin/organisations/${organisation.slug}`;

const csvTitle = 'organisations';

export const List: React.FC = () => {
  const [filtering, setFiltering] = React.useState(false);

  const history = useHistory();
  const { loading, error, data, refetch } = useListOrganisations({});
  const { authenticatedUser } = useAuthenticatedUser();

  if (error) {
    return <DataErrorHandler refetch={refetch} description="Unable to load organisations" error={error} />;
  }

  const organisations = data ? normalise(data) : [];
  const tableOrganisations = organisations.map((organisation) => ({
    ...organisation,
    role: getRoleInOrganisation(authenticatedUser, organisation.id),
    tier: labelsFor(OrganisationTier)[organisation.tier],
  }));

  return (
    <Workspace maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <DataTable<TableOrganisation>
              isLoading={loading}
              options={{ columnsButton: true, filtering }}
              toolbarProps={{
                ...getToolbarSearch('organisations'),
                actions: (props: React.PropsWithChildren<ColumnChangerProps> & ExportProps<TableOrganisation>) => (
                  <>
                    <ColumnChanger
                      columnsButton={props.columnsButton}
                      columns={props.columns}
                      icons={props.icons}
                      onColumnsChanged={props.onColumnsChanged}
                    />
                    <ColumnFiltersToggle filtering={filtering} setFiltering={setFiltering} />
                    <RefreshButton refetch={refetch} />
                    <ExportCsv<TableOrganisation>
                      columns={props.columns}
                      data={props.data}
                      exportFileName={csvTitle}
                      getFieldValue={props.getFieldValue}
                      icons={props.icons}
                    />
                    <Button component={Link} to="/admin/create/organisation" color="primary" variant="contained">
                      Create organisation
                    </Button>
                  </>
                ),
              }}
              columns={[
                {
                  field: 'name',
                  title: 'Name',
                  defaultSort: 'asc',
                  customSort: sortByLocale('name'),
                  render: (organisation) => (
                    <TableLink to={getOrganisationUrl(organisation)}>{organisation.name}</TableLink>
                  ),
                },
                { field: 'tier', title: 'Tier' },
                { field: 'role', title: 'Role' },
                { field: 'chargePoints.length', title: 'Charge Points' },
              ]}
              data={tableOrganisations}
              onRowClick={navigateOnRowClick((organisation) => getOrganisationUrl(organisation), history)}
            />
          </Card>
        </Grid>
        {!loading && (
          <Grid item xs={12}>
            <OrganisationReportButton organisations={organisations} />
          </Grid>
        )}
      </Grid>
    </Workspace>
  );
};
