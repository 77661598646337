import { Chart, Plugin } from 'chart.js';

export function updateChartZoomLocations<T extends string>(
  selectedChart: { chart: Chart },
  chartIds: Record<T, string>,
): void {
  const { xScale } = selectedChart.chart.getDatasetMeta(0);
  if (xScale) {
    Object.keys(chartIds).forEach((id) => {
      const chartId = chartIds[id as T];
      const chart = Chart.getChart(chartId);
      if (chart?.config.options?.scales?.x) {
        chart.config.options.scales.x.min = xScale.min;
        chart.config.options.scales.x.max = xScale.max;
        chart.update();
      }
    });
  }
}

export const verticalSelectorLinePlugin: Plugin<'line'> = {
  id: 'verticalSelectorLinePlugin',
  afterDraw: (chart: Chart) => {
    const activeElements = chart.getActiveElements()[0];
    const xValue = activeElements?.element.x;
    const yScale = chart.scales.y;
    if (xValue && yScale) {
      const { ctx } = chart;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(xValue, yScale.top);
      ctx.lineTo(xValue, yScale.bottom);
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.restore();
    }
  },
};
