import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { RouterLink } from '../../../../components/Link';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export interface SignInFormProps {
  hasCreateAccount?: boolean;
  hasForgotPassword?: boolean;
  onSubmit: (values: typeof initialValues, helpers: FormikHelpers<typeof initialValues>) => void;
}

export const SignInForm: React.FC<SignInFormProps> = ({ hasForgotPassword, hasCreateAccount, onSubmit }) => (
  <Fade in>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <Box>
            <Field name="email">
              {({ field }: FieldProps) => (
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  autoFocus
                  error={!!errors.email}
                  helperText={errors.email}
                  disabled={isSubmitting}
                  {...field}
                />
              )}
            </Field>
          </Box>
          <Box mt={2}>
            <Field name="password">
              {({ field }: FieldProps) => (
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth
                  type="password"
                  error={!!errors.password}
                  helperText={errors.password}
                  disabled={isSubmitting}
                  {...field}
                />
              )}
            </Field>
          </Box>
          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary" size="large" fullWidth disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress size={26} color="inherit" /> : 'Sign in'}
            </Button>
          </Box>
          {hasCreateAccount && (
            <Box mt={3} textAlign="center">
              <RouterLink to="/auth/signup" color="textSecondary">
                Don&apos;t have an account? <Typography color="primary">Sign Up</Typography>
              </RouterLink>
            </Box>
          )}
          {hasForgotPassword && (
            <Box mt={2} textAlign="center">
              <RouterLink to="/auth/forgot-password" fontWeight="bold" color="primary">
                Forgot password?
              </RouterLink>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  </Fade>
);
