import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useNavigationView } from '../../../design-system';
import { mainNav } from '../../navView';
import { List } from './List';
import { Overview } from './Overview';

export const OrganisationsView: React.FC = () => {
  const { path } = useRouteMatch();
  useNavigationView(mainNav);

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <Overview />
      </Route>

      <Route>
        <List />
      </Route>
    </Switch>
  );
};
