import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useNavigationView } from '../../../design-system';
import { mainNav } from '../../navView';
import UserView from './User';
import UsersListView from './UsersList';

const UsersView: React.FC = () => {
  const { path } = useRouteMatch();
  useNavigationView(mainNav);

  return (
    <Switch>
      <Route path={`${path}/:userId`}>
        <UserView />
      </Route>

      <Route>
        <UsersListView />
      </Route>
    </Switch>
  );
};

export default UsersView;
