import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteLoadBalancingGroup } from '../../../../../../../app/ApiGen';
import { DangerButton } from '../../../../../../../components/DangerButton';
import { errorSnackbar } from '../../../../../../../components/ErrorSnackbar';
import { Card } from '../../../../../../../design-system';

export const DeleteLoadBalancingGroup: React.FC<{
  loadBalancingGroupId: string;
  orgSlug: string;
}> = ({ loadBalancingGroupId, orgSlug }) => {
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { mutate } = useDeleteLoadBalancingGroup({});

  const onDelete = React.useCallback(() => {
    setLoading(true);
    mutate(loadBalancingGroupId)
      .then(() => {
        enqueueSnackbar('Load balancing group deleted', { variant: 'success' });
        push(`/organisations/${orgSlug}/load-balancing-groups`);
      })
      .catch((error) => {
        errorSnackbar('Unable to delete load balancing group', closeSnackbar, enqueueSnackbar, error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [closeSnackbar, enqueueSnackbar, loadBalancingGroupId, mutate, orgSlug, push]);

  return (
    <Card title="Delete load balancing group">
      <Box p={2} pt={0}>
        <Typography paragraph>
          This will permanently delete the load balancing group and its associated data.
        </Typography>

        <DangerButton loading={loading} onClick={onDelete} label="Delete load balancing group" />
      </Box>
    </Card>
  );
};
