import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import React from 'react';
import styled from 'styled-components';

interface WrappedAvatarProps extends AvatarProps {
  bgColor?: string;
}

const WrappedAvatar: React.FC<WrappedAvatarProps> = React.forwardRef(
  ({ bgColor, ...rest }: WrappedAvatarProps, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading -- There are too many props to reasonably avoid spreading.
    <Avatar variant="rounded" {...rest} ref={ref} />
  ),
);

WrappedAvatar.defaultProps = { bgColor: undefined };

export const CardAvatar = styled(WrappedAvatar)`
  ${(p) => (p.bgColor ? `background-color: #${`${p.bgColor}`.substring(0, 6)};` : '')}
  outline: none;
`;
