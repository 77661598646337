import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { FormReadonly, Section, useWizard } from '../../../../../../design-system';
import { locationFields } from '../../../../../../utils/locations/FieldDefinitions';

export const ConfirmationStep: React.FC = () => {
  const { state, prevStep, nextStep, isLoading } = useWizard();

  return (
    <>
      <Section title="Location details">
        <FormReadonly fields={Object.values(locationFields)} initialValues={state} />
      </Section>
      <Box pt={2} display="flex">
        <Button variant="contained" onClick={prevStep} disabled={isLoading}>
          Back
        </Button>
        <Box mr="auto" />
        <Button color="primary" variant="contained" onClick={nextStep} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Create location'}
        </Button>
      </Box>
    </>
  );
};
