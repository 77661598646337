import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { VictoryAxis, VictoryChart, VictoryLine } from 'victory';
import { VictoryAxisProps } from 'victory-axis';
import { errorSnackbar } from '../../../../../../components/ErrorSnackbar';
import { Card, Field, List, ListItem, Section, Workspace } from '../../../../../../design-system';
import { usePutChargePointLoadManagement } from '../../../../../../utils/api';
import { useChargePoint } from '../Provider';

const SECONDS_IN_HOUR = 60 * 60;

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(25, minmax(46.7px, 1fr));
`;

export const StyledInput = styled.div<{ children: React.ReactNode }>`
  display: flex;
  flex-flow: nowrap column;
  align-items: center;
`;

export const Label = styled(Typography)`
  margin-bottom: 4px;
  font-size: 12px;
`;

export const Input = styled(TextField)<{ $isInvalid?: boolean }>`
  > * {
    box-shadow: ${(props) => (props.$isInvalid ? `0 0 0px 2px ${props.theme.palette.error.dark};` : 'none;')}
    border-radius: 50%;
  }

  > *::before,
  > *::after {
    display: none;
  }

  input {
    padding: 0;
    width: 32px;
    height: 32px;
    font-size: 14px;
    text-align: center;
  }
`;

export const Chart = styled('div').withConfig({ shouldForwardProp: (prop) => !['isDisabled'].includes(prop) })<{
  isDisabled: boolean;
}>`
  margin-left: -16px;
  min-width: 1184px;
  max-height: 220px;
  opacity: ${(p) => (p.isDisabled ? 0.6 : 1)};
`;

const ChartGradient = () => {
  const theme = useTheme();

  return (
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stopColor={theme.palette.primary.dark} />
        <stop offset="100%" stopColor={theme.palette.primary.main} />
      </linearGradient>
    </defs>
  );
};

// Is the limit within the valid bounds.
const getIsLimitInvalid = (limit: number, lower: number, upper: number) =>
  (limit > 0 && limit < lower) || limit > upper;

// Return a limit within the valid bounds.
const getSafeLimit = (limit: number, lower: number, upper: number) => {
  if (limit > 0 && limit < lower) {
    return lower;
  }

  if (limit > upper) {
    return upper;
  }

  return limit;
};

const getAxisStyle = ({ palette, typography }: DefaultTheme): VictoryAxisProps['style'] => ({
  axis: {
    stroke: palette.grey[500],
  },
  grid: {
    stroke: ({ index }) => (index !== 0 ? palette.grey[300] : ''),
    strokeDasharray: 6,
    strokeDashoffset: 6,
  },
  tickLabels: {
    padding: 1,
    fill: palette.grey[500],
    fontSize: 4,
    fontFamily: typography.fontFamily,
  },
});

const LoadManagementView: React.FC = () => {
  const theme = useTheme();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { chargePoint, refetch } = useChargePoint();

  const loadManagementPeriods = React.useMemo(() => chargePoint?.loadManagement?.chargingProfilePeriods ?? [], [
    chargePoint,
  ]);

  const { loading, mutate } = usePutChargePointLoadManagement({ chargePointId: chargePoint?.id ?? '' });

  // TODO - limits will come from the charge point's context.
  const currentLowerLimit = 6;
  const currentUpperLimit = 32;

  const [isEnabled, setIsEnabled] = React.useState(chargePoint?.loadManagement?.enabled ?? false);
  const [profile, setProfile] = React.useState<{ start: number; limit: number }[]>([]);

  // Creates the profile state from the data on load.
  React.useEffect(() => {
    let limit = currentUpperLimit;

    const schema = [...Array<unknown>(24)].map((_, idx) => {
      const start = idx * SECONDS_IN_HOUR;
      const indexOfLimit = loadManagementPeriods.findIndex(({ start: hourStart }) => hourStart === start);

      if (indexOfLimit !== -1) {
        limit = loadManagementPeriods[indexOfLimit].limit;
      }

      return { start, limit };
    });

    setProfile(schema);
  }, [loadManagementPeriods]);

  // Creates a plottable set of data for the chart to display.
  const plottableProfile = React.useMemo(() => {
    const plottable = profile.map(({ start, limit }) => ({
      x: start,
      y: getSafeLimit(limit, currentLowerLimit, currentUpperLimit),
    }));

    const last = plottable[plottable.length - 1];

    if (last) {
      plottable.push({ x: last.x + SECONDS_IN_HOUR, y: plottable[0].y });
    }

    return plottable;
  }, [profile]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { id, value } = event.target;

      const newLimit = value === '' ? 0 : parseInt(value, 10);

      const match = profile.findIndex(({ start }) => start === parseInt(id, 10));

      const newProfile = [...profile];
      newProfile[match] = { ...profile[match], limit: newLimit };

      setProfile(newProfile);
    },
    [profile],
  );

  const handleBlur = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { id, value } = event.target;

      const newLimit = value === '' ? 0 : parseInt(value, 10);

      if (getIsLimitInvalid(newLimit, currentLowerLimit, currentUpperLimit)) {
        const match = profile.findIndex(({ start }) => start === parseInt(id, 10));

        const newProfile = [...profile];
        newProfile[match] = {
          ...profile[match],
          limit: getSafeLimit(newLimit, currentLowerLimit, currentUpperLimit),
        };

        setProfile(newProfile);
      }
    },
    [profile],
  );

  const onSaveProfile = React.useCallback(() => {
    let limit: number | undefined;

    const saveProfile = profile.filter((step) => {
      if (limit === undefined || limit !== step.limit) {
        limit = step.limit;
        return true;
      }

      return false;
    });

    mutate({
      enabled: isEnabled,
      units: 'A',
      duration: 86400,
      chargingProfilePeriods: saveProfile,
    })
      .then(() => {
        enqueueSnackbar('Load management updated', { variant: 'success' });
        refetch();
      })
      .catch((err) => {
        errorSnackbar('Unable to set load management profile', closeSnackbar, enqueueSnackbar, err);
      });
  }, [closeSnackbar, isEnabled, enqueueSnackbar, profile, mutate, refetch]);

  return (
    <Workspace maxWidth="lg">
      <Section
        title="Load management"
        description="Configure a profile to control the maximum output of a charge point."
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Box p={2}>
                <Box>
                  <List inset>
                    <ListItem>
                      <Field label="Profile enabled">
                        <FormControl>
                          <Switch
                            id="enabled"
                            color="primary"
                            checked={isEnabled}
                            onChange={({ target: { checked } }) => setIsEnabled(checked)}
                          />
                        </FormControl>
                      </Field>
                    </ListItem>
                  </List>
                </Box>
                <Box mt={2} mb={3}>
                  <Divider />
                </Box>
                <Box style={{ overflowY: 'hidden', overflowX: 'auto' }}>
                  <div style={{ minWidth: '880px' }}>
                    <InputsGrid>
                      {profile.map((hour, idx) => (
                        <StyledInput
                          // If we use react-uid here, the `hour` reference changes once every keypress and the input is
                          // re-rendered, making the user lose focus on it.
                          // eslint-disable-next-line react/no-array-index-key -- Can't use react-uid here
                          key={`${idx}_${hour.start}`}
                        >
                          <Label variant="body2" color="textSecondary">
                            {moment.utc(hour.start * 1000).format('ha')}
                          </Label>
                          <Input
                            id={`${hour.start}`}
                            value={hour.limit}
                            disabled={!isEnabled}
                            // We use `tel` type because `number` type introduces a browser input stepper and arrow key
                            // control which then allows the user to input negative values... and then things blow up.
                            type="tel"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size="small"
                            margin="none"
                            variant="filled"
                            $isInvalid={getIsLimitInvalid(hour.limit, currentLowerLimit, currentUpperLimit)}
                          />
                        </StyledInput>
                      ))}
                    </InputsGrid>
                    <Chart isDisabled={!isEnabled}>
                      <VictoryChart height={80} padding={{ top: 8, right: 8, bottom: 8, left: 16 }}>
                        <ChartGradient />
                        <VictoryAxis
                          standalone={false}
                          style={getAxisStyle(theme)}
                          tickValues={[...Array<unknown>(25)].map((_, idx) => idx * SECONDS_IN_HOUR)}
                          tickFormat={(t) => moment.utc(t * 1000).format('ha')}
                        />
                        <VictoryAxis
                          dependentAxis
                          orientation="left"
                          standalone={false}
                          style={getAxisStyle(theme)}
                          tickValues={[0, 8, 16, 24, 32]}
                          tickFormat={(t: string) => `${t}A`}
                        />
                        <VictoryLine
                          data={plottableProfile}
                          domain={{ x: [0, SECONDS_IN_HOUR * 24], y: [0, 32] }}
                          interpolation="stepAfter"
                          scale={{ x: 'time', y: 'linear' }}
                          style={{ data: { stroke: 'url(#gradient)', strokeWidth: 2 } }}
                        />
                      </VictoryChart>
                    </Chart>
                  </div>
                </Box>
                <Box mt={2}>
                  <Button onClick={onSaveProfile} disabled={loading} variant="contained" color="primary">
                    {loading ? <CircularProgress size={26} color="inherit" /> : 'Save profile'}
                  </Button>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Section>
    </Workspace>
  );
};

export default LoadManagementView;
