import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { clearCurrentCardAction, getCurrentCardAction } from '../../../../../store/card/actions';
import { fullStateSelector } from '../../../../../store/root';
import type { StoreContextProps } from '../../../../../store/types';
import { NOT_FOUND_API_ERROR_STATUS } from '../../../../../utils/error';

export const useCard: () => StoreContextProps<'card'> = () => {
  const {
    card: { loading, error, current, selectedItemId: selectedCardId },
  } = useSelector(fullStateSelector);

  const dispatch = useDispatch();

  const {
    params: { id: cardId, slug: organisationSlug },
  } = useRouteMatch<{ slug: string; id: string }>();

  const refetch = React.useCallback(() => {
    if (current) {
      dispatch(clearCurrentCardAction());
    }
    dispatch(getCurrentCardAction(cardId, organisationSlug));
  }, [cardId, current, dispatch, organisationSlug]);

  React.useEffect(() => {
    if (!cardId) {
      // Route params are not available in some contexts, such as nav views.
      return;
    }

    if (selectedCardId !== cardId) {
      dispatch(clearCurrentCardAction());
      dispatch(getCurrentCardAction(cardId, organisationSlug));
    }
  }, [cardId, current, dispatch, loading, organisationSlug, selectedCardId]);

  return { loading, error, card: current, refetch, selectedItemId: selectedCardId };
};

const context = React.createContext<StoreContextProps<'card'>>({ loading: false, refetch: () => {} });
export const CardProvider: React.FC = ({ children }) => {
  const cardState = useCard();

  const {
    params: { id: cardId, slug },
  } = useRouteMatch<{ slug: string; id: string }>();

  const { error, selectedItemId: selectedCardId } = cardState;
  if (error?.status === NOT_FOUND_API_ERROR_STATUS && cardId === selectedCardId) {
    return <Redirect to={`/organisations/${slug}/not-found`} />;
  }
  return <context.Provider value={cardState}>{children}</context.Provider>;
};
