import { rgba } from 'polished';
import styled from 'styled-components';

export const SkeletonChart = styled('div')`
  height: calc(100% - 56px - 47px - 192px);
  min-height: 128px;
  display: flex;
  justify-content: center;
`;

export const ChartWrapper = styled('div')`
  display: flex;
  flex-flow: nowrap column;
  flex: 1 1 auto;
`;

export const Counts = styled('div')`
  padding: ${(p) => p.theme.spacing(2)}px;
  display: flex;
  flex-flow: wrap row;
  align-items: center;
`;

export const Count = styled('div')`
  padding: ${(p) => p.theme.spacing(1)}px;
  flex: 1 1 50%;
  max-width: 50%;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  display: flex;
  flex-flow: nowrap row;
  font-weight: ${(p) => p.theme.typography.fontWeightLight};
  outline: none;
  user-select: none;

  &:focus,
  &:hover {
    background-color: ${(p) => rgba(p.theme.palette.evnex.main, 0.03)};
  }
`;

export const Icon = styled('div')`
  margin-right: ${(p) => p.theme.spacing(1)}px;
  display: flex;
  align-items: center;
`;

export const Label = styled('div')`
  font-size: 12px;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

export const Value = styled('div')`
  font-size: 14px;
  line-height: 24px;
`;
