import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { rgba } from 'polished';
import styled from 'styled-components';

export const OrganisationSwitcherWrapper = styled('div')`
  padding: ${(p) => p.theme.spacing(1)}px;
  padding-top: ${(p) => p.theme.spacing(0)}px;
  width: 100%;
`;

export const AvatarWrapper = styled('div')<{ isCollapsed: boolean }>`
  margin-right: ${(p) => p.theme.spacing(p.isCollapsed ? 0 : 1.5)}px;
  opacity: 0.83;
`;

export const Text = styled('div')``;

export const Primary = styled(Typography)`
  max-width: calc(244px - 32px - 56px);
  color: ${(p) => p.theme.palette.secondary.contrastText};
  font-size: 14px;
  font-weight: ${(p) => p.theme.typography.fontWeightMedium};
  line-height: 18px;
  opacity: 0.83;
`;

export const Secondary = styled(Typography)`
  max-width: calc(244px - 32px - 56px);
  color: ${(p) => p.theme.palette.secondary.contrastText};
  font-size: 12px;
  line-height: 16px;
  opacity: 0.6;
`;

export const DropdownPaper = styled(Paper)`
  min-width: 240px;
  max-height: 320px;
  overflow: auto;
`;

export const StyledOrganisationDropdown = styled('div')`
  padding: ${(p) => p.theme.spacing(1)}px;
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color ${(p) => p.theme.transitions.easing.easeInOut}
      ${(p) => p.theme.transitions.duration.short}ms,
    opacity ${(p) => p.theme.transitions.easing.easeInOut} ${(p) => p.theme.transitions.duration.short}ms;
  outline: none;

  :hover,
  :focus {
    background-color: ${(p) => rgba(p.theme.palette.common.white, 0.13)};

    ${AvatarWrapper},
    ${Primary} {
      opacity: 1;
    }

    ${Secondary} {
      opacity: 0.83;
    }
  }
`;
