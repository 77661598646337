import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import * as React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { Address as AddressType } from '../../utils/api';
import { getAddressAsString } from '../../utils/locations/address';

export const Multiline = styled('div')`
  display: flex;
  flex-flow: nowrap column;
`;

countries.registerLocale(en);

export type AddressProps = {
  multiline?: boolean;
  address: AddressType;
};

export const Address: React.FC<AddressProps> = ({ multiline, address }: AddressProps) => {
  const { address1, address2, address3, city, state, postCode, country } = address;

  if (multiline) {
    return (
      <Multiline>
        <span>{address1}</span>
        {address2 && <span>{address2}</span>}
        {address3 && <span>{address3}</span>}
        {city && <span>{city}</span>}
        {state && <span>{state}</span>}
        <span>{postCode}</span>
        <span>{countries.getName(country, 'en')}</span>
      </Multiline>
    );
  }

  return <span title={getAddressAsString(address)}>{getAddressAsString(address)}</span>;
};

export const singleLineAddress = ({ address }: { address: AddressType }): JSX.Element => (
  <Typography style={{ fontSize: 'inherit' }}>
    <Address address={address} />
  </Typography>
);
