import styled from 'styled-components';

export const StyledHeaderSection = styled('div')`
  padding: ${(p) => p.theme.spacing(0, 2)};
  height: 72px;
  min-height: 72px;
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  box-sizing: content-box;
`;
