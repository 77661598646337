import CreditCardIcon from '@material-ui/icons/CreditCard';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uid } from 'react-uid';
import { CardAvatar } from '../../../../../components/Avatar/Card';
import { ReloadContainerNavItem } from '../../../../../components/ReloadContainerNavItem';
import {
  ContainerHeader,
  ContainerHeaderLoading,
  HeaderSection,
  Item,
  ItemLoading,
  MenuSection,
  NavigationView,
  Separator,
} from '../../../../../design-system';
import { getCurrentCardAction } from '../../../../../store/card/actions';
import { fullStateSelector } from '../../../../../store/root';

const Items: React.FC = () => {
  const {
    card: { current, error, loading },
    organisation: { current: organisation, loading: orgLoading },
  } = useSelector(fullStateSelector);

  const dispatch = useDispatch();

  const refetchCard = React.useCallback(() => {
    if (current && organisation) {
      dispatch(getCurrentCardAction(current.id, organisation.slug));
    }
  }, [current, organisation, dispatch]);

  const ReloadButton = () => <ReloadContainerNavItem loading={loading} descriptor="card" onClick={refetchCard} />;

  if (loading || orgLoading) {
    return (
      <>
        <HeaderSection>{() => <ContainerHeaderLoading avatar subText />}</HeaderSection>
        <Separator mb={1} />
        <MenuSection canOverflow>
          {() =>
            Array(2)
              .fill(undefined)
              .map((_, idx) => <ItemLoading key={uid(idx)} />)
          }
        </MenuSection>
      </>
    );
  }

  if (!current || !organisation) {
    return (
      <>
        <HeaderSection>
          {() => (
            <ContainerHeader
              avatar={
                <CardAvatar>
                  <CreditCardIcon />
                </CardAvatar>
              }
              text={current?.name ?? 'Unknown'}
              subText="Card"
            />
          )}
        </HeaderSection>
      </>
    );
  }

  if (error) {
    return (
      <>
        <HeaderSection>
          {() => (
            <ContainerHeader
              avatar={
                <CardAvatar>
                  <CreditCardIcon />
                </CardAvatar>
              }
              text={current?.name ?? 'Unknown'}
              subText="Card"
            />
          )}
        </HeaderSection>
        <Separator mb={1} />
        <ReloadButton />
      </>
    );
  }

  return (
    <>
      <HeaderSection>
        {() => (
          <ContainerHeader
            avatar={
              <CardAvatar>
                <CreditCardIcon />
              </CardAvatar>
            }
            text={current.name}
            subText="Card"
          />
        )}
      </HeaderSection>
      <Separator mb={1} />
      <MenuSection canOverflow>
        {() => (
          <>
            <Item text="Overview" to={`/organisations/${organisation.slug}/cards/${current.id}/overview`} />
            <Item text="Settings" to={`/organisations/${organisation.slug}/cards/${current.id}/settings`} />
          </>
        )}
      </MenuSection>
      <ReloadButton />
    </>
  );
};

export const cardNav: NavigationView = {
  id: 'card/container',
  type: 'container',
  Items,
};
