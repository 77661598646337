import {
  BarElement,
  CategoryScale,
  Chart,
  ChartDataset,
  ChartOptions,
  LinearScale,
  registerables,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTheme } from 'styled-components';
import { ResetZoomButton } from '../../../../../../../components/ResetZoomButton';
import { Section } from '../../../../../../../design-system';
import { ChargePointLog } from '../../../../../../../utils/api';
import { updateChartZoomLocations } from '../../../../../../../utils/chart';
import { ConnectionChartData, getWebsocketChartDataset } from '../utils/connectionChart';
import { CHART_DATE_FORMAT, diagnosticsChartIds, DiagnosticsChartType } from '../utils/meteringCharts';

Chart.register(BarElement, CategoryScale, LinearScale, TimeScale, zoomPlugin, ...registerables);

const connectionTooltipDisplay = (data: ConnectionChartData) => {
  const [startTime, endedDate] = data.x;
  return `${data.type} - ${new Date(startTime).toLocaleTimeString()} - ${new Date(endedDate).toLocaleTimeString()}`;
};

const chartOptions = (startingDate: string, endDate: string): ChartOptions<'bar'> => ({
  maintainAspectRatio: false,
  indexAxis: 'y',
  elements: { bar: { borderWidth: 2 } },
  scales: {
    x: { type: 'time', time: { tooltipFormat: CHART_DATE_FORMAT }, min: startingDate, max: endDate },
    y: {
      afterFit(scale) {
        // eslint-disable-next-line no-param-reassign -- -- Reassigning the `scale` param's properties is the accepted way to set dimensions in ChartJS
        scale.width = 100;
      },
      ticks: { crossAlign: 'near' },
      afterSetDimensions: (scale) => {
        // eslint-disable-next-line no-param-reassign -- Reassigning the `scale` param's properties is the accepted way to set dimensions in ChartJS
        scale.maxWidth = 100;
      },
    },
  },
  interaction: { intersect: false, mode: 'index', axis: 'x' },
  animation: false,
  plugins: {
    legend: { display: false },
    tooltip: {
      callbacks: {
        label(context) {
          const data = context.raw as ConnectionChartData;
          return connectionTooltipDisplay(data);
        },
      },
      mode: 'nearest',
    },
    zoom: {
      pan: { modifierKey: 'ctrl', enabled: true, mode: 'x' },
      zoom: {
        onZoomComplete: (selectedChart) =>
          updateChartZoomLocations<DiagnosticsChartType>(selectedChart, diagnosticsChartIds),
        mode: 'x',
        drag: { enabled: true },
      },
    },
  },
});

export const ConnectionChart: React.FC<{ data: ChargePointLog[]; startingDate: string; endDate: string }> = ({
  data,
  startingDate,
  endDate,
}) => {
  const { palette } = useTheme();
  const dataset: ChartDataset<'bar', ConnectionChartData[]> = {
    label: 'Online',
    data: getWebsocketChartDataset(data, new Date()),
    backgroundColor: (context) => {
      const index = context.dataIndex;
      const value = (context.dataset.data[index] as unknown) as ConnectionChartData;
      return value?.type === 'Connection' ? palette.cyan.main : palette.stone.main;
    },
  };

  return (
    <>
      <ResetZoomButton<DiagnosticsChartType>
        chartIds={diagnosticsChartIds}
        minXValue={startingDate}
        maxXValue={endDate}
      />
      <Section style={{ height: '20vh', position: 'relative' }}>
        <Bar
          id={diagnosticsChartIds.connection}
          options={chartOptions(startingDate, endDate)}
          data={{ datasets: [dataset] }}
        />
      </Section>
    </>
  );
};
