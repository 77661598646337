import { rgba } from 'polished';
import styled from 'styled-components';

export const StyledCard = styled('div')`
  display: flex;
  flex-flow: nowrap column;
  background-color: ${(p) => p.theme.palette.background.paper};
  border-radius: 5px;
  box-shadow: ${(p) => p.theme.shadows.subtle};
`;

export const CardTitle = styled('div')`
  padding: ${(p) => p.theme.spacing(2)}px;
  font-weight: ${(p) => p.theme.typography.fontWeightLight};
  letter-spacing: 0.025em;
  line-height: 24px;
`;

export const CardFooter = styled('div')`
  padding: ${(p) => p.theme.spacing(1, 2)};
  display: flex;
  flex-flow: nowrap row;
  border-top: 1px solid ${(p) => rgba(p.theme.palette.evnex.main, 0.13)};
`;
