import Box, { BoxProps } from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import styled from 'styled-components';

const LoadingRoot = styled(Box)`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  justify-content: center;
`;

export interface LoadingProps extends BoxProps {
  size?: number;
}

const Loading: React.FC<LoadingProps> = ({ size = 64, ...other }) => (
  <LoadingRoot {...other}>
    <CircularProgress size={size} />
  </LoadingRoot>
);

Loading.displayName = 'Loading';

export { Loading };
