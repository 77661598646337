import { DialogProps } from '../../../../../../../components/Dialog';

export enum ConnectorCommand {
  ChargeNow,
  CancelChargeNow,
}

export interface ConnectorCommandProps {
  chargePointId: string;
  connectorId: string;
}

export type ChargeNowDialogProps = ConnectorCommandProps & DialogProps;
