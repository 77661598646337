import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EvStationIcon from '@material-ui/icons/EvStation';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useSnackbar } from 'notistack';
import React from 'react';
import { uid } from 'react-uid';
import { DefaultTheme, useTheme } from 'styled-components';
import { ChargePoint, Connector, useGetChargePoint } from '../../../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../../../app/CurrentOrganisationProvider';
import { ConnectorItem } from '../../../../../../../components/ConnectorItem';
import { errorSnackbar } from '../../../../../../../components/ErrorSnackbar';
import { Card, EvnexPalette, List, ListItem } from '../../../../../../../design-system';
import { Normalised } from '../../../../../../../utils/request';
import { CommandAction } from './commands/Action';
import { useConnectorCommands } from './commands/Provider';
import { StartChargingDialog } from './commands/StartChargingDialog';

type ConnectorListProps = {
  chargePoint: Normalised<ChargePoint>;
  refetch: ReturnType<typeof useGetChargePoint>['refetch'];
};

export const ConnectorList: React.FC<ConnectorListProps> = ({ chargePoint: initialChargePoint, refetch }) => {
  const { palette }: DefaultTheme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { organisation } = useCurrentOrganisation();
  const [chargePoint] = React.useState(initialChargePoint);
  const [startDialogOpen, setStartDialogOpen] = React.useState(false);
  const [startConnectorId, setConnectorId] = React.useState(1);

  const { connectors } = chargePoint ?? { connectors: [] };

  const {
    anyLoading,
    stopLoading,
    startLoading,
    unlockLoading,
    availabilityLoading,
    error,
    stopMutate,
    startMutate,
    unlockMutate,
    availabilityMutate,
  } = useConnectorCommands();

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to send command to charge point', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  function compareConnectors(a: Connector, b: Connector) {
    const bandA = a.connectorId;
    const bandB = b.connectorId;

    if (bandA > bandB) {
      return 1;
    }
    if (bandA < bandB) {
      return -1;
    }
    return 0;
  }
  const orderedConnectors = connectors.sort(compareConnectors);
  const { enabled }: EvnexPalette = palette;

  return (
    <Card title="Connectors">
      <List>
        {orderedConnectors.map((connector, idx) => {
          const { ocppStatus }: Connector = connector;

          const connectorId = parseInt(connector.connectorId, 10);

          const canStart =
            chargePoint.networkStatus === 'ONLINE' &&
            ['AVAILABLE', 'CHARGING', 'PREPARING', 'RESERVED', 'SUSPENDED_EV', 'SUSPENDED_EVSE'].includes(ocppStatus);
          const canStop =
            chargePoint.networkStatus === 'ONLINE' &&
            ['CHARGING', 'SUSPENDED_EV', 'SUSPENDED_EVSE'].includes(ocppStatus);

          return (
            <ListItem key={uid(connector, idx)}>
              <ConnectorItem
                networkStatus={chargePoint.networkStatus}
                connector={connector}
                actions={
                  <>
                    <StartChargingDialog
                      isOpen={startDialogOpen}
                      onClose={() => setStartDialogOpen(false)}
                      id={startConnectorId}
                      orgSlug={organisation?.slug ?? ''}
                      onSubmit={(tokenUid: string) => {
                        startMutate({ connectorId: startConnectorId, tokenUid })
                          .then(async ({ data }) => {
                            setStartDialogOpen(false);
                            enqueueSnackbar(`Start charging command ${data.status.toLowerCase()}`);
                            await new Promise((done) => setTimeout(done, 1500));
                            await refetch();
                          })
                          .catch((e) => {
                            setStartDialogOpen(false);
                            errorSnackbar('Failed to send Remote Start command', closeSnackbar, enqueueSnackbar, e);
                          });
                      }}
                    />
                    <CommandAction
                      color={canStop ? palette.red.dark : enabled.main}
                      icon={<EvStationIcon />}
                      label={canStop ? 'Stop' : 'Charge'}
                      isDisabled={anyLoading || (!canStop && !canStart)}
                      isLoading={stopLoading || startLoading}
                      onClick={() => {
                        if (canStop) {
                          stopMutate({ connectorId })
                            .then(async ({ data }) => {
                              enqueueSnackbar(`Stop charging command ${data.status.toLowerCase()}`);
                              await new Promise((done) => setTimeout(done, 1500));
                              await refetch();
                            })
                            .catch((e) => {
                              errorSnackbar('Failed to send Remote Stop command', closeSnackbar, enqueueSnackbar, e);
                            });
                        } else {
                          setConnectorId(connectorId);
                          setStartDialogOpen(true);
                        }
                      }}
                    />
                    <CommandAction
                      color={palette.steel.light}
                      icon={<LockOpenIcon />}
                      label="Unlock"
                      isDisabled={anyLoading || chargePoint.networkStatus === 'OFFLINE'}
                      isLoading={unlockLoading}
                      onClick={() => {
                        unlockMutate({ connectorId })
                          .then(async ({ data }) => {
                            enqueueSnackbar(`Unlock command ${data.status.toLowerCase()}`);
                            await new Promise((done) => setTimeout(done, 1500));
                            await refetch();
                          })
                          .catch((e) => {
                            errorSnackbar('Failed to send Unlock command', closeSnackbar, enqueueSnackbar, e);
                          });
                      }}
                    />
                    <CommandAction
                      color={ocppStatus === 'UNAVAILABLE' ? palette.cyan.main : palette.steel.light}
                      icon={ocppStatus === 'UNAVAILABLE' ? <CheckCircleOutlineIcon /> : <BlockIcon />}
                      label={ocppStatus === 'UNAVAILABLE' ? 'Re-enable' : 'Disable'}
                      isDisabled={anyLoading || chargePoint.networkStatus === 'OFFLINE'}
                      isLoading={availabilityLoading}
                      onClick={() => {
                        availabilityMutate({
                          connectorId,
                          changeAvailabilityType: ocppStatus === 'UNAVAILABLE' ? 'Operative' : 'Inoperative',
                        })
                          .then(async ({ data }) => {
                            enqueueSnackbar(`Change availability command ${data.status.toLowerCase()}`);
                            await new Promise((done) => setTimeout(done, 1500));
                            await refetch();
                          })
                          .catch((e) => {
                            errorSnackbar(
                              'Failed to send Change availability command',
                              closeSnackbar,
                              enqueueSnackbar,
                              e,
                            );
                          });
                      }}
                    />
                  </>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
};
