import { ChargePoint, ChargePointLog } from '../../../../../../../utils/api';
import type { LLMServer } from '../../../../../../../utils/chargePoints/diagnostics';

export type LocalLoadManagementConfiguration =
  | { mode: 'Unknown' | 'StandAlone' | 'Server' }
  | {
      mode: 'Client';
      isOnline: boolean;
      server: {
        id: string;
        ocppChargePointId: string;
      };
    };

export type LocalLoadManagementResponse =
  | { Mode: 'StandAlone' }
  | {
      Mode: 'Client';
      OfflineMode: boolean;
      ServerName: string;
    }
  | LLMServer;

export type DataTransferLog = Omit<ChargePointLog, 'event'> & { event: 'DataTransfer' };

const minimumFirmwareVersion = '1.3.0';

export const isLlmSupported = (chargePoint: ChargePoint['details']): boolean => {
  const { firmware, model, vendor } = chargePoint;
  if (model && firmware) {
    const isEXSeries = model.startsWith('E') || model.startsWith('X');
    if (vendor === 'Evnex' && isEXSeries && firmware >= minimumFirmwareVersion) {
      return true;
    }
  }
  return false;
};
