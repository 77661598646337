import { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';
import { Label, StyledDetail, Value } from './styled';

export type DetailProps = {
  label?: React.ReactNode;
  labelProps?: TypographyProps<'div'>;
  valueProps?: TypographyProps<'div'>;
};

export const Detail: React.FC<DetailProps> = ({ label, labelProps, valueProps, children }) => (
  <StyledDetail>
    <Label color="textSecondary" {...labelProps}>
      {label}
    </Label>
    <Value {...valueProps}>{children}</Value>
  </StyledDetail>
);
