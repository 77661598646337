import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CurrentOrganisationProvider } from '../../../app/CurrentOrganisationProvider';
import { useNavigationView } from '../../../design-system';
import { fullStateSelector } from '../../../store/root';
import AddView from './Add';
import CardsView from './Cards';
import ChargePointsView from './ChargePoints';
import CreateView from './Create';
import LoadBalancingView from './LoadBalancing';
import LocationsView from './Locations';
import MyOrganisationView from './MyOrganisation';
import { organisationNav } from './navView';
import NotFoundView from './NotFound';
import OverviewView from './Overview';
import ReportsView from './Reports';
import UpgradeView from './Upgrade';
import UsersView from './Users';

const OrganisationView: React.FC = () => {
  const { path, url } = useRouteMatch();
  const { isAdmin, isEvnexAdmin, isFleetOperator } = useSelector(fullStateSelector);

  useNavigationView(organisationNav);

  return (
    <CurrentOrganisationProvider>
      <Switch>
        <Route path={`${path}/charge-points`}>
          <ChargePointsView />
        </Route>
        <Route path={`${path}/locations`}>
          <LocationsView />
        </Route>
        <Route path={`${path}/organisation`}>
          <MyOrganisationView />
        </Route>
        <Route path={`${path}/overview`}>
          <OverviewView />
        </Route>
        <Route path={`${path}/upgrade`}>
          <UpgradeView />
        </Route>
        <Route path={`${path}/not-found`}>
          <NotFoundView />
        </Route>
        {(isEvnexAdmin || isAdmin) && (
          <Route path={`${path}/cards`}>
            <CardsView />
          </Route>
        )}
        {(isEvnexAdmin || isAdmin) && (
          <Route path={`${path}/create`}>
            <CreateView />
          </Route>
        )}
        {(isEvnexAdmin || isAdmin || isFleetOperator) && (
          <Route path={`${path}/reports`}>
            <ReportsView />
          </Route>
        )}
        {(isEvnexAdmin || isAdmin) && (
          <Route path={`${path}/load-balancing-groups`}>
            <LoadBalancingView />
          </Route>
        )}
        {isEvnexAdmin && (
          <Route path={`${path}/add`}>
            <AddView />
          </Route>
        )}
        {isEvnexAdmin && (
          <Route path={`${path}/users`}>
            <UsersView />
          </Route>
        )}
        <Route>
          <Redirect to={`${url}/overview`} />
        </Route>
      </Switch>
    </CurrentOrganisationProvider>
  );
};

export default OrganisationView;
