import React from 'react';
import { useSnackbar } from 'notistack';
import { ReloadContainerNavItem } from '../../../../../../../components/ReloadContainerNavItem';
import { useGetChargePoint } from '../../../../../../../app/ApiGen';
import { errorSnackbar } from '../../../../../../../components/ErrorSnackbar';

type Props = {
  loading: boolean;
  refetch: ReturnType<typeof useGetChargePoint>['refetch'];
};

export const ReloadButton: React.FC<Props> = ({ loading, refetch }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onRefresh = React.useCallback(() => {
    refetch().catch((err) => {
      errorSnackbar('Unable to refresh charge point data', closeSnackbar, enqueueSnackbar, err);
    });
  }, [closeSnackbar, enqueueSnackbar, refetch]);

  return <ReloadContainerNavItem loading={loading} descriptor="charge point" onClick={onRefresh} />;
};
