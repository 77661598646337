import { all, call, Effect, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getCurrentOrganisationUserAction,
  setCurrentOrganisationUserAction,
  setOrganisationUserErrorAction,
} from './actions';
import { getOrganisationMember, Response } from './api';
import { OrganisationUserAction, OrganisationUserState } from './types';
import { standardiseError } from '../../utils/error';
import { UserSummary } from '../../app/ApiGen';

function toOrganisationUser(membershipResponse: Response): NonNullable<OrganisationUserState['current']> {
  const {
    data: { attributes, id, relationships },
    included,
  } = membershipResponse;
  const { createdDate, isDefault, role, updatedDate } = attributes;
  const user: UserSummary = included[0];
  return {
    id: user.id,
    ...user.attributes,
    membership: {
      createdDate,
      id,
      isDefault,
      organisationId: relationships.organisation.data.id,
      role,
      updatedDate,
    },
  };
}

export function* handleGetCurrentOrganisationUser(
  action: ReturnType<typeof getCurrentOrganisationUserAction>,
): Generator<Effect> {
  try {
    const response: Response = (yield call(
      getOrganisationMember,
      action.payload.organisationSlug,
      action.payload.userId,
    )) as Response;
    yield put(setCurrentOrganisationUserAction(toOrganisationUser(response)));
  } catch (e) {
    const error = standardiseError(e);
    yield put(setOrganisationUserErrorAction(error));
  }
}

function* watchGetCurrentOrganisationUser() {
  yield takeLatest(OrganisationUserAction.GET_CURRENT_ORGANISATION_USER, handleGetCurrentOrganisationUser);
}

export function* organisationUserSagas(): Generator<Effect> {
  yield all([fork(watchGetCurrentOrganisationUser)]);
}
