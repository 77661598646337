import { ChargePointSummary, Location } from '../../app/ApiGen';
import { Normalised } from '../request';
import { chargePointHasAttributes, locationHasAttributes } from '../format';

export const getChargePoints = (location: Normalised<Location> | undefined): Normalised<ChargePointSummary>[] => {
  if (!location || !locationHasAttributes(location)) {
    return [];
  }
  return [...location.chargePoints].filter((chargePoint): chargePoint is Normalised<ChargePointSummary> =>
    chargePointHasAttributes(chargePoint),
  );
};
