import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  useCreateOrganisationMembership,
  useListOrganisationMembers,
  useListUsers,
  UserSummary,
} from '../../../../../app/ApiGen';
import { UserAvatar } from '../../../../../components/Avatar/User';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { errorSnackbar } from '../../../../../components/ErrorSnackbar';
import { SelectableItem, SelectionList } from '../../../../../components/SelectionList';
import { FormActions, Loading } from '../../../../../design-system';
import type { SubState } from '../../../../../store/types';
import { getSelectableUsers } from '../../../../../utils/users/form';

interface SelectUserContext {
  allUsers: UserSummary[];
  organisationMembers: UserSummary[];
  organisation: SubState<'organisation'>;
}

const SelectUser: React.FC<SelectUserContext> = ({ allUsers, organisationMembers, organisation }) => {
  const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>([]);
  const [submitting, setSubmitting] = React.useState(false);
  const { push } = useHistory();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { id: orgId, slug: orgSlug } = organisation;
  const addUserMembership = useCreateOrganisationMembership({ orgSlug });
  const onUserSelect = React.useCallback(
    (user: SelectableItem) => {
      setSelectedUserIds([user.id]);
    },
    [setSelectedUserIds],
  );

  const addDriversToOrg = React.useCallback(async () => {
    await Promise.all(
      selectedUserIds.map(async (userId) => {
        await addUserMembership.mutate({
          data: {
            relationships: {
              organisation: {
                data: {
                  id: orgId,
                  type: 'organisations',
                },
              },
              user: {
                data: {
                  id: userId,
                  type: 'users',
                },
              },
            },
            attributes: {
              isDefault: false,
              role: 2,
            },
            type: 'memberships',
          },
        });
      }),
    );
  }, [addUserMembership, orgId, selectedUserIds]);

  const onAddUser = React.useCallback(() => {
    setSubmitting(true);
    addDriversToOrg()
      .then(() => {
        setSubmitting(false);
        enqueueSnackbar('User added to organisation', { variant: 'success' });
        push(`/organisations/${orgSlug}/users`);
      })
      .catch((e) => {
        setSubmitting(false);
        errorSnackbar('Unable to add user to organisation', closeSnackbar, enqueueSnackbar, e);
      });
  }, [addDriversToOrg, closeSnackbar, enqueueSnackbar, orgSlug, push]);

  const selectableUsers: SelectableItem[] = getSelectableUsers(allUsers, organisationMembers);

  return (
    <SelectionList
      getImage={({ title, color, image }) => <UserAvatar embedded user={{ name: title, color, avatar: image }} />}
      items={selectableUsers}
      label="user"
      onSelectItem={onUserSelect}
      upperActions={
        <Button
          component={Link}
          to={`/organisations/${orgSlug}/create/user`}
          color="primary"
          variant={selectableUsers.length > 0 ? 'outlined' : 'contained'}
        >
          Invite new user
        </Button>
      }
      lowerActions={
        <FormActions>
          <Button
            color="primary"
            variant="contained"
            disabled={submitting || selectedUserIds.length === 0}
            onClick={onAddUser}
          >
            {submitting ? <CircularProgress size={24} color="inherit" /> : 'Add user'}
          </Button>
        </FormActions>
      }
    />
  );
};

export const SelectUserStep: React.FC<{ organisation: SubState<'organisation'> }> = ({ organisation }) => {
  const { slug: orgSlug } = organisation;
  const allUsers = useListUsers({});
  const organisationMembers = useListOrganisationMembers({ orgSlug });

  if (allUsers.loading || organisationMembers.loading) {
    return <Loading />;
  }

  if (!allUsers.data || !organisationMembers.data || allUsers.error || organisationMembers.error) {
    return (
      <>
        {(allUsers.error || !allUsers.data) && (
          <DataErrorHandler description="Unable to load all users" error={allUsers.error} refetch={allUsers.refetch} />
        )}
        {(organisationMembers.error || !organisationMembers.data) && (
          <DataErrorHandler
            description="Unable to load organisation users"
            error={organisationMembers.error}
            refetch={organisationMembers.refetch}
          />
        )}
      </>
    );
  }

  return (
    <SelectUser
      allUsers={allUsers.data.data}
      organisationMembers={organisationMembers.data.included}
      organisation={organisation}
    />
  );
};
