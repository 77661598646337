import { ChargePointSessionSummary } from './chargePointSessionSummary';

function getTotalCarbonUsage(summary: ChargePointSessionSummary) {
  const totalCarbonUsage = summary?.totalCarbonUsage ?? '0';
  return parseInt(totalCarbonUsage.replace(/,/g, ''), 10);
}

export function sortByTotalCarbonUsage(a: ChargePointSessionSummary, b: ChargePointSessionSummary) {
  const first = getTotalCarbonUsage(a);
  const second = getTotalCarbonUsage(b);

  if (first < second) {
    return -1;
  }

  if (second < first) {
    return 1;
  }

  return 0;
}
