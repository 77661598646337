import {
  CategoryScale,
  Chart,
  ChartDataset,
  ChartOptions,
  LinearScale,
  LineElement,
  registerables,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { useTheme } from 'styled-components';
import { ResetZoomButton } from '../../../../../../../components/ResetZoomButton';
import { EvnexPalette, Section } from '../../../../../../../design-system';
import { ChargePointLog } from '../../../../../../../utils/api';
import { updateChartZoomLocations } from '../../../../../../../utils/chart';
import { CHART_DATE_FORMAT, diagnosticsChartIds, DiagnosticsChartType } from '../utils/meteringCharts';

Chart.register(LineElement, CategoryScale, LinearScale, TimeScale, zoomPlugin, ...registerables);

const eventTypes = [
  'Authorize',
  'BootNotification',
  'ChangeAvailability',
  'ChangeConfiguration',
  'ClearCache',
  'ClearChargingProfile',
  'DataTransfer',
  'DiagnosticsStatusNotification',
  'FirmwareStatusNotification',
  'GetCompositeSchedule',
  'GetConfiguration',
  'GetDiagnostics',
  'Heartbeat',
  'MeterValues',
  'RemoteStartTransaction',
  'RemoteStopTransaction',
  'Reset',
  'SetChargingProfile',
  'StartTransaction',
  'StatusNotification',
  'StopTransaction',
  'UnlockConnector',
  'UpdateFirmware',
  'WebSocketClose',
  'WebSocketConnection',
];

export const getMeterChartData = (insights: ChargePointLog[], palette: EvnexPalette): ChartDataset<'line'>[] => {
  const orderedEvents = insights.sort((a, b) => {
    if (a.serverEventDate < b.serverEventDate) {
      return -1;
    }
    if (a.serverEventDate > b.serverEventDate) {
      return 1;
    }
    return 0;
  });

  const chartData: ChartDataset<'line'>[] = [];
  const RESPONSE_PADDING = 0.5;

  eventTypes.forEach((eventType, eventIndex) => {
    const relevantEvents = orderedEvents.filter((eventValue) => eventValue.event.startsWith(eventType));
    if (relevantEvents.length > 0) {
      const dataTo = relevantEvents.map((event) => ({
        x: new Date(event.serverEventDate).getTime(),
        y: event.event.endsWith('Resp') ? eventIndex : eventIndex + RESPONSE_PADDING,
        eventDetails: event.data,
      }));

      chartData.push({
        label: eventType,
        data: dataTo,
        backgroundColor: palette.tussock.main,
        borderColor: palette.tussock.contrastText,
        pointHoverRadius: 20,
      });
    }
  });

  return chartData;
};

const chartOptions = (startingDate: string, endDate: string): ChartOptions<'line'> => ({
  maintainAspectRatio: false,
  parsing: false,
  showLine: false,
  scales: {
    x: { type: 'time', time: { tooltipFormat: CHART_DATE_FORMAT }, min: startingDate, max: endDate },
    y: {
      min: 0,
      max: eventTypes.length,
      afterFit(scale) {
        // eslint-disable-next-line no-param-reassign -- -- Reassigning the `scale` param's properties is the accepted way to set dimensions in ChartJS
        scale.width = 100;
      },
      ticks: {
        callback: (tickValue) => (typeof tickValue === 'number' ? eventTypes[tickValue] : tickValue),
        stepSize: 1,
        sampleSize: eventTypes.length,
        autoSkip: false,
        crossAlign: 'near',
      },
      afterSetDimensions: (scale) => {
        // eslint-disable-next-line no-param-reassign -- Reassigning the `scale` param's properties is the accepted way to set dimensions in ChartJS
        scale.maxWidth = 100;
      },
    },
  },
  interaction: { intersect: false, mode: 'nearest', axis: 'xy' },
  animation: false,
  plugins: {
    legend: { position: 'bottom' },
    tooltip: {
      callbacks: {
        label(context) {
          const data = context.raw as { x: number; y: number; eventDetails?: string };
          const details = data?.eventDetails ?? '';
          return JSON.stringify(details, null, 2);
        },
      },
      mode: 'nearest',
    },
    zoom: {
      pan: { enabled: true, modifierKey: 'ctrl', mode: 'x' },
      zoom: {
        onZoomComplete: (selectedChart) =>
          updateChartZoomLocations<DiagnosticsChartType>(selectedChart, diagnosticsChartIds),
        mode: 'x',
        drag: { enabled: true },
      },
    },
  },
});

export const EventChart: React.FC<{
  data: ChargePointLog[];
  startingDate: string;
  endDate: string;
}> = ({ data, startingDate, endDate }) => {
  const { palette } = useTheme();
  return (
    <>
      <ResetZoomButton<DiagnosticsChartType>
        chartIds={diagnosticsChartIds}
        minXValue={startingDate}
        maxXValue={endDate}
      />
      <Section style={{ height: '60vh', position: 'relative' }}>
        <Line
          id={diagnosticsChartIds.event}
          options={chartOptions(startingDate, endDate)}
          data={{ datasets: getMeterChartData(data, palette) }}
        />
      </Section>
    </>
  );
};
