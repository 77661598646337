import { all, call, fork, put, takeLatest, Effect } from 'redux-saga/effects';
import { standardiseError } from '../../utils/error';
import { getCurrentChargePointAction, setChargePointErrorAction, setCurrentChargePointAction } from './actions';
import { getChargePoint, Response } from './api';
import { ChargePointAction } from './types';

export function* handleGetCurrentChargePoint(
  action: ReturnType<typeof getCurrentChargePointAction>,
): Generator<Effect> {
  try {
    const { data: chargePoint } = (yield call(getChargePoint, action.payload.selectedItemId)) as Response;
    yield put(setCurrentChargePointAction(chargePoint));
  } catch (e) {
    const error = standardiseError(e);
    yield put(setChargePointErrorAction(error, action.payload.selectedItemId));
  }
}

function* watchGetCurrentChargePoint() {
  yield takeLatest(ChargePointAction.GET_CURRENT_CHARGE_POINT, handleGetCurrentChargePoint);
}

export function* chargePointSagas(): Generator<Effect> {
  yield all([fork(watchGetCurrentChargePoint)]);
}
