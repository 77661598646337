import { Token } from '../../app/ApiGen';
import { APIErrorAndError } from '../../utils/error';

export enum CardAction {
  GET_CURRENT_CARD = '@@card/GET_CURRENT_CARD',
  SET_CURRENT_CARD = '@@card/SET_CURRENT_CARD',
  SET_CARD_ERROR = '@@card/SET_CARD_ERROR',
  CLEAR_CURRENT_CARD = '@@card/CLEAR_CURRENT_CARD',
}

export interface CardState {
  loading: boolean;
  error?: APIErrorAndError;
  current?: Token;
  selectedItemId?: Token['id'];
}
