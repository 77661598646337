import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { fullStateSelector } from '../../../../store/root';
import type { StoreContextProps } from '../../../../store/types';
import { clearCurrentUserAction, getCurrentUserAction } from '../../../../store/user/actions';

export const useUser = (): StoreContextProps<'user'> => {
  const {
    user: { loading, current, error },
  } = useSelector(fullStateSelector);

  const dispatch = useDispatch();

  const {
    params: { userId },
  } = useRouteMatch<{ userId: string }>();

  const refetch = React.useCallback(() => {
    if (current) {
      dispatch(clearCurrentUserAction());
    }
    dispatch(getCurrentUserAction(current?.id ?? userId));
  }, [dispatch, current, userId]);

  React.useEffect(() => {
    if (!userId) {
      // Route params are not available in some contexts, such as nav views.
      return;
    }

    // Initial fetch action, if the store is still in its initial state
    if (!loading && !current) {
      dispatch(getCurrentUserAction(userId));
    }
    if (current && current.id !== userId) {
      dispatch(clearCurrentUserAction());
      dispatch(getCurrentUserAction(userId));
    }
  }, [dispatch, current, loading, userId]);

  return { error, loading, refetch, user: current };
};

const context = React.createContext<StoreContextProps<'user'>>({ loading: false, refetch: () => {} });
export const UserProvider: React.FC = ({ children }) => {
  const userState = useUser();
  return <context.Provider value={userState}>{children}</context.Provider>;
};
