import type React from 'react';
import * as Yup from 'yup';
import { FormField, ValueComponentProps } from '../design-system';

export function createSchema<T extends Record<string, unknown>>(
  schema: Yup.ObjectSchemaDefinition<Partial<T>>,
): Yup.ObjectSchema<Partial<T>, T> {
  return Yup.object().shape(schema) as Yup.ObjectSchema<Partial<T>, T>;
}

/**
 * Extract the moreThan or lessThan validation requirement from a Yup.number() schema.
 * @param schema Yup schema of the given type
 * @param {keyof T & string} fieldName A field within this schema that is a Yup.number()
 * @param {"min" | "max"} testType "min" for moreThan, "max" for lessThan
 * @returns {number} The parameter being used for validating the number's minimum or maximum
 */
export function numericSchemaParam<T = Record<string, unknown>>(
  schema: Yup.ObjectSchema<Partial<T>, T>,
  fieldName: keyof T & string,
  testType: 'min' | 'max',
): number {
  const schemaDescriptions = schema.describe().fields;
  if (!(fieldName in schemaDescriptions)) {
    throw new TypeError('Field not present in schema');
  }
  const fieldSchema = schemaDescriptions[fieldName] as Yup.SchemaDescription;
  const numericTest = fieldSchema.tests.find((test) => test.name === testType);
  if (!numericTest) {
    throw new TypeError('Could not find test in schema');
  }
  return testType === 'min' ? (numericTest.params.more as number) : (numericTest.params.less as number);
}

function removeOptionalProps<T extends Record<string, unknown>>(
  values: T,
  schemaDescriptions: Record<string, Yup.SchemaDescription>,
): T {
  const withoutOptionalValues = { ...values };
  Object.entries(values).forEach(([key, value]) => {
    if (key in schemaDescriptions) {
      const schemaDescription = schemaDescriptions[key];
      const required = schemaDescription.tests.find((test) => test.name === 'required');
      const isEmpty =
        value === '' ||
        value === false ||
        (typeof value === 'object' && value && Object.keys(value).length === 0) ||
        (Array.isArray(value) && value.length === 0);
      if (!required && isEmpty) {
        delete withoutOptionalValues[key];
      } else if (schemaDescription.fields && Object.keys(schemaDescription.fields).length > 0) {
        withoutOptionalValues[key as keyof T] = removeOptionalProps(
          value as Record<string, unknown>,
          schemaDescription.fields as Record<string, Yup.SchemaDescription>,
        ) as T[keyof T];
      }
    }
  });
  return withoutOptionalValues;
}

/**
 * Remove optional "falsy" values (empty arrays, empty objects, empty strings, and `false`) from an object, according to
 * a Yup object schema. A property is considered optional if it is present in the Yup schema and is not `required()`.
 * Optional properties of type `number` are never removed, even if their value is `0` (falsy).
 * @typedef T
 * @param {Required<T>} values An object
 * @param {Yup.ObjectSchema} schema Yup schema validators on the given object
 * @return {T} The object without optional falsy properties
 */
export function removeOptionalValues<T extends Record<string, unknown>>(
  values: Required<T>,
  schema: Yup.ObjectSchema<Partial<T>>,
): T {
  const schemaDescriptions = schema.describe().fields as Record<string, Yup.SchemaDescription>;
  return removeOptionalProps(values, schemaDescriptions);
}

export interface FieldDefinition<F = Record<string, unknown>, K extends string = string & keyof F>
  extends FormField<F[K extends keyof F ? K : keyof F]> {
  id: K extends string ? K : string;
}

/**
 * @deprecated Use FormFields<F> instead for better type safety
 */
export type FieldDefinitions<F = Record<string, unknown>> = (FormField & { id: keyof F })[];

/**
 * Call `Object.values()` on an object of this type to get a list of fields to pass to a `<Form>` component.
 */
export type FormFields<F = Record<string, unknown>> = {
  [K in keyof F]: FieldDefinition<Pick<F, K>, K extends string ? K : string>;
};

/**
 * Makes Yup's schema concatenation function work better with types.
 * TODO: This function probably wouldn't be necessary with the properly typed version of Yup, 0.32.0+
 */
export function combineSchemas<A extends Record<string, unknown>, B extends Record<string, unknown>>(
  a: Yup.ObjectSchema<A>,
  b: Yup.ObjectSchema<B>,
): Yup.ObjectSchema<A & B, A & B> {
  return a.concat(b) as Yup.ObjectSchema<A & B, A & B>;
}

/**
 * Utility for converting nonstandard types of change events to the type expected by Formik onChange handlers.
 */
export function toChangeEvent<V>(
  e: Event | React.ChangeEvent<unknown> | google.maps.MouseEvent,
  value: V,
): React.ChangeEvent<ValueComponentProps<V>> {
  const target: EventTarget & ValueComponentProps<V> = {
    addEventListener: () => {},
    dispatchEvent: () => false,
    removeEventListener: () => {},
    value,
  };

  const fakeEventProps = {
    target,
    currentTarget: target,
    isDefaultPrevented: () => false,
    isPropagationStopped: () => false,
    persist: () => {},
  };

  if ('latLng' in e) {
    return {
      ...fakeEventProps,
      nativeEvent: new Event('change'),
      bubbles: false,
      cancelable: false,
      defaultPrevented: false,
      eventPhase: 0,
      isTrusted: false,
      preventDefault: () => e.stop(),
      stopPropagation: () => e.stop(),
      timeStamp: new Date().valueOf(),
      type: 'google.maps.MouseEvent',
    };
  }
  return {
    ...e,
    ...fakeEventProps,
    nativeEvent: 'nativeEvent' in e ? e.nativeEvent : e,
  };
}

/**
 * The `helperText` prop as provided by Formik is not necessarily a string. If a field consists of nested components
 * with object or list value types, the prop may instead be a lookup of field names to helper text, or a list of these
 * lookup objects.
 */
export function getNestedHelperText<V>(
  helperTextProp: string | Record<keyof V, string> | Record<keyof V, string>[] | undefined,
): string | undefined {
  if (typeof helperTextProp === 'undefined' || typeof helperTextProp === 'string') {
    return helperTextProp;
  }
  const lookup = Array.isArray(helperTextProp) ? helperTextProp[0] : helperTextProp;
  return Object.values(lookup)[0] as string | undefined;
}
