import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';
import { StyledItemLoading } from './styled';

export interface ItemLoadingProps {
  icon?: boolean;
}

const ItemLoading: React.FC<ItemLoadingProps> = ({ icon }) => (
  <StyledItemLoading>
    {icon && (
      <Box p={1}>
        <Skeleton variant="rect" width="24px" height="24px" />
      </Box>
    )}
    <Skeleton width="100%" />
  </StyledItemLoading>
);

ItemLoading.displayName = 'ItemLoading';

export default ItemLoading;
