import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ChargePointView } from './ChargePoint';
import { ChargePointsListView } from './ChargePointsList';

const ChargePointsView: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <ChargePointView />
      </Route>
      <Route>
        <ChargePointsListView />
      </Route>
    </Switch>
  );
};

export default ChargePointsView;
