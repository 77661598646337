import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { InputComponentProps, ValueComponentProps } from '../../../design-system';
import { getNestedHelperText, toChangeEvent } from '../../../utils/formUtils';
import type { GooglePlace } from '../../../utils/googlePlace';
import { IcpLookupInput } from './IcpLookupInput';
import type { IcpOption } from './InstallationPointOption';

export type InstallationPoint = Pick<
  GooglePlace['addressComponents'],
  'city' | 'countryShort' | 'streetNumber' | 'streetName'
> & {
  id?: string;
};

type InstallationPointInputProps = InputComponentProps<InstallationPoint | null>;
export const InstallationPointInput: React.VFC<InstallationPointInputProps> = ({
  disabled,
  error,
  helperText,
  name,
  onBlur,
  onChange,
  value,
}: InstallationPointInputProps) => {
  const [queryParameters, setQueryParameters] = React.useState<
    Pick<InstallationPoint, 'city' | 'countryShort' | 'streetNumber' | 'streetName'>
  >(value ?? {});
  const [selectedOption, setSelectedOption] = React.useState<IcpOption | null>(null);

  const onValueChanged = React.useCallback(
    (eventOrFieldName: React.ChangeEvent<ValueComponentProps<IcpOption | null>> | string) => {
      if (typeof eventOrFieldName === 'string') {
        return onChange(eventOrFieldName);
      }
      const {
        target: { value: newValue },
      } = eventOrFieldName;
      const event = {
        ...eventOrFieldName,
        target: { ...eventOrFieldName.target, value: { id: newValue?.id } },
      };

      return onChange(name)(event);
    },
    [name, onChange],
  );

  React.useEffect(() => {
    const { id, streetName, streetNumber, city, countryShort } = value ?? {};
    setSelectedOption(id ? (v) => ({ address: v?.address ?? '', id }) : null);

    if (streetName && streetNumber && countryShort) {
      setQueryParameters({ streetNumber, streetName, city, countryShort });
    }
  }, [value]);

  const { city, countryShort, streetName, streetNumber } = queryParameters;

  if (countryShort === 'NZ' && streetNumber && streetName) {
    return (
      <IcpLookupInput
        streetNumber={streetNumber}
        streetName={streetName}
        city={city}
        error={error}
        disabled={disabled}
        helperText={helperText}
        name={name}
        onBlur={onBlur}
        onChange={onValueChanged}
        value={selectedOption}
      />
    );
  }
  return (
    <TextField
      disabled={disabled}
      error={error}
      fullWidth
      helperText={getNestedHelperText(helperText)}
      margin="dense"
      name={name}
      onBlur={onBlur}
      onChange={(e) => {
        const transformedEvent = toChangeEvent<InstallationPoint | null>(
          e,
          e.target.value ? { id: e.target.value } : null,
        );
        onChange(name)(transformedEvent);
      }}
      value={selectedOption?.id ?? ''}
      variant="outlined"
    />
  );
};

export const InstallationPointValue: React.VFC<ValueComponentProps<InstallationPoint | null>> = ({
  value,
}: ValueComponentProps<InstallationPoint | null>) => <Typography variant="body2">{value?.id}</Typography>;
