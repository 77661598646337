import * as React from 'react';
import { StyledList } from './styled';

export type ListVariant = 'alternate' | 'bordered';

export type ListAlternate = 'even' | 'odd';

export interface ListProps {
  /** Style of the list. */
  variant?: ListVariant;
  /** When the variant is `alternate` this prop will control if the fill begins on an `even` or `odd` row. */
  alternate?: ListAlternate;
  inset?: boolean;
}

const List: React.FC<ListProps> = ({ children, variant = 'alternate', alternate = 'even', inset = false }) => (
  <StyledList alternate={alternate} variant={variant} inset={inset}>
    {children}
  </StyledList>
);

List.displayName = 'List';

export { List };
