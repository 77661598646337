import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import * as React from 'react';
import { LinkProps, useLocation } from 'react-router-dom';
import {
  StyledCollapsedItem,
  StyledLink,
  StyledListItem,
  StyledListItemIcon,
  StyledListItemText,
  StyledTooltip,
} from './styled';

export interface ItemProps {
  icon?: typeof SvgIcon;
  text: TooltipProps['title'];
  to: LinkProps['to'];
  toNewTab?: boolean;
  hideActive?: boolean;
  isCollapsed?: boolean;
  secondaryIcon?: typeof SvgIcon;
  secondaryTooltip?: TooltipProps['title'];
}

const Item: React.FC<ItemProps> = ({
  icon: ItemIcon,
  text,
  to,
  toNewTab,
  hideActive = false,
  isCollapsed = false,
  secondaryIcon: EndIcon,
  secondaryTooltip,
}) => {
  const { pathname } = useLocation();

  const isActive = !hideActive && typeof to === 'string' && pathname.startsWith(to);

  const linkProps = {
    to,
    toNewTab,
    isActive,
    className: clsx({ active: isActive }),
  };

  if (isCollapsed && EndIcon) {
    return (
      <StyledTooltip placement="left" title={secondaryTooltip ?? text} arrow>
        <StyledCollapsedItem {...linkProps}>
          <EndIcon />
        </StyledCollapsedItem>
      </StyledTooltip>
    );
  }
  if (isCollapsed) {
    return (
      <Tooltip placement="left" title={text}>
        <StyledCollapsedItem {...linkProps}>{ItemIcon && <ItemIcon />}</StyledCollapsedItem>
      </Tooltip>
    );
  }

  const ItemSecondaryAction = () => {
    if (!EndIcon) {
      return null;
    }

    if (secondaryTooltip) {
      return (
        <ListItemSecondaryAction>
          <StyledTooltip placement="right" title={secondaryTooltip} arrow>
            <IconButton edge="end" aria-label={secondaryTooltip.toString()}>
              <EndIcon />
            </IconButton>
          </StyledTooltip>
        </ListItemSecondaryAction>
      );
    }
    return (
      <ListItemSecondaryAction>
        <IconButton edge="end">
          <EndIcon />
        </IconButton>
      </ListItemSecondaryAction>
    );
  };

  return (
    <StyledLink {...linkProps}>
      <StyledListItem disableRipple button selected={isActive}>
        {ItemIcon && (
          <StyledListItemIcon>
            <ItemIcon />
          </StyledListItemIcon>
        )}
        <StyledListItemText primary={text} />
        <ItemSecondaryAction />
      </StyledListItem>
    </StyledLink>
  );
};

Item.displayName = 'Item';

export default Item;
