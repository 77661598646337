import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form as FormikForm, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import { CostInput } from '../../../../../../components/CustomFields/Cost';
import { CurrencyInput } from '../../../../../../components/CustomFields/Currency';
import { FormEditable, FormField, OnSubmit, Section, useWizard } from '../../../../../../design-system';

const fields: FormField[] = [
  {
    id: 'cost',
    label: 'Fixed cost',
    inputComponent: CostInput,
  },
  {
    id: 'currency',
    label: 'Currency',
    inputComponent: CurrencyInput,
  },
];

const initialValues = {
  cost: '0.30',
  currency: 'NZD',
};

const validationSchema = Yup.object().shape({
  cost: Yup.string().required('Cost is required'),
  currency: Yup.string().required('Currency is required'),
});

export const ElectricityPriceStep: React.FC = () => {
  const { state, setState, prevStep, nextStep } = useWizard();

  const onSubmit = React.useCallback<OnSubmit<typeof initialValues>>(
    ({ cost, currency }) => {
      setState({ cost, currency });
      nextStep();
    },
    [setState, nextStep],
  );

  return (
    <Section
      title="Electricity price"
      description="Set how much electricity costs at this charge point. This is used to calculate the cost for charging sessions."
    >
      <Formik initialValues={{ ...initialValues, ...state }} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ errors, touched, isSubmitting }) => (
          <FormikForm>
            <FormEditable fields={fields} errors={errors} touched={touched} disabled={isSubmitting} />
            <Box pt={2} display="flex">
              <Button variant="contained" onClick={prevStep}>
                Back
              </Button>
              <Box mr="auto" />
              <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Next'}
              </Button>
            </Box>
          </FormikForm>
        )}
      </Formik>
    </Section>
  );
};
