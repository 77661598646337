import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { clearCurrentChargePointAction, getCurrentChargePointAction } from '../../../../../store/chargePoint/actions';
import { fullStateSelector } from '../../../../../store/root';
import type { StoreContextProps } from '../../../../../store/types';
import { NOT_FOUND_API_ERROR_STATUS } from '../../../../../utils/error';

export const useChargePoint: () => StoreContextProps<'chargePoint'> = () => {
  const {
    chargePoint: { loading, error, current, selectedItemId: selectedChargePointId },
  } = useSelector(fullStateSelector);
  const dispatch = useDispatch();

  const {
    params: { id: pathChargePointId },
  } = useRouteMatch<{ slug: string; id: string }>();

  const refetch = React.useCallback(() => {
    if (current) {
      dispatch(clearCurrentChargePointAction());
    }
    dispatch(getCurrentChargePointAction(pathChargePointId));
  }, [pathChargePointId, current, dispatch]);

  React.useEffect(() => {
    if (!pathChargePointId) {
      // Route params are not available in some contexts, such as nav views.
      return;
    }

    if (selectedChargePointId !== pathChargePointId) {
      dispatch(clearCurrentChargePointAction());
      dispatch(getCurrentChargePointAction(pathChargePointId));
    }
  }, [pathChargePointId, current, dispatch, loading, error, selectedChargePointId]);

  return { loading, error, chargePoint: current, refetch, selectedItemId: selectedChargePointId };
};
const context = React.createContext<StoreContextProps<'chargePoint'>>({ loading: false, refetch: () => {} });
export const ChargePointProvider: React.FC = ({ children }) => {
  const {
    params: { id: pathChargePointId, slug },
  } = useRouteMatch<{ slug: string; id: string }>();
  const chargePointState = useChargePoint();
  const { error, selectedItemId: selectedChargePointId } = chargePointState;
  if (error?.status === NOT_FOUND_API_ERROR_STATUS && pathChargePointId === selectedChargePointId) {
    return <Redirect to={`/organisations/${slug}/not-found`} />;
  }

  return <context.Provider value={chargePointState}>{children}</context.Provider>;
};
