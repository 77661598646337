import { useSnackbar } from 'notistack';
import { parse } from 'query-string';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuth } from '../../../app/AuthProvider';
import { errorSnackbar } from '../../../components/ErrorSnackbar';
import { Splash } from '../../../design-system';
import { Layout } from '../components/Layout';
import { MfaChallengeForm, MfaChallengeFormProps } from '../components/MfaChallengeForm';
import { NewPasswordForm, NewPasswordFormProps } from './components/NewPasswordForm';
import { SignInForm, SignInFormProps } from './components/SignInForm';
import { UnsupportedChallenge } from './components/UnsupportedChallenge';

const SignInView: React.FC = () => {
  const { isLoading, error, challenge, isAuthenticated, signIn, setNewPassword, confirmSignIn } = useAuth();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const queryParams = parse(search);

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to sign in', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  const onSignIn = React.useCallback<SignInFormProps['onSubmit']>(
    ({ email, password }, { setSubmitting }) => {
      signIn(email, password).finally(() => {
        window.dataLayer.push({ event: 'login' });
        setSubmitting(false);
      });
    },
    [signIn],
  );

  const onNewPassword = React.useCallback<NewPasswordFormProps['onSubmit']>(
    async ({ password }, { setSubmitting }) => {
      try {
        await setNewPassword(password);
      } finally {
        setSubmitting(false);
      }
    },
    [setNewPassword],
  );

  const onMfaChallenge = React.useCallback<MfaChallengeFormProps['onSubmit']>(
    async ({ code }, { setSubmitting }) => {
      try {
        await confirmSignIn(code);
      } finally {
        setSubmitting(false);
      }
    },
    [confirmSignIn],
  );

  React.useEffect(() => {
    if (
      queryParams.email &&
      typeof queryParams.email === 'string' &&
      queryParams.code &&
      typeof queryParams.code === 'string'
    ) {
      const [code] = search.split('code-');
      const { email } = queryParams;
      // NB: AuthProvider is already handling the error.
      signIn(email, code).finally(() => {});
    }
  }, [signIn, search, queryParams]);

  if (isLoading) {
    return <Splash />;
  }

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (challenge) {
    switch (challenge.id) {
      case 'EVNEX_USER_NOT_CONFIRMED':
        return <Redirect to="/auth/verify" />;
      case 'EVNEX_RESET_REQUIRED':
        return <Redirect to="/auth/forgot-password" />;
      case 'NEW_PASSWORD_REQUIRED':
        return (
          <Layout title="Welcome!">
            <NewPasswordForm onSubmit={onNewPassword} />
          </Layout>
        );
      case 'SMS_MFA':
      case 'SOFTWARE_TOKEN_MFA':
        return (
          <Layout title="Multi-Factor Authentication">
            <MfaChallengeForm onSubmit={onMfaChallenge} />
          </Layout>
        );
      case 'MFA_SETUP':
      default:
        return (
          <Layout>
            <UnsupportedChallenge challenge={challenge.id} />
          </Layout>
        );
    }
  }

  return (
    <Layout title="Sign in">
      <SignInForm onSubmit={onSignIn} hasCreateAccount={false} hasForgotPassword />
    </Layout>
  );
};

export default SignInView;
