import React from 'react';
import * as Yup from 'yup';
import type { Location, TimeZone } from '../../app/ApiGen';
import { AddressInput, AddressInputProps, AddressValue } from '../../components/CustomFields/Address';
import { InstallationPointInput, InstallationPointValue } from '../../components/CustomFields/InstallationPoint';
import { PositionInput, PositionValue } from '../../components/CustomFields/Position';
import { AutocompleteInput, AutocompleteValue } from '../../components/CustomFields/SimpleAutocomplete';
import { InputComponentProps } from '../../design-system';
import type { Address, Coordinates } from '../api';
import { combineSchemas, createSchema, FieldDefinition, FormFields } from '../formUtils';
import { Normalised } from '../request';
import { sortedTimeZones } from '../sortedTimeZones';
import { isValidNationalMeteringIdentifier } from '../validation/nationalMeteringIdentifier';
import { addressValidationSchema, isAustralia } from './address';
import { InstallationPointField } from './installationConnectionPoint';

export type LocationFields = Pick<Normalised<Location>, 'address' | 'coordinates' | 'name'> & {
  timeZone: string;
} & InstallationPointField;

const MIN_ICP_OR_NMI_LENGTH = 10;

const icpNumberValidationSchema = createSchema<InstallationPointField>({
  installationPoint: Yup.object()
    .nullable()
    .shape({
      id: Yup.string()
        .trim()
        .min(MIN_ICP_OR_NMI_LENGTH, `ICP/NMI must be at least ${MIN_ICP_OR_NMI_LENGTH} characters`),
    })
    .test('icpIsRequired', 'ICP number is required', (value, { parent }) => {
      const location = parent as Normalised<Location>;
      const isNZAddress = location.address?.country === 'NZ';
      const icpIsPresent = !!value?.id;
      return !isNZAddress || icpIsPresent;
    })
    .test('nmiIsRequired', 'NMI number is required', (value, { parent }) => {
      const location = parent as Normalised<Location>;
      const isAUAddress = isAustralia(location.address.country);
      const nmiIsPresent = !!value?.id;
      return !isAUAddress || nmiIsPresent;
    })
    .test('isValidNMI', 'NMI is not valid', (value, { parent }) => {
      const location = parent as Normalised<Location>;
      if (!value?.id || !isAustralia(location.address.country)) {
        return true;
      }
      return isValidNationalMeteringIdentifier(value.id);
    }),
});

const validationSchema = createSchema<LocationFields>({
  name: Yup.string()
    .trim()
    .required('Name is required')
    .min(3, 'Name must be at least ${min} characters')
    .max(999, 'Name cannot exceed ${max} characters'),
  address: addressValidationSchema.required('Address is required'),
  coordinates: Yup.object()
    .shape<Coordinates>({
      latitude: Yup.string()
        .matches(/^\(?[+-]?(90(\.0+)?|[1-8]?\d(\.\d+)?)$/, 'Position latitude is invalid')
        .required('Position latitude is required'),
      longitude: Yup.string()
        .matches(/^\s?[+-]?(180(\.0+)?|1[0-7]\d(\.\d+)?|\d{1,2}(\.\d+)?)\)?$/, 'Position longitude is invalid')
        .required('Position longitude is required'),
    })
    .required('Position is required'),
});

/* eslint no-template-curly-in-string: "off" --- Yup messages can be customised using template curlies in strings. */
export const locationValidationSchema = combineSchemas(validationSchema, icpNumberValidationSchema);

export function addressField({
  coordinatesFieldId,
  icpFieldId,
  timeZoneFieldId,
}: Pick<AddressInputProps, 'coordinatesFieldId' | 'icpFieldId' | 'timeZoneFieldId'>): FieldDefinition<
  Pick<LocationFields, 'address'>
> {
  return {
    id: 'address',
    label: 'Address',
    inputComponent: ({ disabled, error, helperText, name, onBlur, onChange, value }: InputComponentProps<Address>) => (
      <AddressInput
        coordinatesFieldId={coordinatesFieldId}
        disabled={disabled}
        error={error}
        helperText={helperText}
        icpFieldId={icpFieldId}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        timeZoneFieldId={timeZoneFieldId}
        value={value}
      />
    ),
    valueComponent: AddressValue,
  };
}

export const locationFields: FormFields<LocationFields> = {
  name: {
    id: 'name',
    label: 'Name',
    inputProps: {
      helperText: 'A recognisable name for this location, e.g. "My house" or "Office car park"',
    },
  },
  address: addressField({
    coordinatesFieldId: 'coordinates',
    timeZoneFieldId: 'timeZone',
    icpFieldId: 'installationPoint',
  }),
  coordinates: {
    id: 'coordinates',
    label: 'Position',
    inputComponent: ({
      disabled,
      error,
      helperText,
      name,
      onBlur,
      onChange,
      value,
    }: InputComponentProps<Coordinates>) => (
      <PositionInput
        disabled={disabled}
        error={error}
        helperText={helperText}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        zoom={19}
      />
    ),
    valueComponent: PositionValue,
  },
  timeZone: {
    id: 'timeZone',
    label: 'Time zone',
    inputComponent: ({ disabled, error, helperText, name, onBlur, onChange, value }: InputComponentProps<TimeZone>) => (
      <AutocompleteInput<string>
        disabled={disabled}
        error={error}
        helperText={helperText}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        options={sortedTimeZones}
        useNullAsDefaultValue
        value={value}
      />
    ),
    valueComponent: AutocompleteValue,
  },
  installationPoint: {
    id: 'installationPoint',
    label: 'ICP (NZ)/NMI (AU) Number',
    inputComponent: InstallationPointInput,
    valueComponent: InstallationPointValue,
  },
};
