import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { uid } from 'react-uid';
import { Section, ValueComponentProps } from '../../design-system';
import { errorSnackbar } from '../ErrorSnackbar';

export type FileAttachment = {
  filename: string;
  fileUrl: string;
};

type FileExtension = typeof pdfExtension | string;

const pdfExtension = 'pdf';

const imageExtensions = ['jpg', 'jpeg', 'png'];

const getNameAndExtension = (fileName: string): string[] => fileName.split('.');

const getExtension = (fileName: string): FileExtension => getNameAndExtension(fileName)[1];

const getContentType = (fileExtension: FileExtension): string => {
  const contentTypes: Record<FileExtension, string> = {
    [pdfExtension]: 'application/pdf',
  };
  return contentTypes[fileExtension] ?? '';
};

const getFileBlob = async (fileUrl: string, contentType: string): Promise<Blob> => {
  const response = await fetch(fileUrl, {
    method: 'GET',
    headers: {
      'Content-Type': contentType,
    },
  });
  return response.blob();
};

const ImageTile: React.FC<FileAttachment> = ({ filename, fileUrl }: FileAttachment, key: number) => {
  const [hasImageError, toggleHasImageError] = React.useState<boolean>(false);
  const readableFileName = _.startCase(getNameAndExtension(filename)[0]);

  return (
    <Section key={key} style={{ width: '60%' }}>
      {hasImageError ? (
        <Alert severity="warning">Error loading {readableFileName}</Alert>
      ) : (
        <>
          {readableFileName}
          <a href={fileUrl}>
            <img
              src={fileUrl}
              alt={filename}
              style={{ width: '90%' }}
              onLoad={() => toggleHasImageError(false)}
              onError={() => toggleHasImageError(true)}
            />
          </a>
        </>
      )}
    </Section>
  );
};

export const FileDownload: React.FC<FileAttachment> = ({ filename, fileUrl }: FileAttachment, key: number) => {
  const fileExtension = getExtension(filename);
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const downloadFile = React.useCallback(
    async (downloadFilename: string, contentType: string) => {
      const link = document.createElement('a');
      const blob = await getFileBlob(fileUrl, contentType);
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', `${downloadFilename}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    },
    [fileExtension, fileUrl],
  );

  return (
    <Section key={key} style={{ display: 'flex', flexDirection: 'column' }}>
      {_.startCase(filename)}
      <Button
        color="primary"
        onClick={() => {
          downloadFile(filename, getContentType(fileExtension)).catch((e) => {
            errorSnackbar('Unable to download file', closeSnackbar, enqueueSnackbar, e);
          });
        }}
        style={{ width: '30%' }}
        variant="contained"
      >
        Download
      </Button>
    </Section>
  );
};

const getFileElements = (files: FileAttachment[]): JSX.Element[] =>
  files.map(({ filename, fileUrl }, idx) => <FileDownload filename={filename} fileUrl={fileUrl} key={uid(idx)} />);

const getImageElements = (images: FileAttachment[]): JSX.Element[] =>
  images.map(({ filename, fileUrl }, idx) => <ImageTile filename={filename} fileUrl={fileUrl} key={uid(idx)} />);

export const FileAttachmentsValue: React.FC<ValueComponentProps<FileAttachment[]>> = ({ value: attachments }) => {
  if (attachments.length) {
    const [images, files] = _.partition(attachments, (attachment: FileAttachment) =>
      imageExtensions.includes(getExtension(attachment.filename)),
    );
    return (
      <Section style={{ display: 'flex', flexDirection: 'column' }}>
        {getFileElements(files)}
        <Section style={{ display: 'flex', flexFlow: 'row wrap' }}>{getImageElements(images)}</Section>
      </Section>
    );
  }

  return <Typography>None</Typography>;
};
