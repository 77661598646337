import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import React from 'react';
import { Card } from '../../../../../../../design-system';
import type { SubState } from '../../../../../../../store/types';
import { DateFormat, TimeFormat } from '../../../../../../../utils/dateFormat';

export const Header: React.FC<{ card: SubState<'card'> }> = ({ card }) => {
  const { name, uid, createdDate } = card;

  return (
    <Card>
      <Box p={2}>
        <Typography variant="h6" style={{ wordBreak: 'break-word' }} paragraph>
          {name ?? uid}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Card created{' '}
          <span title={moment(createdDate).format(`${DateFormat.Long} ${TimeFormat.NoSeconds}`)}>
            {moment(createdDate).fromNow()}
          </span>
        </Typography>
      </Box>
    </Card>
  );
};
