import type { ChargePointSummary, Connector, MeterValues } from '../../app/ApiGen';
import { chargePointHasAttributes } from '../format';
import { roundTo2DecimalPlaces, sumValues } from '../number';
import type { Normalised } from '../request';

type ChargePoint = Normalised<ChargePointSummary>;

const getTotalCurrent = (currents: Pick<MeterValues, 'current' | 'currentL1' | 'currentL2' | 'currentL3'>): number => {
  const { current, currentL1, currentL2, currentL3 } = currents;
  return typeof current === 'number' ? current : (currentL1 ?? 0) + (currentL2 ?? 0) + (currentL3 ?? 0);
};

const getChargingConnectors = (chargePoint: ChargePoint): Connector[] =>
  chargePointHasAttributes(chargePoint)
    ? chargePoint.connectors.filter((connector) => connector.ocppStatus === 'CHARGING')
    : [];

export function totalChargingCurrent(chargePoints: ChargePoint[]): number {
  const onlineChargePoints = [...chargePoints].filter((chargePoint): chargePoint is ChargePoint =>
    chargePointHasAttributes(chargePoint) ? chargePoint.networkStatus === 'ONLINE' : false,
  );

  const currentValues = onlineChargePoints
    .flatMap(getChargingConnectors)
    .map((connector) => (connector?.meter ? getTotalCurrent(connector.meter) : 0));

  const total = sumValues(currentValues);
  return roundTo2DecimalPlaces(total);
}

////////////////////////////////////////////////////

const ONLINE_LOWER = 'online';

const getOnlineChargePoints = (chargePoints: Normalised<ChargePointSummary>[]) =>
  chargePoints.filter(({ networkStatus }) => networkStatus.toLowerCase() === ONLINE_LOWER);

export const getOfflineChargePointsCount = (chargePoints: Normalised<ChargePointSummary>[]): number =>
  chargePoints.length - getOnlineChargePoints(chargePoints).length;
