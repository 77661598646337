import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import React from 'react';
import styled from 'styled-components';

interface WrappedAvatarProps extends AvatarProps {
  bgColor?: string;
}

const WrappedAvatar: React.FC<WrappedAvatarProps> = React.forwardRef<HTMLDivElement, WrappedAvatarProps>(
  // eslint-disable-next-line react/jsx-props-no-spreading -- There are more than 250 props to spread.
  ({ bgColor, ...rest }: WrappedAvatarProps, ref) => <Avatar variant="circular" {...rest} ref={ref} />,
);

WrappedAvatar.defaultProps = { bgColor: undefined };

const StyledAvatar = styled(WrappedAvatar)`
  ${(p) => (p.bgColor ? `background-color: #${`${p.bgColor}`.substring(0, 6)};` : '')}
  border: 2px solid ${(p) => p.theme.palette.common.white};
  outline: none;
`;

const AvatarWrapper = styled('div')`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  justify-content: flex-end;

  ${StyledAvatar} {
    height: 72px;
    width: 72px;
    font-size: 24px;
  }
`;

interface UserAvatarProps {
  user: { name?: string; color?: string; avatar?: string };
  embedded?: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ embedded, user }) => {
  const EmbeddedAvatar = () => (
    <StyledAvatar bgColor={user.color} src={user.avatar}>
      {user.name?.length ? user.name[0].toUpperCase() : <PersonIcon />}
    </StyledAvatar>
  );
  return embedded ? (
    <EmbeddedAvatar />
  ) : (
    <AvatarWrapper>
      <EmbeddedAvatar />
    </AvatarWrapper>
  );
};
