import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import type { ConnectorType } from '../../../../app/ApiGen';

const ConnectorTypeLabel: Record<ConnectorType, string> = {
  CHADEMO: 'CHAdeMO, DC',
  IEC_62196_T1: 'IEC 62196 Type 1',
  IEC_62196_T1_COMBO: 'Combo Type 1 based, DC',
  IEC_62196_T2: 'IEC 62196 Type 2',
  IEC_62196_T2_COMBO: 'Combo Type 2 based, DC',
  IEC_62196_T3A: 'IEC 62196 Type 3A',
  IEC_62196_T3C: 'IEC 62196 Type 3C',
};
const connectorStandards = Object.entries(ConnectorTypeLabel);

export const StandardInput: React.FC = ({ ...other }) => (
  <TextField select variant="outlined" margin="dense" fullWidth style={{ margin: 0 }} aria-label="Standard" {...other}>
    {connectorStandards.map(([value, label]) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ))}
  </TextField>
);
