import * as Yup from 'yup';
import type { Role } from '../../app/ApiGen';
import { createSchema, FormFields } from '../formUtils';

export const userRoleLabels: Record<Role, string> = {
  0: 'Admin',
  1: 'Fleet Operator',
  2: 'Driver',
};

export type UserCreateDetails = {
  name: string;
  email: string;
};

export const userCreateFields: FormFields<UserCreateDetails> = {
  name: { id: 'name', label: 'Name', inputProps: { helperText: "User's full name" } },
  email: {
    id: 'email',
    label: 'Email',
    inputProps: { helperText: 'This will be the account login for the user to access Evnex apps' },
  },
};

export const userCreateValidation = createSchema<UserCreateDetails>({
  email: Yup.string().trim().email('Invalid email address').required('Email is required'),
  name: Yup.string()
    .trim()
    .required('Name is required')
    .min(3, 'Name is too short to store')
    .max(255, 'Name is too long to store'),
});
