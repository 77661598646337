import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import UsersListView from './UsersList';
import OrganisationUserView from './User';

const UsersView: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <OrganisationUserView />
      </Route>
      <Route>
        <UsersListView />
      </Route>
    </Switch>
  );
};

export default UsersView;
