import Box from '@material-ui/core/Box';
import moment from 'moment-timezone';
import React from 'react';
import { Session, useListOrganisationSessions } from '../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../app/CurrentOrganisationProvider';
import { useDeveloperMode } from '../../../../app/DeveloperModeProvider';
import { ColumnFiltersToggle } from '../../../../components/DataTable/Toolbar';
import { DateRangePicker } from '../../../../components/DateRange';
import { DataErrorHandler } from '../../../../components/ErrorHandler';
import { ExportCsv, ExportProps } from '../../../../components/ExportCsv';
import { Card, ColumnChanger, ColumnChangerProps, DataTable, Section, Workspace } from '../../../../design-system';
import { DateFormat } from '../../../../utils/dateFormat';
import { DEFAULT_DAYS_AGO, useEndDateState, useSetDateRange, useStartDateState } from '../../../../utils/dateRange';
import { chargePointHasAttributes, locationHasAttributes } from '../../../../utils/format';
import { getAddressAsString } from '../../../../utils/locations/address';
import { normalise, Normalised } from '../../../../utils/request';
import { includesSearch } from '../../../../utils/search';
import type { RemappedSession } from '../../../../utils/sessions';
import {
  carbonOffsetColumn,
  connectorIdColumn,
  evseIdColumn,
  sessionIdColumn,
  tokenIdColumn,
  tokenNameColumn,
  totalCostColumn,
  totalChargingCostColumn,
} from '../../../../utils/sessions/columns/creator';
import { durationFormat, powerUsageWithUnit } from '../../../../utils/sessions/columns/format';
import { renderDate, renderTotalCarbonUsage } from '../../../../utils/sessions/columns/render';
import { searchTotalCarbonUsage } from '../../../../utils/sessions/columns/search';
import { sortByDate, sortByDuration, sortByLocale, sortByValue } from '../../../../utils/sortBy';
import { csvDateFormat } from './utils';

const toRowData = (data: Normalised<Session>[]): RemappedSession[] => {
  const relevantSessionWithChargePoints = data.filter((session) => chargePointHasAttributes(session.chargePoint));
  return relevantSessionWithChargePoints.map<RemappedSession>(
    ({
      id,
      evseId,
      chargePoint,
      connectorId,
      token,
      startDate,
      endDate,
      totalPowerUsage,
      electricityCost,
      location,
      transaction,
      totalCost,
      totalCarbonUsage,
      chargingStarted,
      chargingStopped,
      chargingConfiguration,
      cost,
    }) => ({
      connectorId,
      endDate,
      evseId,
      id,
      startDate,
      chargePointId: id,
      chargePointRefId: chargePointHasAttributes(chargePoint) ? chargePoint.referenceId : '',
      carbonOffset: totalPowerUsage ? ((totalPowerUsage / 1000) * 0.3).toFixed(2) : undefined,
      usage: totalPowerUsage ? (totalPowerUsage / 1000).toFixed(2) : undefined,
      locationName: locationHasAttributes(location) ? location.name : '',
      chargePointName: chargePointHasAttributes(chargePoint) ? chargePoint.name : '',
      locationAddress: locationHasAttributes(location) ? getAddressAsString(location.address) : undefined,
      duration: startDate && endDate ? moment(endDate).diff(moment(startDate)) : undefined,
      totalCarbonUsage,
      electricityCost: {
        amount: totalCost?.amount ?? '0',
        currency: electricityCost?.currency ?? 'NZD',
      },
      token: {
        id: token?.tokenId,
        name: token?.tokenName,
      },
      stopReason: transaction?.reason,
      chargingStarted,
      chargingStopped,
      cost: {
        amount: cost?.total ?? '0',
        currency: chargingConfiguration?.currency ?? 'NZD',
      },
    }),
  );
};

const ReportsView: React.FC<{ orgSlug: string }> = ({ orgSlug }) => {
  const [filtering, setFiltering] = React.useState(false);

  const [fromDate, setFromDate] = useStartDateState(DEFAULT_DAYS_AGO);
  const [toDate, setToDate] = useEndDateState();
  const setDateRange = useSetDateRange(setFromDate, setToDate);
  const { isDeveloperMode } = useDeveloperMode();

  const { organisation } = useCurrentOrganisation();
  const organisationId = organisation?.id ?? '';

  const { loading, error, data, refetch } = useListOrganisationSessions({
    organisationId,
    queryParams: { from: fromDate, to: toDate },
  });

  const fromFormatted = moment(fromDate).format(DateFormat.Filename);
  const toFormatted = moment(toDate).format(DateFormat.Filename);
  const exportFileName = `sessions-${orgSlug}-from-${fromFormatted}-to-${toFormatted}`;

  const normalisedSessions: RemappedSession[] = data ? toRowData(normalise(data)) : [];

  if (error) {
    return <DataErrorHandler description="Unable to load report" error={error} refetch={refetch} />;
  }

  return (
    <Workspace maxWidth="xl">
      <Card>
        <Box p={2}>
          <Box>
            <Section
              title="Completed Charging Sessions"
              description="Shows all charging sessions which were completed during the selected date range.
              This can later be filtered, for example if you want to see sessions for a specific charger only."
            />
          </Box>
          <DataTable<RemappedSession>
            isLoading={loading}
            options={{
              columnsButton: true,
              exportButton: true,
              exportFileName,
              filtering,
            }}
            toolbarProps={{
              search: () => <DateRangePicker fromDate={fromDate} toDate={toDate} onApply={setDateRange} />,
              actions: (props: ColumnChangerProps & ExportProps<RemappedSession>) => (
                <>
                  <ColumnChanger
                    columnsButton={props.columnsButton}
                    columns={props.columns}
                    icons={props.icons}
                    onColumnsChanged={props.onColumnsChanged}
                  />
                  <ColumnFiltersToggle filtering={filtering} setFiltering={setFiltering} />
                  <ExportCsv<RemappedSession>
                    columns={props.columns}
                    data={props.data}
                    dateFormat={csvDateFormat}
                    exportFileName={props.exportFileName}
                    getFieldValue={props.getFieldValue}
                    icons={props.icons}
                  />
                </>
              ),
            }}
            columns={[
              sessionIdColumn(),
              {
                field: 'startDate',
                title: 'Start time',
                type: 'date',
                defaultSort: 'desc',
                customSort: sortByDate('startDate'),
                render: ({ startDate }: Partial<RemappedSession>) => renderDate(startDate),
              },
              {
                field: 'endDate',
                title: 'End time',
                type: 'date',
                customSort: sortByDate('endDate'),
                render: ({ endDate }: Partial<RemappedSession>) => renderDate(endDate),
              },
              evseIdColumn(),
              connectorIdColumn(),
              {
                title: 'Charge point reference ID',
                field: 'chargePointRefId',
                customSort: sortByLocale('chargePointRefId'),
                hidden: true,
              },
              {
                title: 'Charge point name',
                field: 'chargePointName',
                customSort: sortByLocale('chargePointName'),
              },
              {
                title: 'Location name',
                field: 'locationName',
                customSort: sortByLocale('locationName'),
              },
              {
                title: 'Location address',
                field: 'locationAddress',
                customSort: sortByLocale('locationAddress'),
                hidden: true,
              },
              tokenIdColumn(),
              tokenNameColumn(),
              {
                title: 'Session duration',
                field: 'duration',
                customSort: sortByDuration('startDate', 'endDate'),
                render: ({ duration }: Partial<RemappedSession>) =>
                  duration !== undefined && <>{durationFormat(duration)}</>,
                customFilterAndSearch: (searchValue: string, { duration }) =>
                  duration !== undefined && includesSearch(durationFormat(duration), searchValue),
              },
              {
                field: 'totalCarbonUsage',
                title: 'Carbon use',
                customSort: sortByValue('totalCarbonUsage'),
                render: renderTotalCarbonUsage,
                customFilterAndSearch: searchTotalCarbonUsage,
              },
              {
                ...carbonOffsetColumn(),
                hidden: true,
              },
              {
                title: 'Total usage',
                field: 'usage',
                customSort: sortByValue('usage'),
                render: ({ usage }: Partial<RemappedSession>) =>
                  usage !== undefined ? <>{powerUsageWithUnit(usage)}</> : <></>,
                customFilterAndSearch: (searchValue: string, { usage }) =>
                  usage !== undefined && includesSearch(powerUsageWithUnit(usage), searchValue),
              },
              totalCostColumn(),
              ...(isDeveloperMode ? [totalChargingCostColumn()] : []),
              {
                field: 'chargingStarted',
                title: 'Charging started',
                type: 'date',
                hidden: true,
                customSort: sortByDate('chargingStarted'),
                render: ({ chargingStarted }: Partial<RemappedSession>) => renderDate(chargingStarted),
              },
              {
                field: 'chargingStopped',
                title: 'Charging stopped',
                type: 'date',
                hidden: true,
                customSort: sortByDate('chargingStopped'),
                render: ({ chargingStopped }: Partial<RemappedSession>) => renderDate(chargingStopped),
              },
            ]}
            data={normalisedSessions}
          />
        </Box>
      </Card>
    </Workspace>
  );
};

export default ReportsView;
