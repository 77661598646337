import { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthenticatedUserAction, getAuthenticatedUserAction } from '../store/authenticatedUser/actions';
import { ApplicationState } from '../store/types';

const dev = {
  log: (message: string, ...opts: unknown[]) =>
    process.env.NODE_ENV === 'development' ? console.log(`[AuthProvider] ${message}`, ...opts) : undefined,
  error: (message: string, ...opts: unknown[]) =>
    process.env.NODE_ENV === 'development' ? console.error(`[AuthProvider] ${message}`, ...opts) : undefined,
};

// WARNING: these strings are thrown from amazon-cognito-identity-js library. If package updates they could change
export enum CognitoStringError {
  NotAuthenticated = 'The user is not authenticated',
  NoUser = 'No current user',
}

function toCognitoError(e: unknown): Error & { code: string } {
  return typeof e === 'string' ? Object.assign(new Error(e), { code: '' }) : (e as Error & { code: string });
}

interface AuthChallenge {
  id: string;
  params?: string[];
}

interface AuthContextProps {
  isLoading: boolean;
  error?: Error;
  challenge?: AuthChallenge;
  isAuthenticated: boolean;
  user?: CognitoUser;
  signIn: (email: string, password: string) => Promise<unknown>;
  signOut: () => Promise<unknown>;
  signUp: (email: string, password: string) => Promise<string | void>;
  setNewPassword: (password: string, requiredAttributes?: unknown) => Promise<unknown>;
  requestNewSignUpCode: (email: string) => Promise<string>;
  confirmSignUp: (email: string, code: string) => Promise<unknown>;
  forgotPassword: (email: string) => Promise<string>;
  forgotPasswordSubmit: (email: string, code: string, newPassword: string) => Promise<string>;
  changePassword: (currentPassword: string, newPassword: string) => Promise<string>;
  confirmSignIn: (token: string) => Promise<void>;
}

const stub = async () => Promise.reject(new Error('Context not initialised'));
const AuthContext = React.createContext<AuthContextProps>({
  isLoading: false,
  isAuthenticated: false,
  changePassword: stub,
  signIn: stub,
  signOut: stub,
  signUp: stub,
  setNewPassword: stub,
  requestNewSignUpCode: stub,
  confirmSignUp: stub,
  forgotPassword: stub,
  forgotPasswordSubmit: stub,
  confirmSignIn: stub,
});

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<CognitoUser | undefined>(undefined);

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | undefined>(undefined);
  const [challenge, setChallenge] = React.useState<AuthChallenge | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  const dispatch = useDispatch();

  const getAuthenticatedUser = React.useCallback(() => dispatch(getAuthenticatedUserAction()), [dispatch]);
  const clearCurrentUser = React.useCallback(() => dispatch(clearAuthenticatedUserAction()), [dispatch]);

  const currentUserLoading = useSelector((state: ApplicationState) => state.authenticatedUser.loading);
  const currentUserError = useSelector((state: ApplicationState) => state.authenticatedUser.error);

  /**
   * Sign up a user
   */

  const signUp = React.useCallback(async (email: string, password: string) => {
    dev.log('signUp() called');
    const emailAddress = email.toLowerCase();

    setIsLoading(true);
    setError(undefined);

    return Auth.signUp({
      username: emailAddress,
      password,
      attributes: { email: emailAddress },
    })
      .then((_signUpResult?: ISignUpResult) => {
        if (!_signUpResult) {
          throw new Error('Sign up failed');
        }
        return emailAddress;
      })
      .catch((e) => {
        dev.error('signUp() error', e);
        const err = toCognitoError(e);
        switch (err.code) {
          case 'NotAuthorizedException':
          case 'UsernameExistsException':
            setError(new Error('Unable to sign up, please contact support@evnex.com if error persists'));
            return;
          default:
            setError(err);
            throw err;
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleUserSignIn = React.useCallback(
    (_user?: CognitoUser) => {
      if (!_user) {
        throw new Error('Did not receive user object');
      }

      setUser(_user);

      // @ts-expect-error -- Where do these parameters come from??
      const { challengeName, challengeParam } = _user;

      if (challengeName) {
        setChallenge({ id: challengeName as string, params: challengeParam as string[] });
        return;
      }

      getAuthenticatedUser();

      setIsAuthenticated(true);
    },
    [getAuthenticatedUser],
  );

  const handleSignInErrorCodes = async (err: Error & { code: string }, defaultFunc: () => Promise<void>) => {
    switch (err.code) {
      case 'UserNotConfirmedException':
        setChallenge({ id: 'EVNEX_USER_NOT_CONFIRMED' });
        return;
      case 'PasswordResetRequiredException':
        setChallenge({ id: 'EVNEX_RESET_REQUIRED' });
        return;
      case 'UserNotFoundException':
      case 'NotAuthorizedException':
        setError(new Error('Incorrect username or password'));
        return;
      default:
        await defaultFunc();
    }
  };

  const trySignInAgain = React.useCallback(
    (email: string, password: string) => async (): Promise<void> => {
      /* Try sign in again with the email lower cased, as the user didn't get the case correct the first time.
       * We don't want to sign in with lower case initially, in case the user signed up with a mixed case email.
       */
      await Auth.signIn(email.toLowerCase(), password)
        .then(handleUserSignIn)
        .catch(async (e) => {
          const err = toCognitoError(e);
          await handleSignInErrorCodes(err, () => {
            setError(err);
            throw err;
          });
        });
    },
    [handleUserSignIn],
  );

  /**
   * Sign a user into the app
   */
  const signIn = React.useCallback(
    async (email: string, password: string) => {
      dev.log('signIn() called');

      setIsLoading(true);
      setError(undefined);
      setChallenge(undefined);
      setIsAuthenticated(false);

      return Auth.signIn(email, password)
        .then(handleUserSignIn)
        .catch(async (e) => {
          const err = toCognitoError(e);
          await handleSignInErrorCodes(err, trySignInAgain(email, password));
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [handleUserSignIn, trySignInAgain],
  );

  /**
   * Sign a user out of the app.
   */
  const signOut = React.useCallback(async () => {
    dev.log('signOut() called');

    setIsLoading(true);
    setError(undefined);
    setChallenge(undefined);

    return Auth.signOut({ global: true })
      .then(() => {
        clearCurrentUser();

        setUser(undefined);
        setIsAuthenticated(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [clearCurrentUser]);

  /**
   * Set a new password for the user.
   */
  const setNewPassword = React.useCallback(
    async (password: string, requiredAttributes?: unknown) => {
      dev.log('setNewPassword() called');
      return Auth.completeNewPassword(user, password, requiredAttributes)
        .then((_user: CognitoUser) => {
          setUser(_user);
          getAuthenticatedUser();

          setIsAuthenticated(true);
        })
        .catch((e) => {
          dev.error('setNewPassword() error', e);
          const err = toCognitoError(e);
          setError(err);
          throw err;
        });
    },
    [getAuthenticatedUser, user],
  );

  /**
   * Request a new sign up verification code.
   */
  const requestNewSignUpCode = React.useCallback(async (email: string) => {
    dev.log('requestNewSignUpCode() called');

    return Auth.resendSignUp(email)
      .then(() => email)
      .catch((e) => {
        dev.error('requestNewSignUpCode() error', e);
        const err = toCognitoError(e);
        setError(err);
        throw err;
      });
  }, []);

  /**
   * Confirm a users sign up verification code.
   */
  const confirmSignUp = React.useCallback(async (email: string, code: string) => {
    dev.log('confirmSignUp() called');

    return Auth.confirmSignUp(email, code).catch((e) => {
      dev.error('confirmSignUp() error', e);
      const err = toCognitoError(e);
      setError(err);
      throw err;
    });
  }, []);

  /**
   * Request a forgotten password email.
   */
  const forgotPassword = React.useCallback(async (email: string) => {
    dev.log('forgotPassword() called');

    return Auth.forgotPassword(email)
      .then(() => email)
      .catch((e) => {
        dev.error('confirmSignUp() error', e);
        const err = toCognitoError(e);
        setError(err);
        throw err;
      });
  }, []);

  /**
   * Submit a new password with a forgotten password code.
   */
  const forgotPasswordSubmit = React.useCallback(async (email: string, code: string, newPassword: string) => {
    dev.log('forgotPasswordSubmit() called');

    return Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(() => email)
      .catch((e) => {
        dev.error('forgotPasswordSubmit() error', e);
        const err = toCognitoError(e);
        setError(err);
        throw err;
      });
  }, []);

  const changePassword = React.useCallback(
    async (currentPassword: string, newPassword: string) => {
      dev.log('changePassword() called');

      return Auth.changePassword(user, currentPassword, newPassword).catch((e) => {
        dev.error('changePassword() error', e);
        const err = toCognitoError(e);
        setError(err);
        throw err;
      });
    },
    [user],
  );

  const confirmSignIn = React.useCallback(
    async (token: string) => {
      dev.log('confirmSignIn() called');

      return Auth.confirmSignIn(user, token, 'SOFTWARE_TOKEN_MFA')
        .then(() => {
          getAuthenticatedUser();
          setIsAuthenticated(true);
          setChallenge(undefined);
        })
        .catch((e) => {
          dev.error('confirmSignIn() error', e);
          const err = toCognitoError(e);
          setError(err);
          throw err;
        });
    },
    [getAuthenticatedUser, user],
  );

  React.useEffect(() => {
    if (currentUserError && isAuthenticated) {
      signOut().finally(() => {
        setError(currentUserError);
      });
    }
  }, [currentUserError, isAuthenticated, signOut]);

  React.useEffect(() => {
    const init = async () => {
      dev.log('init() called');

      try {
        const currentUser = (await Auth.currentAuthenticatedUser()) as CognitoUser;

        if (!currentUser) {
          signOut().finally(() => {
            setError(new Error('Did not receive user object from auth check'));
          });
        }

        getAuthenticatedUser();

        setUser(currentUser);
        setIsAuthenticated(true);
      } catch (e) {
        dev.error('init() error', e);
        if (e === CognitoStringError.NoUser || e === CognitoStringError.NotAuthenticated) {
          return;
        }
        const err = typeof e === 'string' ? new Error(e) : (e as Error);
        setError(err);
      } finally {
        setTimeout(() => setIsLoading(false), 5000);
      }
    };

    init().catch((e) => {
      const err = toCognitoError(e);
      setError(err);
    });
  }, [signOut, getAuthenticatedUser]);

  return (
    <AuthContext.Provider
      value={{
        isLoading: currentUserLoading || isLoading,
        error,
        challenge,
        isAuthenticated,
        user,
        signIn,
        signOut,
        signUp,
        setNewPassword,
        requestNewSignUpCode,
        confirmSignUp,
        forgotPassword,
        forgotPasswordSubmit,
        changePassword,
        confirmSignIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
