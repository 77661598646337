import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import React from 'react';
import { UserAvatar } from '../../../../../components/Avatar/User';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { Card, ContainerHeaderLoading, ItemLoading, Workspace } from '../../../../../design-system';
import { DateFormat, TimeFormat } from '../../../../../utils/dateFormat';
import { userRoleLabels } from '../../../../../utils/users/FieldDefinitions';
import { useOrganisationUser } from './Provider';

const OrganisationUserOverviewView: React.FC = () => {
  const { error, loading, organisationUser, refetch } = useOrganisationUser();

  if (loading) {
    return (
      <Workspace>
        <ContainerHeaderLoading avatar subText />
        <ItemLoading />
      </Workspace>
    );
  }

  if (error || !organisationUser) {
    return <DataErrorHandler description="Unable to load user" error={error} refetch={refetch} />;
  }

  const { name, email, created } = organisationUser;

  return (
    <Workspace>
      <Grid item xs={12}>
        <Card>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Box>
                  <Typography variant="h6" style={{ wordBreak: 'break-word' }}>
                    {name ?? email}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" style={{ wordBreak: 'break-word' }} paragraph>
                    {email}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    User created{' '}
                    <span title={moment(created).format(`${DateFormat.Long} ${TimeFormat.NoSeconds}`)}>
                      {moment(created).fromNow()}
                    </span>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Role: {userRoleLabels[organisationUser.membership.role]}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <UserAvatar user={organisationUser} />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Workspace>
  );
};

export default OrganisationUserOverviewView;
