import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import type { InstallationControlPoint } from '../../../app/ApiGen';
import type { Normalised } from '../../../utils/request';

export type IcpOption = Normalised<InstallationControlPoint>;

type RenderOptionProps = IcpOption | null;
export const InstallationPointOption: React.VFC<RenderOptionProps> = (props: RenderOptionProps) => {
  const { address, id } = props ?? {};
  if (address && id) {
    return (
      <Box>
        <Typography variant="body1">{id}</Typography>
        <Typography variant="caption">{address}</Typography>
      </Box>
    );
  }
  if (id) {
    return <Typography>&quot;{id}&quot;</Typography>;
  }
  return <Box />;
};
