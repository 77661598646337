import React from 'react';
import { StyledContainerNavigation } from './styled';

export type ContainerNavigationProps = Record<string, unknown>;

const ContainerNavigation: React.FC<ContainerNavigationProps> = ({ children }) => (
  <StyledContainerNavigation>{children}</StyledContainerNavigation>
);

ContainerNavigation.displayName = 'ContainerNavigation';

export default ContainerNavigation;
