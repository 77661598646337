import React from 'react';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { Loading, Workspace } from '../../../../../../design-system';
import { useGetChargePointLogs } from '../../../../../../utils/api';
import { useEndDateState, useSetDateRange, useStartDateState } from '../../../../../../utils/dateRange';
import { useChargePoint } from '../Provider';
import { ChargePointLogsTable } from './components/ChargePointLogsTable';

const LogsView: React.FC = () => {
  const [fromDate, setFromDate] = useStartDateState();
  const [toDate, setToDate] = useEndDateState();
  const setDateRange = useSetDateRange(setFromDate, setToDate);

  const { chargePoint } = useChargePoint();
  const chargePointId: string = chargePoint?.id ?? '';

  const { loading, error, data, refetch } = useGetChargePointLogs({
    chargePointId,
    queryParams: { from: fromDate, to: toDate },
  });

  if (loading && !data) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (error || !data) {
    return <DataErrorHandler description="Unable to load charge point logs" error={error} refetch={refetch} />;
  }

  return (
    <Workspace maxWidth="xl">
      <ChargePointLogsTable
        tableProps={{
          loading,
          refetch,
          chargePointLogs: data.data.items,
        }}
        dateProps={{ dateRange: { fromDate, toDate }, setDateRange }}
      />
    </Workspace>
  );
};

export default LogsView;
