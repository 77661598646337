import EvStationIcon from '@material-ui/icons/EvStation';
import React from 'react';
import { ChargePointAvatar } from '../../../../../../components/Avatar/ChargePoint';
import { ContainerHeader, HeaderSection } from '../../../../../../design-system';
import { ChargePoint } from '../../../../../../utils/api';

export const MenuDataMissing: React.FC<{ chargePoint: ChargePoint | undefined }> = ({ chargePoint }) => (
  <>
    <HeaderSection>
      {() => (
        <ContainerHeader
          avatar={
            <ChargePointAvatar>
              <EvStationIcon />
            </ChargePointAvatar>
          }
          text={chargePoint?.name ?? 'Unknown'}
          subText="Charge point"
        />
      )}
    </HeaderSection>
  </>
);
