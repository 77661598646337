import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { fullStateSelector } from '../../../../store/root';
import AddUserView from './AddUser';

const AddView: React.FC = () => {
  const { path } = useRouteMatch();
  const { isEvnexAdmin } = useSelector(fullStateSelector);

  return (
    <Switch>
      {isEvnexAdmin && (
        <Route path={`${path}/user`}>
          <AddUserView />
        </Route>
      )}
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default AddView;
