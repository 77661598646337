import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { Field as FormikField, FormikErrors, FormikTouched, FormikValues } from 'formik';
import * as React from 'react';
import { uid } from 'react-uid';
import { Box, Grid, Typography } from '@material-ui/core';
import { Detail } from '../../../Detail';
import { FieldDefinition, FieldDefinitions } from '../../../../utils/formUtils';
import { ConnectorCreate } from '../../../../utils/api';

type ConnectorSectionProps = {
  name: string;
  sectionId: number;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  fields: FieldDefinitions<ConnectorCreate>;
  arrayHelpers: { remove: (id: number) => void };
};

export const ConnectorSection: React.FC<ConnectorSectionProps> = ({
  name,
  sectionId,
  errors,
  touched,
  fields,
  arrayHelpers,
}) => {
  function getItem<T>(item: FormikErrors<FormikValues> | FormikTouched<FormikValues>): Record<string, T> {
    const itemByName = item && item[name];
    if (itemByName) {
      const itemsAsRecord = itemByName as Record<string, T>[];
      return itemsAsRecord[sectionId] ?? {};
    }
    return {};
  }

  const fieldErrors: Record<string, string> = getItem<string>(errors);
  const fieldsTouched: Record<string, boolean> = getItem<boolean>(touched);

  return (
    <Box>
      <Grid container justify="space-between">
        <Typography>{`Connector ${sectionId + 1}`}</Typography>
        <IconButton size="small" onClick={() => arrayHelpers.remove(sectionId)}>
          <CloseIcon />
        </IconButton>
      </Grid>
      {fields.map(
        ({ id, label, inputComponent: InputComponent, inputProps = {} }: FieldDefinition<ConnectorCreate>, idx) => {
          const error: string = fieldsTouched[id] && !!fieldErrors[id] ? fieldErrors[id] : '';
          const styling = { paddingTop: '8px' };
          return (
            <FormikField key={uid(id, idx)} name={`${name}.${sectionId}.${id}`}>
              {({ field }: any) => (
                <Detail label={label}>
                  {InputComponent ? (
                    <InputComponent style={styling} error={!!error} helperText={error} {...field} />
                  ) : (
                    <TextField
                      style={styling}
                      error={!!error}
                      helperText={error}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      inputProps={{ 'aria-label': `${id}-input` }}
                      {...field}
                      {...inputProps}
                    />
                  )}
                </Detail>
              )}
            </FormikField>
          );
        },
      )}
    </Box>
  );
};
