import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Location, useGetOrganisationLocations } from '../../../../app/ApiGen';
import { singleLineAddress } from '../../../../components/Address';
import { getToolbarSearch, RefreshButton } from '../../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../../components/ErrorHandler';
import { ExportCsv, ExportProps } from '../../../../components/ExportCsv';
import { TableLink } from '../../../../components/Link';
import { NoLocations } from '../../../../components/NoContentPlaceholder/NoLocations';
import { ColumnChanger, ColumnChangerProps, DataTable, Loading, Section, Workspace } from '../../../../design-system';
import { fullStateSelector } from '../../../../store/root';
import { navigateOnRowClick } from '../../../../utils/dataTable/rowHelpers';
import { normalise, Normalised } from '../../../../utils/request';
import { addressIncludesSearch } from '../../../../utils/search';
import { sortByAddress, sortByLocale } from '../../../../utils/sortBy';

type TableLocation = Pick<Normalised<Location>, 'address' | 'icpNumber' | 'id' | 'name'> & {
  chargePointCount: number;
  gridExitPoint?: string;
  electricityDistributor?: string;
  electricityRetailer?: string;
};

const getTableLocations = (locations: Normalised<Location>[]): TableLocation[] =>
  locations.map((location) => {
    const { address, chargePoints, icpNumber, icpDetails, id, name } = location;
    return {
      address,
      chargePointCount: chargePoints.length,
      icpNumber,
      gridExitPoint: icpDetails?.networkConnectionPoint,
      electricityDistributor: icpDetails?.electricityDistributor,
      electricityRetailer: icpDetails?.electricityRetailer,
      id,
      name,
    };
  });

const LocationListView: React.FC = () => {
  const { url, params } = useRouteMatch<{ slug: string }>();
  const history = useHistory();
  const { isAdmin, isDriver, isEvnexAdmin } = useSelector(fullStateSelector);

  const orgSlug: string = params.slug;
  const { loading, error, data, refetch } = useGetOrganisationLocations({ orgSlug });

  if (loading && !data) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (error || !data) {
    return <DataErrorHandler description="Unable to load locations" error={error} refetch={refetch} />;
  }

  const locations = normalise(data);

  if (locations.length === 0) {
    return (
      <Workspace maxWidth="xl">
        <Section>
          <Card>
            <Grid style={{ padding: 30 }} container direction="column" justify="center" alignItems="center">
              <NoLocations orgSlug={orgSlug} isDriver={isDriver} />
            </Grid>
          </Card>
        </Section>
      </Workspace>
    );
  }

  return (
    <Workspace maxWidth="xl">
      <Card>
        <DataTable<TableLocation>
          isLoading={loading}
          options={{ columnsButton: true }}
          toolbarProps={{
            ...getToolbarSearch('locations'),
            actions: (props: React.PropsWithChildren<ColumnChangerProps> & ExportProps<TableLocation>) => (
              <>
                <ColumnChanger
                  columnsButton={props.columnsButton}
                  columns={props.columns}
                  icons={props.icons}
                  onColumnsChanged={props.onColumnsChanged}
                />
                <RefreshButton refetch={refetch} />
                <ExportCsv<TableLocation>
                  columns={props.columns}
                  data={props.data}
                  exportFileName={`organisation-${orgSlug}-locations`}
                  getFieldValue={props.getFieldValue}
                  icons={props.icons}
                />
                {(isAdmin || isEvnexAdmin) && (
                  <Button
                    component={Link}
                    to={`/organisations/${orgSlug}/create/location`}
                    color="primary"
                    variant="contained"
                  >
                    Add location
                  </Button>
                )}
              </>
            ),
          }}
          columns={[
            {
              field: 'name',
              title: 'Name',
              defaultSort: 'asc',
              customSort: sortByLocale('name'),
              render: ({ id, name }: Pick<TableLocation, 'id' | 'name'>) => (
                <TableLink to={`${url}/${id}`}>{name}</TableLink>
              ),
            },
            {
              field: 'address',
              title: 'Address',
              render: singleLineAddress,
              customSort: sortByAddress('address'),
              customFilterAndSearch: (search: string, { address }: TableLocation) =>
                addressIncludesSearch(address, search),
            },
            {
              field: 'chargePointCount',
              title: 'Charge points',
            },
            { field: 'icpNumber', title: 'ICP/NMI number', hidden: true },
            { field: 'gridExitPoint', title: 'GXP', hidden: true },
            { field: 'electricityDistributor', title: 'Electricity Distributor', hidden: true },
            { field: 'electricityRetailer', title: 'Electricity Retailer', hidden: true },
          ]}
          data={getTableLocations(locations)}
          onRowClick={navigateOnRowClick((rowData) => `${url}/${rowData.id}`, history)}
        />
      </Card>
    </Workspace>
  );
};

export default LocationListView;
