import { useSnackbar } from 'notistack';
import React from 'react';
import { Job, JobStatus, RequestUpdateJob, useGetAttachments, useUpdateJob } from '../../../../app/ApiGen';
import { DataErrorHandler } from '../../../../components/ErrorHandler';
import { errorSnackbar } from '../../../../components/ErrorSnackbar';
import { Card, Form, FormLoading, FormReadonly, OnSubmit, Section } from '../../../../design-system';
import {
  attachedFilesField,
  InstallationDetails,
  installationDetailsFields,
  installationDetailsValidationSchema,
  JobAttachments,
  toInstallationDetailsFields,
  toJobAttachmentsField,
} from '../../../../utils/jobs/FieldDefinitions';
import { jobEditable } from '../../../../utils/jobs/status';
import { Normalised } from '../../../../utils/request';

interface InstallationSectionProps {
  job: Normalised<Job>;
  refetchJob: () => Promise<void>;
}

function mapInstallationDetailsToRequestUpdateJob(args: {
  jobId: string;
  update: InstallationDetails;
  jobStatus: JobStatus;
}): RequestUpdateJob {
  const { jobId, update } = args;
  const { referenceId } = update;

  const attributes: RequestUpdateJob['data']['attributes'] = {
    referenceId: referenceId?.length === 0 ? undefined : referenceId ?? undefined,
  };

  return { data: { id: jobId, type: 'jobs', attributes } };
}

export const InstallationDetailsSection: React.VFC<InstallationSectionProps> = ({
  job,
  refetchJob,
}: InstallationSectionProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { id: jobId, status: jobStatus } = job;

  const {
    data: attachments,
    loading: attachmentsLoading,
    error: attachmentsError,
    refetch: refetchAttachments,
  } = useGetAttachments({ jobId });
  const { mutate: updateJob } = useUpdateJob({ jobId });

  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const placeholderFields = Object.values(installationDetailsFields());

  const onSubmit = React.useCallback<OnSubmit<InstallationDetails>>(
    async (update, { setSubmitting }) => {
      try {
        await updateJob(mapInstallationDetailsToRequestUpdateJob({ jobId, jobStatus, update }));
        enqueueSnackbar('Job updated', { variant: 'success' });
        setSubmitting(false);
        setIsEditing(false);
        await refetchJob();
      } catch (err) {
        setSubmitting(false);
        errorSnackbar('Unable to edit job', closeSnackbar, enqueueSnackbar, err);
      }
    },
    [closeSnackbar, enqueueSnackbar, jobId, jobStatus, refetchJob, updateJob],
  );

  if (attachmentsLoading) {
    return (
      <Section data-testid="skeleton">
        <Card>
          <FormLoading fields={placeholderFields} />
        </Card>
      </Section>
    );
  }

  const attachmentsAlert =
    attachmentsError || !attachments ? (
      <DataErrorHandler
        error={attachmentsError}
        description="Unable to load attached files"
        refetch={refetchAttachments}
        severity="info"
      />
    ) : null;

  const initialDetailsValues = toInstallationDetailsFields(job);
  const initialAttachmentValues = { attachments: toJobAttachmentsField(attachments?.data) };

  return (
    <Section>
      <Card title="Installation">
        {jobEditable(jobStatus) ? (
          <Form<InstallationDetails>
            fields={placeholderFields}
            initialValues={initialDetailsValues}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            onSubmit={onSubmit}
            validationSchema={installationDetailsValidationSchema}
          />
        ) : (
          <FormReadonly<InstallationDetails> fields={placeholderFields} initialValues={initialDetailsValues} />
        )}
        {attachmentsAlert}
        <FormReadonly<JobAttachments> fields={[attachedFilesField]} initialValues={initialAttachmentValues} />
      </Card>
    </Section>
  );
};
