import * as React from 'react';
import { DetailsOtherStep } from './DetailsOtherStep';
import { DetailsEvnexStep } from './DetailsEvnexStep';
import { useWizard } from '../../../../../../design-system';

export const DetailsStep: React.FC = () => {
  const wizard = useWizard();
  const { state } = (wizard as unknown) as { state: { vendor: string } };

  switch (state.vendor) {
    case 'evnex':
      return <DetailsEvnexStep />;
    default:
      return <DetailsOtherStep />;
  }
};
