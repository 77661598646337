import { useSnackbar } from 'notistack';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { errorSnackbar } from '../../ErrorSnackbar';
import { SearchField, SearchFieldProps } from '../../../design-system';

type ColumnFiltersProps = {
  filtering: boolean;
  setFiltering: React.Dispatch<React.SetStateAction<boolean>>;
};

export const getToolbarSearch = (
  item: string,
): {
  search: (props: SearchFieldProps) => JSX.Element;
} => ({
  search: ({ search, searchText, onSearchChanged }: SearchFieldProps) => (
    <SearchField
      search={search}
      searchText={searchText}
      onSearchChanged={onSearchChanged}
      placeholder={`Search ${item}...`}
    />
  ),
});

export const RefreshButton: React.FC<{ refetch: () => Promise<void> }> = ({ refetch }) => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const onRefresh = React.useCallback(() => {
    refetch().catch((err) => {
      errorSnackbar('Unable to refresh table data', closeSnackbar, enqueueSnackbar, err);
    });
  }, [closeSnackbar, enqueueSnackbar, refetch]);

  return (
    <Tooltip title="Refresh">
      <IconButton onClick={onRefresh}>
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  );
};

export const ColumnFiltersToggle: React.FC<ColumnFiltersProps> = ({ filtering, setFiltering }) => (
  <Tooltip title={`${filtering ? 'Disable' : 'Enable'} column filters`}>
    <IconButton onClick={() => setFiltering((prev) => !prev)}>
      <FilterListIcon />
    </IconButton>
  </Tooltip>
);
