import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';
import { IconWrapper, IndicatorBadge, IndicatorDot } from './styled';
import { IndicatorColor, IndicatorVariant } from './types';

export interface IndicatorProps {
  variant?: IndicatorVariant;
  color: IndicatorColor;
  icon?: React.ReactNode;
  label?: React.ReactNode;
  toolTipText?: React.ReactNode;
}

const Indicator: React.FC<IndicatorProps> = ({ variant = 'badge', color, icon, label, toolTipText }) => {
  const badge = (
    <IndicatorBadge color={color}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {label && <span>{label}</span>}
    </IndicatorBadge>
  );
  switch (variant) {
    case 'dot':
      if (toolTipText) {
        return (
          <Tooltip
            title={
              <div style={{ display: 'flex' }}>
                {icon && <IconWrapper>{icon}</IconWrapper>}
                {toolTipText && <span>{toolTipText}</span>}
              </div>
            }
          >
            <IndicatorDot color={color} />
          </Tooltip>
        );
      }
      return <IndicatorDot color={color} />;

    default:
      if (toolTipText) {
        return (
          <Tooltip
            title={
              <div style={{ display: 'flex' }}>
                {icon && <IconWrapper>{icon}</IconWrapper>}
                {label && <span>{label}</span>}
              </div>
            }
          >
            {badge}
          </Tooltip>
        );
      }
      return badge;
  }
};

Indicator.displayName = 'Indicator';

export { Indicator };
