import { UserOrganisation } from '../../app/ApiGen';

export enum OrganisationAction {
  SET_CURRENT_ORGANISATION = '@@organisation/SET_CURRENT_ORGANISATION',
  CLEAR_CURRENT_ORGANISATION = '@@organisation/CLEAR_CURRENT_ORGANISATION',
  CHANGE_CURRENT_ORGANISATION = '@@organisation/CHANGE_CURRENT_ORGANISATION',
}

export interface OrganisationState {
  loading: boolean;
  current?: UserOrganisation;
}
