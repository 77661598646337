import React from 'react';
import type { NavigationView, ViewType } from '../types';

const stub = () => {
  throw new Error('useNavigation must be used within a NavigationProvider component');
};

export interface NavigationContextProps {
  views: Record<string, NavigationView>;
  addView: (view: NavigationView) => void;
  setView: (id: string) => void;
  clearView: (type: ViewType) => void;
  productViewId: string | undefined;
  containerViewId: string | undefined;
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const NavigationContext = React.createContext<NavigationContextProps>({
  productViewId: undefined,
  containerViewId: undefined,
  views: {},
  addView: stub,
  setView: stub,
  clearView: stub,
  isCollapsed: false,
  toggleCollapse: stub,
});

export function useNavigation(): NavigationContextProps {
  return React.useContext(NavigationContext);
}

const NavigationProvider: React.FC<{ mock?: NavigationContextProps }> = ({ children, mock }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const toggleCollapse = React.useCallback(() => setIsCollapsed((prev) => !prev), []);

  const [views, setViews] = React.useState<NavigationContextProps['views']>({});
  const [productViewId, setProductViewId] = React.useState<NavigationContextProps['productViewId']>(undefined);
  const [containerViewId, setContainerViewId] = React.useState<NavigationContextProps['containerViewId']>(undefined);

  const addView = React.useCallback(
    (view: NavigationView) => {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console -- Development environment only.
        console.log('[NavigationProvider] adding view:', view);
      }

      setViews((prev) => ({ ...prev, [view.id]: view }));
    },
    [setViews],
  );

  const setView = React.useCallback(
    (id: string) => {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console -- Development environment only.
        console.log('[NavigationProvider] setting view:', id);
      }

      const view = views[id];

      if (!view) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console -- Development environment only.
          console.error(`[NavigationProvider.setView] Unknown viewId '${id}'`);
        }
        return;
      }

      if (view.type === 'product') {
        setProductViewId(view.id);
        return;
      }

      if (view.type === 'container') {
        setContainerViewId(view.id);
      }
    },
    [views, setProductViewId, setContainerViewId],
  );

  const clearView = React.useCallback(
    (type: ViewType) => {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console -- Development environment only.
        console.log('[NavigationProvider] clearing view:', type);
      }

      if (type === 'product') {
        setProductViewId(undefined);
        return;
      }

      if (type === 'container') {
        setContainerViewId(undefined);
      }
    },
    [setProductViewId, setContainerViewId],
  );

  return (
    <NavigationContext.Provider
      value={
        mock ?? { productViewId, containerViewId, views, addView, setView, clearView, isCollapsed, toggleCollapse }
      }
    >
      {children}
    </NavigationContext.Provider>
  );
};

NavigationProvider.displayName = 'NavigationProvider';

export default NavigationProvider;
