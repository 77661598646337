import Button from '@material-ui/core/Button';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment-timezone';
import * as React from 'react';
import { DayIconButton, DayWrapper, StyledRange } from './styled';
import { DateFormat } from '../../utils/dateFormat';

interface DayWrapperGenericProps {
  day: MaterialUiPickersDate;
  isButtonDisabled: boolean;
  isDayBetween: boolean;
  isDayInCurrentMonth: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
}

interface DateRangeProps {
  fromDate: string;
  toDate: string;
  onApply: (fromDate: string, toDate: string) => void;
}

const getIsoString = (day: MaterialUiPickersDate): string | undefined => (day ? day.toISOString() : undefined);

const isSameDay = (day: MaterialUiPickersDate, inputDate?: string): boolean =>
  day ? day.isSame(inputDate, 'day') : false;

const isBetween = (day: MaterialUiPickersDate, firstDate?: string, secondDate?: string): boolean =>
  day ? day.isBetween(firstDate, secondDate, 'days') : false;

const isBefore = (day: MaterialUiPickersDate, inputDate?: string): boolean =>
  day ? day.isBefore(inputDate, 'day') : false;

const isAfter = (day: MaterialUiPickersDate, inputDate?: string): boolean =>
  day ? day.isAfter(inputDate, 'day') : false;

const formattedDate = (day: MaterialUiPickersDate): string => (day ? day.format('D') : '');

const DayView: React.FC<DayWrapperGenericProps> = ({
  day,
  isButtonDisabled,
  isDayBetween,
  isDayInCurrentMonth,
  isFirstDay,
  isLastDay,
}: DayWrapperGenericProps) => (
  <DayWrapper
    isDayInCurrentMonth={isDayInCurrentMonth}
    isDayBetween={!isLastDay && isDayBetween}
    isFirstDay={isFirstDay}
    isLastDay={isLastDay}
  >
    <DayIconButton disabled={isButtonDisabled} isSelected={isFirstDay || isDayBetween || isLastDay}>
      <span>{formattedDate(day)}</span>
    </DayIconButton>
  </DayWrapper>
);

const renderHighlightedDaysTo = (endOfRange: string) => (
  day: MaterialUiPickersDate,
  selectedDay: MaterialUiPickersDate,
  isDayInCurrentMonth: boolean,
) => {
  const selectedDayString = getIsoString(selectedDay);
  const endOfRangeString = moment(endOfRange).toISOString();
  const isFirstDay = isSameDay(day, selectedDayString);
  const isLastDay = isSameDay(day, endOfRangeString);

  return (
    <DayView
      day={day}
      isButtonDisabled={!isDayInCurrentMonth || isAfter(day, endOfRangeString)}
      isDayInCurrentMonth={isDayInCurrentMonth}
      isDayBetween={!isLastDay && isBetween(day, selectedDayString, endOfRangeString)}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  );
};

const renderHighlightedDaysFrom = (startOfRange: string) => (
  day: MaterialUiPickersDate,
  selectedDay: MaterialUiPickersDate,
  isDayInCurrentMonth: boolean,
) => {
  const selectedDayString = getIsoString(selectedDay);
  const startOfRangeString = moment(startOfRange).toISOString();
  const isFirstDay = isSameDay(day, startOfRangeString);
  const isLastDay = isSameDay(day, selectedDayString);

  return (
    <DayView
      day={day}
      isButtonDisabled={!isDayInCurrentMonth || isBefore(day, startOfRangeString)}
      isDayInCurrentMonth={isDayInCurrentMonth}
      isDayBetween={!isLastDay && isBetween(day, startOfRangeString, selectedDayString)}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  );
};

export const DateRangePicker: React.FC<DateRangeProps> = ({ fromDate, toDate, onApply }) => {
  const [from, setFrom] = React.useState(fromDate);
  const [to, setTo] = React.useState(toDate);

  return (
    <StyledRange>
      <DatePicker
        disableToolbar
        label="From"
        variant="inline"
        autoOk
        inputVariant="outlined"
        InputProps={{ margin: 'dense', style: { margin: 0 } }}
        format={DateFormat.Short}
        value={from}
        renderDay={renderHighlightedDaysTo(to)}
        onChange={(date: MaterialUiPickersDate) => {
          if (date) {
            setFrom(date.toISOString());
          }
        }}
      />
      <DatePicker
        disableToolbar
        label="To"
        variant="inline"
        autoOk
        inputVariant="outlined"
        InputProps={{ margin: 'dense', style: { margin: 0 } }}
        format={DateFormat.Short}
        value={to}
        renderDay={renderHighlightedDaysFrom(from)}
        onChange={(date: MaterialUiPickersDate) => {
          if (date) {
            setTo(date.toISOString());
          }
        }}
      />
      <Button color="primary" variant="contained" onClick={() => onApply(from, to)}>
        Search
      </Button>
    </StyledRange>
  );
};
