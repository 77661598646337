import { captureException, captureMessage } from '@sentry/browser';
import { AxiosError } from 'axios';
import type { APIError } from '../app/ApiGen';
import type { APIError as LegacyApiError } from './api';
import { CognitoStringError } from '../app/AuthProvider';

export type APIErrorAndError = APIError & Error;
export const NOT_FOUND_API_ERROR_STATUS = '404';

function isCognitoError(e: unknown): e is { code: string; message: string } {
  if (typeof e !== 'object' || !e) {
    return false;
  }

  const { code, message, status, name } = e as Record<string, unknown>;
  if (name === 'AxiosError') {
    return false;
  }

  return typeof code === 'string' && typeof message === 'string' && status === undefined;
}

function isLegacyApiError(e: unknown): e is LegacyApiError {
  if (typeof e !== 'object' || !e) {
    return false;
  }
  const { code, message, correlationId, title } = e as Record<string, unknown>;
  return (
    typeof code === 'number' &&
    typeof title === 'string' &&
    (typeof message === 'string' || typeof message === 'undefined') &&
    (typeof correlationId === 'string' || typeof correlationId === 'undefined')
  );
}

function isAxiosErrorCheck(e: unknown): e is AxiosError {
  if (typeof e !== 'object' || !e) {
    return false;
  }
  const { response, isAxiosError, detail, status } = e as Record<string, unknown>;
  const respObj = typeof response === 'object' && response ? (response as Record<string, unknown>) : {};
  const { statusText, status: responseStatus } = respObj;

  return (
    typeof isAxiosError === 'boolean' &&
    isAxiosError &&
    typeof responseStatus === 'number' &&
    (typeof status === 'string' || typeof status === 'undefined') &&
    (typeof detail === 'string' || typeof detail === 'undefined') &&
    (typeof statusText === 'string' || typeof statusText === 'undefined')
  );
}

function isApiError(e: unknown): e is APIError {
  if (typeof e !== 'object' || !e) {
    return false;
  }
  const { status, title, id, code, detail, meta, source } = e as Record<string, unknown>;
  const metaObj = typeof meta === 'object' && meta ? (meta as Record<string, unknown>) : {};
  const { correlationId } = metaObj;
  const sourceObj = typeof source === 'object' && source ? (source as Record<string, unknown>) : {};
  const { parameter, pointer } = sourceObj;

  return (
    typeof status === 'string' &&
    typeof title === 'string' &&
    (typeof id === 'string' || typeof id === 'undefined') &&
    (typeof code === 'string' || typeof code === 'undefined') &&
    (typeof detail === 'string' || typeof detail === 'undefined') &&
    (typeof correlationId === 'string' || typeof correlationId === 'undefined') &&
    (typeof parameter === 'string' || typeof parameter === 'undefined') &&
    (typeof pointer === 'string' || typeof pointer === 'undefined')
  );
}

const baseError: APIErrorAndError = Object.assign(new Error('An unknown API error occurred.'), {
  status: '500',
  title: 'ServerError',
  name: 'ServerError',
  detail: 'An unknown API error occurred.',
});

/**
 * Transform an error API response into a single throwable Error.
 */
export function transformErrorResponse({ errors }: { errors: unknown[] }): APIErrorAndError {
  if (errors.length < 1) {
    return baseError;
  }
  const [error] = errors;

  if (isAxiosErrorCheck(error)) {
    return Object.assign(new Error(error.message), {
      name: error.name,

      status: error.response?.status ? error.response.status.toString() : '',
      title: error.name,
    });
  }

  if (isApiError(error)) {
    return Object.assign(new Error(error.detail), { name: error.title, ...error });
  }
  if (isLegacyApiError(error)) {
    return Object.assign(new Error(error.message), {
      name: error.title,
      title: error.title,
      detail: error.message,
      status: error.code.toString(10),
      meta: error.correlationId ? { correlationId: error.correlationId } : undefined,
    });
  }
  if (error instanceof Error) {
    // Unrecognised error object; probably originated client-side
    captureException(error);
    return Object.assign(error, { title: error.name, detail: error.message, status: baseError.status });
  }
  // Totally unknown error type, but probably did not originate client-side
  return baseError;
}

/**
 * Transform any value into a single throwable Error that has the fields of an APIError, filled with default values if
 * any fields are missing. If error did not originate from our backend, log it in Sentry.
 */
export function standardiseError(e: unknown): APIErrorAndError {
  if (typeof e === 'string') {
    if (e !== CognitoStringError.NoUser) {
      captureException(e);
    }
    return Object.assign(new Error(e), { status: '400', title: 'ClientError', name: 'ClientError', detail: e });
  }
  if (isCognitoError(e)) {
    captureException(e);
    const { code, message } = e;
    return Object.assign(new Error(message), { title: code, name: code, status: '401', detail: message });
  }
  if (!e) {
    return baseError;
  }
  if (typeof e !== 'object') {
    captureMessage(`Thrown error could not be parsed as an object: ${JSON.stringify(e)}`);
    return baseError;
  }
  const { data, errors } = e as Record<string, unknown>;
  if (data) {
    return standardiseError(data);
  }
  return transformErrorResponse({ errors: Array.isArray(errors) ? errors : [e] });
}
