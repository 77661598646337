import countries from 'i18n-iso-countries';
import * as Yup from 'yup';
import { Address as IAddress, Address as AddressType } from '../api';

export function isAustralia(countryCode: string): boolean {
  return countryCode === 'AU';
}

export const AustralianState = {
  ACT: 'Australian Capital Territory',
  NSW: 'New South Wales',
  NT: 'Northern Territory',
  QLD: 'Queensland',
  SA: 'South Australia',
  TAS: 'Tasmania',
  VIC: 'Victoria',
  WA: 'Western Australia',
};

export const MAX_ADDRESS_ATTRIBUTE_LENGTH = 999;

/* eslint no-template-curly-in-string: "off" --- Yup messages can be customised using template curlies in strings. */
export const addressValidationSchema = Yup.object().shape<IAddress>({
  address1: Yup.string()
    .trim()
    .required('Address line one is required')
    .max(MAX_ADDRESS_ATTRIBUTE_LENGTH, 'Address line one cannot exceed ${max} characters'),
  address2: Yup.string().trim().max(MAX_ADDRESS_ATTRIBUTE_LENGTH, 'Address line two cannot exceed ${max} characters'),
  address3: Yup.string().trim().max(MAX_ADDRESS_ATTRIBUTE_LENGTH, 'Address line three cannot exceed ${max} characters'),
  city: Yup.string()
    .trim()
    .required('City is required')
    .max(MAX_ADDRESS_ATTRIBUTE_LENGTH, 'City cannot exceed ${max} characters'),
  postCode: Yup.string().trim().max(MAX_ADDRESS_ATTRIBUTE_LENGTH, 'Postcode cannot exceed ${max} characters'),
  state: Yup.string()
    .trim()
    .max(MAX_ADDRESS_ATTRIBUTE_LENGTH, 'State cannot exceed ${max} characters')
    .test('validState', 'Must be a valid state', (state, { parent, createError }) => {
      const formValues = parent as IAddress;
      if (!isAustralia(formValues.country)) {
        return true;
      }
      if (!state) {
        return createError({ message: 'State is required for Australian addresses' });
      }
      const isAustralianState = Object.values(AustralianState).includes(state);
      if (!isAustralianState) {
        return createError({ message: 'Must be a valid Australian state' });
      }
      return true;
    }),
  country: Yup.string()
    .trim()
    .required('Country is required')
    .length(2, 'Country code must be exactly ${length} characters'),
});

export const getAddressAsString = ({
  address1,
  address2,
  address3,
  city,
  state,
  postCode,
  country,
}: AddressType): string =>
  [address1, address2, address3, city, state, postCode, countries.getName(country, 'en')].filter(Boolean).join(', ');

export const isAddress = (value: unknown): value is AddressType => {
  const address = value as AddressType;
  return !!address.address1 && !!address.city && !!address.country;
};
