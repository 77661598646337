import * as React from 'react';

export type ViewType = 'product' | 'container';

export interface NavigationView {
  id: string;
  type: ViewType;
  Items: React.ComponentType;
}

export enum ProductId {
  CLIENT,
  PARTNER,
  ADMIN,
}
