import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Form as FormikForm, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { AutocompleteContents, renderAutocompleteInput } from '../../../../components/CustomFields/SimpleAutocomplete';
import { Dialog } from '../../../../components/Dialog';
import { FormEditable, InputComponentProps, OnSubmit, Section, useWizard } from '../../../../design-system';
import { ConfigurationKey } from '../../../../utils/chargePoints/commands';
import { createSchema, FieldDefinitions } from '../../../../utils/formUtils';
import { ChangeConfigurationStepFields, CommandsWizardState, CommandType } from '../types';

const configurationKeyOptions: ConfigurationKey[] = [
  'APN',
  'AllowOfflineTxForUnknownId',
  'AuthorisationEnabled',
  'AuthorizationKey',
  'AuthorizeRemoteTxRequests',
  'AuxRelayCtrl',
  'BluetoothPassword',
  'CableLockMode',
  'ChargePointIdentity',
  'ClockAlignedDataInterval',
  'ConnectionTimeOut',
  'CpoName',
  'HeartbeatInterval',
  'LocalAuthorizeOffline',
  'LockCableUntilFinished',
  'MeterValueSampleInterval',
  'MeterValuesAlignedData',
  'MeterValuesSampledData',
  'MobileNetworkOperator',
  'OCPPEndPoint',
  'PowerSensorInstalled',
  'RFIDTagReaderEnabled',
  'ResetRetries',
  'SecurityProfile',
  'SolarStartExportPower',
  'SolarWithSchedule',
  'StopTransactionOnEVSideDisconnect',
  'StopTransactionOnInvalidId',
  'StopTxnAlignedData',
  'StopTxnSampledData',
  'SupplyCurrentLimit',
  'TransactionMessageAttempts',
  'TransactionMessageRetryInterval',
  'UnlockConnectorOnEVSideDisconnect',
  'WebSocketPingInterval',
  'WifiPassphrase',
  'WifiSSID',
];

const fields: FieldDefinitions<ChangeConfigurationStepFields> = [
  {
    id: 'configurationKey',
    label: 'Key',
    inputComponent: ({
      disabled,
      error,
      helperText,
      name,
      onChange,
      value: selectedResetType,
    }: InputComponentProps<ConfigurationKey>) => (
      <Autocomplete<ConfigurationKey, false, true>
        disableClearable
        fullWidth
        getOptionLabel={(option) => option ?? ''}
        handleHomeEndKeys
        onChange={(event, command) => {
          onChange(name)({ ...event, target: { ...event.target, value: command } });
        }}
        options={configurationKeyOptions}
        renderInput={renderAutocompleteInput(disabled, error, helperText)}
        value={selectedResetType}
      />
    ),
    valueComponent: ({ value }: AutocompleteContents<ConfigurationKey>) => <>{value}</>,
  },
  { id: 'configurationValue', label: 'Value' },
];

const validationSchema = createSchema<ChangeConfigurationStepFields>({
  configurationKey: Yup.string().required('Key is required'),
  configurationValue: Yup.string().required('Value is required').max(500, 'Value must be 500 characters or less'),
});

export const ChangeConfigurationStep: React.FC = () => {
  const { state: untypedState, setState: untypedSetState, prevStep, nextStep } = useWizard();
  const state = untypedState as Partial<CommandsWizardState>;
  const setState = untypedSetState as (state: Partial<CommandsWizardState>) => void;

  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

  const onCancel = React.useCallback<OnSubmit<ChangeConfigurationStepFields>>(
    ({ configurationKey, configurationValue }) => {
      if (isConfirmationDialogOpen) {
        setConfirmationDialogOpen(false);
      } else {
        setState({ command: { type: CommandType.CHANGE_CONFIGURATION, configurationKey, configurationValue } });
        prevStep();
      }
    },
    [setState, prevStep, isConfirmationDialogOpen],
  );

  const onClickNext = React.useCallback<OnSubmit<ChangeConfigurationStepFields>>(async (_, { setSubmitting }) => {
    setSubmitting(false);
    setConfirmationDialogOpen(true);
  }, []);

  const onSubmit = React.useCallback<OnSubmit<ChangeConfigurationStepFields>>(
    ({ configurationKey, configurationValue }, { setFieldError, setSubmitting }) => {
      setSubmitting(true);
      setFieldError('configurationKey', undefined);
      setFieldError('configurationValue', undefined);
      setState({ command: { type: CommandType.CHANGE_CONFIGURATION, configurationKey, configurationValue } });
      nextStep();
    },
    [nextStep, setState],
  );

  const initialValues = {
    configurationKey: state.command?.configurationKey ?? '',
    configurationValue: state.command?.configurationValue ?? '',
  };

  return (
    <>
      <Section
        title="Enter configuration key and value"
        description="Clicking 'Next' will start updating the configuration on the charge points."
      >
        <Formik<ChangeConfigurationStepFields>
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formikHelpers) =>
            isConfirmationDialogOpen ? onSubmit(values, formikHelpers) : onClickNext(values, formikHelpers)
          }
          validateOnChange
        >
          {(formikProps) => {
            const { errors, handleSubmit, isSubmitting, touched, values } = formikProps;
            return (
              <FormikForm>
                <FormEditable fields={fields} errors={errors} touched={touched} disabled={isSubmitting} />
                <Box pt={2} display="flex" flexDirection="row-reverse">
                  <Button color="primary" type="submit" variant="contained">
                    Next
                  </Button>
                  <Box mr="auto" />
                  <Button variant="contained" onClick={() => onCancel(values, formikProps)}>
                    Back
                  </Button>
                </Box>
                <Dialog
                  hasCloseButton={false}
                  isOpen={isConfirmationDialogOpen}
                  onClose={() => setConfirmationDialogOpen(!isConfirmationDialogOpen)}
                  title="Caution"
                >
                  <DialogContent>
                    <Alert severity="warning">
                      Incorrectly changing these variables can render charge points inoperative and unable to charge
                      vehicles or permanently disable their ability to connect to our network.
                    </Alert>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setConfirmationDialogOpen(false)} variant="contained">
                      Cancel
                    </Button>
                    <Button color="primary" onClick={() => handleSubmit()} variant="contained">
                      {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Send command'}
                    </Button>
                  </DialogActions>
                </Dialog>
              </FormikForm>
            );
          }}
        </Formik>
      </Section>
    </>
  );
};
