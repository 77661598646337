import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MigrateLocationView } from './Locations/MigrateLocation';
import { mainNav } from '../navView';
import { useNavigationView } from '../../design-system';

export const MigrateView: React.FC = () => {
  const { path } = useRouteMatch();
  useNavigationView(mainNav);

  return (
    <Switch>
      <Route path={`${path}/locations`}>
        <MigrateLocationView />
      </Route>
    </Switch>
  );
};
