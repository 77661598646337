import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useListLocations, useListOrganisations, useMoveLocation } from '../../../app/ApiGen';
import { labelsFor, OrganisationTier } from '../../../app/enums';
import { getLocationAvatar } from '../../../components/Avatar/Location';
import { getOrganisationAvatar } from '../../../components/Avatar/Organisation';
import { DataErrorHandler, ErrorHandler } from '../../../components/ErrorHandler';
import { errorSnackbar } from '../../../components/ErrorSnackbar';
import { SelectableItem, SelectionList } from '../../../components/SelectionList';
import { Loading, Section, Workspace } from '../../../design-system';
import { getAddressAsString } from '../../../utils/locations/address';
import { normalise } from '../../../utils/request';

interface SelectItemProps {
  error?: string;
  onSelect: (item: SelectableItem) => void;
}

interface OrgSelectProps extends SelectItemProps {
  loading: boolean;
  onSubmit: () => void;
  selectedLocation: SelectableItem | null;
  selectedOrganisation: SelectableItem | null;
}

const LocationSelect: React.FC<SelectItemProps> = ({ error, onSelect }) => {
  const locations = useListLocations({});
  const selectableLocations: SelectableItem[] = locations.data
    ? normalise(locations.data).map((location) => ({
        id: location.id,
        title: location.name,
        subtitle: getAddressAsString(location.address),
      }))
    : [];

  if (locations.loading) {
    return <Loading />;
  }

  if (locations.error || !locations.data) {
    return (
      <DataErrorHandler error={locations.error} description="Unable to load locations" refetch={locations.refetch} />
    );
  }

  return (
    <SelectionList
      getImage={getLocationAvatar}
      items={selectableLocations}
      label="location"
      onSelectItem={onSelect}
      error={error}
    />
  );
};

const OrgSelect: React.FC<OrgSelectProps> = ({
  error,
  onSelect,
  onSubmit,
  loading,
  selectedLocation,
  selectedOrganisation,
}) => {
  const organisations = useListOrganisations({});
  const selectableOrganisations: SelectableItem[] = organisations.data
    ? normalise(organisations.data).map((organisation) => ({
        id: organisation.id,
        title: organisation.name,
        subtitle: labelsFor(OrganisationTier)[organisation.tier],
      }))
    : [];

  if (organisations.loading) {
    return <Loading />;
  }

  if (organisations.error || !organisations.data) {
    return (
      <DataErrorHandler
        error={organisations.error}
        description="Unable to load organisations"
        refetch={organisations.refetch}
      />
    );
  }

  return (
    <SelectionList
      getImage={() => getOrganisationAvatar({ name: '' })}
      items={selectableOrganisations}
      label="organisation"
      onSelectItem={onSelect}
      error={error}
      lowerActions={
        <>
          <Typography>Selected location: {selectedLocation?.title ?? 'none'}</Typography>
          <Typography>Selected organisation: {selectedOrganisation?.title ?? 'none'}</Typography>
          <Button onClick={onSubmit} color="primary" variant="contained" disabled={loading} aria-label="Move location">
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Move location'}
          </Button>
        </>
      }
    />
  );
};

const SectionContents: React.FC = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const [locationError, setLocationError] = React.useState<string | undefined>();
  const [selectedLocation, setSelectedLocation] = React.useState<SelectableItem | null>(null);
  const [organisationError, setOrganisationError] = React.useState<string | undefined>();
  const [selectedOrganisation, setSelectedOrganisation] = React.useState<SelectableItem | null>(null);

  const { mutate: moveLocation, error, loading } = useMoveLocation({ locationId: selectedLocation?.id ?? '' });

  const onSelectLocation = React.useCallback((location: SelectableItem) => {
    setSelectedLocation(location);
    setLocationError(undefined);
  }, []);

  const onSelectOrganisation = React.useCallback((organisation: SelectableItem) => {
    setSelectedOrganisation(organisation);
    setOrganisationError(undefined);
  }, []);

  const onSubmit = React.useCallback(() => {
    setLocationError(undefined);
    setOrganisationError(undefined);
    if (!selectedLocation) {
      setLocationError('Location is required');
      return;
    }
    if (!selectedOrganisation) {
      setOrganisationError('Organisation is required');
      return;
    }
    moveLocation({ data: { type: 'organisations', id: selectedOrganisation.id } })
      .then(() => {
        enqueueSnackbar(
          `Location ${selectedLocation.title} has been moved to organisation ${selectedOrganisation.title}`,
          {
            variant: 'success',
          },
        );
      })
      .catch((e) => {
        errorSnackbar('Unable to move location', closeSnackbar, enqueueSnackbar, e);
      });
  }, [closeSnackbar, enqueueSnackbar, moveLocation, selectedLocation, selectedOrganisation]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container direction="row-reverse">
          {error && (
            <Grid item xs={12}>
              <ErrorHandler error={error} title="Unable to move location" />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row">
          <Grid item xs={6}>
            <LocationSelect error={locationError} onSelect={onSelectLocation} />
          </Grid>
          <Grid item xs={6}>
            <OrgSelect
              error={organisationError}
              loading={loading}
              onSelect={onSelectOrganisation}
              onSubmit={onSubmit}
              selectedLocation={selectedLocation}
              selectedOrganisation={selectedOrganisation}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const MigrateLocationView: React.FC = () => (
  <Workspace>
    <Section
      title="Move locations"
      description="Move a location from one organisation to another. If the location has charge points and/or users invited to it, those entities will be moved along with the location. (The location's charge points will still have event logs and sessions associated with their former organisation.)"
    >
      <SectionContents />
    </Section>
  </Workspace>
);
