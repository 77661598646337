import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useCreateToken } from '../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import { errorSnackbar } from '../../../../../components/ErrorSnackbar';
import { Card, Form, OnSubmit, Section, Workspace } from '../../../../../design-system';
import type { FieldDefinitions } from '../../../../../utils/formUtils';
import { createSchema } from '../../../../../utils/formUtils';

type CreateCard = {
  name: string;
  uid: string;
};

const fields: FieldDefinitions<CreateCard> = [
  {
    id: 'name',
    label: 'Name',
    inputProps: {
      helperText: 'A recognisable name for this token',
    },
  },
  {
    id: 'uid',
    label: 'RFID',
    inputProps: {
      helperText: 'Unique RFID code of the token you are adding',
    },
  },
];

const initialValues: CreateCard = {
  name: '',
  uid: '',
};

const validationSchema = createSchema<CreateCard>({
  // eslint-disable-next-line no-template-curly-in-string --- Yup messages can be customised using template curlies in strings.
  name: Yup.string().required('Card name is required').max(40, 'Card name must be at most ${max} characters'),
  uid: Yup.string()
    .required('Card RFID is required')
    // eslint-disable-next-line no-template-curly-in-string --- Yup messages can be customised using template curlies in strings.
    .min(3, 'Card RFID must be at least ${min} characters')
    // eslint-disable-next-line no-template-curly-in-string --- Yup messages can be customised using template curlies in strings.
    .max(40, 'Card RFID must be at most ${max} characters')
    .matches(/^[a-z0-9-]*$/, 'Card RFID may only include lower case letters, numbers, and "-" characters'),
});

const CreateCardView: React.FC = () => {
  const { push } = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { organisation } = useCurrentOrganisation();
  const { slug } = organisation ?? { slug: '' };
  const { error, mutate } = useCreateToken({ orgSlug: slug });

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to add card', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  const onSubmit = React.useCallback<OnSubmit<CreateCard>>(
    async ({ name, uid }) => {
      setIsSubmitting(true);

      try {
        await mutate({ name, uid, isValid: true, tokenType: 'RFID' });
        enqueueSnackbar('Card created', { variant: 'success' });
        push(`/organisations/${slug}/cards`);
      } finally {
        setIsSubmitting(false);
      }
    },
    [enqueueSnackbar, mutate, push, slug],
  );

  return (
    <Workspace maxWidth="sm">
      <Section
        title="Create new card"
        description="Add a card to your organisation. Card can be used to authorise charging sessions at your charge points."
      >
        <Card>
          <Form<CreateCard>
            fields={fields}
            initialValues={initialValues}
            validationSchema={validationSchema}
            isEditing
            onSubmit={onSubmit}
            isLoading={isSubmitting}
            labels={{ save: 'Create card' }}
          />
        </Card>
      </Section>
    </Workspace>
  );
};

export default CreateCardView;
