import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import { FormActions, List, ListItem } from '../../../../design-system';

import { createSchema } from '../../../../utils/formUtils';

type MfaCode = { code: string };

const validationSchema = createSchema<MfaCode>({
  code: Yup.string()
    .required()
    .min(6, 'Code must be six characters')
    .max(6, 'Code must be six characters')
    .matches(/^\d+$/, 'Code must only include numbers'),
});

export interface SubmitCodeFormProps {
  onSubmit: (values: MfaCode, helpers: FormikHelpers<MfaCode>) => void;
}

export const SubmitCodeForm: React.FC<SubmitCodeFormProps> = ({ onSubmit }) => (
  <Formik<MfaCode> initialValues={{ code: '' }} validationSchema={validationSchema} onSubmit={onSubmit}>
    {({ isSubmitting, values, touched, handleChange, errors }) => (
      <>
        <Form>
          <List>
            <ListItem>
              <TextField
                id="code"
                error={Boolean(touched.code && errors.code)}
                helperText={touched.code && errors.code ? errors.code : 'Enter the code from your MFA device '}
                value={values.code}
                onChange={handleChange}
                autoComplete="off"
                inputProps={{ maxLength: 6 }}
              />
            </ListItem>
          </List>

          <FormActions>
            <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Verify'}
            </Button>
          </FormActions>
        </Form>
      </>
    )}
  </Formik>
);
