import Tooltip from '@material-ui/core/Tooltip';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import React from 'react';
import { useNavigation } from '../../Provider';
import { StyledToggleButton } from './styled';

export type ToggleCollapseButtonProps = Record<string, unknown>;

const ToggleCollapseButton: React.FC<ToggleCollapseButtonProps> = () => {
  const { isCollapsed, toggleCollapse } = useNavigation();

  return (
    <Tooltip placement="right" title={isCollapsed ? 'Expand' : 'Collapse'}>
      <StyledToggleButton isCollapsed={isCollapsed} onClick={toggleCollapse} color="inherit">
        <MenuOpenIcon color="inherit" />
      </StyledToggleButton>
    </Tooltip>
  );
};

ToggleCollapseButton.displayName = 'ToggleCollapseButton';

export default ToggleCollapseButton;
