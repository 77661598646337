import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import { RequestCommandUploadDiagnostics, useCommandUploadDiagnostics } from '../../../../../../../../app/ApiGen';
import { Dialog, DialogProps } from '../../../../../../../../components/Dialog';
import { Form, FormField, OnSubmit } from '../../../../../../../../design-system';
import { createSchema } from '../../../../../../../../utils/formUtils';
import { useChargePoint } from '../../../Provider';
import { useHandleError } from './useHandleError';

type UploadDiagnosticDialogField = {
  protocol: string;
};
const initialValues = {
  protocol: '',
};

const validationSchema = createSchema<UploadDiagnosticDialogField>({
  protocol: Yup.string().required('Protocol is required'),
});

const fields: FormField[] = [
  {
    id: 'protocol',
    label: 'protocol type',
    inputComponent: (props) => (
      <TextField
        select
        variant="outlined"
        margin="dense"
        fullWidth
        style={{ margin: 0 }}
        disabled={props.disabled}
        error={props.error}
        name={props.name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        defaultValue=""
      >
        {['http', 'ftp'].map((value) => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </TextField>
    ),
  },
];

export const UploadDiagnosticDialog: React.FC<DialogProps> = ({ isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { chargePoint, refetch } = useChargePoint();

  const { error, mutate } = useCommandUploadDiagnostics({ id: chargePoint?.id ?? '' });

  useHandleError({ error, defaultErrorMessage: 'Unable to upload diagnostics from the charge point' });

  const onSubmit = React.useCallback<OnSubmit<UploadDiagnosticDialogField>>(
    async ({ protocol }, { setSubmitting }) => {
      try {
        const { data } = await mutate({ protocol } as RequestCommandUploadDiagnostics);
        enqueueSnackbar(`Upload diagnostic command ${data.status.toLowerCase()}`);
        onClose();
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [mutate, enqueueSnackbar, onClose, refetch],
  );

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Upload diagnostics">
      <Form<UploadDiagnosticDialogField>
        fields={fields}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        labels={{ save: 'Send command' }}
        isEditing
      />
    </Dialog>
  );
};
