import type {
  ChargePointSummary,
  LoadBalancingGroupSummary as LoadBalancingGroupDTO,
  NetworkStatus,
  OcppStatus,
} from '../../app/ApiGen';
import type { ConnectorFormat, ConnectorType, PowerType } from '../../utils/api';
import { APIErrorAndError } from '../../utils/error';
import type { Normalised } from '../../utils/request';

// Unfortunately, charge points coming from the Load Balancing Group API endpoints are JSON object strings.
export type LoadBalancingGroup = Omit<Normalised<LoadBalancingGroupDTO>, 'chargePointJSONs'> & {
  chargePoints: Normalised<ChargePointSummary>[];
};

export enum LoadBalancingGroupAction {
  CLEAR_CURRENT_LOAD_BALANCING_GROUP = '@@lbg/CLEAR_CURRENT_LOAD_BALANCING_GROUP',
  GET_CURRENT_LOAD_BALANCING_GROUP = '@@lbg/GET_CURRENT_LOAD_BALANCING_GROUP',
  SET_CURRENT_LOAD_BALANCING_GROUP = '@@lbg/SET_CURRENT_LOAD_BALANCING_GROUP',
  SET_LOAD_BALANCING_GROUP_ERROR = '@@lbg/SET_LOAD_BALANCING_ERROR',
}

/**
 * @deprecated Remove when Load Balancing Group endpoints return charge points as
 * DTOs rather than JSON object strings. (requires removing charge-point-service-grpc)
 */
interface LoadBalancingGroupChargePoint {
  id: string;
  ocppChargePointId: string;
  evses: Record<string, Evse>;
  tokenRequired: boolean;
  networkStatus: 'Online' | 'Offline' | 'NeverConnected';
  networkStatusUpdated: string;
  maxCurrent: number;
  updatedDate: string;
  createdDate: string;
  needsRegistrationInformation: boolean;
  timeZone: string;
  name: string;
  chargePointSerial: string;
  chargePointVendor: string;
  chargePointModel: string;
  firmwareVersion?: string;
  iccid?: string;
  electricityCost?: { costs: { start: number; cost: string }[]; currency: string; duration: number };
  chargingProfiles?: Record<string, unknown>;
  targetCombinedProfile?: Record<string, unknown>;
  username?: string;
  hashedAuthorizationKey?: string;
}

interface Evse {
  id: string;
  updatedDate: string;
  connector: {
    id: string;
    connectorType: ConnectorType;
    connectorFormat: ConnectorFormat;
    powerType: PowerType;
    maxAmperage: number;
    maxVoltage: number;
    updatedDate: string;
    meterValue?: {
      currentImport?: number;
      energyActiveImportRegister?: number;
      powerActiveImport?: number;
      stateOfCharge?: number;
      temperature?: number;
      frequency?: number;
      currentImportPhased?: Partial<Record<'L1' | 'L2' | 'L3', number>>;
      timestamp: string;
      voltage?: number;
      voltagePhased?: Partial<Record<'L1N' | 'L2N' | 'L3N', number>>;
    };
  };
  evseStatus:
    | 'Available'
    | 'Preparing'
    | 'Charging'
    | 'SuspendedEVSE'
    | 'SuspendedEV'
    | 'Finishing'
    | 'Reserved'
    | 'Unavailable'
    | 'Faulted';
  errorCode: string;
}

export interface LoadBalancingGroupState {
  error?: APIErrorAndError;
  current?: LoadBalancingGroup;
  loading: boolean;
  selectedItemId?: LoadBalancingGroup['id'];
}

/**
 * Hacked-together adapter that ignores electricityCost, chargingProfiles, and some other optional properties that are
 * missing from LoadBalancingGroupChargePoint
 */
function toNormalisedChargePoint(chargePoint: LoadBalancingGroupChargePoint): Normalised<ChargePointSummary> {
  const {
    id,
    chargePointModel,
    chargePointVendor,
    chargePointSerial,
    ocppChargePointId,
    createdDate,
    updatedDate,
    name,
    firmwareVersion,
    maxCurrent,
    iccid,
    networkStatus,
    networkStatusUpdated,
    tokenRequired,
    timeZone,
    evses,
  } = chargePoint;

  const networkStatusMap: Record<LoadBalancingGroupChargePoint['networkStatus'], NetworkStatus> = {
    Offline: 'OFFLINE',
    Online: 'ONLINE',
    NeverConnected: 'NEVER_CONNECTED',
  };

  return {
    id,
    vendor: chargePointVendor,
    updatedDate,
    tokenRequired,
    serial: chargePointSerial,
    ocppChargePointId,
    networkStatusUpdatedDate: networkStatusUpdated,
    name,
    model: chargePointModel,
    maxCurrent,
    iccid,
    firmware: firmwareVersion,
    createdDate,
    connectors: Object.values(evses).map((evse) => {
      const {
        id: evseId,
        connector: {
          id: connectorId,
          connectorType,
          connectorFormat,
          powerType,
          updatedDate: connectorUpdated,
          meterValue,
          maxVoltage,
          maxAmperage,
        },
        errorCode,
        evseStatus,
      } = evse;

      const evseStatusMap: Record<LoadBalancingGroupChargePoint['evses'][string]['evseStatus'], OcppStatus> = {
        Available: 'AVAILABLE',
        Preparing: 'PREPARING',
        Charging: 'CHARGING',
        SuspendedEV: 'SUSPENDED_EV',
        SuspendedEVSE: 'SUSPENDED_EVSE',
        Faulted: 'FAULTED',
        Finishing: 'FINISHING',
        Reserved: 'RESERVED',
        Unavailable: 'UNAVAILABLE',
      };

      return {
        connectorFormat,
        connectorType,
        ocppCode: errorCode,
        ocppStatus: evseStatusMap[evseStatus],
        powerType,
        meter: meterValue
          ? {
              current: meterValue.currentImport,
              currentL1: meterValue.currentImportPhased?.L1,
              currentL2: meterValue.currentImportPhased?.L2,
              currentL3: meterValue.currentImportPhased?.L3,
              frequency: meterValue.frequency,
              power: meterValue.powerActiveImport,
              register: meterValue.energyActiveImportRegister,
              stateOfCharge: meterValue.stateOfCharge,
              temperature: meterValue.temperature,
              updatedDate: meterValue.timestamp,
              voltage: meterValue.voltage,
              voltageL1N: meterValue.voltagePhased?.L1N,
              voltageL2N: meterValue.voltagePhased?.L2N,
              voltageL3N: meterValue.voltagePhased?.L3N,
            }
          : undefined,
        connectorId,
        evseId,
        maxAmperage,
        maxVoltage,
        updatedDate: connectorUpdated,
      };
    }),
    timeZone,
    profiles: {},
    networkStatus: networkStatusMap[networkStatus],
  };
}

/**
 * @deprecated No longer needed when charge points are properly included as JSON:API resources from load balancing group
 * endpoints.
 */
export function fromDTO(dto: LoadBalancingGroupDTO): LoadBalancingGroup {
  const {
    id,
    attributes: { name, phases, enabled, chargePointJSONs },
  } = dto;
  const chargePoints =
    chargePointJSONs?.flatMap((json) => {
      try {
        const parsed = JSON.parse(json) as LoadBalancingGroupChargePoint;
        return [toNormalisedChargePoint(parsed)];
      } catch (e) {
        return [];
      }
    }) ?? [];
  return { id, phases, enabled, name, chargePoints };
}
