import styled from 'styled-components';

export const SplashWrapper = styled('div')`
  height: 100vh;
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  justify-content: center;
`;

export const Brand = styled('div')`
  display: flex;
  flex-flow: nowrap column;
  align-items: center;
  justify-content: center;
`;
