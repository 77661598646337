import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uid } from 'react-uid';
import { getLocationAvatar } from '../../../../../components/Avatar/Location';
import { ReloadContainerNavItem } from '../../../../../components/ReloadContainerNavItem';
import {
  ContainerHeader,
  ContainerHeaderLoading,
  HeaderSection,
  Item,
  ItemLoading,
  MenuSection,
  NavigationView,
  Separator,
} from '../../../../../design-system';
import { getCurrentLocationAction } from '../../../../../store/location/actions';
import { fullStateSelector } from '../../../../../store/root';

const Items = (): JSX.Element => {
  const {
    organisation: { current: currentOrg, loading: orgLoading },
    isEvnexAdmin,
    location: { loading, error, current },
  } = useSelector(fullStateSelector);
  const dispatch = useDispatch();

  const refetchLocation = React.useCallback(() => {
    if (current) {
      dispatch(getCurrentLocationAction(current.id));
    }
  }, [current, dispatch]);

  const ReloadButton = () => (
    <ReloadContainerNavItem loading={loading} descriptor="location" onClick={refetchLocation} />
  );

  if (loading || orgLoading) {
    return (
      <>
        <HeaderSection>{() => <ContainerHeaderLoading avatar subText />}</HeaderSection>
        <Separator mb={1} />
        <MenuSection canOverflow>
          {() =>
            Array(isEvnexAdmin ? 3 : 2)
              .fill(undefined)
              .map((_, idx) => <ItemLoading key={uid(idx)} />)
          }
        </MenuSection>
      </>
    );
  }

  if (!current || !currentOrg) {
    return (
      <>
        <HeaderSection>
          {() => <ContainerHeader avatar={getLocationAvatar()} text={current?.name ?? 'Unknown'} subText="Location" />}
        </HeaderSection>
      </>
    );
  }

  if (error) {
    return (
      <>
        <HeaderSection>
          {() => <ContainerHeader avatar={getLocationAvatar()} text={current?.name ?? 'Unknown'} subText="Location" />}
        </HeaderSection>
        <Separator mb={1} />
        <ReloadButton />
      </>
    );
  }

  return (
    <>
      <HeaderSection>
        {() => <ContainerHeader avatar={getLocationAvatar()} text={current.name} subText="Location" />}
      </HeaderSection>
      <Separator mb={1} />
      <MenuSection canOverflow>
        {() => (
          <>
            <Item text="Overview" to={`/organisations/${currentOrg.slug}/locations/${current.id}/overview`} />
            {isEvnexAdmin && (
              <Item text="Users" to={`/organisations/${currentOrg.slug}/locations/${current.id}/users`} />
            )}
            <Item text="Settings" to={`/organisations/${currentOrg.slug}/locations/${current.id}/settings`} />
          </>
        )}
      </MenuSection>
      <ReloadButton />
    </>
  );
};

export const locationNav: NavigationView = {
  id: 'location/container',
  type: 'container',
  Items,
};
