import { all, call, fork, put, takeLatest, Effect } from 'redux-saga/effects';
import { standardiseError } from '../../utils/error';
import { getCurrentLocationAction, setCurrentLocationAction, setLocationErrorAction } from './actions';
import { getLocation, Response } from './api';
import { LocationAction } from './types';
import { normalise } from '../../utils/request';

export function* handleGetCurrentLocation(action: ReturnType<typeof getCurrentLocationAction>): Generator<Effect> {
  const locationId = action.payload.selectedItemId;
  try {
    const response = (yield call(getLocation, locationId)) as Response;
    yield put(setCurrentLocationAction(normalise(response)));
  } catch (e) {
    const error = standardiseError(e);
    yield put(setLocationErrorAction(error, locationId));
  }
}

function* watchGetCurrentLocation() {
  yield takeLatest(LocationAction.GET_CURRENT_LOCATION, handleGetCurrentLocation);
}

export function* locationSagas(): Generator<Effect> {
  yield all([fork(watchGetCurrentLocation)]);
}
