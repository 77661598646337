import axios from 'axios';
import { GoogleApiKey } from '../config';

const DEFAULT_TIMEZONE = 'Pacific/Auckland';

interface TimeZoneAPIResponse {
  status: string;
}

interface TimeZoneOKResponse extends TimeZoneAPIResponse {
  status: 'OK';
  dstOffset: number;
  rawOffset: number;
  timeZoneId: string;
  timeZoneName: string;
}

interface TimeZoneErrorResponse extends TimeZoneAPIResponse {
  status: string;
  errorMessage: string;
}

function isOkResponse(response: TimeZoneAPIResponse): response is TimeZoneOKResponse {
  return response.status === 'OK';
}

function isErrorResponse(response: TimeZoneAPIResponse): response is TimeZoneErrorResponse {
  return response.status !== 'OK';
}
export async function timeZoneAt(coordinates: { latitude: number; longitude: number }) {
  const { latitude, longitude } = coordinates;
  const nowInSeconds = Date.now() / 1000;
  const url = new URL('https://maps.googleapis.com/maps/api/timezone/json');
  url.searchParams.append('location', `${latitude},${longitude}`);
  url.searchParams.append('timestamp', nowInSeconds.toString());
  url.searchParams.append('key', GoogleApiKey.TimeZone);

  try {
    const { data } = await axios.get<TimeZoneAPIResponse>(url.toString());
    if (isOkResponse(data)) {
      return data.timeZoneId;
    }

    if (isErrorResponse(data)) {
      console.error(`Error response from Google Timezone API: (${data.status}) ${data.errorMessage}`);
    }
  } catch (error) {
    console.error(`Error response from Google Timezone API`, error);
  }
  return DEFAULT_TIMEZONE;
}
