import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import debounce from 'lodash/debounce';
import React from 'react';
import { useListInstallationControlPoints } from '../../../app/ApiGen';
import { pluralise } from '../../../utils/format';
import type { GooglePlace } from '../../../utils/googlePlace';
import { normalise } from '../../../utils/request';
import { DataErrorHandler } from '../../ErrorHandler';
import { renderAutocompleteInput } from '../SimpleAutocomplete';
import { IcpOption, InstallationPointOption } from './InstallationPointOption';
import { InputComponentProps } from '../../../design-system';

type IcpLookupInputProps = Required<Pick<GooglePlace['addressComponents'], 'streetNumber' | 'streetName'>> &
  Pick<GooglePlace['addressComponents'], 'city'> &
  InputComponentProps<IcpOption | null>;
interface InstallationPointAutocompleteProps extends InputComponentProps<IcpOption | null> {
  loading: boolean;
  options: IcpOption[];
}

function getPlaceholderText(numOptions: number, loading: boolean): string {
  if (loading) {
    return 'Fetching ICPs for the given address...';
  }
  return numOptions > 0
    ? `${pluralise(numOptions, 'matching ICP')} found: Select or enter ICP number...`
    : 'No ICPs found matching address; please enter manually';
}

const InstallationPointAutocomplete: React.VFC<InstallationPointAutocompleteProps> = ({
  disabled,
  error,
  helperText,
  loading,
  name,
  onBlur,
  onChange,
  options,
  value,
}: InstallationPointAutocompleteProps) => {
  const [autoSelect, setAutoSelect] = React.useState(true);

  return (
    <Autocomplete<IcpOption | null, false, false, true>
      autoSelect={false}
      clearOnBlur
      disabled={disabled}
      freeSolo
      fullWidth
      getOptionLabel={(option) => option?.id ?? ''}
      getOptionSelected={(option, selectedValue) => option?.id === selectedValue?.id}
      handleHomeEndKeys
      includeInputInList
      onBlur={onBlur}
      onChange={(
        e: React.ChangeEvent<unknown>,
        targetValue: IcpOption | null | string,
        reason: AutocompleteChangeReason,
      ) => {
        if (typeof targetValue === 'string') {
          // Free solo mode: String value changes should be handled by onInputChange instead.
          return;
        }
        if (reason === 'clear' || reason === 'select-option') {
          setAutoSelect(false);
          onChange({ ...e, target: { ...e.target, value: targetValue } });
        }
      }}
      onInputChange={debounce(
        // Without debouncing, there is significant input lag when there are many options to compare the value against.
        (e: React.ChangeEvent<unknown>, targetValue: string) => {
          /**
           * NB: Need a fake event to make Formik happy. Can't use the actual event because it is a pooled synthetic event
           * that gets released/nullified due to debouncing, so we cannot access its `target` property.
           * @see https://fb.me/react-event-pooling
           **/
          const fakeEvent = new Event('change');
          const newValue = targetValue ? { address: '', id: targetValue } : null;
          onChange({ ...fakeEvent, target: { ...fakeEvent.target, value: newValue } });
        },
        300,
      )}
      options={options}
      renderInput={renderAutocompleteInput(disabled, error, helperText, {
        name,
        startAdornment: loading ? <CircularProgress size="1em" /> : <ArrowDropDown color="secondary" />,
        'aria-busy': loading,
        placeholder: getPlaceholderText(options.length, loading),
      })}
      renderOption={InstallationPointOption}
      selectOnFocus={false}
      value={autoSelect && !value && options.length === 1 ? options[0] : value}
    />
  );
};

export const IcpLookupInput: React.VFC<IcpLookupInputProps> = ({
  streetName,
  streetNumber,
  city,
  disabled,
  helperText,
  error: fieldError,
  name,
  onChange,
  onBlur,
  value: selectedOption,
}: IcpLookupInputProps) => {
  const { data, error: apiError, loading, refetch } = useListInstallationControlPoints({
    queryParams: { streetName, streetNumber, city },
  });
  const icpOptions = React.useMemo(() => (data ? normalise(data) : []), [data]);

  const mismatch = React.useMemo(
    () =>
      !apiError &&
      selectedOption &&
      icpOptions.length > 0 &&
      !icpOptions.some((option) => option.id === selectedOption.id),
    [apiError, icpOptions, selectedOption],
  );

  return (
    <Container disableGutters>
      <InstallationPointAutocomplete
        disabled={disabled}
        helperText={
          mismatch
            ? 'This value does not match any listed ICP numbers for the given address. Please check that it is correct.'
            : helperText
        }
        error={fieldError}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        loading={loading}
        options={icpOptions}
        value={selectedOption}
      />
      {apiError && (
        <DataErrorHandler
          description="Unable to look up ICP number for the given address"
          type="embedded"
          error={apiError}
          refetch={refetch}
          severity="info"
        />
      )}
    </Container>
  );
};
