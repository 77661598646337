import React from 'react';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';
import { normalise, Normalised } from '../../../utils/request';
import { Dialog } from '../../../components/Dialog';
import { createSchema } from '../../../utils/formUtils';
import { errorSnackbar } from '../../../components/ErrorSnackbar';
import {
  LegacyUser,
  OrganisationSummary,
  useCreateOrganisationMembership,
  useDeleteOrganisationMemberships,
  useListOrganisationMembers,
} from '../../../app/ApiGen';
import { Form, InputComponentProps, OnSubmit } from '../../../design-system';

type UpdateMemberShipProps = {
  organisation: Normalised<OrganisationSummary>;
  user?: LegacyUser;
};

type UpdateMembershipFields = {
  roleAction: string;
};

const initialValue = {
  roleAction: '',
};

const validationSchema = createSchema<UpdateMembershipFields>({
  roleAction: Yup.string().required('Role action is required'),
});

export const UpdateMembership: React.FC<UpdateMemberShipProps> = ({ organisation, user }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isOpen, setOpened] = React.useState<boolean>(false);
  const updateMembership = useCreateOrganisationMembership({ orgSlug: organisation.slug });
  const currentOrganisationMembers = useListOrganisationMembers({ orgSlug: organisation.slug });
  const deleteMembership = useDeleteOrganisationMemberships({ orgSlug: organisation.slug });

  React.useEffect(() => {
    if (updateMembership.error) {
      errorSnackbar('Unable to add admin role', closeSnackbar, enqueueSnackbar, updateMembership.error);
    }

    if (currentOrganisationMembers.error) {
      errorSnackbar(
        'Unable to search organisation members to remove the role',
        closeSnackbar,
        enqueueSnackbar,
        currentOrganisationMembers.error,
      );
    }

    if (deleteMembership.error) {
      errorSnackbar('Unable to remove the admin role', closeSnackbar, enqueueSnackbar, updateMembership.error);
    }
  }, [closeSnackbar, enqueueSnackbar, updateMembership, deleteMembership, currentOrganisationMembers]);

  const onSubmit = React.useCallback<OnSubmit<UpdateMembershipFields>>(
    async ({ roleAction }, { setSubmitting }) => {
      try {
        if (roleAction === 'Add admin access') {
          await updateMembership.mutate({
            data: {
              relationships: {
                organisation: {
                  data: {
                    id: organisation.id,
                    type: 'organisations',
                  },
                },
                user: {
                  data: {
                    id: user?.id ?? '',
                    type: 'users',
                  },
                },
              },
              attributes: {
                isDefault: false,
                role: 0,
              },
              type: 'memberships',
            },
          });
          enqueueSnackbar(`Admin role added successfully`);
        } else {
          const memberships = currentOrganisationMembers.data ? normalise(currentOrganisationMembers.data) : [];
          const currentMembership = memberships.find((member) => user?.id === member.user?.id);

          if (currentMembership) {
            await deleteMembership.mutate({ data: [{ id: currentMembership.id, type: 'memberships' }] });
            enqueueSnackbar(`Admin role removed successfully`);
          }
        }
        setOpened(false);
      } finally {
        setSubmitting(false);
      }
    },
    [organisation, user, updateMembership, enqueueSnackbar, currentOrganisationMembers, deleteMembership],
  );

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setOpened(true)}>
        Add/Remove admin
      </Button>
      <Dialog isOpen={isOpen} onClose={() => setOpened(false)} title="Role">
        <Form<UpdateMembershipFields>
          fields={[
            {
              id: 'roleAction',
              label: 'Role action',
              inputComponent: (props: InputComponentProps<string>) => (
                <TextField
                  select
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  style={{ margin: 0 }}
                  disabled={props.disabled}
                  error={props.error}
                  name={props.name}
                  onBlur={props.onBlur}
                  onChange={props.onChange}
                  defaultValue=""
                >
                  {['Add admin access', 'Remove admin access'].map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
              ),
            },
          ]}
          initialValues={initialValue}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          labels={{ save: 'Save' }}
          isEditing
        />
      </Dialog>
    </>
  );
};
