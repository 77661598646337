import React from 'react';
import * as Yup from 'yup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation } from 'react-router-dom';
import { WizardStep } from '../../../../components/WizardStep';
import { createSchema, FieldDefinitions } from '../../../../utils/formUtils';
import { AutocompleteContents, renderAutocompleteInput } from '../../../../components/CustomFields/SimpleAutocomplete';
import { ChargePointState, CommandsWizardState, CommandType } from '../types';
import { InputComponentProps, OnSubmit, useWizard } from '../../../../design-system';

type CommandDetails = {
  value: CommandType;
  label: string;
};

type CommandStepState = { command: CommandDetails };

const commandOptions: CommandDetails[] = [
  { label: 'Change configuration', value: CommandType.CHANGE_CONFIGURATION },
  { label: 'Reset', value: CommandType.RESET },
  { label: 'Update firmware', value: CommandType.UPDATE_FIRMWARE },
  { label: 'Send data transfer', value: CommandType.DATA_TRANSFER },
];

const commandsFields: FieldDefinitions<CommandStepState> = [
  {
    id: 'command',
    label: 'Command',
    inputComponent: ({
      disabled,
      error,
      helperText,
      name,
      onChange,
      value: selectedCommand,
    }: InputComponentProps<CommandDetails>) => {
      const validationText = helperText ? 'Command is required' : undefined;
      return (
        <Autocomplete<CommandDetails, false, true>
          disableClearable
          fullWidth
          getOptionLabel={(option) => option.label ?? ''}
          getOptionSelected={(option, value) => option.value === value.value}
          handleHomeEndKeys
          onChange={(event, command) => {
            onChange(name)({ ...event, target: { ...event.target, value: command } });
          }}
          options={commandOptions}
          renderInput={renderAutocompleteInput(disabled, error, validationText)}
          value={selectedCommand}
        />
      );
    },
    valueComponent: ({ value: command }: AutocompleteContents<CommandDetails>) => <>{command.label}</>,
  },
];

const validationSchema = createSchema<CommandStepState>({
  command: Yup.object()
    .shape<CommandDetails>({
      label: Yup.string().required(),
      value: Yup.mixed<CommandType>().required(),
    })
    .required(),
});

const getChargePointsMessage = (chargePointsState: ChargePointState[]): string => {
  const chargePoints = chargePointsState.map((c) => c.name ?? c.ocppChargePointId);
  if (chargePoints.length === 1) {
    return `Select the command to send to charge point (${chargePoints[0]})`;
  }
  if (chargePoints.length > 10) {
    return `Select the command to send to ${chargePoints.length} charge points`;
  }
  return `Select the command to send to ${chargePoints.length} charge points (${chargePoints.join(', ')})`;
};

export const CommandStep: React.FC = () => {
  const { state: routerState } = useLocation<Pick<CommandsWizardState, 'chargePoints'>>();

  const { state: untypedState, setState: untypedSetState, prevStep, nextStep, step } = useWizard();
  const state = untypedState as Partial<CommandsWizardState>;
  const setState = untypedSetState as (state: Partial<CommandsWizardState>) => void;

  const onCancel = React.useCallback<OnSubmit<CommandStepState>>(
    (values) => {
      setState({ command: { type: values.command.value } });
      prevStep();
    },
    [setState, prevStep],
  );

  const onSubmit = React.useCallback<OnSubmit<CommandStepState>>(
    (values, { setFieldError }) => {
      setFieldError('command', undefined);
      setState({ command: { type: values.command.value } });
      nextStep();
    },
    [setState, nextStep],
  );

  const stateCommand = commandOptions.find((opt) => opt.value === state.command?.type);

  const initialValues: CommandStepState = {
    command: {
      label: stateCommand ? stateCommand.label : '',
      value: state.command?.type ?? CommandType.NO_COMMAND,
    },
  };

  return (
    <WizardStep<CommandStepState>
      fields={commandsFields}
      initialValues={initialValues}
      name="Select command"
      onCancel={onCancel}
      onSubmit={onSubmit}
      step={step}
      subtitle={getChargePointsMessage(routerState.chargePoints)}
      validationSchema={validationSchema}
    />
  );
};
