import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  useListLocationUsers,
  useRemoveLocationUsers,
  UserIdentifier,
  UserSummary,
} from '../../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../../app/CurrentOrganisationProvider';
import { getToolbarSearch } from '../../../../../../components/DataTable/Toolbar';
import { DataErrorHandler, ErrorHandler } from '../../../../../../components/ErrorHandler';
import { TableLink } from '../../../../../../components/Link';
import {
  Card,
  ColumnChanger,
  ColumnChangerProps,
  DataTable,
  Loading,
  SearchFieldProps,
  Workspace,
} from '../../../../../../design-system';
import type { SubState } from '../../../../../../store/types';
import { normalise, Normalised } from '../../../../../../utils/request';
import { getDeleteUserAction } from '../../../../../../utils/users/form';
import { useLocation } from '../Provider';

const getToolbarProps: (
  organisationSlug: string,
  locationId: string,
) => {
  search: (props: React.PropsWithChildren<SearchFieldProps>) => JSX.Element;
  actions: (props: React.PropsWithChildren<ColumnChangerProps>) => JSX.Element;
} = (organisationSlug: string, locationId: string) => ({
  ...getToolbarSearch('users'),
  actions: (props: React.PropsWithChildren<ColumnChangerProps>) => {
    const { columnsButton, columns: columnChangerColumns, icons, onColumnsChanged } = props;
    return (
      <>
        <ColumnChanger
          columnsButton={columnsButton}
          columns={columnChangerColumns}
          icons={icons}
          onColumnsChanged={onColumnsChanged}
        />
        <Button
          component={Link}
          to={`/organisations/${organisationSlug}/locations/${locationId}/users/add`}
          color="primary"
          variant="contained"
        >
          Add user
        </Button>
      </>
    );
  },
});

const WithContext: React.FC<{ organisation: SubState<'organisation'>; location: SubState<'location'> }> = ({
  organisation,
  location,
}) => {
  const { data, error, loading, refetch } = useListLocationUsers({ locationId: location.id });
  const removeUsers = useRemoveLocationUsers({ locationId: location.id });
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const tableData: Normalised<UserSummary>[] = data ? normalise(data) : [];

  if (error) {
    return <DataErrorHandler description="Unable to load users" error={error} refetch={refetch} />;
  }

  return (
    <Workspace maxWidth="xl">
      <Card>
        <DataTable<Normalised<UserSummary>>
          isLoading={loading}
          options={{ columnsButton: true, actionsColumnIndex: -1 }}
          toolbarProps={getToolbarProps(organisation.slug, location.id)}
          columns={[
            {
              field: 'name',
              title: 'Name',
              defaultSort: 'asc',
              render: (rowData) => (
                <TableLink to={`/organisations/${organisation.slug}/users/${rowData.id}/overview`}>
                  {rowData.name}
                </TableLink>
              ),
            },
            { field: 'email', title: 'Email Address' },
          ]}
          data={tableData}
          actions={[
            getDeleteUserAction({
              closeSnackbar,
              enqueueSnackbar,
              refetch,
              removeUsers: async (users: Normalised<UserSummary>[]) => {
                const usersToRemove: UserIdentifier[] = users.map((u) => ({ id: u.id, type: 'users' }));
                await removeUsers.mutate({ data: usersToRemove });
              },
              source: location.name,
            }),
          ]}
        />
      </Card>
    </Workspace>
  );
};

const LocationUsersListView: React.FC = () => {
  const { organisation, loading: orgLoading } = useCurrentOrganisation();
  const { location, error, loading: locationLoading, refetch } = useLocation();

  if (orgLoading || locationLoading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }
  if (!organisation) {
    return (
      <Workspace>
        <ErrorHandler description="Unable to load organisation" />
      </Workspace>
    );
  }
  if (!location || error) {
    return <DataErrorHandler error={error} description="Unable to load location" refetch={refetch} />;
  }
  return <WithContext organisation={organisation} location={location} />;
};

export default LocationUsersListView;
