import { rgba } from 'polished';
import styled from 'styled-components';

export const StyledListItem = styled('div')`
  padding: ${(p) => p.theme.spacing(1, 2)};
  ${(p) =>
    p.onClick
      ? `
  cursor: pointer;
  transition: background-color ${p.theme.transitions.easing.easeInOut} ${p.theme.transitions.duration.shortest}ms;

  :hover,
  :focus {
    background-color: ${rgba(p.theme.palette.evnex.main, 0.06)} !important;
  }`
      : ''}
`;
