import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useGetOrganisationLocations } from '../../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../../app/CurrentOrganisationProvider';
import { getLocationAvatar } from '../../../../../../components/Avatar/Location';
import { Empty } from '../../../../../../components/Empty';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { ListLabel } from '../../../../../../components/Label';
import { EmptyGraphic, List, ListItem, Loading, Section, useWizard } from '../../../../../../design-system';
import { getAddressAsString } from '../../../../../../utils/locations/address';
import { normalise } from '../../../../../../utils/request';
import { addressIncludesSearch, includesSearch } from '../../../../../../utils/search';
import { LocationDetails } from '../types';

export const LocationIcon = styled('div')`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LocationStep: React.FC = () => {
  const [search, setSearch] = React.useState('');

  const { setState, prevStep, nextStep } = useWizard();

  const { organisation } = useCurrentOrganisation();

  const organisationSlug: string = organisation?.slug ?? '';

  const { loading, error, data, refetch } = useGetOrganisationLocations({ orgSlug: organisationSlug });

  const onSearchChange = React.useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => setSearch(target.value),
    [],
  );

  const onLocationSelect = React.useCallback(
    (location: LocationDetails) => {
      setState({ location });
      nextStep();
    },
    [setState, nextStep],
  );

  if (loading && !data) {
    return <Loading />;
  }

  if (error || !data) {
    return (
      <DataErrorHandler
        description="Unable to load the organisation's locations."
        error={error}
        refetch={refetch}
        type="embedded"
      />
    );
  }

  const items = normalise(data);

  const visibleItems = items.filter(
    ({ name, address }) => includesSearch(name, search) || addressIncludesSearch(address, search),
  );

  return (
    <>
      <Section title="Select a location" description="Each charge point belongs to a location">
        <Box display="flex" alignItems="center">
          <TextField
            autoFocus
            placeholder="Search locations"
            value={search}
            onChange={onSearchChange}
            margin="dense"
            variant="outlined"
            style={{ margin: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="inherit" fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={!search} onClick={() => setSearch('')} size="small">
                    <ClearIcon color="inherit" fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box mr="auto" />
          <Button
            component={Link}
            to={`/organisations/${organisationSlug}/create/location`}
            color="primary"
            variant={visibleItems.length > 0 ? 'outlined' : 'contained'}
          >
            Create new location
          </Button>
        </Box>
        <Box pt={2}>
          {visibleItems.length > 0 ? (
            <List>
              {visibleItems.map(({ id, name, address }) => (
                <ListItem key={id} onClick={() => onLocationSelect({ id, name, address })}>
                  <Box display="flex" flexDirection="row">
                    <LocationIcon>{getLocationAvatar()}</LocationIcon>
                    <ListLabel>
                      <Typography variant="body2">{name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {getAddressAsString(address)}
                      </Typography>
                    </ListLabel>
                  </Box>
                </ListItem>
              ))}
            </List>
          ) : (
            <Box mx="auto" my={2} width="50%" textAlign="center">
              <Empty
                graphic={EmptyGraphic}
                title="No locations found"
                description="To add a charge point you must first have a location for it to be at"
              />
            </Box>
          )}
        </Box>
      </Section>
      <Box pt={2} display="flex">
        <Button variant="contained" onClick={prevStep}>
          Back
        </Button>
      </Box>
    </>
  );
};
