import React from 'react';
import { useSelector } from 'react-redux';
import { uid } from 'react-uid';
import { useListOrganisationLoadBalancingGroups } from '../../../../../app/ApiGen';
import { getLocationAvatar } from '../../../../../components/Avatar/Location';
import { ReloadContainerNavItem } from '../../../../../components/ReloadContainerNavItem';
import {
  ContainerHeader,
  ContainerHeaderLoading,
  HeaderSection,
  Item,
  ItemLoading,
  MenuSection,
  NavigationView,
  Separator,
} from '../../../../../design-system';
import { fromDTO } from '../../../../../store/loadBalancingGroup/types';
import { fullStateSelector } from '../../../../../store/root';
import { SubState } from '../../../../../store/types';

const LoadingItems: React.FC = () => (
  <>
    <HeaderSection>{() => <ContainerHeaderLoading avatar subText />}</HeaderSection>
    <Separator mb={1} />
    <MenuSection canOverflow>
      {() =>
        Array(2)
          .fill(undefined)
          .map((_, idx) => <ItemLoading key={uid(idx)} />)
      }
    </MenuSection>
  </>
);

const ErrorItems: React.FC = () => (
  <>
    <HeaderSection>
      {() => <ContainerHeader avatar={getLocationAvatar()} text="Unknown" subText="Load Balancing Group" />}
    </HeaderSection>
    <Separator mb={1} />
  </>
);

const ItemsWithContext: React.FC<{
  loadBalancingGroupId: string;
  organisation: SubState<'organisation'>;
}> = ({ loadBalancingGroupId, organisation }) => {
  const { loading, error, refetch, data } = useListOrganisationLoadBalancingGroups({ orgId: organisation.id });

  const response = data?.data.find((group) => group.id === loadBalancingGroupId);
  const loadBalancingGroup = response ? fromDTO(response) : undefined;
  if (loading) {
    return <LoadingItems />;
  }
  if (error || !loadBalancingGroup) {
    return <ErrorItems />;
  }

  return (
    <>
      <HeaderSection>
        {() => (
          <ContainerHeader avatar={getLocationAvatar()} text={loadBalancingGroup.name} subText="Load Balancing Group" />
        )}
      </HeaderSection>
      <Separator mb={1} />
      <MenuSection canOverflow>
        {() => (
          <>
            <Item
              text="Overview"
              to={`/organisations/${organisation.slug}/load-balancing-groups/${loadBalancingGroupId}/overview`}
            />
            <Item
              text="Settings"
              to={`/organisations/${organisation.slug}/load-balancing-groups/${loadBalancingGroupId}/settings`}
            />
          </>
        )}
      </MenuSection>
      <ReloadContainerNavItem
        loading={loading}
        descriptor="load balancing group"
        onClick={() => {
          refetch().catch(() => {
            /* Error is already handled via restful-react error above. */
          });
        }}
      />
    </>
  );
};

const Items: React.FC = () => {
  const {
    loadBalancingGroup: { current: loadBalancingGroup, loading: groupLoading },
    organisation: { current: organisation, loading: orgLoading },
  } = useSelector(fullStateSelector);

  if (orgLoading || groupLoading) {
    return <LoadingItems />;
  }
  if (!loadBalancingGroup || !organisation) {
    return <ErrorItems />;
  }
  return <ItemsWithContext loadBalancingGroupId={loadBalancingGroup.id} organisation={organisation} />;
};

export const loadBalancingGroupNav: NavigationView = {
  id: 'loadBalancingGroup/container',
  type: 'container',
  Items,
};
