import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { uid } from 'react-uid';
import { Card, FormField, FormLoading, Section, Workspace, WorkspaceProps } from '../design-system';

interface LoadingPlaceholderProps extends WorkspaceProps {
  sections: { items: FormField[]; split?: boolean; title?: boolean }[];
}

const LoadingSection: React.FC<LoadingPlaceholderProps['sections'][number]> = ({ items, split, title }) => (
  <Section
    data-testid="skeleton"
    title={title ? <Skeleton width="8em" animation="wave" /> : undefined}
    description={title ? <Skeleton width="50%" animation="wave" /> : undefined}
  >
    <Card title={<Skeleton width="12em" />}>
      {split ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormLoading fields={items} />
          </Grid>
          <Grid item xs={6}>
            <Box width="95%" height="90%" bgcolor="#efefef" />
          </Grid>
        </Grid>
      ) : (
        <FormLoading fields={items} />
      )}
    </Card>
  </Section>
);

const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = ({ gutterY, maxWidth, sections }) => (
  <Workspace maxWidth={maxWidth} gutterY={gutterY}>
    {sections.map(({ items, split, title }, idx) => (
      <div key={uid(items, idx)}>
        <LoadingSection items={items} split={split} title={title} />
      </div>
    ))}
  </Workspace>
);

export default LoadingPlaceholder;
