import moment from 'moment-timezone';
import prettyMs from 'pretty-ms';
import { DateFormat, TimeFormat } from '../../dateFormat';
import { RemappedSession } from '..';
import { TransactionStopReason } from '../../../app/ApiGen';

export const CARBON_UNIT = 'kg CO2e';

export const stopReasonMapper = (reason?: TransactionStopReason): string => {
  switch (reason) {
    case 'EVDisconnected': {
      return 'EV disconnected';
    }
    case 'EmergencyStop': {
      return 'Emergency stop';
    }
    case 'HardReset': {
      return 'Hard reset';
    }
    case 'DeAuthorized': {
      return 'Authorization rejected';
    }
    case 'Local': {
      return 'Locally stopped';
    }
    case 'Other': {
      return 'Other';
    }
    case 'PowerLoss': {
      return 'Power loss';
    }
    case 'Reboot': {
      return 'Reboot';
    }
    case 'UnlockCommand': {
      return 'Unlock command';
    }
    case 'Remote': {
      return 'Remote';
    }
    case 'SoftReset': {
      return 'Soft reset';
    }
    default:
      return reason ?? 'N/A';
  }
};

export const dateFormat = (date: string): string => moment(date).format(`${DateFormat.Long} ${TimeFormat.NoSeconds}`);

export const durationFormat = (duration: number): string => prettyMs(duration, { secondsDecimalDigits: 0 });

export const durationFormatFromStartEnd = (startDate: string, endDate: string): string =>
  durationFormat(moment(endDate).diff(moment(startDate)));

export const powerUsageWithUnit = (powerUsage: string): string => `${powerUsage} kWh`;

export const carbonOffsetFormat = (carbonOffset: number | string): string => {
  const offsetNumber: number = typeof carbonOffset === 'string' ? parseFloat(carbonOffset) : carbonOffset;
  return Number(offsetNumber.toFixed(2)).toLocaleString();
};
export const electricityCostFormat = (electricityCost: RemappedSession['electricityCost']): string => {
  if (electricityCost) {
    const cost = Number(parseFloat(`${electricityCost.amount}`)).toFixed(2);
    return `$${cost.toLocaleString()} (${electricityCost.currency})`;
  }
  return '$0.00';
};

export const chargingCostCostFormat = (cost: RemappedSession['cost']): string => {
  if (cost) {
    const chargingCost = Number(parseFloat(`${cost.amount}`)).toFixed(2);
    return `$${chargingCost.toLocaleString()} (${cost.currency})`;
  }
  return '$0.00';
};
