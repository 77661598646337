import { ChargePointLog } from '../api';
import { roundTo2DecimalPlaces, sumValues } from '../number';

type PerPhaseData = { L1: number; L2: number; L3: number };

export type PhaseKey = keyof PerPhaseData;

export const phases = ['L1', 'L2', 'L3'] as PhaseKey[];

export type LLMClient = {
  Name: string;
  MaxCurrent: number;
  CanCharge: boolean;
  AllowedCurrent: number;
  ChargingOnPhase: 'L1' | 'L2' | 'L3' | 'All';
  ChargeNow: boolean;
  MeasuredCurrent: number;
  WantedCurrent: number;
  UpTime: number;
};

export type LLMServer = {
  Mode: 'Server';
  SupplyCurrent?: PerPhaseData;
  GroupLimit?: number | PerPhaseData;
  SharedLimit?: number;
  MaxSafeLimit?: PerPhaseData;
  ClientInfos: {
    MaxClients: number;
    Clients: LLMClient[];
  };
};

export type LLMServerStatusLog = ChargePointLog & {
  data: LLMServer;
};

export type LimitCalculationData = {
  phase: PhaseKey;
  groupLimit: number;
  nonChargingCurrents: number;
  total: number;
  totalAboveLimit: boolean | undefined;
};

export function getGroupLimit(args: { groupLimit: LLMServer['GroupLimit']; phase: PhaseKey }): number {
  const { groupLimit, phase } = args;
  if (groupLimit === undefined) {
    return 0;
  }
  if (typeof groupLimit === 'number') {
    return groupLimit;
  }
  return groupLimit[phase];
}

export const getLimitCalculationData = (args: {
  log: LLMServerStatusLog;
  supplyCurrentLimit: number | undefined;
}): LimitCalculationData[] => {
  const { ClientInfos, GroupLimit, SupplyCurrent } = args.log.data;
  return phases.map((phase) => {
    const groupLimit = getGroupLimit({ groupLimit: GroupLimit, phase });
    const supplyCurrent = SupplyCurrent ? SupplyCurrent[phase] : 0;
    const measuredCurrents = sumValues(
      ClientInfos.Clients.map((c) =>
        c.ChargingOnPhase === phase || c.ChargingOnPhase === 'All' ? c.MeasuredCurrent : 0,
      ),
    );

    const nonChargingCurrents = supplyCurrent - measuredCurrents;
    const total = groupLimit + nonChargingCurrents;
    return {
      phase,
      groupLimit: roundTo2DecimalPlaces(groupLimit),
      nonChargingCurrents: roundTo2DecimalPlaces(nonChargingCurrents),
      total: roundTo2DecimalPlaces(total),
      totalAboveLimit: args.supplyCurrentLimit ? total > args.supplyCurrentLimit : false,
    };
  });
};
