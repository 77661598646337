import { rgba } from 'polished';
import styled from 'styled-components';

export const ListHeader = styled('div')`
  padding: ${(p) => p.theme.spacing(0.5, 2)};
  background-color: transparent !important;
  border-bottom: 1px solid ${(p) => rgba(p.theme.palette.evnex.main, 0.13)};
  color: ${(p) => p.theme.palette.text.secondary};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
`;
