import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { convertArrayToCSV } from 'convert-array-to-csv';
import React from 'react';
import type { TableColumn } from '../../utils/dataTable/columns';
import { FieldValueGetter, getCsvCellValue, getCsvColumns, TableRow } from '../../utils/dataTable/csvExport';

export interface ExportProps<RowData> {
  columns: TableColumn<RowData>[];
  data: TableRow<RowData>[];
  dateFormat?: string;
  exportFileName: string;
  getFieldValue: FieldValueGetter<RowData>;
  icons: { Export: () => React.ReactElement };
  onDownload?: () => void;
}

const downloadFile = (fileName: string, data: string): void => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  const el = document.createElement('a');
  el.href = URL.createObjectURL(blob);
  el.download = `${fileName}.csv`;
  document.body.appendChild(el);
  el.click();
  el.remove();
};

export function ExportCsv<RowData>({
  columns,
  data,
  exportFileName,
  getFieldValue,
  icons,
  dateFormat,
  onDownload,
}: ExportProps<RowData>): JSX.Element {
  const exportData = React.useCallback(() => {
    const csvColumns = getCsvColumns(columns);
    const dataArrays = data.map((row) =>
      csvColumns.map((col) =>
        getCsvCellValue({ columnField: col.field, columnType: col.type, dateFormat, value: getFieldValue(row, col) }),
      ),
    );
    downloadFile(exportFileName, convertArrayToCSV(dataArrays, { header: csvColumns.map((col) => String(col.title)) }));
    if (typeof onDownload !== 'undefined') {
      onDownload();
    }
  }, [columns, dateFormat, exportFileName, getFieldValue, data, onDownload]);

  return (
    <Tooltip title="Export visible columns">
      <IconButton color="inherit" onClick={exportData}>
        <icons.Export />
      </IconButton>
    </Tooltip>
  );
}
