import { rgba } from 'polished';
import styled from 'styled-components';
import { useTab } from './Context';

export const Tabs = styled('div')`
  display: flex;
  flex-flow: nowrap row;
  overflow-y: auto;
`;

export const Tab = styled('div')<{ tabId?: number }>`
  padding: ${(p) => p.theme.spacing(2)}px;
  min-width: 160px;
  display: flex;
  flex-flow: nowrap column;
  border-top: 4px solid ${(p) => (useTab().selected === p.tabId ? p.theme.palette.primary.main : 'transparent')};
  font-weight: ${(p) =>
    useTab().selected === p.tabId ? p.theme.typography.fontWeightRegular : p.theme.typography.fontWeightLight};
  cursor: pointer;
  outline: none;
  user-select: none;

  &:focus,
  &:hover {
    background-color: ${(p) => rgba(p.theme.palette.evnex.main, 0.03)};
  }
`;

export const Label = styled('div')`
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

export const Value = styled('div')`
  margin-top: 4px;
  font-size: 24px;
  line-height: 32px;
`;

export const ChartWrapper = styled('div')`
  display: flex;
  flex-flow: nowrap column;
  flex: 1 1 auto;
`;
