import type { Membership, Organisation, User } from '../../app/ApiGen';

export enum OrganisationUserAction {
  GET_CURRENT_ORGANISATION_USER = '@@organisationUser/GET_CURRENT_ORGANISATION_USER',
  SET_CURRENT_ORGANISATION_USER = '@@organisationUser/SET_CURRENT_ORGANISATION_USER',
  SET_ORGANISATION_USER_ERROR = '@@organisationUser/SET_ORGANISATION_USER_ERROR',
  CLEAR_CURRENT_ORGANISATION_USER = '@@organisationUser/CLEAR_CURRENT_ORGANISATION_USER',
}

export type OrganisationMembership = Pick<Membership, 'id'> &
  Membership['attributes'] & {
    organisationId: Organisation['id'];
  };

export type OrganisationUser = Pick<User, 'id'> & User['attributes'];

export interface OrganisationUserState {
  loading: boolean;
  error?: Error;
  current?: OrganisationUser & { membership: OrganisationMembership };
}
