import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { JobsView } from './Jobs';
import { CreateView } from './Create';

export const InstallerView: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/jobs`}>
        <JobsView />
      </Route>

      <Route path={`${path}/create`}>
        <CreateView />
      </Route>

      <Route>
        <Redirect to={`${path}/jobs`} />
      </Route>
    </Switch>
  );
};
