import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';

interface DangerButtonProps {
  disabled?: boolean;
  onClick: () => void;
  loading: boolean;
  label: string;
}

const RedButton = styled(Button)`
  background: ${(p) => p.theme.palette.red.main};
  color: ${(p) => p.theme.palette.error.contrastText};

  :hover,
  :focus {
    background-color: ${(p) => p.theme.palette.red.dark};
  }
`;

export const DangerButton: React.VFC<DangerButtonProps> = ({
  disabled,
  onClick,
  loading,
  label,
}: DangerButtonProps) => (
  <RedButton aria-label={label} disabled={disabled ?? loading} onClick={onClick} variant="contained">
    {loading ? <CircularProgress size={24} color="inherit" /> : label}
  </RedButton>
);
