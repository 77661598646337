import Box from '@material-ui/core/Box';
import Container, { ContainerProps } from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';
import * as React from 'react';

export interface WorkspaceProps {
  /** Gutter on the top and bottom of the workspace. */
  gutterY?: number;
  /** Max width of the container. */
  maxWidth?: ContainerProps['maxWidth'];
}

export const Workspace: React.FC<WorkspaceProps> = ({ children, gutterY = 3, maxWidth = 'md' }) => (
  <Fade in>
    <Container maxWidth={maxWidth}>
      <Box my={gutterY}>{children}</Box>
    </Container>
  </Fade>
);
