import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { GoogleMap, Marker } from '@react-google-maps/api';
import * as React from 'react';
import styled from 'styled-components';
import locationImg from '../../../../../../../assets/map/location.png';
import { GoogleMapLoader, googleMapStyles } from '../../../../../../../components/GoogleMap';
import { Card } from '../../../../../../../design-system';
import type { SubState } from '../../../../../../../store/types';
import { getAddressAsString } from '../../../../../../../utils/locations/address';

const MapWrapper = styled('div')`
  height: ${(p) => p.theme.spacing(40)}px;
`;

export const Header: React.FC<{ location: SubState<'location'> }> = ({ location }) => {
  const { coordinates, name, address, isPublic } = location;

  const lat = Number(coordinates.latitude || '0');
  const lng = Number(coordinates.longitude || '0');

  return (
    <Card>
      <MapWrapper>
        <GoogleMapLoader>
          <GoogleMap
            mapContainerStyle={{ height: '100%', width: '100%' }}
            center={{ lat, lng }}
            zoom={15}
            options={{
              styles: googleMapStyles,
              streetViewControl: false,
            }}
          >
            <Marker position={{ lat, lng }} icon={locationImg} />
          </GoogleMap>
        </GoogleMapLoader>
      </MapWrapper>
      <Box p={2}>
        <Typography variant="h6">{name}</Typography>
        {address && (
          <Typography variant="body2" color="textSecondary">
            {getAddressAsString(address)}
          </Typography>
        )}
        {isPublic && (
          <Chip
            style={{ marginTop: '8px' }}
            color="primary"
            size="small"
            label="Location is enabled for OCPI roaming"
          />
        )}
      </Box>
    </Card>
  );
};
