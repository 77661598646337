import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { StandaloneSearchBox } from '@react-google-maps/api';
import * as React from 'react';
import type { Address, Coordinates, Timezone } from '../../../utils/api';
import { toChangeEvent } from '../../../utils/formUtils';
import { GooglePlace, mapPlaceToAddress, parsePlace } from '../../../utils/googlePlace';
import { GoogleMapLoader } from '../../GoogleMap';
import { timeZoneAt } from '../../../utils/timezone';
import { InstallationPoint } from '../InstallationPoint';
import { InputComponentProps } from '../../../design-system';

type AddressSearchInputProps = Pick<InputComponentProps<Address>, 'disabled' | 'onChange'>;

type AddressSearchProps =
  | AddressSearchInputProps
  | (AddressSearchInputProps & {
      addressFieldId: string;
      coordinatesFieldId: string;
      timeZoneFieldId?: string;
      icpFieldId?: string;
    });

function firstMatchingPlace(searchBoxRef: google.maps.places.SearchBox | undefined): GooglePlace | undefined {
  const places = searchBoxRef?.getPlaces();
  if (!places || places.length < 1) {
    return undefined;
  }
  return parsePlace(places[0]);
}

export const AddressEditorSearchInput: React.VFC<AddressSearchProps> = ({
  disabled,
  onChange,
  ...otherProps
}: AddressSearchProps) => {
  const [searchBoxRef, setSearchBoxRef] = React.useState<google.maps.places.SearchBox | undefined>(undefined);

  const onPlacesChanged = React.useCallback(async () => {
    const place = firstMatchingPlace(searchBoxRef);
    if (!place) {
      return;
    }
    const address = mapPlaceToAddress(place);

    if ('addressFieldId' in otherProps) {
      const { addressFieldId, coordinatesFieldId, timeZoneFieldId, icpFieldId } = otherProps;
      onChange(addressFieldId)(toChangeEvent<Address>(new Event('change'), address));

      const { coordinates, addressComponents } = place;

      if (icpFieldId) {
        onChange(icpFieldId)(toChangeEvent<InstallationPoint>(new Event('change'), addressComponents));
      }

      if (coordinates) {
        onChange(coordinatesFieldId)(
          toChangeEvent<Coordinates>(new Event('change'), {
            latitude: coordinates.latitude.toFixed(7),
            longitude: coordinates.longitude.toFixed(7),
          }),
        );

        if (timeZoneFieldId) {
          const timeZone = await timeZoneAt(coordinates);
          onChange(timeZoneFieldId)(toChangeEvent<Timezone>(new Event('change'), timeZone));
        }
      }
    } else {
      Object.entries(address).forEach(([fieldName, fieldValue]) => {
        onChange(fieldName)(fieldValue);
      });
    }
  }, [onChange, otherProps, searchBoxRef]);

  return (
    <GoogleMapLoader>
      <StandaloneSearchBox
        onLoad={(ref) => setSearchBoxRef(ref)}
        onPlacesChanged={searchBoxRef ? onPlacesChanged : undefined}
      >
        <TextField
          type="search"
          placeholder="Search for an address..."
          variant="outlined"
          margin="dense"
          fullWidth
          style={{ margin: 0 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="inherit" fontSize="small" />
              </InputAdornment>
            ),
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          disabled={disabled}
        />
      </StandaloneSearchBox>
    </GoogleMapLoader>
  );
};
