import { FormikValues } from 'formik';

/**
 * Fetches a nested value from an object by period seperated keys.
 *
 * Examples:
 * - `getNestedKey('apples.color', { apples: { color: 'red' } }) // = 'red'`
 * - `getNestedKey('apples', { apples: { color: 'red' } }) // { color: 'red' }`
 * - `getNestedKey('orange', { apples: { color: 'red' } }) // undefined`
 */
export const getNestedValue = (keys: string, object: FormikValues): unknown => {
  const key = keys && keys.split ? keys.split('.') : [keys];

  let obj = object;

  for (let i = 0; i < key.length; i += 1) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO
    obj = obj ? obj[key[i]] : undefined;
  }

  return obj;
};
