import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Form, FormField, InputComponentProps } from '../../../design-system';
import { Address as IAddress } from '../../../utils/api';
import { getNestedHelperText, toChangeEvent } from '../../../utils/formUtils';
import { addressValidationSchema, getAddressAsString } from '../../../utils/locations/address';
import { Dialog } from '../../Dialog';
import { CountryInput, CountryValue } from '../Country';
import { StateInput, StateValue } from '../State';
import { AddressEditorSearchInput } from './AddressEditorSearchInput';

interface SearchFields {
  search: string;
}

const initialValues: IAddress & SearchFields = {
  search: '',
  address1: '',
  address2: '',
  address3: '',
  city: '',
  postCode: '',
  state: '',
  country: '',
};

export interface AddressDialogInputProps extends InputComponentProps<IAddress> {
  hasSearch?: boolean;
}

export const AddressDialogInput: React.FC<AddressDialogInputProps> = ({
  hasSearch,
  value,
  onChange,
  disabled,
  error,
  helperText,
  name,
  onBlur,
}: AddressDialogInputProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onSubmit = React.useCallback(
    (values: IAddress & SearchFields) => {
      const { search, ...address } = values;
      onChange('address')(toChangeEvent<IAddress>(new Event('change'), address));
      setIsOpen(false);
    },
    [onChange, setIsOpen],
  );

  const rows = Object.keys(value).filter((val) => !!value[val as keyof IAddress]).length;

  const searchField = React.useMemo<FormField[]>(() => {
    if (hasSearch) {
      return [
        {
          id: 'search',
          label: 'Search',
          inputComponent: AddressEditorSearchInput,
        },
      ];
    }

    return [];
  }, [hasSearch]);

  return (
    <>
      <TextField
        value={getAddressAsString(value).split(', ').join('\n')}
        multiline
        placeholder="Enter address manually..."
        rows={rows > 1 ? `${rows}` : '1'}
        margin="dense"
        variant="outlined"
        fullWidth
        InputProps={{
          readOnly: true,
          style: { cursor: 'pointer' },
          inputProps: { style: { cursor: 'pointer', lineHeight: '19px', userSelect: 'none' } },
        }}
        style={{ margin: 0 }}
        disabled={disabled}
        onClick={disabled ? undefined : () => setIsOpen(true)}
        error={error}
        helperText={getNestedHelperText(helperText)}
        name={name}
        onBlur={onBlur}
      />

      {/* Edit address dialog */}
      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)} title="Edit address">
        <Form<IAddress & SearchFields>
          fields={[
            ...searchField,
            {
              id: 'address1',
              label: 'Address line 1',
            },
            {
              id: 'address2',
              label: 'Address line 2',
            },
            {
              id: 'address3',
              label: 'Address line 3',
            },
            {
              id: 'city',
              label: 'City',
            },
            {
              id: 'postCode',
              label: 'Post code',
            },
            {
              id: 'state',
              label: 'State',
              inputComponent: (props: InputComponentProps<string>) => (
                <StateInput<IAddress>
                  disabled={props.disabled}
                  error={props.error}
                  helperText={props.helperText}
                  name={props.name}
                  onBlur={props.onBlur}
                  onChange={props.onChange}
                  value={props.value}
                />
              ),
              valueComponent: StateValue,
            },
            {
              id: 'country',
              label: 'Country',
              inputComponent: (countryInputProps: InputComponentProps<string>) => (
                <CountryInput
                  disabled={countryInputProps.disabled}
                  error={countryInputProps.error}
                  name={countryInputProps.name}
                  onBlur={countryInputProps.onBlur}
                  onChange={countryInputProps.onChange}
                  value={countryInputProps.value}
                />
              ),
              valueComponent: CountryValue,
            },
          ]}
          initialValues={{ ...initialValues, ...value }}
          validationSchema={addressValidationSchema}
          onSubmit={onSubmit}
          isEditing
          labels={{ save: 'Change' }}
          formProps={{ autoComplete: 'off' }}
        />
      </Dialog>
    </>
  );
};
