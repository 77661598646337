import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CardView from './Card';
import CardsListView from './CardsList';

const ChargePointsView: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:id`}>
        <CardView />
      </Route>

      <Route>
        <CardsListView />
      </Route>
    </Switch>
  );
};

export default ChargePointsView;
