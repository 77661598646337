import { ChartData } from 'chart.js';
import { StatusSummary } from '../../../../../../app/ApiGen';
import { IndicatorColor, IndicatorPalette } from '../../../../../../design-system';

export interface LegacyChargingStatus {
  label: string;
  color: IndicatorColor;
}

export const legacyStatusesByKey: Record<keyof StatusSummary, LegacyChargingStatus> = {
  charging: { label: 'Charging', color: 'enabled' },
  occupied: { label: 'Occupied', color: 'amber' },
  reserved: { label: 'Reserved', color: 'deepOrange' },
  available: { label: 'Available', color: 'cyan' },
  disabled: { label: 'Disabled', color: 'disabled' },
  faulted: { label: 'Faulted', color: 'red' },
  offline: { label: 'Offline', color: 'steel' },
};

export const statuses = Object.entries(legacyStatusesByKey) as [keyof StatusSummary, LegacyChargingStatus][];

export const getChartData = (insights: StatusSummary, palette: IndicatorPalette): ChartData<'doughnut'> => {
  const data: number[] = statuses.map(([status]) => insights[status]);

  const backgroundColor: string[] = statuses.map(([, { color }]) => palette[color].main);

  return {
    labels: statuses.map(([, { label }]) => label),
    datasets: [{ label: 'Status', data, backgroundColor, borderWidth: 0 }],
  };
};
