import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import styled from 'styled-components';
import PackageInformation from '../../../../../package.json';

const StyledInfo = styled('div')`
  padding: ${(p) => p.theme.spacing(1, 0)};
  width: 100%;
  max-width: 280px;
  text-align: center;
`;

export interface InfoProps {
  style?: React.CSSProperties;
}

export const Info: React.FC<InfoProps> = ({ ...rest }) => {
  const year = React.useMemo(() => new Date().getFullYear(), []);

  return (
    <StyledInfo {...rest}>
      <Box mt={1} />
      <Typography variant="caption" color="textSecondary">
        <Link href="https://www.evnex.com/privacy-policy" target="_blank" color="textPrimary">
          Privacy Policy
        </Link>{' '}
        &middot;{' '}
        <Link href="https://www.evnex.com/terms-and-conditions" target="_blank" color="textPrimary">
          Terms of Use
        </Link>
      </Typography>
      <Box mt={1} />
      <Typography variant="caption" color="textSecondary">
        &copy; Copyright {year} Evnex &middot; v{PackageInformation.version}
      </Typography>
    </StyledInfo>
  );
};
