import { Alert } from '@material-ui/lab';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  ChargePointInfoWithLocation,
  useCommandDataTransfer,
  useLegacyGetOrganisationChargePoints,
} from '../../../../../../app/ApiGen';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { Loading, Section, Workspace } from '../../../../../../design-system';
import { useChargePoint } from '../Provider';
import { Configuration } from './components/Configuration';
import { Logs } from './components/Logs';
import { isLlmSupported, LocalLoadManagementConfiguration, LocalLoadManagementResponse } from './utils/configuration';

const Container: React.FC = ({ children }) => (
  <Workspace maxWidth={false}>
    <Section title="Local load management (LLM) diagnostics">{children}</Section>
  </Workspace>
);

const getConfiguration = (args: {
  response: LocalLoadManagementResponse;
  chargePoints?: ChargePointInfoWithLocation[];
}): LocalLoadManagementConfiguration => {
  const { chargePoints, response } = args;
  if (response.Mode !== 'Client') {
    return { mode: response.Mode };
  }
  const server = chargePoints?.find((c) => c.ocppChargePointId === response.ServerName);
  return {
    mode: 'Client',
    isOnline: !response.OfflineMode,
    server: { id: server?.id ?? '', ocppChargePointId: response.ServerName },
  };
};

export const LocalLoadManagementDiagnostics: React.VFC = () => {
  const { params } = useRouteMatch<{ slug: string }>();
  const { slug: organisationSlug } = params;

  const { chargePoint, error: chargePointError, loading, refetch } = useChargePoint();
  const { data: chargePoints } = useLegacyGetOrganisationChargePoints({ orgSlug: organisationSlug });

  const { mutate: getLocalLoadManagement, error: getLocalLoadManagementError } = useCommandDataTransfer({
    id: chargePoint?.id ?? '',
  });

  const [configuration, setConfiguration] = React.useState<LocalLoadManagementConfiguration>({ mode: 'Unknown' });

  React.useEffect(() => {
    if (!chargePoint || chargePoint.networkStatus !== 'ONLINE' || !isLlmSupported(chargePoint.details)) {
      return;
    }

    getLocalLoadManagement({ vendorId: 'Evnex', messageId: 'GetLLM' })
      .then((response) => {
        if (response.data.message) {
          const parsedResponse = JSON.parse(response.data.message) as LocalLoadManagementResponse;
          setConfiguration(getConfiguration({ response: parsedResponse, chargePoints: chargePoints?.data.items }));
        }
      })
      .catch((e) => {
        console.error('Error getting local load management configuration: ', e);
      });
  }, [chargePoint, chargePoints?.data.items, getLocalLoadManagement]);

  if (loading || !chargePoint || !chargePoints) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (chargePointError) {
    return <DataErrorHandler description="Unable to load charge point" error={chargePointError} refetch={refetch} />;
  }

  if (getLocalLoadManagementError) {
    return (
      <DataErrorHandler
        description="Unable to load local load management configuration"
        error={getLocalLoadManagementError}
        refetch={refetch}
      />
    );
  }

  if (!isLlmSupported(chargePoint.details)) {
    return (
      <Container>
        <Alert severity="warning">
          Local load management is not supported on this charge point. This feature requires an Evnex E/X Series charge
          point with a firmware version of at least 1.3.0.
        </Alert>
      </Container>
    );
  }

  return (
    <Container>
      <Configuration configuration={configuration} />
      <Logs chargePoint={chargePoint} />
    </Container>
  );
};
