import { CombinedState, combineReducers, Reducer } from 'redux';
import { all, Effect, fork } from 'redux-saga/effects';
import { UserRole } from '../app/enums';
import { evnexCpoId } from '../config';
import { authenticatedUserReducers } from './authenticatedUser/reducers';
import { authenticatedUserSagas } from './authenticatedUser/sagas';
import { cardReducers } from './card/reducers';
import { cardSagas } from './card/sagas';
import { chargePointReducers } from './chargePoint/reducers';
import { chargePointSagas } from './chargePoint/sagas';
import { loadBalancingGroupReducers } from './loadBalancingGroup/reducers';
import { loadBalancingGroupSagas } from './loadBalancingGroup/sagas';
import { locationReducers } from './location/reducers';
import { locationSagas } from './location/sagas';
import { organisationReducers } from './organisation/reducers';
import { organisationSagas } from './organisation/sagas';
import { organisationUserReducers } from './organisationUser/reducers';
import { organisationUserSagas } from './organisationUser/sagas';
import type { ApplicationState } from './types';
import { userReducers } from './user/reducers';
import { userSagas } from './user/sagas';

export const createRootReducer: () => Reducer<CombinedState<ApplicationState>> = () =>
  combineReducers({
    authenticatedUser: authenticatedUserReducers,
    card: cardReducers,
    chargePoint: chargePointReducers,
    loadBalancingGroup: loadBalancingGroupReducers,
    location: locationReducers,
    organisation: organisationReducers,
    organisationUser: organisationUserReducers,
    user: userReducers,
  });

export function* rootSaga(): Generator<Effect> {
  yield all([
    fork(authenticatedUserSagas),
    fork(cardSagas),
    fork(chargePointSagas),
    fork(loadBalancingGroupSagas),
    fork(locationSagas),
    fork(organisationSagas),
    fork(organisationUserSagas),
    fork(userSagas),
  ]);
}

export type DerivedState = {
  isAdmin: boolean;
  isDriver: boolean;
  isEvnexAdmin: boolean;
  isFleetOperator: boolean;
  isInstaller: boolean;
  isSuperuser: boolean;
};

export function selectDerivedState(partialState: Partial<ApplicationState>): DerivedState {
  const currentOrg = partialState.organisation?.current;
  const currentUser = partialState.authenticatedUser?.current;

  if (!currentUser && !currentOrg) {
    return {
      isAdmin: false,
      isDriver: false,
      isFleetOperator: false,
      isEvnexAdmin: false,
      isInstaller: false,
      isSuperuser: false,
    };
  }

  const isInstaller = currentUser?.type === 'Installer';
  const isSuperuser = currentUser?.type === 'Superuser';
  const isAdmin = !isSuperuser && currentOrg?.role === UserRole.Admin;
  const isDriver = !isSuperuser && currentOrg?.role === UserRole.Driver;
  const isFleetOperator = !isSuperuser && currentOrg?.role === UserRole.FleetOperator;

  const isEvnexAdmin =
    isSuperuser ||
    (!!currentUser && currentUser.organisations.some((org) => org.id === evnexCpoId && org.role === UserRole.Admin));

  return {
    isAdmin,
    isDriver,
    isEvnexAdmin,
    isFleetOperator,
    isInstaller,
    isSuperuser,
  };
}

export function fullStateSelector(state: ApplicationState): ApplicationState & DerivedState {
  return { ...state, ...selectDerivedState(state) };
}
