import { action, Action, PayloadAction } from 'typesafe-actions';
import { LocationAction } from './types';
import { Normalised } from '../../utils/request';
import { Location } from '../../app/ApiGen';
import { APIErrorAndError } from '../../utils/error';

export function getCurrentLocationAction(
  selectedItemId: string,
): PayloadAction<LocationAction.GET_CURRENT_LOCATION, { selectedItemId: string }> {
  return action(LocationAction.GET_CURRENT_LOCATION, { selectedItemId });
}

export function setCurrentLocationAction(
  location: Normalised<Location>,
): PayloadAction<LocationAction.SET_CURRENT_LOCATION, { location: Normalised<Location> }> {
  return action(LocationAction.SET_CURRENT_LOCATION, { location });
}

export function setLocationErrorAction(
  error: APIErrorAndError,
  selectedItemId: string,
): PayloadAction<LocationAction.SET_LOCATION_ERROR, { error: APIErrorAndError; selectedItemId: string }> {
  return action(LocationAction.SET_LOCATION_ERROR, { error, selectedItemId });
}

export function clearCurrentLocationAction(): Action<LocationAction.CLEAR_CURRENT_LOCATION> {
  return action(LocationAction.CLEAR_CURRENT_LOCATION);
}
