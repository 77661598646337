import MuiToolbar from '@material-ui/core/Toolbar';
import { MaterialTableProps } from 'material-table';
import * as React from 'react';
import styled from 'styled-components';

const StyledToolbar = styled(MuiToolbar)`
  padding: ${(p) => p.theme.spacing(2)}px;
  padding-bottom: ${(p) => p.theme.spacing(1)}px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    flex-flow: nowrap column;
  }
`;

const StyledSearch = styled('div')``;

const StyledActions = styled('div')`
  margin-left: auto;
  color: ${(p) => p.theme.palette.text.secondary};

  ${(p) => p.theme.breakpoints.down('sm')} {
    margin-top: ${(p) => p.theme.spacing(1)}px;
    margin-left: 0;
  }

  > * {
    margin-left: ${(p) => p.theme.spacing(0.5)}px;
  }
`;

export interface ToolbarProps {
  search?: React.ComponentType<any>;
  actions?: React.ComponentType<any>;
  tableProps: MaterialTableProps<Record<string, unknown>>;
}

export const Toolbar: React.FC<ToolbarProps> = ({ search: Search, actions: Actions, tableProps }) => {
  if (!Search && !Actions) {
    return null;
  }

  return (
    <StyledToolbar variant="dense">
      {Search && (
        <StyledSearch>
          <Search {...tableProps} />
        </StyledSearch>
      )}
      {Actions && (
        <StyledActions>
          <Actions {...tableProps} />
        </StyledActions>
      )}
    </StyledToolbar>
  );
};
