import { all, call, fork, put, takeLatest, Effect } from 'redux-saga/effects';
import { standardiseError } from '../../utils/error';
import { getCurrentCardAction, setCardErrorAction, setCurrentCardAction } from './actions';
import { getCards, Response } from './api';
import { CardAction } from './types';

export function* handleGetCurrentCard(action: ReturnType<typeof getCurrentCardAction>): Generator<Effect> {
  const selectedCardId = action.payload.selectedItemId;
  try {
    // FIXME - we don't have an endpoint to get a single card so we fetch all and filter down by the card ID
    const { data } = (yield call(getCards, action.payload.organisationSlug)) as Response;
    const { items: cards } = data;
    const card = cards.find(({ id }) => id === selectedCardId);
    if (card) {
      yield put(setCurrentCardAction(card));
    } else {
      const error = standardiseError(new Error('Unknown card ID'));
      yield put(setCardErrorAction(error, selectedCardId));
    }
  } catch (e) {
    const error = standardiseError(e);
    yield put(setCardErrorAction(error, selectedCardId));
  }
}

function* watchGetCurrentCard() {
  yield takeLatest(CardAction.GET_CURRENT_CARD, handleGetCurrentCard);
}

export function* cardSagas(): Generator<Effect> {
  yield all([fork(watchGetCurrentCard)]);
}
