import {
  CategoryScale,
  Chart,
  ChartData,
  ChartDataset,
  ChartOptions,
  LinearScale,
  LineElement,
  registerables,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { useTheme } from 'styled-components';
import { ResetZoomButton } from '../../../../../../../components/ResetZoomButton';
import { Section } from '../../../../../../../design-system';
import { ChargePointLog } from '../../../../../../../utils/api';
import { updateChartZoomLocations } from '../../../../../../../utils/chart';
import {
  CHART_DATE_FORMAT,
  diagnosticsChartIds,
  DiagnosticsChartType,
  getChartData,
  getTemperatureChartData,
} from '../utils/meteringCharts';

Chart.register(LineElement, zoomPlugin, CategoryScale, LinearScale, TimeScale, ...registerables);

const chartOptions = (startingDate: string, endDate: string): ChartOptions<'line'> => ({
  scales: {
    x: { type: 'time', min: startingDate, max: endDate, time: { tooltipFormat: CHART_DATE_FORMAT } },
    y: {
      afterFit(scale) {
        // eslint-disable-next-line no-param-reassign -- -- Reassigning the `scale` param's properties is the accepted way to set dimensions in ChartJS
        scale.width = 100;
      },
      ticks: { crossAlign: 'near' },
      afterSetDimensions: (scale) => {
        // eslint-disable-next-line no-param-reassign -- Reassigning the `scale` param's properties is the accepted way to set dimensions in ChartJS
        scale.maxWidth = 100;
      },
    },
  },
  animation: false,
  maintainAspectRatio: false,
  interaction: { intersect: false, mode: 'nearest', axis: 'x' },
  plugins: {
    legend: { position: 'bottom' },
    zoom: {
      pan: { enabled: true, modifierKey: 'ctrl', mode: 'x' },
      zoom: {
        onZoomComplete: (selectedChart) =>
          updateChartZoomLocations<DiagnosticsChartType>(selectedChart, diagnosticsChartIds),
        mode: 'x',
        drag: { enabled: true },
      },
    },
  },
});

const chartData: (data: ChartDataset<'line'>[]) => ChartData<'line'> = (data) => ({ datasets: data });

export const MeteringCharts: React.FC<{ data: ChargePointLog[]; startingDate: string; endDate: string }> = ({
  data,
  startingDate,
  endDate,
}) => {
  const { palette } = useTheme();
  const currentResponses = getChartData(data, 'Current.Import');
  const voltageResponses = getChartData(data, 'Voltage');
  const powerResponses = getChartData(data, 'Power.Active.Import');
  const temperatureResponses = getTemperatureChartData(data, palette);
  const options = chartOptions(startingDate, endDate);

  return (
    <>
      <ResetZoomButton<DiagnosticsChartType>
        chartIds={diagnosticsChartIds}
        minXValue={startingDate}
        maxXValue={endDate}
      />
      <Section style={{ height: '50vh', position: 'relative', paddingBottom: 64 }}>
        <Line id={diagnosticsChartIds.power} options={options} data={chartData(powerResponses)} />
      </Section>
      <ResetZoomButton<DiagnosticsChartType>
        chartIds={diagnosticsChartIds}
        minXValue={startingDate}
        maxXValue={endDate}
      />
      <Section style={{ height: '50vh', position: 'relative', paddingBottom: 64 }}>
        <Line id={diagnosticsChartIds.current} options={options} data={chartData(currentResponses)} />
      </Section>
      <ResetZoomButton<DiagnosticsChartType>
        chartIds={diagnosticsChartIds}
        minXValue={startingDate}
        maxXValue={endDate}
      />
      <Section style={{ height: '25vh', position: 'relative', paddingBottom: 64 }}>
        <Line id={diagnosticsChartIds.voltage} options={options} data={chartData(voltageResponses)} />
      </Section>
      <ResetZoomButton<DiagnosticsChartType>
        chartIds={diagnosticsChartIds}
        minXValue={startingDate}
        maxXValue={endDate}
      />
      <Section style={{ height: '25vh', position: 'relative', paddingBottom: 64 }}>
        <Line id={diagnosticsChartIds.temperature} options={options} data={chartData(temperatureResponses)} />
      </Section>
    </>
  );
};
