import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useCurrentOrganisation } from '../../../../app/CurrentOrganisationProvider';
import { Loading, Workspace } from '../../../../design-system';
import { APIDetails } from './components/APIDetails';
import { APIPermissions } from './components/APIPermissions';
import { Details } from './components/Details';
import { Header } from './components/Header';

const MyOrganisationView: React.FC = () => {
  const { loading, organisation } = useCurrentOrganisation();

  if (loading || !organisation) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  return (
    <Workspace>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header />
        </Grid>
        <Grid item xs={12}>
          <Details />
        </Grid>
        <Grid item xs={12}>
          <APIDetails />
        </Grid>
        <Grid item xs={12}>
          <APIPermissions />
        </Grid>
      </Grid>
    </Workspace>
  );
};

export default MyOrganisationView;
