import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { rgba } from 'polished';
import styled from 'styled-components';
import { base } from '../../design-system';

export const Indicator = styled('div')`
  padding: ${(p) => p.theme.spacing(0.5)}px;
  flex: 0 0 auto;
`;

export const ConnectorId = styled(Avatar).withConfig({ shouldForwardProp: (prop) => !['color'].includes(prop) })<{
  color: keyof typeof base.palette;
}>`
  background-color: ${(p) => p.theme.palette[p.color].main};
  border: 1px solid ${(p) => rgba(p.theme.palette.evnex.main, 0.13)};
`;

export const Status = styled('div')`
  padding-left: ${(p) => p.theme.spacing(3)}px;
  flex: 1 1 auto;
  min-width: 160px;
`;

export const Actions = styled('div')``;

export const Expansion = styled('div')`
  padding-left: ${(p) => p.theme.spacing(2)}px;
  flex: 0 0 auto;
  line-height: 0;
`;

export const ItemRow = styled('div')`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
`;

export const ItemExpansionPanel = styled(Box)`
  margin: ${(p) => p.theme.spacing(1, -2, 0, -2)};
  border-top: 1px solid ${(p) => p.theme.palette.divider};
  border-bottom: 1px solid ${(p) => p.theme.palette.divider};
`;
