import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import {
  clearCurrentLoadBalancingGroupAction,
  getCurrentLoadBalancingGroupAction,
} from '../../../../../store/loadBalancingGroup/actions';
import { fullStateSelector } from '../../../../../store/root';
import { StoreContextProps } from '../../../../../store/types';
import { NOT_FOUND_API_ERROR_STATUS } from '../../../../../utils/error';

export const useLoadBalancingGroup: () => StoreContextProps<'loadBalancingGroup'> = () => {
  const {
    loadBalancingGroup: { loading, error, current, selectedItemId: selectedGroupId },
    organisation: { current: organisation },
  } = useSelector(fullStateSelector);

  const dispatch = useDispatch();

  const {
    params: { id: groupId },
  } = useRouteMatch<{ id: string }>();

  const refetch = React.useCallback(() => {
    if (current) {
      dispatch(clearCurrentLoadBalancingGroupAction());
    }
    if (organisation) {
      dispatch(getCurrentLoadBalancingGroupAction({ selectedItemId: groupId, organisationId: organisation.id }));
    }
  }, [current, dispatch, groupId, organisation]);

  React.useEffect(() => {
    if (!groupId) {
      // Route params are not available in some contexts, such as nav views.
      return;
    }
    if (!organisation) {
      // This provider needs the current organisation in order to do anything
      return;
    }

    if (selectedGroupId !== groupId) {
      dispatch(clearCurrentLoadBalancingGroupAction());
      dispatch(getCurrentLoadBalancingGroupAction({ selectedItemId: groupId, organisationId: organisation.id }));
    }
  }, [current, dispatch, loading, groupId, organisation, selectedGroupId]);

  return { error, loading, loadBalancingGroup: current, refetch, selectedItemId: selectedGroupId };
};

const context = React.createContext<StoreContextProps<'loadBalancingGroup'>>({ loading: false, refetch: () => {} });
export const LoadBalancingGroupProvider: React.FC = ({ children }) => {
  const loadBalancingGroupState = useLoadBalancingGroup();
  const {
    params: { id: pathLocationId, slug },
  } = useRouteMatch<{ slug: string; id: string }>();

  const { error, selectedItemId: selectedGroupId } = loadBalancingGroupState;
  if (error?.status === NOT_FOUND_API_ERROR_STATUS && pathLocationId === selectedGroupId) {
    return <Redirect to={`/organisations/${slug}/not-found`} />;
  }

  return <context.Provider value={loadBalancingGroupState}>{children}</context.Provider>;
};
