import Box from '@material-ui/core/Box';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DetailListItem } from '../../../../../../../components/DetailListItem';
import { RouterLink } from '../../../../../../../components/Link';
import { Card } from '../../../../../../../design-system';
import { LocalLoadManagementConfiguration } from '../utils/configuration';

type ConfigurationProps = {
  configuration: LocalLoadManagementConfiguration;
};

export const Configuration: React.VFC<ConfigurationProps> = ({ configuration }: ConfigurationProps) => {
  const { params } = useRouteMatch<{ slug: string }>();
  const { slug: organisationSlug } = params;

  return (
    <Card>
      <Box p={2} style={{ paddingBottom: '8px' }}>
        <DetailListItem attributeName="Mode">{configuration.mode}</DetailListItem>
        {configuration.mode === 'Client' && (
          <>
            <DetailListItem attributeName="Server online">{configuration.isOnline ? 'Yes' : 'No'}</DetailListItem>
            <DetailListItem attributeName="Server OCPP ID">
              <RouterLink
                to={`/organisations/${organisationSlug}/charge-points/${configuration.server.id}/llm-diagnostics`}
              >
                {configuration.server.ocppChargePointId}
              </RouterLink>
            </DetailListItem>
          </>
        )}
      </Box>
    </Card>
  );
};
