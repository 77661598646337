import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Popper from '@material-ui/core/Popper';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uid } from 'react-uid';
import { useTheme } from 'styled-components';
import { UserOrganisation } from '../../app/ApiGen';
import { useAuthenticatedUser } from '../../app/AuthenticatedUserProvider';
import { labelsFor, OrganisationTier } from '../../app/enums';
import { changeCurrentOrganisationAction } from '../../store/organisation/actions';
import { ApplicationState } from '../../store/types';
import { getOrganisationAvatar } from '../Avatar/Organisation';
import { CollapsedTooltip } from '../CollapsedTooltip';
import {
  AvatarWrapper,
  DropdownPaper,
  OrganisationSwitcherWrapper,
  Primary,
  Secondary,
  StyledOrganisationDropdown,
  Text,
} from './styled';

export interface OrganisationSwitcherProps {
  isCollapsed: boolean;
}

export const OrganisationSwitcher: React.FC<OrganisationSwitcherProps> = ({
  isCollapsed,
}: OrganisationSwitcherProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { spacing, zIndex } = useTheme();

  const { authenticatedUser } = useAuthenticatedUser();

  const currentOrganisation = useSelector((state: ApplicationState) => state.organisation.current);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(undefined);

  const openOrganisations = React.useCallback((e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget), []);
  const closeOrganisations = React.useCallback(() => setAnchorEl(undefined), []);

  const selectOrganisation = React.useCallback(
    (nextOrganisation: UserOrganisation) => {
      closeOrganisations();
      dispatch(changeCurrentOrganisationAction(nextOrganisation.slug));
      history.push(`/organisations/${nextOrganisation.slug}`);
    },
    [closeOrganisations, dispatch, history],
  );

  const organisations = React.useMemo(() => {
    if (!authenticatedUser?.organisations) {
      return [];
    }

    const orgs = [...authenticatedUser.organisations];
    orgs.sort((a, b) => a.name.localeCompare(b.name));
    return orgs;
  }, [authenticatedUser]);

  if (!authenticatedUser || !currentOrganisation) {
    return null;
  }

  const { name, tier } = currentOrganisation;

  return (
    <OrganisationSwitcherWrapper>
      <CollapsedTooltip isCollapsed={isCollapsed} text="Organisation" subText={<div>{name}</div>}>
        <StyledOrganisationDropdown onClick={openOrganisations} tabIndex={0}>
          <AvatarWrapper isCollapsed={isCollapsed}>{getOrganisationAvatar(currentOrganisation)}</AvatarWrapper>
          {!isCollapsed && (
            <Text>
              <Primary noWrap variant="body1" color="textPrimary" title={name}>
                {name}
              </Primary>
              <Secondary noWrap variant="body2" color="textSecondary">
                {labelsFor(OrganisationTier)[tier]}
              </Secondary>
            </Text>
          )}
        </StyledOrganisationDropdown>
      </CollapsedTooltip>
      <Popper
        id="user-dropdown-container"
        anchorEl={anchorEl}
        open={!!anchorEl}
        placement="bottom-start"
        transition
        style={{ marginTop: `${spacing(0.5)}px`, zIndex: zIndex.drawer }}
      >
        {({ TransitionProps }) => (
          <Grow
            in={TransitionProps?.in}
            onEnter={TransitionProps?.onEnter}
            onExited={TransitionProps?.onExited}
            timeout={350}
          >
            <DropdownPaper>
              <ClickAwayListener onClickAway={closeOrganisations}>
                <List dense>
                  {organisations.map((organisation, idx) => (
                    <ListItem key={uid(organisation, idx)} button onClick={() => selectOrganisation(organisation)}>
                      <ListItemText primary={organisation.name} />
                      {organisation.id === currentOrganisation.id && (
                        <ListItemSecondaryAction>
                          <CheckIcon />
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  ))}
                </List>
              </ClickAwayListener>
            </DropdownPaper>
          </Grow>
        )}
      </Popper>
    </OrganisationSwitcherWrapper>
  );
};
