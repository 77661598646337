import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import AddUserView from './AddUser';
import LocationUsersListView from './UsersList';

const UsersView: React.FC = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/list`}>
        <LocationUsersListView />
      </Route>
      <Route path={`${path}/add`}>
        <AddUserView />
      </Route>
      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
};

export default UsersView;
