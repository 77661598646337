import { ChargePointLog } from '../../../../../../../utils/api';

export type ConnectionChartData = Datum & {
  type: string;
};

type Datum = { y: string; x: [number, number] };

const getServerEventTime = (log: ChargePointLog): number => new Date(log.serverEventDate).getTime();

const getXValues = (args: {
  log: ChargePointLog;
  index: number;
  logs: ChargePointLog[];
  now: Date;
}): Datum['x'] | undefined => {
  const { index, log, logs, now } = args;

  const logEventTime = getServerEventTime(log);

  const isLastLog = index === logs.length - 1;
  if (isLastLog) {
    return [logEventTime, now.getTime()];
  }

  const nextDistinctWebSocketLog = logs.findIndex((value) => {
    const occurredAfterCurrentLog = getServerEventTime(value) > logEventTime;
    return value.event !== log.event && occurredAfterCurrentLog;
  });

  return nextDistinctWebSocketLog !== -1
    ? [logEventTime, getServerEventTime(logs[nextDistinctWebSocketLog])]
    : undefined;
};

export const getWebsocketChartDataset = (logs: ChargePointLog[], now: Date): ConnectionChartData[] =>
  logs
    .filter((log) => log.event === 'WebSocketConnection' || log.event === 'WebSocketClose')
    .reduce<ConnectionChartData[]>((acc, log, index, arr) => {
      const x = getXValues({ log, logs: arr, index, now });
      return x
        ? [...acc, { x, y: '1', type: log.event === 'WebSocketConnection' ? 'Connection' : 'Disconnection' }]
        : acc;
    }, [])
    .sort((a, b) => a.type.localeCompare(b.type));
