import Grid from '@material-ui/core/Grid';
import React from 'react';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { Loading, Section, Workspace } from '../../../../../../design-system';
import { useCard } from '../Provider';
import { DetailsForm } from './components/DetailsForm';

const SettingsView: React.FC = () => {
  const { card, error, loading, refetch } = useCard();
  if (loading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }
  if (error || !card) {
    return <DataErrorHandler description="Unable to load card" error={error} refetch={refetch} />;
  }

  return (
    <Workspace>
      <Section title="Card settings" description="Details and settings for this card.">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DetailsForm tokenId={card.id} />
          </Grid>
        </Grid>
      </Section>
    </Workspace>
  );
};

export default SettingsView;
