import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import * as Yup from 'yup';
import type { AttachmentSummary, Job, JobStatus as NumericJobStatus } from '../../app/ApiGen';
import { FileAttachment, FileAttachmentsValue } from '../../components/CustomFields/FileAttachments';
import {
  AutocompleteContents,
  AutocompleteValue,
  renderAutocompleteInput,
} from '../../components/CustomFields/SimpleAutocomplete';
import { SwitchInput, SwitchValue } from '../../components/CustomFields/Switch';
import { InputComponentProps, ValueComponentProps } from '../../design-system';
import { chargePointHasAttributes, userHasAttributes } from '../format';
import { combineSchemas, createSchema, FieldDefinition, FormFields } from '../formUtils';
import type { Normalised } from '../request';
import { sortByEmail } from '../sortBy';
import { jobDetailsFields, powerSensorCountValidation, referenceIdValidation } from './CreateJob';
import {
  AssignedInstallers,
  assignedInstallersValidationSchema,
  getInstallerLabel,
  InstallerDetails,
} from './installer';

interface JobChargePoint {
  id: string;
  serial: string;
}

export interface JobAttachments {
  attachments: FileAttachment[];
}

export type InstallationDetails = {
  chargePoint: JobChargePoint | null;
  installer: InstallerDetails | null;
  referenceId: string | null;
  supplyCurrentLimit: number | null;
  powerSensorCount: number;
  // Status is optional because it has no corresponding FormFields entry; only present for validation schema purposes.
  status?: NumericJobStatus;
};

export type EvnexInstallationDetails = InstallationDetails & {
  assignedInstallers: InstallerDetails[];
  duration: number | null;
  concealedCableLength: number | null;
  surfaceCableLength: number | null;
  notes: string;
  isThirdParty: boolean;
};

export const installationDetailsFields = (): FormFields<InstallationDetails> => ({
  referenceId: jobDetailsFields.referenceId,
  installer: {
    id: 'installer',
    label: 'Submitted by',
    valueComponent: ({ value }: ValueComponentProps<InstallerDetails | null>) => (
      <AutocompleteValue value={value} label={value ? getInstallerLabel(value) : 'n/a'} />
    ),
    inputComponent: ({ value }: InputComponentProps<InstallerDetails | null>) => (
      <TextField disabled variant="outlined" margin="dense" fullWidth value={value ? getInstallerLabel(value) : ''} />
    ),
  },
  chargePoint: {
    id: 'chargePoint',
    label: 'Installed charge point',
    valueComponent: ({ value }: ValueComponentProps<JobChargePoint | null>) => (
      <>{value?.serial ?? value?.id ?? 'n/a'}</>
    ),
    inputComponent: ({ value }: InputComponentProps<JobChargePoint | null>) => (
      <TextField disabled variant="outlined" margin="dense" fullWidth value={value?.serial ?? value?.id ?? ''} />
    ),
  },
  supplyCurrentLimit: { id: 'supplyCurrentLimit', label: 'Supply current limit (A)', inputProps: { disabled: true } },
  powerSensorCount: {
    id: 'powerSensorCount',
    label: 'How many power sensors installed',
    inputProps: { type: 'number' },
  },
});

export const assignedInstallersField: (installerOptions: InstallerDetails[]) => FieldDefinition<AssignedInstallers> = (
  installerOptions,
) => ({
  id: 'assignedInstallers',
  label: 'Installer(s)',
  inputComponent: ({
    disabled,
    error,
    helperText,
    value: selectedInstallers,
    name,
    onChange,
  }: InputComponentProps<InstallerDetails[]>) => (
    <Autocomplete<InstallerDetails, true, true>
      disableClearable
      disableCloseOnSelect
      fullWidth
      getOptionLabel={getInstallerLabel}
      getOptionSelected={(option, value) => option.id === value.id}
      handleHomeEndKeys
      multiple
      onChange={(event, changedInstallers) => {
        onChange(name)({ ...event, target: { ...event.target, value: changedInstallers } });
      }}
      options={installerOptions.sort(sortByEmail('email'))}
      renderInput={renderAutocompleteInput(disabled, error, helperText)}
      selectOnFocus
      value={selectedInstallers.sort(sortByEmail('email'))}
    />
  ),
  valueComponent: ({ value }: AutocompleteContents<InstallerDetails[]>) => (
    <AutocompleteValue value={value} label={getInstallerLabel} />
  ),
});

export const evnexInstallationDetailsFields = (
  installerOptions: InstallerDetails[],
): FormFields<EvnexInstallationDetails> => {
  const { chargePoint, installer, powerSensorCount, referenceId, supplyCurrentLimit } = installationDetailsFields();
  return {
    referenceId,
    isThirdParty: {
      id: 'isThirdParty',
      label: 'Third-party job',
      inputComponent: SwitchInput,
      valueComponent: ({ value }: ValueComponentProps<boolean>) => (
        <SwitchValue value={value} labels={{ true: 'Yes', false: 'No' }} />
      ),
    },
    assignedInstallers: assignedInstallersField(installerOptions),
    installer,
    chargePoint,
    duration: { id: 'duration', label: 'Time spent onsite (h)' },
    concealedCableLength: {
      id: 'concealedCableLength',
      label: 'Concealed cable used (m)',
    },
    surfaceCableLength: { id: 'surfaceCableLength', label: 'Surface cable used (m)' },
    supplyCurrentLimit,
    notes: { id: 'notes', label: 'Job notes', inputProps: { multiline: true } },
    powerSensorCount,
  };
};

export const installationDetailsValidationSchema = combineSchemas(
  combineSchemas(referenceIdValidation, powerSensorCountValidation),
  createSchema<InstallationDetails>({
    supplyCurrentLimit: Yup.number().optional().nullable(true),
  }),
);

export const evnexInstallationDetailsValidationSchema = combineSchemas(
  installationDetailsValidationSchema,
  assignedInstallersValidationSchema,
);

export const attachedFilesField: FieldDefinition<JobAttachments> = {
  id: 'attachments',
  label: 'Attached files',
  valueComponent: FileAttachmentsValue,
};

function getJobInstaller(jobData: Normalised<Job>): InstallerDetails | null {
  const { installer } = jobData;

  if (userHasAttributes(installer)) {
    return { email: installer.email, id: installer.id, name: installer.name ?? '' };
  }

  if (installer === null) {
    return null;
  }

  return { email: '', id: installer.id, name: '' };
}

function getJobChargePoint(jobData: Normalised<Job>): JobChargePoint | null {
  const { chargePoint } = jobData;
  const chargePointSerial = chargePointHasAttributes(chargePoint) ? chargePoint.serial : undefined;
  return chargePoint ? { id: chargePoint.id, serial: chargePointSerial ?? '' } : null;
}

export const toJobAttachmentsField = (attachments: AttachmentSummary[] | undefined): FileAttachment[] =>
  attachments
    ? attachments.map((attachment) => ({ filename: attachment.attributes.fileName, fileUrl: attachment.id }))
    : [];

export function toInstallationDetailsFields(jobData: Normalised<Job>): Required<EvnexInstallationDetails> {
  const {
    assignedInstallers,
    details: { concealedCableLength, houseMaxCurrent, surfaceCableLength },
    duration,
    notes,
    referenceId,
    distributionBoards,
    isThirdParty,
    status,
  } = jobData;
  const [firstDistBoard] = distributionBoards;
  return {
    assignedInstallers: assignedInstallers
      .map((user) =>
        userHasAttributes(user)
          ? { email: user.email, id: user.id, name: user.name ?? '' }
          : { email: '', id: user.id, name: '' },
      )
      .sort(sortByEmail('email')),
    chargePoint: getJobChargePoint(jobData),
    concealedCableLength: concealedCableLength ?? null,
    duration: duration ?? null,
    supplyCurrentLimit: houseMaxCurrent ?? firstDistBoard?.supplyCurrentLimit ?? null,
    installer: getJobInstaller(jobData),
    isThirdParty,
    notes: notes ?? '',
    referenceId: referenceId ?? '',
    status,
    surfaceCableLength: surfaceCableLength ?? null,
    powerSensorCount: firstDistBoard?.powerSensorCount ?? 0,
  };
}
