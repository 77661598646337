import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useAuth } from '../../../app/AuthProvider';
import { errorSnackbar } from '../../../components/ErrorSnackbar';
import { Card, useNavigationView, Workspace } from '../../../design-system';
import { ChangePasswordComplete } from './components/ChangePasswordComplete';
import { ChangePasswordForm, ChangePasswordFormProps } from './components/ChangePasswordForm';
import { changePasswordNav } from './navView';

export enum ChangePasswordState {
  FORM,
  DONE,
}

const ChangePasswordView: React.FC = () => {
  useNavigationView(changePasswordNav);

  const { changePassword, error } = useAuth();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to change password', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  const [changePasswordState, setChangePasswordState] = React.useState(ChangePasswordState.FORM);

  const onChangePassword = React.useCallback<ChangePasswordFormProps['onSubmit']>(
    async ({ currentPassword, newPassword }, { setSubmitting }) => {
      setSubmitting(false);
      await changePassword(currentPassword, newPassword);
      setChangePasswordState(ChangePasswordState.DONE);
    },
    [changePassword],
  );

  switch (changePasswordState) {
    case ChangePasswordState.DONE:
      return (
        <Workspace maxWidth="sm">
          <Card title="Change password">
            <ChangePasswordComplete />
          </Card>
        </Workspace>
      );
    default:
      return (
        <Workspace maxWidth="sm">
          <Card title="Change password">
            <ChangePasswordForm onSubmit={onChangePassword} />
          </Card>
        </Workspace>
      );
  }
};

export default ChangePasswordView;
