import styled from 'styled-components';
import { ChargePointAvatar } from '../../../../../../../../components/Avatar/ChargePoint';
import { LocationAvatar } from '../../../../../../../../components/Avatar/Location';

export const Location = styled('div')`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
`;

export const AvatarWrapper = styled('div')`
  ${ChargePointAvatar},
  ${LocationAvatar} {
    margin-right: ${(p) => p.theme.spacing(1.5)}px;
    height: ${(p) => p.theme.spacing(4)}px;
    width: ${(p) => p.theme.spacing(4)}px;

    svg {
      height: ${(p) => p.theme.spacing(2.5)}px;
      width: ${(p) => p.theme.spacing(2.5)}px;
    }
  }
`;

export const Text = styled('div')`
  text-decoration: none;
  width: calc(100% - 40px);
`;

export const Primary = styled('div')`
  color: ${(p) => p.theme.palette.text.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Secondary = styled('div')`
  color: ${(p) => p.theme.palette.text.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
