import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import React from 'react';
import { SwitchValue } from '../../../../components/CustomFields/Switch';
import { DataErrorHandler } from '../../../../components/ErrorHandler';
import { RouterLink } from '../../../../components/Link';
import {
  Card,
  ContainerHeaderLoading,
  ItemLoading,
  List,
  ListHeader,
  ListItem,
  Workspace,
} from '../../../../design-system';
import { DateFormat } from '../../../../utils/dateFormat';
import { membershipHasAttributes } from '../../../../utils/format';
import { userRoleLabels } from '../../../../utils/users/FieldDefinitions';
import { useUser } from './Provider';

const UserMembershipsView: React.FC = () => {
  const { error, loading, user, refetch } = useUser();

  if (loading) {
    return (
      <Workspace>
        <div data-testid="item-loading">
          <ContainerHeaderLoading avatar subText />
          <ItemLoading />
        </div>
      </Workspace>
    );
  }

  if (error || !user) {
    return <DataErrorHandler description="Unable to load user" error={error} refetch={refetch} />;
  }

  return (
    <Workspace>
      <Card title="Memberships">
        <Box mb={1}>
          <List variant="bordered" inset>
            <ListHeader>
              <Grid container>
                <Grid item xs={3}>
                  Organisation
                </Grid>
                <Grid item xs={2}>
                  Role
                </Grid>
                <Grid item xs={3}>
                  Member Since
                </Grid>
                <Grid item xs={3}>
                  Membership Updated
                </Grid>
                <Grid item xs={1}>
                  Default
                </Grid>
              </Grid>
            </ListHeader>
            {user.memberships.length === 0 ? (
              <Box mx="auto" mt="1em" mb=".5em">
                <Typography variant="body2">No memberships to display</Typography>
              </Box>
            ) : (
              <>
                {user.memberships.filter(membershipHasAttributes).map((membership) => (
                  <ListItem key={membership.id}>
                    <Grid container>
                      <Grid item xs={3}>
                        <RouterLink to={`/organisations/${membership.organisation.slug}`}>
                          {membership.organisation.name}
                        </RouterLink>
                      </Grid>
                      <Grid item xs={2}>
                        {userRoleLabels[membership.role]}
                      </Grid>
                      <Grid item xs={3}>
                        <span title={membership.createdDate}>
                          {moment(membership.createdDate).format(DateFormat.Long)}
                        </span>
                      </Grid>
                      <Grid item xs={3}>
                        <span title={membership.updatedDate}>
                          {moment(membership.updatedDate).format(DateFormat.Long)}
                        </span>
                      </Grid>
                      <Grid item xs={1}>
                        <SwitchValue value={membership.isDefault ?? false} labels={{ true: 'Yes', false: 'No' }} />
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </>
            )}
          </List>
        </Box>
      </Card>
    </Workspace>
  );
};

export default UserMembershipsView;
