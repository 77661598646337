import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';

const initialValues = {
  code: '',
};

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits'),
});

export interface MfaChallengeFormProps {
  onSubmit: (values: typeof initialValues, helpers: FormikHelpers<typeof initialValues>) => void;
}

export const MfaChallengeForm: React.FC<MfaChallengeFormProps> = ({ onSubmit }) => (
  <Fade in>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <Box>
            <Field name="code">
              {({ field }: FieldProps) => (
                <TextField
                  label="Code"
                  variant="outlined"
                  fullWidth
                  type="text"
                  autoFocus
                  error={!!errors.code}
                  helperText={errors.code}
                  disabled={isSubmitting}
                  autoComplete="off"
                  inputProps={{ maxLength: 6 }}
                  {...field}
                />
              )}
            </Field>
          </Box>
          <Box mt={3}>
            <Button type="submit" variant="contained" color="primary" size="large" fullWidth disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress size={26} color="inherit" /> : 'Submit'}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  </Fade>
);
