import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

export const StyledRange = styled('div')`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  > * {
    margin-right: ${(p) => p.theme.spacing(1)}px !important;
  }
`;

export interface DayWrapperProps {
  isDayInCurrentMonth: boolean;
  isDayBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
}

export const DayWrapper = styled('div')<DayWrapperProps>`
  ${(p) => !p.isDayInCurrentMonth && `opacity: 0;`}
  ${(p) =>
    p.isFirstDay &&
    `
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: ${p.theme.palette.primary.main};
  `}
  ${(p) =>
    p.isDayBetween &&
    `
    background-color: ${p.theme.palette.primary.main};
  `}
  ${(p) =>
    p.isLastDay &&
    `
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: ${p.theme.palette.primary.main};
  `}
`;

export const DayIconButton = styled(IconButton).withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<{ isSelected: boolean }>`
  width: 36px;
  height: 36px;
  margin: 0 2px;
  padding: 0;
  font-size: 0.75rem;
  font-weight: 500;
  ${(p) => (p.isSelected ? `color: ${p.theme.palette.common.white};` : '')}
`;
