import React from 'react';
import { useSelector } from 'react-redux';
import { NavigationView } from '../../../../../../design-system';
import { fullStateSelector } from '../../../../../../store/root';
import { Menu } from './Menu';
import { MenuDataMissing } from './MenuDataMissing';
import { MenuError } from './MenuError';
import { menuItemsFactory } from './menuItems';
import { MenuLoading } from './MenuLoading';

const Items: React.FC = () => {
  const { organisation: organisationState, chargePoint: chargePointState } = useSelector(fullStateSelector);
  const { current: organisation, loading: organisationIsLoading } = organisationState;
  const { current: chargePoint, loading: chargePointIsLoading, error: chargePointError } = chargePointState;

  if (chargePointIsLoading || organisationIsLoading) {
    return <MenuLoading />;
  }

  if (!chargePoint || !organisation) {
    return <MenuDataMissing chargePoint={chargePoint} />;
  }

  if (chargePointError) {
    return <MenuError chargePoint={chargePoint} />;
  }

  return <Menu chargePoint={chargePoint} organisation={organisation} menuItemsFactory={menuItemsFactory} />;
};

export const chargePointNav: NavigationView = {
  id: 'chargePoint/container',
  type: 'container',
  Items,
};
