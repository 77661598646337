import * as React from 'react';
import { ContainerNavigation } from '../components/ContainerNavigation';
import { FooterSection, FooterSectionProps } from '../components/FooterSection';
import { HeaderSection, HeaderSectionProps } from '../components/HeaderSection';
import { ProductNavigation } from '../components/ProductNavigation';
import { Separator } from '../components/Separator';
import { useNavigation } from '../Provider';
import { NavigationView, ProductId } from '../types';
import { ContentWrapper, NavigationLayout, NavigationWrapper } from './styled';

const getProductNavigation = (
  id: string | undefined,
  views: Record<string, NavigationView>,
  productId?: ProductId,
  header?: HeaderSectionProps['children'],
  footer?: FooterSectionProps['children'],
) => {
  const view = id ? views[id] : undefined;

  if (!view) {
    return undefined;
  }

  const { Items } = view;

  return (
    <ProductNavigation productId={productId}>
      {header && (
        <>
          <HeaderSection>{header}</HeaderSection>
          <Separator mb={1} />
        </>
      )}
      {Items && <Items />}
      {footer && (
        <>
          <Separator mt="auto" mb={1} />
          <FooterSection>{footer}</FooterSection>
        </>
      )}
    </ProductNavigation>
  );
};

const getContainerNavigation = (id: string | undefined, views: Record<string, NavigationView>) => {
  const view = id ? views[id] : undefined;

  if (!view) {
    return undefined;
  }

  const { Items } = view;

  return <ContainerNavigation>{Items && <Items />}</ContainerNavigation>;
};

export interface NavigationManagerProps {
  globalProductId?: ProductId;
  globalProductHeader?: HeaderSectionProps['children'];
  globalProductFooter?: FooterSectionProps['children'];
}

const NavigationManager: React.FC<NavigationManagerProps> = ({
  children,
  globalProductId: productId,
  globalProductHeader: header,
  globalProductFooter: footer,
}) => {
  const { productViewId, containerViewId, views, isCollapsed } = useNavigation();

  const productNavigation = getProductNavigation(productViewId, views, productId, header, footer);
  const containerNavigation = getContainerNavigation(containerViewId, views);

  return (
    <NavigationLayout>
      {(productNavigation || containerNavigation) && (
        <NavigationWrapper>
          {productNavigation}
          {containerNavigation}
        </NavigationWrapper>
      )}
      <ContentWrapper
        isCollapsed={isCollapsed}
        hasProductNavigation={!!productNavigation}
        hasContainerNavigation={!!containerNavigation}
      >
        {children}
      </ContentWrapper>
    </NavigationLayout>
  );
};

NavigationManager.displayName = 'NavigationManager';

export default NavigationManager;
