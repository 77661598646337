import { addSeconds, differenceInSeconds } from 'date-fns';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import * as Yup from 'yup';
import { SwitchInput, SwitchValue } from '../../../../../../../components/CustomFields/Switch';
import { TimeInput, TimeValue } from '../../../../../../../components/CustomFields/Time';
import { errorSnackbar } from '../../../../../../../components/ErrorSnackbar';
import { Card, Form, OnSubmit } from '../../../../../../../design-system';
import { SubState } from '../../../../../../../store/types';
import { usePutChargePointChargeSchedule } from '../../../../../../../utils/api';
import { createSchema } from '../../../../../../../utils/formUtils';
import { getStartOfDay } from '../../../../../../../utils/midnight';
import { generateSchedule, scheduleFromChargingProfile } from './utils';

type DaySchedule = {
  enabled: boolean;
  startTime: string;
  endTime: string;
};

type DayScheduleProps = {
  chargePoint: SubState<'chargePoint'>;
  refetch: () => void;
};

const initialValues: DaySchedule = {
  enabled: false,
  startTime: moment().startOf('day').toISOString(),
  endTime: moment().endOf('day').toISOString(),
};

const validationSchema = createSchema<DaySchedule>({
  enabled: Yup.boolean().required('Enabled is required'),
  startTime: Yup.string().required('Start time is required'),
  endTime: Yup.string().required('End time is required'),
});

export const DayScheduleForm: React.FC<DayScheduleProps> = ({ chargePoint, refetch }) => {
  const [isEditing, setIsEditing] = React.useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { id: chargePointId, loadSchedule } = chargePoint;

  const { error, mutate } = usePutChargePointChargeSchedule({ chargePointId });

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to set charging schedule', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  const values = React.useMemo(() => {
    const { startTime, endTime } = scheduleFromChargingProfile({
      chargingProfilePeriods: loadSchedule?.chargingProfilePeriods,
      isSolarEnabled: chargePoint.isSolarEnabled,
    });

    const midnight = getStartOfDay();

    return {
      enabled: loadSchedule?.enabled ?? false,
      startTime: addSeconds(midnight, startTime).toISOString(),
      endTime: addSeconds(midnight, endTime).toISOString(),
    };
  }, [chargePoint.isSolarEnabled, loadSchedule]);

  const onSubmit = React.useCallback<OnSubmit<DaySchedule>>(
    async ({ enabled, startTime, endTime }, { setSubmitting }) => {
      try {
        const chargingProfilePeriods = generateSchedule(
          differenceInSeconds(new Date(startTime), getStartOfDay()),
          differenceInSeconds(new Date(endTime), getStartOfDay()),
          chargePoint.isSolarEnabled,
        );
        await mutate({
          enabled,
          units: 'A',
          duration: 86400,
          chargingProfilePeriods,
        });
        enqueueSnackbar('Charge schedule updated', { variant: 'success' });
        setIsEditing(false);
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [chargePoint.isSolarEnabled, mutate, enqueueSnackbar, refetch],
  );

  return (
    <Card>
      <Form<DaySchedule>
        fields={[
          {
            id: 'enabled',
            label: 'Schedule enabled',
            valueComponent: SwitchValue,
            inputComponent: SwitchInput,
          },
          {
            id: 'startTime',
            label: 'Start time',
            inputProps: {
              helperText: 'The time that you want to begin charging at.',
            },
            valueComponent: TimeValue,
            inputComponent: TimeInput,
          },
          {
            id: 'endTime',
            label: 'End time',
            inputProps: {
              helperText: 'The time that you want to finish charging at.',
            },
            valueComponent: TimeValue,
            inputComponent: TimeInput,
          },
        ]}
        initialValues={{ ...initialValues, ...values }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </Card>
  );
};
