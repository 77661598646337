import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useCommandSetOverride } from '../../../../../../../../app/ApiGen';
import { Dialog } from '../../../../../../../../components/Dialog';
import { errorSnackbar } from '../../../../../../../../components/ErrorSnackbar';
import { FormActions } from '../../../../../../../../design-system';
import { useChargePoint } from '../../../Provider';
import { ChargeNowDialogProps } from '../../types/chargeNow';

export const CancelChargeNowDialog: React.FC<ChargeNowDialogProps> = ({
  isOpen,
  onClose,
  connectorId,
}: ChargeNowDialogProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { chargePoint, refetch } = useChargePoint();
  const { id: chargePointId } = chargePoint ?? { id: '' };

  const { mutate, loading } = useCommandSetOverride({
    id: chargePointId,
  });

  const onSubmit = React.useCallback(() => {
    mutate({ connectorId: parseInt(connectorId, 10), chargeNow: false })
      .then(() => {
        onClose();
        enqueueSnackbar(`Cancel charge now command sent to connector ${connectorId}`);
        refetch();
      })
      .catch((error) => {
        onClose();
        errorSnackbar('Unable to send cancel charge now command', closeSnackbar, enqueueSnackbar, error);
      });
  }, [connectorId, mutate, enqueueSnackbar, onClose, refetch, closeSnackbar]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={`Send cancel charge now command to connector ${connectorId}`}>
      <DialogContent>
        The charger will fall back to its usual charging settings for the current time of day - note this could mean
        that it carries on charging if it would usually charge at this time.
      </DialogContent>
      <FormActions>
        <Button color="primary" variant="contained" disabled={loading} onClick={onSubmit}>
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Send command'}
        </Button>
      </FormActions>
    </Dialog>
  );
};
