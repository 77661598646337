import * as Yup from 'yup';
import type { Job } from '../../app/ApiGen';
import { userHasAttributes } from '../format';
import { combineSchemas, createSchema, FormFields } from '../formUtils';
import type { Normalised } from '../request';
import { userCreateValidation } from '../users/FieldDefinitions';
import { isValidPhoneNumber } from '../validation/phoneNumber';

export type CustomerDetails = {
  customerName: string;
  customerEmail: string;
  contactPhoneNumber: string;
};

export const customerDetailsFields = (args?: {
  emailDisabled: boolean;
  emailHelperText: string;
}): FormFields<CustomerDetails> => ({
  customerName: { id: 'customerName', label: 'Name' },
  customerEmail: {
    id: 'customerEmail',
    label: 'Email',
    inputProps: { disabled: args?.emailDisabled, helperText: args?.emailHelperText },
  },
  contactPhoneNumber: { id: 'contactPhoneNumber', label: 'Customer phone number' },
});

export function toCustomerDetailsFields(jobData: Normalised<Job>): Required<CustomerDetails> {
  const { customer, contactPhoneNumber } = jobData;
  const { name, email } = userHasAttributes(customer) ? customer : { name: '', email: '' };
  return {
    contactPhoneNumber: contactPhoneNumber ?? '',
    customerEmail: email,
    customerName: name ?? '',
  };
}

export const phoneNumberValidationSchema = createSchema<Pick<CustomerDetails, 'contactPhoneNumber'>>({
  contactPhoneNumber: Yup.string()
    .required('Phone number is required')
    .test('isPhoneNumber', 'Phone number is not valid', isValidPhoneNumber),
});

const customerValidationSchema = createSchema<Pick<CustomerDetails, 'customerEmail' | 'customerName'>>({
  customerEmail: Yup.reach(userCreateValidation, 'email') as Yup.StringSchema,
  customerName: Yup.reach(userCreateValidation, 'name') as Yup.StringSchema,
});

export const customerDetailsValidationSchema = combineSchemas(phoneNumberValidationSchema, customerValidationSchema);
