import { combineSchemas, FormFields } from '../formUtils';
import { jobDetailsValidationSchema, JobFormDetails, referenceIdValidation } from './CreateJob';
import { customerDetailsFields } from './customer';
import { assignedInstallersField, installationDetailsFields } from './FieldDefinitions';
import type { AssignedInstallers, InstallerDetails } from './installer';
import { assignedInstallersValidationSchema } from './installer';

export type EvnexJobFormDetails = JobFormDetails & AssignedInstallers;

export const evnexJobFormFields = ({
  installerOptions,
}: {
  installerOptions: InstallerDetails[];
}): FormFields<EvnexJobFormDetails> => {
  const installationDetails = installationDetailsFields();
  return {
    assignedInstallers: assignedInstallersField(installerOptions),
    referenceId: installationDetails.referenceId,
    contactPhoneNumber: customerDetailsFields().contactPhoneNumber,
    powerSensorCount: installationDetails.powerSensorCount,
  };
};

export const evnexJobDetailsSchema = combineSchemas(
  combineSchemas(jobDetailsValidationSchema, assignedInstallersValidationSchema),
  referenceIdValidation,
);
