import ChromeReaderModeOutlinedIcon from '@material-ui/icons/ChromeReaderModeOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import EvStationIcon from '@material-ui/icons/EvStation';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ForumIconOutlined from '@material-ui/icons/ForumOutlined';
import PeopleIcon from '@material-ui/icons/People';
import PlaceIcon from '@material-ui/icons/Place';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import React from 'react';
import { useSelector } from 'react-redux';
import { uid } from 'react-uid';
import { OrganisationSwitcher } from '../../../components/OrganisationSwitcher';
import { Item, ItemLoading, MenuSection, MenuTitle, NavigationView, Separator } from '../../../design-system';
import { fullStateSelector } from '../../../store/root';

const NavLinks: React.FC = () => {
  const {
    organisation: { current: userOrg, loading },
    authenticatedUser: { current: user, loading: userLoading },
    isAdmin,
    isEvnexAdmin,
    isFleetOperator,
  } = useSelector(fullStateSelector);

  if (loading || userLoading) {
    return (
      // NB: Can only use test ID to find an ItemLoading element in the document, since it has no ARIA role.
      <>
        <Separator mb={1} />
        <MenuSection canOverflow>
          {() => (
            <div data-testid="skeleton">
              {Array(7)
                .fill(null)
                .map((_, idx) => (
                  <ItemLoading key={uid(idx)} />
                ))}
            </div>
          )}
        </MenuSection>
      </>
    );
  }

  if (!userOrg || !user) {
    return <></>;
  }

  const orgLink = `/organisations/${userOrg.slug}`;

  return (
    <>
      <Separator mb={1} />
      <MenuSection canOverflow>
        {({ isCollapsed }) => (
          <>
            <Item icon={DashboardIcon} text="Overview" to={`${orgLink}/overview`} isCollapsed={isCollapsed} />
            <Item icon={PlaceIcon} text="Locations" to={`${orgLink}/locations`} isCollapsed={isCollapsed} />
            <Item icon={EvStationIcon} text="Charge points" to={`${orgLink}/charge-points`} isCollapsed={isCollapsed} />
            {(isEvnexAdmin || isAdmin) && (
              <Item
                icon={ShareOutlinedIcon}
                text="Load balancing"
                to={`${orgLink}/load-balancing-groups`}
                isCollapsed={isCollapsed}
              />
            )}
            {(isEvnexAdmin || isAdmin || isFleetOperator) && (
              <Item icon={ShowChartIcon} text="Reports" to={`${orgLink}/reports`} isCollapsed={isCollapsed} />
            )}
            {(isEvnexAdmin || isAdmin) && (
              <Item icon={CreditCardIcon} text="Cards" to={`${orgLink}/cards`} isCollapsed={isCollapsed} />
            )}
            {(isEvnexAdmin || isAdmin) && <MenuTitle isCollapsed={isCollapsed}>My Organisation</MenuTitle>}
            {(isEvnexAdmin || isAdmin) && (
              <Item
                icon={EmojiTransportationIcon}
                text="Organisation"
                to={`${orgLink}/organisation`}
                isCollapsed={isCollapsed}
              />
            )}

            {isEvnexAdmin && <Item icon={PeopleIcon} text="Users" to={`${orgLink}/users`} isCollapsed={isCollapsed} />}
            <MenuTitle isCollapsed={isCollapsed}>Support</MenuTitle>
            <Item
              icon={ChromeReaderModeOutlinedIcon}
              text="Documentation"
              toNewTab
              to="https://docs.evnex.io/"
              isCollapsed={isCollapsed}
            />
            <Item
              icon={ForumIconOutlined}
              text="Community"
              toNewTab
              to="https://community.evnex.io/"
              isCollapsed={isCollapsed}
            />
            <Item
              icon={FormatListBulletedIcon}
              text="Changelog"
              toNewTab
              to="https://docs.evnex.io/changelog"
              isCollapsed={isCollapsed}
            />
          </>
        )}
      </MenuSection>
    </>
  );
};

const Items: React.FC = () => (
  <>
    <MenuSection>{({ isCollapsed }) => <OrganisationSwitcher isCollapsed={isCollapsed} />}</MenuSection>
    <NavLinks />
  </>
);

export const organisationNav: NavigationView = {
  id: 'organisation/product',
  type: 'product',
  Items,
};
