import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import format from 'date-fns/format';
import { useSnackbar } from 'notistack';
import React from 'react';
import { uid } from 'react-uid';
import {
  CommissionAttemptResult as CommissionResult,
  Job,
  JobStatusUpdate,
  useUpdateJob,
} from '../../../../app/ApiGen';
import { CommissionAttemptResult, JobStatus } from '../../../../app/enums';
import { JobStatusValue } from '../../../../components/CustomFields/JobStatus';
import { errorSnackbar } from '../../../../components/ErrorSnackbar';
import { Card, FormActions, List, ListHeader, ListItem, Section } from '../../../../design-system';
import { DateFormatDateFns, TimeFormatDateFns } from '../../../../utils/dateFormat';
import type { Normalised } from '../../../../utils/request';

interface JobStatusSectionProps {
  job: Normalised<Job>;
  refetchJob: () => Promise<void>;
}

const HumanReadableCommissionResult: Record<CommissionResult, string> = {
  [CommissionAttemptResult.Success]: 'Successfully commissioned',
  [CommissionAttemptResult.AccountNotActive]: 'Failed - Customer account not active',
  [CommissionAttemptResult.ChargePointAlreadyClaimed]: 'Failed - Charge point already claimed',
  [CommissionAttemptResult.ChargePointUnresponsive]: 'Failed - Charge point unresponsive',
  [CommissionAttemptResult.FailedForUnknownReason]: 'Failed for unknown reason',
  [CommissionAttemptResult.JobHasIncorrectParameters]: 'Failed - Job has incorrect parameters',
};

export const JobStatusSection: React.VFC<JobStatusSectionProps> = ({ job, refetchJob }: JobStatusSectionProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { mutate } = useUpdateJob({ jobId: job.id });

  const jobStatus = job.status;
  const jobStatusCanBeUpdated =
    jobStatus === JobStatus.Pending || jobStatus === JobStatus.Commissioning || jobStatus === JobStatus.Error;

  const handleUpdateJobStatus = (status: JobStatusUpdate) => {
    mutate({
      data: {
        id: job.id,
        type: 'jobs',
        attributes: {
          status,
        },
      },
    })
      .then(async () => {
        await refetchJob();
        enqueueSnackbar('Job status updated', { variant: 'success' });
      })
      .catch((err) => {
        errorSnackbar('Unable to edit job status', closeSnackbar, enqueueSnackbar, err);
      });
  };

  return (
    <Section>
      <Card title="Job Status">
        <List>
          <ListItem>
            <Grid container spacing={3}>
              <Grid item>
                <JobStatusValue value={job.status} />
              </Grid>

              {!jobStatusCanBeUpdated && (
                <Grid item>The job status can only be updated when job status is Pending, Commissioning or Error.</Grid>
              )}
            </Grid>
          </ListItem>
        </List>
        {jobStatusCanBeUpdated && (
          <FormActions>
            <Button variant="outlined" onClick={() => handleUpdateJobStatus(JobStatus.Cancelled)}>
              Cancel Job
            </Button>
            <Box m={2} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleUpdateJobStatus(JobStatus.ManuallyCommissioned)}
            >
              Complete Job
            </Button>
          </FormActions>
        )}
        <Box m={1} />
        <List inset variant="bordered">
          <ListHeader>
            <Grid container>
              <Grid item xs={4}>
                Date
              </Grid>
              <Grid item xs={8}>
                Result
              </Grid>
            </Grid>
          </ListHeader>
          {job.commissionResults && job.commissionResults.length > 0 ? (
            job.commissionResults.map(({ date, result }) => (
              <ListItem key={uid(date)}>
                <Grid container>
                  <Grid item xs={4}>
                    {format(new Date(date), `${DateFormatDateFns.Long} ${TimeFormatDateFns.WithSeconds}`)}
                  </Grid>
                  <Grid item xs={8}>
                    {HumanReadableCommissionResult[result]}
                  </Grid>
                </Grid>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <Grid container justify="center">
                <Grid item>No commissioning attempts</Grid>
              </Grid>
            </ListItem>
          )}
        </List>
      </Card>
    </Section>
  );
};
