import * as React from 'react';
import { StyledLabel, StyledField, StyledChildren } from './styled';

export interface FieldProps {
  label?: React.ReactNode;
  children?: React.ReactNode;
}

export const Field: React.FC<FieldProps> = ({ label, children }) => (
  <StyledField>
    <StyledLabel>{label}</StyledLabel>
    <StyledChildren>{children}</StyledChildren>
  </StyledField>
);
