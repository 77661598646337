import Box, { BoxProps } from '@material-ui/core/Box';
import * as React from 'react';
import { Description, Header, Title } from './styled';

export interface SectionProps extends Omit<BoxProps, 'title'> {
  title?: React.ReactNode;
  description?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ children, title, description, ...rest }) => (
  <Box {...rest}>
    <Header>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
    </Header>
    {children}
  </Box>
);

Section.displayName = 'Section';

export { Section };
