import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { clearCurrentLocationAction, getCurrentLocationAction } from '../../../../../store/location/actions';
import { fullStateSelector } from '../../../../../store/root';
import type { StoreContextProps } from '../../../../../store/types';
import { NOT_FOUND_API_ERROR_STATUS } from '../../../../../utils/error';

export const useLocation: () => StoreContextProps<'location'> = () => {
  const {
    location: { loading, error, current, selectedItemId: selectedLocationId },
  } = useSelector(fullStateSelector);

  const dispatch = useDispatch();

  const {
    params: { id: pathLocationId },
  } = useRouteMatch<{ slug: string; id: string }>();

  const refetch = React.useCallback(() => {
    if (current) {
      dispatch(clearCurrentLocationAction());
    }
    dispatch(getCurrentLocationAction(pathLocationId));
  }, [current, dispatch, pathLocationId]);

  React.useEffect(() => {
    if (!pathLocationId) {
      // Route params are not available in some contexts, such as nav views.
      return;
    }

    if (selectedLocationId !== pathLocationId) {
      dispatch(clearCurrentLocationAction());
      dispatch(getCurrentLocationAction(pathLocationId));
    }
  }, [current, dispatch, loading, pathLocationId, selectedLocationId]);

  return { error, loading, location: current, refetch, selectedItemId: selectedLocationId };
};

const context = React.createContext<StoreContextProps<'location'>>({ loading: false, refetch: () => {} });
export const LocationProvider: React.FC = ({ children }) => {
  const {
    params: { id: pathLocationId, slug },
  } = useRouteMatch<{ slug: string; id: string }>();
  const locationState = useLocation();

  const { error, selectedItemId: selectedLocationId } = locationState;
  if (error?.status === NOT_FOUND_API_ERROR_STATUS && pathLocationId === selectedLocationId) {
    return <Redirect to={`/organisations/${slug}/not-found`} />;
  }

  return <context.Provider value={locationState}>{children}</context.Provider>;
};
