import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import * as Yup from 'yup';
import { RequestCommandSudo, useCommandSudo } from '../../../../../../../../app/ApiGen';
import { Dialog, DialogProps } from '../../../../../../../../components/Dialog';
import { Form, OnSubmit } from '../../../../../../../../design-system';
import { createSchema } from '../../../../../../../../utils/formUtils';
import { useChargePoint } from '../../../Provider';
import { useHandleError } from './useHandleError';

const initialValues = {
  action: '',
  message: '',
};

type SudoDialogFields = {
  action: string;
  message: string;
};

const validationSchema = createSchema<SudoDialogFields>({
  action: Yup.string().required('Action is required'),
});

export const SudoDialog: React.FC<DialogProps> = ({ isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { chargePoint, refetch } = useChargePoint();
  const { error, mutate } = useCommandSudo({ id: chargePoint?.id ?? '' });

  useHandleError({ error, defaultErrorMessage: 'Unable to send the custom OCPP message' });

  const onSubmit = React.useCallback<OnSubmit<SudoDialogFields>>(
    async ({ action, message }, { setSubmitting }) => {
      try {
        const { data } = await mutate({
          action: action as RequestCommandSudo['action'],
          message: message ? (JSON.parse(message) as RequestCommandSudo['message']) : {},
        });
        enqueueSnackbar(`OCPP Action ${data.status.toLocaleLowerCase()}`);
        onClose();
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [mutate, enqueueSnackbar, onClose, refetch],
  );

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="OCPP Action">
      <Form<SudoDialogFields>
        fields={[
          {
            id: 'action',
            label: 'OCPP Action',
            inputComponent: (props) => (
              <TextField
                select
                variant="outlined"
                margin="dense"
                fullWidth
                style={{ margin: 0 }}
                disabled={props.disabled}
                error={props.error}
                name={props.name}
                onBlur={props.onBlur}
                onChange={props.onChange}
                defaultValue=""
              >
                {[
                  'CancelReservation',
                  'ChangeAvailability',
                  'ChangeConfiguration',
                  'ClearCache',
                  'ClearChargingProfile',
                  'DataTransfer',
                  'DeleteCertificate',
                  'GetCompositeSchedule',
                  'GetDiagnostics',
                  'GetInstalledCertificateIds',
                  'GetLocalListVersion',
                  'GetLog',
                  'InstallCertificate',
                  'RemoteStartTransaction',
                  'RemoteStopTransaction',
                  'ReserveNow',
                  'Reset',
                  'SendLocalList',
                  'SetChargingProfile',
                  'TriggerMessage',
                  'UnlockConnector',
                  'UpdateFirmware',
                ].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </TextField>
            ),
          },
          {
            id: 'message',
            label: 'Message',
            inputComponent: (props) => (
              <TextField
                variant="outlined"
                margin="dense"
                fullWidth
                disabled={props.disabled}
                error={props.error}
                helperText={props.helperText}
                name={props.name}
                onBlur={props.onBlur}
                onChange={props.onChange}
                defaultValue=""
              />
            ),
          },
        ]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        labels={{ save: 'Send command' }}
        isEditing
      />
    </Dialog>
  );
};
