import { amber, blue, common, cyan, deepOrange, green, purple, red } from '@material-ui/core/colors';
import { rgba } from 'polished';

export interface DefaultPalette {
  main: string;
  contrastText: string;
  default?: string;
}

export interface ShadePalette extends DefaultPalette {
  light: string;
  dark: string;
}

export type BaseColor = 'red' | 'amber' | 'deepOrange' | 'green' | 'blue' | 'cyan' | 'purple' | 'steel';
export type BasePalette = Record<BaseColor, ShadePalette>;

export const basePalette: BasePalette = {
  red: {
    main: red[600],
    contrastText: common.white,
    light: red[500],
    dark: red[700],
  },
  amber: {
    main: amber[600],
    contrastText: common.black,
    light: amber[500],
    dark: amber[700],
  },
  deepOrange: {
    main: deepOrange[400],
    contrastText: common.black,
    light: deepOrange[300],
    dark: deepOrange[500],
  },
  green: {
    main: green[600],
    contrastText: common.white,
    light: green[500],
    dark: green[700],
  },
  blue: {
    main: blue[600],
    contrastText: common.white,
    light: blue[500],
    dark: blue[700],
  },
  cyan: {
    main: cyan[600],
    contrastText: common.white,
    light: cyan[500],
    dark: cyan[700],
  },
  purple: {
    main: purple[600],
    contrastText: common.white,
    light: purple[500],
    dark: purple[700],
  },
  steel: {
    main: '#6D6D6D',
    contrastText: common.white,
    light: '#808080',
    dark: '#5F5F5F',
  },
};

export type EvnexColor =
  | 'evnex'
  | 'charcoal'
  | 'sand'
  | 'bone'
  | 'stone'
  | 'driftwood'
  | 'tussock'
  | 'enabled'
  | 'disabled';
export type EvnexPalette = Record<EvnexColor, DefaultPalette>;

export const evnexPalette: EvnexPalette = {
  evnex: {
    main: '#161c2d',
    contrastText: common.white,
  },
  charcoal: {
    main: '#141414',
    contrastText: common.white,
  },
  sand: {
    main: '#beb9af',
    contrastText: '#141414', // Charcoal
  },
  bone: {
    main: '#F3F2EE',
    contrastText: '#141414', // Charcoal
  },
  stone: {
    main: '#958f8e',
    contrastText: '#141414', // Charcoal
  },
  driftwood: {
    main: '#2b2423',
    contrastText: common.white,
  },
  tussock: {
    main: '#db9d32',
    contrastText: '#141414', // Charcoal
  },
  enabled: {
    main: '#539804',
    contrastText: '#141414', // Charcoal
  },
  disabled: {
    main: '#202020',
    contrastText: common.white,
  },
};

export const base = {
  palette: { ...evnexPalette, ...basePalette },
  shadows: {
    0: 'none',
    1: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    2: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    3: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    4: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    5: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    6: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    7: '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    8: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    9: '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    10: '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    11: '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    12: '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    13: '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    14: '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    15: '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    16: '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    17: '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    18: '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    19: '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    20: '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    21: '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    22: '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    23: '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    24: '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
    subtle: `0px 0px 2px 0px ${rgba(evnexPalette.evnex.main, 0.25)}`,
  },
};
