import type { ActiveElement, ChartDataset, ChartOptions, ScatterDataPoint } from 'chart.js';
import { Chart } from 'chart.js';
import { basePalette } from '../../../../../../../design-system';
import { chartColours } from '../../../../../../../theme/globalStyles';
import { updateChartZoomLocations } from '../../../../../../../utils/chart';
import { CHART_DATE_FORMAT } from '../../Diagnostics/utils/meteringCharts';

export type Phase = 'phaseL1' | 'phaseL2' | 'phaseL3';
export type LineDataset = ChartDataset<'line', ScatterDataPoint[]>;
export type DatasetKey = 'client' | 'groupLimit' | 'maxSafeLimit' | 'sharedLimit' | 'supplyCurrent';

export const chartIds: Record<Phase, string> = {
  phaseL1: 'phaseL1Chart',
  phaseL2: 'phaseL2Chart',
  phaseL3: 'phaseL3Chart',
};

type PresentationAttributes = {
  backgroundColour?: string;
  borderColour?: string;
  borderWidth: number;
  fill?: boolean;
  hidden: boolean;
  label?: string;
  stackId: DatasetKey;
  stackOrder: number;
};

export const Presentation: Record<DatasetKey, PresentationAttributes> = {
  groupLimit: {
    borderColour: 'red',
    borderWidth: 4,
    label: 'Group limit',
    hidden: false,
    stackId: 'groupLimit',
    stackOrder: 0,
  },
  sharedLimit: {
    borderColour: 'green',
    borderWidth: 4,
    label: 'Shared limit',
    hidden: false,
    stackId: 'sharedLimit',
    stackOrder: 1,
  },
  maxSafeLimit: {
    borderColour: 'purple',
    borderWidth: 4,
    label: 'Max safe limit',
    hidden: false,
    stackId: 'maxSafeLimit',
    stackOrder: 2,
  },
  supplyCurrent: {
    borderColour: 'blue',
    borderWidth: 4,
    label: 'Supply current',
    hidden: true,
    stackId: 'supplyCurrent',
    stackOrder: 3,
  },
  client: { borderWidth: 1, fill: true, hidden: false, stackId: 'client', stackOrder: 4 },
};

export const colourOptions = [
  basePalette.blue.main,
  basePalette.steel.main,
  basePalette.purple.main,
  basePalette.amber.main,
  basePalette.cyan.main,
  ...chartColours,
];

export function getDataset(args: { key: DatasetKey; data: ScatterDataPoint[] }): LineDataset {
  const { data, key } = args;
  const { backgroundColour, borderColour, borderWidth, fill, hidden, label, stackId, stackOrder } = Presentation[key];
  return {
    backgroundColor: backgroundColour,
    borderColor: borderColour,
    borderWidth,
    data,
    fill,
    hidden,
    label,
    order: stackOrder,
    pointRadius: 0,
    stack: stackId,
  };
}

export const getChartOptions = (onClickHandler: (chart: Chart) => void): ChartOptions<'line'> => ({
  scales: {
    x: { type: 'time', time: { tooltipFormat: CHART_DATE_FORMAT } },
    y: {
      stacked: true,
      afterFit(scale) {
        // eslint-disable-next-line no-param-reassign -- -- Reassigning the `scale` param's properties is the accepted way to set dimensions in ChartJS
        scale.width = 100;
      },
      ticks: { crossAlign: 'near' },
      afterSetDimensions: (scale) => {
        // eslint-disable-next-line no-param-reassign -- Reassigning the `scale` param's properties is the accepted way to set dimensions in ChartJS
        scale.maxWidth = 100;
      },
    },
  },
  animation: false,
  maintainAspectRatio: false,
  interaction: { intersect: false, mode: 'nearest', axis: 'x' },
  plugins: {
    legend: { position: 'bottom' },
    zoom: {
      pan: { enabled: true, modifierKey: 'ctrl', mode: 'x' },
      zoom: {
        mode: 'x',
        drag: { enabled: true },
        onZoomComplete: (selectedChart) => updateChartZoomLocations<Phase>(selectedChart, chartIds),
      },
    },
  },
  onClick: (_event: never, _active: never, chart: Chart) => {
    onClickHandler(chart);
  },
});

export function timestampOfActiveElement(
  activeElements: ActiveElement[],
  datasets: ChartDataset[] | undefined,
): number | undefined {
  if (activeElements.length < 1 || typeof datasets === 'undefined' || datasets.length < 1) {
    return undefined;
  }
  const { index } = activeElements[0];
  const foundDatum = datasets
    // NB: type for dataset.data[index] is incorrect - should be {x: number; y: number}
    .map((dataset) => dataset.data[index] as { x: number; y: number } | undefined)
    .find((datum): datum is { x: number; y: number } => typeof datum !== 'undefined');
  return foundDatum?.x;
}
