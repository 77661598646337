import * as React from 'react';
import * as Yup from 'yup';
import { VendorInput } from '../../../../../../components/CustomFields/Vendor';
import { WizardStep } from '../../../../../../components/WizardStep';
import { FormField, OnSubmit, useWizard } from '../../../../../../design-system';
import { createSchema } from '../../../../../../utils/formUtils';
import { VendorWizardStep } from '../types';

const fields: FormField[] = [
  {
    id: 'vendor',
    label: 'Vendor',
    inputComponent: VendorInput,
  },
];

const initialValues = {
  vendor: 'evnex',
};

const validationSchema = createSchema<VendorWizardStep>({
  vendor: Yup.string().required(),
});

export const VendorStep: React.FC = () => {
  const { state: untypedState, setState: untypedSetState, prevStep, nextStep, step } = useWizard();
  const state = untypedState as VendorWizardStep;
  const setState = untypedSetState as (state: VendorWizardStep) => void;

  const onCancel = React.useCallback<OnSubmit<VendorWizardStep>>(
    ({ vendor }) => {
      setState({ vendor });
      prevStep();
    },
    [setState, prevStep],
  );

  const onSubmit = React.useCallback<OnSubmit<VendorWizardStep>>(
    ({ vendor }) => {
      setState({ vendor });
      nextStep();
    },
    [setState, nextStep],
  );

  return (
    <WizardStep<VendorWizardStep>
      fields={fields}
      initialValues={{ ...initialValues, ...state }}
      name="Select your charge point's vendor"
      onCancel={onCancel}
      onSubmit={onSubmit}
      step={step}
      subtitle=""
      validationSchema={validationSchema}
    />
  );
};
