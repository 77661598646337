import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import type { ConnectorFormat } from '../../../../app/ApiGen';

const ConnectorFormatLabel: Record<ConnectorFormat, string> = { CABLE: 'Cable', SOCKET: 'Socket' };
const connectorFormats = Object.entries(ConnectorFormatLabel);

export const FormatInput: React.FC = ({ ...other }) => (
  <TextField select variant="outlined" margin="dense" fullWidth style={{ margin: 0 }} aria-label="Format" {...other}>
    {connectorFormats.map(([value, label]) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ))}
  </TextField>
);
