import moment from 'moment-timezone';
import { Normalised } from '../../../utils/request';
import { ChargePoint } from '../../../app/ApiGen';
import { organisationHasAttributes } from '../../../utils/format';
import { getChargePointConnectivityStatus } from '../../../utils/chargePoints/getChargePointConnectivityStatus';

export type TableChargePoint = Pick<
  Normalised<ChargePoint>,
  | 'connectors'
  | 'createdDate'
  | 'firmware'
  | 'iccid'
  | 'id'
  | 'model'
  | 'name'
  | 'networkStatus'
  | 'networkStatusUpdatedDate'
  | 'ocppChargePointId'
  | 'referenceId'
  | 'serial'
  | 'updatedDate'
  | 'vendor'
> & {
  activeThisMonth: string;
  connectivityStatus: string;
  organisationSlug: string;
  organisationName: string;
  tableData: {
    checked: boolean;
  };
  vendor: string;
};

const activeThisMonth = (chargePoint: Normalised<ChargePoint>): string => {
  if (typeof chargePoint.networkStatusUpdatedDate === 'undefined') {
    return 'No';
  }
  return moment(chargePoint.networkStatusUpdatedDate).isAfter(moment().startOf('month')) ? 'Yes' : 'No';
};

export const constructTableChargePoints = (chargePoints: Normalised<ChargePoint>[]): TableChargePoint[] =>
  chargePoints.map((chargePoint) => {
    const { networkStatus, networkStatusUpdatedDate, organisation, vendor } = chargePoint;
    const orgHasAttributes = organisationHasAttributes(organisation);
    return {
      ...chargePoint,
      activeThisMonth: activeThisMonth(chargePoint),
      connectivityStatus: getChargePointConnectivityStatus(networkStatus, networkStatusUpdatedDate),
      organisationName: orgHasAttributes ? organisation.name : '',
      organisationSlug: orgHasAttributes ? organisation.slug : '',
      tableData: {
        checked: false,
      },
      vendor: vendor ?? 'Unknown',
    };
  });
