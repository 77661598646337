import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import { useCommandGetDiagnostics } from '../../../../../../../../app/ApiGen';
import { Dialog, DialogProps } from '../../../../../../../../components/Dialog';
import { Form, FormField, OnSubmit } from '../../../../../../../../design-system';
import { createSchema } from '../../../../../../../../utils/formUtils';
import { useChargePoint } from '../../../Provider';
import { useHandleError } from './useHandleError';

const DEFAULT_URL =
  'http://endpoint-refactor.mpadppdr3r.ap-southeast-2.elasticbeanstalk.com/test/r98e7whfueHSFUIBFYDSGFIUDSHFDEFJSDE';

const initialValues = {
  location: DEFAULT_URL,
};

type GetDiagnosticsDialogFields = {
  location: string;
};

const fields: FormField[] = [
  {
    id: 'location',
    label: 'Upload URL',
    inputProps: {
      helperText: 'URL where the diagnostics will be saved',
    },
  },
];

const validationSchema = createSchema<GetDiagnosticsDialogFields>({
  location: Yup.string().required('Upload URL is required'),
});

export const GetDiagnosticsDialog: React.FC<DialogProps> = ({ isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { chargePoint, refetch } = useChargePoint();

  const { error, mutate } = useCommandGetDiagnostics({ id: chargePoint?.id ?? '' });

  useHandleError({ error, defaultErrorMessage: 'Unable to upload the charge point diagnostics' });

  const onSubmit = React.useCallback<OnSubmit<GetDiagnosticsDialogFields>>(
    async ({ location }, { setSubmitting }) => {
      try {
        const { data } = await mutate({ location });
        enqueueSnackbar(`Diagnostic command ${data.status.toLowerCase()}`);
        onClose();
        refetch();
      } finally {
        setSubmitting(false);
      }
    },
    [mutate, enqueueSnackbar, onClose, refetch],
  );

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Get charge point diagnostics">
      <Form<GetDiagnosticsDialogFields>
        isEditing
        fields={fields}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        labels={{ save: 'Send command' }}
      />
    </Dialog>
  );
};
