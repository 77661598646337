import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import * as Yup from 'yup';
import { Connector, NetworkStatus } from '../../app/ApiGen';
import { Form } from '../../design-system';
import { getStatusIndicator } from '../Connectors';
import { ExpandButton } from '../ExpandButton';
import { Actions, ConnectorId, Expansion, Indicator, ItemExpansionPanel, ItemRow, Status } from './styled';
import { getConnectorFields, getConnectorSecondaryText } from './util';

export interface ConnectorItemProps {
  connector: Connector;
  networkStatus: NetworkStatus;
  actions?: React.ReactNode;
}

export const ConnectorItem: React.FC<ConnectorItemProps> = ({ networkStatus, connector, actions }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const { connectorId } = connector;

  const { color, label } = getStatusIndicator(networkStatus, connector);
  const secondaryText = networkStatus === 'ONLINE' ? getConnectorSecondaryText(connector) : null;

  return (
    <Box>
      <ItemRow>
        <Indicator>
          <ConnectorId color={color}>{connectorId}</ConnectorId>
        </Indicator>
        <Status>
          <Typography variant="body1">{label}</Typography>
          {secondaryText && secondaryText !== label && (
            <Typography variant="body2" color="textSecondary">
              {secondaryText}
            </Typography>
          )}
        </Status>
        {actions && <Actions>{actions}</Actions>}
        <Expansion>
          <ExpandButton isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
        </Expansion>
      </ItemRow>
      <Fade in={isExpanded} unmountOnExit>
        <ItemExpansionPanel>
          <Form<typeof connector>
            fields={getConnectorFields(networkStatus, connector)}
            initialValues={connector}
            validationSchema={Yup.object().shape({})}
            onSubmit={() => {}}
          />
        </ItemExpansionPanel>
      </Fade>
    </Box>
  );
};
