import type { BrowserOptions } from '@sentry/browser';
import PackageInformation from '../package.json';

const applicationName = PackageInformation.name.slice(1, PackageInformation.name.length).replace('/', ':');

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT ?? '';

export const evnexCpoId = process.env.REACT_APP_EVNEX_CPO_ID ?? '';

// Taken from https://docs.amplify.aws/react/prev/tools/libraries/configure-categories/
type CognitoConfiguration = {
  aws_cognito_region: string; // (required) - Region where Amazon Cognito project was created
  aws_user_pools_id?: string; // (optional) -  Amazon Cognito User Pool ID
  aws_user_pools_web_client_id?: string; // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
  aws_cognito_identity_pool_id?: string; // (optional) - Amazon Cognito Identity Pool ID
  aws_mandatory_sign_in?: string; // (optional) - Users are not allowed to get the aws credentials unless they are signed in
};

export const aws: CognitoConfiguration = {
  aws_cognito_region: process.env.REACT_APP_AWS_REGION ?? '',
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_IDENTITY_POOL_ID ?? '',
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID ?? '',
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID ?? '',
};

export const sentry: BrowserOptions = {
  dsn: 'https://d3d115f303d1418aacfce4cdfb202958@o1278386.ingest.sentry.io/6477723',
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: `${applicationName}@${PackageInformation.version}`,
};

export const environment = process.env.JEST_WORKER_ID ? 'jest' : process.env.NODE_ENV;

export const gtm = {
  gtmId: process.env.REACT_APP_GTM ?? '',
};

export const GoogleApiKey = {
  Maps: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
  TimeZone: process.env.REACT_APP_GOOGLE_TIME_ZONE_API_KEY ?? '',
};
