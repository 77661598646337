import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { SwitchValue } from '../../../../../../../components/CustomFields/Switch';
import { Card } from '../../../../../../../design-system';
import type { LoadBalancingGroup } from '../../../../../../../store/loadBalancingGroup/types';
import { totalChargingCurrent } from '../../../../../../../utils/chargePoints/calculateCurrent';

export const Header: React.FC<{ loadBalancingGroup: LoadBalancingGroup }> = ({ loadBalancingGroup }) => {
  const { phases, enabled, name, chargePoints } = loadBalancingGroup;
  const chargingTotal = totalChargingCurrent(chargePoints);
  return (
    <Card>
      <Box p={2}>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body2" color="textSecondary">
          Status:&nbsp;
        </Typography>
        <SwitchValue value={enabled} labels={{ true: 'Enabled', false: 'Disabled' }} />
        <Typography variant="body2" color="textSecondary">
          Group Current:&nbsp;
          {chargingTotal > 0 && `${chargingTotal} / `}
          {phases[0].maximumCurrent}A
        </Typography>
      </Box>
    </Card>
  );
};
