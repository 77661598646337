import Fade from '@material-ui/core/Fade';
import React from 'react';
import styled from 'styled-components';
import { useLocationMap } from '../../Context';
import { LocationCluster } from './LocationCluster';
import { SingleLocation } from './SingleLocation';

export const PanelWrapper = styled('div')`
  height: ${(p) => p.theme.spacing(50)}px;
  width: ${(p) => p.theme.spacing(40)}px;
`;

export const DetailPanel: React.FC<{ orgSlug: string }> = ({ orgSlug }) => {
  const { isPanelOpen, clusterLocations, singleLocation } = useLocationMap();

  return (
    <Fade in={isPanelOpen} unmountOnExit>
      <PanelWrapper>
        {clusterLocations && <LocationCluster locations={clusterLocations} />}
        {singleLocation && <SingleLocation location={singleLocation} orgSlug={orgSlug} />}
      </PanelWrapper>
    </Fade>
  );
};
