import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateJob, useCreateLocation, useCreateOrganisationUser } from '../../../../../app/ApiGen';
import { errorSnackbar } from '../../../../../components/ErrorSnackbar';
import { FormReadonly, Section, useWizard } from '../../../../../design-system';
import { EvnexJobFormDetails, evnexJobFormFields } from '../../../../../utils/jobs/CreateEvnexJob';
import { JobLocationDetails, jobLocationFields } from '../../../../../utils/jobs/location';
import {
  mapJobWizardStateToRequestCreateJob,
  mapJobWizardStateToRequestCreateLocation,
  mapJobWizardStateToRequestInviteUser,
} from '../../../../../utils/jobs/mappers';
import { UserCreateDetails, userCreateFields } from '../../../../../utils/users/FieldDefinitions';
import { JobWizardState } from '../types';
import { getOrganisationFields, OrganisationDetails } from './OrganisationStep';

export const ConfirmationStep: React.FC = () => {
  const { push } = useHistory();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const { state: untypedState, prevStep, nextStep } = useWizard();
  const state = untypedState as JobWizardState;

  const jobDetailsFields = evnexJobFormFields({ installerOptions: state.assignedInstallers });

  const inviteUser = useCreateOrganisationUser({ orgSlug: state.organisation.slug });
  const createLocation = useCreateLocation({ orgSlug: state.organisation.slug });
  const createJob = useCreateJob({});

  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  const redirectLocation = '/admin/jobs';

  const createJobWithIds = React.useCallback(
    (args: { driverId: string; locationId: string }) => {
      createJob
        .mutate(mapJobWizardStateToRequestCreateJob({ ...state, driverId: args.driverId, locationId: args.locationId }))
        .then(() => {
          enqueueSnackbar(`Home Installation job created`, { variant: 'success' });
          push(redirectLocation);
          nextStep();
        })
        .catch((e) => {
          errorSnackbar('Unable to create pending job', closeSnackbar, enqueueSnackbar, e);
        });
    },
    [closeSnackbar, createJob, enqueueSnackbar, nextStep, push, state],
  );

  const createLocationAndJob = React.useCallback(
    (driverId: string) => {
      createLocation
        .mutate(mapJobWizardStateToRequestCreateLocation(state))
        .then(({ data: location }) => {
          if (location.id !== undefined) {
            enqueueSnackbar(`Location created`, { variant: 'success' });
            createJobWithIds({ driverId, locationId: location.id });
          }
        })
        .catch((e) => {
          errorSnackbar('Unable to create location', closeSnackbar, enqueueSnackbar, e);
        });
    },
    [closeSnackbar, createJobWithIds, createLocation, enqueueSnackbar, state],
  );

  const createJobAndRelated = React.useCallback(() => {
    setSubmitting(true);
    inviteUser
      .mutate(mapJobWizardStateToRequestInviteUser(state))
      .then(({ data: driver }) => {
        if (driver.id !== undefined) {
          enqueueSnackbar(`Customer invited to ${state.organisation.name}`, { variant: 'success' });
          createLocationAndJob(driver.id);
        }
      })
      .catch((e) => {
        errorSnackbar('Unable to create user account', closeSnackbar, enqueueSnackbar, e);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [inviteUser, state, createLocationAndJob, closeSnackbar, enqueueSnackbar]);

  return (
    <>
      <Section title="Organisation details">
        <FormReadonly<OrganisationDetails>
          fields={Object.values(getOrganisationFields([state.organisation]))}
          initialValues={{ organisation: state.organisation }}
        />
      </Section>
      <Section title="Customer details">
        <FormReadonly<UserCreateDetails>
          fields={Object.values(userCreateFields)}
          initialValues={state.driverFieldContents}
        />
      </Section>
      <Section title="New location details">
        <FormReadonly<JobLocationDetails>
          fields={Object.values(jobLocationFields())}
          initialValues={state.locationFieldContents}
        />
      </Section>
      <Section title="Job details">
        <FormReadonly<EvnexJobFormDetails> fields={Object.values(jobDetailsFields)} initialValues={state} />
      </Section>
      <Box pt={2} display="flex">
        <Button variant="contained" onClick={prevStep} disabled={isSubmitting}>
          Back
        </Button>
        <Box mr="auto" />
        <Button color="primary" variant="contained" onClick={createJobAndRelated} disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Create job'}
        </Button>
      </Box>
    </>
  );
};
