import Button from '@material-ui/core/Button';
import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Job, useListJobs } from '../../../app/ApiGen';
import { getToolbarSearch, RefreshButton } from '../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../components/ErrorHandler';
import { ExportCsv, ExportProps } from '../../../components/ExportCsv';
import { TableLink } from '../../../components/Link';
import { Card, ColumnChanger, ColumnChangerProps, DataTable, Workspace } from '../../../design-system';
import { ColumnDefinitions } from '../../../utils/dataTable/columns';
import { navigateOnRowClick } from '../../../utils/dataTable/rowHelpers';
import { getCondensedDate } from '../../../utils/dateFormat';
import { jobColumns, JobListData, jobListOptions, toJobListData } from '../../../utils/jobs/table';
import { normalise, Normalised } from '../../../utils/request';

type InstallerJobData = JobListData;

function toInstallerJobData(jobs: Normalised<Job>[]): InstallerJobData[] {
  return jobs.map(toJobListData);
}

export const JobsList: React.FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { data, error, loading, refetch } = useListJobs({});

  if (error) {
    return <DataErrorHandler description="Unable to load jobs" error={error} refetch={refetch} />;
  }

  const tableData: InstallerJobData[] = data ? toInstallerJobData(normalise(data)) : [];
  const columns = jobColumns as ColumnDefinitions<InstallerJobData>;

  return (
    <Workspace maxWidth={false}>
      <Card>
        <DataTable<InstallerJobData>
          isLoading={loading}
          options={jobListOptions}
          toolbarProps={{
            ...getToolbarSearch('jobs'),
            actions: (props: ColumnChangerProps & ExportProps<InstallerJobData>) => (
              <>
                <ColumnChanger
                  columnsButton={props.columnsButton}
                  columns={props.columns}
                  icons={props.icons}
                  onColumnsChanged={props.onColumnsChanged}
                />
                <RefreshButton refetch={refetch} />
                <ExportCsv<InstallerJobData>
                  columns={props.columns}
                  data={props.data}
                  exportFileName={props.exportFileName}
                  getFieldValue={props.getFieldValue}
                  icons={props.icons}
                />
                <Button component={Link} to="/installer/create/job" color="primary" variant="contained">
                  Create job
                </Button>
              </>
            ),
          }}
          columns={[
            {
              field: 'created',
              title: 'Created',
              type: 'date',
              defaultSort: 'desc',
              render: ({ id, created }) => <TableLink to={`${url}/${id}`}>{getCondensedDate(created)}</TableLink>,
            },
            {
              field: 'updated',
              title: 'Last Updated',
              type: 'date',
              hidden: true,
              render: ({ updated }) => getCondensedDate(updated),
            },
            columns.referenceId,
            columns.type,
            columns.status,
            columns.organisationName,
            columns.customerName,
            columns.email,
            columns.address,
            columns.icpNumber,
            columns.installerLabel,
          ]}
          data={tableData}
          onRowClick={navigateOnRowClick((rowData) => `${url}/${rowData.id}`, history)}
        />
      </Card>
    </Workspace>
  );
};
