import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const StyledContainerHeader = styled('div')`
  max-width: 100%;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const StyledAvatar = styled('div')`
  margin-right: ${(p) => p.theme.spacing(1)}px;
`;

export const Text = styled('div')`
  width: 100%;
`;

export const Primary = styled(Typography)`
  max-width: calc(220px - 32px - 56px);
  line-height: 18px;
  font-size: 14px;
  font-weight: ${(p) => p.theme.typography.fontWeightMedium};
`;

export const Secondary = styled(Typography)`
  max-width: calc(220px - 32px - 56px);
  font-size: 12px;
  line-height: 16px;
`;
