import Skeleton from '@material-ui/lab/Skeleton';
import * as React from 'react';
import { uid } from 'react-uid';
import { Field } from '../../../Field';
import { List, ListItem } from '../../../List';
import { FormField } from '../../types';

export interface FormLoadingProps {
  fields: FormField[];
  inset?: boolean;
}

export const FormLoading: React.FC<FormLoadingProps> = ({ fields, inset }) => (
  <List inset={inset}>
    {fields.map((field, idx) => (
      <ListItem key={uid(field, idx)}>
        <Field label={<Skeleton width="50%" />}>
          <Skeleton width="40%" />
        </Field>
      </ListItem>
    ))}
  </List>
);
