import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import OrganisationView from './Organisation';
import OrganisationPicker from './OrganisationPicker';

const OrganisationsView: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <OrganisationView />
      </Route>

      <Route>
        <OrganisationPicker />
      </Route>
    </Switch>
  );
};

export default OrganisationsView;
