import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import React from 'react';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import { ErrorHandler } from '../../../../../components/ErrorHandler';
import { Card, Loading, Section, Workspace } from '../../../../../design-system';
import { SelectUserStep } from './SelectUserStep';

const AddUserView: React.FC = () => {
  const { loading, organisation } = useCurrentOrganisation();
  if (loading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (!organisation) {
    return (
      <Workspace>
        <ErrorHandler title="Unable to load organisation" />
      </Workspace>
    );
  }

  return (
    <Workspace>
      <Section title="Add existing user" description="Add an existing user to the organisation">
        <Card>
          <Box py={4}>
            <Container maxWidth="sm">
              <SelectUserStep organisation={organisation} />
            </Container>
          </Box>
        </Card>
      </Section>
    </Workspace>
  );
};

export default AddUserView;
