import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import PlaceIcon from '@material-ui/icons/Place';
import React from 'react';
import styled from 'styled-components';

interface WrappedAvatarProps extends AvatarProps {
  bgColor?: string;
}

const WrappedAvatar: React.FC<WrappedAvatarProps> = React.forwardRef(
  ({ bgColor, ...rest }: WrappedAvatarProps, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading -- There are more than 250 properties to spread.
    <Avatar variant="rounded" {...rest} ref={ref} />
  ),
);

WrappedAvatar.defaultProps = { bgColor: undefined };

export const LocationAvatar = styled(WrappedAvatar)`
  ${(p) => (p.bgColor ? `background-color: #${`${p.bgColor}`.substring(0, 6)};` : '')}
  outline: none;
`;

export const getLocationAvatar: () => React.ReactElement = () => (
  <LocationAvatar>
    <PlaceIcon />
  </LocationAvatar>
);
