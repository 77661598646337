import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  changeCurrentOrganisationAction,
  clearCurrentOrganisationAction,
  setCurrentOrganisationAction,
} from '../store/organisation/actions';
import { fullStateSelector } from '../store/root';
import type { StoreContextProps } from '../store/types';

export const useCurrentOrganisation: () => StoreContextProps<'organisation'> = () => {
  const {
    authenticatedUser: { loading: userLoading, current: user },
    organisation: { loading: organisationLoading, current: organisation },
  } = useSelector(fullStateSelector);

  const dispatch = useDispatch();

  const {
    params: { slug: organisationSlug },
  } = useRouteMatch<{ slug: string }>();

  React.useEffect(() => {
    if (!organisationSlug) {
      // Route params are not available in some contexts, such as nav views.
      return;
    }

    if (!organisationLoading && !organisation) {
      // Initial action, if the store is still in its initial state
      const selectedOrg = user?.organisations.find(({ slug }) => organisationSlug === slug);
      if (selectedOrg) {
        dispatch(setCurrentOrganisationAction(selectedOrg));
      }
    }

    if (!organisationLoading && organisationSlug !== organisation?.slug) {
      dispatch(changeCurrentOrganisationAction(organisationSlug));
    }

    const userHasGivenSlug = user?.organisations.find(({ slug }) => slug === organisationSlug);
    if (!userHasGivenSlug) {
      dispatch(clearCurrentOrganisationAction());
    }
  }, [dispatch, organisation, organisationLoading, organisationSlug, user?.organisations]);

  return { loading: organisationLoading || userLoading, organisation };
};

const context = React.createContext<StoreContextProps<'organisation'>>({ loading: false });
export const CurrentOrganisationProvider: React.FC = ({ children }) => {
  const organisationState = useCurrentOrganisation();
  return <context.Provider value={organisationState}>{children}</context.Provider>;
};
