import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useListOrganisationLoadBalancingGroups } from '../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import { SwitchValue } from '../../../../../components/CustomFields/Switch';
import { getToolbarSearch, RefreshButton } from '../../../../../components/DataTable/Toolbar';
import { DataErrorHandler, ErrorHandler } from '../../../../../components/ErrorHandler';
import { TableLink } from '../../../../../components/Link';
import { ColumnChanger, ColumnChangerProps, DataTable, Loading, Workspace } from '../../../../../design-system';
import type { LoadBalancingGroup } from '../../../../../store/loadBalancingGroup/types';
import { fromDTO } from '../../../../../store/loadBalancingGroup/types';
import { SubState } from '../../../../../store/types';
import { navigateOnRowClick } from '../../../../../utils/dataTable/rowHelpers';
import { includesSearch } from '../../../../../utils/search';

const ENABLED_TEXT = 'Enabled';
const DISABLED_TEXT = 'Disabled';

const formatMaxCurrent = (phases: LoadBalancingGroup['phases']) => `${phases[0].maximumCurrent}A`;

const LoadBalancingGroups: React.FC<{
  organisation: SubState<'organisation'>;
  url: string;
}> = ({ organisation, url }) => {
  const history = useHistory();
  const { loading, error, data, refetch } = useListOrganisationLoadBalancingGroups({ orgId: organisation.id });

  if (loading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (error || !data) {
    return (
      <DataErrorHandler
        description="Unable to fetch organisation's load balancing groups"
        error={error}
        refetch={refetch}
      />
    );
  }

  const loadBalancingGroups = data.data.map(fromDTO);

  return (
    <Workspace maxWidth="xl">
      <Card>
        <DataTable<LoadBalancingGroup>
          options={{ columnsButton: true }}
          columns={[
            {
              title: 'Name',
              field: 'name',
              defaultSort: 'asc',
              render: ({ id, name }: LoadBalancingGroup) => <TableLink to={`${url}/${id}`}>{name}</TableLink>,
              customFilterAndSearch: (searchValue: string, { name }: LoadBalancingGroup) =>
                includesSearch(name, searchValue),
            },
            {
              title: 'Load Balancing Group ID',
              field: 'id',
              hidden: true,
            },
            {
              title: 'Status',
              field: 'enabled',
              render: ({ enabled }: LoadBalancingGroup) => (
                <SwitchValue value={enabled} labels={{ true: ENABLED_TEXT, false: DISABLED_TEXT }} />
              ),
              customFilterAndSearch: (searchValue: string, { enabled }: LoadBalancingGroup) => {
                const keyword = enabled ? ENABLED_TEXT : DISABLED_TEXT;
                return includesSearch(keyword, searchValue);
              },
            },
            {
              title: 'Max. Current (A)',
              field: 'phases',
              render: ({ phases }: LoadBalancingGroup) => (
                <Typography style={{ fontSize: 'inherit' }}>{formatMaxCurrent(phases)}</Typography>
              ),
              customFilterAndSearch: (searchValue: string, { phases }: LoadBalancingGroup) =>
                includesSearch(formatMaxCurrent(phases), searchValue),
            },

            {
              title: 'No. Charge Points',
              field: 'chargePoints',
              render: ({ chargePoints }: LoadBalancingGroup) => (
                <Typography style={{ fontSize: 'inherit' }}>{chargePoints.length}</Typography>
              ),
              customFilterAndSearch: (searchValue: string, { chargePoints }: LoadBalancingGroup) =>
                includesSearch(`${chargePoints.length}`, searchValue),
            },
          ]}
          toolbarProps={{
            ...getToolbarSearch('groups'),
            actions: (props: ColumnChangerProps) => (
              <>
                <ColumnChanger
                  columnsButton={props.columnsButton}
                  columns={props.columns}
                  icons={props.icons}
                  onColumnsChanged={props.onColumnsChanged}
                />
                <RefreshButton refetch={refetch} />
                <Button
                  component={Link}
                  to={`/organisations/${organisation.slug}/create/load-balancing-group`}
                  color="primary"
                  variant="contained"
                >
                  Add load balancing group
                </Button>
              </>
            ),
          }}
          data={loadBalancingGroups}
          onRowClick={navigateOnRowClick((rowData) => `${url}/${rowData.id}`, history)}
        />
      </Card>
    </Workspace>
  );
};

const LoadBalancingGroupsListView: React.FC = () => {
  const { url } = useRouteMatch<{ slug: string }>();
  const { organisation, loading } = useCurrentOrganisation();

  if (loading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }
  if (!organisation) {
    return <ErrorHandler description="Unable to load organisation" />;
  }
  return <LoadBalancingGroups organisation={organisation} url={url} />;
};

export default LoadBalancingGroupsListView;
