import React from 'react';
import * as Yup from 'yup';
import { useGetOrganisationTokens } from '../../../../../../../../app/ApiGen';
import { TokenInput } from '../../../../../../../../components/CustomFields/Token';
import { Dialog, DialogProps } from '../../../../../../../../components/Dialog';
import { Form, InputComponentProps } from '../../../../../../../../design-system';
import { createSchema } from '../../../../../../../../utils/formUtils';

type TokenSelectFormProps = {
  tokenUid: string;
};

const initialValues: TokenSelectFormProps = {
  tokenUid: '',
};

const validationSchema = createSchema<TokenSelectFormProps>({
  tokenUid: Yup.string().required('Token is required'),
});

interface StartChargingDialogProps {
  orgSlug: string;
  isOpen: DialogProps['isOpen'];
  onClose: DialogProps['onClose'];
  onSubmit: (tokenUid: string) => void;
  id: number;
}

export const StartChargingDialog: React.FC<StartChargingDialogProps> = ({ isOpen, onClose, onSubmit, id, orgSlug }) => {
  const { loading, error, data } = useGetOrganisationTokens({ orgSlug });

  return (
    <Dialog title={`Start charging connector ${id}`} isOpen={isOpen} onClose={onClose}>
      <Form<TokenSelectFormProps>
        fields={[
          {
            id: 'tokenUid',
            label: 'Token',
            inputComponent: ({
              disabled,
              error: fieldError,
              helperText,
              name,
              onBlur,
              onChange,
              value,
            }: InputComponentProps<string | undefined>) => (
              <TokenInput
                dataError={error}
                dataLoading={loading}
                disabled={disabled}
                error={fieldError}
                helperText={helperText}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                options={data?.data.items}
                value={value}
              />
            ),
            inputProps: { helperText: 'To begin charging please select a token' },
          },
        ]}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={({ tokenUid }) => onSubmit(tokenUid)}
        isEditing
        labels={{ save: 'Start charging' }}
        formProps={{ autoComplete: 'off' }}
      />
    </Dialog>
  );
};
