import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useChargePoint } from '../Provider';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { TimeOfUsePricingSchedule } from './components/TimeOfUsePricingSchedule';
import { ElectricityCostSettings } from './components/ElectricityCostSettings';
import { Loading, Section, Workspace } from '../../../../../../design-system';

export const ElectricityPricingView: React.VFC = () => {
  const { chargePoint, error, loading, refetch } = useChargePoint();

  if (loading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (error || !chargePoint) {
    return <DataErrorHandler description="Unable to load charge point" error={error} refetch={refetch} />;
  }

  return (
    <Workspace maxWidth="lg">
      <Section title="Electricity pricing" description="Cost and currency settings.">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ElectricityCostSettings chargePoint={chargePoint} refetch={refetch} />
          </Grid>
          <Grid item xs={12}>
            <TimeOfUsePricingSchedule chargePoint={chargePoint} />
          </Grid>
        </Grid>
      </Section>
    </Workspace>
  );
};
