import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import * as React from 'react';

export interface SearchFieldProps {
  /** Placeholder to display in the field. */
  placeholder?: string;
  /** DO NOT SET - Provided by the table props. */
  search: boolean;
  /** DO NOT SET - Provided by the table props. */
  searchText: string;
  /** DO NOT SET - Provided by the table props. */
  onSearchChanged: (next: string) => void;
}

export const SearchField: React.FC<SearchFieldProps> = ({ search, searchText, onSearchChanged, placeholder }) => {
  if (!search) {
    return null;
  }

  return (
    <TextField
      margin="dense"
      variant="outlined"
      placeholder={placeholder ?? 'Search...'}
      value={searchText}
      onChange={(event) => onSearchChanged(event.target.value)}
      style={{ margin: 0 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="inherit" fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton disabled={!searchText} onClick={() => onSearchChanged('')} size="small">
              <ClearIcon color="inherit" fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
