import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useNavigationView } from '../../../../design-system';
import { userNav } from './navView';
import { UserProvider } from './Provider';
import UserLocationsView from './UserLocationsView';
import UserMembershipsView from './UserMembershipsView';
import { UserOverview } from './UserOverview';

const UserView: React.FC = () => {
  const {
    path,
    url,
    params: { userId },
  } = useRouteMatch<{ userId: string }>();

  useNavigationView(userNav);

  return (
    <UserProvider>
      <Switch>
        <Route path={`${path}/overview`}>
          <UserOverview />
        </Route>
        <Route path={`${path}/memberships`}>
          <UserMembershipsView />
        </Route>
        <Route path={`${path}/locations`}>
          <UserLocationsView userId={userId} />
        </Route>
        <Route>
          <Redirect to={`${url}/overview`} />
        </Route>
      </Switch>
    </UserProvider>
  );
};

export default UserView;
