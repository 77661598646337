import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useNavigationView } from '../../../../design-system';
import MonthlyUseByCharger from './MonthlyUseByCharger';
import { reportsNav } from './navView';
import SessionsView from './SessionsView';

const Routes: React.FC = () => {
  const {
    path,
    url,
    params: { slug },
  } = useRouteMatch<{ slug: string }>();

  useNavigationView(reportsNav);

  return (
    <Switch>
      <Route path={`${path}/sessions`}>
        <SessionsView orgSlug={slug} />
      </Route>
      <Route path={`${path}/monthly-use-by-charger`}>
        <MonthlyUseByCharger orgSlug={slug} />
      </Route>
      <Route>
        <Redirect to={`${url}/sessions`} />
      </Route>
    </Switch>
  );
};

const ReportsView: React.FC = () => <Routes />;

export default ReportsView;
