import { LegacyUser } from '../../../../../app/ApiGen';
import { emailDomain } from '../../../../../utils/format';
import { evnexCpoId } from '../../../../../config';
import { DateFormatDateFns, TimeFormatDateFns } from '../../../../../utils/dateFormat';

export const shouldTrackAnalytics = (user?: LegacyUser): boolean => {
  if (!user) {
    return false;
  }
  const { email, organisations } = user;
  const isEvnexUser = emailDomain(email) === 'evnex.com';
  const isInEvnexCpo = organisations.some((org) => org.id === evnexCpoId);
  return !isEvnexUser && !isInEvnexCpo;
};

export const csvDateFormat = `${DateFormatDateFns.Filename} ${TimeFormatDateFns.TwentyFourHoursWithSeconds}`;
