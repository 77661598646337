import Box from '@material-ui/core/Box';
import * as React from 'react';
import { ChargePointLog } from '../../../../../../utils/api';

const getParsedData = (data: unknown) => {
  if (typeof data === 'string') {
    try {
      return JSON.parse(data) as Record<string, unknown>;
      // eslint-disable-next-line no-empty -- there's no way to conditionally parse JSON without a try catch
    } catch {}
  }

  return data;
};

export const renderLogData = (log: ChargePointLog): React.ReactNode => {
  if (!log.data) {
    return <Box component="pre" m={2} />;
  }

  const logData = log.data as Record<string, unknown>;
  const displayData = logData.data ? { ...logData, data: getParsedData(logData.data) } : logData;

  return (
    <Box component="pre" m={2}>
      {JSON.stringify(displayData, null, 2)}
    </Box>
  );
};
