import { Auth } from 'aws-amplify';
import * as React from 'react';
import { Store } from 'redux';
import { RestfulProvider, RestfulReactProviderProps } from 'restful-react';
import { action } from 'typesafe-actions';
import { apiEndpoint } from '../config';
import { AuthenticatedUserAction } from '../store/authenticatedUser/types';
import { standardiseError } from '../utils/error';

function isMfaError(e: unknown) {
  const error = standardiseError(e);
  return error.status === '403' && error.detail === 'MFA step-up required';
}

function onError(err: unknown, store: Store): void {
  if (isMfaError(err)) {
    store.dispatch(action(AuthenticatedUserAction.SET_AUTHENTICATED_USER_NEEDS_MFA));
  }
}

type RestfulReactProviderParams = Omit<RestfulReactProviderProps, 'base'> & {
  store: Store;
};

export const RestfulReactProvider: React.FC<RestfulReactProviderParams> = ({ children, store, ...rest }) => (
  <RestfulProvider
    base={apiEndpoint}
    requestOptions={async () =>
      Auth.currentSession()
        .then((session) => ({ headers: { authorization: session.getAccessToken().getJwtToken() } }))
        .catch(() => ({}))
    }
    onError={(err) => onError(err, store)}
    {...rest}
  >
    {children}
  </RestfulProvider>
);
