import type {
  ChargePointIdentifier,
  ChargePointSummary,
  LocationIdentifier,
  LocationSummary,
  MembershipIdentifier,
  OrganisationIdentifier,
  OrganisationSummary,
  UserIdentifier,
  UserSummary,
} from '../app/ApiGen';
import { NormalisedMembership } from '../store/user/types';
import type { Normalised } from './request';

export const pluralise = (count: number, item: string): string =>
  count === 1 ? `${count} ${item}` : `${count} ${item}s`;

export function basicStringify(value: unknown): string {
  if (value !== false && !value) {
    return '';
  }
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }
  return String(value);
}

export function locationHasAttributes(
  location: Normalised<LocationSummary> | LocationIdentifier | null,
): location is Normalised<LocationSummary> {
  return !!location && 'address' in location;
}

export function userHasAttributes(
  user: Normalised<UserSummary> | UserIdentifier | null,
): user is Normalised<UserSummary> {
  return !!user && 'email' in user;
}

export function chargePointHasAttributes(
  chargePoint: Normalised<ChargePointSummary> | ChargePointIdentifier | null,
): chargePoint is Normalised<ChargePointSummary> {
  return !!chargePoint && 'ocppChargePointId' in chargePoint;
}

export function membershipHasAttributes(
  membership: Partial<NormalisedMembership> | MembershipIdentifier | null,
): membership is NormalisedMembership & { organisation: Normalised<OrganisationSummary> } {
  return !!membership && 'organisation' in membership && !!membership.organisation && 'name' in membership.organisation;
}

export function organisationHasAttributes(
  organisation: Normalised<OrganisationSummary> | OrganisationIdentifier | null,
): organisation is Normalised<OrganisationSummary> {
  return !!organisation && 'name' in organisation;
}

export const getDisplayUser = (args: {
  user: Normalised<UserSummary> | UserIdentifier | null;
  noUserLabel: string;
}): string => {
  const { noUserLabel, user } = args;
  if (userHasAttributes(user) && user.name !== undefined) {
    return user.name;
  }
  return noUserLabel;
};

export const emailDomain = (email: string): string => email.split('@')[1];

function emailDomains(acc: Set<string>, user: Normalised<UserSummary>): Set<string> {
  acc.add(emailDomain(user.email));
  return acc;
}

export const renderUserList = (userList: (Normalised<UserSummary> | UserIdentifier)[], descriptor = 'user'): string => {
  if (userList.length === 0) {
    return '(none)';
  }
  const userCount = pluralise(userList.length, descriptor);
  if (!userList.every(userHasAttributes)) {
    return userCount;
  }
  if (userList.length === 1) {
    return userList[0].name ?? userList[0].email;
  }
  const domainList = Array.from(userList.reduce(emailDomains, new Set()));
  const domainLabel = domainList.length === 1 ? domainList[0] : 'multiple domains';
  return `${userCount} from ${domainLabel}`;
};
