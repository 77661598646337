import moment from 'moment-timezone';
import React from 'react';
import { Session, useListChargePointSessions } from '../../../../../app/ApiGen';
import { useDeveloperMode } from '../../../../../app/DeveloperModeProvider';
import { getToolbarSearch, RefreshButton } from '../../../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { Card, ColumnChanger, ColumnChangerProps, DataTable, Section, Workspace } from '../../../../../design-system';
import { chargePointHasAttributes, locationHasAttributes } from '../../../../../utils/format';
import { getAddressAsString } from '../../../../../utils/locations/address';
import { normalise, Normalised } from '../../../../../utils/request';
import { includesSearch } from '../../../../../utils/search';
import { RemappedSession } from '../../../../../utils/sessions';
import {
  carbonOffsetColumn,
  connectorIdColumn,
  evseIdColumn,
  sessionIdColumn,
  stopReasonColumn,
  tokenNameColumn,
  tokenUidColumn,
  totalCostColumn,
  totalChargingCostColumn,
} from '../../../../../utils/sessions/columns/creator';
import {
  dateFormat,
  durationFormatFromStartEnd,
  powerUsageWithUnit,
} from '../../../../../utils/sessions/columns/format';
import { renderDate, renderTotalCarbonUsage } from '../../../../../utils/sessions/columns/render';
import { searchTotalCarbonUsage } from '../../../../../utils/sessions/columns/search';
import { sortByDate, sortByDuration, sortByValue } from '../../../../../utils/sortBy';
import { useChargePoint } from './Provider';

const toRowData = (data: Normalised<Session>[]): RemappedSession[] =>
  data.map<RemappedSession>(
    ({
      id,
      evseId,
      chargePoint,
      connectorId,
      token,
      startDate,
      endDate,
      totalPowerUsage,
      electricityCost,
      chargePointReferenceId,
      location,
      transaction,
      totalCost,
      totalCarbonUsage,
      chargingConfiguration,
      cost,
    }) => ({
      connectorId,
      endDate,
      evseId,
      id,
      startDate,
      chargePointId: id,
      chargePointRefId: chargePointReferenceId,
      carbonOffset: totalPowerUsage ? ((totalPowerUsage / 1000) * 0.3).toFixed(2) : undefined,
      usage: totalPowerUsage ? (totalPowerUsage / 1000).toFixed(2) : undefined,
      locationName: locationHasAttributes(location) ? location.name : '',
      chargePointName: chargePointHasAttributes(chargePoint) ? chargePoint.name : '',
      locationAddress: locationHasAttributes(location) ? getAddressAsString(location.address) : undefined,
      duration: startDate && endDate ? moment(endDate).diff(moment(startDate)) : undefined,
      electricityCost: {
        amount: totalCost?.amount ?? '0',
        currency: electricityCost?.currency ?? 'NZD',
      },
      token: {
        id: token?.tokenId,
        name: token?.tokenName,
      },
      stopReason: transaction?.reason,
      totalCarbonUsage,
      cost: {
        amount: cost?.total ?? '0',
        currency: chargingConfiguration?.currency ?? 'NZD',
      },
    }),
  );

const dateIncludesSearch = (date: string, searchValue: string): boolean =>
  includesSearch(dateFormat(date), searchValue);

const SessionsView: React.FC = () => {
  const { chargePoint } = useChargePoint();
  const { isDeveloperMode } = useDeveloperMode();

  const chargePointId: string = chargePoint?.id ?? '';

  const { data, error, refetch, loading } = useListChargePointSessions({ id: chargePointId });
  const normalisedSessions: RemappedSession[] = data ? toRowData(normalise(data)) : [];

  if (error) {
    return <DataErrorHandler error={error} refetch={refetch} description="Unable to load charge point sessions" />;
  }

  return (
    <Workspace maxWidth="lg">
      <Section title="Charge point sessions" description="View records of sessions at this charge point.">
        <Card>
          <DataTable<RemappedSession>
            isLoading={loading}
            options={{ columnsButton: true }}
            toolbarProps={{
              ...getToolbarSearch('sessions'),
              actions: (props: ColumnChangerProps) => (
                <>
                  <ColumnChanger
                    columnsButton={props.columnsButton}
                    columns={props.columns}
                    icons={props.icons}
                    onColumnsChanged={props.onColumnsChanged}
                  />
                  <RefreshButton refetch={refetch} />
                </>
              ),
            }}
            columns={[
              sessionIdColumn(),
              {
                field: 'startDate',
                title: 'Start time',
                defaultSort: 'desc',
                customSort: sortByDate('startDate'),
                render: ({ startDate }: RemappedSession) => renderDate(startDate),
                customFilterAndSearch: (searchValue: string, { startDate }) =>
                  dateIncludesSearch(startDate, searchValue),
              },
              {
                field: 'endDate',
                title: 'End time',
                customSort: sortByDate('endDate'),
                render: ({ endDate }: RemappedSession) => renderDate(endDate),
                customFilterAndSearch: (searchValue: string, { endDate }) =>
                  !!endDate && dateIncludesSearch(endDate, searchValue),
              },
              evseIdColumn(),
              connectorIdColumn(),
              tokenUidColumn(),
              { ...tokenNameColumn(), hidden: true },
              {
                title: 'Duration',
                customSort: sortByDuration('startDate', 'endDate'),
                render: ({ startDate, endDate }: RemappedSession) =>
                  startDate && endDate && <>{durationFormatFromStartEnd(startDate, endDate)}</>,
                customFilterAndSearch: (searchValue: string, { startDate, endDate }) =>
                  !!startDate &&
                  !!endDate &&
                  includesSearch(durationFormatFromStartEnd(startDate, endDate), searchValue),
              },
              stopReasonColumn(),
              {
                field: 'totalCarbonUsage',
                title: 'Carbon use',
                customSort: sortByValue('totalCarbonUsage'),
                render: renderTotalCarbonUsage,
                customFilterAndSearch: searchTotalCarbonUsage,
              },
              { ...carbonOffsetColumn(), hidden: true },
              {
                title: 'Energy delivered',
                customSort: sortByValue('usage'),
                render: ({ usage }) => usage !== undefined && <>{powerUsageWithUnit(usage)}</>,
                customFilterAndSearch: (searchValue: string, { usage }) =>
                  usage !== undefined && includesSearch(powerUsageWithUnit(usage), searchValue),
              },
              totalCostColumn(),
              ...(isDeveloperMode ? [totalChargingCostColumn()] : []),
            ]}
            data={normalisedSessions}
          />
        </Card>
      </Section>
    </Workspace>
  );
};

export default SessionsView;
