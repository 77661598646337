import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo';
import { GoogleMap, Marker } from '@react-google-maps/api';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { uid } from 'react-uid';
import styled from 'styled-components';
import { ChargePointSummary, Connector, LocationSummary, NetworkStatus } from '../../../../../../../app/ApiGen';
import locationImg from '../../../../../../../assets/map/location.png';
import { getStatusIndicator } from '../../../../../../../components/Connectors';
import { Statuses } from '../../../../../../../components/Connectors/styled';
import { LocationValue } from '../../../../../../../components/CustomFields/Location';
import { DetailListItem } from '../../../../../../../components/DetailListItem';
import { GoogleMapLoader, googleMapStyles } from '../../../../../../../components/GoogleMap';
import { Card, Indicator, IndicatorProps } from '../../../../../../../design-system';
import { fullStateSelector } from '../../../../../../../store/root';
import { SubState } from '../../../../../../../store/types';
import { getChargePointConnectivityStatus } from '../../../../../../../utils/chargePoints/getChargePointConnectivityStatus';
import { Normalised } from '../../../../../../../utils/request';

const MapWrapper = styled('div')`
  height: 100%;
`;

interface ConnectorsProps {
  items: Connector[];
  networkStatus: NetworkStatus;
  variant?: IndicatorProps['variant'];
}

const Connectors: React.FC<ConnectorsProps> = ({ items, variant, networkStatus }) => (
  <Statuses data-testid="connectors">
    {items.map((connector, idx) => {
      const { color, label } = getStatusIndicator(networkStatus, connector);
      return (
        <Indicator
          key={uid(connector, idx)}
          variant={variant}
          color={color}
          icon={<SettingsInputSvideoIcon />}
          label={`#${connector.connectorId} ${label}`}
          toolTipText={`#${connector.connectorId} ${label}`}
        />
      );
    })}
  </Statuses>
);

export const Header: React.FC<{
  chargePoint: Normalised<ChargePointSummary>;
  organisation: SubState<'organisation'>;
  location: Normalised<LocationSummary> | undefined;
}> = ({ chargePoint, organisation, location }) => {
  const { name, serial, connectors, ocppChargePointId, networkStatusUpdatedDate, networkStatus } = chargePoint;
  const { isEvnexAdmin } = useSelector(fullStateSelector);

  const hasMarker = !!location;
  const lat = Number(location?.coordinates.latitude ?? '0');
  const lng = Number(location?.coordinates.longitude ?? '0');
  const orderedConnectors = connectors.sort((a, b) => {
    if (a.connectorId > b.connectorId) {
      return 1;
    }
    if (a.connectorId < b.connectorId) {
      return -1;
    }
    return 0;
  });

  return (
    <Card>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box p={2} style={{ paddingBottom: '8px' }}>
            <Grid>
              <Typography variant="h6">{name}</Typography>
            </Grid>

            <Divider style={{ margin: '8px 0px' }} />

            <DetailListItem attributeName="Connector statuses">
              <Connectors variant="dot" items={orderedConnectors} networkStatus={networkStatus} />
            </DetailListItem>
            <DetailListItem attributeName="Connectivity status">
              {getChargePointConnectivityStatus(networkStatus, networkStatusUpdatedDate)}
            </DetailListItem>
            <DetailListItem attributeName="OCPP charge point ID">{ocppChargePointId ?? 'N/A'}</DetailListItem>
            <DetailListItem attributeName="Serial">
              {serial && serial.length > 25 ? (
                <>
                  {serial}
                  <Typography color="error" variant="body2">
                    Warning: Serial number is too long to be compliant with OCPP 1.6 specification, which only accepts
                    serial numbers of 25 characters or fewer. Check with the manufacturer for advice.
                  </Typography>
                </>
              ) : (
                serial ?? 'N/A'
              )}
            </DetailListItem>
            <DetailListItem attributeName="Vendor">{chargePoint.vendor ?? 'N/A'}</DetailListItem>
            <DetailListItem attributeName="Model">{chargePoint.model ?? 'N/A'}</DetailListItem>
            <DetailListItem attributeName="Firmware">{chargePoint.firmware ?? 'N/A'}</DetailListItem>
            <DetailListItem attributeName="ICCID">{chargePoint.iccid ?? 'N/A'}</DetailListItem>
            {isEvnexAdmin && <DetailListItem attributeName="Time Zone">{chargePoint.timeZone ?? 'N/A'}</DetailListItem>}
            <DetailListItem attributeName="Location">
              <LocationValue value={location} organisationSlug={organisation.slug} />
            </DetailListItem>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <MapWrapper>
            <GoogleMapLoader>
              <GoogleMap
                mapContainerStyle={{ height: '100%', width: '100%' }}
                center={{ lat, lng }}
                zoom={hasMarker ? 13 : 2}
                options={{
                  styles: googleMapStyles,
                  streetViewControl: false,
                }}
              >
                {hasMarker && <Marker position={{ lat, lng }} icon={locationImg} />}
              </GoogleMap>
            </GoogleMapLoader>
          </MapWrapper>
        </Grid>
      </Grid>
    </Card>
  );
};
