import styled from 'styled-components';
import { rgba } from 'polished';
import { device } from '../../../utils/screenUtils';

export const MapWrapper = styled('div')`
  width: 100%;
  position: relative;

  .evnex-map-cluster {
    box-sizing: content-box;
    background-color: ${(p) => p.theme.palette.primary.main};
    border: 2px solid ${(p) => rgba(p.theme.palette.evnex.main, 0.13)};
    border-radius: 50%;

    &:empty {
      border: none;
    }

    > * {
      color: ${(p) => p.theme.palette.common.white} !important;
      font-family: inherit !important;
      font-size: 12px !important;
      font-weight: 500 !important;
    }
  }

  height: ${(p) => p.theme.spacing(30)}px;

  @media ${device.laptop} {
    height: ${(p) => p.theme.spacing(35)}px;
  }

  @media ${device.desktop} {
    height: ${(p) => p.theme.spacing(50)}px;
  }
`;
