import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Role, useUpdateOrganisationMembership } from '../../../../../app/ApiGen';
import { useAuthenticatedUser } from '../../../../../app/AuthenticatedUserProvider';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import { UserRole } from '../../../../../app/enums';
import {
  AutocompleteContents,
  AutocompleteInfo,
  AutocompleteInput,
  AutocompleteValue,
} from '../../../../../components/CustomFields/SimpleAutocomplete';
import { SwitchInput, SwitchValue } from '../../../../../components/CustomFields/Switch';
import { ErrorHandler } from '../../../../../components/ErrorHandler';
import { errorSnackbar } from '../../../../../components/ErrorSnackbar';
import {
  Card,
  Form,
  InputComponentProps,
  OnSubmit,
  Section,
  ValueComponentProps,
  Workspace,
} from '../../../../../design-system';
import { getAuthenticatedUserAction } from '../../../../../store/authenticatedUser/actions';
import { clearCurrentOrganisationAction } from '../../../../../store/organisation/actions';
import type { OrganisationMembership } from '../../../../../store/organisationUser/types';
import { createSchema } from '../../../../../utils/formUtils';
import { userRoleLabels } from '../../../../../utils/users/FieldDefinitions';
import { useOrganisationUser } from './Provider';

const roleOptions: AutocompleteInfo<Role>[] = Object.keys(userRoleLabels).map((role) => {
  const numericalRole = parseInt(role, 10) as Role;
  return {
    label: userRoleLabels[numericalRole],
    value: numericalRole,
  };
});

type UserDetails = Pick<OrganisationMembership, 'role' | 'isDefault'>;

const DetailsForm: React.FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = React.useState(false);
  const { loading: organisationLoading, organisation } = useCurrentOrganisation();
  const { error: fetchError, loading, organisationUser, refetch } = useOrganisationUser();
  const { authenticatedUser } = useAuthenticatedUser();
  const dispatch = useDispatch();

  const { mutate } = useUpdateOrganisationMembership({
    orgSlug: organisation?.slug ?? '',
    userId: organisationUser?.id ?? '',
  });

  const onSubmit = React.useCallback<OnSubmit<UserDetails>>(
    async ({ role, isDefault }, { setSubmitting }) => {
      try {
        await mutate({
          data: {
            id: organisationUser?.membership.id ?? '',
            type: 'memberships',
            attributes: { role, isDefault },
          },
        });

        enqueueSnackbar('User updated', { variant: 'success' });

        if (authenticatedUser?.id === organisationUser?.id) {
          dispatch(getAuthenticatedUserAction());
          dispatch(clearCurrentOrganisationAction());
        } else {
          setSubmitting(false);
          setIsEditing(false);
        }
        refetch();
      } catch (err) {
        setSubmitting(false);
        errorSnackbar('Unable to edit user', closeSnackbar, enqueueSnackbar, err);
      }
    },
    [closeSnackbar, enqueueSnackbar, mutate, organisationUser, refetch, dispatch, authenticatedUser?.id],
  );

  if (fetchError || (!loading && !organisationUser)) {
    return (
      <ErrorHandler
        description="Unable to load membership details"
        error={fetchError}
        action={
          <Button variant="outlined" color="inherit" size="small" onClick={refetch}>
            Retry
          </Button>
        }
      />
    );
  }

  return (
    <Card title="Details">
      <Form<UserDetails>
        isLoading={loading || organisationLoading}
        fields={[
          {
            id: 'role',
            label: 'Role',
            inputComponent: ({ disabled, error, name, onBlur, onChange, value: role }: InputComponentProps<Role>) => (
              <AutocompleteInput<Role>
                disabled={disabled}
                error={error}
                filterOptions={{ stringify: ({ value }: AutocompleteInfo<Role>) => userRoleLabels[value] }}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                options={roleOptions}
                value={role}
              />
            ),
            valueComponent: ({ value }: AutocompleteContents<Role>) => (
              <AutocompleteValue label={userRoleLabels[value]} value={value} />
            ),
          },
          {
            id: 'isDefault',
            label: 'Is Default',
            inputComponent: SwitchInput,
            valueComponent: ({ value }: ValueComponentProps<boolean>) => <SwitchValue value={value} />,
          },
        ]}
        initialValues={{
          role: organisationUser?.membership.role ?? UserRole.Driver,
          isDefault: organisationUser?.membership.isDefault ?? false,
        }}
        validationSchema={createSchema({})}
        onSubmit={onSubmit}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </Card>
  );
};

const SettingsView: React.FC = () => (
  <Workspace>
    <Section title="User settings" description="Details and settings for this user.">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DetailsForm />
        </Grid>
      </Grid>
    </Section>
  </Workspace>
);
export default SettingsView;
