import React from 'react';
import { useSelector } from 'react-redux';
import { Card, List } from '../../../../../../../design-system';
import { fullStateSelector } from '../../../../../../../store/root';
import { ChargeNowDialogProps, ConnectorCommand, ConnectorCommandProps } from '../types/chargeNow';
import { DialogContents } from '../types/commands';
import { CancelChargeNowDialog } from './commandDialogs/CancelChargeNowDialog';
import { ChargeNowDialog } from './commandDialogs/ChargeNowDialog';
import { CommandItem } from './CommandItem';

const connectorCommands: DialogContents<ConnectorCommand, ChargeNowDialogProps>[] = [
  {
    id: ConnectorCommand.ChargeNow,
    label: 'Charge now',
    description: 'Override load management of the charging session',
    DialogComponent: ChargeNowDialog,
  },
  {
    id: ConnectorCommand.CancelChargeNow,
    label: 'Cancel charge now',
    description: 'Cancel overriding load management of the charging session',
    DialogComponent: CancelChargeNowDialog,
  },
];

export const ConnectorCommands: React.FC<ConnectorCommandProps> = ({ chargePointId, connectorId }) => {
  const [opened, setOpened] = React.useState<ConnectorCommand | undefined>(undefined);
  const {
    chargePoint: { loading },
  } = useSelector(fullStateSelector);

  return (
    <Card title={`Connector ${connectorId} commands`}>
      <List>
        {connectorCommands.map(({ id, label, description, DialogComponent }) => (
          <CommandItem
            key={id}
            label={label}
            description={description}
            isDisabled={loading}
            onClick={() => setOpened(id)}
            dialog={
              <DialogComponent
                isOpen={opened === id}
                onClose={() => setOpened(undefined)}
                chargePointId={chargePointId}
                connectorId={connectorId}
              />
            }
          />
        ))}
      </List>
    </Card>
  );
};
