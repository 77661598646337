import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Card } from '../../../../design-system';

type EmailCardProps = {
  email: string;
};

const EmailCard: React.FC<EmailCardProps> = (props: EmailCardProps) => {
  const { email } = props;
  return (
    <Card title="Email">
      <Box p={2} pt={0}>
        <Typography paragraph>
          Your current email address is <strong>{email}</strong>
        </Typography>
        <Tooltip title="Please contact us to change your email address">
          <span>
            <Button variant="outlined" size="small" disabled>
              Change email
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Card>
  );
};

export default EmailCard;
