import { action, Action, PayloadAction } from 'typesafe-actions';
import type { ChargePoint } from '../../utils/api';
import { APIErrorAndError } from '../../utils/error';
import { ChargePointAction } from './types';

export function getCurrentChargePointAction(
  selectedItemId: string,
): PayloadAction<ChargePointAction.GET_CURRENT_CHARGE_POINT, { selectedItemId: string }> {
  return action(ChargePointAction.GET_CURRENT_CHARGE_POINT, { selectedItemId });
}

export function setCurrentChargePointAction(
  chargePoint: ChargePoint,
): PayloadAction<ChargePointAction.SET_CURRENT_CHARGE_POINT, { chargePoint: ChargePoint }> {
  return action(ChargePointAction.SET_CURRENT_CHARGE_POINT, { chargePoint });
}

export function setChargePointErrorAction(
  error: APIErrorAndError,
  selectedItemId: string,
): PayloadAction<ChargePointAction.SET_CHARGE_POINT_ERROR, { error: APIErrorAndError; selectedItemId: string }> {
  return action(ChargePointAction.SET_CHARGE_POINT_ERROR, { error, selectedItemId });
}

export function clearCurrentChargePointAction(): Action<ChargePointAction.CLEAR_CURRENT_CHARGE_POINT> {
  return action(ChargePointAction.CLEAR_CURRENT_CHARGE_POINT);
}
