import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Account, useGetAccountState, useSetUserPassword } from '../../../../app/ApiGen';
import { UserAvatar } from '../../../../components/Avatar/User';
import { RadioGroupValue } from '../../../../components/CustomFields/RadioGroup';
import { SwitchValue } from '../../../../components/CustomFields/Switch';
import { DangerButton } from '../../../../components/DangerButton';
import { Dialog } from '../../../../components/Dialog';
import { DataErrorHandler } from '../../../../components/ErrorHandler';
import { errorSnackbar } from '../../../../components/ErrorSnackbar';
import LoadingPlaceholder from '../../../../components/LoadingPlaceholder';
import {
  Card,
  ContainerHeaderLoading,
  FormField,
  FormReadonly,
  ItemLoading,
  Section,
  ValueComponentProps,
  Workspace,
} from '../../../../design-system';
import { DateFormat, TimeFormat } from '../../../../utils/dateFormat';
import { useUser } from './Provider';

const accountStatusLabels: Record<Account['status'], string> = {
  ARCHIVED: 'Archived',
  CONFIRMED: 'OK',
  FORCE_CHANGE_PASSWORD: 'Invitation sent',
  RESET_REQUIRED: 'Reset required',
  UNCONFIRMED: 'Unconfirmed',
  UNKNOWN: 'unknown',
};

const accountStatusDescriptions: Record<Account['status'], string | null> = {
  ARCHIVED: null,
  CONFIRMED: null,
  FORCE_CHANGE_PASSWORD:
    'This account is in a state where the Forgot Password form cannot be used, because the user must first use the ' +
    'link in the invitation email that was most recently sent to them. If the invitation is expired, lost, or ' +
    'broken, setting the password here will transition the account to a "Confirmed" state, after which the user can ' +
    'use the Forgot Password form normally.',
  RESET_REQUIRED: 'User must request a code and reset their password before they can sign in.',
  UNCONFIRMED: 'This account has been created in the user pool, but not confirmed yet.',
  UNKNOWN: null,
};

const accountFields: FormField<Account>[] = [
  {
    id: 'status',
    label: 'Account Status',
    inputProps: {},
    valueComponent: ({ value }: ValueComponentProps<Account['status']>) => (
      <RadioGroupValue value={value} labels={accountStatusLabels} />
    ),
  },
  {
    id: 'enabled',
    label: 'Enabled',
    valueComponent: ({ value }: ValueComponentProps<boolean>) => (
      <SwitchValue value={value} labels={{ true: 'Enabled', false: 'Disabled' }} />
    ),
  },
  { id: 'createdDate', label: 'Date Created' },
  { id: 'updatedDate', label: 'Date Modified' },
];

const SetPassword: React.FC<{ refetch: () => Promise<void>; userId: string }> = ({ refetch, userId }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { loading, mutate } = useSetUserPassword({ id: userId });
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const onSubmit = React.useCallback(() => {
    setDialogOpen(false);
    mutate()
      .then(async () => {
        enqueueSnackbar('Account password has been changed. User may now use "Forgot Password" form');
        return refetch();
      })
      .catch((e) => errorSnackbar('Failed to set account password', closeSnackbar, enqueueSnackbar, e));
  }, [closeSnackbar, enqueueSnackbar, mutate, refetch]);

  return (
    <Box>
      <Typography variant="body1" paragraph>
        If the account is in an invalid state, use the button below to manually set a random password on the account:
      </Typography>
      <DangerButton loading={loading} onClick={() => setDialogOpen(true)} label="Set account password" />
      <Dialog
        hasCancelButton
        isOpen={dialogOpen}
        maxWidth="xs"
        onClose={() => setDialogOpen(false)}
        submitButton={
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Set password
          </Button>
        }
        title="Set account password"
      >
        <Typography variant="body2">
          Are you sure? The user&apos;s password will be set to a <strong>random</strong> secure value. If you proceed,
          the user will need to request a new password via the Forgot Password form in order to be able to log in.
        </Typography>
      </Dialog>
    </Box>
  );
};

const AccountDetails: React.FC<{ userId: string }> = ({ userId }) => {
  const { error, loading, data, refetch } = useGetAccountState({ id: userId });

  if (loading) {
    return (
      <div data-testid="item-loading">
        <LoadingPlaceholder sections={[{ items: accountFields }]} />
      </div>
    );
  }
  if (error || !data) {
    return (
      <DataErrorHandler error={error} description="Unable to fetch account status" refetch={refetch} type="embedded" />
    );
  }

  return (
    <Card>
      <FormReadonly<Account> fields={accountFields} initialValues={data} />
      <Box padding={2}>
        <Typography variant="body1" paragraph>
          {accountStatusDescriptions[data.status]}
        </Typography>
        <SetPassword refetch={refetch} userId={userId} />
      </Box>
    </Card>
  );
};

export const UserOverview: React.FC = () => {
  const { error, loading, user, refetch } = useUser();
  const [showAccountDetails, setShowAccountDetails] = React.useState(false);

  if (loading) {
    return (
      <Workspace>
        <div data-testid="item-loading">
          <ContainerHeaderLoading avatar subText />
          <ItemLoading />
        </div>
      </Workspace>
    );
  }

  if (error || !user) {
    return <DataErrorHandler description="Unable to load user" error={error} refetch={refetch} type="view" />;
  }

  if (showAccountDetails && (loading || error || !user)) {
    setShowAccountDetails(false);
  }

  const { id, name, email, created, updated } = user;

  return (
    <Workspace>
      <Card>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Box>
                <Typography variant="h6" style={{ wordBreak: 'break-word' }}>
                  {name ?? email}
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ wordBreak: 'break-word' }} paragraph>
                  {email}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  User created{' '}
                  <span title={moment(created).format(`${DateFormat.Long} ${TimeFormat.NoSeconds}`)}>
                    {moment(created).fromNow()}
                  </span>
                </Typography>{' '}
                <Typography variant="body2" color="textSecondary">
                  User updated{' '}
                  <span title={moment(updated).format(`${DateFormat.Long} ${TimeFormat.NoSeconds}`)}>
                    {moment(updated).fromNow()}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <UserAvatar user={user} />
            </Grid>
          </Grid>
        </Box>
      </Card>
      <Section
        mt={2}
        pb={2}
        title="Account status"
        description="Every user has an identity (i.e. an account) in our user pool, which is managed by AWS Cognito
          Identity Provider. Knowing the state of an account in the user pool can help in troubleshooting login issues."
      >
        <Box>
          {showAccountDetails ? (
            <AccountDetails userId={id} />
          ) : (
            <Button type="button" variant="outlined" onClick={() => setShowAccountDetails(true)}>
              Fetch account state
            </Button>
          )}
        </Box>
      </Section>
    </Workspace>
  );
};
