import styled from 'styled-components';

export const SearchWrapper = styled('div')`
  width: 100%;

  & > * {
    width: 100%;
  }

  // Disable the webkit clear button on the search field
  input[type='search' i]::-webkit-search-cancel-button {
    display: none;
  }
`;
