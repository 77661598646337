import React from 'react';
import { uid } from 'react-uid';
import {
  ContainerHeaderLoading,
  HeaderSection,
  ItemLoading,
  MenuSection,
  Separator,
} from '../../../../../../design-system';

export const MenuLoading: React.FC = () => (
  <>
    <HeaderSection>{() => <ContainerHeaderLoading avatar subText />}</HeaderSection>
    <Separator mb={1} />
    <MenuSection canOverflow>
      {() =>
        Array(6)
          .fill(undefined)
          .map((_, idx) => <ItemLoading key={uid(idx)} />)
      }
    </MenuSection>
  </>
);
