import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { ListItem } from '../../../../../../../design-system';
import { CommandItemProps } from '../types/commands';

export const CommandItem: React.FC<CommandItemProps> = ({
  label,
  description,
  isDisabled,
  onClick,
  dialog,
}: CommandItemProps) => (
  <ListItem>
    <Grid container spacing={3}>
      <Grid item sm={12} md={8} lg={10}>
        <Box my={1}>
          <Typography>{label}</Typography>
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={12} md={4} lg={2}>
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Button
            onClick={onClick}
            disabled={isDisabled}
            variant="outlined"
            fullWidth
            aria-label={`Send ${label} command`}
          >
            Send...
          </Button>
        </Box>
      </Grid>
    </Grid>
    {dialog}
  </ListItem>
);
