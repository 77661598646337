import Box, { BoxProps } from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { GraphicProps } from '../../design-system';

export interface EmptyProps {
  graphic: React.ComponentType<GraphicProps>;
  boxProps?: BoxProps;
  title: string;
  description: string;
  action?: React.ReactNode;
}

export const Empty: React.FC<EmptyProps> = ({ graphic: Graphic, title, description, action, boxProps }) => (
  <Box {...boxProps}>
    <Graphic mb={4} textAlign="center" svgProps={{ style: { maxWidth: '60%', height: '100%' } }} />
    <Box mb={1}>
      <Typography variant="body1">{title}</Typography>
    </Box>
    {description && (
      <Typography variant="body2" color="textSecondary">
        {description}
      </Typography>
    )}
    {action && <Box mt={2}>{action}</Box>}
  </Box>
);
