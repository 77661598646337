import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import * as React from 'react';
import { ExpandButtonProps } from './types';

export const ExpandButton: React.FC<ExpandButtonProps> = ({ isExpanded, setIsExpanded }) => (
  <IconButton title="Expand" onClick={() => setIsExpanded((prev) => !prev)}>
    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
  </IconButton>
);
