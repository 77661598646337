import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Column, EditCellColumnDef } from 'material-table';
import * as React from 'react';
import styled from 'styled-components';

export const SubheaderBackground = styled.div`
  background-color: ${(p) => p.theme.palette.background.paper};
`;

export const CheckboxWrapper = styled.div`
  > * {
    margin-left: 0;
    padding: ${(p) => p.theme.spacing(0.25)}px;
  }
`;

export interface ColumnChangerProps {
  /** DO NOT SET - Provided by the table props. */
  columnsButton: boolean;
  /** DO NOT SET - Provided by the table props. */
  columns: (Column<Record<string, unknown>> & EditCellColumnDef)[];
  /** DO NOT SET - Provided by the table props. */
  icons: { ViewColumn: () => React.ReactElement };
  /** DO NOT SET - Provided by the table props. */
  onColumnsChanged: (col: unknown, isHidden: boolean) => void;
}

export const ColumnChanger: React.FC<ColumnChangerProps> = ({
  columnsButton,
  columns,
  icons: { ViewColumn: ViewColumnIcon },
  onColumnsChanged,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | undefined>(undefined);

  if (!columnsButton) {
    return null;
  }

  return (
    <>
      <Tooltip title="Add or remove columns">
        <IconButton
          color="inherit"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          data-testid="change-columns-button"
        >
          <ViewColumnIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(undefined)}
        MenuListProps={{
          dense: true,
          subheader: (
            <ListSubheader>
              <SubheaderBackground>Add or remove columns</SubheaderBackground>
            </ListSubheader>
          ),
        }}
      >
        {columns.map((col) =>
          col.removable === false ? null : (
            <MenuItem key={col.tableData.id} onClick={() => onColumnsChanged(col, !col.hidden)}>
              <ListItemIcon style={{ minWidth: '40px' }}>
                <CheckboxWrapper>
                  <Checkbox edge="start" checked={!col.hidden} tabIndex={-1} disableRipple size="small" />
                </CheckboxWrapper>
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                {col.title}
              </Typography>
            </MenuItem>
          ),
        )}
      </Menu>
    </>
  );
};
