import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { InputComponentProps, ValueComponentProps } from '../../design-system';

type RadioGroupValueProps<T extends string> = ValueComponentProps<T | undefined> & {
  /**
   * Human-friendly label for each radio button option
   */
  labels?: Record<T, string>;
};

/**
 * Read-only value display component for a RadioGroup.
 */
export function RadioGroupValue<T extends string>({ value, labels }: RadioGroupValueProps<T>): React.ReactElement {
  let displayText: string;
  if (value && labels) {
    displayText = labels[value];
  } else if (typeof value === 'undefined') {
    displayText = '';
  } else {
    displayText = value;
  }
  return (
    <Typography color="textPrimary" variant="body2">
      {displayText}
    </Typography>
  );
}

type RadioGroupInputProps<T extends string> = InputComponentProps<string> & {
  row?: boolean;
  options: { value: T; label: string }[];
};

/**
 * Input component for a RadioGroup. Note that the value of a radio input is always a **string** due to how the DOM API
 * handles radio buttons.
 */
export function RadioGroupInput<T extends string>({
  disabled,
  helperText,
  name,
  onBlur,
  onChange,
  options,
  row,
  value,
}: RadioGroupInputProps<T>): React.ReactElement {
  return (
    <FormControl disabled={disabled}>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <RadioGroup name={name} onBlur={onBlur} onChange={onChange} row={row} value={value}>
        {options.map((option) => (
          <FormControlLabel
            control={<Radio value={option.value} checked={value === option.value} />}
            key={option.value}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
