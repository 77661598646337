import { rgba } from 'polished';
import styled from 'styled-components';
import { IndicatorColor } from './types';

export const IndicatorBadge = styled('div')<{ color: IndicatorColor }>`
  padding: ${(p) => p.theme.spacing(0, 1)};
  display: inline-flex;
  align-items: center;
  min-height: 20px;
  background-color: ${(p) => p.theme.palette[p.color].main};
  border-radius: ${(p) => p.theme.shape.borderRadius * 2}px;
  border: 1px solid ${(p) => rgba(p.theme.palette.evnex.main, 0.13)};
  color: ${(p) => p.theme.palette[p.color].contrastText};
  font-size: 12px;
  line-height: 20px;
`;

export const IndicatorDot = styled('div')<{ color: IndicatorColor }>`
  display: inline-flex;
  min-height: 16px;
  min-width: 16px;
  background-color: ${({ color, theme: { palette } }) => palette[color].main};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => rgba(theme.palette.evnex.main, 0.13)};
  color: ${({ color, theme }) => theme.palette[color].contrastText};
`;

export const IconWrapper = styled('span')`
  margin-right: ${(p) => p.theme.spacing(0.5)}px;
  display: inline-flex;

  svg {
    font-size: 14px;
  }
`;
