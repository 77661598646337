import * as React from 'react';
import moment from 'moment-timezone';
import {
  carbonOffsetFormat,
  chargingCostCostFormat,
  dateFormat,
  electricityCostFormat,
  stopReasonMapper,
} from './format';
import { RemappedSession } from '..';

export const renderDate = (date?: string): JSX.Element =>
  date ? <span title={moment(date).toString()}>{dateFormat(date)}</span> : <></>;

export function renderStopReason({ stopReason }: RemappedSession): string {
  return stopReasonMapper(stopReason);
}

export function renderCarbonOffset({ carbonOffset }: RemappedSession): JSX.Element {
  return carbonOffset !== undefined ? (
    <>
      {carbonOffsetFormat(carbonOffset)} kg CO<sub>2</sub>e
    </>
  ) : (
    <></>
  );
}

export function renderElectricityCost({ electricityCost }: RemappedSession): JSX.Element {
  return <>{electricityCostFormat(electricityCost)}</>;
}

export function renderChargingCost({ cost }: RemappedSession): JSX.Element {
  return <>{chargingCostCostFormat(cost)}</>;
}

export function renderTotalCarbonUsage({ totalCarbonUsage }: RemappedSession): JSX.Element {
  return totalCarbonUsage !== undefined ? (
    <>
      {totalCarbonUsage.toLocaleString(undefined, { maximumFractionDigits: 0 })} g CO<sub>2</sub>
    </>
  ) : (
    <></>
  );
}
