import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Workspace } from '../../../../design-system';

const NotFoundView: React.FC = () => {
  const history = useHistory();

  const content = {
    'header-p1': '404 - Page not found',
    description: `The page you're looking for doesn't seem to be here.`,
    'primary-action': 'Go back',
  };

  return (
    <>
      <Workspace maxWidth="xl">
        <Card>
          <Container maxWidth="xl">
            <Box pt={10} pb={12}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Typography variant="h3" component="h2" gutterBottom>
                    <Typography color="primary" variant="h3" component="span">
                      {content['header-p1']}
                    </Typography>
                  </Typography>
                  <Typography variant="subtitle1" component="p" color="textPrimary">
                    {content.description}
                  </Typography>
                  <Box mt={3}>
                    <Button variant="contained" color="primary" onClick={() => history.goBack()}>
                      {content['primary-action']}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Card>
      </Workspace>
    </>
  );
};

export default NotFoundView;
