import Card from '@material-ui/core/Card';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Location, useListUserLocations } from '../../../../app/ApiGen';
import { singleLineAddress } from '../../../../components/Address';
import { getToolbarSearch } from '../../../../components/DataTable/Toolbar';
import { DataErrorHandler } from '../../../../components/ErrorHandler';
import { TableLink } from '../../../../components/Link';
import { ColumnChanger, ColumnChangerProps, DataTable, SearchFieldProps, Workspace } from '../../../../design-system';
import { navigateOnRowClick } from '../../../../utils/dataTable/rowHelpers';
import { organisationHasAttributes } from '../../../../utils/format';
import { normalise, Normalised } from '../../../../utils/request';
import { addressIncludesSearch } from '../../../../utils/search';
import { sortByAddress } from '../../../../utils/sortBy';

const getToolbarProps: () => {
  search: (props: React.PropsWithChildren<SearchFieldProps>) => JSX.Element;
  actions: (props: React.PropsWithChildren<ColumnChangerProps>) => JSX.Element;
} = () => ({
  ...getToolbarSearch('locations'),
  actions: (props: ColumnChangerProps) => (
    <ColumnChanger
      columnsButton={props.columnsButton}
      columns={props.columns}
      icons={props.icons}
      onColumnsChanged={props.onColumnsChanged}
    />
  ),
});

const UserLocationsView: React.FC<{ userId: string }> = ({ userId }) => {
  const history = useHistory();

  const { data, error, loading, refetch } = useListUserLocations({ id: userId });
  const tableData: Normalised<Location>[] = data ? normalise(data) : [];

  if (error) {
    return <DataErrorHandler error={error} description="Unable to load user locations" refetch={refetch} />;
  }

  const locationLink = (rowData: Normalised<Location>) =>
    organisationHasAttributes(rowData.organisation)
      ? `/organisations/${rowData.organisation.slug}/locations/${rowData.id}`
      : '';

  return (
    <Workspace maxWidth="xl">
      <Card>
        <DataTable<Normalised<Location>>
          options={{ columnsButton: true }}
          onRowClick={navigateOnRowClick(locationLink, history)}
          toolbarProps={getToolbarProps()}
          columns={[
            {
              field: 'name',
              title: 'Name',
              render: (rowData) => {
                const link = locationLink(rowData);
                const locationName = rowData.name;
                return link ? <TableLink to={link}>{locationName}</TableLink> : locationName;
              },
              defaultSort: 'asc',
            },
            {
              field: 'address',
              title: 'Address',
              render: singleLineAddress,
              customSort: sortByAddress('address'),
              customFilterAndSearch: (search: string, { address }) => addressIncludesSearch(address, search),
            },
            {
              title: 'Organisation',
              render: ({ organisation }) =>
                organisationHasAttributes(organisation) ? (
                  <TableLink to={`/organisations/${organisation.slug}`} fontWeight="normal" color="primary">
                    {organisation.name}
                  </TableLink>
                ) : undefined,
            },
          ]}
          isLoading={loading}
          data={tableData}
        />
      </Card>
    </Workspace>
  );
};

export default UserLocationsView;
