import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import { Form as FormikForm, Formik, FormikHelpers, FormikValues } from 'formik';
import * as React from 'react';
import { FormActions } from './components/Actions';
import { FormEditable } from './components/Editable';
import { FormLoading } from './components/Loading';
import { FormReadonly } from './components/Readonly';
import { FormField, InitialValues, ValidationSchema } from './types';

const defaultLabels = {
  edit: 'Edit',
  cancel: 'Cancel',
  save: 'Save',
};

export type OnSubmit<V> = (fields: V, actions: FormikHelpers<V>) => void;

export interface FormProps<V = InitialValues> {
  fields: FormField[];
  initialValues: V;
  validationSchema: ValidationSchema;
  onSubmit: OnSubmit<V>;
  isLoading?: boolean;
  isEditing?: boolean;
  setIsEditing?: (prev: boolean) => void;
  labels?: {
    edit?: string;
    cancel?: string;
    save?: string;
  };
  formProps?: Omit<React.HTMLProps<HTMLFormElement>, 'ref'>;
  inset?: boolean;
}

function Form<Values extends FormikValues>({
  fields,
  initialValues,
  validationSchema,
  onSubmit,
  isLoading,
  isEditing,
  setIsEditing,
  labels,
  formProps,
  inset,
}: FormProps<Values>): React.ReactElement {
  const { edit, cancel, save } = { ...defaultLabels, ...labels };

  const onEdit = React.useCallback(() => {
    if (setIsEditing) {
      setIsEditing(true);
    }
  }, [setIsEditing]);

  const onCancel = React.useCallback(() => {
    if (setIsEditing) {
      setIsEditing(false);
    }
  }, [setIsEditing]);

  if (isLoading) {
    return (
      <>
        <FormLoading fields={fields} inset={inset} />
        {setIsEditing && (
          <FormActions>
            <Skeleton width="80px" height="30px" />
          </FormActions>
        )}
      </>
    );
  }

  if (isEditing) {
    return (
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ errors, touched, isSubmitting }) => (
          <FormikForm {...formProps}>
            <FormEditable fields={fields} errors={errors} touched={touched} disabled={isSubmitting} inset={inset} />
            <FormActions>
              {setIsEditing && (
                <>
                  <Button color="default" variant="contained" type="button" disabled={isSubmitting} onClick={onCancel}>
                    {cancel}
                  </Button>
                  <Box ml={2} />
                </>
              )}
              <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress size={24} color="inherit" /> : save}
              </Button>
            </FormActions>
          </FormikForm>
        )}
      </Formik>
    );
  }

  return (
    <>
      <FormReadonly fields={fields} initialValues={initialValues} inset={inset} />
      {setIsEditing && (
        <FormActions>
          <Button variant="outlined" size="small" onClick={onEdit}>
            {edit}
          </Button>
        </FormActions>
      )}
    </>
  );
}

Form.displayName = 'Form';

export { Form };
