import ShowChartIcon from '@material-ui/icons/ShowChart';
import React from 'react';
import { useSelector } from 'react-redux';
import { uid } from 'react-uid';
import { InsightAvatar } from '../../../../components/Avatar/Insight';
import {
  ContainerHeader,
  ContainerHeaderLoading,
  HeaderSection,
  Item,
  ItemLoading,
  MenuSection,
  NavigationView,
  Separator,
} from '../../../../design-system';
import { fullStateSelector } from '../../../../store/root';

const Items: React.FC = () => {
  const {
    isAdmin,
    isEvnexAdmin,
    isFleetOperator,
    organisation: { current: userOrg },
  } = useSelector(fullStateSelector);

  if (!userOrg) {
    // NB: Can only use test ID to find an ItemLoading element in the document, since it has no ARIA role.
    return (
      <>
        <ContainerHeaderLoading avatar subText />
        <div data-testid="skeleton">
          {Array(2)
            .fill(null)
            .map((_, idx) => (
              <ItemLoading key={uid(idx)} />
            ))}
        </div>
      </>
    );
  }
  const { slug } = userOrg;
  const orgLink = `/organisations/${slug}`;

  return (
    <>
      <HeaderSection>
        {() => (
          <ContainerHeader
            avatar={
              <InsightAvatar>
                <ShowChartIcon />
              </InsightAvatar>
            }
            text="Reports"
          />
        )}
      </HeaderSection>
      <Separator mb={1} />
      <MenuSection canOverflow>
        {() => (
          <>
            {(isEvnexAdmin || isAdmin || isFleetOperator) && (
              <Item text="All Sessions by Date" to={`${orgLink}/reports/sessions`} />
            )}
            {(isEvnexAdmin || isAdmin || isFleetOperator) && (
              <Item text="Monthly Use by Charger" to={`${orgLink}/reports/monthly-use-by-charger`} />
            )}
          </>
        )}
      </MenuSection>
    </>
  );
};

export const reportsNav: NavigationView = {
  id: 'reports/container',
  type: 'container',
  Items,
};
