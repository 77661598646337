import type { Reducer } from 'redux';
import * as Actions from './actions';
import { OrganisationUserAction, OrganisationUserState } from './types';

type Action = ReturnType<typeof Actions[keyof typeof Actions]>;

const initialState: OrganisationUserState = {
  loading: false,
  error: undefined,
  current: undefined,
};

export const organisationUserReducers: Reducer<OrganisationUserState, Action> = (
  state,
  action,
): OrganisationUserState => {
  switch (action.type) {
    case OrganisationUserAction.GET_CURRENT_ORGANISATION_USER:
      return {
        ...(state ?? initialState),
        loading: true,
        error: undefined,
      };
    case OrganisationUserAction.SET_CURRENT_ORGANISATION_USER:
      return {
        ...(state ?? initialState),
        loading: false,
        error: undefined,
        current: action.payload.user,
      };
    case OrganisationUserAction.SET_ORGANISATION_USER_ERROR:
      return {
        ...(state ?? initialState),
        loading: false,
        error: action.payload.error,
      };
    case OrganisationUserAction.CLEAR_CURRENT_ORGANISATION_USER:
      return {
        ...(state ?? initialState),
        loading: false,
        error: undefined,
        current: undefined,
      };
    default:
      return state ?? initialState;
  }
};
