import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as React from 'react';
import { SwitchValue } from '../../../../../../components/CustomFields/Switch';
import { FormReadonly, Section, useWizard } from '../../../../../../design-system';
import { locationField } from '../fields';

export const ConfirmationStepEvnex: React.FC = () => {
  const { state, prevStep, nextStep, isLoading } = useWizard();

  return (
    <>
      <Section title="Charge point details">
        <FormReadonly
          fields={[
            {
              id: 'name',
              label: 'Name',
            },
            locationField,
            {
              id: 'serial',
              label: 'Serial',
            },
            {
              id: 'referenceId',
              label: 'Reference ID',
            },
          ]}
          initialValues={state}
        />
      </Section>
      <Section pt={3} title="Configuration">
        <FormReadonly
          fields={[
            {
              id: 'authorizationRequired',
              label: 'Authorization required',
              valueComponent: SwitchValue,
            },
          ]}
          initialValues={state}
        />
      </Section>
      <Box pt={2} display="flex">
        <Button variant="contained" onClick={prevStep} disabled={isLoading}>
          Back
        </Button>
        <Box mr="auto" />
        <Button color="primary" variant="contained" onClick={nextStep} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Create charge point'}
        </Button>
      </Box>
    </>
  );
};
