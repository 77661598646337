import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useCurrentOrganisation } from '../../../../../../app/CurrentOrganisationProvider';
import { DataErrorHandler, ErrorHandler } from '../../../../../../components/ErrorHandler';
import { Loading, Section, Workspace } from '../../../../../../design-system';
import { useLoadBalancingGroup } from '../Provider';
import { DeleteLoadBalancingGroup } from './components/DeleteLoadBalancingGroup';
import { DetailsForm } from './components/DetailsForm';

const SettingsView: React.FC = () => {
  const { organisation, loading: organisationLoading } = useCurrentOrganisation();
  const {
    loadBalancingGroup,
    loading: groupLoading,
    error: groupError,
    refetch: refetchGroup,
  } = useLoadBalancingGroup();

  if (organisationLoading || groupLoading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }
  if (!organisation) {
    return (
      <Workspace>
        <ErrorHandler description="Unable to load organisation" />
      </Workspace>
    );
  }
  if (!loadBalancingGroup || groupError) {
    return (
      <Workspace>
        <DataErrorHandler
          description="Unable to fetch load balancing group details"
          error={groupError}
          refetch={refetchGroup}
        />
      </Workspace>
    );
  }

  return (
    <Workspace>
      <Section title="Load balancing group settings" description="Details and settings for this load balancing group.">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DetailsForm
              loadBalancingGroup={loadBalancingGroup}
              organisation={organisation}
              refetchLoadBalancingGroup={refetchGroup}
            />
          </Grid>
        </Grid>
      </Section>
      <Box pt={3} />
      <Section title="Warning" description="This process cannot be undone.">
        <DeleteLoadBalancingGroup loadBalancingGroupId={loadBalancingGroup.id} orgSlug={organisation.slug} />
      </Section>
    </Workspace>
  );
};

export default SettingsView;
