import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import React from 'react';
import { useTheme } from 'styled-components';
import { EvnexPalette, InputComponentProps, ValueComponentProps } from '../../design-system';
import { toChangeEvent } from '../../utils/formUtils';

export interface SwitchValueProps extends ValueComponentProps<boolean> {
  labels?: {
    true: string;
    false: string;
  };
}

export const SwitchValue: React.FC<SwitchValueProps> = ({ value, labels = { true: 'On', false: 'Off' } }) => {
  const { palette } = useTheme();
  const { enabled }: EvnexPalette = palette;

  if (value === undefined) {
    return null;
  }

  return value ? (
    <Chip style={{ backgroundColor: enabled.main }} label={labels.true} size="small" />
  ) : (
    <Chip color="default" label={labels.false} size="small" />
  );
};

export const SwitchInput: React.FC<InputComponentProps<boolean>> = ({
  disabled,
  error,
  helperText,
  name,
  onBlur,
  onChange,
  value,
}) => (
  <FormControl>
    <Switch
      id={name}
      color="primary"
      checked={value}
      disabled={disabled}
      onBlur={onBlur}
      onChange={(event, checked) => onChange(name)(toChangeEvent(event, checked))}
    />
    {helperText && (
      <FormHelperText variant="outlined" error={error}>
        {helperText}
      </FormHelperText>
    )}
  </FormControl>
);
