import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import React from 'react';

interface ReloadButtonProps {
  descriptor: string;
  loading: boolean;
  onClick: () => void;
}

export const ReloadContainerNavItem: React.FC<ReloadButtonProps> = ({ descriptor, loading, onClick }) => (
  <Box mt="auto" p={1}>
    <Button
      variant="outlined"
      onClick={onClick}
      fullWidth
      disabled={loading}
      startIcon={loading ? null : <RefreshIcon />}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : `Reload ${descriptor}`}
    </Button>
  </Box>
);
