import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ChangePasswordView from './ChangePassword';
import SettingsView from './Settings';

const AuthenticatedUserView: React.FC = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/settings`}>
        <SettingsView />
      </Route>
      <Route path={`${path}/change-password`}>
        <ChangePasswordView />
      </Route>
      <Route>
        <Redirect to={`${url}/settings`} />
      </Route>
    </Switch>
  );
};

export default AuthenticatedUserView;
