import { all, call, Effect, fork, put, takeLatest } from 'redux-saga/effects';
import { standardiseError } from '../../utils/error';
import {
  getCurrentLoadBalancingGroupAction,
  setCurrentLoadBalancingGroupAction,
  setLoadBalancingGroupErrorAction,
} from './actions';
import { getLoadBalancingGroups, Response } from './api';
import { fromDTO, LoadBalancingGroupAction } from './types';

export function* handleGetCurrentLoadBalancingGroup(
  action: ReturnType<typeof getCurrentLoadBalancingGroupAction>,
): Generator<Effect> {
  // There's no endpoint to get a single load balancing group, so we fetch all of them and filter down by ID
  const groupId = action.payload.selectedItemId;
  try {
    const { data } = (yield call(getLoadBalancingGroups, action.payload.organisationId)) as Response;
    const group = data.find(({ id }) => id === groupId);
    if (typeof group === 'undefined') {
      const error = standardiseError(new Error(`Load Balancing Group ${groupId} not found`));
      yield put(setLoadBalancingGroupErrorAction(error, groupId));
      return;
    }
    const normalisedGroup = fromDTO(group);
    yield put(setCurrentLoadBalancingGroupAction(normalisedGroup));
  } catch (e) {
    yield put(setLoadBalancingGroupErrorAction(standardiseError(e), groupId));
  }
}

function* watchGetCurrentLoadBalancingGroup() {
  yield takeLatest(LoadBalancingGroupAction.GET_CURRENT_LOAD_BALANCING_GROUP, handleGetCurrentLoadBalancingGroup);
}

export function* loadBalancingGroupSagas(): Generator<Effect> {
  yield all([fork(watchGetCurrentLoadBalancingGroup)]);
}
