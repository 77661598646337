import React from 'react';
import { Link } from 'react-router-dom';
import {
  FooterSectionProps,
  HeaderSectionProps,
  Logo,
  NavigationManager,
  ProductId,
  ToggleCollapseButton,
  useNavigation,
} from '../../design-system';
import { userNav } from '../../views/Admin/Users/User/navView';
import { changePasswordNav } from '../../views/AuthenticatedUser/ChangePassword/navView';
import { authenticatedUserNav } from '../../views/AuthenticatedUser/navView';
import { mainNav } from '../../views/navView';
import { cardNav } from '../../views/Organisations/Organisation/Cards/Card/navView';
import { chargePointNav } from '../../views/Organisations/Organisation/ChargePoints/ChargePoint/navView';
import { loadBalancingGroupNav } from '../../views/Organisations/Organisation/LoadBalancing/LoadBalancingGroup/navView';
import { locationNav } from '../../views/Organisations/Organisation/Locations/Location/navView';
import { organisationNav } from '../../views/Organisations/Organisation/navView';
import { reportsNav } from '../../views/Organisations/Organisation/Reports/navView';
import { organisationUserNav } from '../../views/Organisations/Organisation/Users/User/navView';
import { UserDropdown } from '../UserDropdown';

const globalProductHeader: HeaderSectionProps['children'] = ({ isCollapsed }) => (
  <>
    {!isCollapsed && (
      <Link to="/" tabIndex={0} style={{ lineHeight: 0, outline: 'none' }}>
        <Logo variant="dark" width={128} height={40} />
      </Link>
    )}
    <ToggleCollapseButton />
  </>
);

const globalProductFooter: FooterSectionProps['children'] = ({ isCollapsed }) => (
  <UserDropdown isCollapsed={isCollapsed} />
);

export const Navigation: React.FC = ({ children }) => {
  const { addView } = useNavigation();

  React.useEffect(() => {
    // Register all navigation views before they can be used
    addView(authenticatedUserNav);
    addView(cardNav);
    addView(changePasswordNav);
    addView(chargePointNav);
    addView(reportsNav);
    addView(loadBalancingGroupNav);
    addView(locationNav);
    addView(mainNav);
    addView(organisationNav);
    addView(organisationUserNav);
    addView(userNav);
  }, [addView]);

  return (
    <NavigationManager
      globalProductId={ProductId.CLIENT}
      globalProductHeader={globalProductHeader}
      globalProductFooter={globalProductFooter}
    >
      {children}
    </NavigationManager>
  );
};
