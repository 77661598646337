import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as React from 'react';
import { uid } from 'react-uid';
import { ValueComponentProps } from '../../../design-system';
import type { ConnectorCreate } from '../../../utils/api';

const getDescription = ({ connectorType, connectorFormat, powerType, voltage, amperage }: ConnectorCreate) =>
  [connectorType, connectorFormat, powerType, `${voltage}V`, `${amperage}A`].join(', ');

type ConnectorsValueProps = ValueComponentProps<ConnectorCreate[]>;

export const ConnectorsValue: React.FC<ConnectorsValueProps> = ({ value }: ConnectorsValueProps) => {
  if (value.length === 0) {
    return <>No connectors</>;
  }

  return (
    <List disablePadding dense>
      {value.map((connector, idx) => (
        <ListItem key={uid(connector, idx)} disableGutters>
          <ListItemText primary={`Connector ${idx + 1}`} secondary={getDescription(connector)} />
        </ListItem>
      ))}
    </List>
  );
};
