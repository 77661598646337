import * as React from 'react';
import { CardFooter, CardTitle, StyledCard } from './styled';

export interface CardProps {
  /** Title of the card. */
  title?: React.ReactNode;
  /**
   * Child components within the card.
   * Note that these are not wrapped and you will need to provide your own padding around them.
   */
  children?: React.ReactNode;
  /** Footer components within the card. */
  footer?: React.ReactNode;
  /** CSS classes */
  className?: string;
  /** CSS styles */
  style?: React.CSSProperties;
}

const Card: React.FC<CardProps> = ({ title, children, footer, ...rest }) => (
  <StyledCard {...rest}>
    {title && <CardTitle>{title}</CardTitle>}
    {children}
    {footer && <CardFooter>{footer}</CardFooter>}
  </StyledCard>
);

Card.displayName = 'Card';

export { Card };
