import type { Reducer } from 'redux';
import * as Actions from './actions';
import { UserAction, UserState } from './types';

type Action = ReturnType<typeof Actions[keyof typeof Actions]>;

const initialState: UserState = {
  loading: false,
  error: undefined,
  current: undefined,
};

export const userReducers: Reducer<UserState, Action> = (state, action): UserState => {
  switch (action.type) {
    case UserAction.GET_CURRENT_USER:
      return {
        ...(state ?? initialState),
        loading: true,
        error: undefined,
      };
    case UserAction.SET_CURRENT_USER:
      return {
        ...(state ?? initialState),
        loading: false,
        error: undefined,
        current: action.payload.user,
      };
    case UserAction.SET_USER_ERROR:
      return {
        ...(state ?? initialState),
        loading: false,
        error: action.payload.error,
      };
    case UserAction.CLEAR_CURRENT_USER:
      return {
        ...(state ?? initialState),
        loading: false,
        error: undefined,
        current: undefined,
      };
    default:
      return state ?? initialState;
  }
};
