import * as React from 'react';
import { useNavigation } from './Provider';
import { NavigationView } from './types';

/** Set the current navigation view and clear it on unmount. View must have already been registered with the `addView` hook */
export const useNavigationView: (view: NavigationView) => void = (view) => {
  const { setView, clearView } = useNavigation();

  React.useEffect(() => {
    setView(view.id);

    return () => clearView(view.type);
  }, [setView, clearView, view]);
};
