import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/Business';
import React from 'react';
import styled from 'styled-components';

interface WrappedAvatarProps extends AvatarProps {
  bgColor?: string;
}

const WrappedAvatar: React.FC<WrappedAvatarProps> = React.forwardRef(
  // eslint-disable-next-line react/jsx-props-no-spreading -- There are over 250 properties being spread here.
  ({ bgColor, ...rest }: WrappedAvatarProps, ref) => <Avatar variant="rounded" {...rest} ref={ref} />,
);

WrappedAvatar.defaultProps = { bgColor: undefined };

export const OrganisationAvatar = styled(WrappedAvatar)`
  ${(p) => (p.bgColor ? `background-color: #${`${p.bgColor}`.substring(0, 6)};` : '')}
  border: 2px solid ${(p) => p.theme.palette.common.white};
  outline: none;
`;

export const getOrganisationAvatar: (organisation: {
  avatar?: string;
  color?: string;
  name: string;
}) => React.ReactElement = (organisation) => {
  const { avatar, color, name } = organisation;
  return (
    <OrganisationAvatar bgColor={color} src={avatar} variant="rounded">
      {name.length > 1 ? name[0].toUpperCase() : <BusinessIcon />}
    </OrganisationAvatar>
  );
};
