import { Action, action, PayloadAction } from 'typesafe-actions';
import { APIErrorAndError } from '../../utils/error';
import { LoadBalancingGroup, LoadBalancingGroupAction } from './types';

export function getCurrentLoadBalancingGroupAction({
  selectedItemId,
  organisationId,
}: {
  selectedItemId: string;
  organisationId: string;
}): PayloadAction<
  LoadBalancingGroupAction.GET_CURRENT_LOAD_BALANCING_GROUP,
  { selectedItemId: string; organisationId: string }
> {
  return action(LoadBalancingGroupAction.GET_CURRENT_LOAD_BALANCING_GROUP, { selectedItemId, organisationId });
}

export function setCurrentLoadBalancingGroupAction(
  loadBalancingGroup: LoadBalancingGroup,
): PayloadAction<
  LoadBalancingGroupAction.SET_CURRENT_LOAD_BALANCING_GROUP,
  { loadBalancingGroup: LoadBalancingGroup }
> {
  return action(LoadBalancingGroupAction.SET_CURRENT_LOAD_BALANCING_GROUP, { loadBalancingGroup });
}

export function setLoadBalancingGroupErrorAction(
  error: APIErrorAndError,
  selectedItemId: string,
): PayloadAction<
  LoadBalancingGroupAction.SET_LOAD_BALANCING_GROUP_ERROR,
  { error: APIErrorAndError; selectedItemId: string }
> {
  return action(LoadBalancingGroupAction.SET_LOAD_BALANCING_GROUP_ERROR, { error, selectedItemId });
}

export function clearCurrentLoadBalancingGroupAction(): Action<LoadBalancingGroupAction.CLEAR_CURRENT_LOAD_BALANCING_GROUP> {
  return action(LoadBalancingGroupAction.CLEAR_CURRENT_LOAD_BALANCING_GROUP);
}
