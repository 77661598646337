import * as React from 'react';
import { Primary, Secondary, StyledAvatar, StyledContainerHeader, Text } from './styled';

export interface ContainerHeaderProps {
  /** Optional avatar to show. */
  avatar?: React.ReactNode;
  /** Primary text to show. */
  text: React.ReactNode;
  /** Secondary text to show. */
  subText?: React.ReactNode;
}

const ContainerHeader: React.FC<ContainerHeaderProps> = ({ avatar, text, subText }) => (
  <StyledContainerHeader>
    {avatar && <StyledAvatar>{avatar}</StyledAvatar>}
    <Text>
      <Primary noWrap variant="body1" color="textPrimary" title={typeof text === 'string' ? text : undefined}>
        {text}
      </Primary>
      {subText && (
        <Secondary
          noWrap
          variant="body2"
          color="textSecondary"
          title={typeof subText === 'string' ? subText : undefined}
        >
          {subText}
        </Secondary>
      )}
    </Text>
  </StyledContainerHeader>
);

ContainerHeader.displayName = 'ContainerHeader';

export default ContainerHeader;
