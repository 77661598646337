import { Action, action, PayloadAction } from 'typesafe-actions';
import { OrganisationUserAction, OrganisationUserState } from './types';

type OrganisationUser = NonNullable<OrganisationUserState['current']>;

export function getCurrentOrganisationUserAction(
  organisationSlug: string,
  userId: string,
): PayloadAction<OrganisationUserAction.GET_CURRENT_ORGANISATION_USER, { organisationSlug: string; userId: string }> {
  return action(OrganisationUserAction.GET_CURRENT_ORGANISATION_USER, { organisationSlug, userId });
}

export function setCurrentOrganisationUserAction(
  user: NonNullable<OrganisationUserState['current']>,
): PayloadAction<OrganisationUserAction.SET_CURRENT_ORGANISATION_USER, { user: OrganisationUser }> {
  return action(OrganisationUserAction.SET_CURRENT_ORGANISATION_USER, { user });
}

export function setOrganisationUserErrorAction(
  error: Error,
): PayloadAction<OrganisationUserAction.SET_ORGANISATION_USER_ERROR, { error: Error }> {
  return action(OrganisationUserAction.SET_ORGANISATION_USER_ERROR, { error });
}

export function clearCurrentOrganisationUserAction(): Action<OrganisationUserAction.CLEAR_CURRENT_ORGANISATION_USER> {
  return action(OrganisationUserAction.CLEAR_CURRENT_ORGANISATION_USER);
}
