import React from 'react';
import { useSelector } from 'react-redux';
import { Card, List } from '../../../../../../../design-system';
import { fullStateSelector } from '../../../../../../../store/root';
import { DialogContents } from '../types/commands';
import { ChangeAvailabilityDialog } from './commandDialogs/ChangeAvailabilityDialog';
import { ClearCacheDialog } from './commandDialogs/ClearCacheDialog';
import { ClearChargingProfileDialog } from './commandDialogs/ClearChargingProfileDialog';
import { DataTransferDialog } from './commandDialogs/DataTransferDialog';
import { GetDiagnosticsDialog } from './commandDialogs/GetDiagnosticsDialog';
import { ResetDialog } from './commandDialogs/ResetDialog';
import { SudoDialog } from './commandDialogs/SudoDialog';
import { UploadDiagnosticDialog } from './commandDialogs/UploadDiagnosticDialog';
import { CommandItem } from './CommandItem';

enum Dialog {
  ChangeAvailability,
  Reset,
  Sudo,
  ClearCache,
  Diagnostics,
  DataTransfer,
  ClearChargingProfile,
  UploadDiagnostics,
}

interface UserType {
  isSuperuser: boolean;
  isDriver: boolean;
}

const driverCommands: DialogContents<Dialog>[] = [
  {
    id: Dialog.ChangeAvailability,
    label: 'Change availability',
    description: 'Enable or disable all connectors on the charge point',
    DialogComponent: ChangeAvailabilityDialog,
  },
];

const commands: DialogContents<Dialog>[] = [
  ...driverCommands,
  {
    id: Dialog.Reset,
    label: 'Reset',
    description: 'Reset the charge point',
    DialogComponent: ResetDialog,
  },
];

const superCommands: DialogContents<Dialog>[] = [
  ...commands,
  {
    id: Dialog.Sudo,
    label: 'Sudo Command',
    description: 'Send custom OCPP message',
    DialogComponent: SudoDialog,
  },
  {
    id: Dialog.ClearCache,
    label: 'Clear cache',
    description: "Clear a charge point's token cache",
    DialogComponent: ClearCacheDialog,
  },
  {
    id: Dialog.Diagnostics,
    label: 'Get diagnostics',
    description: 'Upload charge point diagnostic information to a URL',
    DialogComponent: GetDiagnosticsDialog,
  },
  {
    id: Dialog.DataTransfer,
    label: 'Data transfer',
    description: 'Send a custom data message to the charge point',
    DialogComponent: DataTransferDialog,
  },
  {
    id: Dialog.ClearChargingProfile,
    label: 'Clear charging profile',
    description: 'Clear a specific charging profile',
    DialogComponent: ClearChargingProfileDialog,
  },
  {
    id: Dialog.UploadDiagnostics,
    label: 'Upload diagnostics',
    description: 'Upload diagnostics from the charge point',
    DialogComponent: UploadDiagnosticDialog,
  },
];

const getCommands = ({ isSuperuser, isDriver }: UserType) => {
  if (isSuperuser) {
    return superCommands;
  }
  if (isDriver) {
    return driverCommands;
  }
  return commands;
};

export const ChargePointCommands: React.FC = () => {
  const [opened, setOpened] = React.useState<Dialog | undefined>(undefined);
  const {
    chargePoint: { loading },
    isDriver,
    isSuperuser,
  } = useSelector(fullStateSelector);

  const chargePointCommands = getCommands({ isSuperuser, isDriver });

  return (
    <Card title="Charge point commands">
      <List>
        {chargePointCommands.map(({ id, label, description, DialogComponent }) => (
          <CommandItem
            key={id}
            label={label}
            description={description}
            isDisabled={loading}
            onClick={() => setOpened(id)}
            dialog={<DialogComponent isOpen={opened === id} onClose={() => setOpened(undefined)} />}
          />
        ))}
      </List>
    </Card>
  );
};
