import type {
  RequestAtomicOperations,
  RequestCreateJob,
  RequestCreateOrganisationLocation,
  RequestInviteDriver,
} from '../../app/ApiGen';
import { JobType } from '../../app/enums';
import type { JobWizardState as EvnexJobWizardState } from '../../views/Admin/Jobs/CreateJob/types';
import type { JobWizardState as InstallerJobWizardState } from '../../views/Installer/Jobs/CreateJob/types';
import { removeOptionalValues } from '../formUtils';
import { getIcpNumber } from '../locations/installationConnectionPoint';
import { evnexJobDetailsSchema, EvnexJobFormDetails } from './CreateEvnexJob';
import type { JobFormDetails } from './CreateJob';
import { JobLocationDetails } from './location';
import { locationValidationSchema } from '../locations/FieldDefinitions';

function mapWizardStateToAddJobOperation(wizardState: InstallerJobWizardState) {
  const { contactPhoneNumber, powerSensorCount, referenceId } = removeOptionalValues<JobFormDetails>(
    wizardState,
    evnexJobDetailsSchema,
  );
  const { timeZone } = wizardState.locationFieldContents;
  return {
    contactPhoneNumber,
    details: { timeZone },
    isHardwareOnly: false,
    isThirdParty: true,
    jobType: JobType.ResidentialInstall,
    referenceId: referenceId?.trim() ?? undefined,
    distributionBoards: [{ powerSensorCount, chargePointCount: 1 }],
  };
}

export function getLocationAttributes(location: JobLocationDetails) {
  return {
    address: location.address,
    coordinates: location.coordinates,
    icpNumber: getIcpNumber(location.installationPoint),
    name: location.name,
  };
}

export function mapJobWizardStateToRequestAtomicOperations(
  wizardState: InstallerJobWizardState,
): RequestAtomicOperations {
  const {
    driverFieldContents,
    locationFieldContents: { installationPoint, ...location },
  } = wizardState;
  return {
    'atomic:operations': [
      { op: 'add', data: { type: 'users', lid: 'customerId', attributes: driverFieldContents } },
      {
        op: 'add',
        data: {
          type: 'locations',
          lid: 'locationId',
          attributes: getLocationAttributes({ ...location, installationPoint }),
        },
      },
      {
        op: 'add',
        data: { type: 'jobs', lid: 'jobId', attributes: mapWizardStateToAddJobOperation(wizardState) },
      },
    ],
  };
}

export function mapJobWizardStateToRequestCreateJob(
  wizardState: EvnexJobWizardState & { driverId: string; locationId: string },
): RequestCreateJob {
  const {
    assignedInstallers,
    contactPhoneNumber,
    powerSensorCount,
    referenceId,
  } = removeOptionalValues<EvnexJobFormDetails>(wizardState, evnexJobDetailsSchema);
  const {
    driverId,
    locationId,
    locationFieldContents: { timeZone },
  } = wizardState;

  return {
    data: {
      type: 'jobs',
      attributes: {
        contactPhoneNumber,
        details: { timeZone },
        isThirdParty: false,
        jobType: JobType.ResidentialInstall,
        referenceId: referenceId?.trim() ?? undefined,
        distributionBoards: [{ powerSensorCount, chargePointCount: 1 }],
      },
      relationships: {
        assignedInstallers: {
          data: assignedInstallers.map((installer) => ({ type: 'users', id: installer.id })),
        },
        customer: { data: { type: 'users', id: driverId } },
        location: { data: { type: 'locations', id: locationId } },
      },
    },
  };
}

export function mapJobWizardStateToRequestInviteUser(wizardState: EvnexJobWizardState): RequestInviteDriver {
  return { data: { type: 'users', attributes: { ...wizardState.driverFieldContents, suppressInvitationEmail: true } } };
}

export function mapJobWizardStateToRequestCreateLocation(
  wizardState: EvnexJobWizardState,
): RequestCreateOrganisationLocation {
  const { installationPoint, ...locationFieldValues } = wizardState.locationFieldContents;
  const location = { ...locationFieldValues, icpNumber: getIcpNumber(installationPoint) };
  return {
    data: {
      type: 'locations',
      attributes: { ...removeOptionalValues(location, locationValidationSchema), isPublic: false },
    },
  };
}
