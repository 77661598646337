import { useSnackbar } from 'notistack';
import { parse } from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../app/AuthProvider';
import { errorSnackbar } from '../../../components/ErrorSnackbar';
import { Loading } from '../../../design-system';
import { Layout } from '../components/Layout';
import { CodeSent } from './components/CodeSent';
import { CodeVerified } from './components/CodeVerified';
import { RequestNewCodeForm, RequestNewCodeFormProps } from './components/RequestNewCodeForm';

enum VerificationState {
  LOADING,
  REQUEST_NEW_CODE,
  CODE_SENT,
  CODE_VERIFIED,
}

const VerifyView: React.FC = () => {
  const [verificationState, setVerificationState] = React.useState(VerificationState.LOADING);
  const [sentToEmail, setSentToEmail] = React.useState<string | undefined>(undefined);

  const { error, requestNewSignUpCode, confirmSignUp } = useAuth();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const fromSearch = parse(search);

  React.useEffect(() => {
    if (error) {
      errorSnackbar('Unable to verify account', closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, enqueueSnackbar, error]);

  React.useEffect(() => {
    if (
      fromSearch.email &&
      typeof fromSearch.email === 'string' &&
      fromSearch.code &&
      typeof fromSearch.code === 'string'
    ) {
      confirmSignUp(fromSearch.email, fromSearch.code).finally(() =>
        setVerificationState(VerificationState.CODE_VERIFIED),
      );
    }

    setVerificationState(VerificationState.REQUEST_NEW_CODE);
  }, [confirmSignUp, fromSearch.email, fromSearch.code]);

  const onRequestNewCode = React.useCallback<RequestNewCodeFormProps['onSubmit']>(
    async ({ email }, { setSubmitting }) => {
      try {
        const sentTo = await requestNewSignUpCode(email);
        setSentToEmail(sentTo || undefined);
        setVerificationState(VerificationState.CODE_SENT);
      } finally {
        setSubmitting(false);
      }
    },
    [requestNewSignUpCode],
  );

  switch (verificationState) {
    case VerificationState.LOADING:
      return (
        <Layout>
          <Loading />
        </Layout>
      );
    case VerificationState.CODE_SENT:
      return (
        <Layout>
          <CodeSent sentToEmail={sentToEmail} />
        </Layout>
      );
    case VerificationState.CODE_VERIFIED:
      return (
        <Layout>
          <CodeVerified />
        </Layout>
      );
    default:
      return (
        <Layout>
          <RequestNewCodeForm onSubmit={onRequestNewCode} />
        </Layout>
      );
  }
};

export default VerifyView;
