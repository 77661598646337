import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useGetOrganisationClientCredentials } from '../../../../../app/ApiGen';
import { useCurrentOrganisation } from '../../../../../app/CurrentOrganisationProvider';
import { DataErrorHandler } from '../../../../../components/ErrorHandler';
import { Card, Loading } from '../../../../../design-system';
import type { SubState } from '../../../../../store/types';

const FetchContext: React.FC<{ organisation: SubState<'organisation'> }> = ({ organisation }) => {
  const { data, error, loading, refetch } = useGetOrganisationClientCredentials({
    orgSlug: organisation.slug,
  });
  const [showClientsDetails, setShowClientsDetails] = React.useState(false);
  const { push } = useHistory();

  if (loading) {
    return <Loading />;
  }

  const clientCredentials = data ? data.data : { id: '****', secret: '****' };
  const apiAccess = !!organisation.tierDetails.apiAccess || !!organisation.tierDetails.demandResponse;

  const handleClickShowClientsDetails = () => {
    setShowClientsDetails(!showClientsDetails);
  };

  if (error) {
    return error.status === 403 ? null : (
      <DataErrorHandler
        description="Unable to load API client credentials"
        error={error}
        refetch={refetch}
        type="embedded"
      />
    );
  }

  return (
    <>
      <Card>
        <Box>
          <Grid container justify="space-between" spacing={2}>
            <Grid item xs={8}>
              <Typography style={{ padding: '16px' }} color="textSecondary" variant="body2" paragraph>
                API details
              </Typography>
            </Grid>
            <Grid
              style={{ padding: '16px', marginRight: '8px', marginTop: '4px' }}
              container
              direction="column"
              item
              xs={3}
              alignContent="flex-end"
            >
              {apiAccess ? (
                <Button
                  variant="outlined"
                  type="button"
                  onClick={handleClickShowClientsDetails}
                  endIcon={showClientsDetails ? <Visibility /> : <VisibilityOff />}
                >
                  {showClientsDetails ? 'Hide' : 'Show'} API details
                </Button>
              ) : (
                <Tooltip title="Upgrade to unlock API access.">
                  <Button
                    endIcon={<LockOutlinedIcon />}
                    onClick={() => push(`/organisations/${organisation?.slug}/upgrade`)}
                    color="primary"
                    variant="contained"
                  >
                    Show API details
                  </Button>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          <Box p={2} pt={0}>
            <Grid container>
              <Grid item xs={4}>
                <Typography color="textSecondary" variant="body2" paragraph>
                  Client ID
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" paragraph style={{ marginLeft: '-20px' }}>
                  {clientCredentials.id}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <Typography color="textSecondary" variant="body2" paragraph>
                  Client Secret
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" paragraph style={{ marginLeft: '-20px' }}>
                  {showClientsDetails ? clientCredentials.secret : '****'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export const APIDetails: React.FC = () => {
  const { organisation, loading } = useCurrentOrganisation();

  if (loading || !organisation) {
    return <Loading />;
  }
  return <FetchContext organisation={organisation} />;
};
