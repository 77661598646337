import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { OrganisationsView } from './Organisations';
import { ChargePointsListView } from './ChargePoints';
import { CommandsView } from './Commands/CommandsView';
import CreateView from './Create';
import InstallersView from './Installers';
import JobsView from './Jobs';
import { LocationsView } from './Locations';
import { MigrateView } from './Migrate';
import UsersView from './Users';

export const AdminView: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/organisations`}>
        <OrganisationsView />
      </Route>
      <Route path={`${path}/charge-points`}>
        <ChargePointsListView />
      </Route>
      <Route path={`${path}/charge-points-command`}>
        <CommandsView />
      </Route>
      <Route path={`${path}/create`}>
        <CreateView />
      </Route>
      <Route path={`${path}/installers`}>
        <InstallersView />
      </Route>
      <Route path={`${path}/jobs`}>
        <JobsView />
      </Route>
      <Route path={`${path}/locations`}>
        <LocationsView />
      </Route>
      <Route path={`${path}/migrate`}>
        <MigrateView />
      </Route>
      <Route path={`${path}/users`}>
        <UsersView />
      </Route>
    </Switch>
  );
};
