import Grid from '@material-ui/core/Grid';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import React from 'react';
import type { Job } from '../../app/ApiGen';
import { Section } from '../../design-system';
import { DateFormatDateFns, TimeFormatDateFns } from '../../utils/dateFormat';
import { HumanReadableJobType } from '../../utils/jobs/table';
import type { Normalised } from '../../utils/request';
import { JobStatusValue } from '../CustomFields/JobStatus';

type JobHeaderSectionProps = {
  job: Normalised<Job>;
};

export const HeaderSection: React.VFC<JobHeaderSectionProps> = ({ job }: JobHeaderSectionProps) => (
  <>
    <Section
      title={
        <Grid container spacing={1}>
          <Grid item>{job.referenceId ?? 'Job'}</Grid>
          <Grid item>
            <JobStatusValue value={job.status} />
          </Grid>
        </Grid>
      }
      description={`${HumanReadableJobType[job.jobType]} job for ${job.organisationName}`}
    />
    <Section
      description={
        <>
          Created {formatDistance(new Date(job.created), new Date(), { addSuffix: true })}
          <br />
          Last updated:&nbsp;
          {format(new Date(job.updated), `${DateFormatDateFns.Long} ${TimeFormatDateFns.NoSeconds}`)}
        </>
      }
    />
  </>
);
