import React from 'react';
import { useSnackbar } from 'notistack';
import { CircularProgress, Button } from '@material-ui/core';
import { Dialog, DialogProps } from '../../../../../../../../components/Dialog';
import { useChargePoint } from '../../../Provider';
import { useCommandClearCache } from '../../../../../../../../app/ApiGen';
import { useHandleError } from './useHandleError';

export const ClearCacheDialog: React.FC<DialogProps> = ({ isOpen, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { chargePoint, refetch } = useChargePoint();
  const { error, mutate } = useCommandClearCache({ id: chargePoint?.id ?? '' });
  const [isLoading, setLoading] = React.useState(false);

  useHandleError({ error, defaultErrorMessage: 'Unable to clear the cache' });

  const onSubmit = () => {
    setLoading(true);
    mutate()
      .then((response) => enqueueSnackbar(`Clear cache action ${response.data.status.toLocaleLowerCase()}`))
      .catch(() => {
        // We're already handling this from the mutation context result.
      })
      .finally(() => {
        onClose();
        refetch();
        setLoading(false);
      });
  };

  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Clear cache">
      <Button variant="contained" onClick={onSubmit} disabled={isLoading} color="primary">
        {isLoading ? <CircularProgress size={21} /> : 'Send command'}
      </Button>
    </Dialog>
  );
};
