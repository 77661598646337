import Skeleton from '@material-ui/lab/Skeleton';
import { useLoadScript } from '@react-google-maps/api';
import { UseLoadScriptOptions } from '@react-google-maps/api/dist/useLoadScript';
import * as React from 'react';
import { GoogleApiKey } from '../../config';
import { ErrorHandler } from '../ErrorHandler';

const config: UseLoadScriptOptions = {
  googleMapsApiKey: GoogleApiKey.Maps,
  libraries: ['places'],
};

export const GoogleMapLoader: React.FC = ({ children }) => {
  const { isLoaded, loadError } = useLoadScript(config);

  if (loadError) {
    return <ErrorHandler description="Unable to load map" error={loadError} />;
  }

  return isLoaded ? <>{children}</> : <Skeleton variant="rect" style={{ height: '100%' }} />;
};
