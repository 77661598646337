import { rgba } from 'polished';
import styled from 'styled-components';
import { ProductId } from '../../types';
import { StyledFooterSection } from '../FooterSection/styled';
import { StyledHeaderSection } from '../HeaderSection/styled';
import { StyledLink } from '../Item/styled';
import { StyledMenuSection } from '../MenuSection/styled';

const getBackground = (productId?: ProductId) => {
  let lighter;
  let darker;

  switch (productId) {
    case ProductId.ADMIN:
      lighter = '#501212';
      darker = '#1b0a0a';
      break;
    case ProductId.PARTNER:
      lighter = '#114820';
      darker = '#010601';
      break;
    default:
      lighter = '#beb9af';
      darker = '#beb9af';
      break;
  }

  return { lighter, darker };
};

export const StyledProductNavigation = styled('div')<{ productId?: ProductId; isCollapsed: boolean }>`
  min-width: ${(p) => (p.isCollapsed ? 72 : 220)}px;
  max-width: ${(p) => (p.isCollapsed ? 72 : 220)}px;
  height: 100vh;
  display: flex;
  flex-flow: nowrap column;
  background-color: ${(p) => getBackground(p.productId).lighter};
  background: linear-gradient(
    180deg,
    ${(p) => getBackground(p.productId).lighter} 0%,
    ${(p) => getBackground(p.productId).darker} 100%
  );
  box-shadow: ${(p) => p.theme.shadows['4']};
  color: ${(p) => p.theme.palette.secondary.contrastText};
  overflow: hidden;
  transition: min-width ${(p) => p.theme.transitions.easing.easeInOut} ${(p) => p.theme.transitions.duration.standard}ms,
    max-width ${(p) => p.theme.transitions.easing.easeInOut} ${(p) => p.theme.transitions.duration.standard}ms;
  z-index: ${(p) => p.theme.zIndex.appBar};

  ${StyledHeaderSection} {
    justify-content: ${(p) => (p.isCollapsed ? 'center' : 'normal')};
  }

  ${StyledMenuSection} {
    align-items: ${(p) => (p.isCollapsed ? 'center' : 'normal')};
  }

  ${StyledFooterSection} {
    justify-content: ${(p) => (p.isCollapsed ? 'center' : 'normal')};
  }

  ${StyledLink} {
    margin: ${(p) => p.theme.spacing(1)}px;
    margin-top: 0;
    width: ${(p) => (p.isCollapsed ? '40px' : 'auto')};
    min-width: ${(p) => (p.isCollapsed ? 'auto' : '204px')};
    border-radius: 4px;
    color: ${(p) => p.theme.palette.secondary.contrastText};

    :hover,
    :focus,
    &.active {
      background-color: ${(p) => rgba(p.theme.palette.common.white, 0.13)};
    }
  }
`;
