type ElectricityCost = { cost: number } & unknown;

export const currencyOptions = [
  { label: '(AUD) Australian dollar', value: 'AUD' },
  { label: '(EUR) Euro', value: 'EUR' },
  { label: '(GBP) Pound sterling', value: 'GBP' },
  { label: '(NZD) New Zealand dollar', value: 'NZD' },
];

export function getCost(costs: ElectricityCost[] | undefined): number | undefined {
  if (costs === undefined || costs.length === 0) {
    return undefined;
  }
  return costs[0].cost;
}
