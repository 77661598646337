import { useSnackbar } from 'notistack';
import React from 'react';
import { Job, useUpdateJobLocation } from '../../app/ApiGen';
import { JobLocationDetails, jobLocationFields, toJobLocationFields } from '../../utils/jobs/location';
import { getLocationAttributes } from '../../utils/jobs/mappers';
import type { Normalised } from '../../utils/request';
import { errorSnackbar } from '../ErrorSnackbar';
import { locationValidationSchema } from '../../utils/locations/FieldDefinitions';
import { Card, Form, OnSubmit, Section } from '../../design-system';

interface LocationDetailsSectionProps {
  job: Normalised<Job>;
  refetchJob: () => Promise<void>;
}

export const LocationDetailsSection: React.VFC<LocationDetailsSectionProps> = ({
  job,
  refetchJob,
}: LocationDetailsSectionProps) => {
  const locationFieldValues = toJobLocationFields(job);
  const { loading: updateLocationLoading, mutate: updateLocation } = useUpdateJobLocation({ jobId: job.id });
  const [isEditing, setIsEditing] = React.useState(false);
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const onSubmit: OnSubmit<JobLocationDetails> = React.useCallback(
    (locationUpdate) => {
      if (!job.location) {
        return;
      }
      setIsEditing(false);
      updateLocation({
        data: {
          id: job.location.id,
          type: 'locations',
          attributes: getLocationAttributes(locationUpdate),
        },
      })
        .then(async () => {
          enqueueSnackbar('Job location updated', { variant: 'success' });
          await refetchJob();
        })
        .catch((e) => {
          errorSnackbar('Unable to update job location', closeSnackbar, enqueueSnackbar, e);
        });
    },
    [closeSnackbar, enqueueSnackbar, job, refetchJob, updateLocation],
  );

  return (
    <Section>
      <Card title="Location">
        <Form<JobLocationDetails>
          fields={Object.values(jobLocationFields({ timeZoneEditable: false }))}
          initialValues={locationFieldValues}
          validationSchema={locationValidationSchema}
          onSubmit={onSubmit}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isLoading={updateLocationLoading}
        />
      </Card>
    </Section>
  );
};
