import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateCardView from './CreateCard';
import CreateChargePointView from './CreateChargePoint';
import CreateLoadBalancingGroupView from './CreateLoadBalancingGroup';
import CreateLocationView from './CreateLocation';
import CreateUserView from './CreateUser';

const CreateView: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/card`}>
        <CreateCardView />
      </Route>
      <Route path={`${path}/charge-point`}>
        <CreateChargePointView />
      </Route>
      <Route path={`${path}/load-balancing-group`}>
        <CreateLoadBalancingGroupView />
      </Route>
      <Route path={`${path}/location`}>
        <CreateLocationView />
      </Route>
      <Route path={`${path}/user`}>
        <CreateUserView />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default CreateView;
