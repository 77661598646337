import { ChargePoint } from '../../utils/api';
import { APIErrorAndError } from '../../utils/error';

export enum ChargePointAction {
  GET_CURRENT_CHARGE_POINT = '@@chargePoint/GET_CURRENT_CHARGE_POINT',
  SET_CURRENT_CHARGE_POINT = '@@chargePoint/SET_CURRENT_CHARGE_POINT',
  SET_CHARGE_POINT_ERROR = '@@chargePoint/SET_CHARGE_POINT_ERROR',
  CLEAR_CURRENT_CHARGE_POINT = '@@chargePoint/CLEAR_CURRENT_CHARGE_POINT',
}

export interface ChargePointState {
  loading: boolean;
  error?: APIErrorAndError;
  current?: ChargePoint;
  selectedItemId?: ChargePoint['id'];
}
