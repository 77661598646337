import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import * as React from 'react';
import emptyImg from '../../../assets/map/empty.png';
import locationImg from '../../../assets/map/location.png';
import { GoogleMapLoader, googleMapStyles } from '../../../components/GoogleMap';
import { MapWrapper } from './styled';
import { LocationData } from './types';

interface CenterState {
  lat: number;
  lng: number;
}

interface LocationMapProps {
  locations: LocationData[];
}

type LocationMapItem = {
  pos: { lat: number; lng: number };
  id: string;
};

const botanicalHillLatittude = -41.2667;
const botanicalHillLongitude = 173.3;

export const LocationMap: React.FC<LocationMapProps> = ({ locations }) => {
  const [center, setCenter] = React.useState<CenterState | undefined>({
    lat: botanicalHillLatittude,
    lng: botanicalHillLongitude,
  });
  const locationItems = React.useMemo<LocationMapItem[]>(
    () =>
      locations.map(({ coordinates, id }) => ({
        id,
        pos: { lat: Number(coordinates.latitude), lng: Number(coordinates.longitude) },
      })),
    [locations],
  );

  React.useEffect(() => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported!');
      return;
    }

    navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      setCenter({ lat: Number(latitude), lng: Number(longitude) });
    });
  }, []);

  return (
    <MapWrapper>
      <GoogleMapLoader>
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          center={center}
          zoom={4}
          options={{
            streetViewControl: false,
            styles: googleMapStyles,
          }}
        >
          <MarkerClusterer
            averageCenter
            gridSize={1}
            clusterClass="evnex-map-cluster"
            styles={[{ url: emptyImg, height: 20, width: 20 }]}
          >
            {(clusterer) =>
              locationItems.map((location) => (
                <Marker
                  key={location.id}
                  icon={locationImg}
                  position={location.pos}
                  clusterer={clusterer}
                  onLoad={(m) => {
                    m.set('evnexLocationData', location);
                  }}
                />
              ))
            }
          </MarkerClusterer>
        </GoogleMap>
      </GoogleMapLoader>
    </MapWrapper>
  );
};
