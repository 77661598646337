import React from 'react';
import { useSnackbar } from 'notistack';
import { standardiseError } from '../../../../../../../../utils/error';
import { errorSnackbar } from '../../../../../../../../components/ErrorSnackbar';

export const useHandleError = (args: { error: unknown; defaultErrorMessage: string }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { defaultErrorMessage, error } = args;

  return React.useEffect(() => {
    if (error) {
      const standardisedError = standardiseError(error);
      errorSnackbar(standardisedError.detail ?? defaultErrorMessage, closeSnackbar, enqueueSnackbar, error);
    }
  }, [closeSnackbar, defaultErrorMessage, enqueueSnackbar, error]);
};
