import { all, call, fork, put, takeLatest, Effect } from 'redux-saga/effects';
import { environment } from '../../config';
import { clearCurrentOrganisationAction } from '../organisation/actions';
import {
  clearAuthenticatedUserDoneAction,
  setAuthenticatedUserAction,
  setAuthenticatedUserErrorAction,
} from './actions';
import { getLegacyAuthenticatedUser, Response } from './api';
import { AuthenticatedUserAction } from './types';
import { standardiseError } from '../../utils/error';

export function* handleGetAuthenticatedUser(): Generator<Effect> {
  try {
    const { data } = (yield call(getLegacyAuthenticatedUser)) as Response;
    yield put(setAuthenticatedUserAction(data));
  } catch (e) {
    if (environment === 'development' || environment === 'test') {
      console.error('handleGetCurrentUser() error', e);
    }
    const error = standardiseError(e);
    yield put(setAuthenticatedUserErrorAction(error));
  }
}

function* watchGetAuthenticatedUser() {
  yield takeLatest(AuthenticatedUserAction.GET_AUTHENTICATED_USER, handleGetAuthenticatedUser);
}

export function* handleClearAuthenticatedUser(): Generator<Effect> {
  yield put(clearCurrentOrganisationAction());
  yield put(clearAuthenticatedUserDoneAction());
}

function* watchClearAuthenticatedUser() {
  yield takeLatest(AuthenticatedUserAction.CLEAR_AUTHENTICATED_USER, handleClearAuthenticatedUser);
}

export function* authenticatedUserSagas(): Generator<Effect> {
  yield all([fork(watchGetAuthenticatedUser), fork(watchClearAuthenticatedUser)]);
}
