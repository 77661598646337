import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  ResponseListLocationUsers,
  ResponseListMemberships,
  useAddLocationUsers,
  useListLocationUsers,
  useListOrganisationMembers,
} from '../../../../../../../app/ApiGen';
import { UserAvatar } from '../../../../../../../components/Avatar/User';
import { DataErrorHandler } from '../../../../../../../components/ErrorHandler';
import { errorSnackbar } from '../../../../../../../components/ErrorSnackbar';
import { SelectableItem, SelectionList } from '../../../../../../../components/SelectionList';
import { FormActions, Loading } from '../../../../../../../design-system';
import type { SubState } from '../../../../../../../store/types';
import { getSelectableUsers } from '../../../../../../../utils/users/form';

interface SelectUserContext {
  location: SubState<'location'>;
  organisation: SubState<'organisation'>;
  organisationMembers: ResponseListMemberships;
  usersInLocation: ResponseListLocationUsers;
}

const SelectUser: React.FC<SelectUserContext> = ({ location, organisation, organisationMembers, usersInLocation }) => {
  const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>([]);
  const [submitting, setSubmitting] = React.useState(false);
  const { push } = useHistory();
  const addLocationUsers = useAddLocationUsers({ locationId: location.id });
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const selectableUsers: SelectableItem[] = getSelectableUsers(organisationMembers.included, usersInLocation.data);

  const onUserSelect = React.useCallback(
    (user: SelectableItem) => {
      // Currently, can only select one user at a time
      setSelectedUserIds([user.id]);
    },
    [setSelectedUserIds],
  );

  const onAddUser = React.useCallback(() => {
    setSubmitting(true);
    addLocationUsers
      .mutate({ data: selectedUserIds.map((userId) => ({ type: 'users', id: userId })) })
      .then(() => {
        setSubmitting(false);
        enqueueSnackbar('Users added to location', { variant: 'success' });
        push(`/organisations/${organisation.slug}/locations/${location.id}/users`);
      })
      .catch((e) => {
        setSubmitting(false);
        errorSnackbar('Unable to add users to location', closeSnackbar, enqueueSnackbar, e);
      });
  }, [addLocationUsers, closeSnackbar, enqueueSnackbar, location.id, organisation.slug, push, selectedUserIds]);

  return (
    <SelectionList
      getImage={({ title, color, image }) => <UserAvatar embedded user={{ name: title, color, avatar: image }} />}
      items={selectableUsers}
      label="user"
      onSelectItem={onUserSelect}
      lowerActions={
        <FormActions>
          <Button
            color="primary"
            variant="contained"
            disabled={submitting || selectedUserIds.length === 0}
            onClick={onAddUser}
          >
            {submitting ? <CircularProgress size={24} color="inherit" /> : 'Add user'}
          </Button>
        </FormActions>
      }
    />
  );
};

export const SelectUserStep: React.FC<{ location: SubState<'location'>; organisation: SubState<'organisation'> }> = ({
  location,
  organisation,
}) => {
  const { slug: orgSlug } = organisation;
  const { id: locationId } = location;
  const organisationMembers = useListOrganisationMembers({ orgSlug });
  const usersInLocation = useListLocationUsers({ locationId });

  if (organisationMembers.loading || usersInLocation.loading) {
    return <Loading />;
  }

  if (!usersInLocation.data || !organisationMembers.data || usersInLocation.error || organisationMembers.error) {
    return (
      <>
        {(usersInLocation.error || !usersInLocation.data) && (
          <DataErrorHandler
            description="Unable to load location users"
            error={usersInLocation.error}
            refetch={usersInLocation.refetch}
            type="embedded"
          />
        )}
        {(organisationMembers.error || !organisationMembers.data) && (
          <DataErrorHandler
            description="Unable to load organisation users"
            error={organisationMembers.error}
            refetch={organisationMembers.refetch}
            type="embedded"
          />
        )}
      </>
    );
  }

  return (
    <SelectUser
      location={location}
      organisation={organisation}
      organisationMembers={organisationMembers.data}
      usersInLocation={usersInLocation.data}
    />
  );
};
