import type { Reducer } from 'redux';
import * as Actions from './actions';
import { LocationAction, LocationState } from './types';

type Action = ReturnType<typeof Actions[keyof typeof Actions]>;

const initialState: LocationState = {
  loading: false,
  error: undefined,
  current: undefined,
  selectedItemId: undefined,
};

export const locationReducers: Reducer<LocationState, Action> = (state, action): LocationState => {
  switch (action.type) {
    case LocationAction.GET_CURRENT_LOCATION:
      return {
        ...(state ?? initialState),
        selectedItemId: action.payload.selectedItemId,
        loading: true,
        error: undefined,
      };
    case LocationAction.SET_CURRENT_LOCATION:
      return {
        ...(state ?? initialState),
        selectedItemId: action.payload.location.id,
        loading: false,
        error: undefined,
        current: action.payload.location,
      };
    case LocationAction.SET_LOCATION_ERROR:
      return {
        ...(state ?? initialState),
        selectedItemId: action.payload.selectedItemId,
        loading: false,
        error: action.payload.error,
      };
    case LocationAction.CLEAR_CURRENT_LOCATION:
      return {
        ...(state ?? initialState),
        selectedItemId: undefined,
        loading: false,
        error: undefined,
        current: undefined,
      };
    default:
      return state ?? initialState;
  }
};
