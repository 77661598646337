import Grid from '@material-ui/core/Grid';
import React from 'react';
import { DataErrorHandler } from '../../../../../../components/ErrorHandler';
import { Loading, Workspace } from '../../../../../../design-system';
import { useLocation } from '../Provider';
import { ChargePoints } from './components/ChargePoints';
import { Header } from './components/Header';

const OverviewView: React.FC = () => {
  const { error, loading, location, refetch } = useLocation();

  if (loading) {
    return (
      <Workspace>
        <Loading />
      </Workspace>
    );
  }

  if (error || !location) {
    return <DataErrorHandler description="Unable to load location" error={error} refetch={refetch} />;
  }

  return (
    <Workspace>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header location={location} />
        </Grid>
        <Grid item xs={12}>
          <ChargePoints />
        </Grid>
      </Grid>
    </Workspace>
  );
};

export default OverviewView;
