import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Form as FormikForm, Formik } from 'formik';
import * as React from 'react';
import { FormEditable, OnSubmit, Section, useWizard } from '../../../../../../design-system';
import {
  locationFields,
  LocationFields,
  locationValidationSchema,
} from '../../../../../../utils/locations/FieldDefinitions';

const initialValues: LocationFields = {
  name: '',
  address: {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    postCode: '',
    state: '',
    country: '',
  },
  coordinates: {
    latitude: '',
    longitude: '',
  },
  timeZone: '',
  installationPoint: null,
};

export const DetailsStep: React.FC = () => {
  const { state, setState, prevStep, nextStep } = useWizard();

  const onSubmit = React.useCallback<OnSubmit<LocationFields>>(
    ({ name, address, coordinates, timeZone, installationPoint }) => {
      setState({ name, address, coordinates, timeZone, installationPoint });
      nextStep();
    },
    [setState, nextStep],
  );

  return (
    <Formik
      initialValues={{ ...initialValues, ...state }}
      validationSchema={locationValidationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <FormikForm>
          <Section title="Location details">
            <FormEditable
              fields={Object.values(locationFields)}
              errors={errors}
              touched={touched}
              disabled={isSubmitting}
            />
          </Section>
          <Box pt={2} display="flex">
            <Button variant="contained" onClick={prevStep}>
              Cancel
            </Button>
            <Box mr="auto" />
            <Button color="primary" variant="contained" type="submit">
              Next
            </Button>
          </Box>
        </FormikForm>
      )}
    </Formik>
  );
};
