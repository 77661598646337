import React from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import { RouterLink } from '../../../../components/Link';
import { ConfirmedGraphic } from '../../../../design-system';

export const PasswordChanged = () => (
  <Fade in>
    <div>
      <ConfirmedGraphic textAlign="center" svgProps={{ style: { maxWidth: '60%', height: '100%' } }} />
      <Box mt={3} textAlign="center">
        <Typography>Your password has been reset.</Typography>
      </Box>
      <Box mt={2} textAlign="center">
        <RouterLink to="/auth/signin">Go to sign in screen</RouterLink>
      </Box>
    </div>
  </Fade>
);
