import type { ScatterDataPoint } from 'chart.js';
import { LLMClient, PhaseKey } from '../../../../../../../utils/chargePoints/diagnostics';

export interface ClientMeasurementAttributes {
  current: LLMClient['MeasuredCurrent'];
  phase: LLMClient['ChargingOnPhase'];
  serverEventDate: string;
}

export class ClientMeasurement implements ClientMeasurementAttributes {
  readonly current: LLMClient['MeasuredCurrent'];

  readonly phase: LLMClient['ChargingOnPhase'];

  readonly serverEventDate: string;

  constructor(params: ClientMeasurementAttributes) {
    const { current, phase, serverEventDate } = params;

    this.current = current;
    this.phase = phase;
    this.serverEventDate = serverEventDate;
  }

  getDatum(args: { phase: PhaseKey }): ScatterDataPoint {
    const { phase: phaseToLookFor } = args;
    return {
      x: new Date(this.serverEventDate).getTime(),
      y: this.phase === phaseToLookFor || this.phase === 'All' ? this.current : 0,
    };
  }
}
