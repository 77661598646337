import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { GetDataError } from 'restful-react';
import { ResponseApiErrors, Token } from '../../app/ApiGen';
import { InputComponentProps } from '../../design-system';
import { AutocompleteContents, AutocompleteWrapper, renderAutocompleteInput } from './SimpleAutocomplete';

export interface TokenInputProps extends InputComponentProps<string | undefined> {
  dataLoading: boolean;
  dataError: GetDataError<ResponseApiErrors> | null;
  options?: Token[];
}

export const TokenInput: React.FC<TokenInputProps> = ({
  dataLoading,
  dataError,
  options,
  value,
  name,
  onBlur,
  onChange,
  disabled,
  error,
  helperText,
}: TokenInputProps) => {
  if (dataLoading) {
    return <CircularProgress size="32px" />;
  }

  if (dataError || !options) {
    return <Typography color="error">Unable to load tokens</Typography>;
  }

  const mappedOptions = options.map(({ uid, name: tokenName }) => ({ label: tokenName ?? '', value: uid }));

  return (
    <AutocompleteWrapper>
      <Autocomplete
        disableClearable
        options={mappedOptions}
        getOptionLabel={({ label }) => label}
        value={mappedOptions.find(({ value: key }) => key === value)}
        renderOption={({ label, value: optionValue }) => (
          <div>
            <Typography variant="body2">{label}</Typography>
            <Typography variant="body2" color="textSecondary">
              {optionValue}
            </Typography>
          </div>
        )}
        renderInput={renderAutocompleteInput(disabled, error, helperText)}
        disabled={disabled}
        onBlur={onBlur}
        onChange={(_, token: AutocompleteContents) => {
          // Note: this also blocks the user from manually clearing the field
          if (token && token.value) {
            onChange(name)(token.value);
          }
        }}
        style={{ width: '100%' }}
      />
    </AutocompleteWrapper>
  );
};
