import { ChartData } from 'chart.js';
import { LLMServerStatusLog, PhaseKey, phases } from '../../../../../../../utils/chargePoints/diagnostics';
import type { DataTransferLog } from './configuration';
import { ClientsByName, LLMClientModel } from './LLMClientModel';
import { LLMGroupLimitModel } from './LLMGroupLimitModel';
import { LLMMaxSafeLimitModel } from './LLMMaxSafeLimitModel';
import { LLMServerModel, LLMServerModelKey, Models, ServerModelKeys } from './LLMServerModel';
import { LLMSharedLimitModel } from './LLMSharedLimitModel';
import { LLMSupplyCurrentModel } from './LLMSupplyCurrentModel';
import { DatasetKey, getDataset } from './presentation';

type LLMChartData = Record<PhaseKey, ChartData<'line'>>;

interface LLMChartAttributes {
  logs: LLMServerStatusLog[];
}

type ServerModels = Record<
  Exclude<DatasetKey, 'client'>,
  typeof LLMGroupLimitModel | typeof LLMSharedLimitModel | typeof LLMSupplyCurrentModel
>;

export const ModelMap: ServerModels = {
  groupLimit: LLMGroupLimitModel,
  maxSafeLimit: LLMMaxSafeLimitModel,
  sharedLimit: LLMSharedLimitModel,
  supplyCurrent: LLMSupplyCurrentModel,
};

const emptyChartData = () => ({ L1: { datasets: [] }, L2: { datasets: [] }, L3: { datasets: [] } });

export class LLMChartModel implements LLMChartAttributes {
  readonly logs: LLMServerStatusLog[];

  private constructor(params: LLMChartAttributes) {
    this.logs = params.logs;
  }

  static fromLogs(logs: DataTransferLog[]): LLMChartModel {
    const llmLogs = logs.flatMap((log) => {
      if (log.data?.messageId !== 'LLMStatus' || typeof log.data.data !== 'string') {
        return [];
      }
      return { ...log, data: JSON.parse(log.data.data) as LLMServerStatusLog['data'] };
    });
    return new LLMChartModel({ logs: llmLogs });
  }

  constructChartData(): LLMChartData {
    if (this.logs.length === 0) {
      return emptyChartData();
    }

    const modelsByType = this.logs.reduce<Models>(LLMChartModel.addModels, {
      client: {},
      groupLimit: [],
      maxSafeLimit: [],
      sharedLimit: [],
      supplyCurrent: [],
    });

    return phases.reduce<LLMChartData>(
      (acc, phase) => ({ ...acc, ...LLMChartModel.chartDataForPhase({ modelsByType, phase }) }),
      emptyChartData(),
    );
  }

  private static addModels(models: Models, log: LLMServerStatusLog): Models {
    const serverModels = LLMChartModel.updateServerModels({ models, log });
    const clientModels = LLMChartModel.updateClientModels({ models, log });
    return { ...serverModels, client: clientModels };
  }

  private static chartDataForPhase(args: { modelsByType: Models; phase: PhaseKey }) {
    const { modelsByType, phase } = args;
    const clientValues = LLMClientModel.getDatasets({ clientsByName: modelsByType.client, phase });
    const serverValues = ServerModelKeys.map((key: LLMServerModelKey) => {
      const models = modelsByType[key];
      return getDataset({ key, data: LLMServerModel.getSortedData({ models, phase }) });
    });
    return { [phase]: { datasets: [...clientValues, ...serverValues] } };
  }

  private static updateClientModels(args: { models: Models; log: LLMServerStatusLog }): ClientsByName {
    const { models, log } = args;

    const newClientModels = log.data.ClientInfos.Clients.reduce<ClientsByName>(
      (clients, llmClient) =>
        LLMClientModel.addClientModel({
          allClients: clients,
          currentClient: models.client[llmClient.Name],
          llmClient,
          serverEventDate: log.serverEventDate,
        }),
      {},
    );

    return { ...models.client, ...newClientModels };
  }

  private static updateServerModels(args: { models: Models; log: LLMServerStatusLog }): Models {
    const { models, log } = args;
    return ServerModelKeys.reduce((serverModels, key) => {
      const Model = ModelMap[key];
      const newModels = [...models[key], new Model({ server: log.data, serverEventDate: log.serverEventDate })];
      return { ...serverModels, [key]: newModels };
    }, models);
  }
}
