import type { ScatterDataPoint } from 'chart.js';
import { PhaseKey } from '../../../../../../../utils/chargePoints/diagnostics';
import { LLMServerModel } from './LLMServerModel';

export class LLMSupplyCurrentModel extends LLMServerModel {
  getDatum(args: { phase: PhaseKey }): ScatterDataPoint {
    const { phase } = args;
    return {
      x: new Date(this.serverEventDate).getTime(),
      y: this.server.SupplyCurrent ? this.server.SupplyCurrent[phase] : 0,
    };
  }
}
