import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { StyledMenuTitle } from './styled';

export interface MenuTitleProps {
  isCollapsed?: boolean;
}

const MenuTitle: React.FC<MenuTitleProps> = ({ children, isCollapsed = false }) => (
  <StyledMenuTitle>
    {!isCollapsed && (
      <Typography noWrap variant="overline">
        {children}
      </Typography>
    )}
  </StyledMenuTitle>
);

MenuTitle.displayName = 'MenuTitle';

export default MenuTitle;
